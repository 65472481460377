/* eslint-disable no-unused-vars */
import React from 'react';

import { IVisaInformationFormValues } from '../../../types/IVisaInformation';

export interface IVisaInformationContext {
  products: { data: Array<any> | null; isLoading: boolean };
  visaInformationValues: IVisaInformationFormValues;
  setVisaInformationValues: (type: IVisaInformationFormValues) => void;
  discoverVisaProducts: (values: any, cb?: ((response?: any) => void) | undefined) => Promise<void>;
  sendVisaInformation: (values: any, cb?: ((response?: any) => void) | undefined) => Promise<void>;
  getCountriesList: (cb?: ((response?: any) => void) | undefined) => Promise<void>;
}

const VisaInformationContext = React.createContext<IVisaInformationContext>(
  {} as IVisaInformationContext
);

export default VisaInformationContext;

export const useVisaInformationContext = () => React.useContext(VisaInformationContext);
