import { getTime } from 'date-fns';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import {
  S3Client,
  PutObjectCommand,
  ListObjectsV2Command,
  DeleteObjectCommand
} from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { validateFile } from '../helper/validateFile';
import { getFileExtensionFromMimeType } from '../helper';

const mode = localStorage.getItem('oaMode');

export const awsBucketName =
  mode === 'live'
    ? process.env.REACT_APP_AWS_BUCKETNAME_LIVE
    : process.env.REACT_APP_AWS_BUCKETNAME_TEST;

const awsRegion = process.env.REACT_APP_AWS_REGION;

const awsS3Client = new S3Client({
  region: awsRegion,
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({ region: awsRegion }),
    identityPoolId: process.env.REACT_APP_AWS_IDENTITYPOOLID as string // IDENTITY_POOL_ID
  })
});

type UploadResult = {
  uploadedUrl?: string;
  error?: string;
  isUploading: boolean;
};

export const useS3FileUploader = (fileSizeLimit: number, allowedFileTypes: string[]) => {
  const uploadFile = async (
    file: File,
    basePath?: string,
    fileNameProps?: string
  ): Promise<UploadResult> => {
    let uploadResult: UploadResult = {
      isUploading: true
    };

    const isFileError = validateFile(file, fileSizeLimit, allowedFileTypes);
    if (isFileError) {
      return {
        error: isFileError,
        isUploading: false
      };
    }

    const fileExt = getFileExtensionFromMimeType(file?.type);

    let fileName = fileNameProps || `${getTime(new Date())}`;
    if (basePath) fileName = basePath + fileName;

    const filePath = `${fileName}.${fileExt}`;

    const uploadParams = {
      Bucket: awsBucketName,
      Key: filePath,
      Body: file,
      ContentType: file.type,
      ACL: 'public-read'
    };

    try {
      await awsS3Client.send(new PutObjectCommand(uploadParams));
      const uploadedUrl = `https://${awsBucketName}.s3.${awsRegion}.amazonaws.com/${filePath}`;
      uploadResult = { ...uploadResult, uploadedUrl: uploadedUrl, isUploading: false };
    } catch (error: any) {
      uploadResult = { ...uploadResult, error: error, isUploading: false };
    }
    return uploadResult;
  };

  return [uploadFile];
};

export const getS3File = async (key: string) => {
  const command = new ListObjectsV2Command({
    Bucket: awsBucketName,
    Delimiter: '/',
    Prefix: key
  });

  try {
    const { Contents } = await awsS3Client.send(command);
    return {
      content: Contents,
      bucketName: awsBucketName
    };
  } catch (err) {
    console.error(err);
  }
};

export const deleteS3File = async (bucketName: string, objectKey: string) => {
  const command = new DeleteObjectCommand({
    Bucket: bucketName,
    Key: objectKey
  });

  try {
    const response = await awsS3Client.send(command);
    return 'File deleted successfully';
  } catch (err) {
    return 'file deletion failed';
  }
};
