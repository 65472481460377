import axios from 'axios';

import { getAccessTokenByRefreshToken } from '../auth/core/_requests';
import * as authHelper from '../auth/core/AuthHelpers';
import showToast from './showToast';
import { getStorageOrgnizationId } from '.';

const API = axios.create({
  baseURL: ((process.env.REACT_APP_API_URL as any) + process.env.REACT_APP_API_URL_VERSION) as any,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json'
  }
});

export default API;

/**
 * Add a request interceptor ...
 */
let sessionTimedOut = false;

API.interceptors.request.use(
  (config: any) => {
    const isBlob = config?.url?.includes('/orders/report?');
    const isBlobPdf = config?.url?.includes('/quote/pdf');
    if (isBlob || isBlobPdf) {
      config.responseType = 'blob';
    }
    /**
     * NOTE: add 'loading-indicator' class to show global loading indicator ...
     */
    document.body.classList.add('loading-indicator');

    const token = localStorage?.getItem('kt-auth-react-v')
      ? JSON.parse(localStorage?.getItem('kt-auth-react-v') as any)?.data?.accessToken
      : '';

    if (!config.headers) config.headers = {};

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    const lsMode = localStorage.getItem('oaMode');
    config.headers['mode'] = lsMode;
    const isAllOrg = config?.url?.includes('orgId=all');

    config.headers['organizationId'] = isAllOrg ? '' : getStorageOrgnizationId();

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

/**
 * Add a response interceptor ...
 */
API.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger ...
    document.body.classList.remove('loading-indicator');

    return response;
  },
  async error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger ...
    let originalRequest: any = error.config;
    if (error?.response) {
      if (error?.response?.status === 401 && !originalRequest?._retry) {
        const lsAuth = JSON.parse(localStorage?.getItem('kt-auth-react-v') as any);
        originalRequest = Object.assign(originalRequest, {
          _retry: true
        });
        const result: any = await getAccessTokenByRefreshToken(lsAuth?.data?.refreshToken)
          .then(res => {
            const { accessToken } = res?.data?.data?.authenticationResult;
            const draftAuthData = {
              ...lsAuth,
              data: {
                ...lsAuth?.data,
                accessToken
              }
            };
            authHelper.setAuth(draftAuthData);

            error.response.config.headers['Authorization'] = 'Bearer ' + accessToken;
            return axios(error.response.config);
          })
          .catch(err => {
            if (
              !sessionTimedOut &&
              originalRequest._retry &&
              err?.response?.data?.httpStatus === 401
            ) {
              sessionTimedOut = true;
              showToast('Session timed out. Please login again', 'error');
              window.location.href = '/auth';
            }
          });
        document.body.classList.remove('loading-indicator');

        return result;
      }
    } else {
      showToast('Network Error', 'error');
    }

    return Promise.reject(error);
  }
);
