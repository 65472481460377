import { useEffect, useState } from 'react';
import {
  BadgeSVG,
  DeleteSVG,
  DummyDocSVG,
  PremiumSVG,
  ViewSVG
} from '../../../../../components/Icons';
import OAModalComponent from '../../../../../components/OAModalComponent';
import AddItineraryForm from './AddItineraryForm';
import { useOrderContext } from '../../../context/OrderContext';
import OAButton from '../../../../../components/widgets/OAButton';
import DummyForm from './DummyForm';
import { getFormattedDate, showToast } from '../../../../../helper';
import { OA_DATE_FORMAT_FE } from '../../../../../constants';
import { deleteS3File, getS3File } from '../../../../../hooks/useS3FileOperation';

export default function CreatorTools() {
  const { orderData } = useOrderContext();

  const [isItineraryModalOpen, setItineraryModalOpen] = useState<boolean>(false);
  const [isDummyModalOpen, setDummyModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [dummyLoading, setDummyLoading] = useState<boolean>(false);
  const [ItineraryLinks, setItineraryLinks] = useState<any>(null);
  const [itineraryStatus, setItineraryStatus] = useState<any>(true);
  const [coverLetterLinks, setCoverLetterLinks] = useState<any>(null);
  const [loads3Files, setLoadS3Files] = useState<any>(false);

  const toggleItineraryModal = () => setItineraryModalOpen(prev => !prev);

  const toggleDummyModal = () => setDummyModalOpen(prev => !prev);

  const toggleLoader = (props: boolean) => {
    setLoading(props);
  };

  const toggleItineraryStatus = (props: string) => {
    setItineraryStatus(props);
  };

  const loadFiles = (props: boolean) => {
    setLoadS3Files(props);
  };

  const toggleDummyLoader = (props: boolean) => {
    setDummyLoading(props);
  };

  useEffect(() => {
    setLoading(loading);

    setDummyLoading(dummyLoading);

    getS3File(`Itinerary/${orderData?.data?.id}/`)
      .then((data: any) => {
        setItineraryLinks(data);
      })
      .catch(err => {
        showToast(err, 'error');
      });

    getS3File(`CoverLetter/${orderData?.data?.id}/`)
      .then((data: any) => {
        setCoverLetterLinks(data);
      })
      .catch(err => {
        showToast(err, 'error');
      });

    setLoadS3Files(false);
  }, [loads3Files]);

  const handleDelete = (bucketName: string, key: string) => {
    deleteS3File(bucketName, key)
      .then(data => {
        showToast(data, 'success');
        setLoadS3Files(true);
      })
      .catch(err => {
        showToast(err, 'error');
      });
  };

  let case1 =
    (ItineraryLinks?.content && itineraryStatus) ||
    itineraryStatus === 'Processing-Itinerary' ||
    itineraryStatus === 'Processing-Travel-Documents';
  let case2 =
    (coverLetterLinks?.content && itineraryStatus) ||
    itineraryStatus === 'Processing-Travel-Documents';
  let case3 =
    ItineraryLinks?.content &&
    itineraryStatus !== 'Processing-Itinerary' &&
    ItineraryLinks?.content &&
    itineraryStatus !== 'Processing-Travel-Documents';
  let case4 = coverLetterLinks?.content && itineraryStatus !== 'Processing-Travel-Documents';

  return (
    <div className='payment-summary card'>
      <div className='card-header align-items-center border-0'>
        <h3 className='card-title align-items-start flex-column'>
          <div className='d-flex align-items-center text-gray-800 fw-bolder'>
            <span className='fw-bolder text-dark'>DocuCraft</span>
          </div>
        </h3>
      </div>
      <div className='separator mb-5' />
      {case1 || case2 ? (
        <div className='flex-grow-1 mb-8'>
          <div>
            <table className='table mb-0'>
              <thead>
                <tr className='border-bottom fs-8 fw-bold text-muted'>
                  <th className='text-center'>CREATED ON</th>
                  <th>DOCUMENT TYPE</th>
                  <th className='text-center'>STATUS</th>
                  <th>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {case1 ? (
                  <tr className='fw-bold fs-7 border-bottom'>
                    <td className='text-center'>
                      {case3
                        ? getFormattedDate(
                            ItineraryLinks?.content[0]?.LastModified,
                            OA_DATE_FORMAT_FE
                          )
                        : getFormattedDate(new Date(), OA_DATE_FORMAT_FE)}
                    </td>
                    <td>Travel Itinerary</td>
                    <td className='text-center'>
                      {itineraryStatus === 'Processing-Itinerary' ||
                      itineraryStatus === 'Processing-Travel-Documents' ? (
                        <span className='badge badge-light-primary'> Processing </span>
                      ) : (
                        <span className='badge badge-light-success'> Completed </span>
                      )}
                    </td>
                    {case3 ? (
                      <td>
                        <a
                          href={`https://${ItineraryLinks?.bucketName}.s3.ap-south-1.amazonaws.com/${ItineraryLinks?.content[0]?.Key}`}
                          className='cursor-pointer'>
                          <ViewSVG height='22' width='22' />
                        </a>
                        <span
                          className='ms-3 cursor-pointer'
                          onClick={() =>
                            handleDelete(
                              ItineraryLinks?.bucketName,
                              ItineraryLinks?.content[0]?.Key
                            )
                          }>
                          <DeleteSVG height='22' width='22' />
                        </span>
                      </td>
                    ) : null}
                  </tr>
                ) : null}
                {case2 ? (
                  <tr className='fw-bold fs-7 text-end'>
                    <td className='text-center'>
                      {case4
                        ? getFormattedDate(
                            coverLetterLinks?.content[0]?.LastModified,
                            OA_DATE_FORMAT_FE
                          )
                        : getFormattedDate(new Date(), OA_DATE_FORMAT_FE)}
                    </td>
                    <td className='text-start'>Cover Letter</td>
                    <td className='text-center'>
                      {itineraryStatus === 'Processing-Travel-Documents' ? (
                        <span className='badge badge-light-primary'> Processing </span>
                      ) : (
                        <span className='badge badge-light-success'> Completed </span>
                      )}
                    </td>
                    {case4 ? (
                      <td className='text-start'>
                        <a
                          href={`https://${coverLetterLinks?.bucketName}.s3.ap-south-1.amazonaws.com/${coverLetterLinks?.content[0]?.Key}`}
                          className='cursor-pointer'>
                          <ViewSVG height='22' width='22' />
                        </a>
                        <span
                          className='ms-3 cursor-pointer'
                          onClick={() =>
                            handleDelete(
                              coverLetterLinks?.bucketName,
                              coverLetterLinks?.content[0]?.Key
                            )
                          }>
                          <DeleteSVG height='22' width='22' />
                        </span>
                      </td>
                    ) : null}
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
      <div className='d-flex justify-content-center my-20'>
        <div className='mt-7'>
          <DummyDocSVG />
        </div>
        <div className='ms-15'>
          <PremiumSVG />
          <span className='text-warning ms-1'>PREMIUM</span>
          <h3 className='mt-2'>AI DocuCraft</h3>
          <p>
            Enjoy effortless creation of customised travel itineraries
            <br />
            cover letters, & dummy bookings so that you can focus on <br />
            your customer’s experience!
          </p>
          <ul>
            <li>Auto create travel itinerary</li>
            <li>Auto create cover letter</li>
            <li>Create dummy flight bookings</li>
          </ul>
          <BadgeSVG /> <span className='text-primary ms-2'>Free for limited time only</span>
          <div className='d-flex mt-5'>
            <div>
              <OAButton
                onClick={() => setItineraryModalOpen(true)}
                disabled={loading}
                label={loading ? 'Processing your request ...' : 'Create Travel Documents'}
              />
            </div>
            <div className='ms-5'>
              <OAButton
                onClick={() => setDummyModalOpen(true)}
                disabled={dummyLoading}
                label={dummyLoading ? 'Processing your request ...' : 'Create Dummy Flight'}
              />
            </div>
          </div>
        </div>
      </div>

      <OAModalComponent
        className='itinerary-document-modal'
        show={isItineraryModalOpen}
        onHide={toggleItineraryModal}
        header={{
          title: 'Create Travel Documents',
          closeButton: true
        }}
        body={{
          content: (
            <AddItineraryForm
              onHide={toggleItineraryModal}
              orderData={orderData?.data}
              loader={toggleLoader}
              loadFiles={loadFiles}
              status={toggleItineraryStatus}
            />
          )
        }}
      />

      <OAModalComponent
        className='dummy-document-modal'
        show={isDummyModalOpen}
        onHide={toggleDummyModal}
        header={{
          title: 'Create a Dummy Flight',
          closeButton: true
        }}
        body={{
          content: (
            <DummyForm
              onHide={toggleDummyModal}
              orderData={orderData?.data}
              dummyLoader={toggleDummyLoader}
            />
          )
        }}
      />
    </div>
  );
}
