import moment from 'moment';
import { capitalizeFirstLetterOnly, getStateAbbr } from '../helper';
import { ITraveller } from '../pages/order-management/order-create/components/AddTravellers/types';

const ocrResponseGenerater = (res: any, inits: ITraveller, formState?: any): ITraveller => {
  const { requiredFields = [], optionalFields = [] } = formState?.travellerFormFields || {};

  const passport = res.passport || {};

  const traveller: any = {
    ...inits,
    title: res?.title || '',
    firstName: capitalizeFirstLetterOnly(res?.firstName) || '',
    lastName: capitalizeFirstLetterOnly(res?.lastName) || '',
    dateOfBirth: res?.dateOfBirth ? moment(new Date(res?.dateOfBirth)) : '',
    gender: capitalizeFirstLetterOnly(res?.gender) || '',
    birthPlace: capitalizeFirstLetterOnly(res?.birthPlace) || '',
    fatherName: res?.fatherName || '',
    nationality: res?.nationality ? res?.nationality?.slice(0, 2) : 'IN',
    passportNumber: passport?.number || '',
    passportIssuingCountry: passport?.issuingCountry || '',
    passportIssuingPlace: passport?.issuingPlace || '',
    passportIssuingDate: passport?.issuingDate ? moment(new Date(passport?.issuingDate)) : '',
    passportExpiration: passport?.expiration ? moment(new Date(res?.passport?.expiration)) : '',
    line1: capitalizeFirstLetterOnly(res?.address?.line1) || '',
    state: res?.address?.state ? getStateAbbr(res?.address?.state) : '',
    postalCode: res?.address?.postalCode || '',
    maritalStatus: res?.maritalStatus || '',
    motherName: res?.motherName || ''
  };

  const filteredTraveller: any = {};

  for (const key in traveller) {
    if (requiredFields?.includes(key) || optionalFields?.includes(key)) {
      filteredTraveller[key] = traveller[key];
    }
  }

  return filteredTraveller;
};

export default ocrResponseGenerater;
