import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const OAPagination = ({
  totalPages,
  pageIndex,
  onPageChange
}: {
  totalPages: number;
  pageIndex: number;
  onPageChange: () => void;
}) => {
  return (
    <Stack spacing={2} className='float-end mt-3'>
      <Pagination
        count={totalPages}
        page={pageIndex}
        onChange={onPageChange}
        shape='rounded'
        color='primary'
        size='small'
      />
    </Stack>
  );
};

export default OAPagination;
