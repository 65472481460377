import moment from 'moment';
import { useAuth } from '../auth';

const RemainingDaysMessage = ({ accountCreationDate }: { accountCreationDate: string }) => {
  const creationDate = moment(accountCreationDate).startOf('day');
  const today = moment().startOf('day');
  const daysSinceCreation = today.diff(creationDate, 'days');
  const daysRemaining = 3 - daysSinceCreation;

  let message;
  if (daysRemaining > 0) {
    message = `- ${daysRemaining} days left`;
  } else {
    message = '- Deactivating today';
  }
  return <span>{message}</span>;
};

const HighLightTestMode = () => {
  const { currentUser } = useAuth();
  const isGuestUser = currentUser?.data?.role === 'GUEST_USER';
  const subscriptionStatus = currentUser?.data?.subscription?.find(
    (obj: any) => obj?.type === 'VISA_INFORMATION'
  )?.status;
  return (
    <div className='highlight-test-mode-container'>
      <div className='horizontal-line'></div>
      <div className='content'>
        <div className='trapezoid'>
          TEST MODE{' '}
          {isGuestUser && subscriptionStatus !== 'ACTIVE' && (
            <RemainingDaysMessage accountCreationDate={currentUser?.data?.createdAt} />
          )}
        </div>
      </div>
    </div>
  );
};

export default HighLightTestMode;
