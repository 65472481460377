import { Moment } from 'moment';
import { getFormattedDate } from '../helper';

//Helper function
const getFormattedDateRange = (
  start: Date | Moment | string,
  end?: Date | Moment | string | undefined,
  format?: string
): string | undefined => {
  if (!start) return;

  const startTemp = start ? getFormattedDate(start, format) : '';
  const endTemp = end ? getFormattedDate(end, format) : '';

  return [startTemp, endTemp].filter(Boolean).join(' - ');
};

export default getFormattedDateRange;
