import classNames from 'classnames';
import { KTSVG } from '../../_metronic/helpers';
import OAButton from '../widgets/OAButton';

interface IAlertComponentProps {
  type: 'primary' | 'danger' | 'info' | 'warning';
  title: string;
  subtitle?: string;
  dismissible?: boolean;
  border?: 'basic' | 'dashed';
  isPulse?: boolean;
  actionProps?: {
    onClick?: () => void;
    buttonText?: string;
  };
}

export default function AlertComponent(props: IAlertComponentProps) {
  const { type = 'primary', dismissible = false, border = 'basic', isPulse = false } = props;

  return (
    <div
      className={classNames(
        `alert-component alert bg-light-${type} ${dismissible && `alert-dismissible`} ${
          !!border && `border border-${type} border-${border}`
        } flex-sm-row p-5 mb-4`
      )}>
      <div className='alert-component--body'>
        <div className={`btn btn-icon pulse pulse-${type} me-4 p-0`}>
          <KTSVG
            path='/media/icons/duotune/general/gen044.svg'
            className={classNames(`svg-icon-${type} svg-icon-2hx`)}
          />
          {isPulse && <span className='pulse-ring'></span>}
        </div>
        <div className={classNames(`d-flex flex-column text-${type} pe-0 pe-sm-10`)}>
          <h5 className='mb-1 text-dark'>{props?.title}</h5>
          {props?.subtitle ? <span className='text-gray-700'>{props?.subtitle}</span> : null}
        </div>
      </div>
      {props?.actionProps?.buttonText && (
        <span
          className={classNames(`alert-component--action svg-icon svg-icon-1 svg-icon-${type}`)}>
          <OAButton
            label={props?.actionProps?.buttonText}
            color={`outline-${type}`}
            className={`btn-outline btn-active-${type} me-3`}
            onClick={props?.actionProps?.onClick}
          />
        </span>
      )}
    </div>
  );
}
