import classNames from 'classnames';
import { useContext } from 'react';
import { Accordion, Badge, Card, AccordionContext, useAccordionButton } from 'react-bootstrap';
import { ArrowSVG } from '../../../../../components/Icons';
import { IMember } from '../../../../../types';

interface ICustomAccordionItem {
  eventKey: string;
  title: string;
  isPrimary: boolean;
  count?: string | number;
  uploadCount?: string | number;
  member?: IMember;
  children: React.ReactNode;
}

const CustomAccordionItem: React.FC<ICustomAccordionItem> = ({
  eventKey,
  title,
  isPrimary,
  count,
  uploadCount,
  member,
  children
}) => {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, e => {
    e.currentTarget.classList.toggle('active');
  });

  const isCurrentEventKey = activeEventKey === eventKey;
  const headerInnerWrapperClassNames = classNames('d-flex align-items-center rotate', {
    active: isCurrentEventKey
  });

  return (
    <Card className='p-3'>
      <Card.Header
        onClick={decoratedOnClick}
        className='border-0 cursor-pointer min-h-50px px-0 align-items-center'>
        <div className={headerInnerWrapperClassNames}>
          <ArrowSVG />
          <h5 className='m-0 ps-3'>{title}</h5>
          <div className='d-flex'>
            {isPrimary && (
              <Badge bg='success' className='ms-4'>
                Primary
              </Badge>
            )}
          </div>
        </div>
        <div className='text-muted'>
          {uploadCount === count ? 'Upload complete' : `(${uploadCount}/${count} Pending)`}
        </div>
      </Card.Header>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body className='p-3 rounded'>{children}</Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default CustomAccordionItem;
