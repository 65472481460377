import { Route, Routes } from 'react-router-dom';
import UserProvider from '../user-management/context/UserProvider';
import { FlightsDashboard } from './FlightsDashboard';
import FlightProvider from './context/FlightProvider';
import { FlightOrderView } from './FlightsDashboard/flightOrderView';
import { IQueryParams } from '../Dashboard';

export const FlightsManagement = (props: IQueryParams) => {
  return (
    <UserProvider>
      {/**
       * NOTE: OrderProvider is used to provide order context to all it's child
       * components...
       */}
      <FlightProvider data={props}>
        <Routes>
          <Route path='/' element={<FlightsDashboard data={props} />} />
          <Route path=':id/*' element={<FlightOrderView />} />
          {/* <Route index element={<Navigate to={`/apps/flights`} />} /> */}
        </Routes>
      </FlightProvider>
    </UserProvider>
  );
};
