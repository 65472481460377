export const handleImageZoom = (e: MouseEvent, img: HTMLImageElement, zoom: boolean) => {
  if (img) {
    const currentTarget = e.currentTarget as HTMLElement;
    img.style.transformOrigin = zoom
      ? `${e.clientX - currentTarget.getBoundingClientRect().left}px ${
          e.clientY - currentTarget.getBoundingClientRect().top
        }px`
      : 'center center';
    img.style.transform = zoom ? 'scale(2)' : 'scale(1)';
  }
};

export const handleImageEvents = (imgContainerArray: HTMLDivElement[], addListeners: boolean) => {
  imgContainerArray.forEach((imgContainer: HTMLDivElement) => {
    const img = imgContainer.getElementsByTagName('img')[0] as HTMLImageElement;
    if (addListeners) {
      imgContainer.addEventListener(
        'mousemove',
        (e: MouseEvent) => handleImageZoom(e, img, true),
        false
      );
      imgContainer.addEventListener(
        'mouseout',
        (e: MouseEvent) => handleImageZoom(e, img, false),
        false
      );
    } else {
      imgContainer.removeEventListener(
        'mousemove',
        (e: MouseEvent) => handleImageZoom(e, img, true),
        false
      );
      imgContainer.removeEventListener(
        'mouseout',
        (e: MouseEvent) => handleImageZoom(e, img, false),
        false
      );
    }
  });
};
