import { Modal } from 'react-bootstrap';
import FileRobotEditor from './FileRobotEditor';

interface IImageEditorModal {
  isOpen: boolean;
  setIsOpen: any;
  onHide: () => void;
  targetImg?: string;
  imgFile?: File | null;
  fileUploadHandler: (file: any) => void;
  setIsImgUploading?: any;
}

const ImageEditorModal = (props: IImageEditorModal) => {
  return (
    <Modal show={props?.isOpen} onHide={props?.onHide} size='lg' backdrop='static'>
      <Modal.Body style={{ height: '750px' }}>
        <FileRobotEditor
          closeEditor={props?.setIsOpen}
          targetImg={props?.targetImg}
          imgFile={props?.imgFile}
          fileUploadHandler={props?.fileUploadHandler}
          setIsImgUploading={props?.setIsImgUploading}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ImageEditorModal;
