import { useEffect, useState } from 'react';

import ApiKeyContext, { IApiKeyContext } from './ApiKeyContext';
import { IUserFormValues } from '../types';
import API from '../../../helper/API';
import { showToast } from '../../../helper';
import { IPageInfo } from '../../../components/OATableComponent';

interface IUserProvider {
  children: any;
  // TODO: define all types
}

export default function ApiKeyProvider(props: IUserProvider) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [apiKeys, setApiKeys] = useState<Array<any>>([]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedApiKey, setSelectedApiKey] = useState<any>({});

  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    totalCount: 0,
    totalPages: 0,
    count: 0,
    size: 10,
    index: 1
  });

  const fetchApiKeys = async () => {
    setIsLoading(true);

    try {
      const queryString = `?page=${pageIndex - 1}&size=${10}`;
      await API.get(`/app-clients${queryString}`)
        .then(response => {
          const result = response?.data?.data;
          setPageInfo(prev =>
            Object.assign({}, prev, {
              totalCount: result?.totalElements,
              totalPages: result?.totalPages,
              count: result?.numberOfElements
            })
          );

          setApiKeys(result?.content);
          setPageCount(result?.totalPages);
          setIsLoading(false);
        })
        .catch(err => {
          console.error(err);
          setIsLoading(false);
        });
    } catch (error: any) {
      setIsLoading(false);
      throw new Error(`API error: ${error?.message}`);
    }
  };
  /**
   * NOTE: fetch data whenever pageIndex changes happen ...
   */
  useEffect(() => {
    fetchApiKeys();
  }, [pageIndex]);
  /**
   *
   * @param value
   */
  const onPageChange = (value: any) => {
    setPageIndex(value);
    setPageInfo(prev =>
      Object.assign({}, prev, {
        index: value
      })
    );
  };

  const toggleModal = () => setIsOpen(prev => !prev);

  const onClickCreateApiKey = () => {
    setSelectedApiKey({});
    toggleModal();
  };

  const onCreateApiKey = async (values: IUserFormValues, callback?: (success: boolean) => void) => {
    try {
      await API.post(`/app-clients`, values)
        .then(_response => {
          /**
           * NOTE: if page index is initial index then no need to set
           * page index again, instead call fetchApiKeys method else change
           * page index to initial index so that useEffect detects
           * pageIndex change and refetch user data...
           */
          pageIndex === 1 ? fetchApiKeys() : setPageIndex(1);
          showToast('API key added successfully.', 'success');
          /**
           * NOTE: call callback method by adding a null check as it is
           * not a required parameter ...
           */
          callback?.(true);
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
          callback?.(false);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const onSelectApiKey = async (userId: string) => {
    try {
      await API.get(`app-clients/secret/${userId}`)
        .then(response => {
          const result = response?.data?.data;
          setSelectedApiKey(result);
          toggleModal();
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const onDeleteApiKey = async (userId: string, callback?: () => void) => {
    try {
      await API.delete(`/app-clients/${userId}`)
        .then(_response => {
          /**
           * NOTE: Edit user will happen on the same page so here no need
           * to change page index. Only refetching user data will work
           * for this case ...
           */
          fetchApiKeys();
          showToast('API key deleted successfully.', 'success');
          /**
           * NOTE: call callback method by adding a null check as it is
           * not a required parameter ...
           */
          callback?.();
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const contextValue: IApiKeyContext = {
    isLoading,
    apiKeys,
    pageIndex,
    pageCount,
    isOpen,
    selectedApiKey,
    pageInfo,
    fetchApiKeys,
    onPageChange,
    toggleModal,
    onClickCreateApiKey,
    onCreateApiKey,
    onSelectApiKey,
    onDeleteApiKey
  };

  return <ApiKeyContext.Provider value={contextValue}>{props?.children}</ApiKeyContext.Provider>;
}
