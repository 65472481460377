import { useEffect, useState } from 'react';
import { Box, Divider } from '@mui/material';
import classNames from 'classnames';
import { OATypography } from '../../../components/widgets';
import Customer from './PaymentSummaryTabs/Customer';
import Supplier from './PaymentSummaryTabs/Supplier';
import { SUPPLIER_NAME } from '../../../constants';

interface IPaymentSummary {
  data: any;
  modalOpen: boolean;
}
export default function PaymentSummary(props: IPaymentSummary) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tabs, setTabs] = useState({
    customer: false,
    cleartrip: false
  });

  useEffect(() => {
    setTabs({
      customer: true,
      cleartrip: false
    });
  }, []);

  const handleTabs = (navHeader: string) => {
    if (navHeader === 'customer') {
      setTabs({
        customer: true,
        cleartrip: false
      });
    }

    if (navHeader === 'cleartrip') {
      setTabs({
        customer: false,
        cleartrip: true
      });
    }
  };

  let customerActive = classNames('nav-link justify-content-center text-active-gray-800', {
    active: tabs?.customer
  });

  let cleartripActive = classNames('nav-link justify-content-center text-active-gray-800', {
    active: tabs?.cleartrip
  });

  return (
    <div className='order-view--activity card pb-6'>
      <OATypography variant='h4' sx={{ padding: '16px' }}>
        Payment Summary
      </OATypography>
      <div className='card-toolbar m-0 order-search'>
        <ul className='nav nav-stretch nav-line-tabs border-transparent' role='tablist'>
          <Box display='flex' paddingLeft='24px'>
            <li className='nav-item' role='presentation'>
              <a className={customerActive} onClick={() => handleTabs('customer')}>
                <span className='me-1 fw-bold fs-7'>Customer</span>
              </a>
            </li>
            <li className='nav-item' role='presentation'>
              <a className={cleartripActive} onClick={() => handleTabs('cleartrip')}>
                <span className='me-1 fw-bold fs-7'>
                  {SUPPLIER_NAME[props?.data?.supplierInfo?.supplierCode]}
                </span>
              </a>
            </li>
          </Box>
        </ul>
      </div>
      <Divider />
      {tabs?.customer && <Customer data={props?.data} isLoading={isLoading} />}
      {tabs?.cleartrip && <Supplier data={props?.data} isLoading={isLoading} />}
    </div>
  );
}

export const calculateTotalExcludingCtMarkup = (taxesAndFees: any) => {
  return taxesAndFees?.reduce((accumulator: any, current: { display: string; amount: any }) => {
    if (current.display !== 'Ct Markup') {
      // Condition to exclude "Ct Markup"
      return accumulator + current.amount;
    }
    return accumulator;
  }, 0); // Initialize the accumulator to 0
};
