import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Box } from '@mui/material';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import * as Yup from 'yup';
import { OATypography } from '../../components/widgets';
import { OATextField } from '../../components/fields';
import OAButton from '../../components/widgets/OAButton';
import { useAuth } from '../../auth';
import { getUserByToken, signup } from '../../auth/core/_requests';
import { encryptStorage } from '../../helper/storage';

const inputBoxStyle = {
  'mb': '19px',
  '& label': {
    fontSize: '14px'
  },
  '& input': {
    minHeight: '36px',
    fontSize: '14px'
  }
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(/[^a-zA-Z0-9]/, 'Password must contain at least one special character')
});

const initialValues = {
  email: '',
  password: ''
};

const SignUpModal = () => {
  const navigate = useNavigate();
  const { saveAuth, setCurrentUser } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const handleSubmit = async (values: any, actions: FormikValues) => {
    try {
      setLoading(true);
      const { data: auth } = await signup('Guest User', values.email, 'Guest', values.password);

      saveAuth(auth);
      const { data: user } = await getUserByToken(auth.data.accessToken);

      setCurrentUser(user);
      //setting up live mode by default
      if (user?.data?.organization?.liveModeEnabled === true) {
        localStorage.setItem('oaMode', 'live');
      } else {
        localStorage.setItem('oaMode', 'test');
      }
      encryptStorage.setItem('oa_orgnization_id', user?.data?.organization?.id);

      window.dispatchEvent(new Event('mode_switch'));
      navigate('/apps/visa-information');
    } catch (error: any) {
      setLoading(false);
      setErrorMsg(error?.response?.data?.errors);
    }
  };

  return (
    <Box sx={{ px: '16px' }}>
      {errorMsg && (
        <Alert
          severity='error'
          sx={{
            backgroundColor: theme => theme?.palette?.error?.light,
            color: theme => theme?.palette?.grey?.[400],
            mb: '16px'
          }}>
          {errorMsg}
        </Alert>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        {(formikProps: FormikProps<any>) => {
          return (
            <Form>
              <Box sx={inputBoxStyle}>
                <OATextField name='email' label='Work email' type='email' required />
              </Box>
              <Box sx={inputBoxStyle}>
                <OATextField name='password' label='Create Password' type='password' required />
              </Box>
              <Box sx={{ mt: '19px' }}>
                <OAButton
                  type='submit'
                  className='btn btn-lg btn-primary w-100 fs-6'
                  disabled={loading}
                  isLoading={loading}
                  label='Get started'
                  loadingText='Please wait...'
                />
              </Box>
            </Form>
          );
        }}
      </Formik>
      <OATypography
        variant='body2'
        sx={{ color: theme => theme?.palette?.grey?.[400], textAlign: 'center', mt: '16px' }}>
        Already have an account?
        <Link to='/auth/login' className='link-primary fw-bolder' style={{ marginLeft: '5px' }}>
          Log in
        </Link>
      </OATypography>
    </Box>
  );
};

export default SignUpModal;
