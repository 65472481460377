import { ErrorMessage, useField } from 'formik';
import classNames from 'classnames';

import { KTSVG } from '../../_metronic/helpers';

interface IOAFileUploadField {
  name: string;
  onChange: (e: any) => void;
  label?: string;
  doc: string;
  required?: boolean;
  inputClassName?: string;
}

export default function OAFileUploadField(props: IOAFileUploadField) {
  const { label, name, required = false, inputClassName = '', doc } = props;

  const [field, _meta, helpers] = useField(name);
  // eslint-disable-next-line no-unused-vars
  const { onChange, ...restFieldProps } = field;

  const labelClassNames = classNames('form-label', {
    required: doc ? '' : required
  });

  const inputClasses = classNames(
    inputClassName,
    'form-control form-control-lg form-control-solid'
  );

  function onKeyDown(keyEvent: any) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const onChangeHandler = (e: any) => {
    helpers.setValue(e.target.value, true);
    props?.onChange(e);
  };

  return (
    <div className='oa-file-upload-field'>
      {label ? (
        <label htmlFor={name} className={labelClassNames}>
          {label}
        </label>
      ) : null}
      <div className='oa-file-upload-field__input-wrapper'>
        <input
          type='file'
          className={inputClasses}
          onKeyDown={onKeyDown}
          onChange={onChangeHandler}
          {...restFieldProps}
        />
        <KTSVG
          path='/media/icons/duotune/communication/com008.svg'
          className='svg-icon-muted svg-icon-2'
        />
      </div>
      <div className='text-danger'>
        <ErrorMessage name={name} />
      </div>
    </div>
  );
}
