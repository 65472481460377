import { Card } from 'react-bootstrap';

import { CloseSVG } from '../../../../../../components/Icons';

interface IImageView {
  src: any;
  handleRemove: () => void;
}

export default function ImageView(props: IImageView) {
  return (
    <Card className='passport-image-container document-card p-0 border border-dashed border-primary'>
      <div className='passport-image zoom position-relative'>
        <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={props?.src} />
      </div>
      <div onClick={props?.handleRemove}>
        <CloseSVG className='passport-image-icon' />
      </div>
    </Card>
  );
}
