import { Spinner } from 'react-bootstrap';

import ActivityContainer from './ActivityContainer';
import { useOrderContext } from '../../../context/OrderContext';
import { useEffect } from 'react';
import { Skeleton } from '@mui/material';
import { useParams } from 'react-router-dom';

interface OrderActivityProps {
  orderData: {
    data: any;
    isLoading: boolean;
  };
  currentUser?: any;
  dataExchange?: any;
  isGroup: boolean;
}

function OrderActivity(props: OrderActivityProps) {
  const params: any = useParams();
  const { orderActivityLogs, fetchActivityLogs } = useOrderContext();
  const { orderData, isGroup } = props;

  useEffect(() => {
    fetchActivityLogs(params?.id, isGroup);
  }, [orderData?.data]);

  return (
    <div className='order-view--activity card pb-6'>
      <div className='card-header'>
        <h3 className='card-title fw-bolder text-dark'>Activity Logs</h3>
      </div>
      {orderData?.isLoading ? (
        <div className='card-body d-flex align-items-center justify-content-center text-muted fw-bold'>
          <Spinner animation='border' className='me-4' /> Loading...
        </div>
      ) : (
        <div className='card-body position-relative pb-0'>
          {orderActivityLogs?.length > 0 ? (
            <ActivityContainer data={orderActivityLogs} dataExchange={props?.dataExchange} />
          ) : (
            <>
              <Skeleton variant='rounded' width='100%' height={24} sx={{ mb: '26px' }} />
              <Skeleton variant='rounded' width='100%' height={24} sx={{ mb: '26px' }} />
              <Skeleton variant='rounded' width='100%' height={24} sx={{ mb: '26px' }} />
              <Skeleton variant='rounded' width='100%' height={24} sx={{ mb: '26px' }} />
              <Skeleton variant='rounded' width='100%' height={24} sx={{ mb: '26px' }} />
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default OrderActivity;
