import { useState } from 'react';
import { isEmpty } from 'lodash';

import AppContext, { IAppContext } from './AppContext';
import showToast from '../helper/showToast';
import API from '../helper/API';

interface IAppProvider {
  children: any;
  // TODO: define all types
}

export default function AppProvider(props: IAppProvider) {
  const [users, setUsers] = useState<Array<any> | null>(null);
  const [dataExchange, setDataExchange] = useState<{ [key: string]: any }>({});

  const fetchUsers = (orgId?: string, cb?: (res: any) => void) => {
    let base_path = `/users/all`;
    if (!isEmpty(orgId)) {
      base_path = `${base_path}?organizationId=${orgId}`;
    }
    try {
      API.get(base_path)
        .then(response => {
          const result = response?.data?.data;
          setUsers(result);

          cb?.(result);
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const getDataExchangeByType = (
    type: string,
    organizationCode: string | null,
    shouldReturn: boolean = false, // @deprecated
    cb?: (res: any) => void
  ) => {
    let params = `?type=${type}`;
    if (organizationCode) {
      params = `${params}&organizationCode=${organizationCode}`;
    }
    try {
      API.get(`/data-exchange${params}`)
        .then(response => {
          const result = response?.data?.data;
          const removeSpace = type.replace(/\s+/g, '');
          let typeArray = removeSpace.split(',');
          let deArray: any = {};
          typeArray.forEach((key: string) => {
            deArray[key] = result.find((de: any) => de.type === key)?.values;
          });
          setDataExchange(prev => Object.assign({}, prev, deArray));
          cb?.(result);
        })
        .catch(err => {
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const fetchAirportCodes = (cb?: (res: any) => void) => {
    try {
      API.get(`/airport`)
        .then(response => {
          const result = response?.data?.data;

          const draft = result.map((item: any) => ({
            label: `${item?.city} (${item?.iata})`,
            value: item?.iata
          }));
          setDataExchange((prev: any) => Object.assign({}, prev, { airportCodes: draft }));

          cb?.(result);
        })
        .catch(err => {
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const getGitVersionInfo = (cb?: (res: any) => void) => {
    try {
      API.get(`/frontend/releases/latest`)
        .then(response => {
          const result = response?.data?.data;
          cb?.(result);
        })
        .catch(err => {
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const requestAccess = (
    accessType?: 'VISA' | 'VISA INFORMATION' | 'INSURANCE' | 'DASHBOARD',
    cb?: (res: any) => void
  ) => {
    try {
      API.get(`/request/access?type=${accessType}`)
        .then(response => {
          const result = response?.data?.data;
          cb?.(result);
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const contextValue: IAppContext = {
    users,
    dataExchange,
    fetchUsers,
    getDataExchangeByType,
    fetchAirportCodes,
    getGitVersionInfo,
    requestAccess
  };

  return <AppContext.Provider value={contextValue}>{props?.children}</AppContext.Provider>;
}
