import { Box } from '@mui/material';
import OATableComponent from '../../../components/OATableComponent';
import { useMemo } from 'react';
import { paymentColumns } from './paymentColumns';

interface IOrderInvoice {
  tableData: any;
  loading?: any;
}

export default function OrderInvoice(props: IOrderInvoice) {
  const tableColumns = useMemo(() => {
    return paymentColumns(props?.loading);
  }, []);
  return (
    <Box>
      <OATableComponent columns={tableColumns} data={props?.tableData ?? []} hideFooter />
    </Box>
  );
}
