import { FormikHelpers } from 'formik';

import SelectVisaForm from './SelectVisaForm';
import SearchVisaForm from './SearchVisaForm';
import { Paper } from '@mui/material';

interface IAddVisaType {
  onSubmit: (
    values: any,
    type: string,
    actions?: FormikHelpers<any>,
    cb?: ((response?: any) => void) | undefined
  ) => void;
}

export default function AddVisaType(props: IAddVisaType) {
  return (
    <div className='w-100 d-flex flex-column'>
      <Paper variant='outlined' className='add-visa-type w-100 p-6'>
        <SearchVisaForm {...props} />
      </Paper>
      <SelectVisaForm {...props} />
    </div>
  );
}
