import React, { useEffect, useState } from 'react';
import { isUndefined } from 'lodash';

import OAButton from '../../../../../components/widgets/OAButton';
import { useOrderContext } from '../../../context/OrderContext';
import { useAuth } from '../../../../../auth';
import EventModal from './EventModal';
import EventCard from './EventCard';

interface IEventsState {
  active: Array<any>;
  archived: Array<any>;
}

const init_events: IEventsState = {
  active: [],
  archived: []
};

const OrderEvents = () => {
  const { currentUser } = useAuth();
  const { getOrderEventById, setOrderEvents, orderEvents, orderData } = useOrderContext();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [allEvents, setAllEvents] = useState<IEventsState>(init_events);

  useEffect(() => {
    getOrderEventById(orderData?.data?.id);

    return () => {
      setOrderEvents(prev => ({ ...prev, data: [] }));
    };
  }, [orderData?.data?.id]);

  useEffect(() => {
    if (orderEvents?.data?.data?.length) {
      const { data } = orderEvents?.data;
      const activeEvents = data?.filter((obj: any) => obj?.status === 'ACTIVE');
      const archivedEvents = data?.filter((obj: any) => obj?.status === 'DELETED');
      setAllEvents({
        active: activeEvents,
        archived: archivedEvents
      });
    } else setAllEvents(init_events);
  }, [orderEvents]);

  const toggleEventModal = (eventId?: string) => {
    setSelectedEvent(isUndefined(eventId) ? null : eventId);
    setIsOpen(prev => !prev);
  };

  const hasSupplierViewAccess =
    currentUser?.data?.organizationId === orderData?.data?.supplierOrganization?.id;

  return (
    <section className='order-events card'>
      <div className='card-header align-items-center border-0'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bolder text-dark'>Events</span>
        </h3>
        {hasSupplierViewAccess && (
          <OAButton
            label='Create an event'
            color='light-primary'
            onClick={() => toggleEventModal()}
            className='me-3'
          />
        )}
      </div>
      <div className='separator mb-5' />
      <div className='card-body pt-5'>
        {allEvents?.active?.length > 0 ? (
          <React.Fragment>
            {allEvents?.active?.map((item: any, index: number) => (
              <EventCard
                event={item}
                key={index}
                supplierViewAccess={hasSupplierViewAccess}
                toggleEventModal={() => toggleEventModal(item?.id)}
              />
            ))}
          </React.Fragment>
        ) : (
          <p className='text-gray-500 text-center'>No events created yet</p>
        )}
      </div>
      {allEvents?.archived?.length > 0 ? (
        <React.Fragment>
          <div className='separator' />
          <div className='card-header align-items-center border-0'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='fw-bolder text-dark'>Archived Events</span>
            </h3>
          </div>
          <div className='card-body pt-5'>
            {allEvents?.archived?.length > 0 && (
              <React.Fragment>
                {allEvents?.archived?.map((item: any, index: number) => (
                  <EventCard
                    event={item}
                    key={index}
                    supplierViewAccess={hasSupplierViewAccess}
                    toggleEventModal={() => toggleEventModal(item?.id)}
                    archived
                  />
                ))}
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      ) : null}
      <EventModal id={selectedEvent} show={isOpen} onHide={() => toggleEventModal()} />
    </section>
  );
};

export default OrderEvents;
