import { useState, useEffect } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { Col, Container, Row } from 'react-bootstrap';
import * as Yup from 'yup';

import { removeEmpty } from '../../../../../helper';
import { useAppContext } from '../../../../../context/AppContext';
import { useOrderContext } from '../../../context/OrderContext';
import OAButton from '../../../../../components/widgets/OAButton';
import { renderBookingInformation } from '../../components/AddTravellers/travelFormRenderer';

interface IStayDetailsForm {
  onSubmit: (values: any) => void;
  for: 'vfs-thai' | 'dnata-uae';
  onHide?: () => void;
  value?: IStayDetailsFormValues;
}

interface IStayDetailsFormValues {
  propertyType: string;
  propertyName: string;
  propertyAddress: {
    line1: string;
    district: string;
    subDistrict: string;
    city: string;
    postalCode: string | number;
  };
  propertyPhone?: string;
}

const initValues: IStayDetailsFormValues = {
  propertyType: '',
  propertyName: '',
  propertyAddress: {
    line1: '',
    district: '',
    subDistrict: '',
    city: '',
    postalCode: ''
  },
  propertyPhone: ''
};

export const vfsThaiValidationSchema = Yup.object({
  propertyType: Yup.string().label('Stay type').required(),
  propertyName: Yup.string().label('Stay name').required(),
  propertyAddress: Yup.object().shape({
    line1: Yup.string().required('Address Line 1 is required'),
    district: Yup.string().required('District is required'),
    subDistrict: Yup.string().required('Sub-District is required'),
    city: Yup.string().required('City is required'),
    postalCode: Yup.string().required('Postal Code is required')
  })
});

export const dnataUaeValidationSchema = Yup.object({
  propertyAddress: Yup.object().shape({
    line1: Yup.string().required('Address Line 1 is required'),
    district: Yup.string().required('District is required'),
    city: Yup.string().required('City is required')
  }),
  propertyPhone: Yup.string().required('Property phone is required')
});

export default function StayDetailsForm(props: IStayDetailsForm) {
  const { getDataExchangeByType } = useAppContext();
  const { formState } = useOrderContext();

  const [init, setInit] = useState<IStayDetailsFormValues>(initValues);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<any>({
    city: [],
    district: [],
    placeOfStay: []
  });

  useEffect(() => {
    setIsLoading(true);
    const typeStr = 'CITY, DISTRICT, PLACE_OF_STAY';
    const supplierOrganizationCode = formState?.tripDetails?.supplierOrganization?.organizationCode;

    getDataExchangeByType(typeStr, supplierOrganizationCode, true, _res => {
      const draftPlaces = _res?.find((item: any) => item?.type === 'PLACE_OF_STAY')?.values;
      const draftCities = _res
        ?.find((item: any) => item?.type === 'CITY')
        ?.values?.map((item: any) => (item?.label ? item : { ...item, label: item?.value }));
      const draftDistricts = _res
        ?.find((item: any) => item?.type === 'DISTRICT')
        ?.values?.map((item: any) => (item?.label ? item : { ...item, label: item?.value }));
      setOptions({
        city: draftCities,
        district: draftDistricts,
        placeOfStay: draftPlaces,
        supplier: _res[0]?.supplierOrganizationCode
      });
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (props?.value) setInit(props?.value);
  }, [props?.value]);

  const handleSubmit = (values: any) => {
    const draftBookingInformation = {
      bookingInformation: [removeEmpty(values)]
    };

    props?.onSubmit(draftBookingInformation);
    props?.onHide?.();
  };

  const validationSchema =
    props?.for === 'vfs-thai' ? vfsThaiValidationSchema : dnataUaeValidationSchema;

  return (
    <Formik
      initialValues={init}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize>
      {(formikProps: FormikProps<IStayDetailsFormValues>) => {
        const draftOptions = {
          placeOfStay: options.placeOfStay,
          city: options?.city,
          district:
            options?.supplier === 'SUP011'
              ? options?.city
                  ?.find((city: any) => city?.value === formikProps?.values?.propertyAddress?.city)
                  ?.associatedValue?.map((sub: string) => ({ value: sub, label: sub }))
              : options?.district,
          subDistrict:
            options?.supplier === 'SUP011'
              ? options?.district
                  ?.find(
                    (dist: any) => dist?.value === formikProps?.values?.propertyAddress?.district
                  )
                  ?.associatedValue?.map((sub: string) => ({ value: sub, label: sub }))
              : null
        };
        return (
          <Container fluid as={Form} className='p-0'>
            <Row>{renderBookingInformation(formState, draftOptions, formikProps, isLoading)}</Row>
            <Row>
              <Col className='d-grid'>
                <OAButton size='sm' type='submit' label='Submit' />
              </Col>
            </Row>
          </Container>
        );
      }}
    </Formik>
  );
}
