import React, { useState } from 'react';

const OATruncatedText = (props: { text: string; maxLength: number }) => {
  const { text, maxLength } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  const renderText = () => {
    if (isExpanded) {
      return (
        <div>
          {text}
          <span onClick={toggleExpanded}> See Less</span>
        </div>
      );
    } else if (text.length > maxLength) {
      return (
        <div>
          {text.slice(0, maxLength)}...
          <span onClick={toggleExpanded}> See More</span>
        </div>
      );
    }
    return <div>{text}</div>;
  };

  return <div className='oa-truncated-text'>{renderText()}</div>;
};

export default OATruncatedText;
