import { FC } from 'react';
import { Chip } from '@mui/material';
import { SxProps, Theme } from '@mui/system';

interface IOAChip {
  label: string;
  color?: 'primary' | 'secondary' | 'info' | 'warning' | 'success' | 'error';
  sx?: SxProps<Theme>;
}

const OAChip: FC<IOAChip> = ({ label, color = 'primary', sx }) => {
  return (
    <Chip
      label={label}
      size='small'
      sx={{
        'borderRadius': '4px',
        'backgroundColor': theme => theme.palette[color].light,
        'color': theme => theme.palette[color].main,
        '& .MuiChip-label': {
          paddingLeft: '4px',
          paddingRight: '4px'
        },
        ...sx
      }}
    />
  );
};

export default OAChip;
