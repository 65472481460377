/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { FormikHelpers, FormikValues } from 'formik';
import { getPrimaryMemberFirst } from '../../../../../helper';
import { useOrderContext } from '../../../context/OrderContext';
import TravellerDocuments from '../../../order-view/OrderTabs/TravellerInfo/TravellerDocuments';
import CustomAccordionItem from './CustomAccordionItem';
import OAButton from '../../../../../components/widgets/OAButton';
import { IMember } from '../../../../../types';

export default function AddDocuments(props: {
  onSubmit: (
    values: any,
    type: string,
    actions?: FormikHelpers<any>,
    cb?: ((response?: any) => void) | undefined
  ) => void;
  handleStepChange: (actions?: FormikValues) => void;
}) {
  const { orderData, draftOrder: orderInDraft } = useOrderContext();

  const [membersData, setMembersData] = useState<any>([]);
  const [active, setActive] = useState<boolean>(false);

  const draftOrder = orderData?.data ?? orderInDraft;

  useEffect(() => {
    if (draftOrder?.members?.length) {
      const draftMembers = getPrimaryMemberFirst(draftOrder?.members);
      setMembersData(draftMembers);
    }
  }, [draftOrder]);

  const isStickerVisa = draftOrder?.product?.productInformation?.productType === 'Sticker';

  useEffect(() => {
    if (membersData?.length) {
      const isInfoType = membersData
        ?.reduce((acc: any, cur: any) => {
          const draft = cur?.documents?.every((doc: any) => doc?.fieldType === 'info');
          return [...acc, draft];
        }, [])
        ?.every((val: any) => val === true);

      const isDocUploaded = membersData
        ?.reduce((acc: any, cur: any) => {
          const draft = cur?.documents?.every((doc: any) => doc?.uploaded === true);
          return [...acc, draft];
        }, [])
        ?.every((val: any) => val === true);

      setActive(isStickerVisa ? !isDocUploaded : isDocUploaded);
    }
  }, [membersData]);

  const onClickContinue = () => {
    props?.handleStepChange();
  };

  return (
    <div className='d-flex flex-column w-100'>
      <Accordion defaultActiveKey='0'>
        {membersData?.map((member: any, index: number) => {
          return (
            <div className='mb-6'>
              <CustomAccordionItem
                member={member}
                eventKey={index.toString()}
                title={`${member?.firstName} ${member?.lastName}`}
                isPrimary={member?.isPrimary}
                count={member?.documents?.length}
                uploadCount={
                  member?.documents?.filter((item: IMember) => item?.uploaded === true).length
                }>
                <TravellerDocuments memberData={member} orderData={orderData?.data ?? draftOrder} />
              </CustomAccordionItem>
            </div>
          );
        })}
      </Accordion>
      <div className='d-flex justify-content-end pt-10'>
        <OAButton onClick={onClickContinue} label='Continue' />
      </div>
    </div>
  );
}
