import { useState } from 'react';
import API from '../../../helper/API';
import OrgContext, { IOrgContext } from './OrgContext';
import { IOrgFormValues } from '../OrgForm';
import { IPaginationQuery } from '../types';
import { showToast } from '../../../helper';

interface IOrgProvider {
  children: any;
  // TODO: define all types
}

export default function OrgProvider(props: IOrgProvider) {
  const [organizations, setOrganizations] = useState<{
    data: Array<any> | null;
    isLoading: boolean;
  }>({
    data: null,
    isLoading: false
  });
  const [orgQuery, setOrgQuery] = useState<IPaginationQuery>({
    pageIndex: 1,
    pageCount: 0,
    size: 10,
    search: ''
  });
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const [selectedOrg, setSelectedOrg] = useState<any>({});
  const [suppliers, setSuppliers] = useState<{
    data: Array<any> | null;
    isLoading: boolean;
  }>({
    data: null,
    isLoading: false
  });

  const fetchOrgs = async (search?: any, orgId?: string, cb?: (response: any) => void) => {
    setOrganizations(prev => ({ ...prev, isLoading: true }));

    try {
      let queryString = '';

      if (search && search !== '') {
        queryString = `?&search=${search}`;
      }

      if (orgId === 'all') {
        queryString += `${search && search !== '' ? '&' : '?'}orgId=all`;
      }

      await API.get(`/organizations${queryString}`)
        .then(response => {
          const resultData = response?.data?.data;
          setOrganizations(_prev => ({ data: resultData, isLoading: false }));

          setPageCount(resultData?.totalPages);
          cb && cb(resultData);
        })
        .catch(err => {
          setOrganizations(prev => ({ ...prev, isLoading: false }));
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      setOrganizations(prev => ({ ...prev, isLoading: false }));
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const fetchSuppliers = async (search?: string, cb?: (response?: any) => void) => {
    setSuppliers(prev => ({ ...prev, isLoading: true }));

    try {
      let queryString = `?type=SUPPLIER`;

      if (search && search !== '') {
        queryString = `${queryString}&search=${search}`;
      }

      await API.get(`/organizations/all${queryString}`)
        .then(response => {
          const resultData = response?.data?.data;
          setSuppliers(_prev => ({ data: resultData, isLoading: false }));

          cb?.(resultData);
        })
        .catch(err => {
          setSuppliers(prev => ({ ...prev, isLoading: false }));
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      setSuppliers(prev => ({ ...prev, isLoading: false }));
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const onPageChange = (event: any, value: any) => setPageIndex(value);

  const onCreateOrg = async (values: IOrgFormValues, cb?: (response?: any) => void) => {
    const draftRequestBody = getModifiedRequestBody(values);

    try {
      await API.post(`/organizations`, draftRequestBody)
        .then(response => {
          fetchOrgs();
          showToast('Organization added successfully.', 'success');
          cb?.(response);
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
          cb?.();
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const onSelectOrg = (orgId: string, cb?: () => void) => {
    const draftOrg = organizations?.data?.find(org => org?.id === orgId);
    setSelectedOrg(draftOrg);
    cb?.();
  };

  const resetSelected = () => setSelectedOrg({});

  const onEditOrg = async (id: string, values: IOrgFormValues, cb?: (response?: any) => void) => {
    const draftRequestBody = getModifiedRequestBody(values);

    try {
      await API.put(`/organizations/${id}`, draftRequestBody)
        .then(response => {
          fetchOrgs();
          showToast('Organization updated successfully.', 'success');
          cb?.(response);
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
          cb?.();
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const onDeleteOrg = async (id: string, cb?: (success: boolean) => void) => {
    try {
      await API.delete(`/organizations/${id}`)
        .then(_response => {
          fetchOrgs();
          showToast('Organization deleted successfully.', 'success');
          cb?.(true);
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
          cb?.(false);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const contextValue: IOrgContext = {
    organizations,
    suppliers,
    orgQuery,
    selectedOrg,
    fetchOrgs,
    fetchSuppliers,
    setOrgQuery,
    onCreateOrg,
    onSelectOrg,
    resetSelected,
    onEditOrg,
    onDeleteOrg,
    pageIndex, // DEVNOTE: not in use
    pageCount, // DEVNOTE: not in use
    onPageChange // DEVNOTE: not in use
  };

  return <OrgContext.Provider value={contextValue}>{props?.children}</OrgContext.Provider>;
}

// helper functions ...
const getModifiedRequestBody = (values: any) => {
  let draft: any = {
    name: values?.name,
    legalName: values?.legalName,
    email: values?.email,
    type: values?.type,
    countryCode: values?.country,
    ancillaryType: values?.ancillaryType,
    address: {
      line1: values?.addressLine1,
      city: values?.city,
      country: values?.country,
      state: values?.state,
      postalCode: values?.postalCode
    },
    parentOrganizationId: null,
    logo: values?.logo ?? null,
    liveModeEnabled: values?.liveModeEnabled
  };

  if (values?.parentOrganizationId) {
    draft.parentOrganizationId = values?.parentOrganizationId;
  }
  if (values?.associatedOrganization !== '') {
    const draftOrgs = Object.keys(values?.associatedOrganization)
      .map(supKey => {
        const draftAncillaryType = values?.associatedOrganization[supKey]?.ancillaryType;

        return {
          organizationId: supKey,
          ancillaries: Object.keys(draftAncillaryType)
            ?.filter(ancKey => values?.ancillaryType?.includes(ancKey))
            ?.map(ancKey => !!draftAncillaryType[ancKey] && ancKey)
            ?.filter(ancKey => !!ancKey)
            ?.map(anc => ({ ancillaryType: anc }))
        };
      })
      ?.filter(org => org?.ancillaries?.length);
    draft.associatedOrganization = draftOrgs;
  }

  return draft;
};
