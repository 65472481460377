import { FC } from 'react';
import { OATypography } from '../../widgets';

interface ISteps {
  steps: any[];
}

export const Steps: FC<ISteps> = ({ steps }) => {
  return (
    <>
      {steps?.map((item: any, index: number) => {
        return (
          <>
            <OATypography variant='body1' sx={{ mb: '4px' }}>
              {`Step ${item?.number}:`}
            </OATypography>
            <OATypography
              variant='body2'
              sx={{
                mb: steps?.length - 1 === index ? '0' : '16px',
                color: theme => theme?.palette?.grey?.[400]
              }}>
              {item?.description}
            </OATypography>
          </>
        );
      })}
    </>
  );
};
