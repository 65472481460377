/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Select, { components } from 'react-select';
import { isNull } from 'lodash';

import { useAuth } from '../../../auth';
import { useAppContext } from '../../../context/AppContext';
import { UserRoleOptions } from '../constants';

interface IAssignUserModal {
  onHide: () => void;
  show: boolean;
  handleAssignReviewers: (userId: string, cb?: ((res: any) => void) | undefined) => void;
  data?: any;
}

export default function AssignUserModal(props: IAssignUserModal) {
  const { currentUser } = useAuth();
  const { users, fetchUsers } = useAppContext();

  const [value, setValue] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (currentUser) {
      fetchUsers(currentUser?.data?.organization?.id);
    }
  }, [currentUser]);

  useEffect(() => {
    if (props?.data) {
      const draftUser = users?.find((obj: any) => obj?.id === props?.data?.id);
      setValue(() =>
        Object.assign({}, draftUser, {
          label: `${draftUser?.firstName} ${draftUser?.lastName}`,
          value: draftUser?.id
        })
      );
    }
    return () => setValue(null);
  }, [props?.data]);

  let userOptions: Array<any> = [];
  if (!isNull(users) && users?.length) {
    userOptions = users?.map((obj: any) =>
      Object.assign({}, obj, {
        label: `${obj?.firstName} ${obj?.lastName}`,
        value: obj?.id
      })
    );
  }

  const handleAssignUser = () => {
    setIsLoading(true);

    props?.handleAssignReviewers(value?.id, _res => {
      setIsLoading(false);
    });
  };

  const CustomOption = (optionProps: any) => {
    const { data } = optionProps;
    const shortName = data?.firstName?.charAt(0).toUpperCase();
    const userRole = UserRoleOptions?.find((obj: any) => obj?.value === data?.role)?.label;

    return (
      <components.Option {...optionProps}>
        <div className='d-flex align-items-center'>
          <div className='oa-symbol oa-symbol-circle'>
            <span className='oa-symbol-label' style={{ background: 'rgb(11, 183, 131)' }}>
              {shortName}
            </span>
          </div>
          <div className='ms-2 flex-grow-1'>
            <div className='fw-bold text-dark d-block fs-7'>{data?.label}</div>
            <span className='text-muted d-block fs-6'>{data?.email}</span>
          </div>
          <div>
            <span className='badge badge-secondary'>{userRole}</span>
          </div>
        </div>
      </components.Option>
    );
  };

  const isValueChanged: boolean = isNull(value)
    ? false
    : props?.data
    ? props?.data?.id !== value?.id
    : true;

  return (
    <Modal show={props?.show} onHide={props?.onHide} backdrop='static' keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>Assign this order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label htmlFor='assignUser' className='form-label'>
          Search your team members
        </label>
        <Select
          options={userOptions}
          components={{
            Option: CustomOption,
            SingleValue: CustomOption
          }}
          styles={{
            option: base => ({ ...base, borderBottom: '1px solid #eff2f5' })
          }}
          onChange={(val: any) => setValue(val)}
          value={value}
          placeholder='Search by name'
          isClearable={true}
          className='oa-select-container'
          classNamePrefix='oa-select'
          isSearchable={isNull(value)}
        />
      </Modal.Body>
      <Modal.Footer className='border-0 pt-0'>
        <Button size='sm' variant='secondary' disabled={isLoading} onClick={props?.onHide}>
          Cancel
        </Button>
        <Button
          size='sm'
          variant='primary'
          disabled={isLoading || !isValueChanged}
          onClick={handleAssignUser}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
