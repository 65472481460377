import OAButton from '../widgets/OAButton';

interface IActionsCell {
  handlePreview: () => void;
  handleDelete?: () => void;
  handleDownload?: () => void;
  isDeleteDisabled?: boolean;
}

const ActionsCell = (props: IActionsCell) => {
  const { handlePreview, isDeleteDisabled, handleDelete, handleDownload } = props;

  return (
    <>
      <OAButton
        color='light'
        onClick={handlePreview}
        className='btn-icon btn-active-light-primary ms-4'
        icon='/media/icons/duotune/eye.svg'
      />
      <OAButton
        color='light'
        onClick={handleDelete}
        className='btn-icon btn-active-light-primary ms-4'
        icon='/media/icons/duotune/general/delete.svg'
      />
      <OAButton
        color='light'
        onClick={handleDownload}
        className='btn-icon btn-active-light-primary ms-4'
        icon='/media/icons/duotune/Download.svg'
      />
    </>
  );
};

export default ActionsCell;
