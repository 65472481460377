import { Fragment, PropsWithChildren } from 'react';
import Modal, { ModalProps } from 'react-bootstrap/Modal';

export interface IOAModalComponent extends ModalProps {
  headerComponent?: JSX.Element;
  header?: {
    className?: string;
    title?: JSX.Element | string | number;
    closeButton?: boolean;
  };
  bodyComponent?: JSX.Element;
  body?: {
    className?: string;
    content?: JSX.Element | string | number;
  };
  footerComponent?: JSX.Element;
  noHeader?: boolean;
}

export default function OAModalComponent(props: PropsWithChildren<IOAModalComponent>) {
  const {
    children,
    headerComponent,
    header,
    bodyComponent,
    body,
    footerComponent,
    centered = true,
    noHeader = false,
    ...rest
  } = props;

  return (
    <Modal centered={centered} {...rest}>
      {noHeader
        ? null
        : headerComponent ?? (
            <Modal.Header
              className={header?.className ?? ''}
              closeButton={header?.closeButton ?? false}>
              <Modal.Title>{header?.title}</Modal.Title>
            </Modal.Header>
          )}
      {children ?? (
        <Fragment>
          {bodyComponent ?? (
            <Modal.Body className={body?.className ?? ''}>{body?.content}</Modal.Body>
          )}
          {footerComponent ? <Modal.Footer>{footerComponent}</Modal.Footer> : null}
        </Fragment>
      )}
    </Modal>
  );
}
