import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { DataGridPro, GridColDef, GridRowModel } from '@mui/x-data-grid-pro';
import { buildColumns } from './CsvColumns';
import { Box } from '@mui/material';
import { useAppContext } from '../../context/AppContext';
import { ISpaces } from '.';
import { getRowsWithErrors } from './helper';
import { KTSVG } from '../../_metronic/helpers';
import { Moment } from 'moment';
import { OATypography } from '../widgets';
import SuccessSVG from '../Icons/SuccessSvg';

interface ICsvGridEditor {
  spaces: ISpaces;
  setSpaces: Dispatch<SetStateAction<ISpaces>>;
  productCode?: string;
  hasErrors: any;
  setHasErrors: Dispatch<SetStateAction<boolean>>;
  tripStartDateMin?: Moment;
  isRowCellEmpty: boolean;
  setIsRowCellEmpty: (isRowEmpty: boolean) => void;
  apiRef?: any;
}

const CsvGridEditor: FC<ICsvGridEditor> = ({
  spaces,
  setSpaces,
  hasErrors,
  setHasErrors,
  tripStartDateMin,
  isRowCellEmpty,
  setIsRowCellEmpty,
  apiRef
}) => {
  const { dataExchange } = useAppContext();

  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [cellInfo, setCellInfo] = useState<any>();

  useEffect(() => {
    if (spaces?.columns && spaces?.columns?.length > 0) {
      const tempColumns = buildColumns(spaces, dataExchange, tripStartDateMin);

      // Add the id column
      tempColumns.unshift({
        field: 'id',
        headerName: '',
        minWidth: 40,
        maxWidth: 40,
        editable: false,
        align: 'center',
        cellClassName: params => 'data-grid-id-cell data-grid-cell-first-col'
      });
      setColumns(tempColumns);
    }
  }, [spaces]);

  useEffect(() => {
    if (columns.length > 0 && spaces?.rows) {
      const { errorRows, isRowEmpty } = getRowsWithErrors(spaces?.rows, columns);
      setHasErrors(errorRows);
      setIsRowCellEmpty(isRowEmpty);
    }
  }, [columns, spaces?.rows]);

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow };
    const mapRows = spaces?.rows?.map(row => (row.id === newRow.id ? updatedRow : row));
    setSpaces((prev: any) => ({
      ...prev,
      rows: mapRows
    }));
    return updatedRow;
  };

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 46 || event.keyCode === 8) {
      if (cellInfo && cellInfo?.isEditable) {
        return;
      }
      if (selectedRows?.length > 0) {
        let transformedRows = spaces?.rows?.filter((obj: any) => !selectedRows.includes(obj?.id));
        transformedRows?.map((row: any, index: number) => {
          row['id'] = index + 1;
          if (row['orderNumber']) {
            row['orderNumber'] = `${index + 1}`;
          }
        });
        setSpaces((prev: any) => ({
          ...prev,
          rows: transformedRows
        }));
      }
    }
  };
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedRows]);

  return (
    <>
      {isRowCellEmpty ? null : hasErrors?.length > 0 ? (
        <OATypography variant='body1' className='text-danger text-break' sx={{ my: 2 }}>
          <KTSVG
            path='/media/icons/duotune/general/gen044.svg'
            className='svg-icon-danger svg-icon-3 me-1'
          />
          {`Rows ${hasErrors} contain errors`}
        </OATypography>
      ) : (
        <OATypography variant='body1' className='text-success' sx={{ my: 2 }}>
          <SuccessSVG />
          All good! Order is ready to be submitted
        </OATypography>
      )}

      <Box sx={{ height: 400, width: '100%' }}>
        <DataGridPro
          apiRef={apiRef}
          rows={spaces?.rows || []}
          columns={columns || []}
          density='compact'
          rowHeight={30}
          columnHeaderHeight={34}
          localeText={{
            noRowsLabel: 'Please select "Order type" and "Insurance type"'
          }}
          classes={{
            columnHeader: 'no-sorting-icons' // Apply a custom class
          }}
          getRowId={row => row?.id}
          processRowUpdate={processRowUpdate}
          // Enable keyboard navigation
          checkboxSelection={false}
          rowSelectionModel={selectedRows}
          onRowSelectionModelChange={newSelection => {
            setSelectedRows(newSelection);
          }}
          onCellClick={event => {
            setCellInfo(event);
          }}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          rowSelection
          sx={{
            '& .MuiDataGrid-cell': {
              borderRight: '0.5px solid #dcdcdc',
              borderLeft: '0.5px solid #dcdcdc'
            },
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: '#EEEEEE',
              borderRight: '1px solid #dcdcdc',
              height: '30px', // Set the custom height here
              lineHeight: '30px', // Adjust the line height to center the text vertically
              padding: '0 10px', // Adjust padding as needed
              fontSize: '10px',
              fontWeight: 600,
              color: '#011229',
              textTransform: 'uppercase'
            },
            '& .MuiDataGrid-sortIcon': {
              display: 'none'
            },
            '& .MuiDataGrid-footerContainer': {
              minHeight: '35px'
            },
            '& .MuiDataGrid-overlayWrapper': {
              zIndex: 0
            }
          }}
        />
      </Box>
    </>
  );
};

export default CsvGridEditor;
