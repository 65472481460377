const getFileExtensionFromMimeType = (mimeType: string): string => {
  const mimeTypeMap: Record<string, string> = {
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'image/gif': 'gif',
    'image/svg+xml': 'svg',
    'application/pdf': 'pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'application/zip': 'zip'
    // add more MIME types and extensions as needed
  };

  return mimeTypeMap[mimeType] || '';
};

export default getFileExtensionFromMimeType;
