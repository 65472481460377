import { OA_DATE_FORMAT_BE } from '../../../constants';
import { getFormattedDate, removeEmpty } from '../../../helper';
import { ORDER_TYPE } from '../constants';
import { IOrderType } from '../types';

export const getTravellerRequest = (type: IOrderType, traveller: any, tripDetails?: any) => {
  const isVisa = type === ORDER_TYPE.VISA;
  const isInsurance = type === ORDER_TYPE.INSURANCE;

  let passportDetails: any;
  if (isInsurance && traveller?.nationality === 'IN' && tripDetails?.domestic === true) {
    passportDetails = null;
  } else {
    passportDetails = {
      issuingCountry: traveller?.passportIssuingCountry ?? traveller?.passport?.issuingCountry,
      issuingPlace: traveller?.passportIssuingPlace ?? traveller?.passport?.issuingPlace,
      number: traveller?.passportNumber ?? traveller?.passport?.number,
      expiration: getFormattedDate(traveller?.passportExpiration, OA_DATE_FORMAT_BE),
      issuingDate: getFormattedDate(traveller?.passportIssuingDate, OA_DATE_FORMAT_BE)
    };
    if (traveller?.oldPassportCount) {
      passportDetails['oldPassportCount'] = traveller?.oldPassportCount;
    }
  }

  let draftTraveller: any = {
    title: traveller?.title,
    firstName: traveller?.firstName,
    lastName: traveller?.lastName,
    dateOfBirth: getFormattedDate(traveller?.dateOfBirth, OA_DATE_FORMAT_BE),
    email: traveller?.email,
    gender: traveller?.gender,
    phone: traveller?.phone,
    nationality: traveller?.nationality,
    passport: passportDetails,
    address: {
      line1: traveller?.line1 ?? traveller?.address?.line1,
      state: traveller?.state ?? traveller?.address?.state,
      postalCode: traveller?.postalCode ?? traveller?.address?.postalCode,
      country: traveller?.country ?? traveller?.address?.country
    },
    primary: traveller?.primary,
    occupation: isVisa ? traveller?.occupation : '',
    id: !traveller?.id?.includes('new_member_') ? traveller?.id : '',
    birthCountry: traveller?.birthCountry ?? '',
    birthPlace: traveller?.birthPlace ?? '',
    fatherName: traveller?.fatherName ?? '',
    maritalStatus: traveller?.maritalStatus ?? '',
    religion: traveller?.religion ?? '',
    profession: traveller?.profession ?? '',
    motherName: traveller?.motherName ?? '',
    annualIncome: traveller?.annualIncome ?? '',
    education: traveller?.education ?? '',
    previousNationality: traveller?.previousNationality ?? ''
  };
  /**
   * NOTE: if "traveller" contains "pnrNumber" and "ticketNumber" then add it in "draftTraveller" ...
   */
  if (traveller?.pnrNumber || traveller?.ticketNumber) {
    draftTraveller.ticketDetails = [
      {
        pnrNumber: traveller?.pnrNumber,
        ticketNumber: traveller?.ticketNumber
      }
    ];
  }

  if (
    traveller?.PassportFrontImage ||
    traveller?.PassportBackImage ||
    traveller?.PassportBioPage ||
    traveller?.PassportLastPage
  ) {
    let draftDocs = [];
    if (traveller?.PassportFrontImage) {
      draftDocs.push({
        fieldName: traveller?.PassportFrontImage?.keyName,
        data: traveller?.PassportFrontImage?.image,
        fileExtension: 'jpg',
        fileName: 'PassportFrontImage.jpg'
      });
    }
    if (traveller?.PassportBioPage) {
      draftDocs.push({
        fieldName: traveller?.PassportBioPage?.keyName,
        data: traveller?.PassportBioPage?.image,
        fileExtension: 'jpg',
        fileName: 'PassportBioPage.jpg'
      });
    }
    if (traveller?.PassportBackImage) {
      draftDocs.push({
        fieldName: traveller?.PassportBackImage?.keyName,
        data: traveller?.PassportBackImage?.image,
        fileExtension: 'jpg',
        fileName: 'PassportBackImage.jpg'
      });
    }
    if (traveller?.PassportLastPage) {
      draftDocs.push({
        fieldName: traveller?.PassportLastPage?.keyName,
        data: traveller?.PassportLastPage?.image,
        fileExtension: 'jpg',
        fileName: 'PassportLastPage.jpg'
      });
    }
    draftTraveller.documents = draftDocs;
  }

  if (traveller?.documents && traveller?.documents?.length > 0) {
    draftTraveller.documents =
      draftTraveller?.documents?.length > 0
        ? [...draftTraveller.documents, ...traveller?.documents]?.filter(
            (document, index, self) =>
              index === self?.findIndex(t => t?.fieldName === document?.fieldName)
          )
        : traveller?.documents;
  }
  /**
   * NOTE: if "traveller" contains "travelDetails" then add it in "draftTraveller" ...
   */
  let draftTravelDetails = [];
  if (traveller?.arrivalCarrierName) {
    draftTravelDetails.push({
      travelType: 'ARRIVAL',
      carrierName: traveller?.arrivalCarrierName,
      carrierNumber: traveller?.arrivalCarrierNumber,
      pnrNumber: traveller?.arrivalPnrNumber,
      iata: traveller?.arrivalIATA
    });
  }
  if (traveller?.departureCarrierName) {
    draftTravelDetails.push({
      travelType: 'DEPARTURE',
      carrierName: traveller?.departureCarrierName,
      carrierNumber: traveller?.departureCarrierNumber,
      pnrNumber: traveller?.departurePnrNumber,
      iata: traveller?.departureIATA
    });
  }
  if (draftTravelDetails?.length) {
    draftTraveller['travelDetails'] = draftTravelDetails;
  }

  return removeEmpty(draftTraveller);
};
