import classNames from 'classnames';

export default function OABadge(props: {
  label: string | JSX.Element;
  type:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'light-primary'
    | 'light-success'
    | 'light-info'
    | 'light-warning'
    | 'light-danger';
  className?: string;
}) {
  const { label, type = 'primary', className = '' } = props;
  const badgeClassNames = classNames('badge', className, `badge-${type}`);

  return <div className={badgeClassNames}>{label}</div>;
}
