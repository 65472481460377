// import { Button } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
// import { useAuth } from '../../../auth';
import { DATE_TIME_FORMAT_DISPLAY_FE } from '../../../constants';
import { getDateFormat } from '../../../helper';
import { formatNumberForCurrency } from '../../../helper';
import { KTCard, KTCardBody, KTSVG } from '../../../_metronic/helpers';
import AddBalanceModal from '../Create/AddBalanceModal';
import OAButton from '../../../components/widgets/OAButton';
import { useAuth } from '../../../auth';
import { useContext, useEffect, useState } from 'react';
import LedgerContext from '../context/LedgerContext';
import { UserRoleOptions } from '../../order-management/constants';

interface IAccountBalance {
  toggle: () => void;
  isOpen: boolean;
  isRefresh: boolean;
  getBalance: () => void;
}

const AccountBalance = (props: IAccountBalance) => {
  const { toggle, isOpen, isRefresh, getBalance } = props;
  const { ledgerBalance, getLedgerInfo } = useContext(LedgerContext);
  const { currentUser } = useAuth();
  const [ledgerInfo, setLedgerInfo] = useState<any>(null);
  const userRole = UserRoleOptions?.find(
    (obj: any) => currentUser?.data?.role === obj?.value
  )?.value;

  useEffect(() => {
    getLedgerInfo(res => {
      setLedgerInfo(res);
    });
  }, []);

  return (
    <>
      <KTCard className='mb-8'>
        <div className='card-header min-h-50px align-items-center px-6 py-4'>
          <h3 className='m-0'>Account balance</h3>
          {userRole === 'ROLE_ORGANIZATION_ADMIN' &&
          currentUser?.data?.organization?.isPaymentGatewayActive &&
          ledgerInfo?.active ? (
            <OAButton onClick={toggle} label='Add Balance' />
          ) : null}
        </div>
        <KTCardBody className='px-6 py-5'>
          <div className='d-flex align-items-center gap-20'>
            <LabelValue
              label='Balance(INR)'
              value={
                ledgerBalance?.balance ? formatNumberForCurrency(ledgerBalance?.balance) : 'N/A'
              }
              isRefresh={isRefresh}
            />
            <LabelValue
              label='Created At'
              value={
                ledgerBalance?.createdAt
                  ? getDateFormat(new Date(ledgerBalance?.createdAt), DATE_TIME_FORMAT_DISPLAY_FE)
                  : 'N/A'
              }
              isRefresh={isRefresh}
            />

            <div className='ms-auto'>
              <span className='fw-bold text-muted fs-7'>
                Last updated on{' '}
                {ledgerBalance?.updatedAt
                  ? getDateFormat(new Date(ledgerBalance?.updatedAt), DATE_TIME_FORMAT_DISPLAY_FE)
                  : 'N/A'}
              </span>
              <button
                onClick={getBalance}
                className='btn btn-icon btn-light-primary btn-sm ms-2'
                disabled={isRefresh}>
                {isRefresh ? (
                  <span className='spinner-border spinner-border-sm align-middle'></span>
                ) : (
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr029.svg'
                    className='svg-icon-3 svg-icon-gray-600'
                  />
                )}
              </button>
            </div>
          </div>
        </KTCardBody>
      </KTCard>
      <AddBalanceModal isOpen={isOpen} toggle={toggle} />
    </>
  );
};

export default AccountBalance;

const LabelValue = ({
  label,
  value,
  isRefresh
}: {
  label: string;
  value: string;
  isRefresh: boolean;
}) => (
  <div>
    <div className='fw-bold text-muted d-block fs-7'>{label}</div>
    <div className='text-dark fw-bold fs-6 text-capitalize'>
      {isRefresh ? <Skeleton height={14} width={100} /> : value}
    </div>
  </div>
);
