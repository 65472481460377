import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import classNames from 'classnames';
import { Box } from '@mui/material';

import { KTSVG } from '../../_metronic/helpers';

export interface IOAButton {
  type?: 'submit' | 'button' | 'reset';
  size?: 'sm' | 'lg';
  className?: string;
  onClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  loadingText?: string;
  label?: any;
  icon?: string | undefined;
  iconComponent?: JSX.Element;
  rightIcon?: string | null | undefined;
  rightIconHover?: any;
  iconClassName?: string;
  color?:
    | 'light'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'dark'
    | 'light-primary'
    | 'light-secondary'
    | 'light-success'
    | 'light-info'
    | 'light-warning'
    | 'light-danger'
    | 'light-dark'
    | 'outline-default'
    | 'outline-primary'
    | 'outline-success'
    | 'outline-info'
    | 'outline-warning'
    | 'outline-danger'
    | 'outline-dark';
}

// TODO: get button variant by it's color and pass ti react-bootstrap button component

export default function OAButton(props: IOAButton) {
  const [hover, setHover] = useState(false);

  const {
    type = 'button',
    size = 'sm',
    className = '',
    onClick,
    isLoading = false,
    disabled = false,
    loadingText = 'loading...',
    label = '',
    icon,
    rightIcon,
    rightIconHover,
    iconClassName = 'svg-icon-4',
    color = 'primary'
  } = props;

  const btnClasses = classNames(className, getClassNames(color));

  const handleMouseEnter = () => setHover(true);

  const handleMouseLeave = () => setHover(false);

  return (
    <Button
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      type={type}
      size={size}
      onClick={onClick}
      disabled={disabled || isLoading}
      variant={color}
      className={btnClasses}>
      {icon && !isLoading && <KTSVG path={icon} className={iconClassName} />}
      {!icon && props?.iconComponent && !isLoading && (
        <Box display='inline-block' marginRight='4px'>
          {props?.iconComponent}
        </Box>
      )}
      {isLoading ? (
        <React.Fragment>
          {loadingText}
          <span className='spinner-border spinner-border-sm align-middle ms-2' />
        </React.Fragment>
      ) : (
        label
      )}
      {rightIcon && !isLoading && (
        <KTSVG path={hover ? rightIconHover : rightIcon} className={`${iconClassName} ms-1`} />
      )}
    </Button>
  );
}

const getClassNames = (color: string) => {
  switch (color) {
    case 'outline-primary':
      return 'btn-outline btn-active-light-primary';
    case 'outline-success':
      return 'btn-outline btn-active-light-success';
    case 'outline-info':
      return 'btn-outline btn-active-light-info';
    case 'outline-warning':
      return 'btn-outline btn-active-light-warning';
    case 'outline-danger':
      return 'btn-outline btn-active-light-danger';
    case 'outline-dark':
      return 'btn-outline btn-active-light-dark';
    case 'outline-default':
      return 'btn-outline btn-active-light-default';
    default:
      return '';
  }
};
