import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import OAModalComponent from '../../../../../components/OAModalComponent';
import { useOrderContext } from '../../../context/OrderContext';
import { IOrderType } from '../../../types';

import { ORDER_TYPE } from '../../../constants';
import { useS3FileUploader } from '../../../../../hooks/useS3FileOperation';
import showToast from '../../../../../helper/showToast';
import { useAppContext } from '../../../../../context/AppContext';
import DefaultCell from '../../../../../components/OAColumn/DefaultCell';

import { Alert, Box, IconButton, MenuItem, Select, TextField } from '@mui/material';
import OAButton from '../../../../../components/widgets/OAButton';
import { ReactComponent as UploadIcon } from '../../../../../assets/icons/upload_pin_icon.svg';
import { ReactComponent as PdfIcon } from '../../../../../assets/icons/pdf_icon.svg';
import { ReactComponent as ImageICon } from '../../../../../assets/icons/image_icon.svg';
import { GridColumnHeaderParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import OATableComponent, { GridColumnHeader } from '../../../../../components/OATableComponent';

/**
 * types ...
 */
interface IVisaStatusUpdateModal {
  isOpen: boolean;
  onHide: () => void;
  member: any;
}

export default function VisaStatusUpdateModal(props: IVisaStatusUpdateModal) {
  return (
    <OAModalComponent
      show={props?.isOpen}
      onHide={props?.onHide}
      backdrop='static'
      size='lg'
      header={{
        title: `Update order status`,
        closeButton: false
      }}
      body={{
        content: <VisaStatusModal onHide={props?.onHide} member={props?.member} />,
        className: 'pt-0'
      }}
    />
  );
}

/**
 * Additional component : VisaStatusModal
 */
interface IVisaStatusModal {
  onHide: () => void;
  member: any;
}

export interface IVisaStatusModalValues {
  status: string;
  document: string;
  remark?: string;
}

function VisaStatusModal(props: IVisaStatusModal) {
  const params = useParams<{ orderType: IOrderType; id?: string }>();

  const { dataExchange } = useAppContext();
  const { updateMemberVisaStatus, orderData } = useOrderContext();

  const [newValue, setNewValue] = useState<any>({
    status: null,
    link: null
  });
  const [commentValue, setCommentValue] = useState<any>(null);
  const [uploadFile] = useS3FileUploader(5, ['jpg', 'png', 'jpeg', 'pdf']);
  const [alert, setAlert] = useState<boolean>(false);

  useEffect(() => {
    let count = 0;
    props?.member?.map((val: any) => {
      if (val?.downloadLink && val?.status && val?.status !== 'APPLIED_AT_PORTAL') {
        count += 1;
      }
    });
    if (count >= 1) {
      setAlert(false);
    } else {
      return setAlert(true);
    }
  }, []);

  let updateStatusOptionsLabel: string;
  let updateStatusOptions = dataExchange?.INSURANCE_UPDATE_STATUS;
  if (params?.orderType === ORDER_TYPE.VISA) {
    updateStatusOptionsLabel = 'Visa Status';
    const productType = orderData?.data.product?.productInformation?.productType;
    updateStatusOptions =
      productType === 'Sticker'
        ? dataExchange?.STICKER_VISA_UPDATE_STATUS
        : dataExchange?.EVISA_UPDATE_STATUS;
  }
  let filteredStatusOptions = updateStatusOptions?.filter(
    (status: any) => status?.value !== 'PARTIAL_APPROVED'
  );
  updateStatusOptions = filteredStatusOptions;

  const handleSubmit = () => {
    let count = 0;
    let updatedValues = props?.member?.map((val: any) => {
      if (val?.downloadLink && val?.status && val?.status !== 'APPLIED_AT_PORTAL') {
        count += 1;
      }
      return {
        memberId: val?.id ?? null,
        status: val?.status ?? null,
        document: val?.downloadLink ?? null,
        remark: val?.remark ?? null
      };
    });
    if (count >= 1) {
      setAlert(false);
      updateMemberVisaStatus(
        params?.orderType!,
        params?.id!,
        props?.member?.id,
        updatedValues,
        _res => {
          props?.onHide();
        }
      );
    } else {
      return setAlert(true);
    }
  };

  const fileUploadHandler = async (e: any, draft: any) => {
    const orderId = params?.id;
    const memberId = draft?.id;

    const fieldName = params?.orderType === ORDER_TYPE.VISA ? 'visa_status_' : 'insurance_status_';
    const result = await uploadFile(
      e?.target?.files?.[0],
      `order/visa/${orderId}/${memberId}/${fieldName}`
    );
    if (result?.uploadedUrl) {
      let member = props?.member?.find((obj: any) => draft?.id === obj?.id);
      member['downloadLink'] = result?.uploadedUrl;
      setNewValue({ link: result?.uploadedUrl });
    } else {
      if (result?.error) showToast(result?.error, 'error');
    }
  };

  const handleChange = (val: any, key: any, params: any) => {
    if (val && key == 'status') {
      setNewValue({ status: val });
      let member = props?.member?.find((obj: any) => params?.id === obj?.id);
      member['status'] = val;
    }

    if (val != '' && val && key == 'remark') {
      setCommentValue(val);
      let member = props?.member?.find((obj: any) => params?.id === obj?.id);
      member['remark'] = val;
    } else if (!val.includes('REJECTED') && !val.includes('APPROVED')) {
      setCommentValue(val);
      let member = props?.member?.find((obj: any) => params?.id === obj?.id);
      member['remark'] = val;
    }
  };

  const getColumns: any = () => [
    {
      field: 'travellers',
      renderHeader: (params: any) => <GridColumnHeader title='Travellers' params={params} />,
      renderCell: (params: any) => {
        return <DefaultCell data={`${params?.row?.firstName} ${params?.row?.lastName}`} />;
      },
      width: 150
    },
    {
      field: 'status',
      headerAlign: 'right',
      renderHeader: (params: GridColumnHeaderParams<GridValidRowModel, any, any>) => (
        <GridColumnHeader title='Status' params={params} />
      ),
      renderCell: (params: any) => {
        return (
          <Select
            size='small'
            value={params?.row?.status ?? newValue?.status}
            onChange={(e: any) => {
              handleChange(e?.target?.value, 'status', params);
            }}
            className='w-100'>
            <MenuItem value='REJECTED'>{'Rejected'}</MenuItem>
            <MenuItem value='APPROVED'>{'Approved'}</MenuItem>
          </Select>
        );
      },
      valueGetter: (params: any) => {
        params?.row?.status;
      },
      width: 150
    },
    {
      field: 'comments',
      headerAlign: 'left',
      renderHeader: (params: GridColumnHeaderParams<GridValidRowModel, any, any>) => (
        <GridColumnHeader title='Comments' params={params} />
      ),
      renderCell: (params: any) => {
        return (
          <TextField
            size='small'
            type='text'
            className='w-100'
            onChange={e => {
              handleChange(e?.target?.value, 'remark', params);
            }}
            value={params?.row?.remark ?? commentValue}
          />
        );
      },
      valueGetter: (params: any) => params?.row?.remark,
      width: 150
    },
    {
      field: 'documents',
      headerAlign: 'left',
      renderHeader: (params: GridColumnHeaderParams<GridValidRowModel, any, any>) => (
        <GridColumnHeader title='Documents' params={params} />
      ),
      renderCell: (params: any) => {
        const draft = params?.row?.downloadLink;
        const urlParts = draft?.split('/');
        const fileName = urlParts?.length ? urlParts[urlParts?.length - 1] : null;
        const fileType = fileName?.split('.').pop();

        return draft ? (
          <div className='d-flex'>
            <FileUploadButton
              onChange={e => {
                setCommentValue(null);
                fileUploadHandler(e, params);
              }}
            />
            <a href={draft} target='_blank' rel='noreferrer'>
              <p className='d-flex ms-2 mb-2 align-items-center'>
                {fileType === 'pdf' ? <PdfIcon /> : <ImageICon />}
                <span className='ms-2'>{fileName}</span>{' '}
              </p>
            </a>
          </div>
        ) : (
          <FileUploadButton
            onChange={e => {
              setCommentValue(null);
              fileUploadHandler(e, params);
            }}
          />
        );
      },
      valueGetter: (params: any) => {
        params?.row?.downLoadLink;
      },
      width: 300
    }
  ];

  const columns = useMemo(() => {
    return getColumns();
  }, [newValue]);

  const handleCancel = () => {
    props.member?.map((obj: any) => {
      obj.status = obj?.logs[obj?.logs?.length - 1]?.status;
    });
    props?.onHide();
  };
  return (
    <div>
      <div className='mt-5'>
        {alert && (
          <Alert
            severity='error'
            sx={{
              backgroundColor: theme => theme?.palette?.error?.light,
              color: theme => theme?.palette?.grey?.[400]
            }}>
            Uploading documents is mandatory when approving a visa status
          </Alert>
        )}
      </div>
      <OATableComponent
        columns={columns}
        data={props?.member?.length > 0 ? props?.member : []}
        loading={false}
        disableColumnMenu={false}
      />
      <Box display='flex' justifyContent='end' gap='1rem' paddingTop='1.5rem'>
        <OAButton
          label='Cancel'
          color='secondary'
          onClick={() => {
            handleCancel();
          }}
        />
        <OAButton label='Update status' color='primary' onClick={handleSubmit} />
      </Box>
    </div>
  );
}

const FileUploadButton: React.FC<{
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ onChange }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    fileInputRef?.current?.click();
  };

  return (
    <>
      <input ref={fileInputRef} type='file' style={{ display: 'none' }} onChange={onChange} />
      <IconButton aria-label='remove' size='small' onClick={handleClick}>
        <UploadIcon />
      </IconButton>
    </>
  );
};
