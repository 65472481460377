/* eslint-disable no-unused-vars */
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid-pro';

import { DefaultCell, CreatedOnCell, StatusCell } from '../../../components/OAColumn';
import { GridColumnHeader } from '../../../components/OATableComponent';
import { OA_DATE_TIME_FORMAT_FE } from '../../../constants';

type getApiKeyColumnsType = (actions: {
  handleView: (id: string) => void;
  handleDelete: (id: string) => void;
}) => GridColDef[];

export const getApiKeyColumns: getApiKeyColumnsType = actions => [
  {
    field: 'clientName',
    renderHeader: params => <GridColumnHeader title='Client Name' params={params} />,
    renderCell: params => <DefaultCell data={params?.value} />,
    valueGetter: params => params?.row?.clientName ?? '',
    flex: 1
  },
  {
    field: 'apiKey',
    renderHeader: params => <GridColumnHeader title='API Key' params={params} />,
    renderCell: params => <DefaultCell data={params?.value} />,
    valueGetter: params => params?.row?.clientId ?? '',
    flex: 2
  },
  {
    field: 'status',
    renderHeader: params => <GridColumnHeader title='Status' params={params} />,
    renderCell: params => <StatusCell status={params?.value} type='apiKey' />,
    valueGetter: params => params?.row?.status,
    width: 125
  },
  {
    field: 'createdOn',
    renderHeader: params => <GridColumnHeader title='Created On' params={params} />,
    renderCell: params => (
      <CreatedOnCell date={params?.value} dateFormat={OA_DATE_TIME_FORMAT_FE} />
    ),
    valueGetter: params => params?.row?.activatedAt,
    width: 175
  },
  {
    field: 'action',
    type: 'actions',
    getActions: (params: GridRowParams) => {
      return [
        <GridActionsCellItem
          label='View Secret'
          onClick={() => actions?.handleView(params?.row?.id)}
          showInMenu
          disabled={params?.row?.status === 'DELETED'}
        />,
        <GridActionsCellItem
          label='Delete'
          onClick={() => actions?.handleDelete(params?.row?.id)}
          showInMenu
          disabled={params?.row?.status === 'DELETED'}
        />
      ];
    },
    width: 50
  }
];
