const DeditSVG = () => {
  return (
    <svg
      className='rotate-90'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g mask='url(#mask0_225_11)'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M12 3.75C12.1989 3.75 12.3897 3.82902 12.5303 3.96967C12.671 4.11032 12.75 4.30109 12.75 4.5V11.25H19.5C19.6989 11.25 19.8897 11.329 20.0303 11.4697C20.171 11.6103 20.25 11.8011 20.25 12C20.25 12.1989 20.171 12.3897 20.0303 12.5303C19.8897 12.671 19.6989 12.75 19.5 12.75H12.75V19.5C12.75 19.6989 12.671 19.8897 12.5303 20.0303C12.3897 20.171 12.1989 20.25 12 20.25C11.8011 20.25 11.6103 20.171 11.4697 20.0303C11.329 19.8897 11.25 19.6989 11.25 19.5V12.75H4.5C4.30109 12.75 4.11032 12.671 3.96967 12.5303C3.82902 12.3897 3.75 12.1989 3.75 12C3.75 11.8011 3.82902 11.6103 3.96967 11.4697C4.11032 11.329 4.30109 11.25 4.5 11.25H11.25V4.5C11.25 4.30109 11.329 4.11032 11.4697 3.96967C11.6103 3.82902 11.8011 3.75 12 3.75Z'
          fill='#2FB344'
        />
      </g>
    </svg>
  );
};

export default DeditSVG;
