import FilerobotImageEditor, { TABS, TOOLS } from 'react-filerobot-image-editor';
import { toFile } from '../../helper';

/** TODO:
 * props type
 * add types properly
 * constants to up --> done
 * remove undefined checks
 */

const cropParameters = {
  presetsItems: [
    {
      titleKey: 'classicTv',
      descriptionKey: '4:3',
      ratio: 4 / 3
      // icon: CropClassicTv, // optional, CropClassicTv is a React Function component. Possible (React Function component, string or HTML Element)
    },
    {
      titleKey: 'cinemascope',
      descriptionKey: '21:9',
      ratio: 21 / 9
      // icon: CropCinemaScope, // optional, CropCinemaScope is a React Function component.  Possible (React Function component, string or HTML Element)
    }
  ],
  ratio: 'custom'
};

const FileRobotEditor = ({
  targetImg,
  fileUploadHandler,
  closeEditor,
  imgFile,
  setIsImgUploading,
  ...rest
}) => {
  const closeImgEditor = () => {
    closeEditor(false);
    setIsImgUploading(false);
  };

  if (!targetImg && !imgFile) {
    // if condition to check if imgFile is null or undefined
    return null;
  }

  const imgToEdit =
    (targetImg === '' || targetImg === undefined) && typeof imgFile === 'object'
      ? URL.createObjectURL(imgFile || '')
      : targetImg;
  return (
    <FilerobotImageEditor
      key={imgToEdit}
      savingPixelRatio={2}
      previewPixelRatio={2}
      defaultSavedImageQuality={0.7}
      defaultSavedImageType='jpg'
      source={imgToEdit === undefined ? imgFile : imgToEdit} //string || htmlinputelement
      onBeforeSave={() => false}
      onSave={async editedImageObject => {
        const base64Data = editedImageObject?.imageBase64;
        const fileName = editedImageObject?.name;
        const file = await toFile(base64Data, fileName);
        let list = new DataTransfer();
        list?.items?.add(file);
        closeEditor(false);
        fileUploadHandler(file, fileName, 'image/jpg');
      }}
      onClose={closeImgEditor}
      annotationsCommon={{
        fill: '#ff0000'
      }}
      Text={{ text: 'OnArrival editor...' }}
      Rotate={{ angle: 90, componentType: 'slider' }}
      Crop={cropParameters}
      tabsIds={[TABS.ADJUST, TABS.FINETUNE]} // or {['Adjust', 'Annotate', 'Watermark']}
      defaultTabId={TABS.ADJUST} // or 'Annotate'
      defaultToolId={TOOLS.CROP} // or 'Text'
      useBackendTranslations={false}
      {...rest}
    />
  );
};

export default FileRobotEditor;
