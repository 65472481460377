import { useEffect } from 'react';
import { Modal, ModalDialog } from 'react-bootstrap';
import Draggable from 'react-draggable';
import 'react-resizable/css/styles.css';

interface IDocumentPreview {
  isOpen: boolean;
  onHide: () => void;
  document: any;
  imgUrl: string;
}

export default function DocumentPreview(props: IDocumentPreview) {
  useEffect(() => {
    if (props?.isOpen) {
      const el = document.getElementById('kt_body');
      el?.style?.removeProperty('overflow');
    }
  }, [props?.isOpen]);

  const renderContent = (url: string, fileType: any) => {
    switch (fileType) {
      case 'pdf':
        return (
          <embed
            className='w-100'
            src={`${url}#toolbar=0`}
            style={{ minHeight: '450px' }}
            type='application/pdf'
          />
        );
      case 'doc':
      case 'docx':
        return (
          <iframe
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`}
            style={{ minHeight: '500px' }}
            className='w-100 h-auto'
          />
        );
      case 'png':
      case 'jpeg':
      case 'jpg':
        return <img src={url} className='img-fluid' />;
      default:
        return <img src={url} className='img-fluid' />;
    }
  };

  return (
    <Modal
      show={props?.isOpen}
      onHide={props?.onHide}
      size='lg'
      backdrop='static'
      dialogAs={DraggableDialog}
      className='document-preview'>
      <Modal.Header closeButton>
        <Modal.Title>{props?.document?.documentName}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='overflow-auto' style={{ maxHeight: '500px' }}>
        {renderContent(props?.imgUrl, props?.document?.fileExtension)}
      </Modal.Body>
      <Modal.Footer>
        {/* <ReactResizableBox width={0} height={0} axis={'x'}></ReactResizableBox> */}
      </Modal.Footer>
    </Modal>
  );
}

function DraggableDialog(props: any) {
  return (
    <Draggable handle='.modal-header'>
      <ModalDialog {...props} />
    </Draggable>
  );
}
