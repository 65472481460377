import React from 'react';
import { Field } from 'formik';
import { Col } from 'react-bootstrap';

import * as Fields from '../../../../../../components/fields';
import { ORDER_TYPE } from '../../../../constants';
import { useOrderContext } from '../../../../context/OrderContext';

export interface DynamicFieldProps {
  fields: { required?: string[]; optional?: string[] };
  fieldName: string;
  label?: string;
  fieldType: 'select' | 'text' | 'date' | 'phone';
  options?: Array<any>;
  colWidth?: number;
  required?: boolean;
  [x: string]: any;
}

const DynamicField: React.FC<DynamicFieldProps> = props => {
  const {
    fields,
    fieldName,
    label,
    fieldType,
    options,
    colWidth,
    renderCondition,
    inputClassName,
    ...rest
  } = props;

  const { isFetchingOcrData } = useOrderContext();

  const shouldRender = renderCondition ? renderCondition() : true;

  if (!shouldRender) {
    return null;
  }
  const isFieldRequired = fields?.required?.includes(fieldName);
  const isFieldOptional = fields?.optional?.includes(fieldName);

  if (!isFieldRequired && !isFieldOptional && fieldName !== 'consulate') {
    return null;
  }

  const fieldProps = {
    name: fieldName,
    label,
    inputClassName,
    ...rest
  };

  const columnWidth = colWidth || 4;

  switch (fieldType) {
    case 'select':
      return (
        <Col md={columnWidth} className='mb-4'>
          <Field
            {...fieldProps}
            isDisabled={isFetchingOcrData}
            as={Fields.OASelectField}
            options={options}
            valueOnly
            {...rest}
          />
        </Col>
      );
    case 'text':
      return (
        <Col md={columnWidth} className='mb-4'>
          <Fields.OATextField
            {...fieldProps}
            name={fieldProps.name || ''}
            {...rest}
            inputClassName={inputClassName}
            disabled={isFetchingOcrData}
          />
        </Col>
      );
    case 'date':
      return (
        <Col md={rest.params?.orderType === ORDER_TYPE.VISA ? 4 : 4} className='mb-4'>
          <Fields.OADatePickerField
            {...fieldProps}
            name={fieldProps.name || ''}
            label={fieldProps.label || ''}
            disabled={isFetchingOcrData}
            {...rest}
          />
        </Col>
      );
    case 'phone':
      return (
        <Col md={columnWidth} className='mb-4'>
          <Fields.OAPhoneField
            {...fieldProps}
            name={fieldProps.name || ''}
            label={fieldProps.label || ''}
            disabled={isFetchingOcrData}
            {...rest}
          />
        </Col>
      );
    default:
      return null;
  }
};

export default DynamicField;
