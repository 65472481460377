import Big from 'big.js';

import { formatNumberForCurrency, getCurrencySymbol } from '../../../../../../helper';
import { isEmpty } from 'lodash';

export interface IReviewTableRowData {
  id: string;
  type: string;
  currency: string;
  name: string;
  isCollected?: boolean | null;
  quantity: string | number | null;
  price: string;
  gst: 'true' | 'false' | null;
  gstAmount: string | null;
  amount: string;
  editEnabled?: Array<string>;
}

export const getModTableData = (data: any, type: 'proforma' | 'invoice') => {
  const { memberCount } = data?.orderAmount;
  const draftAmount = !isEmpty(data?.invoiceAmount) ? data?.invoiceAmount : data?.orderAmount;
  const additionalAmountModifiers = draftAmount?.additionalAmountModifiers;

  const product = data?.product;
  const productAmount = product?.productInformation?.productAmount;
  const draftProduct = additionalAmountModifiers?.find(
    (obj: any) => obj?.type === 'PRODUCT_FEE' && obj?.name === product?.displayName
  );
  const draft: Array<IReviewTableRowData> = [];
  /**
   * NOTE: check if productAmountToBeCollected is true then show item in invoice table only..
   */
  if (draftProduct) {
    draft.push({
      id: 'PRODUCT_FEE',
      type: draftProduct?.type,
      currency: draftProduct?.currency,
      name: draftProduct?.name,
      isCollected: draftProduct?.amountCollected,
      quantity: draftProduct?.quantity,
      price:
        draftProduct?.quantity !== 0
          ? draftProduct?.tax
            ? Big(draftProduct?.tax?.totalAmountWithoutTax).div(draftProduct?.quantity).toFixed(2)
            : Big(draftProduct?.amount).div(draftProduct?.quantity).toFixed(2)
          : Big(0).toFixed(2),
      gst: draftProduct?.tax ? 'true' : 'false',
      gstAmount: draftProduct?.tax
        ? Big(draftProduct?.tax?.totalTax).toFixed(2)
        : Big(0).toFixed(2),
      amount: Big(draftProduct?.amount).toFixed(2),
      editEnabled: ['quantity', 'price', 'gst']
    });
  } else {
    draft.push({
      id: 'PRODUCT_FEE',
      type: 'PRODUCT_FEE',
      currency: draftAmount?.currency,
      name: product?.displayName,
      isCollected: true,
      quantity: draftAmount?.memberCount,
      price: Big(productAmount?.baseAmount).toFixed(2),
      gst: 'false',
      gstAmount: Big(0).toFixed(2),
      amount: Big(productAmount?.baseAmount).mul(draftAmount?.memberCount).toFixed(2),
      editEnabled: ['quantity', 'price', 'gst']
    });
  }
  /**
   * VAC_SERVICE_CHARGE
   */
  const vac = additionalAmountModifiers?.find((obj: any) => obj?.type === 'VAC_SERVICE_CHARGE');
  if (vac && (type === 'invoice' ? !!vac.amountCollected : true)) {
    const quantity = vac?.hasOwnProperty('quantity') ? vac?.quantity : memberCount;
    const totalAmountWithoutTax = vac?.tax?.totalAmountWithoutTax ?? 0;
    const totalTax = vac?.tax?.totalTax ?? 0;
    const amount = vac?.amount ?? 0;

    const price =
      quantity !== 0
        ? vac?.tax
          ? Big(totalAmountWithoutTax).div(quantity).toFixed(2)
          : Big(amount).div(quantity).toFixed(2)
        : Big(0).toFixed(2);

    draft.push({
      id: 'VAC_SERVICE_CHARGE',
      type: 'VAC_SERVICE_CHARGE',
      currency: vac?.currency,
      name: 'VAC Service fee',
      isCollected: vac?.amountCollected,
      quantity,
      price,
      gst: vac?.tax ? 'true' : 'false',
      gstAmount: Big(totalTax).toFixed(2),
      amount: Big(amount).toFixed(2),
      editEnabled: ['quantity', 'price', 'gst']
    });
  }

  /**
   * ADD_ON_SERVICE_FEE
   */
  const draftAddonServiceFees = additionalAmountModifiers
    ?.filter((obj: any) => obj?.type === 'ADD_ON_SERVICE_FEE')
    ?.filter((obj: any) => (type === 'invoice' ? !!obj?.amountCollected : true));

  if (draftAddonServiceFees?.length) {
    draftAddonServiceFees?.forEach((obj: any, index: number) => {
      // NOTE: draftPrice is the price of the addon service...
      // const draftPrice = productAmount?.additionalAmountModifiers?.find(
      //   (obj2: any) => obj2?.name === obj?.name
      // )?.amount;

      /**
       * NOTE: if 'obj' has 'quantity' then use that else use 'memberCount' ...
       */
      const quantity = obj?.hasOwnProperty('quantity') ? obj?.quantity : memberCount;

      draft.push({
        id: `ADD_ON_SERVICE_FEE_${index}-${obj?.name}`,
        type: 'ADD_ON_SERVICE_FEE',
        currency: obj?.currency,
        name: obj?.name,
        isCollected: obj?.amountCollected,
        quantity,
        price:
          quantity !== 0
            ? obj?.tax
              ? Big(obj?.tax?.totalAmountWithoutTax).div(quantity).toFixed(2)
              : Big(obj?.amount).div(quantity).toFixed(2)
            : Big(0).toFixed(2),
        gst: obj?.tax ? 'true' : 'false',
        gstAmount: obj?.tax ? Big(obj?.tax?.totalTax).toFixed(2) : Big(0).toFixed(2),
        amount: Big(obj?.amount).toFixed(2),
        editEnabled: ['quantity', 'price', 'gst']
      });
    });
  }
  /**
   * OTHERS
   */
  const draftOthers = additionalAmountModifiers?.filter((obj: any) => obj?.type === 'OTHERS');
  if (draftOthers?.length) {
    draftOthers?.forEach((obj: any, index: number) => {
      draft.push({
        id: `OTHERS_${index}-${obj?.name}`,
        type: obj?.type,
        currency: obj?.currency,
        name: obj?.name,
        isCollected: obj?.amountCollected,
        quantity: obj?.quantity,
        price:
          obj?.quantity !== 0
            ? obj?.tax
              ? Big(obj?.tax?.totalAmountWithoutTax).div(obj?.quantity).toFixed(2)
              : Big(obj?.amount).div(obj?.quantity).toFixed(2)
            : Big(0).toFixed(2),
        gst: obj?.tax ? 'true' : 'false',
        gstAmount: obj?.tax ? Big(obj?.tax?.totalTax).toFixed(2) : Big(0).toFixed(2),
        amount: Big(obj?.amount).toFixed(2),
        editEnabled: ['name', 'quantity', 'price', 'gst', 'action']
      });
    });
  }

  return draft;
};

export const getCalTableData = (data: any) => {
  const { memberCount } = data?.orderAmount;
  const draftAmount = !isEmpty(data?.invoiceAmount) ? data?.invoiceAmount : data?.orderAmount;

  const additionalAmountModifiers = draftAmount?.additionalAmountModifiers;
  const totalAmt = draftAmount?.totalAmount;
  const subtotalAmt = draftAmount?.subtotalAmount;

  const { currency } = data?.orderAmount;

  const draft: Array<IReviewTableRowData> = [
    {
      id: 'SUBTOTAL',
      type: 'SUBTOTAL',
      currency: currency,
      name: '',
      isCollected: null,
      quantity: null,
      price: 'A. Subtotal',
      gst: null,
      gstAmount: null,
      amount: Big(subtotalAmt).toFixed(2),
      editEnabled: []
    }
  ];
  /**
   * SUPPLIER_FEE
   */
  const supplierFee = additionalAmountModifiers?.find((obj: any) => obj?.type === 'SUPPLIER_FEE');
  if (supplierFee) {
    const quantity = supplierFee?.hasOwnProperty('quantity') ? supplierFee?.quantity : memberCount;
    const totalAmountWithoutTax = supplierFee?.tax?.totalAmountWithoutTax ?? 0;
    const totalTax = supplierFee?.tax?.totalTax ?? 0;
    const amount = supplierFee?.amount ?? 0;

    const price =
      quantity !== 0
        ? supplierFee?.tax
          ? Big(totalAmountWithoutTax).div(quantity).toFixed(2)
          : Big(amount).div(quantity).toFixed(2)
        : Big(0).toFixed(2);

    const gst = supplierFee?.tax ? 'true' : 'false';
    const gstAmount = Big(supplierFee?.tax ? totalTax : 0).toFixed(2);

    draft.push({
      id: 'SUPPLIER_FEE',
      type: 'SUPPLIER_FEE',
      currency: supplierFee?.currency,
      name: 'Supplier Fee',
      isCollected: null,
      quantity: quantity,
      price: price,
      gst: gst,
      gstAmount: gstAmount,
      amount: Big(amount).toFixed(2),
      editEnabled: ['quantity', 'price', 'gst']
    });
  }

  /**
   * MARKUP_FEE
   */
  const draftMarkup = getMarkupFee(additionalAmountModifiers);
  if (draftMarkup) {
    const quantity = draftMarkup?.hasOwnProperty('quantity') ? draftMarkup?.quantity : memberCount;
    const totalAmountWithoutTax = draftMarkup?.tax?.totalAmountWithoutTax ?? 0;
    const totalTax = draftMarkup?.tax?.totalTax ?? 0;
    const amount = draftMarkup?.amount ?? 0;

    const price =
      quantity !== 0
        ? draftMarkup?.tax
          ? Big(totalAmountWithoutTax).div(quantity).toFixed(2)
          : Big(amount).div(quantity).toFixed(2)
        : Big(0).toFixed(2);

    draft.push({
      id: 'MARKUP_FEE',
      type: 'MARKUP_FEE',
      currency: draftMarkup?.currency ?? currency,
      name: 'Total Markup',
      isCollected: null,
      quantity: quantity,
      price: price,
      gst: draftMarkup?.tax ? 'true' : 'false',
      gstAmount: Big(totalTax).toFixed(2),
      amount: Big(amount).toFixed(2),
      editEnabled: ['quantity', 'price', 'gst']
    });
  }
  /**
   * TOTAL
   */
  draft.push({
    id: 'TOTAL',
    type: 'TOTAL',
    currency: currency,
    name: '',
    isCollected: null,
    quantity: '',
    price: 'Total',
    gst: null,
    gstAmount: null,
    amount: Big(totalAmt).toFixed(2),
    editEnabled: []
  });

  return draft;
};

export function formatAmount(amount: number, currency: string, countryCode: any) {
  return `${getCurrencySymbol(currency)} ${formatNumberForCurrency(amount, { countryCode })}`;
}

function getMarkupFee(amtModifiers: Array<any>) {
  let markupFee = amtModifiers?.find((obj: any) => obj?.type === 'MARKUP_FEE');

  if (markupFee) {
    return markupFee;
  } else {
    return {
      type: 'MARKUP_FEE',
      currency: 'INR',
      amount: 0,
      quantity: 0,
      tax: {
        totalTax: 0,
        totalAmountWithoutTax: 0
      },
      amountCollected: true
    };
  }
}

export function getTotalAmount(
  isCollected: boolean,
  amount: string | number,
  total: Big.Big,
  subtotal: Big.Big
) {
  const draftTotal = isCollected ? total.add(amount) : total.sub(amount);
  const draftSubtotal = isCollected ? subtotal.add(amount) : subtotal.sub(amount);

  return { total: draftTotal, subtotal: draftSubtotal };
}
