import { useContext } from 'react';
import { Formik, Form, FormikProps, ErrorMessage, Field, FormikHelpers } from 'formik';
import { Button, Col, Container, Row } from 'react-bootstrap';
import * as Yup from 'yup';

import ApiKeyContext from '../context/ApiKeyContext';
import { IUserFormValues } from '../types';

const validationSchema = Yup.object({
  clientName: Yup.string().required().label('Client Name'),
  description: Yup.string().required().label('Description')
});

const initValues: IUserFormValues = {
  clientName: '',
  description: ''
};

const ApiKeyForm = (props: { data: any }) => {
  const { toggleModal, onCreateApiKey } = useContext(ApiKeyContext);

  /**
   * NOTE: if 'data' available then call edit user method or call create
   * user method coming from UserContext ...
   *
   * @param values
   */
  const onClickSubmit = (values: IUserFormValues, actions: FormikHelpers<IUserFormValues>) => {
    onCreateApiKey(values, success => {
      success && toggleModal();
      actions?.setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={initValues}
      validationSchema={validationSchema}
      onSubmit={onClickSubmit}
      enableReinitialize>
      {(formikProps: FormikProps<IUserFormValues>) => {
        const isSubmitDisabled = !(formikProps?.isValid && formikProps?.dirty);

        return (
          <Container as={Form} className='api-key--form p-0'>
            <Row className='mb-7'>
              <Col>
                <label className='form-label required'>Client Name</label>
                <Field
                  type='text'
                  name='clientName'
                  placeholder='Type here'
                  className='form-control form-control-solid'
                />
                <div className='text-danger'>
                  <ErrorMessage name='clientName' />
                </div>
              </Col>
            </Row>
            <Row className='mb-7'>
              <Col>
                <label className='form-label required'>Description</label>
                <Field
                  type='textarea'
                  as='textarea'
                  name='description'
                  placeholder='Type here'
                  className='form-control form-control-lg form-control-solid'
                />
                <div className='text-danger'>
                  <ErrorMessage name='description' />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className='text-end'>
                <Button type='reset' size='sm' onClick={toggleModal} variant='light'>
                  Cancel
                </Button>
                <Button
                  type='submit'
                  size='sm'
                  className='ms-4'
                  disabled={isSubmitDisabled || formikProps?.isSubmitting}>
                  {formikProps?.isSubmitting ? 'Saving...' : 'Save'}
                </Button>
              </Col>
            </Row>
          </Container>
        );
      }}
    </Formik>
  );
};

export default ApiKeyForm;
