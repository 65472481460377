const formatNumberForCurrency = (
  value: number,
  options: { countryCode?: string; toFixed?: boolean } = {}
): string => {
  const { countryCode = 'IN', toFixed = true } = options;
  const formatter = new Intl.NumberFormat(`en-${countryCode}`, {
    style: 'decimal',
    minimumFractionDigits: toFixed ? 2 : 0
  });
  return formatter.format(value);
};

export default formatNumberForCurrency;
