import React, { useEffect, useState } from 'react';
import { Col, Modal, Row, Container, Card } from 'react-bootstrap';
import { Box } from '@mui/material';
import moment from 'moment';
import 'moment-timezone';

import { showToast } from '../../../helper';
import { KTSVG } from '../../../_metronic/helpers';
import GroupOrderTabel from './GroupOrderTable';
import { useOrderContext } from '../context/OrderContext';
import AddTravellerFormModal from '../order-create/components/AddTravellers/AddTravellerFormModal';
import OAButton from '../../../components/widgets/OAButton';
import CsvManager from '../../../components/CsvManager';
import { unparseCSVFile } from '../../../components/CsvManager/helper';
import { debounce } from 'lodash';

interface IGroupOrderList {
  data: any;
  isLoading: boolean;
  queryData: any;
}
const GroupOrderList = (props: IGroupOrderList) => {
  const { setQueryParams } = props?.queryData?.data;
  const { data, isLoading } = props;

  const { createBulkOrderGroup, onModifyTravellers, onSelectEditTraveller } = useOrderContext();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
  const [isOpenFile, setIsOpenFile] = useState<boolean>(false);
  const [isRowCellEmpty, setIsRowCellEmpty] = useState<boolean>(false);

  const [updatedCsv, setUpdatedCsv] = useState<any>();

  useEffect(() => {
    setQueryParams((prev: any) => ({
      ...prev,
      search: '',
      pageIndex: 1
    }));

    // Cleanup function to run when the component unmounts
    return () => {
      setQueryParams((prev: any) => ({
        ...prev,
        search: '',
        pageIndex: 1
      }));
    };
  }, []);

  const handleUpdate = (data: any) => {
    setUpdatedCsv(data);
  };

  //disabling add traveller manual button if visa product is not available for the selected travel duration of the group
  const enableAddTravellerManually = () => {
    const today = moment();
    const minTravelDate = data?.groupOrder?.product?.productInformation?.minTravelDate;
    const checkForHours =
      data?.groupOrder?.product?.productInformation?.minProcessingTimeMatrix === 'Hours';
    const sum = checkForHours
      ? moment(today).add(minTravelDate, 'hours')
      : moment(today).add(minTravelDate, 'days');
    const input = {
      datetime: data?.groupOrder?.trip?.tripStart?.datetime,
      timezone: data?.groupOrder?.trip?.tripStart?.timezone
    };
    const tripStartDate = moment.tz(input?.datetime, input?.timezone);
    return sum.isBefore(tripStartDate);
  };

  const toggleModal = () => {
    const canAddTravellerManually = enableAddTravellerManually();
    if (!canAddTravellerManually) {
      showToast(
        'You can’t add traveller because the travel duration is now less that the minimum travel duration of the visa selected',
        'error'
      );
    } else {
      onSelectEditTraveller(null);
      onModifyTravellers();
      setIsOpen(prev => !prev);
    }
  };
  const toggleModalFile = () => {
    setIsOpenFile(prev => !prev);
  };

  const onFileUploadHandle = () => {
    unparseCSVFile(updatedCsv?.spaces, csvFile => {
      setIsLoadingFile(true);
      const formdata = new FormData();
      formdata.append('file', csvFile, '[PROXY]');

      createBulkOrderGroup(formdata, data?.groupOrder?.id, (res: any) => {
        setIsLoadingFile(false);
        if (res?.httpStatus === 200 || res?.httpStatus === 201) {
          setIsOpenFile(false);
        }
        setIsOpenFile(false);
      });
    });
    return;
  };

  const minProcessingStartDateForCsv = moment().add(
    data?.groupOrder?.product?.productInformation?.maxProcessingTime,
    data?.groupOrder?.product?.productInformation?.maxProcessingTimeMatrix?.toLowerCase()
  );

  const onChangeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams((prev: any) => ({
      ...prev,
      search: evt?.target?.value?.trim(),
      pageIndex: 1
    }));
  };

  const debouncedOnChange = debounce(onChangeHandler, 300);

  return (
    <React.Fragment>
      <Card className='mt-6'>
        <Card.Header className='border-bottom border-gray-200 align-items-center'>
          <div className='card-title position-relative search-orders'>
            <KTSVG
              className='position-absolute ms-2 svg-icon-4'
              path='/media/icons/duotune/general/gen021.svg'
            />
            <input
              type='text'
              onChange={debouncedOnChange}
              className='form-control form-control-sm form-control-solid oa-text-search w-150px ps-9'
              placeholder='Search Orders'
            />
          </div>

          <Box display='flex' gap='8px'>
            <OAButton
              label='Add traveller manually'
              className='btn-light-primary btn-active-light-primary'
              onClick={toggleModal}
            />
            <OAButton
              label='Bulk upload CSV'
              className='btn-light-primary btn-active-light-primary'
              onClick={toggleModalFile}
            />
          </Box>
        </Card.Header>
        <Card.Body className='pt-0'>
          <GroupOrderTabel data={data?.content} isLoading={isLoading} queryData={props} />
        </Card.Body>
      </Card>

      <AddTravellerFormModal
        isOpen={isOpen}
        onHide={() => {
          setIsOpen(false);
        }}
      />

      <Modal
        show={isOpenFile}
        onHide={toggleModalFile}
        aria-labelledby='contained-modal-title-vcenter'
        backdrop='static'
        size='xl'
        centered>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>Upload File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid className='p-0'>
            <CsvManager
              orderType='visa'
              category='VISA'
              onUpdate={handleUpdate}
              productSpecificFields={data?.groupOrder?.product?.id}
              productName={'Visa orders'}
              tripStartDateMin={minProcessingStartDateForCsv}
              selectedProduct={data?.groupOrder?.product}
              isRowCellEmpty={isRowCellEmpty}
              setIsRowCellEmpty={setIsRowCellEmpty}
            />

            <Row className='mt-4'>
              <Col className='text-end'>
                <OAButton
                  label='Cancel'
                  className='btn-light-primary btn-active-light-primary me-5'
                  onClick={toggleModalFile}
                />
                <OAButton
                  label='Submit'
                  className='ms-auto btn-primary btn-active-primary btn-flex'
                  disabled={
                    (Array.isArray(updatedCsv?.hasErrors)
                      ? updatedCsv.hasErrors.length > 0
                      : updatedCsv?.hasErrors) ||
                    isRowCellEmpty ||
                    isLoadingFile ||
                    isRowCellEmpty
                  }
                  onClick={onFileUploadHandle}
                  isLoading={isLoadingFile}
                  loadingText='Uploading...'
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default GroupOrderList;
