const FIELDS = [
  {
    field: 'orderNumber',
    headerName: 'Order No.',
    type: 'string',
    editable: true,
    isRequired: true,
    validator: [
      {
        type: 'regex',
        message: 'Order No. must consist of digits only.',
        regex: /^\d+$/
      }
    ]
  },
  {
    field: 'requestedProductCode',
    headerName: 'Product Code',
    type: 'string',
    editable: true,
    isRequired: true,
    validator: [
      {
        type: 'regex',
        message: 'Product code must only be alphanumeric',
        regex: /^[A-Za-z0-9]+$/
      }
    ]
  },
  {
    field: 'tripStartDate',
    headerName: 'Trip Start Date',
    type: 'date',
    editable: true,
    isRequired: true
  },

  {
    field: 'tripStartTime',
    headerName: 'Trip Start Time',
    type: 'string',
    editable: true,
    requiredIf: (row: any) => !['OAINTATA3000', 'OAINTATA5000']?.includes(row.requestedProductCode),
    requiredIfMessage: "Can't be empty",
    validator: [
      {
        type: 'regex',
        message: 'Enter time as HH:MM',
        regex: /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/
      }
    ]
  },
  {
    field: 'tripEndDate',
    headerName: 'Trip End Date',
    type: 'date',
    editable: true,
    isRequired: true
  },
  {
    field: 'tripEndTime',
    headerName: 'Trip End Time',
    type: 'string',
    editable: true,
    requiredIf: (row: any) => !['OAINTATA3000', 'OAINTATA5000']?.includes(row.requestedProductCode),
    requiredIfMessage: "Can't be empty",
    validator: [
      {
        type: 'regex',
        message: 'Enter time as HH:MM',
        regex: /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/
      }
    ]
  },
  {
    field: 'originCity',
    headerName: 'Origin City',
    type: 'string',
    editable: true,
    isRequired: true,
    validator: [
      {
        type: 'regex',
        message: 'Enter valid origin city',
        regex: /^[a-zA-Z\s\-']+$/
      }
    ]
  },
  {
    field: 'originIataCode',
    headerName: 'Origin IataCode',
    type: 'string',
    editable: true,
    isRequired: true,
    validator: [
      {
        type: 'regex',
        message: 'Enter valid IATA Code, Ex. DXB, IXJ',
        regex: /^[A-Z]{3}$/
      }
    ]
  },
  {
    field: 'originCountryCode',
    headerName: 'Origin Country Code',
    type: 'string',
    editable: true,
    isRequired: true,
    validator: [
      {
        type: 'regex',
        message: 'Enter valid country code, Ex. IN, AE',
        regex: /^[A-Z]{2,3}$/
      }
    ]
  },
  {
    field: 'destinationCity',
    headerName: 'Destination City',
    type: 'string',
    editable: true,
    isRequired: true,
    validator: [
      {
        type: 'regex',
        message: 'Enter valid destination city',
        regex: /^[a-zA-Z\s\-']+$/
      }
    ]
  },
  {
    field: 'destinationIataCode',
    headerName: 'destinationIataCode',
    type: 'string',
    editable: true,
    isRequired: true,
    validator: [
      {
        type: 'regex',
        message: 'Enter valid IATA Code, Ex. DXB, IXJ',
        regex: /^[A-Z]{3}$/
      }
    ]
  },
  {
    field: 'destinationCountryCode',
    headerName: 'destinationCountryCode',
    type: 'string',
    editable: true,
    isRequired: true,
    validator: [
      {
        type: 'regex',
        message: 'Enter valid country code, Ex. IN, AE',
        regex: /^[A-Z]{2,3}$/
      }
    ]
  },
  {
    field: 'title',
    headerName: 'title',
    type: 'singleSelect',
    editable: true,
    isRequired: true
  },
  {
    field: 'firstName',
    headerName: 'firstName',
    type: 'string',
    editable: true,
    isRequired: true,
    validator: [
      {
        type: 'regex',
        message: 'Enter valid name',
        regex: /^[a-zA-Z\s]*$/
      }
    ]
  },
  {
    field: 'lastName',
    headerName: 'lastName',
    type: 'string',
    editable: true,
    isRequired: true,
    validator: [
      {
        type: 'regex',
        message: 'Enter valid last name',
        regex: /^[a-zA-Z\s]*$/
      }
    ]
  },
  {
    field: 'dateOfBirth',
    headerName: 'dateOfBirth',
    type: 'date',
    editable: true,
    isRequired: true
  },
  {
    field: 'phone',
    headerName: 'phone',
    type: 'string',
    editable: true,
    isRequired: true,
    validator: [
      {
        type: 'regex',
        message: 'Enter valid 10 digit mobile',
        regex: /^\d{10}$/
      }
    ]
  },
  {
    field: 'email',
    headerName: 'email',
    type: 'string',
    editable: true,
    isRequired: true,
    validator: [
      {
        type: 'regex',
        message: 'Enter valid email',
        regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      }
    ]
  },
  {
    field: 'gender',
    headerName: 'gender',
    type: 'singleSelect',
    editable: true,
    isRequired: true
  },
  {
    field: 'primaryTraveller',
    headerName: 'primaryTraveller',
    type: 'singleSelect',
    editable: true,
    isRequired: true,
    valueOptions: [
      { value: 'TRUE', label: 'TRUE' },
      { value: 'FALSE', label: 'FALSE' }
    ]
  },
  {
    field: 'nationality',
    headerName: 'nationality',
    type: 'string',
    editable: true,
    isRequired: true,
    validator: [
      {
        type: 'regex',
        message: 'Only IN is allowed',
        regex: /^IN$/
      }
    ]
  },
  {
    field: 'passportIssueDate',
    headerName: 'Passport Issue Date',
    type: 'date',
    editable: true,
    isRequired: true
  },
  {
    field: 'passportIssuingDate',
    headerName: 'Passport Issue Date',
    type: 'date',
    editable: true,
    isRequired: true
  },
  {
    field: 'passportNumber',
    headerName: 'passportNumber',
    type: 'string',
    editable: true,
    requiredIf: (row: any) => row.destinationCountryCode !== row?.originCountryCode,
    requiredIfMessage: "Can't be empty since origin and destination are different",
    validator: [
      {
        type: 'regex',
        message: 'Enter valid passport number',
        regex: /^[A-Za-z]{1}-?\d{7}$/
      }
    ]
  },
  {
    field: 'passportExpiry',
    headerName: 'passportExpiry',
    type: 'date',
    editable: true,
    requiredIf: (row: any) => row.passportNumber !== '',
    requiredIfMessage: "Can't be empty"
  },
  {
    field: 'passportExpiration',
    headerName: 'Passport Expiration',
    type: 'date',
    editable: true,
    isRequired: true
  },
  {
    field: 'passportIssuingCountry',
    headerName: 'passportIssuingCountry',
    type: 'string',
    editable: true,
    requiredIf: (row: any) => row.passportNumber !== '',
    requiredIfMessage: "Can't be empty",
    validator: [
      {
        type: 'regex',
        message: 'Enter valid country code, Ex. IN, AE',
        regex: /^[A-Z]{2,3}$/
      }
    ]
  },
  {
    field: 'pnrNumber',
    headerName: 'PNR Number',
    type: 'string',
    editable: true
  },
  {
    field: 'ticketNumber',
    headerName: 'Ticket Number',
    type: 'string',
    editable: true
  },
  {
    field: 'tripStartTimeZone',
    headerName: 'Trip Start TimeZone',
    type: 'string',
    editable: true,
    isRequired: true
  },
  {
    field: 'tripEndTimeZone',
    headerName: 'Trip End TimeZone',
    type: 'string',
    editable: true,
    isRequired: true
  },
  {
    field: 'addressLine',
    headerName: 'Address Line',
    type: 'string',
    editable: true,
    isRequired: true
  },
  {
    field: 'addressState',
    headerName: 'Address State',
    type: 'string',
    editable: true,
    isRequired: true
  },
  {
    field: 'addressPostalCode',
    headerName: 'Address Postal Code',
    type: 'string',
    editable: true,
    isRequired: true
  },
  {
    field: 'bookingReferenceId',
    headerName: 'Booking ReferenceId',
    type: 'string',
    editable: true,
    isRequired: true
  },
  {
    field: 'bookingAmountPerNight',
    headerName: 'Booking Amount PerNight',
    type: 'number',
    editable: true,
    isRequired: true
  },
  {
    field: 'bookingAmountCurrency',
    headerName: 'Booking Amount Currency',
    type: 'string',
    editable: true,
    isRequired: true,
    validator: [
      {
        type: 'regex',
        message: 'Enter valid currency, Ex. INR, USD',
        regex: /^[A-Z]{2,3}$/
      }
    ]
  },
  {
    field: 'occupation',
    headerName: 'Occupation',
    type: 'singleSelect',
    editable: true,
    isRequired: true
  },
  {
    field: 'passportIssuingPlace',
    headerName: 'Passport Issuing Place',
    type: 'string',
    editable: true,
    isRequired: true,
    validator: [
      {
        type: 'regex',
        message: 'Enter valid origin city',
        regex: /^[a-zA-Z\s\-']+$/
      }
    ]
  }
];

export const generateFields = (fields: any) => {
  return fields?.map((field: any) => {
    return (
      FIELDS?.find(f => f.field === field) || {
        field: field,
        headerName: field,
        type: 'string',
        editable: true,
        isRequired: true
      }
    );
  });
};
