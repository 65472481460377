import { GridColDef } from '@mui/x-data-grid-pro';
import { GridColumnHeader } from '../../components/OATableComponent';
import { CreatedOnCell, DefaultCell, LinkCell, StatusCell } from '../../components/OAColumn';
import { OA_DATE_TIME_FORMAT_FE } from '../../constants';
import { OAStatusChip } from '../../components/widgets';
import { useAppContext } from '../../context/AppContext';
import { Key } from 'react';

type getOcrColumnsType = () => GridColDef[];

export const getOcrColumns: getOcrColumnsType = () => [
  {
    field: 'Request ID',
    renderHeader: params => <GridColumnHeader title='Request Id' params={params} />,
    renderCell: params => <DefaultCell data={params?.value} />,
    valueGetter: params => params?.row?.id,
    width: 400
  },
  {
    field: 'PASSPORT IMAGE URL',
    renderHeader: params => <GridColumnHeader title='PASSPORT IMAGE URL' params={params} />,
    renderCell: params => {
      const fileIdentifiers = params?.row?.fileIdentifier ?? [];
      const frontImage = fileIdentifiers.find(
        (obj: { type: string }) => obj?.type === 'FRONT_PAGE'
      );
      const backImage = fileIdentifiers.find((obj: { type: string }) => obj?.type === 'BACK_PAGE');

      const renderLinkCell = (image: { fileUrl: string }, label: string | undefined) =>
        image ? (
          <>
            <div
              className={`me-2 ms-2 ${fileIdentifiers.length > 1 ? '' : 'justify-content-center'}`}>
              <LinkCell link={image.fileUrl} fileName={label} isEllipsis />
            </div>
            {params?.row?.fileIdentifier?.length > 1 && label !== 'Back Page' && ', '}
          </>
        ) : null;

      return (
        <div className='d-flex justify-content-evenly'>
          {renderLinkCell(frontImage, 'Front Page')}
          {renderLinkCell(backImage, 'Back Page')}
          {!frontImage && !backImage && (
            <>
              <div className='me-2'>
                <LinkCell link={params?.row?.fileUrls[0]} isEllipsis />
              </div>
              {params?.row?.fileUrls?.length > 1 && ', '}
              {params?.row?.fileUrls?.length > 1 && (
                <>
                  <div className='ms-2'>
                    <LinkCell link={params?.row?.fileUrls[1]} isEllipsis />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      );
    },
    valueGetter: params => params?.row?.fileUrls,
    width: 300
  },
  {
    field: 'status',
    renderHeader: params => <GridColumnHeader title='Status' params={params} />,
    renderCell: params => {
      return (
        <OAStatusChip
          status={params?.row?.errors ? 'ORDER_FAILED' : 'COMPLETED'}
          label={params?.row?.errors ? 'Failed' : 'Success'}
          variant={params?.row?.errors ? 'danger' : 'success'}
        />
      );
    },
    valueGetter: params => (params?.row?.errors ? 'FAILED' : 'SUCCESS'),
    width: 300
  },
  {
    field: 'createdBy',
    renderHeader: params => <GridColumnHeader title='Created By' params={params} />,
    renderCell: params => {
      const draftData = `${params?.row?.createdByUser?.firstName} ${params?.row?.createdByUser?.lastName}`;
      return <DefaultCell data={draftData} />;
    },
    valueGetter: params => params?.row,
    width: 300
  },
  {
    field: 'createdAt',
    renderHeader: params => <GridColumnHeader title='Created On' params={params} />,
    renderCell: params => (
      <CreatedOnCell date={params?.value} dateFormat={OA_DATE_TIME_FORMAT_FE} />
    ),
    valueGetter: params => params?.row?.createdAt,
    width: 300,
    sortable: true
  }
];
