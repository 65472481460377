// hooks/useCPayAPI.ts
import { useState } from 'react';
import axios from 'axios';
import { IMember, IProduct, ITrip } from '../types';
import { getFormattedDate, showToast } from '../helper';
import API from '../helper/API';
import { useOrderContext } from '../pages/order-management/context/OrderContext';
import { IOrderAmount } from '../types/IOrderAmount';

const MERCHANTID = process.env.REACT_APP_CPAY_MERCHANT_ID;
const MERCHANTTOKEN = process.env.REACT_APP_CPAY_MERCHANT_TOKEN;

const HEADER = {
  headers: {
    'x-merchant-id': MERCHANTID,
    'x-merchant-token': MERCHANTTOKEN
  }
};

export const useCPayAPI = (order: {
  members: IMember[];
  product: IProduct;
  orderAmount: IOrderAmount;
  trip: ITrip;
  id: string;
}) => {
  const { members, product, orderAmount, trip, id } = order;
  const { productInformation } = product;

  const { getOrderById } = useOrderContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getCurrencyExchange = async (countryCode: string, amount: number) => {
    const response = await API.get(`/currency/exchange?countryCode=AE&amountInInr=${amount}`);
    return response?.data?.data?.amount;
  };

  let baseURL = 'api-dev.cpay.ws';
  if (window.location.href.includes('hub.onarrival.travel')) {
    baseURL = 'api.cpay.ws';
  }

  const processPayment = async (): Promise<string | null> => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://${baseURL}/v1/widget/account/token`, HEADER);
      const responseToken = response?.data?.data?.token;
      const primaryTraveller = members?.find((member: IMember) => member?.primary);
      const amount = await getCurrencyExchange(
        trip?.destination?.[0]?.countryCode,
        orderAmount?.totalAmount
      );

      if (response?.status === 200 && responseToken && primaryTraveller) {
        const requestPayload = {
          amount: amount?.toFixed(2),
          // amount: 1,
          product: 'visa',
          order_note: `Visa, orderID - ${order?.id}`,
          metadata: {
            visa: {
              visa_type: productInformation?.visaType,
              source_type: 'Auth',
              present_nationality: primaryTraveller?.nationality ?? '-',
              passport_number: primaryTraveller?.passport?.number,
              first_name: primaryTraveller?.firstName,
              last_name: primaryTraveller?.lastName,
              gender: primaryTraveller?.gender,
              marital_status: primaryTraveller?.maritalStatus ?? '-',
              birth_date: primaryTraveller?.dateOfBirth,
              birth_country: primaryTraveller?.birthCountry ?? '-',
              birth_place: primaryTraveller?.birthPlace ?? '-',
              date_of_issue: primaryTraveller?.passport?.issuingDate
                ? getFormattedDate(primaryTraveller?.passport?.issuingDate, 'DD-MM-YYYY')
                : '-',
              expiration_date: primaryTraveller?.passport?.expiration
                ? getFormattedDate(primaryTraveller?.passport?.expiration, 'DD-MM-YYYY')
                : '-'
            },
            contact_details: {
              mobile: primaryTraveller?.phone,
              email: primaryTraveller?.email
            }
          }
        };

        const headerRequest = {
          headers: {
            'token': responseToken,
            'Content-Type': 'application/json'
          }
        };

        const orderResponse = await axios.post(
          `https://${baseURL}/api/v1/widget/order/create`,
          requestPayload,
          headerRequest
        );

        const orderId = orderResponse?.data?.data?.order_id;

        if (orderId) {
          return orderId;
        } else {
          setIsLoading(false);
          showToast('something went wrong', 'error');
          return null;
        }
      } else {
        setIsLoading(false);
        showToast('something went wrong', 'error');
        return null;
      }
    } catch (error: any) {
      setIsLoading(false);
      showToast(error?.response?.data?.message, 'error');
      return null;
    }
  };

  const sendPaymentReference = (orderId: string, requestBody: any) => {
    const request = {
      paymentReferenceData: {
        orderReference: requestBody?.order_reference,
        status: requestBody?.booking_status,
        bookingReference: requestBody?.booking_reference
      }
    };

    API.post(`/visa/submit?id=${orderId}`, request)
      .then(response => {
        setIsLoading(false);
        getOrderById('visa', orderId);
        showToast('Visa submitted successfully.', 'success');
      })
      .catch(err => {
        setIsLoading(false);
        showToast(err?.response?.data, 'error');
      });
  };

  return {
    processPayment,
    isLoading,
    sendPaymentReference,
    setIsLoading
  };
};
