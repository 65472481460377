import { useEffect, useRef, useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { ErrorMessage, useField, useFormikContext } from 'formik';
import moment, { Moment } from 'moment';
import classNames from 'classnames';

import { OA_DATE_FORMAT_FE, OA_DATE_FORMAT_FE_BASIC } from '../../constants';

interface IOADatePickerField {
  name: string;
  label: string;
  required?: boolean;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  date?: string;
}

export default function OADatePickerField(props: IOADatePickerField) {
  const { name, label = null, required = false, disabled = false, date } = props;
  const [open, setOpen] = useState<boolean>(false);

  const [field, meta, helpers] = useField(name);

  const formikBag = useFormikContext();
  const fieldRef = useRef<HTMLDivElement>(null);

  const labelClassNames = classNames('form-label', {
    required: required
  });
  /**
   * NOTE: scroll to field in case of validation failure or any error...
   */
  useEffect(() => {
    const firstError = Object.keys(formikBag.errors)[0];
    if (formikBag.isSubmitting && firstError === field.name) {
      fieldRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [meta.error, formikBag.isSubmitting, field.name, formikBag.errors]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && open === true) {
      // Close the date picker popup
      setOpen(false);
    }

    if (event.key === 'Enter' && open === false) {
      // Open the date picker popup
      setOpen(true);
    }
  };

  return (
    <section className='oa-datepicker-field' ref={fieldRef}>
      <label htmlFor={name} className={labelClassNames}>
        {label}
      </label>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          open={open}
          onClose={() => setOpen(false)}
          value={field?.value ? moment(field.value) : date ? moment(date) : null}
          onChange={(val: Moment | null) => {
            if (props?.minDate) {
              const minDate = moment(props?.minDate);

              if (val && val.isBefore(minDate, 'day')) {
                helpers.setError(`Date must be on or after ${minDate.format(OA_DATE_FORMAT_FE)}`);
                return;
              }
            }
            return helpers.setValue(val);
          }}
          slotProps={{
            textField: {
              size: 'small',
              onClick: () => setOpen(true),
              onKeyDown: handleKeyPress
            }
          }}
          format={OA_DATE_FORMAT_FE_BASIC}
          minDate={props?.minDate && moment(props?.minDate!)}
          maxDate={props?.maxDate && moment(props?.maxDate!)}
          disabled={disabled}
        />
      </LocalizationProvider>
      <div className='text-danger fw-bold fs-9'>
        <ErrorMessage name={name} />
      </div>
    </section>
  );
}
