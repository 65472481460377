import { GridColDef } from '@mui/x-data-grid-pro';
import { AmountCell, CreatedOnCell, DefaultCell } from '../../../components/OAColumn';
import { CLAIM_STATUS, OA_DATE_TIME_FORMAT_FE } from '../../../constants';
import { GridColumnHeader } from '../../../components/OATableComponent';
import { OAStatusChip } from '../../../components/widgets';
import { Chip } from '@mui/material';
import { MemberDisplay } from '../../../components/OAColumn/TravellersCell';
import { SettleClaim } from './settleClaim';

type getLedgerColumnsType = (onHideRequestSupportModal: any) => GridColDef[];

export const getClaimColumns: getLedgerColumnsType = onHideRequestSupportModal => {
  let draft: Array<GridColDef> = [
    {
      field: 'ClaimNumber',
      renderHeader: params => <GridColumnHeader title='Claim Number' params={params} />,
      renderCell: params => <DefaultCell data={params?.row?.claimNumber ?? '--'} />,
      width: 180
    },
    {
      field: 'PolicyNumber',
      renderHeader: params => <GridColumnHeader title='Policy Number' params={params} />,
      renderCell: params => <DefaultCell data={params?.row?.policyNumber ?? '--'} />,
      width: 180
    },
    {
      field: 'ClaimStatus',
      renderHeader: params => <GridColumnHeader title='Claim Status' params={params} />,
      renderCell: params => {
        return (
          <Chip
            label={params?.row?.claimStatus}
            size='small'
            sx={{
              borderRadius: '4px',
              textTransform: 'uppercase',
              fontWeight: '600',
              fontSize: '10px',
              lineHeight: '12px',
              backgroundColor: 'warning.light',
              color: 'warning.main'
            }}
          />
        );
      },
      width: 150
    },
    {
      field: 'OrderStatus',
      renderHeader: params => <GridColumnHeader title='Order Status' params={params} />,
      renderCell: params => {
        const statusObj: any = CLAIM_STATUS?.find((obj: any) => obj?.value === params?.row?.status);

        return (
          <OAStatusChip
            status={statusObj?.value}
            label={statusObj?.label}
            variant={statusObj?.type}
          />
        );
      },
      minWidth: 180
    },
    {
      field: 'claimantName',
      renderHeader: params => <GridColumnHeader title='Claimant Name' params={params} />,
      renderCell: params => {
        return <MemberDisplay name={params?.row?.claimantName} />;
      },
      width: 170
    },
    {
      field: 'productName',
      renderHeader: params => <GridColumnHeader title='Product Name' params={params} />,
      renderCell: params => <DefaultCell data={params?.row?.productName} isEllipsis />,
      width: 170
    },
    {
      field: 'coverName',
      renderHeader: params => <GridColumnHeader title='Cover Name' params={params} />,
      renderCell: params => <DefaultCell data={params?.row?.coverName} isEllipsis />,
      width: 160
    },
    {
      field: 'raisedOn',
      renderHeader: params => <GridColumnHeader title='Raised On(Onarrival)' params={params} />,
      renderCell: params => (
        <CreatedOnCell date={params?.value} dateFormat={OA_DATE_TIME_FORMAT_FE} />
      ),
      valueGetter: params => params?.row?.createdAt,
      width: 170,
      sortable: true
    },
    {
      field: 'netPayable',
      renderHeader: params => <GridColumnHeader title='Net Payable(INR)' params={params} />,
      renderCell: params => <AmountCell data={params?.row?.netPayableAmount ?? 0} />,
      width: 130
    },
    {
      field: 'actionColumn',
      renderHeader: params => <GridColumnHeader title='' params={params} />,
      renderCell: params => <SettleClaim data={params?.row} onHide={onHideRequestSupportModal} />,
      width: 100
    }
  ];
  return draft;
};
