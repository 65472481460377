import {
  AddOns,
  AdditionalNotes,
  Documents,
  EmbassyAndConsulate,
  Faqs,
  Source,
  Steps,
  VisaFees,
  VisaFormsLink
} from './AccordionItem';
import { EmbassyHolidayList } from './AccordionItem/EmbassyHolidayList';
import Exemptions from './AccordionItem/Exemptions';
import FinancialInformation from './AccordionItem/FinancialInformation';

interface IOAAccordionConfig {
  selectedConsulate: any;
  selectedVisaInformation: any;
  requiredVisaForms: any;
  requiredDocs: any;
  groupByApplicableFor: any;
}

const OAAccordionConfig = (props: IOAAccordionConfig) => {
  const {
    selectedConsulate,
    selectedVisaInformation,
    requiredVisaForms,
    requiredDocs,
    groupByApplicableFor
  } = props;
  // Function to check if an array is not empty and its items are non-empty strings
  const isNonEmptyStringArray = (arr: any) =>
    Array?.isArray(arr) && arr?.some(item => item?.trim() !== '');
  let accordionConfig: any = [
    {
      component: EmbassyAndConsulate,
      data: { consulate: selectedConsulate, visaInfo: selectedVisaInformation },
      header: 'Embassy/Consulate/Jurisdiction Details',
      check:
        selectedConsulate?.consulateInfo?.description ||
        selectedVisaInformation?.embassyInfo?.description
    },
    {
      component: VisaFees,
      data: { visaFee: selectedVisaInformation?.visaFeeInInr },
      header: 'Visa Fees',
      check: selectedVisaInformation?.visaFeeInInr?.length > 0
    },
    {
      component: AddOns,
      data: { addOns: selectedVisaInformation?.addOnServicesFeeInr },
      header: 'Add-ons and other charges',
      check: selectedVisaInformation?.addOnServicesFeeInr?.length > 0
    },
    {
      component: VisaFormsLink,
      data: { forms: requiredVisaForms },
      header: 'Visa forms & other formats',
      check: requiredVisaForms?.length > 0
    },
    {
      component: Steps,
      data: { steps: selectedVisaInformation?.processOfApplyingVisa },
      header: 'Steps Involved',
      check: selectedVisaInformation?.processOfApplyingVisa?.length > 0
    },
    {
      component: FinancialInformation,
      check:
        selectedVisaInformation?.financialInformation &&
        selectedVisaInformation?.financialInformation?.some((item: string) => item?.trim() !== ''),
      header: 'Financial Information',
      data: { financialInfo: selectedVisaInformation?.financialInformation }
    },
    {
      component: Exemptions,
      check:
        selectedVisaInformation?.exemptions &&
        selectedVisaInformation?.exemptions?.some((item: string) => item?.trim() !== ''),
      header: 'Exemptions',
      data: { exemptions: selectedVisaInformation?.exemptions }
    },
    {
      component: Documents,

      check: requiredDocs?.length > 0,
      header: 'Required Documents',
      data: { documents: requiredDocs }
    }
  ];

  // Dynamic items for groupByApplicableFor
  if (groupByApplicableFor && Object.entries(groupByApplicableFor).length > 0) {
    const additionalDocs = Object.entries(groupByApplicableFor).map(([key, value]) => ({
      component: Documents,
      data: { documents: value },
      header: `Additional Documents For ${key}`,
      check: true
    }));
    accordionConfig.push(...additionalDocs);
  }

  accordionConfig.push(
    {
      component: AdditionalNotes,
      data: { notes: selectedVisaInformation?.additionalNotes },
      header: 'Additional Notes',
      check: isNonEmptyStringArray(selectedVisaInformation?.additionalNotes)
    },
    {
      component: AdditionalNotes,
      check: Array.isArray(selectedVisaInformation?.decisionGuideForVisaApplication),
      header: 'Decision Guide For Visa',
      data: { notes: selectedVisaInformation?.decisionGuideForVisaApplication }
    },
    {
      component: Faqs,
      check: selectedVisaInformation?.faqs?.length > 0,
      header: 'FAQs',
      data: { faq: selectedVisaInformation?.faqs }
    },
    {
      component: AdditionalNotes,
      check: selectedVisaInformation?.securityGuidelinesDuringAppointment?.length > 0,
      header: 'Security Guidelines During Appointment',
      data: { notes: selectedVisaInformation?.securityGuidelinesDuringAppointment }
    },
    {
      component: Source,
      check: selectedVisaInformation?.source?.length > 0,
      header: 'Source',
      data: { source: selectedVisaInformation?.source }
    },
    {
      component: EmbassyHolidayList,
      check: selectedConsulate?.consulateInfo?.holidayList,
      header: 'Embassy Holiday List',
      data: { consulate: selectedConsulate }
    }
  );

  accordionConfig = accordionConfig?.map((item: any, index: number) => ({ ...item, idx: index }));

  return accordionConfig;
};

export default OAAccordionConfig;
