import Swal, { SweetAlertResult, SweetAlertIcon } from 'sweetalert2';

interface DialogOptions {
  title: string;
  text: string;
  icon: SweetAlertIcon;
  showCancelButton?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  customClass?: Record<string, string>;
  buttonsStyling?: boolean;
  reverseButtons?: boolean;
  focusCancel?: boolean;
}

const showDialog = ({
  title,
  text,
  icon,
  showCancelButton = true,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  customClass = {
    confirmButton: 'btn btn-sm btn-primary',
    cancelButton: 'btn btn-sm btn-light-primary btn-active-light-primary me-3'
  },
  buttonsStyling = false,
  reverseButtons = true,
  focusCancel = true
}: DialogOptions): Promise<SweetAlertResult<any>> => {
  return Swal.fire({
    title,
    text,
    icon,
    showCancelButton,
    confirmButtonText,
    cancelButtonText,
    customClass,
    buttonsStyling,
    reverseButtons,
    focusCancel
  });
};

export default showDialog;
