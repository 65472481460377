import { Autocomplete, Chip, TextField } from '@mui/material';
import { ErrorMessage, useFormikContext } from 'formik';
import { GridCloseIcon } from '@mui/x-data-grid-pro';
import { useState } from 'react';
interface IOATagsBasedInputProps {
  textFieldName: string;
  textFieldPlaceholder: string;
  inputTagId: string;
  onChange: (event: any, value: any) => void;
  onInputChange?: (e: any, value: any) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLImageElement>) => void;
  inputValue?: string[];
}

const OATagsBasedInput = (props: IOATagsBasedInputProps) => {
  const {
    inputValue = [],
    textFieldName,
    textFieldPlaceholder,
    inputTagId,
    onChange,
    onInputChange,
    onKeyDown
  } = props;

  const { setFieldValue } = useFormikContext();

  const [value, setValue] = useState<string[]>(inputValue);
  const [inputVal, setInputVal] = useState<string>('');

  const renderChipTags = (value: readonly string[], getTagProps: any) => {
    return value?.map((option: string, index: number) => (
      <Chip
        sx={{ height: 20, borderRadius: 1, fontSize: 10 }}
        deleteIcon={<GridCloseIcon sx={{ height: 14, width: 14 }} />}
        variant='filled'
        label={option}
        {...getTagProps({ index })}
      />
    ));
  };

  const onInputChangeHandler = (event: any, newInputValue: string) => {
    setInputVal(newInputValue);
    setFieldValue(textFieldName, [...value, newInputValue]); // Registering input value to Formik
  };

  const onChangeValue = (event: any, newValue: any) => {
    onChange(event, newValue);
    setValue(newValue);
    setFieldValue(textFieldName, newValue); // Registering tags to Formik
  };

  const onKeyDownhandler = (event: any) => {
    if (event.key === 'Enter') {
      // Prevent's default 'Enter' behavior.
      event.defaultMuiPrevented = true;
    }
    if (event.keyCode === 188) {
      const tagValue = event.target.value; // remove trailing comma
      const newValue = [...value, tagValue];
      setValue(newValue);
      onChange(event, newValue);
      setInputVal(''); // reset input value after a tag is added using comma
      setFieldValue(textFieldName, newValue); // Registering new tags to Formik
      event.preventDefault(); // prevent the comma from being added
    }
  };

  return (
    <section>
      <label className='mb-1'>Email address</label>
      <Autocomplete
        multiple
        renderInput={params => {
          return (
            <TextField
              sx={{ paddingLeft: 1.5 }}
              name={textFieldName}
              value={value}
              {...params}
              variant='standard'
              placeholder={value?.length === 0 ? textFieldPlaceholder : ''}
              onKeyDown={onKeyDownhandler}
              InputProps={{ ...params.InputProps, disableUnderline: true }}
            />
          );
        }}
        id={inputTagId}
        options={[]}
        defaultValue={[]}
        value={value}
        inputValue={inputVal}
        freeSolo
        onChange={onChangeValue}
        onInputChange={onInputChangeHandler}
        sx={{ padding: 0, fontWeight: 3, color: '#5e6278', display: 'flex', alignItems: 'center' }}
        renderTags={renderChipTags}
        className='form-control form-control-sm form-control-solid'
      />
      <div className='text-danger fw-bold fs-9'>
        <ErrorMessage name={textFieldName} />
      </div>
    </section>
  );
};

export default OATagsBasedInput;
