import React, {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
  PropsWithChildren
} from 'react';
import { LayoutSplashScreen } from '../../_metronic/layout/core';
import { AuthModel, UserModel } from './_models';
import * as authHelper from './AuthHelpers';
import { getUserByToken } from './_requests';
import { NovuProvider } from '@novu/notification-center';
import { encryptLocalStorage, encryptStorage } from '../../helper/storage';
import * as Sentry from '@sentry/react';

type AuthContextProps = {
  auth: AuthModel | undefined;
  saveAuth: (auth: AuthModel | undefined) => void;
  currentUser: UserModel | undefined;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
  logout: () => void;
};

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {}
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>();

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth);
    } else {
      authHelper.removeAuth();
    }
  };

  const logout = () => {
    saveAuth(undefined);
    setCurrentUser(undefined);
    localStorage.removeItem('oaMode');
    localStorage.removeItem('savedLayouts');
    encryptStorage.clear();
    encryptLocalStorage.clear();
    Sentry.setUser(null);
  };

  // to get live / test novu notifications based on the mode switch
  const oaMode = localStorage.getItem('oaMode');
  const novuAppId =
    oaMode === 'live'
      ? process.env.REACT_APP_NOVU_APP_ID_LIVE
      : process.env.REACT_APP_NOVU_APP_ID_TEST;

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}>
      <NovuProvider
        subscriberId={currentUser?.data.id}
        applicationIdentifier={novuAppId ? novuAppId : ''}>
        <React.Fragment>{children}</React.Fragment>
      </NovuProvider>
    </AuthContext.Provider>
  );
};

const AuthInit: FC<PropsWithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth();
  const didRequest = useRef(false);

  const [showSplashScreen, setShowSplashScreen] = useState(true);

  /**
   * DEVNOTE: We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN)
   * before rendering the application ...
   */
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const { data: user } = await getUserByToken(apiToken);
          if (user) {
            setCurrentUser(user);
            let lsMode = localStorage.getItem('oaMode');
            if (!lsMode) {
              /**
               * NOTE: operation role have only access to live mode ...
               */
              //setting up live mode by default
              if (user?.data?.organization?.liveModeEnabled === true) {
                localStorage.setItem('oaMode', 'live');
              } else {
                localStorage.setItem('oaMode', 'test');
              }
            }

            // check orgnization id
            const orgnizationId = encryptStorage.getItem('oa_orgnization_id');
            if (!orgnizationId) {
              encryptStorage.setItem('oa_orgnization_id', user?.data?.organization?.id);
            }
          }
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }
      return () => (didRequest.current = true);
    };

    if (auth?.data?.accessToken) {
      requestUser(auth?.data?.accessToken);
    } else {
      logout();
      setShowSplashScreen(false);
    }
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { useAuth, AuthProvider, AuthInit };
