/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Button, Col, Container, Row } from 'react-bootstrap';

import OATextAreaField from '../../../../../components/fields/OATextAreaField';
import * as Yup from 'yup';
import OATextField from '../../../../../components/fields/OATextField';
import moment from 'moment';
import { useOrderContext } from '../../../context/OrderContext';
import getFromAndTo from '../../../../../util/getFromAndTo';
import { getFormattedDate, showToast } from '../../../../../helper';
import { useAuth } from '../../../../../auth';
import OASelectField from '../../../../../components/fields/OASelectField';
import { OccupationOptions, VisaTypeOptions } from '../../../constants';
import OASwitchField from '../../../../../components/fields/OASwitchField';
import OAButton from '../../../../../components/widgets/OAButton';
import { OA_DATE_FORMAT_BE, OA_DATE_FORMAT_FE_BASIC } from '../../../../../constants';
import { awsBucketName } from '../../../../../hooks/useS3FileOperation';

interface IItineraryFormModal {
  onHide: () => void;
  orderData: any;
  loader: (props: boolean) => void;
  loadFiles: (props: boolean) => void;
  status: (props: string) => void;
}

const init = {
  email: '',
  fullName: '',
  occupation: '',
  occupationInformation: '',
  destinationCountry: '',
  originCountry: '',
  travelDuration: '',
  intentOfTravel: '',
  budget: '',
  numberOfDays: '',
  numberOfTravellers: '',
  additionalInformation: ''
};

const ItinerarySchema = Yup.object().shape({
  intentOfTravel: Yup.string().required('intentOfTravel is required'),
  budget: Yup.number()
    .integer()
    .moreThan(-1, 'Amount cannot be less than 0')
    .required('budget is required'),
  numberOfDays: Yup.number()
    .integer()
    .moreThan(-1, 'Amount cannot be less than 0')
    .required('numberOfDays is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required')
});

const coverLetterSchema = Yup.object().shape({
  fullName: Yup.string().required('FullName is required'),
  occupation: Yup.string().required('Occupation is required'),
  occupationInformation: Yup.string().required('occupation Information is required')
});

export default function AddItineraryForm(props: IItineraryFormModal) {
  const [initValues, setInitValues] = useState<any>(init);
  const [toggleTravelItinerary, setToggleTravelItinerary] = useState<boolean>(false);
  const [toggleCoverLetter, setToggleCoverLetter] = useState<boolean>(false);

  const { onCreateItinerary } = useOrderContext();
  const { currentUser } = useAuth();
  let trip = props?.orderData?.trip;

  useEffect(() => {
    let startDate = getFormattedDate(trip?.tripStart?.datetime, OA_DATE_FORMAT_BE);

    let endDate = getFormattedDate(trip?.tripEnd?.datetime, OA_DATE_FORMAT_BE);

    let duration = moment(endDate).diff(startDate, 'days');
    let draft = {
      email: currentUser?.data?.email,
      destinationCountry: getFromAndTo(props?.orderData?.trip),
      originCountry: 'India',
      numberOfDays: duration,
      numberOfTravellers: props?.orderData?.members?.length,
      travelDuration: `${getFormattedDate(
        trip?.tripStart?.datetime,
        OA_DATE_FORMAT_FE_BASIC
      )} - ${getFormattedDate(trip?.tripEnd?.datetime, OA_DATE_FORMAT_FE_BASIC)}`
    };
    setInitValues(draft);
  }, [props]);

  const toggleCover = (val: boolean) => {
    setToggleCoverLetter(prev => !prev);
  };

  const toggleItinerary = (val: boolean) => {
    setToggleTravelItinerary(prev => !prev);
  };

  const handleSubmit = (values: any, actions: FormikHelpers<any>) => {
    values['orderData'] = props?.orderData;
    values['bucketName'] = awsBucketName;
    props?.onHide();
    props?.loader(true);
    values?.coverLetter && values?.Itinerary
      ? props?.status('Processing-Travel-Documents')
      : props?.status('Processing-Itinerary');
    const draft_success_text = 'Success! Your travel itinerary will soon be delivered by mail.';
    showToast(draft_success_text, 'success');
    onCreateItinerary(values, async (res: any) => {
      props?.loader(false);
      props?.loadFiles(true);
      props?.status('Completed');
    });
  };

  let combinedSchema = ItinerarySchema.concat(coverLetterSchema);

  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleSubmit}
      validationSchema={toggleCoverLetter ? combinedSchema : ItinerarySchema}
      enableReinitialize>
      {(formikProps: FormikProps<any>) => {
        return (
          <Container fluid as={Form} className='p-0'>
            <Row className='mb-4'>
              <Col>
                <OASwitchField
                  name='Itinerary'
                  label='Create a Travel Itinerary'
                  toggle={toggleItinerary}
                />
              </Col>
            </Row>
            <Row className='mb-4'>
              <Col>
                <OASwitchField
                  name='coverLetter'
                  label='Create a Cover Letter'
                  toggle={toggleCover}
                />
              </Col>
            </Row>
            {toggleTravelItinerary && (
              <>
                <Row className='mb-4'>
                  <Col lg={6}>
                    <Field
                      name='intentOfTravel'
                      label='Intent Of Travel'
                      placeholder='Select Travel Intent'
                      as={OASelectField}
                      options={VisaTypeOptions}
                      valueOnly
                      required
                    />
                  </Col>
                  <Col lg={6}>
                    <OATextField name='email' label='Email Address' required />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <OATextField name='budget' label='Total Trip Budget(₹)' required />
                  </Col>
                  <Col lg={6}>
                    <OATextField name='numberOfDays' label='Total Number of Days' required />
                  </Col>
                </Row>
                <Row className='mt-5'>
                  <Col>
                    <OATextAreaField
                      name='additionalInformation'
                      label='Additional Information'
                      placeholder='For e.g. Please include Indian restaurants or vegan restaurants as part of the itinerary'
                    />
                  </Col>
                </Row>
              </>
            )}
            {toggleCoverLetter && (
              <>
                <Row className='mb-4 mt-5'>
                  <Col lg={6}>
                    <OATextField name='fullName' label='Full name (as per passport)' required />
                  </Col>
                  <Col lg={6}>
                    <Field
                      name='occupation'
                      label='Occupation'
                      placeholder='Select Occupation'
                      as={OASelectField}
                      options={OccupationOptions}
                      valueOnly
                      required
                    />
                  </Col>
                </Row>
                <Row className='mb-5'>
                  <Col>
                    <OATextAreaField
                      name='occupationInformation'
                      label='Occupation Description'
                      placeholder='For e.g. I am working as a professor in KKR university with a CTC of 10L. Apart from this, I possess investments of Rs 10L'
                      required
                    />
                  </Col>
                </Row>
              </>
            )}
            <div className='d-flex justify-content-end mt-5'>
              <OAButton
                className='btn-light-primary btn-active-light-primary me-3'
                label='Cancel'
                onClick={() => {
                  formikProps?.resetForm();
                  props?.onHide();
                }}
              />
              <OAButton
                type='submit'
                disabled={
                  (!formikProps?.dirty && formikProps?.isValid) ||
                  (formikProps?.dirty && !formikProps?.isValid)
                }
                label='Create Travel Documents'
              />
            </div>
          </Container>
        );
      }}
    </Formik>
  );
}
