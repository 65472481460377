import React, { Dispatch } from 'react';
import { IClaimQueryParams } from '../type';
import { IPageInfo } from '../../../components/OATableComponent';

export interface IClaimContext {
  pageInfo: IPageInfo;
  getClaimList: (cb?: ((response?: any) => void) | undefined) => Promise<void>;
  settleClaim: (id: any, cb?: ((response?: any) => void) | undefined) => Promise<void>;
  shareLink: (id: any, cb?: ((response?: any) => void) | undefined) => Promise<void>;
  statusChange: boolean;
}

const ClaimContext = React.createContext<IClaimContext>({} as IClaimContext);

export default ClaimContext;
