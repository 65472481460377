import { GridColDef } from '@mui/x-data-grid-pro';
import {
  AmountCell,
  CreatedOnCell,
  CreditDebitIcon,
  DefaultCell
} from '../../../components/OAColumn';
import { OA_DATE_TIME_FORMAT_FE } from '../../../constants';
import { GridColumnHeader } from '../../../components/OATableComponent';
import LedgerAction from '../../../components/OAColumn/LedgerAction';

type getLedgerColumnsType = (handleEditTransaction: (data: any) => void) => GridColDef[];

export const getLedgerColumns: getLedgerColumnsType = handleEditTransaction => {
  let draft: Array<GridColDef> = [
    {
      field: 'icon',
      renderHeader: params => <GridColumnHeader title='' params={params} />,
      renderCell: params => <CreditDebitIcon isDebited={params?.row?.debit} />,
      width: 110
    },
    {
      field: 'createdOn',
      renderHeader: params => <GridColumnHeader title='Created On' params={params} />,
      renderCell: params => (
        <CreatedOnCell date={params?.row?.createdAt} dateFormat={OA_DATE_TIME_FORMAT_FE} />
      ),
      width: 210
    },
    {
      field: 'orderId',
      renderHeader: params => <GridColumnHeader title='Order Id' params={params} />,
      renderCell: params => (params?.row?.linkedOrderId ? `#${params?.row?.linkedOrderId}` : '--'),
      width: 210
    },
    {
      field: 'Description',
      renderHeader: params => <GridColumnHeader title='Description' params={params} />,
      renderCell: params => <DefaultCell data={params?.row?.description ?? ''} />,
      width: 610
    },
    {
      field: 'debit',
      renderHeader: params => <GridColumnHeader title='Debit(INR)' params={params} />,
      renderCell: params => <AmountCell data={params?.row?.debit ?? 0} />,
      width: 110
    },
    {
      field: 'credit',
      renderHeader: params => <GridColumnHeader title='Credit(INR)' params={params} />,
      renderCell: params => <AmountCell data={params?.row?.credit ?? 0} />,
      width: 110
    },
    {
      field: 'balance',
      renderHeader: params => <GridColumnHeader title='Balance(INR)' params={params} />,
      renderCell: params => <AmountCell data={params?.row?.runningBalance ?? 0} />,
      width: 110
    },
    {
      field: 'edit',
      renderHeader: params => <GridColumnHeader title='' params={params} />,
      renderCell: params => (
        <LedgerAction data={params?.row} handleEditTransaction={handleEditTransaction} />
      ),
      width: 110
    }
  ];
  return draft;
};
