import { useContext, useEffect, useState } from 'react';

import OARangePicker from '../../../components/OARangePicker';
import OAToolbar from '../../../components/widgets/OAToolbar';
import ClaimContext from '../context/ClaimContext';
import { ClaimsHistory } from './claimHistory';
import BreadcrumbItem from './BreadcrumbItem';

interface IClaimsQueryParams {
  data: any;
}
export const ClaimsDashboard = (props: IClaimsQueryParams) => {
  const { queryParams, setQueryParams } = props?.data;
  const { getClaimList, statusChange } = useContext(ClaimContext);

  const [list, setList] = useState<Array<any>>([]);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const claimList = () => {
    setIsLoading(true);
    getClaimList(res => {
      setIsLoading(false);
      setList(res?.content);
      setTotalPage(res?.totalPages ?? 0);
    });
  };

  useEffect(() => {
    if (queryParams?.dateRange[0] && queryParams?.dateRange[1]) {
      claimList();
    }
  }, [queryParams, statusChange]);

  const onChangeRangePicker = (range: any) => {
    setQueryParams((prev: any) => ({ ...prev, dateRange: range }));
  };
  return (
    <>
      <OAToolbar
        title={'Claims'}
        breadcrumb={BreadcrumbItem()}
        actionsElement={
          <div className='d-flex'>
            <div className='me-4'>
              <OARangePicker
                className='dashboard-data-range-filter'
                name='dashboard-data-range'
                onChangeRangePicker={onChangeRangePicker}
                dateRange={queryParams?.dateRange}
                maxDate={new Date()}
              />
            </div>
          </div>
        }
      />
      <ClaimsHistory
        list={list ?? []}
        totalPage={totalPage}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        onChangeRangePicker={onChangeRangePicker}
        loading={isLoading}
      />
    </>
  );
};
