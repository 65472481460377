import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { useAuth } from '../../auth';
import { encryptStorage } from '../../helper/storage';
import OrgContext from '../../pages/org-management/context/OrgContext';
import OrgProvider from '../../pages/org-management/context/OrgProvider';

const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    'background': '#F6F8FA',
    'minHeight': '30px',
    'minWidth': '150px',
    'paddingRight': '5px',
    'borderRadius': '8px',
    'borderWidth': 0,
    ':focus': {
      borderWidth: 0
    },
    ':hover': {
      borderColor: 'transparent',
      boxShadow: 'none'
    }
  }),
  indicatorSeparator: (state: any) => ({
    display: 'none'
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    padding: 0,
    marginLeft: '-7px',
    color: '#B5B5C2'
  })
};

const OrganizationSelect = () => {
  const { currentUser } = useAuth();
  const { organizations, fetchOrgs } = useContext(OrgContext);
  const { data, isLoading } = organizations;
  const [list, setList] = useState<Array<{ value: string; label: string; isDisabled?: boolean }>>([
    { value: 'all', label: 'All' }
  ]);
  const [selectedOrg, setSelectedOrg] = useState<{ value: string; label: string }>({
    value: 'all',
    label: 'All'
  });

  useEffect(() => {
    if (!data) {
      fetchOrgs?.('', 'all');
    }
  }, []);

  useEffect(() => {
    if (data && data.length > 0) {
      let sortedArray = [...data].sort((a, b) =>
        a?.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
      );
      let orgMap = sortedArray.map((obj: any) => ({
        value: obj?.id,
        label: obj?.name
      }));
      setList(prev => [...prev, ...orgMap]);
      const orgnizationId = encryptStorage.getItem('oa_orgnization_id');
      if (orgnizationId) {
        const findOrgId = orgMap.find((obj: any) => obj?.value === orgnizationId);
        setSelectedOrg(findOrgId as { value: string; label: string });
      } else {
        const org = currentUser?.data?.organization;
        if (org) setSelectedOrg({ value: org?.id, label: org?.name });
      }
    }
  }, [data]);

  const onChangeSelectHandler = (option: any) => {
    encryptStorage.setItem('oa_orgnization_id', option?.value);
    setSelectedOrg(option);
    document.body.classList.add('loading-indicator');
    window.location.reload();
  };

  return (
    <Select
      isLoading={isLoading}
      isSearchable={true}
      isClearable={false}
      options={list}
      value={selectedOrg}
      onChange={onChangeSelectHandler}
      styles={customStyles}
    />
  );
};

const OrganizationFilter = () => {
  return (
    <OrgProvider>
      <OrganizationSelect />
    </OrgProvider>
  );
};

export default OrganizationFilter;
