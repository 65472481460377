import classNames from 'classnames';

import { IconType } from '.';

export default function EditSVG(props: IconType) {
  const {
    color = '#B5B5C2',
    width = '14',
    height = '14',
    // isActive = false,
    className = ''
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 18'
      fill='none'
      className={classNames('icon-svg edit-svg', className)}
      xmlns='http://www.w3.org/2000/svg'>
      <path
        opacity='0.3'
        d='M16.0518 6.26486L14.4326 7.88333L10.1156 3.56635L11.7341 1.94705C12.0204 1.66076 12.4088 1.5 12.8137 1.5C13.2186 1.5 13.6069 1.66076 13.8933 1.94705L16.0518 4.10559C16.3381 4.39195 16.4989 4.78026 16.4989 5.18518C16.4989 5.5901 16.3381 5.9785 16.0518 6.26486ZM2.76705 16.4491L7.41705 14.8988L3.10005 10.5818L1.5498 15.2318C1.49292 15.4017 1.48458 15.5841 1.52572 15.7584C1.56687 15.9328 1.65586 16.0922 1.78268 16.2187C1.9095 16.3452 2.06911 16.4337 2.24355 16.4745C2.418 16.5152 2.60033 16.5064 2.77005 16.4491H2.76705Z'
        fill={color}
      />
      <path
        d='M4.17859 15.9743L2.76709 16.4453C2.59752 16.5017 2.41559 16.5099 2.24167 16.4688C2.06774 16.4277 1.90867 16.339 1.78227 16.2127C1.65586 16.0864 1.5671 15.9274 1.52592 15.7535C1.48474 15.5796 1.49276 15.3977 1.54908 15.2281L2.02009 13.8158L4.17859 15.9743ZM3.09933 10.578L7.41634 14.895L14.4318 7.87949L10.1148 3.5625L3.09933 10.578Z'
        fill={color}
      />
    </svg>
  );
}
