import React, { useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { parseISO, format } from 'date-fns';
import isEmpty from 'lodash/isEmpty';

import { capitalizeFirstLetterOnly, getCountryName } from '../../../../../helper';
import { useOrderContext } from '../../../context/OrderContext';
import InsuranceProductCard from './InsuranceProductCard';
import VisaProductCard from './VisaProductCard';
import { ORDER_TYPE } from '../../../constants';
import { capitalize } from 'lodash';
import { IOrderType } from '../../../types';

const iso3311a2 = require('iso-3166-1-alpha-2');

interface TravelDetailProps {
  quotationId: string;
  onClickStep: (stepIndex: number) => void | undefined;
}

const TravelDetail = (props: TravelDetailProps) => {
  const params = useParams<{ orderType: IOrderType }>();

  const { formState, orderData } = useOrderContext();

  const isInsuranceOrder = params?.orderType === ORDER_TYPE.INSURANCE;
  const trip = formState?.tripDetails?.trip;

  useEffect(() => {
    formState.travellerDetails = [];
    formState.taxInfo = {};
  }, []);

  return (
    <div className={`add-visa-type w-100 bg-white rounded p-6`}>
      <h3 className='text-dark fw-bolder mb-4'>Travel Information</h3>
      {!isEmpty(formState?.tripDetails) && (
        <React.Fragment>
          <Row>
            <Col>
              <div className='fw-bold text-muted d-block fs-7'>
                {trip?.domestic ? 'From' : 'From - To'}
              </div>
              <div className='text-dark fw-bolder fs-6 text-capitalize mb-4'>
                {iso3311a2.getCountry(trip?.origin?.countryCode)}{' '}
                {!trip?.domestic && ' - ' + getArrival(trip?.destination)}
              </div>
            </Col>
            {trip?.visaType && (
              <Col>
                <div className='fw-bold text-muted d-block fs-7'>Purpose</div>
                <div className='text-dark fw-bolder fs-6 text-capitalize'>
                  {capitalizeFirstLetterOnly(trip?.visaType)}
                </div>
              </Col>
            )}
            {isInsuranceOrder && trip?.tripType ? (
              <Col>
                <div className='fw-bold text-muted d-block fs-7'>Trip type</div>
                <div className='text-dark fw-bolder fs-6 text-capitalize'>
                  {capitalizeFirstLetterOnly(trip?.tripType)}
                </div>
              </Col>
            ) : null}
            <Col>
              <div className='fw-bold text-muted d-block fs-7'>Travellers</div>
              <div className='text-dark fw-bolder fs-6'>
                {getMemberCount(orderData?.data?.memberCount)}
              </div>
            </Col>
            {isInsuranceOrder && orderData?.data?.trip && (
              <Col>
                <div className='fw-bold text-muted d-block fs-7'>Travel Duration</div>
                <div className='text-dark fw-bolder fs-6 text-capitalize'>
                  {getTravelDuration(orderData?.data?.trip)}
                </div>
              </Col>
            )}
          </Row>
          <h3 className='text-dark fw-bolder mb-4 mt-4 text-capitalize'>
            {params?.orderType} Selected
          </h3>
          <Row>
            <Col>
              {formState?.tripDetails?.ancillaryType === 'EVISA' ? (
                <VisaProductCard product={formState?.tripDetails} />
              ) : (
                <InsuranceProductCard product={formState?.tripDetails} />
              )}
            </Col>
          </Row>
          <Row>
            <Col className='text-end'>
              <Button size='sm' variant='primary' onClick={() => props?.onClickStep(2)}>
                <span className='indicator-label'>Continue</span>
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </div>
  );
};

export default TravelDetail;

/**
 * Helper Functions -----------------------------------------------------------
 */
const getTravelDuration = (tf: any) => {
  let td = 'N/A - N/A';
  let tripStart = tf?.tripStart?.datetime;
  let tripEnd = tf?.tripEnd?.datetime;
  const formattedTripStart = tripStart && format(parseISO(tripStart), 'dd LLL, yyyy');
  const formattedTripEnd = tripEnd && format(parseISO(tripEnd), 'dd LLL, yyyy');

  if (formattedTripStart && formattedTripEnd) td = `${formattedTripStart} - ${formattedTripEnd}`;
  else td = formattedTripStart;

  return td;
};

const getArrival = (info: any) => {
  let arrival = info[0]?.countryCode?.includes(',')
    ? info[0]?.countryCode
        ?.split(',')
        .map((obj: any) => {
          return getCountryName(obj);
        })
        ?.join(', ')
    : info
        .map((obj: any) => {
          return getCountryName(obj?.countryCode);
        })
        ?.join(', ');

  return arrival;
};

const getMemberCount = (tf: Array<any>) => {
  return tf?.map((obj: any) => `${obj?.count} ${capitalize(obj?.type)}`)?.join(', ');
};
