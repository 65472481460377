/* eslint-disable no-unused-vars */
import { Paper } from '@mui/material';

import AddonServices from '../../../shared/AddonServices';
import { OrderSectionHeader } from '../../../shared/OrderSectionHeader';

interface IAddonServicesSectionProps {
  isVisa: boolean;
  addonServices: Array<any>;
  onChangeAddonService: (event: any) => void;
  values?: any[];
}

export function AddonServicesSection(props: IAddonServicesSectionProps) {
  const { addonServices, isVisa } = props;

  return addonServices?.length > 0 && isVisa ? (
    <Paper variant='outlined' className='addon-services w-100 p-6 mb-6'>
      <OrderSectionHeader title='Premium services' subtitle='Add other premium services' />
      <AddonServices
        data={addonServices}
        values={props?.values}
        handleChange={props?.onChangeAddonService}
        type='add'
      />
    </Paper>
  ) : null;
}
