import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import { isUndefined } from 'lodash';
import classNames from 'classnames';
import Big from 'big.js';

import AddGstNumberForm from '../../../order-create/components/ReviewOrder/AddOrEditGstForm';
import ReviewOrderTable from '../../../order-create/components/ReviewOrder/ReviewOrderTable';
import { ORDER_STATUS, ORDER_SUBMITTED_STATUS_ARR, ORDER_TYPE } from '../../../constants';
import OAModalComponent from '../../../../../components/OAModalComponent';
import { useOrderContext } from '../../../context/OrderContext';
import { InvoiceType, IOrderType } from '../../../types';
import AddonServicesModal from './AddonServicesModal';
import { OATypography } from '../../../../../components/widgets';
import OAButton from '../../../../../components/widgets/OAButton';
import OrderInvoice from './OrderInvoice';
import { useAuth } from '../../../../../auth';
import InvoicePdf from './OrderInvoice/InvoicePdf';
import OrgProvider from '../../../../org-management/context/OrgProvider';
import { getInvoiceRequestBody } from '../../../helper/getInvoiceRequestBody';
import Preview from '../../../../quotation/create/Preview';
import { getTotalAmount } from './OrderInvoice/helper';

import { ReactComponent as EditIcon } from '../../../../../assets/icons/icon_edit_2.svg';
import { ReactComponent as DownloadIcon } from '../../../../../assets/icons/icon_download.svg';
import CreateQuoteModal from './ShareQuote/CreateQuoteModal';
import AddOrEditMarkupForm from '../../../order-create/components/ReviewOrder/AddOrEditMarkupForm';
import { showToast } from '../../../../../helper';

interface IPaymentSummary {
  isEditable: boolean;
  isReviewrequired: boolean;
}

export default function PaymentSummary(props: IPaymentSummary) {
  const params = useParams<{ orderType: IOrderType; id?: string }>();
  const { currentUser } = useAuth();

  const {
    formState,
    orderData,
    draftOrder,
    // onDownloadQuote,
    onDownloadVisaInvoice,
    onEditDraftOrder,
    getOrderById,
    updateProforma,
    getDraftOrderQuotation,
    onUpdateDraftQuote
  } = useOrderContext();

  const printRef = React.useRef(null);

  const [isAddonModalOpen, setIsAddonModalOpen] = useState<boolean>(false);
  const [isGstModalOpen, setIsGstModalOpen] = useState<boolean>(false);
  // const [isQuoteDownloading, setIsQuoteDownloading] = useState<boolean>(false);
  const [isRequestingInvoice, setIsRequestingInvoice] = useState<{
    type: InvoiceType;
    loading: boolean;
  } | null>(null);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState<boolean>(false);
  const [isProformaModalOpen, setProformaModalOpen] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [shareQuote, setShareQuote] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { id: orderId, bookingStatus, markup, taxInfo } = orderData?.data;

  const toggleInvoiceModal = () => setIsInvoiceModalOpen(prev => !prev);

  const isInvoiceReadyToDownload = ORDER_SUBMITTED_STATUS_ARR.includes(bookingStatus);
  /**
   * NOTE: if bookingStatus is "ORDER_QUOTE" then make isQuotation to true...
   */
  const isQuotation = orderData?.data?.bookingStatus === ORDER_STATUS.ORDER_QUOTE;

  // const onClickQuoteDownload = () => {
  //   setIsQuoteDownloading(true);

  //   let draftObj: any = markup?.tax?.totalAmountWithoutTax
  //     ? {
  //         amount: markup?.tax?.totalAmountWithoutTax
  //       }
  //     : null;

  //   onDownloadQuote(params?.orderType as IOrderType, draftObj, (_res: any) => {
  //     setIsQuoteDownloading(false);
  //   });
  // };

  const onClickRequestInvoice = (type: InvoiceType) => {
    setIsRequestingInvoice({ type, loading: true });
    onDownloadVisaInvoice(params?.id!, type, (_res: any) => {
      setIsRequestingInvoice(null);
    });
  };

  const hasAddonServices =
    formState?.tripDetails?.productInformation?.productAmount?.additionalAmountModifiers?.filter(
      (obj: any) => obj?.type === 'ADD_ON_SERVICE_FEE'
    )?.length;

  const onSubmitGst = (values: any) => {
    onEditDraftOrder(orderId, params?.orderType as IOrderType, values, _res => {
      getOrderById(params?.orderType as IOrderType, orderId);
    });
    setIsGstModalOpen(false);
  };

  const toggleGstModal = () => setIsGstModalOpen(prev => !prev);
  const toggleEditProforma = () => setProformaModalOpen(prev => !prev);

  const taxRegistrationNumber =
    formState?.taxInfo?.taxRegistrationNumber ?? taxInfo?.taxRegistrationNumber;

  const onAmountCollectedChange = (modifier: any) => {
    const { orderAmount } = orderData?.data;

    let draftTotal = Big(orderAmount?.totalAmount);
    let draftSubtotal = Big(orderAmount?.subtotalAmount);

    let draftAdditionalAmountModifiers = orderAmount?.additionalAmountModifiers;
    /**
     * NOTE: for old orders, 'PRODUCT_FEE' modifier is not present in...
     */
    if (!draftAdditionalAmountModifiers?.find((obj: any) => obj?.type === 'PRODUCT_FEE')) {
      const { product } = orderData?.data;

      draftAdditionalAmountModifiers?.push({
        type: 'PRODUCT_FEE',
        name: product?.displayName,
        amount: product?.productInformation?.productAmount?.baseAmount,
        quantity: orderAmount?.memberCount,
        currency: orderAmount?.currency,
        amountCollected: true
      });
    }

    const draftData = orderAmount?.additionalAmountModifiers?.map((obj: any) => {
      if (
        obj?.type === modifier?.key &&
        (obj?.type === 'PRODUCT_FEE' || obj?.type === 'VAC_SERVICE_CHARGE')
      ) {
        const draftAmt = getTotalAmount(
          modifier?.amountCollected,
          obj?.amount,
          draftTotal,
          draftSubtotal
        );
        draftTotal = draftAmt?.total;
        draftSubtotal = draftAmt?.subtotal;

        const draftObj = isUndefined(obj?.quantity)
          ? Object.assign({}, obj, { quantity: orderAmount?.memberCount })
          : obj;

        return { ...draftObj, amountCollected: modifier?.amountCollected };
      } else if (obj?.type === modifier?.key && obj?.name === modifier?.name) {
        const draftAmt = getTotalAmount(
          modifier?.amountCollected,
          obj?.amount,
          draftTotal,
          draftSubtotal
        );
        draftTotal = draftAmt?.total;
        draftSubtotal = draftAmt?.subtotal;

        const draftObj = isUndefined(obj?.quantity)
          ? Object.assign({}, obj, { quantity: orderAmount?.memberCount })
          : obj;

        return { ...draftObj, amountCollected: modifier?.amountCollected };
      } else {
        const draftObj = isUndefined(obj?.quantity)
          ? Object.assign({}, obj, { quantity: orderAmount?.memberCount })
          : obj;
        return draftObj;
      }
    });

    const requestBody = getInvoiceRequestBody(
      {
        data: draftData,
        orderId: params?.id!,
        totalAmount: draftTotal.toFixed(2),
        subtotalAmount: draftSubtotal.toFixed(2)
      },
      'proforma'
    );
    updateProforma(requestBody);
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current
  });

  const toggleModal = (open?: boolean) => setIsOpen(prev => !prev);

  const onMarkup = (value: any) => {
    setIsLoading(true);
    formState.travellerDetails = formState?.travellerDetails?.length
      ? formState?.travellerDetails
      : draftOrder?.members;

    if (formState?.quotation) {
      const draft = {
        occupation: orderData?.data?.occupation,
        memberCount: orderData?.data?.memberCount,
        markup: value?.markup
      };
      onUpdateDraftQuote(params?.id!, params?.orderType as IOrderType, draft, (_res: any) => {
        if (_res?.status === 200) {
          showToast('Markup modified successfully', 'success');
        }
        toggleModal();
      });
    } else {
      getDraftOrderQuotation(params?.orderType as IOrderType, null, value, (_res: any) => {
        setIsLoading(false);
        toggleModal();
      });
    }
  };

  return (
    <div className='payment-summary card'>
      <div className='card-header align-items-center border-0'>
        <OATypography variant='h5'>
          {params?.orderType === ORDER_TYPE.VISA
            ? orderData?.data?.invoiceAmount
              ? 'Invoice'
              : 'Proforma invoice'
            : 'Order review'}
        </OATypography>
        <div className='d-flex align-items-center gap-2 gap-lg-3'>
          {isQuotation ? (
            <Fragment>
              <OAButton
                color='light-primary'
                label='Share'
                onClick={() => setShareQuote(true)}
                className='btn-active-light-primary'
              />
              <OAButton label='Preview' onClick={() => setShowPreview(true)} />
            </Fragment>
          ) : null}
          {params?.orderType === ORDER_TYPE.VISA && isQuotation ? (
            <Fragment>
              <OAButton
                label={formState?.markup ? 'Edit Markup' : 'Add Markup'}
                color='light-primary'
                className='btn-active-light-primary'
                iconComponent={<EditIcon />}
                onClick={() => toggleModal()}
              />
            </Fragment>
          ) : null}
          {params?.orderType === ORDER_TYPE.VISA &&
          hasAddonServices &&
          !orderData?.data?.invoiceAmount ? (
            <OAButton
              label={formState?.addOnServiceModifier?.length ? 'Modify Addons' : 'Add Addons'}
              color='light-primary'
              className='btn-active-light-primary'
              onClick={() => setIsAddonModalOpen(true)}
              iconComponent={<EditIcon />}
            />
          ) : null}
          {props?.isEditable && (
            <React.Fragment>
              {params?.orderType === ORDER_TYPE.INSURANCE && (
                <OAButton
                  label={`${taxInfo?.taxRegistrationNumber ? 'Edit' : 'Add'} GST number`}
                  color='light-primary'
                  className='btn-active-light-primary me-3'
                  onClick={() => setIsGstModalOpen(true)}
                />
              )}
            </React.Fragment>
          )}
          {/* TODO: removed temporarily. once quote api fixed enable it... */}
          {/* {params?.orderType === ORDER_TYPE.VISA && !orderData?.data?.invoiceAmount ? (
            <OAButton
              color='light-primary'
              className='btn-active-light-primary'
              disabled={isQuoteDownloading}
              onClick={onClickQuoteDownload}
              label={isQuoteDownloading ? 'Downloading...' : 'Send quote'}
              iconComponent={<DownloadIcon />}
            />
          ) : null} */}
          {/**
           * NOTE: 'Edit Proforma' button is visible if invoice has not been generated...
           */}
          {params?.orderType === ORDER_TYPE.VISA &&
          !orderData?.data?.invoiceAmount &&
          !isQuotation ? (
            <OAButton
              label='Edit Proforma'
              color='light-primary'
              onClick={toggleEditProforma}
              className='btn-active-light-primary'
              iconComponent={<EditIcon />}
            />
          ) : null}
          {/**
           * NOTE: Only super admin can see 'Generate Invoice' or 'Edit invoice'
           * button post draft stage ...
           */}
          {params?.orderType === ORDER_TYPE.VISA &&
          currentUser?.data?.role === 'ROLE_SUPER_ADMIN' &&
          !props?.isEditable &&
          !isQuotation ? (
            <React.Fragment>
              <OAButton
                label={orderData?.data?.invoiceAmount ? 'Edit invoice' : 'Generate Invoice'}
                onClick={toggleInvoiceModal}
                color={orderData?.data?.invoiceAmount ? 'light-primary' : 'primary'}
                className={classNames({
                  'btn-active-light-primary': orderData?.data?.invoiceAmount
                })}
                iconComponent={<EditIcon />}
              />
              {/**
               * NOTE: Only super admin can see 'Download Invoice' button post
               * creation of invoice ...
               */}
              {orderData?.data?.invoiceAmount ? (
                <OAButton
                  label='Download Invoice'
                  onClick={handlePrint}
                  iconComponent={<DownloadIcon />}
                />
              ) : null}
            </React.Fragment>
          ) : null}
          {params?.orderType === ORDER_TYPE.VISA && isInvoiceReadyToDownload && (
            <React.Fragment>
              <Button
                size='sm'
                className='btn-light-primary btn-active-light-primary me-3'
                disabled={
                  isRequestingInvoice?.type === 'SUPPLIER_INVOICE' && isRequestingInvoice?.loading
                }
                onClick={() => onClickRequestInvoice('SUPPLIER_INVOICE')}>
                Supplier Invoice
              </Button>
              <Button
                size='sm'
                className='btn-light-primary btn-active-light-primary me-3'
                disabled={
                  isRequestingInvoice?.type === 'EMBASSY_INVOICE' && isRequestingInvoice?.loading
                }
                onClick={() => onClickRequestInvoice('EMBASSY_INVOICE')}>
                Embassy Invoice
              </Button>
            </React.Fragment>
          )}
        </div>
      </div>
      <div className='separator' />
      {taxInfo?.taxRegistrationNumber && (
        <div className='ps-6 mb-2 mt-5'>
          GST Number:
          <span className='text-dark fw-bolder fs-5 ps-3'>{taxInfo?.taxRegistrationNumber}</span>
        </div>
      )}
      <div className='card-body py-0'>
        {params?.orderType === ORDER_TYPE.VISA ? (
          <OrderInvoice
            data={orderData?.data}
            handleAmountCollected={onAmountCollectedChange}
            editable={false}
            type={orderData?.data?.invoiceAmount ? 'invoice' : 'proforma'}
          />
        ) : (
          <ReviewOrderTable data={orderData?.data} />
        )}
      </div>
      {params?.orderType === ORDER_TYPE.VISA && (
        <OrgProvider>
          <div style={{ position: 'absolute', visibility: 'hidden' }}>
            <InvoicePdf ref={printRef} data={orderData?.data} />
          </div>
        </OrgProvider>
      )}
      <AddonServicesModal isOpen={isAddonModalOpen} onHide={() => setIsAddonModalOpen(false)} />
      <OAModalComponent
        show={isGstModalOpen}
        onHide={toggleGstModal}
        header={{
          title: `${taxRegistrationNumber ? 'Edit' : 'Add'} GST Number`,
          closeButton: true
        }}
        body={{
          content: (
            <AddGstNumberForm
              onHide={toggleGstModal}
              onGstSubmit={onSubmitGst}
              value={taxRegistrationNumber}
            />
          )
        }}
      />
      {params?.orderType === ORDER_TYPE.VISA && (
        <OAModalComponent
          show={isProformaModalOpen}
          onHide={() => setProformaModalOpen(false)}
          size='xl'
          header={{
            title: 'Proforma Invoice',
            closeButton: true
          }}
          body={{
            content: (
              <OrderInvoice
                onHide={() => setProformaModalOpen(false)}
                data={orderData?.data}
                handleAmountCollected={onAmountCollectedChange}
                editable
                type='proforma'
              />
            ),
            className: 'pt-0'
          }}
        />
      )}
      {params?.orderType === ORDER_TYPE.VISA && (
        <OAModalComponent
          show={isInvoiceModalOpen}
          onHide={() => setIsInvoiceModalOpen(false)}
          size='xl'
          header={{
            title: 'Generate Invoice',
            closeButton: true
          }}
          body={{
            content: (
              <OrderInvoice
                onHide={() => setIsInvoiceModalOpen(false)}
                data={orderData?.data}
                handleAmountCollected={onAmountCollectedChange}
                editable
                type='invoice'
              />
            ),
            className: 'pt-0'
          }}
        />
      )}
      <OAModalComponent
        show={showPreview}
        onHide={() => setShowPreview(false)}
        size='lg'
        noHeader
        body={{
          content: <Preview previewType='elaboration' />,
          className: 'pt-0'
        }}
      />

      <OAModalComponent
        className='add-or-edit-markup--modal'
        show={isOpen}
        onHide={toggleModal}
        header={{
          title: `${formState?.markup?.amount ? 'Edit' : 'Add'} Price Markup`,
          closeButton: true
        }}
        body={{
          content: (
            <AddOrEditMarkupForm
              onHide={toggleModal}
              onMarkupSubmit={onMarkup}
              value={formState?.markup?.amount}
            />
          )
        }}
      />

      <CreateQuoteModal
        isOpen={shareQuote}
        toggle={() => setShareQuote(false)}
        consulateData={orderData?.data?.consulate}
      />
    </div>
  );
}
