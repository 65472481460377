import { useContext, useState } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';

import OALabelValue from '../../../../../components/widgets/OALabelValue';
import OtherInfoFormModal from './OtherInfoFormModal';
import OrderContext from '../../../context/OrderContext';
import { getFormattedDate } from '../../../../../helper';
import { OA_DATE_TIME_FORMAT_FE } from '../../../../../constants';

function OtherInfo() {
  const { orderData } = useContext(OrderContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  let paymentInfoArr;
  const paymentReference = orderData?.data?.orderAmount?.paymentReference;
  if (paymentReference) {
    paymentInfoArr = [
      {
        label: 'Booking Reference',
        value: paymentReference?.bookingReference ?? 'N/A'
      },
      {
        label: 'Order Reference',
        value: paymentReference?.orderReference ?? 'N/A'
      },
      {
        label: 'Payment At',
        value: paymentReference?.paymentDateTime
          ? getFormattedDate(paymentReference?.paymentDateTime, OA_DATE_TIME_FORMAT_FE)
          : 'N/A'
      }
    ];
  } else {
    paymentInfoArr = [
      {
        label: 'Client name',
        value: orderData?.data?.additionalOrderInfo?.clientName
          ? orderData?.data?.additionalOrderInfo?.clientName
          : 'N/A'
      },
      {
        label: 'Payment reference',
        value: orderData?.data?.additionalOrderInfo?.paymentReference
          ? orderData?.data?.additionalOrderInfo?.paymentReference
          : 'N/A'
      },
      {
        label: 'Search tags',
        value: orderData?.data?.additionalOrderInfo?.tags?.length
          ? orderData?.data?.additionalOrderInfo?.tags?.join(', ')
          : 'N/A'
      }
    ];
  }

  return (
    <div id='other_info' className='other-info card'>
      <div className='card-header align-items-center border-0'>
        <h3 className='card-title align-items-start flex-column'>
          <div className='d-flex align-items-center text-gray-800 fw-bolder'>
            <span className='fw-bolder text-dark'>Other information</span>
          </div>
        </h3>
        {!paymentReference && (
          <div className='d-flex align-items-center gap-2 gap-lg-3'>
            <Button
              size='sm'
              className='btn-light-primary btn-active-light-primary me-3'
              onClick={() => setIsOpen(true)}>
              Edit information
            </Button>
          </div>
        )}
      </div>
      <div className='separator' />
      {orderData?.isLoading ? (
        <div className='card-body d-flex align-items-center justify-content-center text-muted fw-bold'>
          <Spinner animation='border' className='me-4' /> Loading...
        </div>
      ) : (
        <div className='card-body'>
          <Row>
            {paymentInfoArr?.map((obj, idx) => (
              <Col key={idx} md='4'>
                <OALabelValue label={obj?.label} value={obj?.value} />
              </Col>
            ))}
          </Row>
        </div>
      )}
      <OtherInfoFormModal show={isOpen} onHide={() => setIsOpen(false)} />
    </div>
  );
}

export default OtherInfo;
