/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';

import { IUserFormValues } from '../types';
import { IPageInfo } from '../../../components/OATableComponent';

export interface IUserContext {
  users: Array<any>;
  isOpen: boolean;
  selectedUser: any;
  usersList: Array<any>;
  hasMoreUsers: boolean;
  isMoreUsersLoading: boolean;
  pageInfo: IPageInfo;
  isLoading: boolean;
  fetchUsers: (search?: any, loadMore?: boolean) => Promise<void>;
  onPageChange: (value: any) => void;
  toggleModal: () => void;
  onClickCreateUser: () => void;
  onCreateUser: (values: IUserFormValues, callback?: (success: boolean) => void) => Promise<void>;
  onSelectEditUser: (userId: string) => void;
  onEditUser: (
    userId: string,
    values: IUserFormValues,
    callback?: (success: boolean) => void
  ) => Promise<void>;
  onDeleteUser: (userId: string, callback?: () => void) => Promise<void>;
  onPasswordReset: (requestbody: any, callback?: (response: any) => void) => Promise<void>;
  setIsMoreUsersLoading: (isLoading: boolean) => void;
  setHasMoreUsers: (hasMore: boolean) => void;
  setUsersList: (users: Array<any>) => void;
}

const UserContext = React.createContext<IUserContext>({} as IUserContext);

export default UserContext;

export const useUserContext = () => useContext(UserContext);
