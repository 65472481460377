import { Fragment } from 'react';
import { ErrorMessage, useField } from 'formik';
import classNames from 'classnames';

interface IOACheckboxField {
  label?: string;
  name: string;
  // placeholder?: string;
  required?: boolean;
  // inputClassName?: string;
  className?: string;
  size?: 'sm' | 'lg';
}

export default function OACheckboxField(props: IOACheckboxField) {
  const {
    label,
    name,
    required = false,
    // inputClassName = '',
    // placeholder = 'Type here',
    size = 'md',
    className = ''
  } = props;

  const [{ value, checked, onChange, ...rest }, _meta, helpers] = useField(name);

  const labelClassNames = classNames('form-label mb-0 ms-3', {
    required: required
  });

  const rootClasses = classNames('form-check form-check-custom form-check-solid', className, {
    'form-check-sm': size === 'sm',
    'form-check-lg': size === 'lg'
  });

  return (
    <Fragment>
      <div className={rootClasses}>
        <input
          className='form-check-input'
          type='checkbox'
          id={name}
          checked={value}
          onChange={e => helpers?.setValue(e?.target?.checked)}
          {...rest}
        />
        <label htmlFor={name} className={labelClassNames}>
          {label}
        </label>
      </div>
      <div className='text-danger'>
        <ErrorMessage name={name} />
      </div>
    </Fragment>
  );
}
