interface IMemberCell {
  data: any;
  colorScheme: string;
}

export default function MemberCell(props: IMemberCell) {
  const { data } = props;

  const shortName = data?.firstName?.charAt(0).toUpperCase();
  const fullName = `${data?.title ? `${data?.title} ` : ``}${data?.firstName} ${data?.lastName}`;

  return (
    <div className='d-flex align-items-center'>
      <div className='oa-symbol oa-symbol-circle d-flex align-items-center justify-content-center'>
        <span className='oa-symbol-label' style={{ background: props?.colorScheme }}>
          {shortName}
        </span>
      </div>
      <div className='d-flex flex-column ms-2'>
        <span className='text-dark fw-bold'>{fullName}</span>
        {data?.email ? <span className='text-muted'>{data?.email}</span> : null}
      </div>
    </div>
  );
}
