import classNames from 'classnames';
import { isNull } from 'lodash';

import { IMember } from '../../types';
import { OAHoverPopover, OATypography } from '../widgets';

interface ITravellersCell {
  members: Array<IMember>;
  showPrimary?: boolean;
  disabled?: boolean;
}

export default function TravellersCell(props: ITravellersCell) {
  const { members = [] } = props;

  let initMember = props?.showPrimary ? members.find((obj: IMember) => obj?.primary) : members?.[0];
  let otherMembers = props?.showPrimary
    ? members.filter((obj: IMember) => !obj?.primary)
    : members?.slice(1);

  const popperComponent = (
    <div className='pe-2'>
      {otherMembers.map((obj: IMember) => (
        <MemberDisplay key={obj.id} member={obj} secondary disabled={props?.disabled} />
      ))}
    </div>
  );

  return (
    <div className='d-flex align-items-center'>
      {initMember && <MemberDisplay member={initMember} disabled={props?.disabled} />}
      {otherMembers.length > 0 && (
        <OAHoverPopover popperComponent={popperComponent}>
          <OATypography color='primary'>{`+${otherMembers?.length} more`}</OATypography>
        </OAHoverPopover>
      )}
    </div>
  );
}

interface IMemberDisplay {
  member?: IMember;
  secondary?: boolean;
  disabled?: boolean;
  name?: string;
}

export const MemberDisplay = (props: IMemberDisplay) => {
  const { member, secondary = false, disabled = false, name = null } = props;

  const shortName = isNull(name)
    ? member?.firstName?.charAt?.(0)?.toUpperCase?.()
    : name?.charAt?.(0)?.toUpperCase?.();
  const fullName = isNull(name) ? `${member?.firstName} ${member?.lastName}` : name;

  return (
    <div className='d-flex align-items-center p-3 pe-1'>
      <div className='symbol symbol-circle symbol-20px overflow-hidden me-2'>
        <div
          className={classNames('symbol-label fs-7', {
            'bg-info text-light': !secondary && !disabled,
            'bg-secondary': secondary || disabled,
            'text-muted': disabled
          })}>
          {shortName}
        </div>
      </div>
      <div
        className={classNames('d-flex flex-column text-capitalize fw-bold fs-6', {
          'text-muted': disabled
        })}>
        {fullName}
      </div>
    </div>
  );
};
