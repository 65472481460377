import { Col, Container, Modal, Row } from 'react-bootstrap';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { OAPhoneField, OATextField } from '../../../components/fields';
import { usePhonepe } from '../../../hooks/usePhonepe';
import OAButton from '../../../components/widgets/OAButton';

interface IAddBalanceModal {
  isOpen: boolean;
  toggle: () => void;
}

interface IBalanceForm {
  amount: number;
  phone: string;
}

const init: IBalanceForm = {
  amount: 0,
  phone: ''
};

const balanceValidation = Yup.object({
  amount: Yup.number().positive().required('Please enter balance').min(1),
  phone: Yup.string()
    .required()
    .min(13, 'Mobile number must be at least 10 characters')
    .max(13, 'Mobile number must be at least 10 characters')
});

const AddBalanceModal = (props: IAddBalanceModal) => {
  const { isOpen, toggle } = props;
  const { paymentInitiate } = usePhonepe();

  const submitHandler = async (values: IBalanceForm) => {
    let draft = {
      amount: (values?.amount).toFixed(2),
      phone: values?.phone?.trim().slice(-10),
      redirectUrl: window.location.href
    };
    let paymentResult = await paymentInitiate(draft);
    paymentResult ? (window.location.href = paymentResult) : null;
    toggle();
  };

  return (
    <Modal show={isOpen} onHide={toggle} centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Add Balance</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={init}
          onSubmit={submitHandler}
          validationSchema={balanceValidation}
          enableReinitialize>
          {(formikProps: FormikProps<IBalanceForm>) => {
            return (
              <Container fluid as={Form} className='p-0'>
                <Row className='mb-8'>
                  <Col lg={6}>
                    <OATextField name='amount' label='Amount(₹)' type='number' required />
                  </Col>
                  <Col lg={6}>
                    <OAPhoneField name='phone' label='Mobile Number' />
                  </Col>
                </Row>
                <Row>
                  <Col className='text-end'>
                    <OAButton
                      label='Cancel'
                      onClick={() => {
                        formikProps?.resetForm();
                        toggle();
                      }}
                      className='me-5 btn-light-primary btn-active-light-primary'
                    />
                    <OAButton
                      type='submit'
                      className='ms-auto btn-primary btn-active-primary btn-flex'
                      disabled={
                        !(formikProps?.isValid && formikProps?.dirty) || formikProps?.isSubmitting
                      }
                      label='Save'
                      isLoading={formikProps?.isSubmitting}
                      loadingText='Saving...'
                    />
                  </Col>
                </Row>
              </Container>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddBalanceModal;
