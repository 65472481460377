import { FC, useState, MouseEvent } from 'react';
import { Avatar, Box, Popover, Theme } from '@mui/material';
import { useOrderContext } from '../../pages/order-management/context/OrderContext';
import { IMember, ITask } from '../../types';
import { OATypography } from '../widgets';

const avatarStyles = {
  fontSize: '12px',
  width: 24,
  height: 24,
  bgcolor: (theme: Theme) => theme.palette.info.main
};

const popoverStyles = {
  pointerEvents: 'none'
};

interface IAvatarDisplay {
  task: ITask;
}

const AvatarDisplay: FC<IAvatarDisplay> = ({ task }) => {
  const { orderData } = useOrderContext();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  let memberChar, name;

  const { memberId } = task;
  const { members } = orderData?.data || {};

  if (memberId && members) {
    const member = members.find((m: IMember) => m.id === memberId);

    if (member) {
      const { firstName, lastName } = member;
      name = `${firstName} ${lastName}`;
      memberChar = `${firstName[0]}${lastName[0]}`;
    }
  }

  const openPopover = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  return (
    <>
      {memberChar && (
        <Box onMouseEnter={openPopover} onMouseLeave={closePopover}>
          <Avatar sx={avatarStyles}>{memberChar}</Avatar>
          <Popover
            sx={popoverStyles}
            open={isOpen}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right'
            }}
            onClose={closePopover}
            disableRestoreFocus>
            <OATypography variant='tooltip' sx={{ p: '8px' }}>
              {name}
            </OATypography>
          </Popover>
        </Box>
      )}
    </>
  );
};

export default AvatarDisplay;
