import React from 'react';
import { IconType } from '.';

export default function ApprovedSVG(props: IconType) {
  const { color = '#74CA8F', height = '15', width = '15', className = '' } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 16 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.09085 1.19357C7.54823 0.593103 8.45177 0.593103 8.90915 1.19357L10.0076 2.63572C10.2292 2.92661 10.5766 3.09392 10.9422 3.08578L12.7546 3.04546C13.5093 3.02867 14.0726 3.73508 13.8883 4.46706L13.4457 6.22506C13.3564 6.57968 13.4422 6.9556 13.6765 7.23635L14.8381 8.62822C15.3217 9.20774 15.1207 10.0886 14.4335 10.4009L12.783 11.151C12.4501 11.3023 12.2097 11.6037 12.1363 11.962L11.7723 13.7379C11.6208 14.4774 10.8067 14.8694 10.1341 14.5268L8.51865 13.7041C8.19279 13.5382 7.80721 13.5382 7.48135 13.7041L5.86592 14.5268C5.1933 14.8694 4.37924 14.4774 4.22768 13.7379L3.8637 11.962C3.79028 11.6037 3.54987 11.3023 3.21695 11.151L1.56653 10.4009C0.879338 10.0886 0.678281 9.20775 1.16191 8.62822L2.32347 7.23636C2.55777 6.9556 2.64357 6.57968 2.55429 6.22506L2.11167 4.46706C1.92738 3.73508 2.49073 3.02867 3.24536 3.04546L5.05778 3.08578C5.42337 3.09392 5.77077 2.92661 5.99235 2.63572L7.09085 1.19357Z'
        fill={color}
      />
      <path
        d='M5.14258 7.98893L6.83723 9.47174C6.87275 9.50282 6.92647 9.50031 6.95894 9.46604L10.8569 5.35156'
        stroke='white'
        stroke-width='0.857143'
        stroke-linecap='round'
      />
    </svg>
  );
}
