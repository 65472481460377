import { GridColDef } from '@mui/x-data-grid-pro';
import { GridColumnHeader } from '../../../components/OATableComponent';
import { OATypography } from '../../../components/widgets';
import { Box, Skeleton } from '@mui/material';
import { ReactComponent as DotSeparatorIcon } from '../../../assets/icons/dot_separator.svg';
import { ReactComponent as DoubleArrowIcon } from '../../../assets/icons/Bi-directionArrows.svg';

type getFlightColumnsType = (loading: boolean) => GridColDef[];

export const paymentColumns: getFlightColumnsType = loading => {
  let draft: Array<GridColDef> = [
    {
      field: 'product',
      renderHeader: params => <GridColumnHeader title='Product' params={params} />,
      renderCell: params => {
        let split = params?.row?.label?.split('|');
        let newLabel = (
          <>
            {split[0]} <DoubleArrowIcon /> {split[1]}
          </>
        );

        return (
          !params?.row?.label?.includes('Total') &&
          (params?.row?.header ? (
            params?.row?.label?.includes('Taxes & fees') && params?.row?.roundTrip ? (
              <Box display='flex' alignItems='center' gap='8px' paddingLeft='24px'>
                <DotSeparatorIcon width={8} height={8} fill='#011229' />
                <OATypography variant='body1'>{params?.row?.label}</OATypography>
              </Box>
            ) : (
              <OATypography variant='body1'>
                {params?.row?.label?.includes('|') ? newLabel : params?.row?.label}{' '}
              </OATypography>
            )
          ) : params?.row?.roundTrip ? (
            <OATypography
              variant='body2'
              sx={{ paddingLeft: '40px', color: params?.row?.success ? 'success.main' : '' }}>
              {params?.row?.label}
            </OATypography>
          ) : (
            !params?.row?.label?.includes('Refunded to Bank A/C') && (
              <OATypography
                variant='body2'
                sx={{ paddingLeft: '16px', color: params?.row?.success ? 'success.main' : '' }}>
                {params?.row?.label}
              </OATypography>
            )
          ))
        );
      },
      flex: 2,
      minWidth: 200
    },
    {
      field: 'total',
      renderHeader: params => <GridColumnHeader title='' params={params} />,
      renderCell: params => {
        return params?.row?.label?.includes('Total') ? (
          <OATypography variant='subtitle2'>{params?.row?.label}</OATypography>
        ) : (
          params?.row?.label?.includes('Refunded to Bank A/C') && (
            <OATypography variant='body2' sx={{ color: 'grey.400' }}>
              {params?.row?.label}
            </OATypography>
          )
        );
      },
      flex: 2,
      minWidth: 200
    },
    {
      field: 'amount',
      renderHeader: params => <GridColumnHeader title='Amount' params={params} />,
      renderCell: params => {
        return (
          <Box sx={{ textAlign: 'right', width: '100%' }}>
            {params?.row?.label?.includes('Refunded to Bank A/C') ? (
              <OATypography variant='body2' sx={{ color: 'grey.400' }}>
                {params?.row?.value}
              </OATypography>
            ) : params?.row?.value ? (
              <OATypography
                variant={params?.row?.label?.includes('Total') ? 'subtitle2' : 'body2'}
                sx={{ color: params?.row?.success ? 'success.main' : '' }}>
                {params?.row?.value ?? '--'}
              </OATypography>
            ) : (
              <Skeleton variant='text' width={80} height={20} />
            )}
          </Box>
        );
      },
      width: 120,
      headerAlign: 'right',
      align: 'right'
    }
  ];
  return draft;
};
