import React, { useEffect, useState } from 'react';
import { Field, Form, Formik, FormikHelpers, FormikProps, useFormikContext } from 'formik';
import { Col, Container, Row } from 'react-bootstrap';
import isEqual from 'lodash/isEqual';
import moment from 'moment';

import {
  CountryOptions,
  DepartureCountries,
  DestinationOptions,
  DestinationOptionsMap,
  TripTypeOptions,
  ORDER_TYPE,
  countryOptions
} from '../../../constants';
import { useOrderContext } from '../../../context/OrderContext';
import OASelectField from '../../../../../components/fields/OASelectField';
import OARangePickerField from '../../../../../components/fields/OARangePickerField';
import OADatePickerField from '../../../../../components/fields/OADatePickerField';
import OAMultiSelectField from '../../../../../components/fields/OAMultiSelectField';
import { dateDiffInsurance } from '../../../../../helper/dateFormat';
import OAButton from '../../../../../components/widgets/OAButton';

const inits: any = {
  insuranceType: '',
  destination: '',
  tripType: '',
  visaType: '',
  departure: '',
  arrival: '',
  tripDuration: {},
  travelDate: ''
};

const validate = (values: any) => {
  const errors: any = {};

  const isStandardInsurance = values?.insuranceType === 'STANDARD_TRAVEL_INSURANCE';
  const isCFAR = values?.insuranceType === 'CFAR_FOR_HOTEL';
  const isBusInsurance = values?.insuranceType === 'BUS_INSURANCE';
  const isCabInsurance = values?.insuranceType === 'CAB_INSURANCE';
  const isDomestic = values?.destination === DestinationOptionsMap?.DOMESTIC;
  /**
   * NOTE: validation for 'insuranceType' field...
   */
  if (!values?.insuranceType) {
    errors.insuranceType = 'Insurance type is a required field';
  }
  /**
   * NOTE: validation for 'destination' field...
   */
  if (!values.destination) {
    errors.destination = 'Destination is a required field';
  }
  /**
   * NOTE: validation for 'tripType' field...
   */
  if (isDomestic && !(isBusInsurance || isCabInsurance) && !values?.tripType) {
    errors.tripType = 'Trip Type is a required field';
  }
  /**
   * NOTE: validation for 'departure' field...
   */
  if (values?.destination && !isDomestic && !values.departure) {
    errors.departure = 'Departure is a required field';
  }
  /**
   * NOTE: validation for 'arrival' field...
   */
  if (values?.destination && !isDomestic && !values.arrival) {
    errors.arrival = 'Arrival is a required field';
  }

  /**
   * NOTE: validation for 'tripDuration' field...
   */
  const duration_case_1 = isStandardInsurance && isDomestic && values?.tripType !== 'SINGLE';
  const duration_case_2 = isStandardInsurance && !isDomestic;
  const duration_case_3 = isCFAR || isBusInsurance;
  if (duration_case_1 || duration_case_2 || duration_case_3) {
    if (!(values?.tripDuration?.from && values?.tripDuration?.to)) {
      errors.tripDuration = 'Duration is a required field';
    } else if (
      !(
        moment(values?.tripDuration?.from).isValid() && moment(values?.tripDuration?.to).isValid()
      ) ||
      (values?.tripDuration?.from &&
        moment(values?.tripDuration?.from).isBefore(moment(), 'day')) ||
      (values?.tripDuration?.from &&
        values?.tripDuration?.to &&
        moment(values?.tripDuration?.to).isBefore(moment(values?.tripDuration?.from)))
    ) {
      errors.tripDuration = 'Invalid duration selected';
    }
  }

  let durationFrom =
    moment(values?.tripDuration?.from ?? values?.travelDate)?.diff(moment(), 'days') + 2;

  if (durationFrom >= 180 && values?.tripDuration?.from) {
    errors.tripDuration = 'The start Date should be below 180 days from the current date';
  } else if (durationFrom >= 180 && values?.travelDate) {
    errors.travelDate = 'The start Date should be below 180 days from the current date';
  }
  /**
   * NOTE: validation for 'travelDate' field...
   */
  const date_case_1 = isStandardInsurance && isDomestic && values?.tripType === 'SINGLE';
  const date_case_2 = isCabInsurance;
  let dateFrom = dateDiffInsurance(new Date(), values?.travelDate);
  if (date_case_1 || date_case_2) {
    if (!values?.travelDate) {
      errors.travelDate = 'Travel date is a required field';
    } else if (
      !moment(values?.travelDate).isValid() ||
      (values?.travelDate && moment(values?.travelDate).isBefore(moment(), 'day'))
    ) {
      errors.travelDate = 'Invalid date selected';
    }
  } else if (Math.sign(dateFrom) < 0) {
    errors.travelDate = 'The dates you selected is past the visa application deadline.';
  }

  return errors;
};

interface ISearchCoverForm {
  onSubmit: (values: any, type: string, actions?: FormikHelpers<any>) => void;
}

export default function SearchCoverForm(props: ISearchCoverForm) {
  const {
    formState,
    resetProducts,
    discoverMasterProduct,
    onAddCategory,
    updateFormState,
    getInsuranceCategory
  } = useOrderContext();

  const [initValues] = useState<any>(inits);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [prevValue, setPrevValue] = useState<any>(null);
  const [insuranceCategory, setInsuranceCategory] = useState<any>(null);

  useEffect(() => {
    formState.travellerDetails = [];
    const compareObjectResult = isEqual(prevValue, initValues);
    if (!compareObjectResult) {
      resetProducts();
    }
  }, [prevValue]);

  useEffect(() => {
    getInsuranceCategory(res => {
      setInsuranceCategory(res);
    });
  }, []);

  const handleSubmit = (values: any, actions: FormikHelpers<any>) => {
    setIsLoading(true);

    const isStandardInsurance = values?.insuranceType === 'STANDARD_TRAVEL_INSURANCE';
    const isCFAR = values?.insuranceType === 'CFAR_FOR_HOTEL';
    const isBusInsurance = values?.insuranceType === 'BUS_INSURANCE';
    const isDomestic = values?.destination === DestinationOptionsMap?.DOMESTIC;
    /**
     * NOTE: if below cases matches then show duration field else show travel
     * date field.
     */
    const showDuration_case_1 = isStandardInsurance && !!values?.destination && !isDomestic;
    const showDuration_case_2 =
      isStandardInsurance && isDomestic && values?.tripType && values?.tripType !== 'SINGLE';
    const showDuration_case_3 = isCFAR || isBusInsurance;

    const { tripDuration, travelDate, ...rest } = values;
    let _draft: any = rest;
    if (showDuration_case_1 || showDuration_case_2 || showDuration_case_3) {
      _draft['tripDuration'] = {
        from: new Date(tripDuration?.from),
        to: new Date(tripDuration?.to)
      };
    } else {
      _draft['tripDuration'] = {
        from: new Date(travelDate)
      };
    }

    onAddCategory(values?.insuranceType);
    /**
     * NOTE: update form state ...
     */
    updateFormState({
      tripDuration: _draft?.tripDuration,
      tripType: _draft?.tripType
    });

    discoverMasterProduct(ORDER_TYPE.INSURANCE, _draft, _res => {
      setIsLoading(false);
    });
  };

  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleSubmit}
      validate={validate}
      enableReinitialize>
      {(formikProps: FormikProps<any>) => {
        const { values } = formikProps;
        setPrevValue(values);

        const isStandardInsurance = values?.insuranceType === 'STANDARD_TRAVEL_INSURANCE';
        const isCFAR = values?.insuranceType === 'CFAR_FOR_HOTEL';
        const isBusInsurance = values?.insuranceType === 'BUS_INSURANCE';
        const isCabInsurance = values?.insuranceType === 'CAB_INSURANCE';
        const isDomestic = values?.destination === DestinationOptionsMap?.DOMESTIC;
        /**
         * NOTE: if below cases matches then show picker container ...
         */
        const showPicker_case_1 = (isStandardInsurance || isCFAR) && !!values?.destination;
        const showPicker_case_2 = (isBusInsurance || isCabInsurance) && !!values?.destination;
        /**
         * NOTE: if below cases matches then show duration field else show travel
         * date field.
         */
        const showDuration_case_1 = isStandardInsurance && !!values?.destination && !isDomestic;
        const showDuration_case_2 =
          isStandardInsurance && isDomestic && values?.tripType && values?.tripType !== 'SINGLE';
        const showDuration_case_3 = isCFAR || isBusInsurance;

        return (
          <Form className='search-visa-form w-100'>
            <div className='mb-6'>
              <h3 className='fw-semibold text-dark mb-0'>Search insurance</h3>
              <p className='text-gray-400 fw-bold fs-6 mb-0'>
                Compare and find the right insurance plan
              </p>
            </div>
            <Container fluid className='p-0'>
              <Row>
                <Col md={6} className='mb-4'>
                  <Field
                    name='insuranceType'
                    label='Insurance type'
                    placeholder='Select insurance type'
                    as={OASelectField}
                    options={insuranceCategory}
                    valueOnly
                    required
                  />
                </Col>
                <Col lg={6} className='mb-4'>
                  <DestinationField />
                </Col>
                {!(isBusInsurance || isCabInsurance) && isDomestic ? (
                  <Col md={6} className='mb-4'>
                    <Field
                      name='tripType'
                      label='Trip Type'
                      as={OASelectField}
                      options={TripTypeOptions}
                      valueOnly
                      required={isDomestic}
                    />
                  </Col>
                ) : null}
                <SearchCoverDynamicFields />
                {showPicker_case_1 || showPicker_case_2 ? (
                  <Col md={6} className='mb-4'>
                    {showDuration_case_1 || showDuration_case_2 || showDuration_case_3 ? (
                      <OARangePickerField
                        label='Duration'
                        name='tripDuration'
                        minDate={new Date()}
                        required
                      />
                    ) : (
                      <OADatePickerField
                        name='travelDate'
                        label='Travel Date'
                        minDate={new Date()}
                        required
                      />
                    )}
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col className='d-grid'>
                  <OAButton
                    type='submit'
                    color='primary'
                    label='Search'
                    disabled={isLoading}
                    isLoading={isLoading}
                  />
                </Col>
              </Row>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
}
/**
 * helper components ...
 */
const SearchCoverDynamicFields = () => {
  const { values, setValues } = useFormikContext<any>();
  /**
   * NOTE: reset fields everytime "destination" changes ...
   */
  useEffect(() => {
    const draftArrivalValue = values?.destination === DestinationOptionsMap.DOMESTIC ? 'IN' : '';

    setValues((prev: any) =>
      Object.assign(prev, {
        departure: 'IN',
        arrival: draftArrivalValue,
        tripDuration: {}
      })
    );
  }, [values?.destination]);

  return values?.destination && values?.destination !== DestinationOptionsMap.DOMESTIC ? (
    <React.Fragment>
      <Col lg={6} className='mb-4'>
        <Field
          name='departure'
          label='Origin'
          as={OASelectField}
          options={DepartureCountries}
          valueOnly
          required
        />
      </Col>
      <Col lg={6} className='mb-4'>
        <OAMultiSelectField
          name='arrival'
          label='Destination'
          options={
            values?.destination === DestinationOptionsMap.ASIA
              ? countryOptions
              : CountryOptions?.filter(obj => obj?.value !== values?.departure)
          }
          required
        />
      </Col>
    </React.Fragment>
  ) : null;
};

const DestinationField = () => {
  const { values, setFieldValue } = useFormikContext<any>();

  const [destination, setDestination] = useState<any>(DestinationOptions);

  useEffect(() => {
    /**
     * NOTE: for CFAR_FOR_HOTEL, BUS_INSURANCE and CAB_INSURANCE, destination type
     * will always be Domestic.
     */
    if (values?.insuranceType !== 'STANDARD_TRAVEL_INSURANCE') {
      setFieldValue('destination', 'Domestic (Within India)');
      setDestination([{ value: 'Domestic (Within India)', label: 'Domestic (Within India)' }]);
    } else {
      setFieldValue('destination', '');
      setDestination(DestinationOptions);
    }
  }, [values?.insuranceType]);

  return (
    <Field
      name='destination'
      label='Destination Type'
      placeholder='Select destination type'
      as={OASelectField}
      options={destination}
      valueOnly
      required
    />
  );
};
