import { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { useOrderContext } from '../../../../context/OrderContext';
import { openPathInNewTab } from '../../../../../../helper';
import Banner from './Banner';

interface IActiveTravellerBanner {
  value: string;
}

export default function ActiveTravellerBanner(props: IActiveTravellerBanner) {
  const location = useLocation();
  const { validateMemberByPassportNumber, draftOrder, orderData } = useOrderContext();

  const [duplicateOrderIds, setDuplicateOrderIds] = useState<Array<string>>([]);

  useEffect(() => {
    if (props?.value && props?.value?.match(/^[A-Za-z]{1}-?\d{7}$/)) {
      validateMemberByPassportNumber(props?.value, (res: any) => {
        if (res?.data?.data?.orderIds) {
          let oldArr = res?.data?.data?.orderIds;
          const newArray = oldArr?.filter(
            (item: any) => item !== (draftOrder ? draftOrder?.id : orderData?.data?.id)
          );
          setDuplicateOrderIds(newArray ?? oldArr);
        }
      });
    } else {
      setDuplicateOrderIds([]);
    }
  }, [props?.value]);

  return duplicateOrderIds?.length > 0 ? (
    <Col sm={12} className='mb-4'>
      <Banner text='This traveller is an active traveller in' type='warning' textClassName='m-0'>
        <div className='d-flex flex-wrap'>
          {duplicateOrderIds?.map((orderId: string, index: number) => (
            <span
              key={index}
              className='btn btn-link fs-7 p-0 me-3'
              style={{ textDecoration: 'underline' }}
              onClick={() => openPathInNewTab(`/apps/orders/visa/${orderId}`, location)}>
              {orderId}
            </span>
          ))}
        </div>
      </Banner>
    </Col>
  ) : null;
}
