import styled from '@emotion/styled';
import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { FC } from 'react';
import { OATypography } from '../widgets';

interface ICsvErrorToolTip {
  title: string;
  value?: string;
}

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#000000'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#000000'
  }
}));

const CsvErrorToolTip: FC<ICsvErrorToolTip> = ({ title, value }) => {
  return (
    <>
      <OATypography variant='body1'>{value}</OATypography>
      <BootstrapTooltip title={title}>
        <span className='data-grid-triangle'></span>
      </BootstrapTooltip>
    </>
  );
};

export default CsvErrorToolTip;
