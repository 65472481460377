import fileDownload from 'js-file-download';
import { CLOUDFRONT_URL } from '../pages/order-management/order-view/OrderTabs/TravellerInfo/constants';

export const handleDownload = (url: any): any => {
  const replaceUrl = () => {
    if (url?.startsWith('https://storage.googleapis.com')) {
      return url;
    }

    const comIndex = url.indexOf('.com');
    if (comIndex !== -1) {
      const protocolAndDomain = url.substring(0, comIndex + 4);
      if (url.includes('https://onarrival-develop.') || url.includes('https://onarrival-prod.')) {
        return url.replace(protocolAndDomain, process.env.REACT_APP_AWS_CLOUDFRONT_URL_LIVE);
      } else if (
        url.includes('https://onarrival-develop-test.') ||
        url.includes('https://onarrival-prod-test.')
      ) {
        return url.replace(protocolAndDomain, process.env.REACT_APP_AWS_CLOUDFRONT_URL_TEST);
      } else if (url.includes('https://onarrival-stage-test.')) {
        return url.replace(protocolAndDomain, process.env.REACT_APP_AWS_CLOUDFRONT_URL_STAGE_TEST);
      } else if (url.includes('https://onarrival-stage.')) {
        return url.replace(protocolAndDomain, process.env.REACT_APP_AWS_CLOUDFRONT_URL_STAGE_LIVE);
      } else if (url.includes('https://onarrival-e-tickets-dev.')) {
        return url.replace(protocolAndDomain, process.env.REACT_APP_ITINERARY_CLOUDFRONT);
      } else if (url.includes('https://onarrival-e-tickets.')) {
        return url.replace(protocolAndDomain, process.env.REACT_APP_ITINERARY_CLOUDFRONT);
      }
    }
    return url;
  };
  // Use the replaceUrl function to modify the URL
  const modifiedUrl = replaceUrl();
  if (modifiedUrl) {
    const filename = modifiedUrl.split('/').pop();
    if (filename) {
      fetch(modifiedUrl)
        .then(response => {
          if (response.ok) {
            return response.blob();
          }
          throw new Error('Something went wrong');
        })
        .then(imageBlob => {
          fileDownload(imageBlob, filename);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
};

export const handleS3Download = async (url: string) => {
  const mode = localStorage.getItem('oaMode');
  const CLOUDFRONT_URL =
    mode == 'live'
      ? process.env.REACT_APP_AWS_CLOUDFRONT_URL_LIVE
      : process.env.REACT_APP_AWS_CLOUDFRONT_URL_TEST;

  let urlKey = url?.split('.com')[1];
  const originalImage = url;
  const image = await fetch(CLOUDFRONT_URL + urlKey);

  //Split image name
  const nameSplit = originalImage.split('/');
  const imageName = nameSplit.pop();

  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);
  const link = document.createElement('a');
  link.href = imageURL;
  link.download = '' + imageName + '';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
