import classNames from 'classnames';
import OAProductCard from '../../../components/OAProductCard';

interface IProductCard {
  product: any;
  selectedId: any;
  onClickCard: (id: any) => void;
}

const ProductCard = (props: IProductCard) => {
  const { product, selectedId } = props;

  return (
    <>
      {product?.map((item: any, index: number) => {
        const rootClassNames = classNames('notice rounded border border-dashed p-5 mb-6', {
          'bg-light-secondary border-secondary product-list-card-hover': selectedId !== index,
          'bg-light-primary border-primary': selectedId === index
        });
        return (
          <section key={index} className={rootClassNames} onClick={() => props?.onClickCard(index)}>
            <OAProductCard
              isBasicCard={true}
              prodInfo={item}
              selectedId={selectedId}
              visaInfo={true}
              index={index}
            />
          </section>
        );
      })}
    </>
  );
};

export default ProductCard;
