import React, { useState } from 'react';
import { Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { getTime } from 'date-fns';

import { ViewSVG, UploadSVG } from '../../../../../components/Icons';
import DocumentPreview from './DocumentPreview';
import { KTSVG } from '../../../../../_metronic/helpers';
import ImageEditorModal from '../../../../../components/OAImageEditor';
import { handleS3Download } from '../../../../../helper/handleDownload';

interface IDocumentCard {
  index: number | string;
  document: any;
  memberId: string;
  isEditable: boolean;
  fileUploadHandler?: any;
  bgColor?: string;
}

const DocumentCard = (props: IDocumentCard) => {
  const { bgColor } = props;

  const [isEditorOpen, setIsEditorOpen] = useState<boolean>(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const [imgFile, setImgFile] = useState<File | null>(null);
  const [imgUrl, setImgUrl] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const onHideEditor = () => {
    setIsEditorOpen(false);
    setImgFile(null);
    setIsLoading(false);
  };

  const onHidePreview = () => setIsPreviewOpen(false);

  const handleSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (event?.target?.files && event?.target?.files?.length > 0) {
      const file = event?.target?.files?.[0];
      if (file?.type?.includes('image')) {
        setImgFile(file);
        setIsEditorOpen(true);
      } else {
        const extension = file.name.slice(file.name.lastIndexOf('.') + 1);
        const draftDocFieldName = props?.document?.fieldName?.replaceAll(/\s/g, '');
        const fileName = `${draftDocFieldName}_${getTime(new Date())}`;
        props?.fileUploadHandler(file, fileName, extension);
      }
    }
    event.target.value = '';
  };

  const handlePreview = () => {
    const draftArr = props?.document?.documentUrl?.split('.');
    const isImageFile =
      draftArr?.[draftArr?.length - 1] === 'jpeg' ||
      draftArr?.[draftArr?.length - 1] === 'jpg' ||
      draftArr?.[draftArr?.length - 1] === 'png';

    if (isImageFile) {
      setImgUrl(props?.document?.documentUrl);
      setIsPreviewOpen(true);
    } else {
      const documentUrl =
        draftArr?.[draftArr?.length - 1] === 'pdf'
          ? props?.document?.documentUrl
          : `https://view.officeapps.live.com/op/embed.aspx?src=${props?.document?.documentUrl}`;
      window.open(documentUrl, '_blank');
    }
  };

  const fileType = props?.document?.documentUrl?.split('.')?.reverse()?.[0];
  // const isRequestedDoc = !props?.document?.uploaded;
  // const isUploadDisabled = !props?.isEditable && !isRequestedDoc;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <React.Fragment>
      <Card className='document-card'>
        {!props?.document?.uploaded ? (
          <Card.Body
            className={classNames('document-empty', {
              disabled: !props?.isEditable
            })}>
            <label
              htmlFor={`document_upload_${props?.memberId}_${props?.document?.fieldName}_${props?.index}`}
              className='document-card--upload-label'>
              <svg
                width='45'
                height='45'
                viewBox='0 0 39 39'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <rect
                  width='38.76'
                  height='38.76'
                  rx='4.56'
                  fill={props?.isEditable ? '#F3FAFE' : '#F0F2F5'}
                />
                <path
                  opacity='0.3'
                  d='M26.026 20.1176H12.7419C12.2991 20.1176 12.0039 19.8224 12.0039 19.3796C12.0039 18.9368 12.2991 18.6416 12.7419 18.6416H26.026C26.4688 18.6416 26.764 18.9368 26.764 19.3796C26.764 19.8224 26.4688 20.1176 26.026 20.1176Z'
                  fill={props?.isEditable ? '#3A86FF' : '#7F8297'}
                />
                <path
                  d='M19.3806 26.7601C18.9378 26.7601 18.6426 26.4649 18.6426 26.0221V12.738C18.6426 12.2952 18.9378 12 19.3806 12C19.8234 12 20.1186 12.2952 20.1186 12.738V26.0221C20.1186 26.4649 19.8234 26.7601 19.3806 26.7601Z'
                  fill={props?.isEditable ? '#3A86FF' : '#7F8297'}
                />
              </svg>
            </label>
            <input
              type='file'
              id={`document_upload_${props?.memberId}_${props?.document?.fieldName}_${props?.index}`}
              onChange={handleSelectFile}
              className='document-card--upload-input'
              accept='image/*, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              disabled={!props?.isEditable}
            />
          </Card.Body>
        ) : (
          <Card.Body
            className='document-thumbnail'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <DocumentCardPreview fileType={fileType} documentUrl={props?.document?.documentUrl} />
            {isHovered && (
              <Card.ImgOverlay className='document-thumbnail--overlay d-flex align-items-center justify-content-center'>
                <div className='d-flex justify-content-center'>
                  <Button
                    variant='light'
                    size='sm'
                    className='w-30px h-30px p-0 ms-3'
                    onClick={() => {
                      handleS3Download(props?.document?.documentUrl);
                    }}
                    title='Download'>
                    <KTSVG
                      path='/media/icons/duotune/files/fil021.svg'
                      className='svg-icon-2 m-0'
                    />
                  </Button>
                  <Button
                    variant='light'
                    size='sm'
                    className='w-30px h-30px p-0 ms-3'
                    onClick={handlePreview}
                    title='View'>
                    <ViewSVG height='16' width='16' />
                  </Button>
                  <label
                    htmlFor={`document_upload_${props?.memberId}_${props?.document?.fieldName}_${props?.index}`}
                    className={classNames('ms-3', {
                      disabled: !props?.isEditable
                    })}>
                    <span
                      title='Reupload'
                      className='btn btn-sm btn-bg-light w-30px h-30px p-0 d-flex justify-content-center align-items-center'>
                      <UploadSVG height='16' width='16' />
                      <input
                        type='file'
                        id={`document_upload_${props?.memberId}_${props?.document?.fieldName}_${props?.index}`}
                        onChange={handleSelectFile}
                        className='document-thumbnail--input-file'
                        accept='image/*, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                        disabled={!props?.isEditable}
                      />
                    </span>
                  </label>
                </div>
              </Card.ImgOverlay>
            )}
          </Card.Body>
        )}
        <div
          className={classNames('d-flex align-items-center pt-3 pb-2', {
            'bg-gray-100': bgColor === 'bg-gray-100'
          })}>
          <span className='fs-6 text-gray-400 me-2'>{props?.document?.documentName}</span>
          <OverlayTrigger
            placement='top'
            delay={{ show: 250, hide: 400 }}
            overlay={(overlayProps: any) => (
              <Tooltip id='button-tooltip' {...overlayProps}>
                {props?.document?.description}
              </Tooltip>
            )}>
            <span className='btn btn-link p-0'>(?)</span>
          </OverlayTrigger>
        </div>
        <div
          className={classNames('d-flex align-items-center pb-3', {
            'bg-gray-100': bgColor === 'bg-gray-100'
          })}>
          <span
            className={classNames(
              `badge badge-light-${props?.document?.uploaded ? `success` : `danger`}`
            )}>
            {props?.document?.uploaded ? 'Success' : 'Not uploaded'}
          </span>
        </div>
      </Card>
      <ImageEditorModal
        isOpen={isEditorOpen}
        setIsOpen={setIsEditorOpen}
        onHide={onHideEditor}
        imgFile={imgFile}
        fileUploadHandler={props?.fileUploadHandler}
      />
      <DocumentPreview
        isOpen={isPreviewOpen}
        onHide={onHidePreview}
        imgUrl={imgUrl}
        document={props?.document}
      />
    </React.Fragment>
  );
};

export default DocumentCard;

const DocumentCardPreview = ({
  fileType,
  documentUrl
}: {
  fileType: string;
  documentUrl: string;
}) => {
  if (fileType === 'pdf') return <iframe height='100%' width='100%' src={documentUrl} />;
  else if (fileType === 'docx' || fileType === 'xlsx')
    return (
      <iframe
        height='100%'
        width='100%'
        src={`https://docs.google.com/viewer?embedded=true&url=${documentUrl}`}
      />
    );
  else return <Card.Img variant='top' src={documentUrl} />;
};
