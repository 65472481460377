import { Box } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';

import OAButton from '../../../../../../components/widgets/OAButton';
import { OATypography } from '../../../../../../components/widgets';

interface OIModificationTableProps {
  data: Array<any>;
  columns: GridColDef[];
  handleAddRow: () => void;
  editable?: boolean;
}

export default function OIModificationTable(props: OIModificationTableProps) {
  const { data = [], editable = false } = props;

  return (
    <Box height={!data?.length ? '108px' : 'auto'}>
      <DataGridPro
        rows={data}
        columns={props?.columns}
        disableColumnMenu
        disableRowSelectionOnClick
        className='oa-table-component border-0 no-hover'
        hideFooter
        slots={{
          noRowsOverlay: () => (
            <Box height='52px' display='flex' alignItems='center' justifyContent='center'>
              <OATypography variant='body1' color='textSecondary'>
                No Data
              </OATypography>
            </Box>
          )
        }}
      />
      {editable ? (
        <Box padding='10px'>
          <OAButton
            color='light-primary'
            label='Add another item'
            onClick={props?.handleAddRow}
            className='btn-active-light-primary'
          />
        </Box>
      ) : null}
    </Box>
  );
}
