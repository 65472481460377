import { useEffect, useState } from 'react';
import { orderBy } from 'lodash';

import OABadge from '../../../../../components/widgets/OABadge';
import { getCurrencySymbol } from '../../../../../helper';
import { formatNumberForCurrency } from '../../../../../helper';
import { useParams } from 'react-router-dom';
import { IOrderType } from '../../../types';
import { ORDER_TYPE } from '../../../constants';

export default function ReviewOrderTable(props: { data: any }) {
  const params = useParams<{ orderType: IOrderType; id?: string }>();

  const isQuotation = props?.data?.bookingStatus === 'ORDER_QUOTE';

  const [memberData, setMemberData] = useState<any>([]);

  useEffect(() => {
    if (props?.data?.bookingStatus === 'ORDER_QUOTE') {
      if (params?.orderType === ORDER_TYPE.INSURANCE) {
        const draft = props?.data?.orderAmount?.items?.map((obj: any) => {
          const memberObj = props?.data?.memberCount?.find((mem: any) => mem?.type === obj?.type);

          return {
            memberId: obj?.memberId,
            memberType: memberObj?.type,
            memberCount: memberObj?.count,
            productName: props?.data?.product?.name,
            price: `${getCurrencySymbol(obj?.currency)} ${formatNumberForCurrency(
              obj?.tax?.totalAmountWithoutTax,
              {
                countryCode: props?.data?.orderAmount?.currencyCountry
              }
            )}`
          };
        });

        setMemberData(draft);
      } else {
        const draftProductFee = props?.data?.orderAmount?.additionalAmountModifiers?.find(
          (obj: any) => obj?.type === 'PRODUCT_FEE'
        );
        const draft = props?.data?.memberCount?.map((obj: any) => {
          const draftAmt = draftProductFee?.amount * (obj?.count / draftProductFee?.quantity);
          return {
            memberType: obj?.type,
            memberCount: obj?.count,
            productName: props?.data?.product?.name,
            price: `${getCurrencySymbol(obj?.currency)} ${formatNumberForCurrency(draftAmt, {
              countryCode: props?.data?.orderAmount?.currencyCountry
            })}`
          };
        });

        setMemberData(draft);
      }
    } else {
      const draft = props?.data?.orderAmount?.items?.map((obj: any) => {
        const draftMember = props?.data?.members?.find(
          (member: any) => member?.id === obj?.memberId
        );
        return {
          memberId: obj?.memberId,
          memberName: `${draftMember?.title} ${draftMember?.firstName} ${draftMember?.lastName}`,
          primary: draftMember?.primary,
          productName: props?.data?.product?.name,
          price: `${getCurrencySymbol(obj?.currency)} ${formatNumberForCurrency(
            obj?.tax?.totalAmountWithoutTax,
            {
              countryCode: props?.data?.orderAmount?.currencyCountry
            }
          )}`
        };
      });
      const sortedDraftDataOrderBy = orderBy(draft, ['primary'], ['desc']);
      const draftDataOrderBy = sortedDraftDataOrderBy.sort((a, b) => b.primary - a.primary); // sort function to get the primary member at the top.
      setMemberData(draftDataOrderBy);
    }
  }, [props?.data]);

  let subTotal: string = '0';
  let taxes: string = '0';
  let grandTotal: string = '0';
  let isTaxShow: boolean = false;
  if (props?.data?.orderAmount) {
    const draftCurrency: any = getCurrencySymbol(props?.data?.orderAmount?.currency);
    subTotal = `${draftCurrency} ${formatNumberForCurrency(
      props?.data?.orderAmount?.subtotalAmount,
      {
        countryCode: props?.data?.orderAmount?.currencyCountry
      }
    )}`;
    taxes = `${draftCurrency} ${formatNumberForCurrency(props?.data?.orderAmount?.tax?.totalTax, {
      countryCode: props?.data?.orderAmount?.currencyCountry
    })}`;
    grandTotal = `${draftCurrency} ${formatNumberForCurrency(
      props?.data?.orderAmount?.totalAmount,
      {
        countryCode: props?.data?.orderAmount?.currencyCountry
      }
    )}`;

    if (props?.data?.product?.ancillaryType === 'EVISA') {
      if (props?.data?.orderAmount?.tax?.taxes) {
        const findGST = props?.data?.orderAmount?.tax?.taxes?.find(
          (obj: any) => obj?.type === 'GST'
        );
        if (findGST && findGST?.amount) {
          isTaxShow = true;
          taxes = `${draftCurrency} ${formatNumberForCurrency(findGST?.amount, {
            countryCode: props?.data?.orderAmount?.currencyCountry
          })}`;
        }
      }
    } else {
      isTaxShow = true;
    }
  }

  const serviceChargeFind = props?.data?.orderAmount?.additionalAmountModifiers?.find(
    (obj: any) => obj?.type === 'SERVICE_CHARGES'
  );
  let serviceCharge: string = '0';
  let serviceChargeTax: string = '0';
  const draftCurrency = getCurrencySymbol(serviceChargeFind?.currency);
  if (serviceChargeFind) {
    serviceCharge = `${draftCurrency} ${formatNumberForCurrency(
      serviceChargeFind?.tax?.totalAmountWithoutTax,
      { countryCode: props?.data?.orderAmount?.currencyCountry }
    )}`;
    serviceChargeTax = `${draftCurrency} ${formatNumberForCurrency(
      serviceChargeFind?.tax?.totalTax,
      {
        countryCode: props?.data?.orderAmount?.currencyCountry
      }
    )}`;
  }

  let markupAmount: any, markupTax: any;
  if (props?.data?.orderAmount?.additionalAmountModifiers) {
    const findMarkup = props?.data?.orderAmount?.additionalAmountModifiers.find(
      (obj: any) => obj.type === 'MARKUP_FEE'
    );
    if (findMarkup) {
      const draftCurrency = getCurrencySymbol(findMarkup?.currency);
      markupAmount = `${draftCurrency} ${formatNumberForCurrency(
        findMarkup?.tax?.totalAmountWithoutTax,
        {
          countryCode: props?.data?.orderAmount?.currencyCountry
        }
      )}`;
      markupTax = `${draftCurrency} ${formatNumberForCurrency(findMarkup?.tax?.totalTax, {
        countryCode: props?.data?.orderAmount?.currencyCountry
      })}`;
    }
  }

  const addonServices = props?.data?.orderAmount?.additionalAmountModifiers
    ?.filter((obj: any) => obj?.type === 'ADD_ON_SERVICE_FEE')
    ?.map((obj: any) => {
      const draftCurrency = getCurrencySymbol(obj?.currency);
      const draftAmount = formatNumberForCurrency(obj?.amount);

      return {
        name: obj?.name,
        currency: draftCurrency,
        amount: draftAmount
      };
    });

  return (
    <div className='table-responsive'>
      <table className='table table-row-gray-100 align-middle gs-0 gy-1 oa-order-review-table'>
        <thead>
          <tr className='fw-bolder text-muted'>
            <th className={isQuotation ? 'w-100px' : 'w-250px'}>
              {isQuotation ? 'TRAVELLERS' : 'TRAVELLER NAME'}
            </th>
            <th className='min-w-140px'>PRODUCT</th>
            {isQuotation ? <th className='w-140px'>UNIT</th> : null}
            <th className='w-150px text-end'>PRICE</th>
          </tr>
        </thead>

        <tbody>
          {memberData?.map((obj: any, index: number) => {
            return (
              <tr key={index} className='member-tr'>
                <td className='pt-6'>
                  {isQuotation ? (
                    <span className='text-dark fs-6'>{obj?.memberType}</span>
                  ) : (
                    <span className='text-dark fs-6'>
                      {obj?.memberName}
                      {obj?.primary ? (
                        <OABadge label='Primary' type='success' className='ms-5' />
                      ) : null}
                    </span>
                  )}
                </td>
                <td className='pt-6'>
                  <span className='text-dark d-block mb-1 fs-6'>{obj?.productName}</span>
                </td>
                {isQuotation ? (
                  <td className='pt-6'>
                    <span className='text-dark mb-1 fs-6'>{obj?.memberCount}</span>
                  </td>
                ) : null}
                <td className='text-dark fs-6 text-end pt-6'>{obj?.price}</td>
              </tr>
            );
          })}
          {addonServices?.length
            ? addonServices?.map((obj: any) => {
                return (
                  <tr>
                    <td colSpan={isQuotation ? 3 : 2} className='text-end'>
                      <span className='text-muted fw-bold text-muted d-block fs-6'>
                        {obj?.name}
                      </span>
                    </td>
                    <td className='text-muted fw-bold fs-6 text-end'>{`${obj?.currency} ${obj?.amount}`}</td>
                  </tr>
                );
              })
            : null}
          {serviceChargeFind && (
            <tr className='service-charge-find'>
              <td colSpan={isQuotation ? 3 : 2} className='text-end pt-5'>
                <span className='text-muted fw-bold text-muted d-block fs-6'>Service charge</span>
              </td>
              <td className='text-muted fw-bold fs-6 text-end pt-5'>{serviceCharge}</td>
            </tr>
          )}
          <tr>
            <td colSpan={isQuotation ? 3 : 2} className='text-end'>
              <span className='text-muted fw-bold text-muted d-block fs-6'>Subtotal</span>
            </td>
            <td className='text-muted fw-bold fs-6 text-end'>{subTotal}</td>
          </tr>

          {serviceChargeFind && (
            <tr>
              <td colSpan={isQuotation ? 3 : 2} className='text-end'>
                <span className='text-muted fw-bold text-muted d-block fs-6'>
                  Service charge GST
                </span>
              </td>
              <td className='text-muted fw-bold fs-6 text-end'>{serviceChargeTax}</td>
            </tr>
          )}

          {isTaxShow && (
            <tr>
              <td colSpan={isQuotation ? 3 : 2} className='text-end'>
                <span className='text-muted fw-bold text-muted d-block fs-6'>Taxes</span>
              </td>
              <td className='text-muted fw-bold fs-6 text-end'>{taxes}</td>
            </tr>
          )}
          {/* {(serviceChargeTax !== taxes && props?.data?.orderAmount?.tax) &&
              <tr>
                  <td colSpan={isQuotation ? 3 : 2} className='text-end'>
                      <span className='text-muted fw-bold text-muted d-block fs-6'>Taxes</span>
                  </td>
                  <td className='text-muted fw-bold fs-6 text-end'>{taxes}</td>
              </tr>
          } */}

          {markupAmount && (
            <tr>
              <td colSpan={isQuotation ? 3 : 2} className='text-end'>
                <span className='text-muted fw-bold text-muted d-block fs-6'>Total Markup</span>
              </td>
              <td className='text-muted fw-bold fs-6 text-end'>{markupAmount}</td>
            </tr>
          )}
          {markupTax && (
            <tr>
              <td colSpan={isQuotation ? 3 : 2} className='text-end'>
                <span className='text-muted fw-bold text-muted d-block fs-6'>Markup GST</span>
              </td>
              <td className='text-muted fw-bold fs-6 text-end'>{markupTax}</td>
            </tr>
          )}

          <tr>
            <td colSpan={isQuotation ? 3 : 2} className='text-end'>
              <span className='text-dark fw-bolder d-block mb-1 fs-5'>Grand total</span>
            </td>
            <td className='text-dark fw-bolder fs-5 text-end'>{grandTotal}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
