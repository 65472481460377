import { useState } from 'react';

import { getDateFormat, showToast } from '../../../helper';
import API from '../../../helper/API';
import { DATE_FORMAT_BE } from '../../../constants';
import { IClaimProvider, IClaimQueryParams } from '../type';
import ClaimContext, { IClaimContext } from './ClaimContext';
import { IPageInfo } from '../../../components/OATableComponent';

const ClaimProvider = (props: IClaimProvider) => {
  const [statusChange, setStatusChange] = useState<boolean>(false);
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    totalCount: 0,
    totalPages: 0,
    count: 0,
    size: 10,
    index: 1
  });
  const getClaimList = async (cb?: (response?: any) => void) => {
    try {
      API.get(`/insurance/insurance-claims?${buildQueryParams(props?.data?.queryParams)}`)
        .then((response: any) => {
          const resultData = response?.data?.data;
          setPageInfo(prev =>
            Object.assign({}, prev, {
              totalCount: resultData?.totalElements,
              totalPages: resultData?.totalPages,
              count: resultData?.numberOfElements
            })
          );
          cb?.(resultData);
        })
        .catch(err => {
          cb?.(err);
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const settleClaim = async (id: any, cb?: (response?: any) => void) => {
    try {
      setStatusChange(false);
      API.get(`/insurance/insurance-claims/settlement?id=${id}`)
        .then((response: any) => {
          const resultData = response?.data?.data;
          showToast('Claim settled successfully.', 'success');
          setStatusChange(true);
          cb?.(resultData);
        })
        .catch(err => {
          setStatusChange(false);
          showToast(err?.message, 'error');
          cb?.(err);
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      setStatusChange(false);
      showToast(error?.message, 'error');
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const shareLink = async (id: any, cb?: (response?: any) => void) => {
    try {
      API.get(`/insurance/insurance-claims/share-link?id=${id}`)
        .then((response: any) => {
          const resultData = response?.data?.data;
          showToast('Link shared successfully.', 'success');
          cb?.(resultData);
        })
        .catch(err => {
          showToast(err?.message, 'error');
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      showToast(error?.message, 'error');
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const contextValue: IClaimContext = {
    getClaimList,
    pageInfo,
    settleClaim,
    shareLink,
    statusChange
  };

  return <ClaimContext.Provider value={contextValue}>{props?.children}</ClaimContext.Provider>;
};

export default ClaimProvider;

const buildQueryParams = (queryParams: IClaimQueryParams): string => {
  const startDate = getDateFormat(queryParams?.dateRange?.[0] as Date, DATE_FORMAT_BE);
  const endDate = getDateFormat(queryParams?.dateRange?.[1] as Date, DATE_FORMAT_BE);
  let queryStr = `start=${startDate}&end=${endDate}&page=${queryParams?.pageIndex - 1}&size=10`;
  if (queryParams?.search && queryParams?.search !== '') {
    queryStr = `${queryStr}&search=${queryParams?.search}`;
  }
  return queryStr;
};
