import { FC } from 'react';
import { OATypography } from '../../widgets';
interface IAdditionalNotes {
  notes: any[];
}

export const AdditionalNotes: FC<IAdditionalNotes> = ({ notes }) => {
  return (
    <ul>
      {notes?.length > 0
        ? notes?.map((note: any, index: number) => (
            <li key={index} className='mb-3'>
              <OATypography variant='body2' sx={{ color: theme => theme?.palette?.grey?.[400] }}>
                {note}
              </OATypography>
            </li>
          ))
        : null}
    </ul>
  );
};
