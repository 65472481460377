import React, { Dispatch, useContext } from 'react';
import { IOrderFilterQueryParams } from '../../order-management/types';
import { IPageInfo } from '../../../components/OATableComponent';

export interface IApiContext {
  queryParams: IOrderFilterQueryParams;
  setQueryParams: Dispatch<React.SetStateAction<IOrderFilterQueryParams>>;
  getInitQueryParams: () => void;
  getOcrList: (cb?: ((response?: any) => void) | undefined) => Promise<void>;
  pageInfo: IPageInfo;
}

const ApiContext = React.createContext<IApiContext>({} as IApiContext);

export default ApiContext;

export const useApiContext = () => useContext(ApiContext);
