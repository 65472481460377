import React from 'react';
import { IconType } from '.';

export default function WarningSVG(props: IconType): JSX.Element {
  const { color = '#DE506E', height = '14', width = '14', className = '' } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 14 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <circle cx='7' cy='8' r='7' fill={color} />
      <path
        d='M7.75 4.19L7.574 9.503H6.276L6.089 4.19H7.75ZM6.958 12.077C6.67933 12.077 6.44833 11.9927 6.265 11.824C6.089 11.648 6.001 11.4317 6.001 11.175C6.001 10.9183 6.089 10.7057 6.265 10.537C6.44833 10.361 6.67933 10.273 6.958 10.273C7.22933 10.273 7.453 10.361 7.629 10.537C7.805 10.7057 7.893 10.9183 7.893 11.175C7.893 11.4317 7.805 11.648 7.629 11.824C7.453 11.9927 7.22933 12.077 6.958 12.077Z'
        fill='white'
      />
    </svg>
  );
}
