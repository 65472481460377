import { FC } from 'react';
import { IDocument } from '../../../types';
import { OATypography } from '../../widgets';

interface IDocuments {
  documents: IDocument[];
}

export const Documents: FC<IDocuments> = ({ documents }) => {
  return (
    <>
      {documents.map((doc: IDocument, index: number) => (
        <div key={index}>
          <OATypography variant='body1' sx={{ mb: '4px' }}>
            {doc?.documentName}
          </OATypography>
          <OATypography
            variant='body2'
            sx={{
              mb: documents?.length - 1 === index ? '0' : '16px',
              color: theme => theme?.palette?.grey?.[400]
            }}>
            {doc?.description}
          </OATypography>
        </div>
      ))}
    </>
  );
};
