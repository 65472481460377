import { Box, Grid, Paper } from '@mui/material';

import { capitalizeFirstLetterOnly } from '../../helper';
import OALabelValue from '../widgets/OALabelValue';
import { KTSVG } from '../../_metronic/helpers';

interface IProductViewCard {
  data: any;
}

export default function ProductViewCard({ data }: IProductViewCard) {
  return (
    <Paper variant='outlined' className='product-view-card w-100 p-6 mb-6'>
      <div className='d-flex'>
        <div className='flex-grow-1 pe-4'>
          <div className='d-flex'>
            <div className='flex-grow-1 mb-6'>
              <Box display='flex'>
                <h4 className='fw-semibold text-dark mb-0'>{data?.displayName}</h4>
                <div className='badge badge-light-primary ms-3 mb-2'>
                  {data?.productInformation?.consulate}
                </div>
              </Box>
              <InfoTag tag={data?.productInformation?.processingType} />
              <InfoTag tag={data?.productInformation?.tripType} />
              <InfoTag tag={data?.productInformation?.occupation?.split('_').join(' ')} />
            </div>
          </div>
          <Grid container spacing={2}>
            {data?.productInformation?.processingTime ? (
              <Grid item xs={3}>
                <OALabelValue
                  label='Processing Time'
                  value={data?.productInformation?.processingTime}
                />
              </Grid>
            ) : null}
            {data?.productInformation?.stayPeriod ? (
              <Grid item xs={3}>
                <OALabelValue label='Stay Period' value={data?.productInformation?.stayPeriod} />
              </Grid>
            ) : null}
            {data?.productInformation?.validity ? (
              <Grid item xs={3}>
                <OALabelValue label='Validity' value={data?.productInformation?.validity} />
              </Grid>
            ) : null}
            {data?.productInformation?.visaType ? (
              <Grid item xs={3}>
                <OALabelValue label='Visa Type' value={data?.productInformation?.visaType} />
              </Grid>
            ) : null}
          </Grid>
          {data?.productInformation?.disclaimer && (
            <div className='d-flex mt-4'>
              <div>
                <KTSVG
                  path='/media/icons/duotune/general/gen045.svg'
                  className='svg-icon-3 svg-icon-gray-600 me-1'
                />
              </div>
              <div>{data?.productInformation?.disclaimer}</div>
            </div>
          )}
        </div>
      </div>
    </Paper>
  );
}

const InfoTag = ({ tag }: { tag: string }) => (
  <span className='badge py-1 px-2 fs-7 badge-light-info me-2'>
    {capitalizeFirstLetterOnly(tag?.toLowerCase())}
  </span>
);
