import React, { useContext } from 'react';

export interface IAppContext {
  users: Array<any> | null;
  dataExchange: { [key: string]: any };
  fetchUsers: (orgId?: string, cb?: ((res: any) => void) | undefined) => void;
  getDataExchangeByType: (
    type: string,
    supplierCode: string | null,
    shouldReturn?: boolean,
    cb?: (res: any) => void
  ) => void;
  fetchAirportCodes: (cb?: ((res: any) => void) | undefined) => void;
  getGitVersionInfo: (cb?: ((res: any) => void) | undefined) => void;
  requestAccess: (
    accessType: 'VISA' | 'VISA INFORMATION' | 'INSURANCE' | 'DASHBOARD',
    cb?: ((res: any) => void) | undefined
  ) => void;
}

const AppContext = React.createContext<IAppContext>({} as IAppContext);

export default AppContext;

export const useAppContext = () => useContext(AppContext);
