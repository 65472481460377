import { useState } from 'react';
import ApiContext, { IApiContext } from './ApiContext';
import { IOrderFilterQueryParams } from '../../order-management/types';
import { DATE_FORMAT_BE, OA_DATE_FORMAT_BE } from '../../../constants';
import { getDateFormat, getFormattedDate } from '../../../helper';
import { ILedgerQueryParams } from '../../Ledger/type';
import API from '../../../helper/API';
import { IPageInfo } from '../../../components/OATableComponent';

const initQueryParams = {
  orderStatus: 'SUCCESS',
  pageIndex: 1,
  pageCount: 0,
  dateRange: [] as any,
  size: 20,
  sortByField: null,
  sortBy: null,
  loading: null
};

const ApiProvider = (props: any) => {
  const [queryParams, setQueryParams] = useState<IOrderFilterQueryParams>(initQueryParams);
  const getInitQueryParams = () => setQueryParams(initQueryParams);
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    totalCount: 0,
    totalPages: 0,
    count: 0,
    size: 20,
    index: 1
  });

  const getOcrList = async (cb?: (response?: any) => void) => {
    try {
      API.get(`/document/dashboard?${buildQueryParams(queryParams)}`)
        .then((response: any) => {
          const resultData = response?.data?.data;
          setPageInfo(prev =>
            Object.assign({}, prev, {
              totalCount: resultData?.totalElements,
              totalPages: resultData?.totalPages,
              count: resultData?.content?.length
            })
          );
          cb?.(resultData);
        })
        .catch(err => {
          cb?.(err);
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const contextValue: IApiContext = {
    queryParams,
    setQueryParams,
    getInitQueryParams,
    getOcrList,
    pageInfo
  };

  return <ApiContext.Provider value={contextValue}>{props?.children}</ApiContext.Provider>;
};

export default ApiProvider;

const buildQueryParams = (queryParams: IOrderFilterQueryParams): string => {
  let queryStr = `page=${queryParams?.pageIndex - 1}&size=${queryParams?.size}`;

  if (queryParams?.dateRange.length > 0) {
    const startDate = queryParams?.dateRange[0]
      ? getFormattedDate(queryParams?.dateRange[0], OA_DATE_FORMAT_BE)
      : null;
    const endDate = queryParams?.dateRange[1]
      ? getFormattedDate(queryParams?.dateRange[1], OA_DATE_FORMAT_BE)
      : null;
    queryStr = `${queryStr}&start=${startDate}&end=${endDate}`;
  }

  if (queryParams?.orderStatus != '') {
    queryStr = `${queryStr}&status=${queryParams?.orderStatus}`;
  }

  return queryStr;
};
