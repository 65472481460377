import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Container, Row, Col } from 'react-bootstrap';
import {
  OASelectField,
  OATextField,
  OATextAreaField,
  OADatePickerField
} from '../../../components/fields';
import OAButton from '../../../components/widgets/OAButton';
import { TransactionTypeOptions } from '../../order-management/constants';
import * as Yup from 'yup';
import { useContext, useEffect, useState } from 'react';
import LedgerContext from '../context/LedgerContext';
import moment from 'moment';
import { subMonths } from 'date-fns';
import OATimePicker from '../../../components/OATimePicker';
import { getFormattedDate } from '../../../helper';
import { OA_DATE_FORMAT_BE } from '../../../constants';

interface ITransactionFormModal {
  onHide: () => void;
  data: any;
}

const init = {
  type: '',
  dateOfTransaction: '',
  transactionTime: '',
  orderId: '',
  amount: '',
  description: ''
};

const TransactionSchema = Yup.object().shape({
  type: Yup.string().required('Type is required'),
  dateOfTransaction: Yup.string().required('Date Of Transaction is required'),
  orderId: Yup.string().required('Order Id is required'),
  amount: Yup.number().positive().required('Please enter amount').min(1),
  description: Yup.string().required('Description is required')
});

export default function AddTransactionForm(props: ITransactionFormModal) {
  const [initValues, setInitValues] = useState<any>(init);
  const { getLedgerInfo, addTransaction, editTransaction } = useContext(LedgerContext);
  const [ledgerInfo, setLedgerInfo] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getLedgerInfo(res => {
      setLedgerInfo(res);
    });
  }, []);

  useEffect(() => {
    let initDraft = {
      type: props?.data?.credit === 0 ? 'DEBIT' : 'CREDIT',
      dateOfTransaction: props?.data?.createdAt ?? null,
      transactionTime: props?.data?.createdAt ? moment.utc(props?.data?.createdAt).local() : null,
      orderId: props?.data?.linkedOrderId,
      amount: props?.data?.debit === 0 ? props?.data?.credit : props?.data?.debit,
      description: props?.data?.description
    };
    props?.data?.id ? setInitValues(initDraft) : setInitValues(init);
  }, []);

  const handleSubmit = (values: any, actions: FormikHelpers<any>) => {
    if (!moment(values?.dateOfTransaction)?.isValid()) {
      setLoading(false);
      return actions?.setErrors({ dateOfTransaction: 'Future/Past dates are not allowed' });
    }
    let targetDate: any = getFormattedDate(values?.dateOfTransaction, OA_DATE_FORMAT_BE);
    let sourceDate: any = getFormattedDate(moment().subtract(3, 'months'), OA_DATE_FORMAT_BE);
    let diff = moment().diff(sourceDate, 'days');
    let resultDate = moment(targetDate).diff(sourceDate, 'days');
    // manual time entry validation
    if (
      resultDate === diff &&
      moment(values?.transactionTime, 'h:mma').isAfter(moment(moment(), 'h:mma'))
    ) {
      setLoading(false);
      return actions?.setErrors({ transactionTime: 'Time in the future is not allowed' });
    }
    // manual date and Time entry validation
    if (diff < 0 || resultDate > diff) {
      setLoading(false);
      return actions?.setErrors({
        dateOfTransaction: 'Future/Past dates are not allowed',
        transactionTime: 'Future/Past Time is not allowed'
      });
    }
    // ist to utc date validation
    if (
      moment(values?.transactionTime, 'h:mma').isSameOrAfter(moment('12:00am', 'h:mma')) &&
      moment(values?.transactionTime, 'h:mma').isSameOrBefore(moment('5:30am', 'h:mma'))
    ) {
      let dateToCheck = moment(values?.dateOfTransaction);
      if (dateToCheck.month() === 0 && dateToCheck.date() === 1) {
        // Subtract one day, one month, and one year
        values.dateOfTransaction = dateToCheck
          .subtract(1, 'day')
          .subtract(1, 'month')
          .subtract(1, 'year');
      } else {
        values.dateOfTransaction = moment(values?.dateOfTransaction).subtract(1, 'days');
      }
    }

    values.id = props?.data?.id ?? null;
    (values.ledgerId = props?.data?.ledgerId ?? ledgerInfo?.id),
      (values.credit = values?.type === 'CREDIT' ? parseFloat(values?.amount) : 0),
      (values.debit = values?.type === 'DEBIT' ? parseFloat(values?.amount) : 0);
    props?.data?.id
      ? editTransaction(values, res => {
          setLoading(false);
          props?.onHide();
        })
      : addTransaction(values, res => {
          setLoading(false);
          props?.onHide();
        });
  };

  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleSubmit}
      validationSchema={TransactionSchema}
      enableReinitialize>
      {(formikProps: FormikProps<any>) => {
        return (
          <Container fluid as={Form} className='p-0'>
            <Row className='mb-4'>
              <Col>
                <Field
                  name='type'
                  label='Type'
                  placeholder='Type'
                  as={OASelectField}
                  options={TransactionTypeOptions}
                  valueOnly
                  isDisabled={props?.data ? true : false}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <OADatePickerField
                  name='dateOfTransaction'
                  label='Date of Transaction'
                  minDate={subMonths(new Date(), 3)}
                  maxDate={new Date()}
                  date={props?.data?.createdAt ?? null}
                  disabled={props?.data ? true : false}
                  required
                />
              </Col>
              <Col sm={6} className='mb-5'>
                <OATimePicker
                  name='transactionTime'
                  label='Time'
                  disabled={props?.data ? true : false}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <OATextField name='orderId' label='Order ID' required />
              </Col>
              <Col lg={6}>
                <OATextField name='amount' label='Amount(₹)' required />
              </Col>
            </Row>
            <Row className='mt-5'>
              <Col>
                <OATextAreaField
                  name='description'
                  label='Description'
                  placeholder='Debited for OnArrival service fees on visa product'
                  required
                />
              </Col>
            </Row>

            <div className='d-flex justify-content-end mt-5'>
              <OAButton
                className='btn-light-primary btn-active-light-primary me-3'
                label='Cancel'
                onClick={() => {
                  formikProps?.resetForm();
                  props?.onHide();
                }}
              />
              <OAButton
                type='submit'
                onClick={() => {
                  // formikProps?.resetForm();
                  setLoading(true);
                }}
                disabled={
                  (!formikProps?.dirty && formikProps?.isValid && !props?.data?.id) ||
                  (formikProps?.dirty && !formikProps?.isValid && !props?.data?.id)
                }
                label={
                  loading ? (
                    <div className='d-flex justify-content-between'>
                      {props?.data?.id ? 'editing ...' : 'adding ...'}
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    </div>
                  ) : (
                    `${props?.data?.id ? 'Edit' : 'Add'} Transaction`
                  )
                }
              />
            </div>
          </Container>
        );
      }}
    </Formik>
  );
}
