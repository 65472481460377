import { Box } from '@mui/material';
import classNames from 'classnames';
import { OATypography } from '../../../../components/widgets';
import { getFormattedDate, getAgeInYearsOrMonths, removeChar } from '../../../../helper';
import DetailsContainer from '../../../order-management/order-view/OrderTabs/TravellerInfo/DetailsContainer';
import { ReactComponent as SourceDestIcon } from '../../../../assets/icons/source-DestArrow.svg';
import { Segment, Traveller } from './FlightInterface';

interface IFlightsJourney {
  flightType: { segments: [] };
  data: { travellers: [] };
}

export const FlightsJourney = (props: IFlightsJourney) => {
  const { flightType, data } = props;
  return (
    <Box>
      {flightType?.segments?.map((segment: Segment, idx: number) => (
        <Box key={idx}>
          <Box display='flex' alignItems='center' gap='8px' marginTop='24px' marginLeft='16px'>
            <OATypography variant='h6'>{segment?.departure?.airport?.city}</OATypography>
            <SourceDestIcon />
            <OATypography variant='h6'>{segment?.arrival?.airport?.city}</OATypography>
            <OATypography variant='body2' color='textSecondary'>
              {getFormattedDate(segment?.departure?.date, 'ddd, DD MMM, YYYY')}
            </OATypography>
          </Box>
          {data?.travellers?.map((traveller: Traveller) => {
            const age = getAgeInYearsOrMonths(traveller?.dateOfBirth);
            const ageGroup =
              !age.includes('months') && parseInt(age) >= 18
                ? 'Adult'
                : !age.includes('months') && parseInt(age) >= 1
                ? 'Child'
                : 'Infant';
            let pnrNumber = segment?.bookingInfos?.find(
              (obj: any) => obj?.travellerId === traveller?.id
            );
            return (
              <div className={classNames('order-view--traveller-card card', 'order-view--tabs')}>
                <div id={`traveller_${removeChar(traveller?.id)}_info`} className='card-body pb-6'>
                  <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                    <div className='flex-grow-1'>
                      <div className='d-flex justify-content-between align-items-start'>
                        <div className='d-flex flex-column'>
                          <div className='d-flex align-items-center mb-2'>
                            <div className='symbol symbol-circle symbol-20px overflow-hidden me-2'>
                              <div className='symbol-label fs-7 bg-info text-light'>
                                {traveller?.firstName?.charAt(0)}
                              </div>
                            </div>
                            <span className='d-flex flex-column text-capitalize fw-bolder fs-5'>
                              {`${traveller?.firstName} ${traveller?.lastName}`}
                            </span>
                            <span className='badge ms-2 badge-light fs-8 fw-bold'>{ageGroup}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card-body pt-0 pb-2 border-grey-200'>
                  <DetailsContainer
                    data={traveller}
                    flight
                    onwardData={pnrNumber?.pnrNumber ? pnrNumber : flightType}
                    flightData={data}
                    index={idx}
                  />
                </div>
              </div>
            );
          })}
        </Box>
      ))}
    </Box>
  );
};
