import { isEmpty, isUndefined } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import { useAppContext } from '../../context/AppContext';
import DefaultCell from './DefaultCell';

interface IAssignedUserCell {
  userId: string;
}

export default function AssignedUserCell(props: IAssignedUserCell) {
  const { users } = useAppContext();

  const draftUser: any = users?.length && users?.find((obj: any) => obj?.id === props?.userId);

  if (isUndefined(draftUser) || isEmpty(draftUser)) {
    return <Skeleton height={22} className='mb-2' />;
  } else {
    return <DefaultCell data={`${draftUser?.firstName} ${draftUser?.lastName}`} isEllipsis />;
  }
}
