import { Card, Col, Row } from 'react-bootstrap';
import OARangePicker from '../../components/OARangePicker';
import OAToolbar, { IBreadcrumbItem } from '../../components/widgets/OAToolbar';
import ApiCardTile from './ApiStats';
import { useApiContext } from './context/ApiContext';
import ApiProvider from './context/ApiProvider';
import { useEffect, useMemo, useState } from 'react';
import OATableComponent, { OATablePaginationProps } from '../../components/OATableComponent';
import { ReactComponent as VectorIcon } from '../../assets/icons/vector.svg';
import { ReactComponent as UniqueIcon } from '../../assets/icons/finger_print.svg';
import { ReactComponent as SuccessIcon } from '../../assets/icons/success_tick.svg';
import { ReactComponent as FailureIcon } from '../../assets/icons/failure_tick.svg';
import { getOcrColumns } from './OcrColumns';
import OcrSearch from './OcrSearch';
import { useAuth } from '../../auth';
import { Navigate } from 'react-router-dom';
import { Box } from '@mui/material';

const PICKYOURTRAILORGID = [process.env.REACT_APP_PICK_YOUR_TRAIL_ORG_ID];
const ApiWrapper = () => {
  const { queryParams, setQueryParams, getOcrList, pageInfo } = useApiContext();
  const [list, setList] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableLoader, setTableLoader] = useState<boolean>(false);

  useEffect(() => {
    queryParams?.isLoading === true || queryParams?.isLoading === null ? setLoading(true) : null;
    setTableLoader(true);
    getOcrList(res => {
      setList(res);
      setLoading(false);
      setTableLoader(false);
    });
  }, [queryParams]);

  const breadcrumb: Array<IBreadcrumbItem> = [
    {
      title: `Dashboard`,
      path: `/dashboard`,
      isActive: false
    },
    {
      title: `OCR`,
      path: ``,
      isActive: true
    }
  ];

  const onChangeRangePicker = (range: any) => {
    setQueryParams(prev => ({ ...prev, dateRange: range, isLoading: true }));
  };

  const columns: any = useMemo(() => {
    return getOcrColumns();
  }, []);

  const paginationProps: OATablePaginationProps = {
    pageIndex: queryParams?.pageIndex,
    pageCount: pageInfo?.totalPages,
    total: pageInfo?.totalCount,
    pageSize: pageInfo?.size,
    onPageChange: (value: any) => {
      setQueryParams(prev => ({ ...prev, pageIndex: value, isLoading: true }));
    }
  };
  return (
    <div>
      <OAToolbar
        title='Passport OCR'
        breadcrumb={breadcrumb}
        actionsElement={
          <div className='d-flex'>
            <div className='me-4'>
              <OARangePicker
                className='dashboard-data-range-filter'
                name='order-list-data-range'
                onChangeRangePicker={onChangeRangePicker}
                dateRange={queryParams?.dateRange}
                maxDate={new Date()}
                shortcuts
              />
            </div>
          </div>
        }
      />
      <Box sx={{ display: 'flex', margin: '1rem 1rem 0 0' }}>
        <ApiCardTile
          title={'Total calls'}
          data={list?.totalCalls}
          isLoading={loading}
          icon={<VectorIcon />}
        />
        <ApiCardTile
          title={'Successful calls'}
          data={list?.totalSuccessCalls}
          isLoading={loading}
          icon={<SuccessIcon />}
        />
        <ApiCardTile
          title={'Failed calls'}
          data={list?.totalFailedCalls}
          isLoading={loading}
          icon={<FailureIcon />}
        />
        <ApiCardTile
          title={'Unique passport'}
          data={list?.totalUniqueCalls}
          isLoading={loading}
          icon={<UniqueIcon />}
        />
      </Box>
      <Card className='mt-6'>
        <OcrSearch totalOrder={list} isLoading={loading} />
        <Card.Body className='p-0 px-2'>
          <OATableComponent
            columns={columns}
            data={list && list?.content?.length > 0 ? list?.content : []}
            pagination={paginationProps}
            loading={tableLoader}
            disableColumnMenu={false}
          />
        </Card.Body>
      </Card>
    </div>
  );
};

const Api = () => {
  const { currentUser } = useAuth();
  if (PICKYOURTRAILORGID?.includes(currentUser?.data?.organizationId)) {
    return (
      <ApiProvider>
        <ApiWrapper />
      </ApiProvider>
    );
  } else {
    return <Navigate to='/error/404' />;
  }
};

export default Api;
