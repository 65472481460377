import { FC, Fragment } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';

import { Logout, AuthPage, useAuth } from '../auth';
import { ErrorsPage } from '../app/modules/errors/ErrorsPage';
import { PrivateRoutes } from './PrivateRoutes';
import { App } from '../App';
import TermsAndPrivacy from '../StaticPage';
import SignUp from '../pages/SignUp';

const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const { currentUser } = useAuth();
  const queryParams = new URLSearchParams(window.location.search);
  const deepLinkMode = queryParams.get('mode');
  const loginType = queryParams.get('loginType');
  const token = queryParams.get('token');
  const id = queryParams.get('id');

  // Create a new URLSearchParams instance to hold the query parameters
  const newQueryParams = new URLSearchParams();

  // Conditionally add each parameter
  if (deepLinkMode) newQueryParams.append('mode', deepLinkMode);
  if (loginType) newQueryParams.append('loginType', loginType);
  if (token) newQueryParams.append('token', token);
  if (id) newQueryParams.append('id', id);

  // Construct the final URL using location.pathname and the constructed query parameters
  let deepLinkUrl = location.pathname + '?' + newQueryParams.toString();

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='terms-and-conditions' element={<TermsAndPrivacy />} />
          <Route path='privacy-policy' element={<TermsAndPrivacy />} />
          <Route path='signup' element={<SignUp />} />
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <Fragment>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </Fragment>
          ) : (
            <Fragment>
              <Route path='auth/*' element={<AuthPage />} />
              <Route
                path='*'
                element={<Navigate to='/auth' state={{ deepLinkUrl: deepLinkUrl }} />}
              />
            </Fragment>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
