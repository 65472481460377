import { Box } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';

interface OICalculationTableProps {
  data: Array<any>;
  columns: GridColDef[];
}

export default function OICalculationTable(props: OICalculationTableProps) {
  const { data = [] } = props;

  return (
    <Box>
      <DataGridPro
        rows={data}
        columns={props?.columns}
        disableColumnMenu
        disableRowSelectionOnClick
        className='oa-table-component border-0 no-hover'
        hideFooter
        columnHeaderHeight={0}
      />
    </Box>
  );
}
