import classNames from 'classnames';
import React from 'react';
import { Spinner } from 'react-bootstrap';

export default function OALoader(props: { innerComponent: boolean; size?: 'lg' | 'md' | 'sm' }) {
  const { innerComponent, size = 'lg' } = props;

  const loaderClassNames = classNames('oa-loader', {
    'oa-loader--inner': innerComponent
  });

  const spinnerClassNames = classNames({
    'spinner-lg': size === 'lg'
  });

  return (
    <div className={loaderClassNames}>
      <Spinner animation='border' className={spinnerClassNames} />
    </div>
  );
}
