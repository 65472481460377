import { useContext, useState } from 'react';
import ClaimContext from '../context/ClaimContext';
import OAButton from '../../../components/widgets/OAButton';
import { Box } from '@mui/material';

interface ISettleClaim {
  data: any;
  onHide: () => void;
}

export const SettleClaim = (props: ISettleClaim) => {
  const { settleClaim } = useContext(ClaimContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleSettlement = (id: string) => {
    setIsLoading(true);
    settleClaim(id, res => {
      setIsLoading(false);
    });
  };
  return (
    <Box>
      {props?.data?.status === 'PAYMENT_COMPLETED' && (
        <OAButton
          label='Settle'
          isLoading={isLoading}
          loadingText=''
          onClick={() => handleSettlement(props?.data?.id)}
        />
      )}
    </Box>
  );
};
