import { Card } from 'react-bootstrap';

import OANoData from '../../../components/Common/OANoData';
import { KTSVG } from '../../../_metronic/helpers';
import { SkeletonComponent } from './TopSuppliersTile';
const iso3311a2 = require('iso-3166-1-alpha-2');

const TopCountriesTile = ({
  data,
  isLoading,
  dateRange
}: {
  data: any;
  isLoading: boolean;
  dateRange: string;
}) => {
  return (
    <Card className='h-100 overflow-auto'>
      <span className='menu-icon position-absolute top-0 end-0 p-2'>
        <KTSVG path='/media/icons/duotune/Move.svg' className='svg-icon-2' />
      </span>
      <Card.Body>
        <div className='fw-bold'>
          <h3 className='mb-1'>Top countries</h3>
          <p className='fs-7 text-muted mb-0'>{dateRange}</p>
        </div>
        <div className='table-responsive mt-4'>
          {isLoading ? <SkeletonComponent /> : <CountriesTable data={data} />}
        </div>
      </Card.Body>
    </Card>
  );
};

export default TopCountriesTile;

const CountriesTable = ({ data }: { data: any }) => {
  return (
    <>
      {data && data.length > 0 ? (
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          <tbody>
            {data?.map((obj: any, index: any) => (
              <tr key={index}>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start align-items-center'>
                      <div>
                        <img
                          style={{ width: '32px', height: '24px' }}
                          alt='country-img'
                          src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${obj?.countryCode}.svg`}
                        />
                      </div>

                      <span className='text-dark fw-bolder d-block fs-6 ms-4'>
                        {iso3311a2.getCountry(obj?.countryCode)}
                      </span>
                    </div>
                  </div>
                </td>
                <td className='text-end'>
                  <span className='text-dark fw-bolder d-block fs-6'>{obj?.totalOrderCount}</span>
                  <span className='text-muted fw-bold text-muted d-block fs-7'>Orders</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <OANoData icon='/media/icons/duotune/general/gen005.svg' />
      )}
    </>
  );
};
