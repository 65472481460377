import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';

declare module '@mui/material/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    tag: true;
    caption: true;
    form1: true;
    form2: true;
    tooltip: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#3A86FF',
      light: '#EBF3FF',
      dark: '#3678E1'
    },
    secondary: {
      main: '#7f8297',
      light: '#F6F8FA'
    },
    info: {
      main: '#6B3CE1',
      light: '#F0EBFC',
      dark: '#572FBC'
    },
    success: {
      main: '#2FB344',
      light: '#EAF7EC',
      dark: '#229334'
    },
    warning: {
      main: '#F59F00',
      light: '#FEF5E5',
      dark: '#E19200'
    },
    error: {
      main: '#D63939',
      light: '#FBEBEB',
      dark: '#C13434'
    },
    background: {
      default: '#f5f8fa'
    },
    text: {
      primary: '#011229',
      secondary: '#616876'
    },
    grey: {
      500: '#011229',
      400: '#616876',
      300: '#B5B5C2',
      200: '#EEEFF3',
      100: '#EFF2F5',
      50: '#F6F8FA'
    }
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    // fontSize: 13, // default font size 13px
    h1: {
      fontSize: '24px',
      fontWeight: '600',
      color: '#011229',
      lineHeight: '32px'
    },
    h2: {
      fontSize: '20px',
      fontWeight: '600',
      color: '#011229',
      lineHeight: '28px'
    },
    h3: {
      fontSize: '20px',
      fontWeight: '500',
      color: '#011229',
      lineHeight: '28px'
    },
    h4: {
      fontSize: '16px',
      fontWeight: '600',
      color: '#011229',
      lineHeight: '24px'
    },
    h5: {
      fontSize: '14px',
      fontWeight: '500',
      color: '#011229',
      lineHeight: '24px'
    },
    h6: {
      fontSize: '14px',
      fontWeight: '600',
      color: '#011229',
      lineHeight: '24px'
    },
    subtitle1: {
      fontSize: '14px',
      fontWeight: '400',
      color: '#011229',
      lineHeight: '24px'
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: '600',
      color: '#011229',
      lineHeight: '20px'
    },
    body1: {
      fontSize: '14px',
      fontWeight: '500',
      color: '#011229',
      lineHeight: '20px'
    },
    body2: {
      fontSize: '14px',
      fontWeight: '400',
      color: '#011229',
      lineHeight: '20px'
    }
  },
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'tag' },
          style: {
            fontSize: '12px',
            fontWeight: '500',
            lineHeight: '12px',
            letterSpacing: '0.5px',
            color: '#011229'
          }
        },
        {
          props: { variant: 'caption' },
          style: {
            fontSize: '12px',
            fontWeight: '600',
            lineHeight: '16px',
            letterSpacing: '0.4px',
            color: '#011229'
          }
        },
        {
          props: { variant: 'form1' },
          style: {
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '16px',
            color: '#011229'
          }
        },
        {
          props: { variant: 'form2' },
          style: {
            fontSize: '12px',
            fontWeight: '500',
            lineHeight: '14px',
            color: '#011229'
          }
        },
        {
          props: { variant: 'tooltip' },
          style: {
            fontSize: '12px',
            fontWeight: '400',
            lineHeight: '18px',
            color: '#011229'
          }
        }
      ]
    }
    // MuiDataGrid: {},
  }
});
