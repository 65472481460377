import React from 'react';
import { Card, Spinner } from 'react-bootstrap';
import classNames from 'classnames';

import ImgPlusSvg from '../../../../../components/Icons/ImgPlusSVG';

interface IDocumentUpload {
  title: string;
  description: string;
  inputName: string;
  isLoading?: boolean;
  handleSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  id: string;
}

const DocumentUpload = (props: IDocumentUpload) => {
  return (
    <Card
      className='passport-image-container document-card border border-dashed border-primary'
      as='label'
      htmlFor={`doc-upload-${props?.inputName}`}>
      <Card.Body className='document-empty d-flex flex-column align-items-center p-6'>
        <span className={classNames('loading-overlay', { active: props?.isLoading })}>
          {props?.isLoading && <Spinner animation='border' variant='light' />}
        </span>
        <span>
          <ImgPlusSvg />
        </span>
        <p>
          <input
            type='file'
            id={props?.id}
            accept='image/*'
            className='docuemnt-card--upload-input d-none'
            onChange={props?.handleSelect}
            disabled={props?.isLoading}
          />
        </p>
        <p className='fw-semibold fs-6'>{props?.title}</p>
        <p className='text-center'>{props?.description}</p>
      </Card.Body>
    </Card>
  );
};

export default DocumentUpload;
