import * as Yup from 'yup';

export const requiredFieldsValidationRules: any = {
  nationality: Yup.string().label('Nationality').required(),
  passportIssuingCountry: Yup.string().label('Passport issuing country').required(),
  passportIssuingPlace: Yup.string()
    .label('Passport Issuing place')
    .required()
    .typeError('Invalid date'),
  passportIssuingDate: Yup.date()
    .label('Passport Issued Date')
    .nullable()
    .required()
    .typeError('Invalid date'),
  passportExpiration: Yup.date().label('Passport expiry date').nullable().required(),
  passportNumber: Yup.string()
    .label('Passport number')
    .required()
    .matches(/^[A-Za-z]{1}-?\d{7}$/, 'Invalid passport number'),
  firstName: Yup.string()
    .label('First Name')
    .required()
    .matches(/^[A-Za-z ]*$/, 'Invalid first name.'),
  lastName: Yup.string()
    .label('Last Name')
    .required()
    .matches(/^[A-Za-z ]*$/, 'Invalid last name.'),
  country: Yup.string().label('Country').required(),
  gender: Yup.string().label('Gender').required(),
  phone: Yup.string()
    .label('Mobile number')
    .required()
    .min(13, 'Mobile number must be at least 10 characters')
    .max(13, 'Mobile number must be at least 10 characters'),
  dateOfBirth: Yup.date().label('Date of birth').nullable().required(),
  state: Yup.string().label('State').required(),
  title: Yup.string().label('Title').required(),
  email: Yup.string().email().label('Email address').required(),
  line1: Yup.string().label('Full Address').required(),
  postalCode: Yup.string().label('Postal code').required(),
  occupation: Yup.string().label('Occupation').required(),
  birthCountry: Yup.string().label('Birth country').required(),
  birthPlace: Yup.string().label('Birth place').required(),
  fatherName: Yup.string().label('Father name').required(),
  maritalStatus: Yup.string().label('Marital status').required(),
  religion: Yup.string().label('Religion').required(),
  profession: Yup.string().label('Profession').required(),
  motherName: Yup.string().label('Mother name').required(),
  consulate: Yup.string().label('Consulate').required(),
  originLine1: Yup.string().label('Origin Address').required(),
  originCity: Yup.string().label('Origin City').required(),
  originState: Yup.string().label('Origin State').required(),
  originPostalCode: Yup.string().label('Origin Postal Code').required(),
  destinationLine1: Yup.string().label('Destination Address').required(),
  destinationCity: Yup.string().label('Destination City').required(),
  destinationState: Yup.string().label('Destination State').required(),
  destinationPostalCode: Yup.string().label('Destination Postal Code').required(),
  arrivalCarrierName: Yup.string().label('Arrival Carrier Name').required(),
  arrivalCarrierNumber: Yup.string().label('Arrival Carrier Number').required(),
  arrivalPnrNumber: Yup.string().label('Arrival PNR number').required(),
  arrivalIATA: Yup.string().label('Arrival IATA').required(),
  departureCarrierName: Yup.string().label('Departure Carrier Name').required(),
  departureCarrierNumber: Yup.string().label('Departure Carrier Number').required(),
  departurePnrNumber: Yup.string().label('Departure PNR number').required(),
  departureIATA: Yup.string().label('Departure IATA').required(),
  previousNationality: Yup.string().label('Previous Nationality').required(),
  education: Yup.string().label('Education').required()
};

export const optionalFieldsValidationRules: any = {
  oldPassportCount: Yup.string()
    .label('Old passport count')
    .matches(/^([1-9]|10)$/, 'Invalid old passport count'),
  passportIssuingCountry: Yup.string().label('Passport issuing country'),
  passportIssuingPlace: Yup.string().label('Passport Issuing place'),
  passportIssuingDate: Yup.date()
    .label('Passport Issued Date')
    .nullable()
    .typeError('Invalid date'),
  passportExpiration: Yup.date()
    .min(new Date(), `Passport is expired already`)
    .label('Passport expiry date')
    .nullable()
    .typeError('Invalid date'),
  passportNumber: Yup.string().matches(/^[A-Za-z]{1}-?\d{7}$/, 'Invalid passport number')
  // pnrNumber: Yup.string().label('PNR number').max(20, 'PNR number must be at most 20 characters'),
  // ticketNumber: Yup.string()
  //   .label('Ticket number')
  //   .max(20, 'Ticket number must be at most 20 characters')
};
