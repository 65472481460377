import { Modal, Row, Col } from 'react-bootstrap';

export default function InsuranceBenefitsModal(props: {
  show: boolean;
  onHide: () => void;
  data: any;
}) {
  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>{props?.data?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {props?.data?.benefits?.map((benefit: any, index: number) => (
            <Col lg={6} key={`${index}_${benefit?.name}`}>
              <div className='d-flex flex-column justify-content-center'>
                <p className='text-gray-800 fs-7 fw-bold m-0'>{benefit?.name ?? ''}</p>
                {benefit?.additionalConditions?.map((item: any) => (
                  <td className='mb-2'>
                    <li className='text-gray-600'> {item} </li>
                  </td>
                ))}
                <p className='text-gray-600 fs-6 mb-4'>{benefit?.sumInsured ?? ''}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Modal.Body>
    </Modal>
  );
}
