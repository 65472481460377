import React from 'react';

import { IUserFormValues } from '../types';
import { IPageInfo } from '../../../components/OATableComponent';

export interface IApiKeyContext {
  isLoading: boolean;
  apiKeys: Array<any>;
  pageIndex: number;
  pageCount: number;
  isOpen: boolean;
  selectedApiKey: any;
  pageInfo: IPageInfo;
  fetchApiKeys: () => Promise<void>;
  onPageChange: (value: any) => void;
  toggleModal: () => void;
  onClickCreateApiKey: () => void;
  onCreateApiKey: (values: IUserFormValues, callback?: (success: boolean) => void) => Promise<void>;
  onSelectApiKey: (userId: string) => void;
  onDeleteApiKey: (userId: string, callback?: () => void) => Promise<void>;
}

const ApiKeyContext = React.createContext<IApiKeyContext>({} as IApiKeyContext);

export default ApiKeyContext;

export const useApiKeyContext = () => React.useContext(ApiKeyContext);
