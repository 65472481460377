import { Box, Card, CardContent } from '@mui/material';
import { FC, useState } from 'react';
import { OATypography } from '../../widgets';
import { ReactComponent as TimeIcon } from '../../../assets/icons/time.svg';
import moment from 'moment';
import { ArrowDownSVG } from '../../Icons';
interface ILatestNews {
  news: any[];
}

export const LatestNews: FC<ILatestNews> = ({ news }) => {
  const [openCard, setOpenCard] = useState<number | null>(null);

  return (
    <div>
      {news?.length > 0 &&
        news?.map((news: any, index: number) => (
          <Card
            sx={{
              borderLeft: '8px solid #D63939',
              borderRadius: '8px',
              marginTop: index > 0 ? '8px' : null
            }}>
            <CardContent sx={{ pb: '0 !important' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <OATypography
                  variant='body1'
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: 'calc(100% - 100px)'
                  }}>
                  {news?.title}
                </OATypography>
                <div
                  className='cursor-pointer'
                  onClick={() => (openCard === index ? setOpenCard(null) : setOpenCard(index))}>
                  {
                    <ArrowDownSVG
                      down={openCard === index ? false : true}
                      width='16'
                      height='16'
                      fill='#616876'
                    />
                  }
                </div>
              </Box>
              {openCard === index && (
                <OATypography variant='body2' sx={{ marginTop: '4px' }}>
                  {news?.summary}
                </OATypography>
              )}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
                <Box sx={{ display: 'flex' }}>
                  <TimeIcon className='mt-1' />
                  <OATypography
                    variant='body2'
                    sx={{ color: theme => theme.palette.grey[400], marginLeft: '8px' }}>
                    {getUpdatedTime(news?.updatedAt)}
                  </OATypography>
                </Box>
                <a href={news?.source} target='_blank' rel='noreferrer'>
                  <p className='text-decoration-underline'>Source</p>
                </a>
              </Box>
            </CardContent>
          </Card>
        ))}
    </div>
  );
};

/* helper function specific to this file usage for now. 
   we can move it to common helper folder when the need arises. 
*/
const getUpdatedTime = (updatedAt: moment.MomentInput) => {
  const now = moment(); // current time
  const updated = moment(updatedAt); // time when the post was last updated
  const duration = moment.duration(now.diff(updated));
  const days = Math.round(duration.asDays());

  return days < 1 ? 'updated today' : days === 1 ? 'updated 1 day ago' : `updated ${days} days ago`;
};
