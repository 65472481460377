import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Button, Col, Container, Row } from 'react-bootstrap';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';
import moment from 'moment';

import OASelectField from '../../../../../components/fields/OASelectField';
import { DepartureCountries, ORDER_TYPE, VisaTypeOptions } from '../../../constants';
import { useOrderContext } from '../../../context/OrderContext';
import OARadioField from '../../../../../components/fields/OARadioField';
import OATextField from '../../../../../components/fields/OATextField';
import OARangePickerField from '../../../../../components/fields/OARangePickerField';

interface ISearchVisaForm {
  onSubmit: (
    values: any,
    type: string,
    actions?: FormikHelpers<any>,
    cb?: ((response?: any) => void) | undefined
  ) => void;
}

const inits: any = {
  departure: 'IN',
  arrival: '',
  visaType: '',
  tripType: '',
  supplier: '',
  orderType: 'single',
  groupName: '',
  tripDuration: {}
};

const validate = (values: any) => {
  const errors: any = {};

  if (!values?.departure) {
    errors.departure = 'Departure is a required field';
  }
  if (!values?.arrival) {
    errors.arrival = 'Arrival is a required field';
  }
  if (!values?.visaType) {
    errors.visaType = 'Purpose is a required field';
  }
  if (values?.orderType === 'group' && !values?.groupName) {
    errors.groupName = 'Group name is a required field';
  }
  if (!(values?.tripDuration?.from && values?.tripDuration?.to)) {
    errors.tripDuration = 'Duration is a required field';
  } else if (
    !(moment(values?.tripDuration?.from).isValid() && moment(values?.tripDuration?.to).isValid()) ||
    (values?.tripDuration?.from && moment(values?.tripDuration?.from).isBefore(moment(), 'day')) ||
    (values?.tripDuration?.from &&
      values?.tripDuration?.to &&
      moment(values?.tripDuration?.to).isBefore(moment(values?.tripDuration?.from)))
  ) {
    errors.tripDuration = 'Invalid duration selected';
  }

  return errors;
};

function SearchVisaForm(props: ISearchVisaForm) {
  const location = useLocation();
  let [searchParams] = useSearchParams();
  /**
   * NOTE: get 'cloneOrderId' from 'searchParams' and store clone order data in formState...
   */
  const cloneOrderId = searchParams.get('cloneOrderId');
  /**
   * NOTE: check if selected tab was a group or regular one...
   */
  const selectedTab = location.state?.selectedTab;

  const {
    formState,
    products,
    resetProducts,
    onRemoveTripDetails,
    discoverMasterProduct,
    getCountriesList,
    updateFormState
  } = useOrderContext();

  const [initValues, setInitValues] = useState<any>(inits);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [prevValue, setPrevValue] = useState<any>(null);
  const [countries, setCountries] = useState<any>(null);
  const [countryListLoading, setCountryListLoading] = useState<boolean>(true);

  useEffect(() => {
    getCountriesList(res => {
      setCountryListLoading(false);
      setCountries(res?.filter((country: any) => Object.keys(country).length !== 0));
    });
  }, []);

  useEffect(() => {
    if (selectedTab && selectedTab === 'group')
      setInitValues((prev: any) => ({ ...prev, orderType: selectedTab }));
  }, [selectedTab]);
  /**
   * NOTE: if values of search visa form changes then reset products immediately
   * to avoid caching issue ...
   */
  useEffect(() => {
    if (!cloneOrderId) {
      const compareObjectResult = isEqual(prevValue, initValues);
      if (!compareObjectResult) {
        resetProducts();
      }
      formState.travellerDetails = [];
    }
  }, [prevValue]);

  useEffect(() => {
    if (!!cloneOrderId) {
      if (formState?.tripDetails?.trip && formState?.tripDuration) {
        const trip = formState?.tripDetails?.trip;

        const draft = Object.assign({}, initValues, {
          departure: trip?.origin?.countryCode,
          arrival: trip?.destination?.[0]?.countryCode,
          tripType: trip?.tripType,
          visaType: trip?.visaType,
          orderType: 'single',
          tripDuration: {
            from: moment(formState?.tripDuration?.from),
            to: moment(formState?.tripDuration?.to)
          }
        });
        setInitValues(draft);

        if (isNull(products)) {
          discoverMasterProduct(ORDER_TYPE.VISA, draft, _res => {
            setIsLoading(false);
          });
        }
      }
    }
  }, [formState, location]);

  const handleSubmit = (values: any, actions: FormikHelpers<any>) => {
    if (values?.tripDuration?.from && values?.tripDuration?.to) {
      updateFormState({
        tripDuration: {
          from: new Date(values?.tripDuration?.from),
          to: new Date(values?.tripDuration?.to)
        }
      });
    }

    setIsLoading(true);
    onRemoveTripDetails();
    discoverMasterProduct(ORDER_TYPE.VISA, values, _res => {
      setIsLoading(false);
    });
  };

  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleSubmit}
      validate={validate}
      enableReinitialize>
      {(_formikProps: FormikProps<any>) => {
        const { values } = _formikProps;
        setPrevValue(values);
        return (
          <Form className='search-visa-form w-100'>
            <div className='mb-6'>
              <h3 className='fw-semibold text-dark mb-0'>Search visa</h3>
              <p className='text-gray-400 fw-bold fs-6 mb-0'>Compare and find the right visa</p>
            </div>
            <Container fluid className='p-0'>
              <Row>
                <Col className='mb-4'>
                  <label className='form-label required'>OrderType</label>
                  <OARadioField
                    name='orderType'
                    label=''
                    options={[
                      { label: 'Single', value: 'single' },
                      { label: 'Group', value: 'group' }
                    ]}
                    alignment='horizontal'
                  />
                </Col>
              </Row>
              {_formikProps.values.orderType === 'group' && (
                <Row>
                  <Col className='mb-4'>
                    <OATextField name='groupName' label='Group Name' required />
                  </Col>
                </Row>
              )}
              <Row>
                <Col lg={4} className='mb-4'>
                  <Field
                    name='departure'
                    label='Origin'
                    as={OASelectField}
                    options={DepartureCountries}
                    valueOnly
                    required
                  />
                </Col>
                <Col lg={4} className='mb-4'>
                  <Field
                    name='arrival'
                    label='Destination'
                    as={OASelectField}
                    options={countries}
                    valueOnly
                    required
                    isLoading={countryListLoading}
                    isDisabled={countryListLoading}
                  />
                </Col>
                <Col lg={4} className='mb-4'>
                  <Field
                    name='visaType'
                    label='Purpose'
                    as={OASelectField}
                    options={VisaTypeOptions}
                    valueOnly
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={4} className='mb-4'>
                  <OARangePickerField
                    label='Duration'
                    name='tripDuration'
                    direction='up'
                    minDate={moment().toDate()}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col className='d-grid'>
                  <Button size='sm' type='submit' disabled={isLoading}>
                    {isLoading ? (
                      <React.Fragment>
                        Loading...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2' />
                      </React.Fragment>
                    ) : (
                      'Search'
                    )}
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
}

export default SearchVisaForm;
