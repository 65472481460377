import { useContext, useEffect, useState } from 'react';

import OARangePicker from '../../../components/OARangePicker';
import OAToolbar from '../../../components/widgets/OAToolbar';
import FlightContext from '../context/FlightContext';
import { FlightOrderHistory } from './flightOrderHistory';
import BreadcrumbItem from './BreadcrumbItem';
import OAButton from '../../../components/widgets/OAButton';

interface IFlightQueryParams {
  data: any;
}
export const FlightsDashboard = (props: IFlightQueryParams) => {
  const { queryParams, setQueryParams } = props?.data;
  const { getFlightOrderList, downloadReport, report } = useContext(FlightContext);

  const [list, setList] = useState<Array<any>>([]);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const claimList = () => {
    setIsLoading(true);
    getFlightOrderList(res => {
      setIsLoading(false);
      setList(res);
      setTotalPage(res?.totalPages ?? 0);
    });
  };

  useEffect(() => {
    if (queryParams?.dateRange?.[0] && queryParams?.dateRange?.[1]) {
      claimList();
    }
  }, [queryParams]);

  const onChangeRangePicker = (range: any) => {
    setQueryParams((prev: any) => ({ ...prev, dateRange: range }));
  };
  return (
    <>
      <OAToolbar
        title={'Flights'}
        breadcrumb={BreadcrumbItem()}
        actionsElement={
          <div className='d-flex'>
            <div className='me-4'>
              <OARangePicker
                className='dashboard-data-range-filter'
                name='dashboard-data-range'
                onChangeRangePicker={onChangeRangePicker}
                dateRange={queryParams?.dateRange}
                maxDate={new Date()}
              />
            </div>
            <div className='ms-4'>
              <OAButton
                label='Report'
                icon='/media/icons/duotune/files/fil021.svg'
                iconClassName='svg-icon-3 me-2'
                loadingText='generating...'
                isLoading={report?.isLoading}
                onClick={downloadReport}
                className='btn btn-sm btn-flex btn-light btn-primary fw-bolder'
                disabled={report?.isLoading}
              />
            </div>
          </div>
        }
      />
      <FlightOrderHistory
        list={list ?? []}
        totalPage={totalPage}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        onChangeRangePicker={onChangeRangePicker}
        loading={isLoading}
      />
    </>
  );
};
