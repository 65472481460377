/* eslint-disable no-unused-vars */
import { FormikProps } from 'formik';
import { add, sub } from 'date-fns';

import {
  CountryOptions,
  GenderOptions,
  MemberTitleOptions,
  NationalityOptions,
  OccupationOptions,
  PassportIssuingCountriesOptions,
  MaritalStatusOptions
} from '../../../../constants';
import { FieldData } from '../DynamicFormRenderer';

export const generatePrimaryInformationFields = (options: any, isLoading: boolean): FieldData[] => [
  {
    fieldName: 'title',
    label: 'Title',
    fieldType: 'select',
    options: MemberTitleOptions
  },
  {
    fieldName: 'firstName',
    label: 'First Name',
    fieldType: 'text'
  },
  {
    fieldName: 'lastName',
    label: 'Last Name',
    fieldType: 'text'
  },
  {
    fieldName: 'dateOfBirth',
    label: 'Date of birth',
    fieldType: 'date',
    maxDate: sub(new Date(), { months: 6, days: 1 }),
    minDate: '1901-01-01'
  },
  {
    fieldName: 'gender',
    label: 'Gender',
    fieldType: 'select',
    options: GenderOptions
  },
  {
    fieldName: 'occupation',
    label: 'Occupation',
    fieldType: 'select',
    options: options.OCCUPATION
  }
  // ... other field objects
];

export const generateAdditionalPrimaryInfoFields = (
  options: any,
  isLoading: boolean
): FieldData[] => [
  {
    fieldName: 'fatherName',
    label: "Father's Name",
    fieldType: 'text'
  },
  {
    fieldName: 'birthCountry',
    label: 'Birth country',
    fieldType: 'select',
    options: CountryOptions
  },
  {
    fieldName: 'birthPlace',
    label: 'Birth place',
    fieldType: 'text'
  },
  {
    fieldName: 'maritalStatus',
    label: 'Marital Status',
    fieldType: 'select',
    options: MaritalStatusOptions
  },
  {
    fieldName: 'religion',
    label: 'Religion',
    fieldType: 'select',
    options: options?.RELIGION
  },
  {
    fieldName: 'profession',
    label: 'Profession',
    fieldType: 'select',
    options: options?.PROFESSION,
    isLoading
  },
  {
    fieldName: 'motherName',
    label: "Mother's name",
    fieldType: 'text'
  },
  {
    fieldName: 'previousNationality',
    label: 'Previous Nationality',
    fieldType: 'select',
    options: NationalityOptions
  },
  {
    fieldName: 'education',
    label: 'Education',
    fieldType: 'select',
    options: options?.EDUCATION
  },
  {
    fieldName: 'annualIncome',
    label: 'Annual Income',
    fieldType: 'select',
    options: options?.ANNUAL_INCOME
  }
];

export const generateContactInfoFields = (states: any): FieldData[] => {
  return [
    {
      fieldName: 'phone',
      label: 'Mobile number',
      fieldType: 'phone',
      colWidth: 6
    },
    {
      fieldName: 'email',
      label: 'Email address',
      fieldType: 'text',
      colWidth: 6
    },
    {
      fieldName: 'line1',
      label: 'Full Address',
      fieldType: 'text',
      colWidth: 12
    },
    {
      fieldName: 'country',
      label: 'Country',
      fieldType: 'select',
      options: CountryOptions?.filter(c => c?.value === 'IN')
    },
    {
      fieldName: 'state',
      label: 'State',
      fieldType: 'select',
      options: states
    },
    {
      fieldName: 'postalCode',
      label: 'Postal Code',
      fieldType: 'text'
    }
  ];
};

export const generateTravelInfoFields = (states: any): FieldData[] => {
  return [
    {
      fieldName: 'originLine1',
      label: 'Origin Address',
      fieldType: 'text',
      colWidth: 12
    },
    {
      fieldName: 'originCity',
      label: 'Origin City',
      fieldType: 'text'
    },
    {
      fieldName: 'originState',
      label: 'Origin State',
      fieldType: 'select',
      options: states
    },
    {
      fieldName: 'originPostalCode',
      label: 'Origin Postal Code',
      fieldType: 'text'
    },
    {
      fieldName: 'destinationLine1',
      label: 'Destination Address',
      fieldType: 'text',
      colWidth: 12
    },
    {
      fieldName: 'destinationCity',
      label: 'Destination City',
      fieldType: 'text'
    },
    {
      fieldName: 'destinationState',
      label: 'Destination State',
      fieldType: 'select',
      options: states
    },
    {
      fieldName: 'destinationPostalCode',
      label: 'Destination Postal Code',
      fieldType: 'text'
    }
  ];
};

export const generateTravelDetailsFields = (options: any, isLoading: boolean): FieldData[] => {
  return [
    {
      fieldName: 'arrivalIATA',
      label: 'Arrival IATA',
      fieldType: 'select',
      options: options?.airportCodes,
      colWidth: 5,
      valueOnly: true,
      isLoading
    },
    {
      fieldName: 'arrivalCarrierName',
      label: 'Arrival Carrier Name',
      fieldType: 'text',
      colWidth: 3
    },
    {
      fieldName: 'arrivalCarrierNumber',
      label: 'Arrival Carrier Number',
      fieldType: 'text',
      colWidth: 2
    },
    {
      fieldName: 'arrivalPnrNumber',
      label: 'Arrival PNR Number',
      fieldType: 'text',
      colWidth: 2
    },
    {
      fieldName: 'departureIATA',
      label: 'Departure IATA',
      fieldType: 'select',
      options: options?.airportCodes,
      colWidth: 5,
      valueOnly: true,
      isLoading
    },
    {
      fieldName: 'departureCarrierName',
      label: 'Departure Carrier Name',
      fieldType: 'text',
      colWidth: 3
    },
    {
      fieldName: 'departureCarrierNumber',
      label: 'Departure Carrier Number',
      fieldType: 'text',
      colWidth: 2
    },
    {
      fieldName: 'departurePnrNumber',
      label: 'Departure PNR Number',
      fieldType: 'text',
      colWidth: 2
    }
  ];
};

export const generatePassportFields = (formState: any): FieldData[] => [
  {
    fieldName: 'nationality',
    label: 'Nationality',
    fieldType: 'select',
    options: NationalityOptions
  },
  {
    fieldName: 'passportIssuingCountry',
    label: 'Passport issuing country',
    fieldType: 'select',
    options: PassportIssuingCountriesOptions
  },
  {
    fieldName: 'passportIssuingPlace',
    label: 'Passport Issuing Place',
    fieldType: 'text'
  },
  {
    fieldName: 'passportNumber',
    label: 'Passport number',
    fieldType: 'text',
    inputClassName: 'text-uppercase'
  },
  {
    fieldName: 'passportIssuingDate',
    label: 'Passport Issue date',
    fieldType: 'date',
    maxDate: new Date(),
    openDirection: 'up'
  },
  {
    fieldName: 'passportExpiration',
    label: 'Passport Expiry date',
    fieldType: 'date',
    openDirection: 'up',
    minDate: add(new Date(formState?.tripDuration?.from), { months: 6 })
  },
  {
    fieldName: 'oldPassportCount',
    label: 'Old passport count',
    fieldType: 'text'
  },
  {
    fieldName: 'pnrNumber',
    fieldType: 'text',
    label: 'PNR Number',
    placeholder: 'PNR, comma separated'
  },
  {
    fieldName: 'ticketNumber',
    fieldType: 'text',
    label: 'Ticket Number',
    placeholder: 'Ticket Number, comma separated'
  }
];

export const generateBookingInformationFields = (
  options: any,
  formProps: FormikProps<any>,
  isLoading: boolean
): FieldData[] => [
  {
    fieldName: 'propertyType',
    label: 'Stay type',
    fieldType: 'select',
    options: options?.placeOfStay,
    colWidth: 6,
    valueOnly: true
  },
  {
    fieldName: 'propertyName',
    label: 'Stay name',
    placeholder: "Add owner's name/hotel name",
    fieldType: 'text',
    colWidth: 6
  },
  {
    fieldName: 'propertyAddress.line1',
    label: 'Stay address',
    fieldType: 'text',
    colWidth: 12
  },
  {
    fieldName: 'propertyAddress.postalCode',
    label: 'Postal code',
    fieldType: 'text',
    colWidth: 6
  },
  {
    fieldName: 'propertyAddress.city',
    label: 'City',
    fieldType: 'select',
    options: options?.city,
    colWidth: 6,
    valueOnly: true,
    isLoading
  },
  {
    fieldName: 'propertyAddress.district',
    label: 'District/Area',
    fieldType: 'select',
    options: options?.district,
    onChangeData: (option: any) => {
      if (option === formProps?.values?.propertyAddress?.district) return;
      else formProps?.setFieldValue('propertyAddress.subDistrict', '');
    },
    colWidth: 6,
    valueOnly: true,
    isLoading
  },
  {
    fieldName: 'propertyAddress.subDistrict',
    label: 'Sub-district',
    fieldType: 'select',
    options: options?.subDistrict,
    colWidth: 6,
    valueOnly: true,
    isLoading
  },
  {
    fieldName: 'propertyPhone',
    label: 'Stay Phone No.',
    fieldType: 'phone',
    countryCode: 'ae',
    colWidth: 6
  }
];
