import Select from 'react-select';

const TRIPTYPE_OPTIONS = [
  { label: 'All', value: 'ALL' },
  { label: 'Single', value: 'SINGLE' },
  { label: 'Multi', value: 'MULTI' }
];

interface ITripTypeFilterProps {
  setFilterValue: (val: string) => void;
  selectedValue: string;
}

export default function TripTypeFilter(props: ITripTypeFilterProps) {
  return (
    <div className='d-flex align-items-center'>
      <h5 className='fw-bolder me-3 mb-0'>Trip type: </h5>
      <Select
        name='triptype'
        onChange={val => props?.setFilterValue(val?.value!)}
        value={TRIPTYPE_OPTIONS?.find(obj => obj?.value === props?.selectedValue)}
        options={TRIPTYPE_OPTIONS}
      />
    </div>
  );
}
