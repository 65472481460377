import { getCurrencySymbol } from '../../../../../helper';
import { formatNumberForCurrency } from '../../../../../helper';
import { KTSVG } from '../../../../../_metronic/helpers';

interface IVisaProductCard {
  product: any;
}

const VisaProductCard = (props: IVisaProductCard) => {
  const { product } = props;
  const prodInfo = product?.productInformation;
  const draftPrice = prodInfo
    ? `${getCurrencySymbol(prodInfo?.productAmount?.currency)} ${formatNumberForCurrency(
        prodInfo?.productAmount?.totalAmount
      )}`
    : null;

  return (
    <div className='notice rounded border border-dashed p-6 mb-6 bg-light-secondary border-secondary'>
      <div className='d-flex flex-stack flex-wrap flex-grow-1 pb-4'>
        <div>
          <h4 className='text-gray-800 fw-bolder mb-0'>{prodInfo?.name}</h4>
          <div className='fs-6 text-gray-600 text-capitalize'>
            {prodInfo?.processingType}, {prodInfo?.tripType}
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <div className='fw-bold d-flex flex-column justify-content-center align-items-end'>
            <h4 className='text-gray-800 fw-bolder mb-0'>{draftPrice}</h4>
            <div className='fs-6 text-gray-600'>Per traveller</div>
          </div>
        </div>
      </div>
      <div
        className={`d-flex flex-stack flex-wrap flex-grow-1 ${
          prodInfo?.disclaimer ? 'pb-4' : null
        }`}>
        <>
          {prodInfo?.processingTime ? (
            <div>
              <div className='fw-semibold fs-7 text-gray-600 mb-0'>Processing time</div>
              <div className='fw-bold fs-6 text-gray-800'>{prodInfo?.processingTime}</div>
            </div>
          ) : null}
          {prodInfo?.visaType ? (
            <div>
              <div className='fw-semibold fs-7 text-gray-600 mb-1'>Visa type</div>
              <div className='fw-bold fs-6 text-gray-800'>{prodInfo?.visaType}</div>
            </div>
          ) : null}
          {prodInfo?.stayPeriod ? (
            <div>
              <div className='fw-semibold fs-7 text-gray-600 mb-1'>Stay</div>
              <div className='fw-bold fs-6 text-gray-800'>{prodInfo?.stayPeriod}</div>
            </div>
          ) : null}
          {prodInfo?.validity ? (
            <div>
              <div className='fw-semibold fs-7 text-gray-600 mb-1'>Validity</div>
              <div className='fw-bold fs-6 text-gray-800'>{prodInfo?.validity}</div>
            </div>
          ) : null}
        </>
      </div>
      {prodInfo?.disclaimer && (
        <div className='d-flex flex-stack flex-wrap flex-grow-1'>
          <div>
            <KTSVG
              path='/media/icons/duotune/general/gen045.svg'
              className='svg-icon-3 svg-icon-gray-600 me-1'
            />
            {prodInfo?.disclaimer}
          </div>
        </div>
      )}
    </div>
  );
};

export default VisaProductCard;
