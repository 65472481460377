import { FC } from 'react';
import { KTSVG } from '../../../_metronic/helpers';
import { getFormattedDate } from '../../../helper';
import { OA_DATE_TIME_FORMAT_FE } from '../../../constants';
import { OATypography } from '../../widgets';

interface ISource {
  source: any[];
}

export const Source: FC<ISource> = ({ source }) => {
  return (
    <>
      {source?.map((item: any, idx: number) => (
        <div className={source?.length - 1 === idx ? '' : 'mb-8'} key={idx}>
          <a href={item?.link} target='_blank' rel='noreferrer'>
            <p className='mb-2'>
              <KTSVG path='/media/icons/duotune/arrows/arr036.svg' className='svg-icon-4 me-1' />
              {item?.type}
            </p>
            <OATypography variant='body2' sx={{ color: theme => theme?.palette?.grey?.[400] }}>
              {`This content was last updated on ${getFormattedDate(
                item?.createdAt,
                OA_DATE_TIME_FORMAT_FE
              )}`}
            </OATypography>
          </a>
        </div>
      ))}
    </>
  );
};
