import { useState } from 'react';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useParams } from 'react-router-dom';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { filter, includes } from 'lodash';

import { useOrderContext } from '../../../../context/OrderContext';
import { IOrderType } from '../../../../types';
import OATagsBasedInput from '../../../../../../components/OATagsBasedInput';
import OATextField from '../../../../../../components/fields/OATextField';
import OAButton from '../../../../../../components/widgets/OAButton';

interface ICreateQuoteModalProps {
  toggle: (val?: boolean) => void;
  isOpen: boolean;
  consulateData: string | null;
}

const validate = (values?: any) => {
  const errors: any = {};

  if (!values?.proposerName) errors.proposerName = 'Proposer name is a required field';

  if (!values?.proposerEmail) errors.proposerEmail = 'Proposer email is a required field';

  const regexChecker =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!regexChecker.test(values?.proposerEmail)) errors.proposerEmail = 'Email is not valid';

  if (values?.additionalEmail?.length > 0 && values?.additionalEmail !== '') {
    let emails = values?.additionalEmail?.toString().split(',');
    var valid = true;
    var regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    for (var i = 0; i < emails?.length; i++) {
      if (emails[i] === '' || !regex.test(emails[i].trim())) valid = false;
    }

    emails = emails?.map((email: any) => email.trim());
    let duplicates = filter(emails, (value, index, iteratee) =>
      includes(iteratee, value, parseInt(index) + 1)
    );
    if (!valid)
      errors.additionalEmail = 'Please write emails separated by comma and add a valid email';
    if (duplicates.length > 0) errors.additionalEmail = 'The email list contains non-unique emails';
  }

  return errors;
};

const CreateQuoteModal = (props: ICreateQuoteModalProps) => {
  const params = useParams<{ orderType: IOrderType; id: string }>();

  const { formState, orderData, onShareQuotation } = useOrderContext();

  const [loading, setLoading] = useState<boolean>(false);

  const inits = {
    proposerEmail: formState?.proposerInfo?.email,
    proposerName: formState?.proposerInfo?.name,
    additionalEmail: []
  };

  const handleSubmitQuote = (values: any) => {
    setLoading(true);
    // setProposerInformation(values);

    // let updatedValues = Object.assign(travellerInformation, values, {
    //   save: true
    // });
    // if (!isNull(props?.consulateData)) {
    //   updatedValues = Object.assign(updatedValues, { consulate: props?.consulateData });
    // }

    onShareQuotation(values, params?.orderType as IOrderType, params?.id!, () => {
      setLoading(false);
      props?.toggle();
    });
  };

  return (
    <Modal
      show={props?.isOpen}
      onHide={props?.toggle}
      aria-labelledby='contained-modal-title-vcenter'
      backdrop='static'
      centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Share Quotation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={inits}
          validate={validate}
          onSubmit={handleSubmitQuote}
          enableReinitialize>
          {(formikProps: FormikProps<any>) => {
            const handleInputChange = (_: any, newValue: string[]) => {
              formikProps?.setFieldValue('additionalEmail', newValue);
            };

            return (
              <Container fluid as={Form} className='p-0'>
                <Row className='mb-5'>
                  <Col>
                    <OATextField name='proposerName' label='Proposer name' required />
                  </Col>
                  <Col>
                    <OATextField name='proposerEmail' label='Proposer email' required />
                  </Col>
                </Row>
                <Row className='mb-5'>
                  <label className='form-label'>Share quotation with others</label>
                  <OATagsBasedInput
                    inputTagId='tags-filled'
                    textFieldName='additionalEmail'
                    textFieldPlaceholder='Enter email and press comma (",") to add more'
                    // inputValue={proposerInfo?.additionalEmail}
                    onChange={handleInputChange}
                  />
                  <p className='mt-1 text-muted fs-8'>
                    A copy of the quote will also be sent to these email addresses
                  </p>
                </Row>
                <Row>
                  <div className='d-flex mb-5'>
                    <span className='form-check form-check-custom form-check-solid me-4'>
                      <Field className='form-check-input' type='checkbox' name='downloadFile' />
                      <p className='mb-0 ms-4'>Download a copy</p>
                    </span>
                  </div>
                </Row>
                <Row>
                  <Col className='text-end'>
                    <OAButton
                      label='Cancel'
                      className='btn-light-primary btn-active-light-primary me-4'
                      onClick={() => props?.toggle()}
                    />
                    <OAButton
                      type='submit'
                      label='Share Quote'
                      isLoading={loading}
                      disabled={!formikProps?.isValid || loading}
                    />
                  </Col>
                </Row>
              </Container>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default CreateQuoteModal;
