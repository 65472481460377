import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, Card } from 'react-bootstrap';
import moment, { Moment } from 'moment';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { useDashboardContext } from '../context/DashboardContext';
import {
  OA_DATE_FORMAT_BE,
  OA_DATE_FORMAT_FE,
  OA_SHORT_TIME_FORMAT_FE,
  ORDER_EVENT_TYPE_OPTIONS
} from '../../../constants';
import { getFormattedDate } from '../../../helper';
import UserAvatarsCell from '../../../components/OAColumn/UserAvatarsCell';
import OATable from '../../../components/OATable';
import OAHoverPopover from '../../../components/widgets/OAHoverPopover';
import { KTSVG } from '../../../_metronic/helpers';

const iso3311a2 = require('iso-3166-1-alpha-2');

type ITab = {
  label: string;
  value: 'all' | 'week' | 'today';
};

type ICount = {
  all: number;
  week: number;
  today: number;
};

type DateGroupedArray = {
  [date: string]: Array<any>;
};

const initTabs: Array<ITab> = [
  { label: 'All', value: 'all' },
  { label: 'Week', value: 'week' },
  { label: 'Today', value: 'today' }
];

export default function UpcomingEventsTile() {
  const { fetchUpcomingEvents } = useDashboardContext();

  const currentDate = getFormattedDate(moment(), OA_DATE_FORMAT_BE);

  const [weekDays, setWeekDays] = useState<Array<any>>();
  const [weekIndex, setWeekIndex] = useState<number>(0);
  const [selectedDay, setSelectedDay] = useState<any>();
  const [selectedTab, setSelectedTab] = useState<string>('all');
  const [upcomingEvents, setUpcomingEvents] = useState<{ data: any; isLoading: boolean }>({
    data: [],
    isLoading: false
  });
  const [localData, setLocalData] = useState<DateGroupedArray>();
  const [count, setCount] = useState<ICount>({
    all: 0,
    week: 0,
    today: 0
  });

  useEffect(() => {
    setWeekIndex(0);
  }, [selectedTab]);

  useEffect(() => {
    let weekStart = moment().add(weekIndex, 'weeks').startOf('isoWeek');
    let days = [];
    for (let i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, 'days').format(OA_DATE_FORMAT_BE));
    }
    setWeekDays(days);
    !weekIndex && setSelectedDay(currentDate);
  }, [weekIndex]);
  /**
   * NOTE: fetch data and store in 'localData' state...
   */
  useEffect(() => {
    setUpcomingEvents(prev => ({ ...prev, isLoading: true }));
    if (selectedTab === 'all') {
      fetchUpcomingEvents(null, null, res => {
        setUpcomingEvents({ data: res?.length ? res : [], isLoading: false });
      });
    } else if (selectedTab === 'week') {
      const startDate = weekDays?.[0];
      const endDate = weekDays?.[weekDays?.length - 1];
      fetchUpcomingEvents(startDate, endDate, res => {
        setUpcomingEvents({ data: res?.length ? res : [], isLoading: false });
      });
      weekIndex && setSelectedDay(startDate);
    } else if (selectedTab === 'today') {
      fetchUpcomingEvents(currentDate as any, currentDate as any, res => {
        setUpcomingEvents({ data: res?.length ? res : [], isLoading: false });
      });
    } else {
      setUpcomingEvents(prev => ({ ...prev, isLoading: false }));
    }
  }, [weekDays, selectedTab]);

  const groupedByDate: (arr: Array<any>, key?: string) => DateGroupedArray = (
    arr: Array<any>,
    key?: string
  ) =>
    arr
      ?.map(obj => ({
        ...obj,
        accessor: moment(obj?.scheduledOn?.datetime).format(OA_DATE_FORMAT_FE)
      }))
      ?.reduce((acc, item) => {
        const date = item?.accessor;
        // Check if the optional key parameter is passed, then use it to filter the data
        if (!key) {
          if (!acc[date]) acc[date] = []; // Initialize with an empty array if this date is not already in the accumulation object
          acc[date].push(item); // Push the current item to the array associated with this date
        } else {
          if (key === date) {
            if (!acc[date]) acc[date] = []; // Initialize with an empty array if this date is not already in the accumulation object
            acc[date].push(item); // Push the current item to the array associated with this date
          }
        }
        return acc;
      }, {});

  useEffect(() => {
    if (selectedTab === 'all') {
      const curDate = getFormattedDate(moment(), OA_DATE_FORMAT_FE);
      const draft = groupedByDate(upcomingEvents?.data);

      const curWeekData = upcomingEvents?.data?.filter((obj: any) => {
        const dDate = moment(obj?.scheduledOn?.datetime).format(OA_DATE_FORMAT_BE);
        return weekDays?.includes(dDate);
      });

      setLocalData(draft);
      setCount(prev => ({
        ...prev,
        all: upcomingEvents?.data?.length,
        week: curWeekData?.length,
        today: draft?.[curDate!]?.length
      }));
    } else if (selectedTab === 'week') {
      const selectedDate = getFormattedDate(moment(selectedDay), OA_DATE_FORMAT_FE);
      const draft = groupedByDate(upcomingEvents?.data, selectedDate);

      setLocalData(draft);
      setCount(prev => ({
        ...prev,
        week: upcomingEvents?.data?.length
      }));
    } else {
      const draft = groupedByDate(upcomingEvents?.data);
      setLocalData(draft);
      setCount(prev => ({
        ...prev,
        today: upcomingEvents?.data?.length
      }));
    }
  }, [selectedDay, upcomingEvents, selectedTab]);

  const onClickNext = () => setWeekIndex(weekIndex + 1);

  const onClickPrev = () => setWeekIndex(weekIndex - 1);

  const onSelectDate = (date: Date | Moment) => setSelectedDay(date);

  const handleTabChange = (event: React.SyntheticEvent<Element, Event>, value: any) => {
    setSelectedTab(value);
    setLocalData({});
    setUpcomingEvents(prev => ({ ...prev, data: [] }));
  };

  const columns = useMemo(() => getColumns(), []);

  return (
    <Card className='dashboard-tiles upcoming-events h-100 overflow-hidden'>
      <Card.Header>
        <div className='d-flex justify-content-between align-items-center'>
          <h5 className='m-0'>Events</h5>
        </div>
        <ul
          className='nav nav-tabs nav-line-tabs align-items-end border-transparent'
          role='tablist'>
          {initTabs?.map((obj, idx: number) => (
            <OrderFilterNavItem
              key={idx}
              label={obj?.label}
              value={obj?.value}
              count={count[obj?.value]}
              onClick={handleTabChange}
              selectedTab={selectedTab}
              isLoading={upcomingEvents?.isLoading}
            />
          ))}
        </ul>
      </Card.Header>
      {(() => {
        const startOfLastWeek = moment(moment(currentDate).subtract(7, 'days')).startOf('isoWeek');

        return selectedTab === 'week' ? (
          <div className='upcoming-events__header-body'>
            {isEmpty(localData) ? (
              <div>
                <h5 className='page-heading text-dark pb-3'>
                  {getFormattedDate(moment(selectedDay), OA_DATE_FORMAT_FE)}
                </h5>
              </div>
            ) : (
              Object.keys(localData)?.map(key => {
                return (
                  <div>
                    <h5 className='page-heading text-dark pb-3'>{key}</h5>
                  </div>
                );
              })
            )}
            <div className='upcoming-events__week'>
              <div
                className={classNames('dashboard-tiles__btn--arrow me-3', {
                  disabled: moment(weekDays?.[0]).isSameOrBefore(moment(startOfLastWeek))
                })}
                onClick={onClickPrev}>
                <span>{'<-'}</span>
              </div>
              {weekDays?.map((str: any) => {
                const draftDate = getFormattedDate(str, 'DD');
                const draftDay = getFormattedDate(str, 'ddd');

                const eventCount = upcomingEvents?.data?.filter(
                  (obj: any) =>
                    moment.utc(obj?.scheduledOn?.datetime).format(OA_DATE_FORMAT_BE) === str
                )?.length;

                return (
                  <div
                    key={str}
                    onClick={() => onSelectDate(str)}
                    className={classNames('upcoming-events__week-day', {
                      selected: str === selectedDay,
                      disabled: moment(str).isBefore(moment(startOfLastWeek)),
                      filled: !!eventCount
                    })}>
                    <p className='mb-1 fs-6 fw-bold'>{draftDay}</p>
                    <p className='m-0 fs-7 fw-bold'>{draftDate}</p>
                    {!!eventCount && (
                      <Badge pill bg='danger'>
                        {eventCount ? (eventCount > 99 ? '99+' : eventCount) : 0}
                      </Badge>
                    )}
                  </div>
                );
              })}
              <div className='dashboard-tiles__btn--arrow' onClick={onClickNext}>
                <span>{'->'}</span>
              </div>
            </div>
          </div>
        ) : null;
      })()}
      <Card.Body className='upcoming-events__body pt-0 pb-0'>
        <span className='menu-icon position-absolute top-0 end-0'>
          <KTSVG path='/media/icons/duotune/Move.svg' className='svg-icon-2' />
        </span>
        {isEmpty(localData) ? (
          <OATable
            data={[]}
            columns={columns}
            noHeader
            isLoading={upcomingEvents?.isLoading}
            enableSkeleton={true}
          />
        ) : (
          Object.keys(localData)?.map(key => {
            return (
              <div>
                {selectedTab !== 'week' && (
                  <h5 className='page-heading text-dark fw-bold my-0 pt-4'>{key}</h5>
                )}
                <OATable
                  data={localData[key]}
                  columns={columns}
                  noHeader
                  isLoading={upcomingEvents?.isLoading}
                  enableSkeleton={true}
                />
              </div>
            );
          })
        )}
      </Card.Body>
    </Card>
  );
}

const getColumns = () => [
  {
    accessor: 'eventDetails',
    Cell: ({ ...props }) => {
      const draft = props?.data?.[props?.row?.index];
      const eventScheduledAt = moment
        .utc(draft?.scheduledOn?.datetime)
        .format(OA_SHORT_TIME_FORMAT_FE);
      return <span className='d-block fs-7'>{eventScheduledAt}</span>;
    }
  },
  {
    accessor: 'eventTitle',
    Cell: ({ ...props }) => {
      const draft = props?.data?.[props?.row?.index];
      const eventType = ORDER_EVENT_TYPE_OPTIONS?.find(
        (obj: any) => obj?.value === draft?.orderEventType
      )?.label;
      const eventTitle = `${eventType}${draft?.title ? ` - ${draft?.title}` : ''}`;
      return (
        <span className='text-dark fw-bold d-block fs-7' style={{ width: '160px' }}>
          {eventTitle}
        </span>
      );
    }
  },
  {
    accessor: 'travellers',
    Cell: ({ ...props }) => {
      const draft = props?.data?.[props?.row?.index];
      return <UserAvatarsCell members={draft?.members} />;
    }
  },
  {
    accessor: 'trip',
    Cell: ({ ...props }) => {
      const draft = props?.data?.[props?.row?.index];
      const countryCode = draft?.trip?.destination?.[0]?.countryCode;
      return (
        <OAHoverPopover popperText={iso3311a2.getCountry(countryCode)}>
          <img
            style={{ width: '32px', height: '24px' }}
            alt='country-img'
            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`}
          />
        </OAHoverPopover>
      );
    }
  },
  {
    accessor: 'action',
    Cell: ({ ...props }) => {
      const navigate = useNavigate();
      const draft = props?.data?.[props?.row?.index];
      const navigateTo = `/apps/orders/visa/${draft?.orderId}/order-events`;

      return (
        <div className='text-end'>
          <div className='dashboard-tiles__btn--arrow ms-auto' onClick={() => navigate(navigateTo)}>
            <span>{'->'}</span>
          </div>
        </div>
      );
    }
  }
];

interface IOrderFilterNavItem {
  label: string;
  value: string;
  selectedTab: string;
  count: number;
  onClick: (event: React.SyntheticEvent<Element, Event>, value: string) => void;
  isLoading: boolean;
}

const OrderFilterNavItem = (props: IOrderFilterNavItem) => {
  const { label, value, selectedTab, count, onClick } = props;
  const anchorClassNames = classNames('nav-link justify-content-center text-active-gray-800', {
    active: selectedTab === value
  });
  const badgeClassNames = classNames('min-w-35px text-center d-inline fw-normal fs-8', {
    'badge-primary': selectedTab === value,
    'badge-secondary': selectedTab !== value
  });

  return (
    <li className='nav-item cursor-pointer' role='presentation'>
      <a className={anchorClassNames} onClick={event => onClick(event, value)}>
        <span className='me-1 fw-bold fs-7'>{label}</span>
        <Badge
          className={badgeClassNames}
          title={count ? count.toString() : ''}
          pill
          bg={selectedTab === value ? 'primary' : 'secondary'}>
          {count ? (count > 99 ? '99+' : count) : 0}
        </Badge>
      </a>
    </li>
  );
};
