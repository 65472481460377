/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import {
  useNavigate,
  Outlet,
  Route,
  Routes,
  useParams,
  Navigate,
  useSearchParams,
  useLocation
} from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import { Box, IconButton } from '@mui/material';
import isNull from 'lodash/isNull';

import { useOrderContext } from '../context/OrderContext';
import {
  ORDER_DRAFT_STATUS_ARR,
  ORDER_REVIEW_STATUS_ARR,
  ORDER_STATUS,
  ORDER_TYPE
} from '../constants';
import { IOrderType } from '../types';
import OrderBasicDetails from './OrderBasicDetails';
import OrderActions from './OrderActions';
import OrderTabs, {
  // Actions,
  TravelDetails,
  TravellerInfo,
  PaymentSummary,
  VisaStatus,
  VisaSummary,
  InsuranceSummary,
  OrderEvents
} from './OrderTabs';
import { useAuth } from '../../../auth';
import AlertComponent from '../../../components/AlertComponent';
import OAModalComponent from '../../../components/OAModalComponent';
import CancellationReasonForm from './OrderActions/CancellationReasonForm';
import { IMember, IDocument } from '../../../types';
import CreatorTools from './OrderTabs/CreatorTools';
import OrderProgress from './OrderProgress';
import { ReactComponent as ArrowBackIcon } from '../../../assets/icons/arrow_back.svg';
import CommentsAndTasks from './CommentsAndTasks';
import { OATypography } from '../../../components/widgets';
import OrderActivity from './OrderTabs/ActivityLog';
import { useAppContext } from '../../../context/AppContext';

export default function OrderView() {
  const params = useParams<{ orderType: IOrderType; id?: string }>();
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const { currentUser } = useAuth();
  const { dataExchange } = useAppContext();
  const isModeTemp = searchParams.get('mode');
  const isModeLocal = localStorage.getItem('oaMode');

  const { orderData, orderActions, getOrderById, resetOrderData, setOrderActivityLogs } =
    useOrderContext();

  const [isRejectCancellationModalOpen, setIsRejectCancellationModalOpen] = useState(false);
  const [groupId, setGroupId] = useState(null);

  const hasSupplierViewAccess = currentUser?.data?.organization?.type === 'SUPPLIER';

  const isConsumer =
    orderData?.data?.createdByUser?.organizationId === currentUser?.data?.organization?.id;

  const isVisa = params.orderType === ORDER_TYPE.VISA;

  useEffect(() => {
    const handleBackButton = () => {
      if (
        window.location.href.includes('actions') ||
        window.location.href.includes('activity-log')
      ) {
        const localStorageIsGroupTab = localStorage.getItem('isGroupTab');
        if (localStorageIsGroupTab === 'true') {
          navigate(
            `/apps/orders/${params?.orderType}/group/${orderData?.data?.groupOrder?.id}${location?.search}`
          );
        } else {
          navigate(`/apps/orders/${params?.orderType}${location?.search}`); // Replace with the URL of the page you want to redirect to
        }
      }
    };
    window.addEventListener('popstate', handleBackButton); //add the button to detect the backbuttonClick
    return () => {
      window.removeEventListener('popstate', handleBackButton); //remove the eventListner on unmount
    };
  }, []);

  useEffect(() => {
    if (orderData?.data?.groupOrder?.id || orderData?.data?.groupId) {
      setGroupId(
        orderData?.data?.groupOrder ? orderData?.data?.groupOrder?.id : orderData?.data?.groupId
      );
    }
    if (orderData?.data?.id && !orderData?.data?.groupOrder?.id && !orderData?.data?.groupId) {
      setGroupId(null);
    }
  }, [orderData?.data]);
  /**
   * Update deep linking functionality to redirect user to the relevant mode (e.g., live, test)
   */
  useEffect(() => {
    if (isModeTemp && isModeTemp !== isModeLocal) {
      localStorage.setItem('oaMode', isModeTemp);
      window.location.reload();
    }
  });

  useEffect(() => {
    if (params?.orderType && params?.id) {
      getOrderById(params?.orderType as IOrderType, params?.id);
    }
    /**
     * NOTE: on unmount of OrderView, always reset order data from the context state...
     */
    return () => {
      resetOrderData();
    };
  }, [params?.id]);

  const isReviewrequired = params?.orderType === ORDER_TYPE.VISA && orderData?.data?.reviewRequired;
  let isDraftOrder: boolean = ORDER_DRAFT_STATUS_ARR.includes(orderData?.data?.bookingStatus);
  const isOrderInReview = ORDER_REVIEW_STATUS_ARR.includes(orderData?.data?.bookingStatus);
  const ispendingTask = orderActions?.data?.filter((act: any) => act?.status !== 'COMPLETED');

  const fulfilCheckForVisa =
    isVisa && hasSupplierViewAccess && isOrderInReview && ispendingTask?.length !== 0;
  /**
   * NOTE: for insurance, if booking status is 'ISSUANCE_FAILED' then set 'isDraftOrder' to true...
   */
  if (params?.orderType === ORDER_TYPE.INSURANCE && hasSupplierViewAccess) {
    isDraftOrder = isDraftOrder || orderData?.data?.bookingStatus === 'ISSUANCE_FAILED';
  }
  /**
   * NOTE: if orderType is 'insurance' then shouldSubmit is set to true.
   * if orderType is 'visa' then shouldSubmit is set to true by checking if every members
   * documents has been uploaded.
   */
  const shouldSubmit: boolean =
    params?.orderType === ORDER_TYPE.VISA
      ? orderData?.data?.members
          ?.reduce((acc: any, cur: any) => {
            const draft = cur?.documents
              ?.filter((obj: any) => obj?.fieldType === 'upload')
              ?.every((doc: any) => doc?.uploaded === true);

            return [...acc, draft];
          }, [])
          ?.every((val: any) => val === true)
      : true;

  const pendingActionCount = orderActions?.data?.filter(
    (obj: any) => obj?.status !== 'APPROVED'
  )?.length;
  let isOfflineOrder = orderData?.data?.product?.submissionType === 'OFFLINE';

  /**
   * NOTE: check if order has been submitted to embassy or not. if yes, then set
   * 'isSubmittedToEmbassy' to true to show 'visa-status' tab in order view page...
   */
  let isSubmittedToEmbassy = isOfflineOrder && !!orderData?.data?.applicationId;
  if (params?.orderType === ORDER_TYPE.INSURANCE) {
    isOfflineOrder = true;
    isSubmittedToEmbassy = true;
  }
  /**
   * NOTE: every child route component should get exactly same props. 'elementProps'
   * contains all props that need to pass to child route component ...
   */
  const elementProps = {
    isEditable: isDraftOrder,
    isReviewrequired
  };

  const isCancellationRequested = orderData?.data?.bookingStatus === 'ORDER_CANCELLATION_REQUESTED';
  const orderSupplierOrgId = orderData?.data?.supplierOrganization?.id;
  const hasRejectCancellationRequestedAccess =
    isCancellationRequested && currentUser?.data?.organization?.id === orderSupplierOrgId;
  const cancellationStatusReason = orderData?.data?.logs
    ?.filter((log: any) => log?.status === 'ORDER_CANCELLATION_REQUESTED')
    ?.reverse()?.[0]?.message;

  const toggleRejectCancellationModal = () => setIsRejectCancellationModalOpen(prev => !prev);

  const onclickedUploadDosumentBtn = (btnType: string) => {
    navigate(btnType === 'upload' ? 'traveller-info' : 'actions', {
      state: { isUploadDocBtnClicked: true }
    });
  };

  useEffect(() => {
    if (location.state?.isUploadDocBtnClicked) {
      setTimeout(() => {
        let element = document.getElementById('orderTabForScroll');
        if (element) element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 200);
    }
  }, [location]);

  const areAllDocumentsUploaded = orderData?.data?.members?.every((member: IMember) =>
    member?.documents?.every((document: IDocument) => document.uploaded)
  );

  const preSubmitDocs = isDraftOrder && !isOrderInReview && !shouldSubmit;

  const shouldGoBackToGroup = searchParams.get('group') === 'true';

  const handleBack = () => {
    if (shouldGoBackToGroup) {
      navigate(`/apps/orders/${params?.orderType}/group/${groupId}${location?.search}`);
    } else {
      navigate(`/apps/orders/${params?.orderType}`);
    }
  };

  const backBavigationText = shouldGoBackToGroup
    ? 'Back to group order'
    : `Back to ${params?.orderType} orders`;
  /**
   * NOTE: if bookingStatus is "ORDER_QUOTE" then make isQuotation to true...
   */
  const isQuotation = orderData?.data?.bookingStatus === ORDER_STATUS.ORDER_QUOTE;
  // to clear previous state of activity logs when we open a new order page
  useEffect(() => {
    return () => {
      setOrderActivityLogs([]);
    };
  }, []);

  return (
    <Routes>
      <Route
        element={
          <React.Fragment>
            <Row className='order-view'>
              <Col md='12' className='mb-4'>
                <Card>
                  <Card.Header className='border-0 align-items-center'>
                    <Box
                      display='flex'
                      gap='4px'
                      alignItems='center'
                      sx={{ cursor: 'pointer' }}
                      onClick={handleBack}>
                      <IconButton aria-label='go back' size='medium'>
                        <ArrowBackIcon />
                      </IconButton>
                      <OATypography variant='subtitle1'>{backBavigationText}</OATypography>
                    </Box>
                    {!!orderData?.data &&
                    params?.orderType === ORDER_TYPE.VISA &&
                    orderData?.data?.bookingStatus !== ORDER_STATUS.ORDER_QUOTE ? (
                      <OrderProgress
                        bookingStatus={orderData?.data?.bookingStatus}
                        isPendingTask={false}
                      />
                    ) : null}
                    {!!orderData?.data ? (
                      <Box display='flex' gap='4rem' alignItems='center'>
                        <OrderActions
                          isEditable={isDraftOrder}
                          shouldSubmit={!!shouldSubmit}
                          isSubmittedToEmbassy={isSubmittedToEmbassy}
                        />
                      </Box>
                    ) : null}
                  </Card.Header>
                  <Card.Body className='py-0'>
                    <OrderBasicDetails />
                    {hasRejectCancellationRequestedAccess ? (
                      <AlertComponent
                        title='Request for cancellation'
                        subtitle={cancellationStatusReason ?? ''}
                        type='danger'
                        border='dashed'
                        actionProps={{
                          onClick: () => toggleRejectCancellationModal(),
                          buttonText: 'Reject Request'
                        }}
                      />
                    ) : null}
                    {isVisa &&
                      !areAllDocumentsUploaded &&
                      !orderData?.isLoading &&
                      isDraftOrder && (
                        <AlertComponent
                          title='Prepare documents for submission'
                          subtitle={
                            preSubmitDocs
                              ? 'Upload all the requested documents before submitting the application'
                              : 'Upload all the requested documents for submission of the visa application'
                          }
                          type={preSubmitDocs ? 'danger' : 'warning'}
                          border='basic'
                          isPulse={preSubmitDocs ? true : false}
                          actionProps={{
                            onClick: () => onclickedUploadDosumentBtn('upload'),
                            buttonText: 'Upload document'
                          }}
                        />
                      )}
                    {fulfilCheckForVisa && (
                      <AlertComponent
                        title='Review pending tasks'
                        subtitle='Take a look at your pending tasks once, before submitting'
                        type='warning'
                        border='basic'
                      />
                    )}
                    {!isNull(orderData?.data) && !isQuotation ? (
                      <OrderTabs
                        isOrderdataFetched={!!orderData?.data}
                        shouldSubmit={shouldSubmit}
                        pendingActionCount={pendingActionCount}
                        className='order-view--tabs'
                        isReviewrequired={isReviewrequired}
                        isOfflineOrder={isOfflineOrder}
                        isConsumer={isConsumer}
                      />
                    ) : null}
                  </Card.Body>
                </Card>
              </Col>
              <Col md='8'>{!isNull(orderData?.data) && <Outlet />}</Col>
              <Col md='4'>
                {/* <OrderActivity orderData={orderData} /> */}
                <CommentsAndTasks orderData={orderData?.data} currentUser={currentUser?.data} />
              </Col>
            </Row>
            {/**
             * DEVNOTE: keep all modal component at end for better readability...
             */}
            <OAModalComponent
              className='order-status-update__modal'
              show={isRejectCancellationModalOpen}
              onHide={toggleRejectCancellationModal}
              header={{ title: `Reject order cancellation`, closeButton: true }}
              body={{
                content: (
                  <CancellationReasonForm type='reject' onHide={toggleRejectCancellationModal} />
                ),
                className: 'order-status-update__modal-body'
              }}
            />
          </React.Fragment>
        }>
        {!isQuotation ? (
          <Fragment>
            <Route path='travel-details' element={<TravelDetails {...elementProps} />} />
            <Route path='traveller-info' element={<TravellerInfo {...elementProps} />} />
            <Route
              path='activity-log'
              element={
                <OrderActivity
                  orderData={orderData}
                  currentUser={currentUser}
                  dataExchange={dataExchange}
                  isGroup={false}
                />
              }
            />
            {params?.orderType === ORDER_TYPE.VISA ? (
              <Route path='visa-summary' element={<VisaSummary />} />
            ) : null}
            {params?.orderType === ORDER_TYPE.INSURANCE ? (
              <Route path='insurance-summary' element={<InsuranceSummary />} />
            ) : null}
            <Route
              path='visa-status'
              element={
                <VisaStatus
                  isOfflineOrder={isOfflineOrder}
                  isSubmittedToEmbassy={isSubmittedToEmbassy}
                  {...elementProps}
                />
              }
            />
            {params?.orderType === ORDER_TYPE.VISA && (
              <Route path='order-events' element={<OrderEvents />} />
            )}
            <Route path='creator-tools' element={<CreatorTools />} />
          </Fragment>
        ) : null}
        <Route path='payment-summary' element={<PaymentSummary {...elementProps} />} />
        <Route
          index
          element={
            <Navigate
              to={`${isQuotation ? 'payment-summary' : 'activity-log'}${location?.search}`}
            />
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  );
}
