import React from 'react';
import { IconType } from '.';

export default function StarSVG(props: IconType) {
  const {
    color = '#B5B5C2',
    height = '15',
    width = '15',
    isActive = false,
    className = ''
  } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 16 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z'
        fill={isActive ? '#F6C944' : color}
      />
    </svg>
  );
}
