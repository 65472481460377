import * as Yup from 'yup';

export const bulkUploadValidation = Yup.object({
  user: Yup.string().label('User').required(),
  order: Yup.string().label('Order type').required()
});

export const genericValidation = Yup.object({
  order: Yup.string().label('Order type').required()
});
