import { toast } from 'react-toastify';

interface ErrorData {
  httpStatus: number;
  errorMessage: string;
  errors?: any; // Make errors optional
  success: boolean;
  timeStamp: any;
  // add any other properties as needed
}

type ErrorType = 'success' | 'warning' | 'error' | 'default';

interface ErrorStatusMap {
  [key: number]: string;
}

const errorStatusMap: ErrorStatusMap = {
  400: 'Bad Request error',
  401: 'Unauthorized error',
  403: 'Forbidden error',
  404: 'Not Found error',
  409: 'Conflict error',
  422: 'Unprocessable Entity error',
  500: 'Internal Server Error'
};

const showToast = (error: string | ErrorData, type: ErrorType = 'error') => {
  let errorMessage = '';
  if (typeof error === 'string') {
    errorMessage = error;
  } else {
    const errorStatus = error?.httpStatus;
    const hasErrors = error?.errors;

    if (errorStatus && hasErrors) {
      if (typeof hasErrors === 'string') {
        errorMessage = hasErrors;
      } else if (Array.isArray(hasErrors)) {
        errorMessage = hasErrors
          .map((obj: any) =>
            obj?.message.includes(obj?.field) ? `${obj?.message}` : `${obj?.field} ${obj?.message}`
          )
          .join(', ');
      } else {
        errorMessage = errorStatusMap[errorStatus];
      }
    } else {
      errorMessage = errorStatusMap[errorStatus];
    }

    if (errorStatus === 404) {
      window.location.href = '/error/404';
    }
  }
  displayToastByType(errorMessage, type);
};

const displayToastByType = (message: string, type: ErrorType) => {
  switch (type) {
    case 'success':
      toast.success(message);
      break;
    case 'warning':
      toast.warn(message);
      break;
    case 'error':
      toast.error(message);
      break;
    default:
      toast(message);
      break;
  }
};

export default showToast;
