import { useParams } from 'react-router-dom';
import { Form, Formik, FormikProps } from 'formik';
import { Button, Col, Container, Row } from 'react-bootstrap';

import { IOrderType } from '../../types';
import { useOrderContext } from '../../context/OrderContext';
import OATextAreaField from '../../../../components/fields/OATextAreaField';

interface ICancellationReasonFormProps {
  onHide: () => void;
  type: 'request' | 'reject';
}

const validate = (values: { reason: string }) => {
  const errors: any = {};

  if (!values.reason) {
    errors.reason = 'Remarks/reasons required';
  }

  return errors;
};

export default function CancellationReasonForm(props: ICancellationReasonFormProps) {
  const params = useParams<{ orderType: IOrderType; id?: string }>();

  const { onRequestInsuranceCancellation, onCancelOrder } = useOrderContext();

  const handleSubmit = (values: { reason: string }) => {
    if (props?.type === 'request') {
      onRequestInsuranceCancellation(params?.id!, values, _res => {
        props?.onHide();
      });
    } else if (props?.type === 'reject') {
      onCancelOrder(params?.orderType as IOrderType, params?.id!, false, values, _res => {
        props?.onHide();
      });
    }
  };

  return (
    <Formik initialValues={{ reason: '' }} onSubmit={handleSubmit} validate={validate}>
      {(formikProps: FormikProps<{ reason: string }>) => {
        return (
          <Form className='w-100'>
            <Container fluid className='p-0'>
              <Row>
                <Col lg={12} className='mb-6'>
                  <OATextAreaField
                    name='reason'
                    label='Remarks'
                    placeholder='Mention any remarks/reasons here'
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className='d-flex justify-content-end'>
                    <Button
                      type='button'
                      size='sm'
                      onClick={props?.onHide}
                      disabled={formikProps?.isSubmitting}
                      className='btn-light-primary btn-active-light-primary me-4'>
                      Cancel
                    </Button>
                    <Button
                      type='submit'
                      size='sm'
                      disabled={
                        (!formikProps?.isValid && formikProps?.dirty) || formikProps?.isSubmitting
                      }
                      className='btn-primary btn-active-primary'>
                      {formikProps?.isSubmitting ? 'Submitting...' : 'Submit'}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
}
