import { ITravellerFormValues } from '../../../types';
import TravellerCard from './TravellerCard';

interface ITravellersList {
  travellers: Array<ITravellerFormValues>;
  trip: any;
  toggleModal: (val?: boolean) => void;
}

const TravellersList = (props: ITravellersList) => {
  return (
    <div className='row g-5 g-xl-8'>
      {props?.travellers?.map((traveller: ITravellerFormValues, index: number) => (
        <div key={index} className='col-xl-6'>
          <TravellerCard
            traveller={traveller}
            trip={props?.trip}
            toggleModal={props?.toggleModal}
          />
        </div>
      ))}
    </div>
  );
};

export default TravellersList;
