import { removeEmpty } from '../../../helper';
import { ITraveller } from '../order-create/components/AddTravellers/types';

export const getBookingInfo = (values: ITraveller) => {
  const { propertyName, propertyType, propertyAddress, propertyPhone } = values;

  const draft = removeEmpty({ propertyName, propertyType, propertyAddress, propertyPhone });

  return [draft];
};
