import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import OaSpinner from '../components/OaSpinner';
import { toAbsoluteUrl } from '../helper';

const DOCUMENT_URLS: any = {
  '/terms-and-conditions':
    'https://app.termly.io/document/terms-of-service/431f7904-aac2-4068-baff-d2a963f3dd35',
  '/privacy-policy':
    'https://app.termly.io/document/privacy-policy/8df39558-bc72-40c8-9746-bebdaef81bfa'
};

const StaticPage = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState('');

  useEffect(() => {
    setUrl(DOCUMENT_URLS[location.pathname] || ''); // Fall back to an empty string if the path is not recognized
  }, [location]);

  const handleIframeLoad = () => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  return (
    <>
      <div className='header container-fluid justify-content-center'>
        <img
          alt='Logo'
          src={toAbsoluteUrl(
            `/media/logos/${
              window.location.host === 'world.evisas.io' ? 'logo-alsaffron.svg' : 'logo-1.svg'
            }`
          )}
          className='h-25px d-inline'
        />
      </div>
      {loading && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}>
          <OaSpinner />
        </div>
      )}
      <iframe
        src={url}
        style={{
          width: '100%',
          border: 'none',
          height: '100vh',
          display: loading ? 'none' : 'block'
        }}
        onLoad={handleIframeLoad}
        title='Terms And Privacy'
      />
    </>
  );
};

export default StaticPage;
