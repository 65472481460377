import React, { Dispatch, useContext } from 'react';
import { IDashboardFilterQueryParams } from '../type';

export interface IDashboardContext {
  order: {
    summaryData: any | null;
    orderGraphData: any | null;
    revenueGraphData: any | null;
    isLoading: boolean;
  };
  topSuppliers: { data: any | null; isLoading: boolean };
  topProducts: { data: any | null; isLoading: boolean };
  report: { isLoading: boolean };
  topCountries: { data: any | null; isLoading: boolean };
  visaAtRisk: { data: any; isLoading: boolean };
  getTopSuppliers: () => void;
  getTopSellingProducts: () => void;
  getOrderData: () => void;
  downloadReport: () => void;
  getTopCountries: () => void;
  fetchVisaAtRisk: () => void;
  fetchUpcomingEvents: (start: Date | null, end: Date | null, cb?: (res: any) => void) => void;
}

const DashboardContext = React.createContext<IDashboardContext>({} as IDashboardContext);

export default DashboardContext;

export const useDashboardContext = () => useContext(DashboardContext);
