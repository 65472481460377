import { FC } from 'react';
import { Box, Card } from '@mui/material';
import { ITask } from '../../types';
import { OATypography } from '../widgets';
import { getFormattedDate, removeChar } from '../../helper';
import ActionBtn from './ActionBtn';
import TagsDisplay from './TagsDisplay';
import AvatarDisplay from './AvatarDisplay';
import { OA_DATE_TIME_FORMAT_FE } from '../../constants';
import { REASON_OPTIONS } from './TaskCreateUpdate/TaskForm';
import { useNavigate, useParams } from 'react-router-dom';
import { IOrderType } from '../../pages/order-management/types';

const cardStyles = {
  'mb': '8px',
  'p': '12px',
  'borderRadius': '6px',
  'backgroundColor': '#F6F8FA',
  'border': '1px solid #F6F8FA',
  'boxShadow': 'none',
  'cursor': 'pointer',
  '&:hover': {
    borderColor: '#EFF2F5',
    background: '#FFFFFF',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10)'
  }
};

interface ITaskCard {
  index: number;
  task: ITask;
  editTaskHandler: (e: React.MouseEvent, task: ITask) => void;
}

const TaskCard: FC<ITaskCard> = ({ index, task, editTaskHandler }) => {
  const params = useParams<{ orderType: IOrderType; id?: string }>();
  const navigate = useNavigate();
  const base_path = `/apps/orders/${params?.orderType}/${params?.id}`;
  let reason = REASON_OPTIONS.find((obj: any) => obj?.value === task?.reason)?.label;

  const handleNavigate = (rowData: ITask) => {
    switch (rowData?.type) {
      case 'TRIP_INFO':
        navigate(`${base_path}/travel-details#travel_info`);
        break;
      case 'MEMBERS':
        navigate(`${base_path}/traveller-info#traveller_${removeChar(rowData?.memberId!)}_info`);
        break;
      case 'MEMBER_INFO':
        navigate(`${base_path}/traveller-info#traveller_${removeChar(rowData?.memberId!)}_info`);
        break;
      case 'MEMBER_DOCUMENTS':
        navigate(
          `${base_path}/traveller-info#traveller_${removeChar(rowData?.memberId!)}_documents`
        );
        break;
      default:
        break;
    }
  };

  return (
    <Card key={index} sx={cardStyles} onClick={() => handleNavigate(task)}>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          <OATypography
            variant='caption'
            sx={{ color: theme => theme.palette.grey[400], mr: '10px' }}>
            TAS - 00{index + 1}
          </OATypography>
          <OATypography variant='body1'>{reason ? reason : task?.reason}</OATypography>
        </Box>
        <ActionBtn task={task} editTaskHandler={editTaskHandler} />
      </Box>
      <OATypography variant='body2' sx={{ mt: '8px', mb: '8px' }}>
        {task?.comment}
      </OATypography>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <TagsDisplay task={task} />
        <AvatarDisplay task={task} />
      </Box>
      <Box display='flex' alignItems='center' justifyContent='space-between' sx={{ mt: '8px' }}>
        <OATypography variant='tooltip' sx={{ color: theme => theme.palette.grey[400] }}>
          {task?.createdByUser?.firstName} created this task
        </OATypography>
        <OATypography variant='tooltip' sx={{ color: theme => theme.palette.grey[400] }}>
          {getFormattedDate(task?.updatedAt, OA_DATE_TIME_FORMAT_FE)}
        </OATypography>
      </Box>
    </Card>
  );
};

export default TaskCard;
