import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationCenter, IMessage, useNotifications } from '@novu/notification-center';

import { useAuth } from '../../../../auth';
import API from '../../../../helper/API';
import { HeaderUserMenu } from '../../../partials';
import { KTSVG } from '../../../helpers';
import ModeSwitch from '../../../../components/ModeSwitch';

import BellSVG from '../../../../components/Icons/BellSVG';
import OrganizationFilter from '../../../../components/OAFilter/OrganizationFilter';

const TopbarRightMenu = (props: { mode: string }) => {
  const navigate = useNavigate();

  const { currentUser } = useAuth();
  const { unseenCount } = useNotifications();

  const [isOpened, setIsOpened] = useState<boolean>(false);

  function onNotificationClick(notification: IMessage) {
    setIsOpened(true);
    if (notification?.cta?.data?.url) {
      navigate(notification?.cta?.data?.url);
    }
  }

  async function handlerOnActionClick(_templateIdentifier: string, type: any, message: IMessage) {
    setIsOpened(true);
    try {
      API.delete(`/notification/novu/${message._id}`)
        .then(() => {})
        .catch(err => {
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  }

  const modalRef = useRef<any>();

  useEffect(() => {
    if (isOpened === true) {
      const handleClickOutside = (event: any) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          setIsOpened(prev => !prev);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [isOpened]);

  return (
    <div className='topbar__right-menu d-flex'>
      {!['GUEST_USER'].includes(currentUser?.data?.role) && (
        <div className='d-flex align-items-center ms-5'>
          <OrganizationFilter />
        </div>
      )}
      {!['ROLE_OPERATION', 'ROLE_OPERATION_EXTERNAL', 'GUEST_USER'].includes(
        currentUser?.data?.role
      ) ? (
        <div className='d-flex align-items-center ms-5'>
          <ModeSwitch mode={props?.mode} />
        </div>
      ) : null}
      {!['GUEST_USER'].includes(currentUser?.data?.role) && (
        <div
          ref={modalRef}
          className='d-flex align-items-center ms-5 position-relative'
          onClick={() => setIsOpened(prev => !prev)}>
          <BellSVG unseenCount={unseenCount} />
          {isOpened && (
            <div className='novu-popover'>
              <NotificationCenter
                onNotificationClick={onNotificationClick}
                onActionClick={handlerOnActionClick}
                colorScheme='light'
              />
            </div>
          )}
        </div>
      )}

      <div
        className='d-flex align-items-center cursor-pointer symbol ms-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-attach='parent'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='bottom'>
        <div className='user-icon__avatar cursor-pointer symbol symbol-30px symbol-md-30px'>
          <span className='text-gray-600 fw-bolder'>
            {currentUser?.data?.firstName?.charAt(0).toUpperCase()}
            {currentUser?.data?.lastName?.charAt(0).toUpperCase()}
          </span>
          {/* <span className='text-gray-600 fw-bold me-3 text-truncate mw-60px text-capitalize'>
            Hi, {currentUser?.data?.firstName} {currentUser?.data?.lastName}
          </span> */}
          {/* <div className='cursor-pointer symbol symbol-30px symbol-md-30px'></div> */}
          {/* <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='metronic' /> */}
        </div>
        <HeaderUserMenu />
      </div>
      <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
        <div
          id='kt_header_menu_mobile_toggle'
          className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'>
          <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
        </div>
      </div>
    </div>
  );
};

export { TopbarRightMenu };
