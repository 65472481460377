/* eslint-disable no-unused-vars */
import {
  DataGridPro,
  GridColDef,
  GridPaginationModel,
  GridColumnHeaderParams,
  GridEventListener
} from '@mui/x-data-grid-pro';
import classNames from 'classnames';

import { KTSVG } from '../../_metronic/helpers';

export interface OATableComponentProps {
  columns: Array<GridColDef>;
  data: Array<any>;
  noDataHeight?: number;
  pagination?: OATablePaginationProps;
  loading?: boolean;
  disableColumnMenu?: boolean;
  disableSelectionOnClick?: boolean;
  disableRowSelectionOnClick?: boolean;
  height?: number | string;
  onCellClick?: GridEventListener<'cellClick'>;
  hideFooter?: boolean;
  getRowId?: (row: any) => string;
}

export interface OATablePaginationProps {
  pageIndex: number;
  total: number;
  onPageChange: (value: any) => void;
  pageSize?: number;
  pageCount?: number;
}

export interface IPageInfo {
  totalCount: number;
  totalPages: number;
  count: number;
  size: number;
  index: number;
}

export default function OATableComponent(props: OATableComponentProps) {
  const {
    pagination,
    loading = false,
    disableColumnMenu = true,
    disableSelectionOnClick = true,
    disableRowSelectionOnClick = true,
    height = 'auto',
    noDataHeight = 400,
    hideFooter = false,
    getRowId
  } = props;

  const dataGridProColumns = getDataGridProColumns(props?.columns);

  const handlePageChange = (model: GridPaginationModel) => {
    /**
     * NOTE: DataGridPro takes first page index as 0 but here, for better
     * understanding OATableComponent is getting first page index as 1. Because
     * of that on change pagination also we need to accomodate that way so,
     * adding 1 with page number returned by DataGridPro...
     */
    pagination?.onPageChange(model?.page + 1);
  };

  let draftPagination = {};
  if (!!pagination) {
    draftPagination = {
      pagination: true,
      rowCount: pagination?.total,
      pageSizeOptions: [pagination?.pageSize ?? 10],
      paginationModel: {
        page: pagination?.pageIndex - 1,
        pageSize: pagination?.pageSize ?? 10
      },
      paginationMode: 'server',
      onPaginationModelChange: handlePageChange
    };
  }

  const draftTableHeight = props?.data?.length ? height : noDataHeight;

  return (
    <div style={{ height: draftTableHeight, width: '100%' }}>
      <DataGridPro
        rows={props?.data}
        columns={dataGridProColumns}
        disableColumnMenu={disableColumnMenu}
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        loading={loading}
        onCellClick={props?.onCellClick}
        className={classNames('oa-table-component border-0', {
          pointer: !!props?.onCellClick
        })}
        slots={{
          noRowsOverlay: NoRecords
        }}
        slotProps={{
          footer: {
            style: {
              height: 56
            }
          }
        }}
        hideFooter={hideFooter}
        getRowId={getRowId}
        // filterModel={{
        //   items: [{ field: 'userName', operator: '', value: '' }]
        // }}
        {...draftPagination}
      />
    </div>
  );
}

export const GridColumnHeader = (props: {
  title: string | JSX.Element;
  params: GridColumnHeaderParams;
}) => {
  const { title, params } = props;

  return (
    <span aria-label={params?.field} className='text-muted fw-bolder fs-8 text-uppercase'>
      {title}
    </span>
  );
};

const getDataGridProColumns: (columns: Array<GridColDef>) => Array<GridColDef> = (
  columns: Array<GridColDef>
) => {
  const draftDefault = {
    sortable: false,
    resizable: false,
    pinnable: false,
    disableColumnMenu: true,
    filterable: false
  };

  return columns?.map(obj => Object.assign({}, draftDefault, obj));
};

const NoRecords = () => {
  return (
    <div className='d-flex flex-column align-items-center justify-content-center h-100'>
      <KTSVG className='svg-icon-3x' path='/media/icons/duotune/general/gen021.svg' />
      <p className='mt-1 mb-0'>No matching records found</p>
    </div>
  );
};
