import { OATypography, OAStatusChip } from '../../../components/widgets';
import { FLIGHT_STATUS, OA_SHORT_TIME_FORMAT_FE } from '../../../constants';
import { getFormattedDate } from '../../../helper';
import { ReactComponent as FlagIcon } from '../../../assets/icons/flagIcon.svg';

interface IActivityLogItem {
  item: any;
  dataExchange?: any;
}

const ActivityLogItem: React.FC<IActivityLogItem> = ({ item }) => {
  function createStatusObj(statusArray: any, item: any) {
    const foundStatus: any = statusArray?.find((status: any) => status?.value === item?.status);
    if (foundStatus) {
      return {
        status: item?.status,
        variant: foundStatus?.type ?? 'warning',
        label: foundStatus?.label
      };
    } else {
      return null;
    }
  }
  const statusObj = createStatusObj(FLIGHT_STATUS, item);
  return (
    <div className='d-flex w-100'>
      <div className='timeline-line w-30px'></div>
      <div className='timeline-icon ms-1'>
        <FlagIcon />
      </div>
      <div className='w-100 mb-8'>
        <div className='d-flex justify-content-between flex-column w-100'>
          <div className='d-flex justify-content-between'>
            <div className='d-flex align-items-center'>
              <OATypography sx={{ color: theme => theme.palette.grey[400] }}>
                Flight order status changed to
              </OATypography>
              <div className='ms-3'>
                <OAStatusChip
                  status={statusObj?.status}
                  label={statusObj?.label ?? item?.status}
                  variant={statusObj?.variant}
                />
              </div>
            </div>
            <div>
              <OATypography
                variant='body2'
                sx={{
                  color: theme => theme?.palette?.grey?.[600]
                }}>
                {getFormattedDate(item?.createdAt, OA_SHORT_TIME_FORMAT_FE)}
              </OATypography>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default ActivityLogItem;
