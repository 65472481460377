import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { parseISO, format } from 'date-fns';

import { useAuth } from '../../../../auth';
import { useOrderContext } from '../../../order-management/context/OrderContext';

const ElaborationHeader = () => {
  const { currentUser } = useAuth();

  const {
    orderData: { data }
  } = useOrderContext();

  const org: any = currentUser?.data?.organization;
  const orgAddress: any = org?.address;
  const formattedCreatedOn = data?.createdAt && format(parseISO(data?.createdAt), 'dd LLL, yyyy');

  return (
    <React.Fragment>
      <Row className='dev--quotation__elaboration-header mb-8'>
        <Col className='fw-bolder fs-6 text-dark'>
          {org?.logo && <img alt='Logo' src={org.logo} className='h-25px' />}
        </Col>
        <Col className='fs-6 text-gray-400'>
          <p className='mb-0 text-end'>{org?.name}</p>
          <p className='mb-0 text-end fs-7'>{`${orgAddress?.line1} ${orgAddress?.line2}`}</p>
          <p className='mb-0 text-end fs-7'>
            {orgAddress?.city}, {orgAddress?.state}, {orgAddress?.postalCode}
          </p>
          <p className='mb-0 text-end fs-7'>{org?.email}</p>
        </Col>
      </Row>
      <Row className='mb-8'>
        <Col>
          <p className='fs-6 text-dark mb-0'>{data?.proposerInfo?.name}</p>
          <p className='fs-7 text-muted mb-0'>{data?.proposerInfo?.email}</p>
          <p className='fs-7 text-muted mb-0'>{data?.proposerInfo?.companyName}</p>
        </Col>
        <Col className='text-end'>
          <p className='fs-6 text-dark mb-0'>
            {data?.product?.name}{' '}
            {data?.product?.productInformation?.processingType &&
              `(${data?.product?.productInformation?.processingType})`}
          </p>
          <p className='fs-7 text-muted mb-0'>Quotation #{data?.id}</p>
          <p className='fs-7 text-muted mb-0'>Issued on {formattedCreatedOn}</p>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ElaborationHeader;
