import { useContext, useState } from 'react';
import OAModalComponent from '../../../components/OAModalComponent';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import { OATypography } from '../../../components/widgets';
import OAButton from '../../../components/widgets/OAButton';
import { getFormattedDate } from '../../../helper';
import { getTimeDifference } from '../../../helper/timeDifference';
import lineFlightImg from '../../../assets/icons/2dotsLine.svg';
import ClaimContext from '../context/ClaimContext';
import { ReactComponent as FlightCancellationIcon } from '../../../assets/icons/flight_cancel.svg';
import { FLIGHT_NAMES } from '../../order-management/constants';

interface IClaimsDetails {
  isOpen: boolean;
  onHide: () => void;
  data: any;
}

export const ClaimDetails = (props: IClaimsDetails) => {
  const { settleClaim, shareLink } = useContext(ClaimContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  let departureDifference = getTimeDifference(
    props?.data?.scheduledDepartureTime,
    props?.data?.scheduledDepartureDate,
    props?.data?.actualDepartureTime ?? props?.data?.scheduledArrivalTime,
    props?.data?.actualDepartureDate ?? props?.data?.scheduledArrivalDate
  );

  const handleSettlement = (id: string) => {
    setIsLoading(true);
    settleClaim(id, res => {
      setIsLoading(false);
      props?.onHide();
    });
  };

  const handleShareLink = (id: string) => {
    shareLink(id, res => {
      props?.onHide();
    });
  };
  let flightNumber = props?.data?.flightNumber?.includes('-')
    ? props?.data?.flightNumber?.split('-')[0]
    : props?.data?.flightNumber?.match(/^[A-Za-z]+/)?.[0];

  let flightObj = FLIGHT_NAMES?.find(
    (obj: { label: string; value: string }) => obj?.value === flightNumber
  );
  return (
    <OAModalComponent
      size='lg' // NOTE: set modal size
      backdrop='static' // NOTE: disable backdrop click
      centered // NOTE: vertically center modal
      show={props?.isOpen}
      onHide={props?.onHide}
      header={{
        title: 'View Claim Details',
        closeButton: true // NOTE: show close button on header
      }}
      body={{
        content: (
          <>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Box>
                <OATypography variant='subtitle2'>Insured Details</OATypography>
                <Box display='flex'>
                  {props?.data?.policyStatus && (
                    <Chip
                      label={props?.data?.policyStatus}
                      size='small'
                      sx={{
                        marginTop: '2px',
                        borderRadius: '4px',
                        textTransform: 'uppercase',
                        fontWeight: '600',
                        fontSize: '10px',
                        lineHeight: '12px',
                        backgroundColor: 'warning.light',
                        color: 'warning.main'
                      }}
                    />
                  )}
                  {props?.data?.claimStatus && (
                    <Chip
                      label={props?.data?.claimStatus}
                      size='small'
                      sx={{
                        marginTop: '2px',
                        marginLeft: '8px',
                        borderRadius: '4px',
                        textTransform: 'uppercase',
                        fontWeight: '600',
                        fontSize: '10px',
                        lineHeight: '12px',
                        backgroundColor: 'warning.light',
                        color: 'warning.main'
                      }}
                    />
                  )}
                </Box>
              </Box>
              <Box display='flex'>
                <OAButton
                  label='Resend Link'
                  className='btn btn-light-primary me-4'
                  onClick={() => handleShareLink(props?.data?.id)}
                />
                {props?.data?.status === 'PAYMENT_COMPLETED' && (
                  <OAButton
                    label='Settle'
                    isLoading={isLoading}
                    loadingText=''
                    onClick={() => handleSettlement(props?.data?.id)}
                  />
                )}
              </Box>
            </Box>
            <Box display='flex' justifyContent='center'>
              <Card
                variant='outlined'
                sx={{
                  marginTop: '16px',
                  width: '660px',
                  borderRadius: '12px'
                }}>
                <CardContent>
                  <Box display='flex' justifyContent='space-between'>
                    <Box sx={{ display: 'flex' }}>
                      <img
                        width={20}
                        height={20}
                        src={`https://oa-flight-assets.s3.ap-south-1.amazonaws.com/airline-logos/${
                          flightNumber ?? ''
                        }.svg`}
                        alt='Indigo'
                        loading='lazy'
                        style={{ borderRadius: '50%' }}
                      />
                      <Typography variant='body2' sx={{ marginLeft: '8px' }}>
                        {flightObj?.label ?? 'N/A'} •
                      </Typography>
                      <Typography variant='body2' sx={{ marginLeft: '8px', fontWeight: 400 }}>
                        {props?.data?.flightNumber}
                      </Typography>
                    </Box>
                    <Typography variant='body2' textAlign='end'>
                      {getFormattedDate(props?.data?.scheduledDepartureDate, 'ddd, MMM DD, YYYY')}
                    </Typography>
                  </Box>
                  <Divider sx={{ marginTop: '10px' }} />
                  <Box display='flex' justifyContent='space-around' alignItems='end'>
                    <Box display='flex'>
                      <Typography variant='h4' sx={{ fontWeight: 400 }}>
                        {props?.data?.from}
                      </Typography>
                      <Typography variant='h4' sx={{ fontWeight: 400, marginLeft: '8px' }}>
                        {props?.data?.scheduledDepartureTime}
                      </Typography>
                    </Box>
                    {props?.data?.coverName !== 'Flight Cancellation' ? (
                      <Grid xs={6} marginTop='15px'>
                        <Stack
                          direction='column'
                          justifyContent='center'
                          alignItems='center'
                          sx={{ m: 0, width: '100%' }}>
                          <Typography
                            variant='body2'
                            sx={{
                              color: departureDifference?.includes('early')
                                ? 'success.main'
                                : 'error.main'
                            }}>
                            {`${departureDifference} at departure`}
                          </Typography>
                          <img src={lineFlightImg} alt='line' loading='lazy' />
                        </Stack>
                      </Grid>
                    ) : (
                      <Box marginTop='12px' display='flex' justifyContent='center'>
                        <Chip
                          label='Flight cancelled'
                          size='medium'
                          sx={{
                            backgroundColor: 'error.light',
                            color: 'error.main',
                            width: '300px !important'
                          }}
                          avatar={
                            <Avatar sx={{ backgroundColor: 'error.light' }}>
                              <FlightCancellationIcon />
                            </Avatar>
                          }
                        />
                      </Box>
                    )}
                    <Box display='flex'>
                      <Typography variant='h4' sx={{ fontWeight: 400 }}>
                        {props?.data?.to}
                      </Typography>
                      <Typography variant='h4' sx={{ fontWeight: 400, marginLeft: '8px' }}>
                        {props?.data?.scheduledArrivalTime}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Box>
            <Box display='flex' marginTop='24px' justifyContent='space-around'>
              <Box>
                <Typography
                  variant='body2'
                  sx={{ fontWeight: 600, fontSize: '10px', lineHeight: '16px' }}>
                  CLAIMANT NAME
                </Typography>
                <Typography variant='body2'>{props?.data?.claimantName ?? 'N/A'}</Typography>
                <Typography
                  variant='body2'
                  sx={{ fontWeight: 600, fontSize: '10px', lineHeight: '16px', marginTop: '20px' }}>
                  CLAIMANT NUMBER
                </Typography>
                <Typography variant='body2'>{props?.data?.claimNumber ?? 'N/A'}</Typography>
              </Box>
              <Box>
                <Typography
                  variant='body2'
                  sx={{ fontWeight: 600, fontSize: '10px', lineHeight: '16px' }}>
                  CLAIMANT EMAIL
                </Typography>
                <Typography variant='body2'>{props?.data?.claimantEmailId ?? 'N/A'}</Typography>
                <Typography
                  variant='body2'
                  sx={{ fontWeight: 600, fontSize: '10px', lineHeight: '16px', marginTop: '20px' }}>
                  POLICY NUMBER
                </Typography>
                <Typography variant='body2'>{props?.data?.policyNumber ?? 'N/A'}</Typography>
              </Box>
              <Box>
                <Typography
                  variant='body2'
                  sx={{ fontWeight: 600, fontSize: '10px', lineHeight: '16px' }}>
                  MOBILE NUMBER
                </Typography>
                <Typography variant='body2'>
                  {props?.data?.claimantMobileNumber ?? 'N/A'}
                </Typography>
                <Typography
                  variant='body2'
                  sx={{ fontWeight: 600, fontSize: '10px', lineHeight: '16px', marginTop: '20px' }}>
                  NET PAYABLE
                </Typography>
                <Typography variant='body2'>
                  {props?.data?.currency} {props?.data?.netPayableAmount ?? 'N/A'}
                </Typography>
              </Box>
            </Box>
            <Divider sx={{ marginTop: '16px' }} />
            {props?.data?.bankDetail?.type === 'BANK_DETAIL' ? (
              <>
                <Box display='flex' marginTop='24px' justifyContent='space-around'>
                  <Box>
                    <Typography
                      variant='body2'
                      sx={{ fontWeight: 600, fontSize: '10px', lineHeight: '16px' }}>
                      IFSC CODE
                    </Typography>
                    <Typography variant='body2'>
                      {props?.data?.bankDetail?.ifscCode ?? 'N/A'}
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{
                        fontWeight: 600,
                        fontSize: '10px',
                        lineHeight: '16px',
                        marginTop: '20px'
                      }}>
                      ACCOUNT NUMBER
                    </Typography>
                    <Typography variant='body2'>
                      {props?.data?.bankDetail?.accountNumber ?? 'N/A'}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant='body2'
                      sx={{ fontWeight: 600, fontSize: '10px', lineHeight: '16px' }}>
                      BANK NAME
                    </Typography>
                    <Typography variant='body2'>
                      {props?.data?.bankDetail?.bankName ?? 'N/A'}
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{
                        fontWeight: 600,
                        fontSize: '10px',
                        lineHeight: '16px',
                        marginTop: '20px'
                      }}>
                      TRANSACTION REF
                    </Typography>
                    <Typography variant='body2'>
                      {props?.data?.paymentDetail?.bankReferenceId ?? 'N/A'}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant='body2'
                      sx={{ fontWeight: 600, fontSize: '10px', lineHeight: '16px' }}>
                      BANK BRANCH
                    </Typography>
                    <Typography variant='body2'>
                      {props?.data?.bankDetail?.branchName ?? 'N/A'}
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ marginTop: '24px' }} />
              </>
            ) : (
              <>
                <Box display='flex' marginTop='24px' marginLeft='60px' gap='20%'>
                  <Box>
                    <Typography
                      variant='body2'
                      sx={{ fontWeight: 600, fontSize: '10px', lineHeight: '16px' }}>
                      UPI ID
                    </Typography>
                    <Typography variant='body2'>{props?.data?.bankDetail?.vpa ?? 'N/A'}</Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant='body2'
                      sx={{
                        fontWeight: 600,
                        fontSize: '10px',
                        lineHeight: '16px'
                      }}>
                      TRANSACTION REF
                    </Typography>
                    <Typography variant='body2'>
                      {props?.data?.paymentDetail?.bankReferenceId ?? 'N/A'}
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ marginTop: '24px' }} />
              </>
            )}
            <Box display='flex' justifyContent='end' alignItems='center' marginTop='16px'>
              <OAButton label='close' color='light' onClick={() => props?.onHide()} />
            </Box>
          </>
        )
      }}
    />
  );
};
