import classNames from 'classnames';
import React from 'react';
import { IconType } from '.';

export default function InfoSVG(props: IconType) {
  const {
    color = '#B5B5C2',
    width = '14',
    height = '14',
    isActive = false,
    className = ''
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 17'
      fill='none'
      className={classNames('icon info-svg', className)}
      xmlns='http://www.w3.org/2000/svg'>
      <circle cx='7' cy='7' r='7' fill={isActive ? '#3A86FF' : color} />
      <path
        d='M7.9998 2.48047L7.8078 8.27647H6.3918L6.1878 2.48047H7.9998ZM7.1358 11.0845C6.8318 11.0845 6.5798 10.9925 6.3798 10.8085C6.1878 10.6165 6.0918 10.3805 6.0918 10.1005C6.0918 9.82047 6.1878 9.58847 6.3798 9.40447C6.5798 9.21247 6.8318 9.11647 7.1358 9.11647C7.4318 9.11647 7.6758 9.21247 7.8678 9.40447C8.0598 9.58847 8.1558 9.82047 8.1558 10.1005C8.1558 10.3805 8.0598 10.6165 7.8678 10.8085C7.6758 10.9925 7.4318 11.0845 7.1358 11.0845Z'
        fill='white'
      />
    </svg>
  );
}
