import { useEffect, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';

import { getUserByToken, login } from '../core/_requests';
import { useAuth } from '../core/Auth';
import { encryptStorage } from '../../helper/storage';
import { useNavigate } from 'react-router-dom';
import { isAlsaffronOrg, showToast } from '../../helper';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required')
});

const initialValues = {
  email: '',
  password: ''
};

const LoadingMessage = () => (
  <div className='text-center'>
    <span className='spinner-border spinner-border-lg align-middle'></span>
    <h4 className='mt-3'>Please wait while we sign in..</h4>
  </div>
);

const loadScript = () => {
  // Check if script tag already exists
  const existingScript = document.getElementById('hs-script-loader');

  if (existingScript) {
    return;
  }

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = 'hs-script-loader';
  script.async = true;
  script.defer = true;
  script.src = '//js.hs-scripts.com/21847530.js';
  document.body.appendChild(script);
};

export function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const deepLinkUrl = location?.state?.deepLinkUrl;
  const isDeepLink = deepLinkUrl?.includes('mode=live') || deepLinkUrl?.includes('mode=test');

  let mode: any;
  let loginType = searchParams.get('loginType');
  let token = searchParams.get('token');
  let id = searchParams.get('id');
  if (deepLinkUrl) {
    // Extract the query string from the URL
    const queryString = deepLinkUrl.split('?')[1];
    // Use URLSearchParams to extract the desired parameters
    const params = new URLSearchParams(queryString);
    mode = params.get('mode');
    loginType = params.get('loginType');
    token = params.get('token');
    id = params.get('id');
  }

  const performLogin = async (
    email: string,
    password: string,
    isSso: boolean,
    id: string | undefined
  ) => {
    setLoading(true);
    try {
      const { data: auth } = await login(email, password, id, isSso);
      if (auth?.data?.newPasswordRequired === true) {
        navigate(`/auth/reset-password/${email}/${true}`);
      }
      saveAuth(auth);
      if (auth.data.accessToken) {
        const { data: user } = await getUserByToken(auth.data.accessToken);

        if (!isAlsaffronOrg(user)) {
          loadScript();
        }
        setCurrentUser(user);
        //setting up live mode by default
        if (user?.data?.organization?.liveModeEnabled === true) {
          localStorage.setItem('oaMode', 'live');
        } else {
          localStorage.setItem('oaMode', 'test');
        }
        encryptStorage.setItem('oa_orgnization_id', user?.data?.organization?.id);
        if (deepLinkUrl && isDeepLink) {
          const queryString = deepLinkUrl.split('?')[0];
          navigate(queryString + '?mode=' + mode);
        }

        window.dispatchEvent(new Event('mode_switch'));
      }
    } catch (error: any) {
      setLoading(false);
      showToast(error?.response?.data?.errors);
    }
  };

  useEffect(() => {
    if (loginType || token || id) {
      if (loginType && token && id) {
        performLogin(loginType, token, true, id);
      } else {
        showToast(
          "Unable to proceed. Ensure you've provided the correct login type, token, and ID.",
          'error'
        );
      }
    }
  }, [loginType, token]);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        performLogin(values.email, values.password, false, undefined);
      } catch (error) {
        saveAuth(undefined);
        setStatus('The login detail is incorrect');
        setSubmitting(false);
        setLoading(false);
      }
    }
  });

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      id='kt_login_signin_form'
      noValidate>
      {loginType && token && id && loading ? (
        <LoadingMessage />
      ) : (
        <>
          <div className='text-center mb-10'>
            <h1 className='text-dark mb-3'>
              Sign In to {window.location.host === 'world.evisas.io' ? 'E-visas.io' : 'OnArrival'}
            </h1>
          </div>

          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : null}

          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
            <input
              placeholder='Email'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email
                }
              )}
              type='email'
              name='email'
              autoComplete='off'
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            )}
          </div>
          <div className='fv-row mb-10'>
            <div className='d-flex justify-content-between mt-n5'>
              <div className='d-flex flex-stack mb-2'>
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                <Link
                  to='/auth/forgot-password'
                  className='link-primary fs-6 fw-bolder'
                  style={{ marginLeft: '5px' }}>
                  Forgot Password ?
                </Link>
              </div>
            </div>
            <input
              placeholder='Password'
              type='password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid}>
              {!loading && <span className='indicator-label'>Continue</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          <ul className='menu menu-primary fw-bold order-1 justify-content-center'>
            <li className='menu-item'>
              <a
                href='/terms-and-conditions'
                target='_BLANK'
                rel='noreferrer'
                className='menu-link pe-1 py-0'>
                Terms and Conditions |
              </a>
            </li>
            <li className='menu-item'>
              <a
                href='/privacy-policy'
                target='_BLANK'
                rel='noreferrer'
                className='menu-link ps-0 py-0'>
                Privacy Policy
              </a>
            </li>
          </ul>
        </>
      )}
    </form>
  );
}
