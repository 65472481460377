import JSZip from 'jszip';

export async function extractNonZipFilesFromZip(
  file: Blob | File,
  fileName?: string
): Promise<{ blob: Blob; name: string }[]> {
  const nonZipBlobs: { blob: Blob; name: string }[] = [];

  if (!(fileName || file.name).endsWith('.zip')) {
    return [{ blob: file, name: fileName || file.name }];
  }

  const zipContents = new JSZip();
  await zipContents.loadAsync(file);

  const files = Object.values(zipContents.files);

  for (const fileObj of files) {
    if (fileObj.name.endsWith('.zip') && !fileObj.dir) {
      const innerZipBlob = await fileObj.async('blob');
      const innerFiles = await extractNonZipFilesFromZip(innerZipBlob, fileObj.name);
      nonZipBlobs.push(...innerFiles);
    } else if (!fileObj.dir) {
      const blob = await fileObj.async('blob');
      // Extract the filename only, without any path
      const extractedFileName = fileObj.name.split('/').pop();
      nonZipBlobs.push({ blob, name: extractedFileName! });
    }
  }

  return nonZipBlobs;
}
