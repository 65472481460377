import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import CsvActionBar from './CsvActionBar';
import CsvGridEditor from './CsvGridEditor';
import { createEmptyRows, parseCSVFile } from './helper';
import { Moment } from 'moment';
import { generateFields } from './helpers/generateFields';
import { GridApi } from '@mui/x-data-grid-pro';
import { generateSampleCsvData } from './helpers/generateSampleCsvData';

export interface ISpaces {
  title?: string;
  category?: string | null;
  columns?: any[];
  rows?: any[];
  requestedProductCode?: string;
}

interface ICsvManager {
  productSpecificFields: any;
  productName: string;
  orderType: 'insurance' | 'visa';
  category: string | undefined;
  onUpdate: (data: any) => void;
  tripStartDateMin?: Moment;
  selectedProduct?: any;
  isRowCellEmpty: boolean;
  setIsRowCellEmpty: (isRowEmpty: boolean) => void;
}

const CsvManager: FC<ICsvManager> = ({
  productSpecificFields,
  productName,
  orderType,
  category,
  onUpdate,
  tripStartDateMin,
  selectedProduct,
  setIsRowCellEmpty,
  isRowCellEmpty
}) => {
  const [spaces, setSpaces] = useState<ISpaces>({
    title: '',
    category: '',
    columns: [],
    rows: [],
    requestedProductCode: ''
  });
  const [hasErrors, setHasErrors] = useState<boolean>(false);

  const dataGridApiRef = useRef<GridApi>();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const targetFile = event.target.files?.[0];
    if (!targetFile) return;
    parseCSVFile(targetFile, orderType, dataWithIds => {
      setSpaces((prev: any) => {
        return {
          ...prev,
          rows: dataWithIds.length > 0 ? dataWithIds : createEmptyRows(100)
        };
      });
    });
  };

  const convertToCSV = (rows: any[]) => {
    if (rows.length === 0) return '';

    const headers = Object.keys(rows[0]).join(',');
    const data = rows.map(row => Object.values(row).join(',')).join('\n');

    return `${headers}\n${data}`;
  };

  const exportCsv = () => {
    if (dataGridApiRef.current) {
      const sampleRow = generateSampleCsvData(selectedProduct?.requiredBulkFields);
      const csvContent = convertToCSV([sampleRow]);
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = category ? `${category}.csv` : 'sample.csv';
      link.click();
      URL.revokeObjectURL(link.href);
    }
  };

  useEffect(() => {
    if (productSpecificFields) {
      const requiredFields = selectedProduct?.requiredBulkFields || {};
      const csvColumnsToValidate = generateFields(requiredFields);
      setSpaces({
        title: category,
        category: category,
        columns: csvColumnsToValidate,
        rows: createEmptyRows(100),
        requestedProductCode: productSpecificFields?.value
      });
    } else {
      setSpaces({
        title: '',
        category: null,
        columns: [],
        rows: [],
        requestedProductCode: ''
      });
    }
  }, [productSpecificFields]);

  useEffect(() => {
    const allObjectsHaveOnlyId = spaces?.rows?.every((row: any) => {
      const keysWithoutId = Object.keys(row).filter(key => key !== 'id');
      return keysWithoutId.length === 0;
    });

    const errors = allObjectsHaveOnlyId ? true : hasErrors;

    onUpdate({
      spaces: spaces,
      hasErrors: errors
    });
  }, [spaces?.rows, hasErrors]);

  return (
    <>
      <CsvActionBar
        productName={productName}
        category={spaces?.category!}
        title={spaces.title!}
        onChange={handleFileChange}
        handleDownloadSampleCsv={exportCsv}
      />
      <CsvGridEditor
        {...{
          spaces,
          setSpaces,
          productSpecificFields,
          hasErrors,
          setHasErrors,
          tripStartDateMin,
          isRowCellEmpty,
          setIsRowCellEmpty,
          apiRef: dataGridApiRef
        }}
      />
    </>
  );
};

export default CsvManager;
