import { createRoot } from 'react-dom/client';
import axios from 'axios';
import { QueryClient, QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools';
import { initFirebase } from 'react-commenting-engine';
import { Chart, registerables } from 'chart.js';
import { Replay } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';
import { LicenseInfo } from '@mui/x-license-pro';
// import { ponyfillGlobal } from '@mui/utils';

import { AuthProvider, setupAxios } from './auth';
import { AppRoutes } from './route/AppRoutes';
import ErrorBoundary from './components/Common/ErrorBoundary';

import './_metronic/assets/sass/style.scss';
import './_metronic/assets/sass/style.react.scss';
import InternetBoundary from './components/Common/InternetBoundary';
import { theme } from './config/theme';
import { ThemeProvider } from '@mui/material';

setupAxios(axios);

Chart.register(...registerables);

const queryClient = new QueryClient();

const allowedUrlsToTrack: any = ['https://www.onarriv.io', 'https://hub.onarrival.travel'];

if (allowedUrlsToTrack.some((url: string) => window.location.href.includes(url))) {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_ID ?? '');
}

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.BrowserTracing(),
      new Replay({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
        sessionSampleRate: 1.0,
        errorSampleRate: 1.0
      })
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [allowedUrlsToTrack],
    profilesSampleRate: 1.0,
    environment: process.env.NODE_ENV
  });
}
const container = document.getElementById('root');
const root = createRoot(container!);

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

initFirebase(firebaseConfig);

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_LICENSE_KEY as string);

root.render(
  <InternetBoundary>
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </ThemeProvider>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </ErrorBoundary>
  </InternetBoundary>
);
