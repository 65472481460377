import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Card, CardContent, Tab, Tabs, useTheme } from '@mui/material';
import OATasks from '../../../components/OATasks';
import OAComments from '../../../components/OAComments';
import { IOrder } from '../../../types';
import OANoData from '../../../components/Common/OANoData';
import { useOrderCommentCount } from 'react-commenting-engine';
import { OATypography } from '../../../components/widgets';
import OAChip from '../../../components/Base/OAChip';
import { ORDER_TYPE } from '../constants';

const a11yProps = (index: number) => ({
  'id': `tab-${index}`,
  'aria-controls': `tabpanel-${index}`
});

const CardBoxStyle = {
  boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10)',
  borderRadius: '12px'
};

interface ICustomTabPanel {
  children?: React.ReactNode;
  value: number;
  index: number;
}
const CustomTabPanel: FC<ICustomTabPanel> = ({ children, value, index }) => (
  <div
    role='tabpanel'
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}>
    {value === index && <Box>{children}</Box>}
  </div>
);

interface ITabLabel {
  label: string;
  count: number;
  selected: boolean;
  isVisa?: boolean | undefined;
}
const TabLabel: FC<ITabLabel> = ({ label, count, selected, isVisa }) => {
  const theme = useTheme();
  const color = selected ? theme.palette.primary.main : theme.palette.grey[400];
  const chipColor = selected ? 'primary' : 'secondary';

  return (
    <Box display='flex' alignItems='center'>
      {isVisa ? (
        <OATypography variant='body1' sx={{ textTransform: 'capitalize', mr: '4px', color }}>
          {label}
        </OATypography>
      ) : (
        <OATypography variant='h4' sx={{ textTransform: 'capitalize', mr: '4px' }}>
          {label}
        </OATypography>
      )}
      {count > 0 && (
        <OAChip
          label={count?.toString()}
          color={chipColor}
          sx={{ height: '18px', width: 'auto', borderRadius: '30px', fontSize: '12px' }}
        />
      )}
    </Box>
  );
};

interface ICommentsAndTasks {
  orderData: IOrder;
  currentUser: any;
  isFlight?: boolean | undefined;
}
const CommentsAndTasks: React.FC<ICommentsAndTasks> = ({ orderData, currentUser, isFlight }) => {
  const { id, orderType } = useParams();
  const mode = localStorage.getItem('oaMode');

  const isVisa = orderType === ORDER_TYPE.VISA;

  const [value, setValue] = useState(isVisa ? 0 : 1);
  const [taskCount, setTaskCount] = useState(0);
  const [readComment, setReadComment] = useState<boolean>(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const userName = `${currentUser?.firstName} ${currentUser?.lastName}`;
  const { unseenCommentsCount } = useOrderCommentCount(id, currentUser?.id);

  const getTaskCount = (count: number) => setTaskCount(count);

  const handleReadRecepits = () => {
    setReadComment(true);
  };

  return (
    <Card sx={CardBoxStyle}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs variant={isVisa ? 'fullWidth' : 'standard'} value={value} onChange={handleChange}>
          {isVisa && (
            <Tab
              label={
                <TabLabel label='Tasks' count={taskCount} selected={value === 0} isVisa={isVisa} />
              }
              {...a11yProps(0)}
            />
          )}
          <Tab
            disabled={!isVisa}
            label={
              <TabLabel
                label='Comments'
                count={unseenCommentsCount}
                selected={value === 1}
                isVisa={isVisa}
              />
            }
            onClick={handleReadRecepits}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <CardContent sx={{ p: '16px 8px 0 8px' }}>
        {isVisa && (
          <CustomTabPanel value={value} index={0}>
            <OATasks orderId={id!} getTaskCount={getTaskCount} />
          </CustomTabPanel>
        )}
        <CustomTabPanel value={value} index={1}>
          {['ORDER_DRAFT', 'ORDER_QUOTE']?.includes(orderData?.bookingStatus) ? (
            <OANoData
              icon='/media/icons/duotune/general/nochat.svg'
              title='You can post comments once the order has been submitted'
            />
          ) : (
            <OAComments
              orderId={id!}
              userName={userName}
              userId={currentUser?.id}
              readComment={readComment}
              mode={mode}
              isFlight={isFlight}
            />
          )}
        </CustomTabPanel>
      </CardContent>
    </Card>
  );
};

export default CommentsAndTasks;
