import * as Yup from 'yup';
export interface IQuotationProvider {
  children: any;
  // TODO: define all types
}

export interface IQuotationFilterQueryParams {
  search?: string;
  pageIndex: number;
  pageCount: number;
  createdBy?: string;
  sortByField: string | null;
  sortBy: 'ASC' | 'DESC' | null;
}
export interface IQuotationFormValues {
  departure: string;
  arrival: string;
  senior: number | string;
  adult: number | string;
  child: number | string;
  infant: number | string;
  tripType: string;
  requestedProductId: string;
  tripDuration: any;
  occupation: string;
  supplier: string;
  destination: string;
}

export interface IQuotationProposerValues {
  proposerEmail: string;
  proposerName: string;
  companyName: string;
  additionalEmail: string[];
}

export interface IQuotationProvider {
  children: any;
  // TODO: define all types
}

//new information form validation
export const validateInformationForm = Yup.object({
  departure: Yup.string().required().label('Departure'),
  arrival: Yup.lazy(val =>
    Array.isArray(val)
      ? Yup.array().of(Yup.string()).required().label('Destination')
      : Yup.string().required().label('Destination')
  ),
  adult: Yup.number().min(0, 'invalid').label('count'),
  senior: Yup.number().min(0, 'invalid').label('count'),
  child: Yup.number().min(0, 'invalid').label('count').nullable(true),
  infant: Yup.number().min(0, 'invalid').label('count')
  // visaType: Yup.string().required().label('Purpose')
});

export const validateTravellersForVisa = Yup.object().shape(
  {
    adult: Yup.number().when('child', {
      is: (child: any, adult: any) => !child,
      then: Yup.number().required().label('Adult')
    }),
    child: Yup.number().when('adult', {
      is: (adult: any, child: any) => !adult,
      then: Yup.number().required().label('Child')
    })
  },
  [['adult', 'child']]
);

export const validateTravellersForInsurance = Yup.object({}).shape(
  {
    adult: Yup.number().when('senior', {
      is: (child: any) => !child,
      then: Yup.number().required().label('Adult')
    }),
    senior: Yup.number().when('adult', {
      is: (adult: any) => !adult,
      then: Yup.number().required().label('Senior')
    })
  },
  [['adult', 'senior']]
);

export const validationSchema = Yup.object({
  departure: Yup.string().required().label('Departure'),
  arrival: Yup.lazy(val =>
    Array.isArray(val) ? Yup.array().of(Yup.string()).required() : Yup.string().required()
  ),
  adult: Yup.number().min(0, 'invalid').label('count'),
  senior: Yup.number().min(0, 'invalid').label('count'),
  child: Yup.number().min(0, 'invalid').label('count'),
  infant: Yup.number().min(0, 'invalid').label('count'),
  proposerName: Yup.string().required().label('Trip Type'),
  proposerEmail: Yup.string().email().required().label('Email address'),
  companyName: Yup.string().required().label('Purpose'),
  supplier: Yup.string().required('Supplier'),
  requestedProductId: Yup.string().required('Visa')
});

export const evisaValidationSchema = Yup.object({
  occupation: Yup.string().label('Occupation').required()
});

export const inits: IQuotationFormValues = {
  departure: 'IN',
  arrival: '',
  senior: '',
  adult: '',
  child: '',
  infant: '',
  tripType: '',
  requestedProductId: '',
  tripDuration: {
    from: null,
    to: null
  },
  occupation: '',
  supplier: '',
  destination: ''
};

export const proposerInits: IQuotationProposerValues = {
  proposerEmail: '',
  proposerName: '',
  additionalEmail: [],
  companyName: ''
};
