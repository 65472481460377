import React, { useEffect, useRef, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FormikHelpers, FormikValues } from 'formik';
import classNames from 'classnames';

import { ORDER_STEPS_SCHEMA, ORDER_TYPE, ORDER_CREATE_ACTIONS } from '../constants';
import OAToolbar, { IBreadcrumbItem } from '../../../components/widgets/OAToolbar';
import { StepperComponent } from '../../../_metronic/assets/ts/components';
import { useOrderContext } from '../context/OrderContext';
import { IOrderType, IStepSchema } from '../types';
import InsuranceFormSteps from './insurance';
import VisaFormSteps from './visa';
import { showDialog } from '../../../helper';
import { Paper } from '@mui/material';
import { OATypography } from '../../../components/widgets';

export default function CreateOrder() {
  const params = useParams<{ orderType: IOrderType; id?: string; step?: any }>();
  const location = useLocation();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const {
    products,
    formState,
    draftOrder,
    updateFormState,
    onAddTripDetails,
    onGstSubmit,
    getDraftOrderQuotation,
    onCreateDraftOrder,
    onEditDraftOrder,
    getOrderById,
    onDuplicateOrder,
    onCreateDraftQuote
  } = useOrderContext();

  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);

  const [curIndex, setCurIndex] = useState<number>(1);
  const [stepsSchema, setStepsSchema] = useState<Array<IStepSchema>>(ORDER_STEPS_SCHEMA.VISA);
  const [loading, setLoading] = useState<boolean>(true);

  const convertToOrder = searchParams.get('convertToOrder');
  const quotationId = searchParams.get('quotationId');
  const cloneOrderId = searchParams.get('cloneOrderId');

  useEffect(() => {
    if (convertToOrder === 'true' && quotationId) {
      getOrderById(params?.orderType as IOrderType, quotationId, null, () => {
        updateFormState({ quotation: false, convertToOrder: true });
        setLoading(false);
      });
    } else if (cloneOrderId && !formState?.cloneOrderId) {
      getOrderById(params?.orderType!, cloneOrderId, null, () => {
        onDuplicateOrder(cloneOrderId);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);
  /**
   * NOTE: if the order type is 'VISA' and the groupName is not empty
   * then extract the first two elements of the stepsSchema array using the slice() method
   */
  useEffect(() => {
    if (params?.orderType === ORDER_TYPE.VISA && !!formState?.groupName) {
      const firstTwoElements = stepsSchema.slice(0, 2);
      setStepsSchema(firstTwoElements);
    }
  }, [formState]);
  /**
   * NOTE: change form steps schema based on orderType coming as router parameter ...
   */
  useEffect(() => {
    if (!loading) {
      if (params?.orderType === ORDER_TYPE.VISA) {
        const draftSchema = formState?.quotation
          ? ORDER_STEPS_SCHEMA.QUOTATION
          : ORDER_STEPS_SCHEMA.VISA;
        setStepsSchema(draftSchema);
      } else {
        const draftSchema = formState?.quotation
          ? ORDER_STEPS_SCHEMA.QUOTATION
          : ORDER_STEPS_SCHEMA.INSURANCE;
        setStepsSchema(draftSchema);
      }
    }
  }, [params?.orderType, formState?.quotation, loading]);

  useEffect(() => {
    if (!stepperRef.current) return;
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement);
  }, [stepperRef]);

  useEffect(() => {
    // Add the event listener when the component mounts
    window.addEventListener('popstate', handlePopstate);
    window.addEventListener('beforeunload', handleBeforeUnload);
    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopstate);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (event: any) => {
    event.preventDefault();
    event.returnValue = '';
  };

  const handlePopstate = (event: any) => {
    const currentStepIndex: any = stepper.current?.currentStepIndex;
    if (currentStepIndex === 2 && formState?.travellerDetails?.length > 0) {
      // popup - you will be redirect to
      showDialog({
        title: 'Attention Required',
        text: 'You will be redirected to the visa order page',
        icon: 'warning'
      }).then(result => {
        if (result?.isConfirmed) {
          navigate(`/apps/orders/${params?.orderType}`);
        } else {
          event.preventDefault();
          window.history.pushState(null, 'null', window.location.pathname);
        }
      });
    } else if (currentStepIndex === 2 && formState?.travellerDetails?.length === 0) {
      stepper?.current?.goto(currentStepIndex - 1);
      setCurIndex(currentStepIndex - 1);
      navigate(
        `/apps/orders/${params?.orderType}/create/${currentStepIndex - 1}${location?.search}`
      );
    } else if (currentStepIndex === 1) {
      navigate(`/apps/orders/${params?.orderType}`);
    } else {
      stepper?.current?.goto(currentStepIndex - 1);
      setCurIndex(currentStepIndex - 1);
      navigate(
        `/apps/orders/${params?.orderType}/create/${currentStepIndex - 1}${location?.search}`
      );
    }
  };
  /**
   * NOTE: 'handleStepChange' method calls on specific conditions while changing
   * each step...
   *
   * @param actions
   * @param isQuotation
   * @returns
   */
  const handleStepChange = (actions?: FormikValues, isQuotation?: boolean) => {
    /**
     * NOTE: if stepper is not available then return...
     */
    if (!stepper.current) return;
    /**
     * NOTE: if current step index is not equal to total steps number then go to
     * next step and navigate to next step url...
     */

    if (curIndex !== stepper.current.totatStepsNumber) {
      let searchParamStr = location?.search;
      /**
       * NOTE: if "isQuotation" is true then update formState and add quotation
       * query param in url else remove quotation query param from url...
       */
      if (isQuotation) {
        updateFormState({ quotation: true });
        searchParamStr = `?quotation=true`;
      } else {
        updateFormState({ quotation: false });
        if (location?.search?.includes('?quotation=true')) {
          searchParamStr = searchParamStr?.replace('?quotation=true', '');
        }
      }
      setCurIndex(prev => {
        stepper?.current?.goNext();
        navigate(`/apps/orders/${params?.orderType}/create/${prev + 1}${searchParamStr}`, {
          state: location?.state
        });
        return prev + 1;
      });
    } else {
      setCurIndex(() => {
        stepper?.current?.goto(1);
        return 1;
      });
      actions?.resetForm();
    }
    /**
     * DEVNOTE: scroll to top everytime step changes happen...
     */
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };
  /**
   * NOTE: 'handleSubmit' method calls on specific conditions while submitting
   * each step form...
   */
  const handleSubmit = (
    values: any,
    type: string,
    actions?: FormikHelpers<any>,
    cb?: (response?: any) => void
  ) => {
    switch (type) {
      case ORDER_CREATE_ACTIONS.ADD_TRIP_DETAILS:
        const { isQuotation, ...restValues } = values;
        const draftProduct = products?.find(opt => opt?.id === restValues?.requestedProductId);
        onAddTripDetails(draftProduct);
        handleStepChange(actions, isQuotation);
        break;

      case ORDER_CREATE_ACTIONS.ADD_GST:
        onGstSubmit(values);
        let orderIdTemp = params?.id ?? draftOrder?.id;
        onEditDraftOrder(orderIdTemp, params?.orderType as IOrderType, values, (_res: any) => {
          cb?.(_res);
        });
        break;

      case ORDER_CREATE_ACTIONS.DOWNLOAD_QUOTE:
        getDraftOrderQuotation(params?.orderType as IOrderType, null, 'downloadQuote');
        break;

      case ORDER_CREATE_ACTIONS.GET_ORDER_QUOTATION:
        getDraftOrderQuotation(params?.orderType as IOrderType, null, null, (_res: any) => {
          if (_res?.status === 200) {
            handleStepChange(actions);
          }
          cb?.(_res);
        });
        break;

      case ORDER_CREATE_ACTIONS.REVIEW_ORDER:
        if (params?.id || formState?.orderId || draftOrder?.bookingStatus === 'ORDER_DRAFT') {
          const orderId = params?.id ?? formState?.orderId;
          onEditDraftOrder(orderId, params?.orderType as IOrderType, null, (_res: any) => {
            if (_res?.status === 200) {
              handleStepChange(actions);
            }
            cb?.(_res);
          });
        } else {
          onCreateDraftOrder(params?.orderType as IOrderType, (_res: any) => {
            if (_res?.status === 200 && formState?.cloneOrderId) {
              handleStepChange(actions);
            }
            cb?.(_res);
          });
        }
        break;

      case ORDER_CREATE_ACTIONS.CREATE_DRAFT_QUOTE:
        onCreateDraftQuote(
          params?.orderType as IOrderType,
          { memberCount: values?.memberCount, save: false },
          (_res: any) => {
            if (_res?.status === 200) {
              handleStepChange(actions, true);
            }
            cb?.(_res);
          }
        );
        break;

      case ORDER_CREATE_ACTIONS.CREATE_DRAFT_ORDER:
        onCreateDraftOrder(params?.orderType as IOrderType, (_res: any) => {
          handleStepChange(actions);
        });
        break;

      case ORDER_CREATE_ACTIONS.EDIT_DRAFT_ORDER:
        const orderId = params?.id ?? draftOrder?.id;
        onEditDraftOrder(orderId, params?.orderType as IOrderType, null, (_res: any) => {
          handleStepChange(actions);
        });
        break;

      default:
        break;
    }
  };

  const gotoNextStep = () => {
    stepper?.current?.goNext();
    setCurIndex(2);
  };

  const handleStepClick = (stepIndex: any) => {
    if (!!draftOrder?.id || !!formState?.quotationId) {
      if (stepIndex < 2) {
        return;
      } else if (stepIndex < curIndex) {
        navigate(`/apps/orders/${params?.orderType}/create/${stepIndex}${location?.search}`);
        stepper?.current?.goto(stepIndex);
        setCurIndex(stepIndex);
      }
    } else {
      if (stepIndex < curIndex) {
        navigate(`/apps/orders/${params?.orderType}/create/${stepIndex}${location?.search}`);
        stepper?.current?.goto(stepIndex);
        setCurIndex(stepIndex);
      }
    }
  };

  const stepperClasses =
    'stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid';

  const pageTitle =
    params?.orderType === ORDER_TYPE.VISA
      ? 'Visa'
      : params?.orderType === ORDER_TYPE.INSURANCE
      ? 'Insurance'
      : '';

  const breadcrumb: Array<IBreadcrumbItem> = [
    {
      title: `Dashboard`,
      path: `/dashboard`,
      isActive: false
    },
    {
      title: `${pageTitle} Order Management`,
      path: `/apps/orders/${params?.orderType}`,
      isActive: false
    },
    {
      title: `Create ${pageTitle}${formState?.quotation ? ' Quotation' : ''}`,
      path: ``,
      isActive: true
    }
  ];
  /**
   * NOTE: orderType is coming from route parameters. If orderType doesn't match
   * ORDER_TYPE.VISA or ORDER_TYPE.INSURANCE then just redirect to error page.
   * If loggedIn user is a part of a supplier org then redirect to error page.
   * And if orderType is matching either of ORDER_TYPE.VISA or ORDER_TYPE.INSURANCE
   * then proceed to call api with the orderType and show respective view for that.
   */
  if (![ORDER_TYPE.VISA, ORDER_TYPE.INSURANCE].includes(params?.orderType!)) {
    return <Navigate to='/error/404' />;
  }
  return (
    <React.Fragment>
      <OAToolbar
        title={`Create ${pageTitle}${formState?.quotation ? ' Quotation' : ''}`}
        breadcrumb={breadcrumb}
      />
      <div
        ref={stepperRef}
        id='kt_create_account_stepper'
        className={classNames(stepperClasses, 'order-create')}>
        <CreateOrderFormSteps
          schema={stepsSchema}
          onClickStep={handleStepClick}
          innerRef={stepper}
          loading={loading}
        />
        <div className='d-flex flex-row-fluid'>
          {params?.orderType === ORDER_TYPE.VISA ? (
            <VisaFormSteps
              handleSubmit={handleSubmit}
              handleStepChange={handleStepChange}
              quotationId={quotationId}
              onClickStep={gotoNextStep}
            />
          ) : (
            <InsuranceFormSteps
              handleSubmit={handleSubmit}
              handleStepChange={handleStepChange}
              // quotationId={quotationId}
              onClickStep={gotoNextStep}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
/**
 * Additional Components
 */
interface ICreateOrderFormSteps {
  schema: Array<IStepSchema>;
  onClickStep: (stepIndex: number) => void | undefined;
  innerRef: React.MutableRefObject<StepperComponent | null>;
  loading: boolean;
}

const CreateOrderFormSteps = (props: ICreateOrderFormSteps) => {
  return (
    <Paper
      variant='outlined'
      className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9 h-100 stepper-steps-card'>
      <div className='px-6 px-lg-10 px-xxl-15 py-9'>
        <div className='stepper-nav'>
          {props?.schema?.map((obj: IStepSchema) => {
            return (
              <div
                key={`${obj?.index}_${obj?.current}`}
                className={classNames('stepper-item', { current: obj?.current })}
                data-kt-stepper-element='nav'
                onClick={() => props?.onClickStep(obj?.index)}>
                <div className='stepper-line w-40px' />
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check' />
                  <OATypography
                    className='stepper-number'
                    isLoading={props?.loading}
                    skeletonProps={{ height: 40, width: 40 }}>
                    {obj?.index}
                  </OATypography>
                </div>
                <div className='stepper-label'>
                  <OATypography
                    className='stepper-title'
                    isLoading={props?.loading}
                    skeletonProps={{ height: 20, width: 186 }}>
                    {obj?.title}
                  </OATypography>
                  <OATypography
                    variant='caption'
                    className='stepper-desc'
                    isLoading={props?.loading}
                    skeletonProps={{ height: 16, width: 195 }}>
                    {obj?.desc}
                  </OATypography>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Paper>
  );
};
