import { Dispatch, useContext, useEffect, useMemo, useState } from 'react';
import OARangePicker from '../../../components/OARangePicker';
import { KTCard, KTCardBody } from '../../../_metronic/helpers';
import LedgerContext, { ILedgerContext } from '../context/LedgerContext';
import { ILedgerQueryParams } from '../type';
import OAModalComponent from '../../../components/OAModalComponent';
import OAButton from '../../../components/widgets/OAButton';
import AddTransactionForm from '../Create/AddTransactionForm';
import { useAuth } from '../../../auth';
import OATableComponent, { OATablePaginationProps } from '../../../components/OATableComponent';
import { getLedgerColumns } from './LedgerColumns';
interface ITransactionHistory {
  list: Array<any>;
  queryParams: ILedgerQueryParams;
  setQueryParams: Dispatch<React.SetStateAction<ILedgerQueryParams>>;
  onChangeRangePicker: (range: any) => void;
}

const TransactionHistory = (props: ITransactionHistory) => {
  const { list, queryParams, setQueryParams, onChangeRangePicker } = props;
  const { downloadReport, ledgerList, getLedgerInfo, pageInfo }: ILedgerContext =
    useContext(LedgerContext);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [isTransactionModalOpen, setTransactionModalOpen] = useState<boolean>(false);
  const [transactionData, setTransactionData] = useState<any>(null);
  const [ledgerInfo, setLedgerInfo] = useState<any>(null);
  const { currentUser } = useAuth();

  const toggleTransactionModal = () => {
    if (isTransactionModalOpen) {
      setTransactionData(null);
    }
    setTransactionModalOpen(prev => !prev);
  };

  const download = () => {
    setIsGenerating(true);
    downloadReport(res => {
      setIsGenerating(false);
    });
  };

  useEffect(() => {
    getLedgerInfo(res => {
      setLedgerInfo(res);
    });
  }, []);

  const handleEditTransaction = (data: any) => {
    toggleTransactionModal();
    setTransactionData(data);
  };

  const tableColumns = useMemo(() => {
    return getLedgerColumns(handleEditTransaction);
  }, []);

  const paginationProps: OATablePaginationProps = {
    pageIndex: queryParams?.pageIndex,
    pageCount: pageInfo?.totalPages,
    total: pageInfo?.totalCount,
    pageSize: pageInfo?.size,
    onPageChange: (value: any) => {
      setQueryParams(prev => ({ ...prev, pageIndex: value }));
    }
  };

  return (
    <div>
      <KTCard>
        <div className='card-header min-h-50px align-items-center px-6 py-4'>
          <h3 className='m-0'>Transaction history</h3>
          <div className='d-flex align-items-end'>
            <OARangePicker
              name='ledger-data-range'
              className='mw-250px'
              onChangeRangePicker={onChangeRangePicker}
              dateRange={queryParams?.dateRange}
              maxDate={new Date()}
            />
            <OAButton
              onClick={download}
              className='btn btn-sm btn-flex btn-light-primary fw-bolder text-nowrap ms-2'
              disabled={isGenerating || (list && list.length == 0)}
              icon='/media/icons/duotune/files/fil021.svg'
              iconClassName='svg-icon-3 me-2'
              isLoading={isGenerating}
              loadingText='Generating...'
              label='Download CSV'
            />

            {currentUser?.data?.role === 'ROLE_SUPER_ADMIN' && ledgerInfo?.active && (
              <OAButton
                onClick={() => setTransactionModalOpen(true)}
                label='Add Transaction'
                className='ms-2'
              />
            )}
          </div>
        </div>
        <KTCardBody className='px-6 py-5'>
          <OATableComponent
            columns={tableColumns}
            data={ledgerList ?? []}
            pagination={paginationProps}
            loading={false}
            disableColumnMenu={false}
          />
        </KTCardBody>
      </KTCard>
      <OAModalComponent
        className='transactional-modal'
        show={isTransactionModalOpen}
        onHide={toggleTransactionModal}
        header={{
          title: `${transactionData ? 'Edit' : 'Add'} Transaction`,
          closeButton: true
        }}
        body={{
          content: <AddTransactionForm onHide={toggleTransactionModal} data={transactionData} />
        }}
      />
    </div>
  );
};

export default TransactionHistory;
