import Skeleton from 'react-loading-skeleton';
import { Card } from 'react-bootstrap';

import OANoData from '../../../components/Common/OANoData';
import { formatNumberForCurrency } from '../../../helper';
import { KTSVG } from '../../../_metronic/helpers';
import { SkeletonComponent } from './TopSuppliersTile';

const TopSellingProductsTile = ({
  data,
  isLoading,
  dateRange
}: {
  data: any;
  isLoading: boolean;
  dateRange: string;
}) => {
  return (
    <Card className='h-100 overflow-hidden'>
      <span className='menu-icon position-absolute top-0 end-0 p-2'>
        <KTSVG path='/media/icons/duotune/Move.svg' className='svg-icon-2' />
      </span>
      <Card.Body>
        <div className='fw-bold'>
          <h3 className='mb-1'>Top selling products</h3>
          <p className='fs-7 text-muted mb-0'>{dateRange}</p>
        </div>
        <div className='table-responsive mt-4'>
          {isLoading ? <SkeletonComponent /> : <SuppliersTable data={data} />}
        </div>
      </Card.Body>
    </Card>
  );
};

export default TopSellingProductsTile;

const SuppliersTable = ({ data }: { data: any }) => {
  return (
    <>
      {data && data.length > 0 ? (
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          <tbody>
            {data.map((obj: any) => (
              <tr key={obj?.productId}>
                <td>
                  <span className='text-dark fw-bolder d-block fs-6'>{obj?.product?.name}</span>
                  <span className='text-muted fw-bold text-muted d-block fs-7 text-capitalize'>
                    {obj?.product?.ancillaryType.toLowerCase()}
                  </span>
                </td>
                <td className='text-end'>
                  <span className='text-dark fw-bolder d-block fs-6'>{obj?.totalOrder}</span>
                  <span className='text-muted fw-bold text-muted d-block fs-7'>Orders</span>
                </td>
                <td className='text-end'>
                  <span className='text-dark fw-bolder d-block fs-6'>
                    ₹{formatNumberForCurrency(obj?.totalAmount)}
                  </span>
                  <span className='text-muted fw-bold text-muted d-block fs-7'>Revenue</span>
                </td>
                <td className='text-end'>
                  <span className='text-dark fw-bolder d-block fs-6'>{obj?.totalTrip}</span>
                  <span className='text-muted fw-bold text-muted d-block fs-7'>Trips captured</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <OANoData icon='/media/icons/duotune/general/gen005.svg' />
      )}
    </>
  );
};
