import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { DatePicker, DateRange, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import moment, { Moment } from 'moment';

import OrderContext from '../../../context/OrderContext';
import { OA_DATE_FORMAT_FE_BASIC } from '../../../../../constants';
import { IOrderType } from '../../../types';
import OARangePicker from '../../../../../components/OARangePicker';

interface ITravelDateFormModal {
  show: boolean;
  onHide: () => void;
}

const initRange = {
  from: null,
  to: null
};

export default function TravelDateFormModal(props: ITravelDateFormModal) {
  const { onHide, ...rest } = props;
  const params = useParams<{ orderType: IOrderType; id?: string }>();

  const { orderData, formState, onEditDraftOrder, getOrderById, onEditOrderInfo } =
    useContext(OrderContext);

  const [values, setValues] = useState<{ from: Date | null; to?: Date | null }>(initRange);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dateError, setdateError] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (formState?.tripDuration) {
      const { from, to } = formState?.tripDuration;
      setValues({ from, to });
    }
  }, [formState?.tripDuration]);

  const handleSubmit = () => {
    setIsLoading(true);
    const draftValues = {
      tripDuration: {
        from: values?.from ? new Date(values?.from) : null,
        to: values?.to ? new Date(values?.to) : null
      }
    };
    params.orderType === 'visa'
      ? onEditOrderInfo(orderData?.data?.id, params?.orderType as IOrderType, draftValues, _res => {
          getOrderById(params?.orderType as IOrderType, orderData?.data?.id);
          setIsLoading(false);
          props?.onHide();
        })
      : onEditDraftOrder(
          orderData?.data?.id,
          params?.orderType as IOrderType,
          draftValues,
          _res => {
            getOrderById(params?.orderType as IOrderType, orderData?.data?.id);
            setIsLoading(false);
            onHide();
          }
        );
  };

  const manualDateHandler = (startDate: any, endDate: any, type: string) => {
    let durationFrom = moment(startDate)?.diff(moment(), 'days') + 2;
    if (type === 'SDP' && moment(startDate).isValid()) {
      const from = startDate;
      setValues({ from: from as Date });
      setdateError(null);
    } else {
      setdateError(1);
    }
    if (
      durationFrom >= 180 &&
      params?.orderType === 'insurance' &&
      orderData?.data?.supplierOrganizationCode === 'SUP005'
    ) {
      setdateError(4);
    } else {
      setdateError(null);
    }
  };

  const onChangeRangePicker = (
    range: DateRange<Moment | Date>,
    minDate: Moment | Date | undefined
  ) => {
    const fromDate = moment(range?.[0]);
    const toDate = moment(range?.[1]);
    const durationFrom = fromDate.diff(moment(), 'days') + 2;

    if (fromDate.isValid() && toDate.isValid()) {
      setValues({ from: fromDate.toDate(), to: toDate.toDate() });
    } else {
      setdateError(null);
    }

    if (
      durationFrom >= 180 &&
      params?.orderType === 'insurance' &&
      orderData?.data?.supplierOrganizationCode === 'SUP005'
    ) {
      setdateError(4);
    } else if (toDate < fromDate) {
      setdateError(2);
    } else if (fromDate < moment(minDate) && params?.orderType === 'visa') {
      setdateError(3);
    } else {
      setdateError(null);
    }
  };

  const onCancel = () => {
    if (formState?.tripDuration) {
      setValues({ from: formState?.tripDuration?.from, to: formState?.tripDuration?.to });
    }
    onHide();
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && open === true) {
      // Close the date picker popup
      setOpen(false);
    }

    if (event.key === 'Enter' && open === false) {
      // Open the date picker popup
      setOpen(true);
    }
  };

  return (
    <Modal centered onHide={onCancel} {...rest}>
      <Modal.Header closeButton>
        <Modal.Title>Change travel date</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className='mb-10'>
          {formState?.tripDuration?.from && !formState?.tripDuration?.to ? (
            <Col>
              <section className='oa-datepicker-field d-flex flex-column'>
                <label htmlFor='Travel Date' className='form-label required'>
                  Travel Date
                </label>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    open={open}
                    onClose={() => setOpen(false)}
                    value={moment(values?.from)}
                    onChange={val => manualDateHandler(val, null, 'SDP')}
                    format={OA_DATE_FORMAT_FE_BASIC}
                    slotProps={{
                      textField: {
                        size: 'small',
                        onClick: () => setOpen(true),
                        onKeyDown: handleKeyPress
                      }
                    }}
                  />
                </LocalizationProvider>
              </section>
            </Col>
          ) : (
            <Col>
              <label htmlFor='tripDuration' className='form-label required'>
                Travel Duration
              </label>
              <OARangePicker
                name='travel-info-data-range'
                onChangeRangePicker={onChangeRangePicker}
                dateRange={[values?.from as Date, values?.to as Date]}
                minDate={moment().add(3, 'days')}
              />
            </Col>
          )}
          {dateError === 1 && (
            <div className='text-danger'>Travel Duration is a required field</div>
          )}
          {dateError === 2 && (
            <div className='text-danger'>End Date must be greater than Start date</div>
          )}
          {dateError === 3 && (
            <div className='text-danger'>
              The dates you selected is past the visa application deadline.
            </div>
          )}
          {dateError === 4 && (
            <div className='text-danger'>
              The start Date should be below 180 days from the current date.
            </div>
          )}
        </Row>
        <div className='d-flex justify-content-end'>
          <Button
            size='sm'
            onClick={onCancel}
            disabled={isLoading}
            className='btn-light-primary btn-active-light-primary me-3'>
            Cancel
          </Button>
          <Button size='sm' disabled={dateError !== null} onClick={handleSubmit}>
            {isLoading ? (
              <React.Fragment>
                Saving... <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </React.Fragment>
            ) : (
              'Save'
            )}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
