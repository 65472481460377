import React from 'react';
import '../../_metronic/assets/sass/style.scss';
export default function BadgeSVG(props: any) {
  return (
    <svg
      className={'badgeIcon'}
      width='13'
      height='14'
      viewBox='0 0 13 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M0 7C0 3.41 2.91 0.5 6.5 0.5C10.09 0.5 13 3.41 13 7C13 10.59 10.09 13.5 6.5 13.5C2.91 13.5 0 10.59 0 7ZM6.5 4.5C6.63261 4.5 6.75979 4.55268 6.85355 4.64645C6.94732 4.74022 7 4.86739 7 5V7.5C7 7.63261 6.94732 7.75979 6.85355 7.85355C6.75979 7.94732 6.63261 8 6.5 8C6.36739 8 6.24022 7.94732 6.14645 7.85355C6.05268 7.75979 6 7.63261 6 7.5V5C6 4.86739 6.05268 4.74022 6.14645 4.64645C6.24022 4.55268 6.36739 4.5 6.5 4.5ZM6.5 10C6.63261 10 6.75979 9.94732 6.85355 9.85355C6.94732 9.75979 7 9.63261 7 9.5C7 9.36739 6.94732 9.24022 6.85355 9.14645C6.75979 9.05268 6.63261 9 6.5 9C6.36739 9 6.24022 9.05268 6.14645 9.14645C6.05268 9.24022 6 9.36739 6 9.5C6 9.63261 6.05268 9.75979 6.14645 9.85355C6.24022 9.94732 6.36739 10 6.5 10Z'
        fill='#3A86FF'
      />
    </svg>
  );
}
