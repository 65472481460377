import { Row, Col } from 'react-bootstrap';
import { KTCard, KTCardBody } from '../../../_metronic/helpers';
import OALabelValue from '../../../components/widgets/OALabelValue';
import { getFromAndTo } from '../../../util';

const BasicDetails = ({ data }: { data: any; isLoading: boolean }) => {
  return (
    <KTCard rounded='rounded' className='mt-6'>
      <div className='border-bottom border-gray-200'>
        <h3 className='fw-bolder text-dark ms-7 mt-5 mb-5'>Overview</h3>
      </div>
      <KTCardBody>
        <Row>
          <Col md='4' className='mb-6'>
            <OALabelValue label={'From-to'} value={getFromAndTo(data?.groupOrder?.trip) || ''} />
          </Col>
          <Col md='3' className='mb-6'>
            <OALabelValue label={'Purpose'} value={data?.groupOrder?.trip?.visaType} />
          </Col>
          <Col md='3' className='mb-6'>
            <OALabelValue label={'Supplier'} value={data?.groupOrder?.supplierOrganization?.name} />
          </Col>
          <Col className='mb-6'>
            <OALabelValue
              label={'Visa validity'}
              value={data?.groupOrder?.product?.productInformation?.validity || 'N/A'}
            />
          </Col>
        </Row>
      </KTCardBody>
    </KTCard>
  );
};

export default BasicDetails;
