import SecureLS from 'secure-ls';
import { Location } from 'react-router-dom';
import { DateRange } from '@mui/x-date-pickers-pro';
import moment, { Moment } from 'moment';

import { IOrderType } from '../pages/order-management/types';
import { setDateFormat, getDateFormat } from './dateFormat';
import { encryptStorage } from './storage';
import { OA_DATE_FORMAT_BE } from '../constants';

const iso3311a2 = require('iso-3166-1-alpha-2');

const getPrimaryMemberFirst: (members: Array<any>) => Array<any> = (members: Array<any>) => {
  const primaryMember = members?.find(obj => obj?.primary);
  const rest = members?.filter(obj => !obj?.primary);
  const draftMembers = [primaryMember, ...rest];

  return draftMembers;
};

function downloadFile(link: string) {
  var element = document.createElement('a');
  element.setAttribute('href', link);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function openInNewTab(url: string) {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href: url
  }).click();
}

const getCurrencySymbol = (currency: string) => {
  let draft = '';
  switch (currency) {
    case 'INR':
      draft = '₹';
      break;
    case 'USD':
      draft = '$';
      break;
    default:
      draft = '₹';
      break;
  }
  return draft;
};

const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname;

const getAncillaryNameByOrderType = (orderType: IOrderType) => {
  switch (orderType) {
    case 'visa':
      return 'Visa';
    case 'insurance':
      return 'Insurance';
    default:
      return '';
  }
};
const clearWhiteSpaces = (destinationCountry: any) => {
  let countries = ['VN'];
  let match = countries.find(c => {
    return destinationCountry === c;
  });
  destinationCountry =
    destinationCountry === match
      ? iso3311a2
          .getCountry(destinationCountry)
          .split(' ')
          ?.map((s: string, i: number) => (i !== 0 ? s?.toLowerCase() : s))
          ?.join('')
      : iso3311a2.getCountry(destinationCountry);
  return destinationCountry;
};

// convert first letter of a string to uppercase
const capitalizeFirstLetterOnly = (str: string) => {
  if (str) {
    const normalize = str.toLowerCase();
    // converting first letter to uppercase
    const capitalized = normalize.charAt(0).toUpperCase() + normalize.slice(1);
    return capitalized;
  } else return;
};

var secureLS = new SecureLS();

const numberWithCommas = (x: number) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const getCountryName = (countryCode: string) => {
  if (countryCode) return iso3311a2.getCountry(countryCode);
  else return 'N/A';
};

const removeChar = (str: string) => str?.replace(/[a-zA-Z]/g, '');

const getStorageOrgnizationId = () => {
  const orgnizationId = encryptStorage.getItem('oa_orgnization_id');
  if (orgnizationId) {
    if (orgnizationId === 'all') return '';
    else return orgnizationId;
  } else return '';
};

export const stateAbbr: any = {
  'andhra pradesh': 'AP',
  'arunachal pradesh': 'AR',
  'andaman and nicobar islands': 'AN',
  'assam': 'AS',
  'bihar': 'BR',
  'chhattisgarh': 'CG',
  'chandigarh': 'CH',
  'goa': 'GA',
  'gujarat': 'GJ',
  'dadra and nagar haveli': 'DH',
  'delhi': 'DL',
  'haryana': 'HR',
  'himachal pradesh': 'HP',
  'jharkhand': 'JH',
  'jammu and kashmir': 'JK',
  'karnataka': 'KA',
  'kerala': 'KL',
  'lakshadweep': 'LD',
  'madhya pradesh': 'MP',
  'maharashtra': 'MH',
  'manipur': 'MN',
  'meghalaya': 'ML',
  'mizoram': 'MZ',
  'nagaland': 'NL',
  'odisha': 'OR',
  'punjab': 'PB',
  'puducherry': 'PY',
  'rajasthan': 'RJ',
  'sikkim': 'SK',
  'tamil nadu': 'TN',
  'telangana': 'TG',
  'tripura': 'TR',
  'uttar pradesh': 'UP',
  'uttarakhand': 'UT',
  'west bengal': 'WB'
};

const getStateAbbr = (stateName: any) => {
  return stateAbbr[stateName.toLowerCase()] || 'Invalid state name';
};

// Function to convert a URL to a File object
const toFile = async (url: any, filename: any) => {
  // Fetch the file from the URL
  const response = await fetch(url);
  // Convert the response to a Blob object
  const blob = await response.blob();
  // Create a File object from the Blob with the specified filename and content type
  return new File([blob], filename, { type: blob.type });
};

const arrayToCsvBlob = (data: any) => {
  let csvStr = data
    .map((row: any) => {
      const csvColumns = row.map((value: any) => {
        if (typeof value === 'string' && value.includes(',')) {
          // Value contains a comma, enclose it in double quotes
          return `"${value}"`;
        }
        return value;
      });
      return csvColumns.join(',');
    })
    .join('\n');

  return new Blob([csvStr], { type: 'text/csv' });
};

const getFormattedAddress = (obj: {
  line1: string;
  city: string;
  state: string;
  postalCode: string;
  countryCode: string;
}) => {
  const { line1, city, state, postalCode, countryCode } = obj;
  return `${line1}, ${city}, ${state}, ${postalCode}, ${countryCode}`;
};

export const areDatesSame = (date1: moment.Moment, date2: moment.Moment): boolean => {
  const draftDate1 = moment(date1).format(OA_DATE_FORMAT_BE);
  const draftDate2 = moment(date2).format(OA_DATE_FORMAT_BE);
  return moment(draftDate1).isSame(draftDate2, 'day');
};

export const generateRange = (
  subtraction: string | number,
  unit?: moment.unitOfTime.DurationConstructor
) => {
  const period = moment().subtract(subtraction, unit);
  return unit
    ? ([moment(period.startOf(unit)), moment(period.endOf(unit))] as DateRange<Moment>)
    : ([period, period] as DateRange<Moment>);
};

export function openPathInNewTab(path: string, location: Location) {
  const fullPath = new URL(path, window.location.origin + location.pathname).href;
  window.open(fullPath, '_blank');
}

export function handleSmoothScroll(ref: React.RefObject<HTMLHeadingElement>) {
  ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
}
/**
 * NOTE: This function will remove all the empty values from any nested object.
 * - This function uses recursion to delete items from nested objects
 * @param obj
 * @returns
 */
export const removeEmpty = (obj: any) => {
  let draft = Object.assign({}, obj);
  Object.entries(draft).forEach(
    ([key, val]) =>
      (val && typeof val === 'object' && removeEmpty(val)) ||
      ((val === null || val === '') && delete draft[key])
  );
  return draft;
};

function formatWordWithUnderscore(str: string) {
  return str
    .split('_') // Split by underscore
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter and make rest lowercase
    .join(' '); // Join words with spaces
}

export {
  setDateFormat,
  getDateFormat,
  getPrimaryMemberFirst,
  downloadFile,
  getCurrencySymbol,
  toAbsoluteUrl,
  getAncillaryNameByOrderType,
  clearWhiteSpaces,
  capitalizeFirstLetterOnly,
  numberWithCommas,
  getCountryName,
  removeChar,
  secureLS,
  getStorageOrgnizationId,
  getStateAbbr,
  toFile,
  arrayToCsvBlob,
  getFormattedAddress,
  formatWordWithUnderscore
};

// Converts bytes to kilobytes, megabytes, or gigabytes.
export { default as bytesToSize } from './bytesToSize';
export { default as showDialog } from './showDialog';
export { default as formatNumberForCurrency } from './formatNumber';
export { default as getFileExtensionFromMimeType } from './getFileExtensionFromMimeType';
export { default as showToast } from './showToast';
export { default as getValueByKey } from './getValueByKey';
export { default as getFormattedDate } from './getFormattedDate';
export { default as getMomentObject } from './getMomentObject';
export { default as getFormattedDateRange } from './getFormattedDateRange';
export * from './extractNonZipFilesFromZip';
export * from './getAgeInYearsOrMonths';
export * from './getStatusConfig';
export * from './getByOrderStatus';
export * from './getSupplierOrganizationName';
export * from './getFileExtension';
export * from './isAlsaffronOrg';
