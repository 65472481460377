import { FC } from 'react';

const OaSpinner: FC<any> = () => {
  return (
    <div className='w-100 text-center py-4'>
      <span className='spinner-border spinner-border-sm align-middle me-2'></span>
      <span className='text-muted d-block'>Please wait...</span>
    </div>
  );
};

export default OaSpinner;
