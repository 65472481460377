import { OATypography } from '../../widgets';

interface IExemptions {
  exemptions: string[];
}

const Exemptions: React.FC<IExemptions> = ({ exemptions }) => {
  if (!exemptions || exemptions?.length === 0) {
    return null;
  }
  return (
    <ul>
      {exemptions?.length > 0
        ? exemptions?.map((item: any, index: number) => (
            <li key={index} className='mb-3'>
              <OATypography variant='body2' sx={{ color: theme => theme?.palette?.grey?.[400] }}>
                {item}
              </OATypography>
            </li>
          ))
        : null}
    </ul>
  );
};

export default Exemptions;
