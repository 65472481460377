import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import OrderActivity from '../order-view/OrderTabs/ActivityLog';
import BasicDetails from './BasicDetails';
import GroupOrderList from './GroupOrderList';
import { IOrderType } from '../types';
import { useOrderContext } from '../context/OrderContext';
import GroupOrderView from './GroupOrderView';
import { useAppContext } from '../../../context/AppContext';

interface IGroupViewQueryParams {
  data: any;
}
const GroupView = (props: IGroupViewQueryParams) => {
  const params = useParams<{ orderType: IOrderType; id?: string }>();
  const navigate = useNavigate();
  const { dataExchange } = useAppContext();

  const { formState, orderData, getGroupById, onRemoveConsulate, fetchActivityLogs } =
    useOrderContext();

  useEffect(() => {
    if (params?.orderType && params?.id) {
      getGroupById(params?.orderType as IOrderType, params?.id);
      // TODO : Temp implimetation - we can't change url as of now our notification work
      localStorage.setItem('isGroupTab', 'true');
    }
  }, [params?.id]);

  useEffect(() => {
    history.pushState({}, '', '');
    const handleBackButtonGroup = () => navigate(`/apps/orders/${params?.orderType}`);
    formState?.consulate && onRemoveConsulate();
    window.addEventListener('popstate', handleBackButtonGroup); //add the button to detect the backbuttonClick
    return () => {
      window.removeEventListener('popstate', handleBackButtonGroup); //remove the eventListner on unmount
    };
  }, []);

  useEffect(() => {
    fetchActivityLogs(params?.id as string, true);
  }, []);

  return (
    <>
      <Row>
        <Col>
          <GroupOrderView />
        </Col>
      </Row>
      <Row className='d-flex'>
        <Col md='6'>
          <BasicDetails {...orderData} />
          <GroupOrderList {...orderData} queryData={props} />
        </Col>
        <Col md='6' className='mt-6'>
          <OrderActivity orderData={orderData} dataExchange={dataExchange} isGroup={true} />
        </Col>
      </Row>
    </>
  );
};

export default GroupView;
