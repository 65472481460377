import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { ErrorMessage, Field, FieldProps, FormikProps } from 'formik';
import classNames from 'classnames';
import moment from 'moment';

interface TimePickerFieldProps {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  minTime?: Date;
  maxTime?: Date;
}

export default function OATimePicker(props: TimePickerFieldProps) {
  const { name, label, disabled = false } = props;

  const labelClassNames = classNames('form-label', {
    required: !!props?.required
  });

  return (
    <section className='oa-time-picker'>
      <label className={labelClassNames}>{label}</label>
      <Field name={name}>
        {({ field, form }: FieldProps<any>) => {
          const { setFieldValue } = form as FormikProps<any>;

          const handleChange = (time: Date | null) => {
            setFieldValue(name, time);
          };

          let draftProps: any = {
            onChange: handleChange
          };
          if (field?.value) {
            draftProps['value'] = field?.value;
          }
          if (props?.minTime) {
            draftProps['minTime'] = moment(props?.minTime);
          }
          if (props?.maxTime) {
            draftProps['maxTime'] = moment(props?.maxTime);
          }

          return (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                slotProps={{
                  textField: {
                    size: 'small'
                  }
                }}
                {...draftProps}
                disabled={disabled}
              />
            </LocalizationProvider>
          );
        }}
      </Field>
      <div className='text-danger fw-bold fs-9'>
        <ErrorMessage name={name} />
      </div>
    </section>
  );
}
