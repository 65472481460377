import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

import { useAuth } from '../auth';
import { showDialog } from '../helper';

const modeOptions: Array<any> = [
  { value: 'test', label: 'Test Mode', color: '#FFC400' },
  { value: 'live', label: 'Live Mode', color: '#36B37E' }
];

const dot = (color = 'transparent') => ({
  'alignItems': 'center',
  'display': 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10
  }
});

const ModeSwitch = (props: { mode: string }) => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const [curMode, setCurMode] = useState<string>();

  useEffect(() => {
    const draftModeOption = modeOptions?.find(obj => obj?.value === props?.mode);
    setCurMode(draftModeOption!);
  }, [props?.mode]);

  const modeStyles: any = {
    singleValue: (styles: any, { data }: any) => ({
      ...styles,
      ...dot(data.color)
    }),
    control: (styles: any) => ({
      ...styles,
      ...{
        'background': '#F6F8FA',
        'minHeight': '30px',
        'paddingRight': '5px',
        'borderRadius': '8px',
        'borderWidth': 0,
        ':focus': {
          borderWidth: 0
        },
        ':hover': {
          borderColor: 'transparent',
          boxShadow: 'none'
        }
      }
    }),
    indicatorSeparator: (styles: any) => (styles: any) => ({
      ...styles,
      ...{
        display: 'none'
      }
    }),
    dropdownIndicator: (styles: any) => (styles: any) => ({
      ...styles,
      ...{
        padding: 0,
        marginLeft: '-7px',
        color: '#B5B5C2'
      }
    })
  };

  const handleChange = (option: any) => {
    if (option?.value === 'live') {
      if (currentUser?.data?.organization?.liveModeEnabled === true) {
        localStorage.setItem('oaMode', option?.value);
        navigate(`/dashboard`);
        window.location.reload();
      } else {
        showDialog({
          title: 'Activation Required',
          text: 'You can only use OnArrival in test mode until your account is activated.',
          icon: 'warning'
        }).then(result => {
          if (result?.isConfirmed) {
            navigate(`/dashboard`);
            window.location.reload();
          }
        });
      }
    } else {
      localStorage.setItem('oaMode', option?.value);
      navigate(`/dashboard`);
      window.location.reload();
    }
  };

  return (
    <div className='select-mode'>
      <Select
        onChange={handleChange}
        value={curMode}
        options={modeOptions}
        styles={modeStyles}
        isSearchable={false}
      />
    </div>
  );
};

export default ModeSwitch;
