import { Box } from '@mui/material';
import { formatNumberForCurrency } from '../../helper';

const AmountCell = ({ data }: { data: number }) => {
  return (
    <Box sx={{ marginLeft: '24px' }}>
      {data || data === 0 ? formatNumberForCurrency(data) : 'N/A'}
    </Box>
  );
};

export default AmountCell;
