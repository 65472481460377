import { ILabelValuePair } from '../types';
export { default as OA_STATUS } from './oaStatus';
/**
 * @deprecated
 */
export const DATE_FORMAT_BE = 'yyyy-MM-dd';
/**
 * @deprecated
 */
export const DATE_FORMAT_FE = 'dd/MM/yyyy';
/**
 * @deprecated
 */
export const DATE_FORMAT_DISPLAY_FE = 'dd LLL, yyyy';
/**
 * @deprecated
 */
export const DATE_TIME_FORMAT_DISPLAY_FE = 'dd MMM, yyyy hh:mm a';
/**
 * @deprecated
 */
export const MOMENT_DATE_FORMAT_FE = 'DD MMM, YYYY';

export const OA_DATE_FORMAT_FE = 'DD MMM, YYYY';
export const OA_DATE_TIME_FORMAT_FE = 'DD MMM, YYYY, hh:mm a';
export const OA_DATE_FORMAT_DOB_FE = 'DD/MM/YYYY';
export const OA_DATE_FORMAT_FE_BASIC = 'DD/MM/YYYY';
export const OA_DATE_FORMAT_FE_BASIC_II = 'DD-MM-YYYY';
export const OA_DATE_FORMAT_BE = 'YYYY-MM-DD';
export const OA_TIME_FORMAT = 'HH:mm:ss';
export const OA_SHORT_TIME_FORMAT_FE = 'h:mm A';
export const OA_TIME_FORMAT_MH = 'HH:mm';

export const ORG_TYPES: Array<ILabelValuePair> = [
  { value: 'CONSUMER', label: 'Consumer' },
  { value: 'SUPPLIER', label: 'Supplier' }
];

export const ANCILLARY_TYPES: Array<ILabelValuePair> = [
  { value: 'EVISA', label: 'EVISA' },
  { value: 'INSURANCE', label: 'INSURANCE' }
];

export const ANCILLARY_TYPES_MAP = {
  EVISA: 'EVISA',
  INSURANCE: 'INSURANCE'
};

export const AVATAR_COLORS = [
  '#0BB783',
  '#4848C9',
  '#FFA800',
  '#EF6327',
  '#011229',
  '#A84D12',
  '#F1416C',
  '#A78611',
  '#BB0BCD'
];

export const ORDER_EVENT_TYPE_OPTIONS = [
  { label: 'Embassy Appointment', value: 'EMBASSY_APPOINTMENT' },
  { label: 'Visa Application centre', value: 'VISA_APPLICATION_CENTRE' },
  { label: 'Others', value: 'OTHERS' }
];

export const CLAIM_STATUS = [
  {
    label: 'Active',
    value: 'ACTIVE',
    type: 'primary'
  },
  {
    label: 'Settled',
    value: 'SETTLED',
    type: 'success'
  },
  {
    label: 'Bank Updated',
    value: 'BANK_UPDATED',
    type: 'primary'
  },
  {
    label: 'Payment Initiated',
    value: 'PAYMENT_INITIATED',
    type: 'primary'
  },
  {
    label: 'Payment in progress',
    value: 'PAYMENT_IN_PROGRESS',
    type: 'info'
  },
  {
    label: 'Payment Completed',
    value: 'PAYMENT_COMPLETED',
    type: 'success'
  },
  {
    label: 'Payment Failed',
    value: 'PAYMENT_FAILED',
    type: 'danger'
  },
  {
    label: 'Claim cancelled',
    value: 'CLAIM_CANCELLED',
    type: 'danger'
  },
  {
    label: 'Settlement Not Required',
    value: 'SETTLEMENT_NOT_REQUIRED',
    type: 'success'
  }
];

export const FLIGHT_STATUS = [
  {
    label: 'Booking confirmed',
    value: 'BOOKING_CONFIRMED',
    type: 'success'
  },
  {
    label: 'Processing offline',
    value: 'PROCESSING_OFFLINE',
    type: 'primary'
  },
  {
    label: 'In Progress',
    value: 'IN_PROGRESS',
    type: 'info'
  },
  {
    label: 'Payment Failed',
    value: 'PAYMENT_FAILED',
    type: 'danger'
  },
  {
    label: 'Cancelled',
    value: 'CANCELLED',
    type: 'danger'
  },
  {
    label: 'Refund in progress',
    value: 'REFUND_IN_PROGRESS',
    type: 'info'
  },
  {
    label: 'Cancel failed',
    value: 'CANCEL_FAILED',
    type: 'danger'
  },
  {
    label: 'Booking failed',
    value: 'BOOKING_FAILED',
    type: 'danger'
  },
  {
    label: 'Booking in progress',
    value: 'BOOKING_IN_PROGRESS',
    type: 'primary'
  },
  {
    label: 'Itinerary',
    value: 'ITINERARY',
    type: 'primary'
  },
  {
    label: 'Cancellation Requested',
    value: 'CANCELLATION_REQUESTED',
    type: 'danger'
  },
  {
    label: 'Unknown',
    value: 'UNKNOWN',
    type: 'default'
  }
];

export const SUPPLIER_NAME: any = {
  CT: 'Cleartrip',
  TBO: 'TBO'
};
