import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { PropsWithChildren } from 'react';

import { DrawerComponent } from '../../assets/ts/components';

const Content: React.FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    DrawerComponent.hideAll();
  }, [location]);

  return (
    <Container fluid id='kt_content_container'>
      {children}
    </Container>
  );
};

export { Content };
