import React from 'react';

import { IOrgFormValues } from '../OrgForm';
import { IPaginationQuery } from '../types';

export interface IOrgContext {
  organizations: {
    data: Array<any> | null;
    isLoading: boolean;
  };
  suppliers: {
    data: Array<any> | null;
    isLoading: boolean;
  };
  orgQuery: IPaginationQuery;
  pageIndex: number;
  pageCount: number;
  selectedOrg: any;
  fetchOrgs: (
    search?: any,
    orgId?: string,
    cb?: ((response: any) => void) | undefined
  ) => Promise<void>;
  fetchSuppliers: (search?: string, cb?: ((response: any) => void) | undefined) => Promise<void>;
  setOrgQuery: React.Dispatch<React.SetStateAction<IPaginationQuery>>;
  onPageChange: (event: any, value: any) => void;
  onCreateOrg: (
    values: IOrgFormValues,
    cb?: ((response?: any) => void) | undefined
  ) => Promise<void>;
  onSelectOrg: (orgId: string, cb?: () => void) => void;
  resetSelected: () => void;
  onEditOrg: (
    id: string,
    values: IOrgFormValues,
    cb?: ((response?: any) => void) | undefined
  ) => Promise<void>;
  onDeleteOrg: (id: string, cb?: ((success: boolean) => void) | undefined) => Promise<void>;
}

const OrgContext = React.createContext<IOrgContext>({} as IOrgContext);

export default OrgContext;

export const useOrgContext = () => React.useContext(OrgContext);
