import React from 'react';
import Select from 'react-select';
import classNames from 'classnames';

export default function OASelectMultiField(props: any) {
  const {
    label,
    className,
    placeholder,
    field,
    form,
    options,
    required,
    isClearable = true,
    isDisabled = false,
    isMulti = false,
    ...rest
  } = props;

  const onChange = (option: any) => {
    form.setFieldValue(
      field.name,
      option.map((item: { value: any }) => item.value)
    );
  };

  const labelClassNames = classNames('form-label', {
    required: required
  });

  return (
    <React.Fragment>
      <label htmlFor={rest?.id || rest?.name} className={labelClassNames}>
        {label}
      </label>
      <Select
        defaultValue={options?.find((option: any) => option?.value === field?.value)}
        onChange={onChange}
        placeholder={placeholder}
        isClearable={isClearable}
        isDisabled={isDisabled}
        className='oa-select-container'
        classNamePrefix='oa-select'
        options={options}
        isMulti={isMulti}
        value={field?.value ? options?.find((opt: any) => opt?.value === field?.value) : ''}
      />
      {/* TODO: check if this field has been touched or not. don't show error message
       *  unless form has been submitted. */}
      {/**
       * NOTE: Show error message in case of any.
       *
       */}
      <div className='text-danger mt-2'>
        {form.touched.arrival == true ? <p>{form.errors.arrival}</p> : ''}
      </div>
    </React.Fragment>
  );
}
