function numberToWords(number: number): string {
  const units: string[] = [
    'Zero',
    'One',
    'Two',
    'Three',
    'Four',
    'Five',
    'Six',
    'Seven',
    'Eight',
    'Nine',
    'Ten',
    'Eleven',
    'Twelve',
    'Thirteen',
    'Fourteen',
    'Fifteen',
    'Sixteen',
    'Seventeen',
    'Eighteen',
    'Nineteen'
  ];

  const tens: string[] = [
    '',
    '',
    'Twenty',
    'Thirty',
    'Forty',
    'Fifty',
    'Sixty',
    'Seventy',
    'Eighty',
    'Ninety'
  ];

  if (number === 0) {
    return 'Zero';
  }

  let words: string = '';

  if (number < 0) {
    words += 'minus ';
    number = -number;
  }

  if (Math.floor(number / 10000000) > 0) {
    words += numberToWords(Math.floor(number / 10000000)) + ' Crore ';
    number %= 10000000;
  }

  if (Math.floor(number / 100000) > 0) {
    words += numberToWords(Math.floor(number / 100000)) + ' Lakh ';
    number %= 100000;
  }

  if (Math.floor(number / 1000) > 0) {
    words += numberToWords(Math.floor(number / 1000)) + ' Thousand ';
    number %= 1000;
  }

  if (Math.floor(number / 100) > 0) {
    words += numberToWords(Math.floor(number / 100)) + ' Hundred ';
    number %= 100;
  }

  if (number > 0) {
    if (words !== '') {
      words += 'and ';
    }

    if (number < 20) {
      words += units[number];
    } else {
      words += tens[Math.floor(number / 10)];
      if (number % 10 > 0) {
        words += '-' + units[number % 10];
      }
    }
  }

  return words;
}

export function convertPriceToWords(price: number): string {
  const dollars = Math.floor(price);
  const cents = Math.floor((price - dollars) * 100);
  const dollarsInWords = numberToWords(dollars);
  const centsInWords = numberToWords(cents);

  let result = '';
  if (dollarsInWords !== 'Zero') {
    result += dollarsInWords + ' Rupees';
  }

  if (centsInWords !== 'Zero') {
    result += ' and ' + centsInWords + ' Paise';
  }

  return `${result.trim()} Only`;
}
