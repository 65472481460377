import classNames from 'classnames';
import { Badge } from 'react-bootstrap';
import { OATypography } from '../widgets';

interface IOrderFilterNavItem {
  label: string;
  value: string;
  orderStatus: string;
  count: number;
  onClick: () => void;
  isLoading: boolean;
}

const OrderFilterNavItem = (props: IOrderFilterNavItem) => {
  const { label, value, orderStatus, count, onClick } = props;
  const anchorClassNames = classNames('nav-link justify-content-center text-active-gray-800', {
    active: orderStatus === value
  });
  const badgeClassNames = classNames('min-w-35px text-center d-inline fw-normal fs-8', {
    'badge-primary': orderStatus === value,
    'badge-secondary': orderStatus !== value
  });

  return (
    <li className='nav-item' role='presentation'>
      <a className={anchorClassNames} onClick={onClick}>
        <OATypography
          sx={{ fontWeight: 500, fontSize: '0.95rem', marginRight: '3.25px' }}
          className='me-1 fw-bold fs-7'>
          {label}
        </OATypography>
        <Badge
          className={badgeClassNames}
          title={count ? count.toString() : ''}
          pill
          bg={orderStatus === value ? 'primary' : 'secondary'}>
          {count ? (count > 999 ? '999+' : count) : 0}
        </Badge>
      </a>
    </li>
  );
};

export default OrderFilterNavItem;
