import { Link, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { PremiumSVG, WarningSVG } from '../../../../components/Icons';
import { ORDER_TYPE } from '../../constants';
import { IOrderType } from '../../types';

interface IOrderTabs {
  isOrderdataFetched: boolean;
  shouldSubmit: boolean;
  pendingActionCount: number | string;
  isReviewrequired: boolean;
  isOfflineOrder: boolean;
  className?: string;
  isConsumer?: boolean;
}

const OrderTabs = (props: IOrderTabs) => {
  const { isOfflineOrder, shouldSubmit, isOrderdataFetched } = props;

  const params = useParams<{ orderType: IOrderType; id?: string }>();
  const location = useLocation();

  const isVisa = params?.orderType === ORDER_TYPE.VISA;
  const isInsurance = params?.orderType === ORDER_TYPE.INSURANCE;
  const hasTravellerWarning = isVisa && !shouldSubmit && isOrderdataFetched;

  const getClassNames = (pathValue: string) => {
    return classNames('nav-link text-active-primary me-4', {
      active: location?.pathname?.includes(pathValue)
    });
  };

  const getListItem = (
    title: string,
    to: string,
    icon?: JSX.Element,
    iconPosition?: 'pre' | 'post'
  ) => (
    <li className='nav-item'>
      <Link to={`${to}${location?.search}`} className={getClassNames(to)}>
        {icon && iconPosition === 'pre' && icon}
        <span>{title}</span>
        {icon && iconPosition === 'post' && icon}
      </Link>
    </li>
  );

  const WarningIcon = () => <WarningSVG className='ms-1' height='16' width='16' />;
  const TIWarning = () => (hasTravellerWarning ? <WarningIcon /> : null);

  return (
    <div
      id='orderTabForScroll'
      className={classNames('d-flex overflow-auto', props?.className ?? '')}>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-bolder flex-nowrap'>
        {getListItem('Activity log', 'activity-log')}
        {getListItem('Travel details', 'travel-details')}
        {getListItem('Traveller info', 'traveller-info', <TIWarning />, 'post')}
        {getListItem('Payment summary', 'payment-summary')}
        {isInsurance && getListItem('Insurance summary', 'insurance-summary')}
        {isInsurance && getListItem('Insurance status', 'visa-status')}
        {isVisa && getListItem('Visa summary', 'visa-summary')}
        {isVisa && isOfflineOrder && getListItem('Visa status', 'visa-status')}
        {isVisa && getListItem('Events', 'order-events')}
        {isVisa && getListItem('DocuCraft', 'creator-tools', <PremiumSVG />, 'pre')}
      </ul>
    </div>
  );
};

export default OrderTabs;

export { default as TravelDetails } from './TravelDetails';
export { default as TravellerInfo } from './TravellerInfo';
export { default as PaymentSummary } from './PaymentSummary';
export { default as VisaStatus } from './VisaStatus';
export { default as VisaSummary } from './VisaSummary';
export { default as InsuranceSummary } from './InsuranceSummary';
export { default as OrderEvents } from './OrderEvents';
