import React, { forwardRef } from 'react';
import { Box } from '@mui/material';
import { OATypography } from '../widgets';

interface IOAVisaSummary {
  accordionConfig: any;
  selectedVisaInformation: any;
  sectionRefs?: any;
}

const OAVisaSummary = forwardRef((props: IOAVisaSummary) => {
  let refIndex = 0;
  const createAccordionItem = (
    idx: number,
    condition: boolean,
    component: React.ElementType,
    data: any,
    header: string
  ) => {
    if (condition) {
      const currentIndex = refIndex; // Capture the current index
      refIndex++; // Increment ref index only when a ref is assigned

      return (
        <Box
          ref={el =>
            props.sectionRefs &&
            props.sectionRefs.current &&
            (props.sectionRefs.current[currentIndex] = el)
          }
          key={currentIndex}
          sx={{ p: '16px', borderBottom: '1px solid #e8e8e8' }}>
          <OATypography variant='h4' sx={{ fontWeight: '500' }} className='mb-5'>
            {header}
          </OATypography>
          {React.createElement(component, { ...data })}
        </Box>
      );
    }
    return null;
  };

  return (
    <section>
      {props.selectedVisaInformation?.introductionOfVisa && (
        <OATypography variant='body2' sx={{ p: '16px', color: theme => theme.palette.grey[400] }}>
          {props.selectedVisaInformation.introductionOfVisa}
        </OATypography>
      )}
      {props?.accordionConfig?.map((item: any, index: number) =>
        createAccordionItem(index, item.check, item.component, item.data ?? '', item.header)
      )}
    </section>
  );
});

OAVisaSummary.displayName = 'OAVisaSummary';

export default OAVisaSummary;
