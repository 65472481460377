import { IconType } from '.';

export default function PremiumSVG(props: IconType) {
  const { width = '13', height = '13', className = '' } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 13 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}>
      <path
        d='M4.98398 4.46875L6.4194 1.625H6.5819L8.01732 4.46875H4.98398ZM6.0944 10.8875L1.42253 5.28125H6.0944V10.8875ZM6.9069 10.8875V5.28125H11.5788L6.9069 10.8875ZM8.91107 4.46875L7.50273 1.625H10.2923L11.7142 4.46875H8.91107ZM1.28711 4.46875L2.70898 1.625H5.49857L4.09023 4.46875H1.28711Z'
        fill='#FF9933'
      />
    </svg>
  );
}
