import { FC } from 'react';
import { useAuth } from '../../../auth';
import { isAlsaffronOrg } from '../../../helper';

const Footer: FC = () => {
  const { currentUser } = useAuth();

  const isAlsaffron = isAlsaffronOrg(currentUser);

  return (
    <div className='footer py-4 d-flex mt-9 align-items-center justify-content-center'>
      <div>
        <span className='text-muted fw-bold me-2'>{new Date().getFullYear()} &copy;</span>
        <a
          href={isAlsaffron ? '#' : 'https://onarrival.travel/'}
          target='_BLANK'
          rel='noreferrer'
          className='text-gray-800 text-hover-primary'>
          {isAlsaffron
            ? 'Al Saffron Travel & Tourism LLC'
            : 'OnArrival Travel Technology Private Limited.'}{' '}
        </a>
        <span className='text-gray-800'>{!isAlsaffron && 'All rights reserved.'} (v2.2.0)</span>
      </div>
      {!isAlsaffron && (
        <ul className='menu menu-primary fw-bold order-1'>
          <li className='menu-item'>
            <a
              href='/terms-and-conditions'
              target='_BLANK'
              rel='noreferrer'
              className='menu-link pe-1'>
              Terms and Conditions |
            </a>
          </li>
          <li className='menu-item'>
            <a href='/privacy-policy' target='_BLANK' rel='noreferrer' className='menu-link ps-0'>
              Privacy Policy
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};

export { Footer };
