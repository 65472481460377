import React from 'react';
import { ErrorMessage, useField } from 'formik';
import Select, { ActionMeta, OptionProps } from 'react-select';
import classNames from 'classnames';

import { ILabelValuePair } from '../../types';

interface IOAMultiSelectField {
  label: string;
  name: string;
  options: Array<ILabelValuePair> | any;
  required?: boolean;
  valueOnly?: boolean;
  className?: string;
  placeholder?: string;
  isDisabled?: boolean;
}

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    'fontSize': '0.925rem',
    'borderColor': state.isFocused ? 'red' : provided.borderColor,
    '&:hover': {
      borderColor: '#f5f8fa'
    }
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    height: '30px',
    width: '30px',
    padding: '5px'
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontSize: '0.925rem',
    color: '#B5B5C2'
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '0.925rem',
    color: '#616876'
  })
};

export default function OAMultiSelectField(props: IOAMultiSelectField) {
  const { options, required = false, isDisabled = false } = props;

  const [field, _meta, { setValue }] = useField(props?.name);

  const onChange = (option: any, _actionMeta: ActionMeta<OptionProps>) => {
    setValue(option?.map((opt: any) => opt?.value));
  };

  const labelClassNames = classNames('form-label', {
    required: required
  });

  return (
    <React.Fragment>
      <label htmlFor={props?.name} className={labelClassNames}>
        {props?.label}
      </label>
      <Select
        // defaultValue={options?.find((option: any) => option?.value === field?.value)}
        options={options}
        onChange={onChange}
        placeholder={props?.placeholder}
        isDisabled={isDisabled}
        isMulti
        isClearable
        className='oa-select-container'
        classNamePrefix='oa-select'
        value={
          field?.value?.length
            ? options?.filter((opt: any) => field?.value?.includes(opt?.value))
            : ''
        }
        styles={customStyles}
      />
      {/* TODO: check if this field has been touched or not. don't show error message
       *  unless form has been submitted. */}
      {/**
       * NOTE: Show error message in case of any.
       *
       */}
      <div className='text-danger fw-bold fs-9'>
        <ErrorMessage name={props?.name} />
      </div>
    </React.Fragment>
  );
}
