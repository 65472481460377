import React, { useEffect, useRef, useState } from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import classNames from 'classnames';
import moment from 'moment';
import * as Yup from 'yup';

import { handleImageEvents } from '../imageZoom';
import { ICreateOrderFormState, IOrderType } from '../../../../types';
import { useOrderContext } from '../../../../context/OrderContext';
import DocumentUpload from '../DocumentUpload';
import { handleSmoothScroll, showDialog } from '../../../../../../helper';
import { ITraveller } from '../types';
import DocumentUploadGroup from '../DocumentUploadGroup';
import ImageEditorModal from '../../../../../../components/OAImageEditor';
import { optionalFieldsValidationRules, requiredFieldsValidationRules } from '../validationRules';
import { useS3FileUploader } from '../../../../../../hooks/useS3FileOperation';
import showToast from '../../../../../../helper/showToast';
import { getConsulateOptionsForSelect, ocrResponseGenerater } from '../../../../../../util';
import { validateFile } from '../../../../../../helper/validateFile';
import DynamicField from '../DynamicFormRenderer/DynamicField';
import {
  renderContactInformation,
  renderPrimaryInformation,
  renderAdditionalInformation,
  renderPassportForm,
  renderTravelInformation,
  renderTravelDetails
} from '../travelFormRenderer';
import { formatOptionLabel } from '../../../visa/AddTripDetails';
import { ORDER_TYPE, SUPPLIER_CODE } from '../../../../constants';
import { getBookingInfo } from '../../../../helper/getBookingInfo';
import { useAppContext } from '../../../../../../context/AppContext';
import ActiveTravellerBanner from './ActiveTravellerBanner';
import DisplayHeaderMessage from './DisplayHeaderMessage';
import ImageView from './ImageView';
import Banner from './Banner';
import BookingInformationSection from './BookingInformationSection';
import {
  dnataUaeValidationSchema,
  vfsThaiValidationSchema
} from '../../../visa/AddStayDetails/StayDetailsForm';
import OAButton from '../../../../../../components/widgets/OAButton';
import { IDocument } from '../../../../../../types';
import { isAtLeast6monthOld } from '../../../../../../components/CsvManager/helper';

interface ITravellerForm {
  resetFormVal: () => void;
  onHide: () => void;
  button: any;
  isFormDirty: boolean;
  setIsFormDirty: React.Dispatch<React.SetStateAction<boolean>>;
  data?: ITraveller;
  type?: 'single' | 'multi';
  travellerId?: string;
  onCancel?: (cb?: () => void) => void;
}

const inits: ITraveller = {
  title: '',
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  gender: '',
  occupation: '',
  phone: '+91',
  email: '',
  line1: '',
  country: 'IN',
  state: '',
  postalCode: '',
  nationality: 'IN',
  passportIssuingCountry: 'IN',
  passportIssuingPlace: '',
  passportIssuingDate: '',
  passportNumber: '',
  passportExpiration: '',
  birthCountry: '',
  birthPlace: '',
  fatherName: '',
  maritalStatus: '',
  religion: '',
  profession: '',
  motherName: '',
  submitAction: '',
  consulate: '',
  pnrNumber: '',
  ticketNumber: '',
  PassportFrontImage: '',
  PassportBackImage: '',
  PassportBioPage: '',
  PassportLastPage: '',
  originLine1: '',
  originCity: '',
  originState: '',
  originPostalCode: '',
  destinationLine1: '',
  destinationCity: '',
  destinationState: '',
  destinationPostalCode: '',
  arrivalCarrierName: '',
  arrivalCarrierNumber: '',
  arrivalPnrNumber: '',
  arrivalIATA: '',
  departureCarrierName: '',
  departureCarrierNumber: '',
  departurePnrNumber: '',
  departureIATA: '',
  propertyType: '',
  propertyName: '',
  propertyAddress: {
    line1: '',
    district: '',
    subDistrict: '',
    city: '',
    postalCode: ''
  },
  propertyPhone: '',
  previousNationality: '',
  education: '',
  annualIncome: ''
};

export default function TravellerForm(props: ITravellerForm) {
  const { type = 'multi' } = props;
  const params = useParams<{ orderType: IOrderType; id?: string }>();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const { dataExchange, getDataExchangeByType, fetchAirportCodes } = useAppContext();
  const {
    orderData,
    formState,
    travellerId,
    onEditTravellerDetails,
    onEditDraftOrder,
    getOrderById,
    onCreateDraftOrder,
    getOcrResponseByImgUrl,
    onAddConsulate,
    onAddCategory
  } = useOrderContext();

  const [uploadFile] = useS3FileUploader(5, ['jpg', 'png', 'jpeg', 'pdf']);

  const [initValues, setInitValues] = useState<ITraveller>(inits);
  const [isFetchingDataFromApi, setIsFetchingDataFromApi] = useState<boolean>(false);
  const [buttonValue, setButtonValue] = useState<any>(props?.button);
  const [uploadedDoc, setUploadedDoc] = useState<Array<{ fieldName: string; data: string }>>([]);
  const [isEditorOpen, setIsEditorOpen] = useState<boolean>(false);
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);
  const [consulateOption, setConsulateOption] = useState<Array<any>>([]);
  const [submitOnLoad, setsubmitOnLoad] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [selectedImg, setSelectedImg] = useState<any>({
    name: null,
    file: null
  });
  const [imgUrl, setImgUrl] = useState<{ front: any; back: any }>({
    front: null,
    back: null
  });
  const [uploadedPassportFrontImg, setUploadedPassportFrontImg] = useState<any>();
  const [uploadedPassportBackImg, setUploadedPassportBackImg] = useState<any>();
  const [optionsLoading, setOptionsLoading] = useState<boolean>(false);
  const [documents, setDocuments] = useState<Array<any>>([]);

  const personalInfoRef = useRef<HTMLHeadingElement>(null);
  const passportDetailsRef = useRef<HTMLHeadingElement>(null);

  const isBusOrCabInsurance =
    formState?.category === 'BUS_INSURANCE' || formState?.category === 'CAB_INSURANCE';
  const isGroupOrder = formState?.groupName && formState?.groupName !== '';

  const convertToOrder = searchParams.get('convertToOrder');
  const quotationId = searchParams.get('quotationId');

  const showSaveOnly = type === 'single' || !!travellerId;
  /**
   * NOTE: for below two cases we are enabling "Save and add another" button...
   */
  const case_1 = !showSaveOnly && formState?.groupName === '';
  const case_2 = !showSaveOnly && !formState?.groupName && convertToOrder && quotationId;

  // when quote is converted to order checking if category is going null or not
  useEffect(() => {
    if (!formState?.category) {
      onAddCategory(formState?.tripDetails?.category);
    }
  }, []);

  useEffect(() => {
    const imgContainerArray = document.querySelectorAll(
      '.passport-image'
    ) as NodeListOf<HTMLDivElement>;
    handleImageEvents(Array.from(imgContainerArray), true);

    return () => {
      handleImageEvents(Array.from(imgContainerArray), false);
    };
  }, [imgUrl]);

  const handleSelectImage = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const onSelectImage = () => {
      event.preventDefault();

      if (event?.target?.files && event?.target?.files?.length > 0) {
        const file = event?.target?.files?.[0];
        const isFileError = validateFile(file, 5, ['jpg', 'png', 'jpeg']);
        if (isFileError) {
          return toast.error(isFileError);
        } else {
          setSelectedImg({ file, name });
          setIsEditorOpen(true);
        }
      }
      event.target.value = '';
    };

    if (!isEmpty(props?.data)) {
      showDialog({
        title: 'Are you sure?',
        text: 'Traveller information will be overwritten according to passport uploaded',
        icon: 'warning'
      }).then(result => {
        if (result?.isConfirmed) {
          onSelectImage();
        }
      });
    } else {
      onSelectImage();
    }
  };

  useEffect(() => {
    const consulateData =
      orderData?.data?.product?.productInformation?.consulateData ||
      orderData?.data?.groupOrder?.product?.productInformation?.consulateData;
    if (consulateData) setConsulateOption(getConsulateOptionsForSelect(consulateData));
  }, [orderData]);

  useEffect(() => {
    if (imgUrl?.front || imgUrl?.back) {
      setIsFetchingDataFromApi(true);
      getOcrResponseByImgUrl(imgUrl, (res: any) => {
        const passportData = ocrResponseGenerater(res, initValues, formState);
        const draft = Object.assign({}, initValues, passportData);
        if (formState?.consulate) draft['consulate'] = formState?.consulate;
        setInitValues(draft);
        setIsFetchingDataFromApi(false);
        props?.setIsFormDirty(true);
      });
    } else {
      setInitValues(inits);
    }
  }, [imgUrl]);

  useEffect(() => {
    if (!isEmpty(props?.data)) {
      setInitValues(prev => Object.assign({}, prev, props?.data));
    } else {
      setInitValues(inits);
    }
  }, [props?.data]);

  useEffect(() => {
    if (buttonValue !== 1) {
      if (formState?.orderId) {
        onEditDraftOrder(formState?.orderId, params?.orderType as IOrderType, null);
      } else {
        onCreateDraftOrder(params?.orderType as IOrderType);
      }
    }
  }, [buttonValue]);

  const isVfsThai =
    formState?.tripDetails?.supplierOrganization?.organizationCode === SUPPLIER_CODE.VFS &&
    formState?.tripDetails?.destination?.[0]?.countryCode === 'TH';
  const isDnataAe =
    formState?.tripDetails?.supplierOrganization?.organizationCode === SUPPLIER_CODE.DNATA &&
    formState?.tripDetails?.destination?.[0]?.countryCode === 'AE';
  /**
   * NOTE: Fetching data exchange values for education and annual income on the
   * basis of supplier organization code and their availability in the form fields...
   */
  useEffect(() => {
    setOptionsLoading(true);
    let typeArr: string[] = [];
    if (isFieldPresent(formState, 'education')) typeArr = [...typeArr, 'EDUCATION'];
    if (isFieldPresent(formState, 'annualIncome')) typeArr = [...typeArr, 'ANNUAL_INCOME'];
    if (isFieldPresent(formState, 'profession')) typeArr = [...typeArr, 'PROFESSION'];
    if (isFieldPresent(formState, 'religion')) typeArr = [...typeArr, 'RELIGION'];

    let typeStr = typeArr.filter(component => component).join(', ');
    const supplierOrganizationCode = formState?.tripDetails?.supplierOrganization?.organizationCode;
    getDataExchangeByType(typeStr, supplierOrganizationCode, false, _res => {
      setOptionsLoading(false);
    });
    if (isFieldPresent(formState, 'arrivalIATA') || isFieldPresent(formState, 'departureIATA')) {
      fetchAirportCodes(_res => {
        setOptionsLoading(false);
      });
    }
  }, []);

  const uploadImgToS3 = async (file: any) => {
    setIsImageUploading(true);

    const result = await uploadFile(file, 'ocr/frontImage/');

    if (result.uploadedUrl) {
      if (selectedImg?.file) {
        if (selectedImg?.name === 'ocr_front')
          setImgUrl(prev => Object.assign({}, prev, { front: result.uploadedUrl }));
        if (selectedImg?.name === 'ocr_back')
          setImgUrl(prev => Object.assign({}, prev, { back: result.uploadedUrl }));
      }
      setIsEditorOpen(false);
      setIsImageUploading(false);
    } else {
      setIsImageUploading(false);
      if (result?.error) showToast(result?.error, 'error');
    }
  };

  const handleRemoveImage = (type: 'front' | 'back') => {
    setImgUrl(prev => ({
      front: type === 'front' ? null : prev?.front,
      back: type === 'back' ? null : prev?.back
    }));
    if (type === 'front') {
      setUploadedPassportFrontImg(null);
    } else {
      setUploadedPassportBackImg(null);
    }
    setInitValues(inits);
  };

  const convertToDates = (values: ITraveller) => {
    const { dateOfBirth, passportExpiration, passportIssuingDate } = values;
    return {
      ...values,
      dateOfBirth: new Date(dateOfBirth),
      passportExpiration: passportExpiration ? new Date(passportExpiration) : passportExpiration,
      passportIssuingDate: passportIssuingDate ? new Date(passportIssuingDate) : passportIssuingDate
    };
  };

  const getInitDocs = (requiredDocumentKeys: string[], frontImage: string, backImage: string) => {
    let docs: any = {};

    requiredDocumentKeys?.forEach((doc: any) => {
      if (doc?.processedKey?.includes('passportfrontimage')) {
        docs[doc?.originalKey] = { image: frontImage, keyName: doc?.originalKey };
      }
      if (doc?.processedKey?.includes('passportbackimage')) {
        docs[doc?.originalKey] = { image: backImage, keyName: doc?.originalKey };
      }
      if (doc?.processedKey?.includes('passportbiopage')) {
        docs[doc?.originalKey] = { image: backImage, keyName: doc?.originalKey };
      }
      if (doc?.processedKey?.includes('passportlastpage')) {
        docs[doc?.originalKey] = { image: backImage, keyName: doc?.originalKey };
      }
    });

    return docs;
  };

  const handleSubmit = (values: ITraveller, actions: FormikHelpers<ITraveller>) => {
    if (
      formState?.travellerDetails?.length >= 9 &&
      formState?.tripDetails?.supplierOrganization?.organizationCode === 'SUP005'
    ) {
      props?.onHide();
      return showToast('You can process only 9 travellers in an order', 'error');
    }
    const {
      originLine1,
      originCity,
      originState,
      originPostalCode,
      destinationLine1,
      destinationCity,
      destinationState,
      destinationPostalCode,
      ...rest
    } = values;

    if ((isVfsThai || isDnataAe) && isGroupOrder) {
      const bookingInformation = getBookingInfo(values);
      formState.tripDetails.trip.bookingInformation = bookingInformation;
    }

    if (isBusOrCabInsurance) {
      const draftTrip = Object.assign({}, formState?.tripDetails?.trip, {
        origin: {
          line1: originLine1,
          city: originCity,
          state: originState,
          postalCode: originPostalCode,
          countryCode: 'IN'
        },
        destination: [
          {
            line1: destinationLine1,
            city: destinationCity,
            state: destinationState,
            postalCode: destinationPostalCode,
            countryCode: 'IN'
          }
        ]
      });
      formState.tripDetails.trip = draftTrip;
    }

    const transformedValues = convertToDates(rest);

    const requiredDocumentKeys = formState?.tripDetails?.productInformation?.consulateData
      ?.find((obj: any) => obj?.value === formState?.consulate)
      ?.requiredDocuments?.map((obj: IDocument) => ({
        originalKey: obj?.fieldName,
        processedKey: obj?.fieldName?.toLowerCase().replace(/\s+/g, '').trim()
      }));

    const initDocs = getInitDocs(requiredDocumentKeys, imgUrl?.front, imgUrl?.back);

    const draftValues = {
      ...transformedValues,
      ...initDocs,
      id: travellerId ?? `new_member_${Date.now().toString()}`,
      primary: transformedValues?.primary ?? false,
      isNew: !travellerId
    };

    if (uploadedDoc?.length > 0 && formState?.groupName !== '') {
      draftValues.documents = uploadedDoc;
    }

    if (travellerId || props?.travellerId) {
      setsubmitOnLoad(false);
      const draft = { ...props?.data, ...draftValues };
      showDialog({
        title: 'Update traveller?',
        text: 'Are you sure you want to update traveller?',
        icon: 'warning'
      }).then(result => {
        if (result?.isConfirmed) {
          if (props?.type === 'single') {
            let travellerFound = formState?.travellerDetails?.find((mem: any) => {
              return mem?.id === draft?.id ? mem : null;
            });
            let finalDraft = travellerFound ? Object.assign(travellerFound, draft) : draft;
            onEditDraftOrder(
              props?.travellerId ?? orderData?.data?.id,
              params?.orderType as IOrderType,
              { member: finalDraft },
              res => {
                showToast('Traveller updated successfully.', 'success');
                if (props?.travellerId) {
                  navigate(`/apps/orders/visa/${res?.data?.data?.id}/travel-details`);
                } else {
                  getOrderById(params?.orderType as IOrderType, orderData?.data?.id);
                }
                props?.onHide();
                actions?.resetForm();
              }
            );
          } else {
            setButtonValue(0);
            onEditTravellerDetails(draft);
            showToast('Traveller updated successfully.', 'success');
            props?.onHide();
            actions?.resetForm();
          }
        }
      });
    } else {
      if (!isNull(imgUrl?.front) || !isNull(imgUrl?.back)) {
        showDialog({
          title: 'Verify details',
          text: 'Please verify and double check the data retrieved from the uploaded passport',
          icon: 'warning'
        }).then(result => {
          if (result?.isConfirmed) {
            formState.travellerDetails.push(draftValues);
            formState?.orderId
              ? onEditDraftOrder(formState?.orderId, params?.orderType as IOrderType, null, res => {
                  if (res) {
                    setDisableButton(false);
                    actions?.resetForm();
                    if (rest?.submitAction === 'save-and-next') {
                      setInitValues(inits);
                      setImgUrl({ front: null, back: null });
                    } else {
                      props?.onHide();
                    }
                  }
                })
              : onCreateDraftOrder(params?.orderType as IOrderType, res => {
                  if (res) {
                    setDisableButton(false);
                    actions?.resetForm();
                    if (rest?.submitAction === 'save-and-next') {
                      setInitValues(inits);
                      setImgUrl({ front: null, back: null });
                    } else {
                      props?.onHide();
                    }
                  }
                });
          } else {
            setsubmitOnLoad(false);
            setDisableButton(false);
          }
        });
      } else {
        formState.travellerDetails.push(draftValues);
        /**
         * NOTE: cbFunc is a callback function which will be called in both
         * create and edit draft order...
         * @param res
         */
        const cbFunc = (res: any) => {
          if (res) {
            setDisableButton(false);
            actions?.resetForm();
            rest?.submitAction === 'save-and-next' ? null : props?.onHide();
          }
        };
        formState?.orderId
          ? onEditDraftOrder(formState?.orderId, params?.orderType as IOrderType, null, cbFunc)
          : onCreateDraftOrder(params?.orderType as IOrderType, cbFunc);
      }
    }
  };

  const onHideEditor = () => setIsEditorOpen(false);

  const validate = (values: any) => {
    const errors: any = {};
    let diff = moment().diff(moment(values.dateOfBirth, 'YYYY-MM-DD'), 'milliseconds');
    let age = moment.duration(diff).years();

    const isPassportNumberAlreadyUsed = formState?.travellerDetails?.some(
      member => member?.passportNumber === values?.passportNumber
    );

    if (!values?.id) {
      if (isPassportNumberAlreadyUsed) {
        errors.passportNumber = 'This passport number is already used in this order';
      }
    } else if (values?.id) {
      const count = formState?.travellerDetails?.filter(
        member => member?.passportNumber === values?.passportNumber
      )?.length;

      if (count > 1) {
        errors.passportNumber = 'This passport number is already used in this order';
      }
    }

    if (
      ['OAINTATA3000', 'OAINTATA5000']?.includes(formState?.tripDetails?.code) &&
      !isAtLeast6monthOld(values?.dateOfBirth)
    ) {
      errors.dateOfBirth = 'Age must be at least 6 months old or at most 60 years old';
    }

    if (
      formState?.tripDetails?.ancillaryType === 'INSURANCE' &&
      age < 1 &&
      !['OAINTATA3000', 'OAINTATA5000']?.includes(formState?.tripDetails?.code)
    ) {
      errors.dateOfBirth = 'Date of Birth should be greater than 1 year';
    } else if (!moment(values?.dateOfBirth).isValid()) {
      errors.dateOfBirth = 'Date of Birth is invalid';
    }
    if (values?.oldPassportCount < 0) {
      errors.oldPassportCount = 'Old Passport Count cannot be negative';
    }

    return errors;
  };

  const generateValidationSchema = (fields: any) => {
    const schemaFields: any = {};
    fields?.forEach((field: any) => {
      schemaFields[field.name] = field.rules;
    });
    return Yup.object().shape(schemaFields);
  };

  let requiredFieldsTemp = formState?.travellerFormFields?.requiredFields;

  if (isGroupOrder) {
    requiredFieldsTemp?.push('consulate');
  }

  const formFields: any = requiredFieldsTemp?.map((fieldName: any) => ({
    name: fieldName,
    rules: requiredFieldsValidationRules[fieldName] || Yup.string()
  }));

  const optionalFormFields: any = formState?.travellerFormFields?.optionalFields?.map(
    (fieldName: any) => ({
      name: fieldName,
      rules: optionalFieldsValidationRules[fieldName] || Yup.string()
    })
  );

  let dynamicValidationSchema = generateValidationSchema(formFields?.concat(optionalFormFields));
  /**
   * NOTE: Adding validation schema for VFS TH and DNATA AE...
   * - only for group orders...
   */
  if (isVfsThai && isGroupOrder) {
    dynamicValidationSchema = dynamicValidationSchema.concat(vfsThaiValidationSchema);
  } else if (isDnataAe && isGroupOrder) {
    dynamicValidationSchema = dynamicValidationSchema.concat(dnataUaeValidationSchema);
  }

  const onChangeConsulateSelect = (e: any) => onAddConsulate(e);

  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleSubmit}
      validationSchema={dynamicValidationSchema}
      validate={validate}
      enableReinitialize>
      {(formikProps: FormikProps<ITraveller>) => {
        if (formikProps?.dirty !== props?.isFormDirty && !imgUrl?.front && !imgUrl?.back) {
          props?.setIsFormDirty(formikProps?.dirty);
        }
        useEffect(() => {
          if (props?.data?.PassportFrontImage) {
            setUploadedPassportFrontImg(props?.data?.PassportFrontImage);
          }
          if (props?.data?.PassportBioPage) {
            setUploadedPassportFrontImg(props?.data?.PassportBioPage);
          }
          if (props?.data?.PassportBackImage) {
            setUploadedPassportBackImg(props?.data?.PassportBackImage);
          }
          if (props?.data?.PassportLastPage) {
            setUploadedPassportBackImg(props?.data?.PassportLastPage);
          }
        }, [props?.data, formikProps?.values]);

        const passportExpirationDate = formikProps?.values?.passportExpiration;
        const tripEndDate = formState?.tripDuration?.to;
        //passport expiry date should be 6 months greater than trip end date
        const currentDate = new Date();
        const isPassportExpiryLessThanTripEnd =
          moment(passportExpirationDate).isBefore(moment(tripEndDate).add(6, 'months')) &&
          moment(passportExpirationDate).isAfter(moment(currentDate));

        const handleCancel = () => {
          if (props?.onCancel) {
            props?.onCancel(() => formikProps?.resetForm());
          } else {
            formikProps?.resetForm();
            props?.onHide();
          }
        };

        if (consulateOption?.length === 1 && !formikProps?.values?.consulate && isGroupOrder) {
          formikProps?.setFieldValue('consulate', consulateOption?.[0]?.value);
          onChangeConsulateSelect(consulateOption?.[0]?.value);
        }

        return (
          <Container fluid as={Form} className='p-0'>
            <Row>
              {params?.orderType === ORDER_TYPE.VISA && (
                <Col lg={4}>
                  <Row className='passport-container'>
                    {isPassportExpiryLessThanTripEnd && (
                      <Col sm={12} className='mb-4'>
                        <Banner
                          text='The passport will expire in less than 6 months from the scheduled
                              return'
                          type='error'
                          action={{
                            text: 'View',
                            handleAction: () => handleSmoothScroll(passportDetailsRef)
                          }}
                        />
                      </Col>
                    )}
                    <ActiveTravellerBanner value={formikProps?.values?.passportNumber} />
                    <Col sm={12} className='mb-4'>
                      {imgUrl?.front || uploadedPassportFrontImg ? (
                        <ImageView
                          src={imgUrl?.front || uploadedPassportFrontImg}
                          handleRemove={() => handleRemoveImage('front')}
                        />
                      ) : (
                        <DocumentUpload
                          inputName='frontImage'
                          title='Upload passport front page'
                          description='Ensure image quality is clear and readable'
                          id='doc-upload-frontImage'
                          handleSelect={e => handleSelectImage(e, 'ocr_front')}
                          isLoading={isImageUploading && selectedImg?.name === 'ocr_front'}
                        />
                      )}
                    </Col>
                    <Col sm={12}>
                      {imgUrl?.back || uploadedPassportBackImg ? (
                        <ImageView
                          src={imgUrl?.back || uploadedPassportBackImg}
                          handleRemove={() => handleRemoveImage('back')}
                        />
                      ) : (
                        <DocumentUpload
                          inputName='backImage'
                          title='Upload passport back page'
                          description='Ensure image quality is clear and readable'
                          id='doc-upload-backImage'
                          handleSelect={e => handleSelectImage(e, 'ocr_back')}
                          isLoading={isImageUploading && selectedImg?.name === 'ocr_back'}
                        />
                      )}
                    </Col>
                  </Row>
                  <ImageEditorModal
                    isOpen={isEditorOpen}
                    setIsOpen={setIsEditorOpen}
                    onHide={onHideEditor}
                    setIsImgUploading={setIsImageUploading}
                    imgFile={selectedImg?.file}
                    fileUploadHandler={uploadImgToS3}
                  />
                </Col>
              )}
              <Col lg={params?.orderType === ORDER_TYPE.VISA && 8}>
                {params?.orderType === ORDER_TYPE.VISA && (
                  <DisplayHeaderMessage
                    isFetchingDataFromApi={isFetchingDataFromApi}
                    imgUrl={imgUrl}
                  />
                )}
                <DynamicField
                  fields={{
                    required: formState?.travellerFormFields?.requiredFields,
                    optional: formState?.travellerFormFields?.optionalFields
                  }}
                  fieldName='consulate'
                  label='Consulate'
                  fieldType='select'
                  options={consulateOption}
                  onChangeData={onChangeConsulateSelect}
                  formatOptionLabel={formatOptionLabel}
                  isClearable={false}
                  valueOnly
                  colWidth={6}
                  required
                  renderCondition={() => isGroupOrder}
                />
                {/**
                 * NOTE: Personal information section...
                 */}
                <React.Fragment>
                  <h6 className='text-dark mb-1 mt-6' ref={personalInfoRef}>
                    Personal information
                  </h6>
                  <div className='separator mb-4' />
                  <Row>{renderPrimaryInformation(formState, dataExchange, optionsLoading)}</Row>
                  <Row>{renderAdditionalInformation(formState, dataExchange, optionsLoading)}</Row>
                </React.Fragment>
                {/**
                 * NOTE: Contact information section...
                 */}
                <React.Fragment>
                  <h6 className='text-dark mb-1 mt-6'>Contact information</h6>
                  <div className='separator mb-4' />
                  <Row>{renderContactInformation(formState)}</Row>
                </React.Fragment>
                {/**
                 * NOTE: Travel information section...
                 */}
                {isBusOrCabInsurance && !orderData?.data?.id ? (
                  <React.Fragment>
                    <h6 className='text-dark mb-1 mt-6'>Travel information</h6>
                    <div className='separator mb-4' />
                    <Row>{renderTravelInformation(formState)}</Row>
                  </React.Fragment>
                ) : null}
                {/**
                 * NOTE: Travel details section...
                 */}
                {!isNull(
                  renderTravelDetails(
                    formState,
                    { airportCodes: dataExchange?.airportCodes },
                    optionsLoading
                  )
                ) ? (
                  <React.Fragment>
                    <h6 className='text-dark mb-1 mt-6'>Travel details</h6>
                    <div className='separator mb-4' />
                    <Row>
                      {renderTravelDetails(
                        formState,
                        { airportCodes: dataExchange?.airportCodes },
                        optionsLoading
                      )}
                    </Row>
                  </React.Fragment>
                ) : null}
                {/**
                 * NOTE: Passport details section...
                 */}
                {formState?.tripDetails?.domestic === false && (
                  <React.Fragment>
                    <h6 className='text-dark mb-1 mt-6' ref={passportDetailsRef}>
                      Passport details
                    </h6>
                    <div className='separator mb-4' />
                    <Row>{renderPassportForm(formState)}</Row>
                  </React.Fragment>
                )}
                {isGroupOrder && (
                  <BookingInformationSection
                    shouldShow={isVfsThai || isDnataAe}
                    formProps={formikProps}
                  />
                )}
              </Col>
            </Row>
            {isGroupOrder && (
              <div className='order-view'>
                <div className='order-view--traveller-card '>
                  <DocumentUploadGroup
                    formState={formState}
                    documents={documents}
                    setDocuments={setDocuments}
                    setUploadedDoc={setUploadedDoc}
                    orderData={orderData?.data}
                  />
                </div>
              </div>
            )}
            <div
              className={classNames('d-flex mt-4', {
                'justify-content-end': params?.orderType === ORDER_TYPE.INSURANCE,
                'flex-stack': params?.orderType === ORDER_TYPE.VISA
              })}>
              <OAButton
                type='button'
                color='light-primary'
                onClick={handleCancel}
                disabled={submitOnLoad}
                className={classNames('btn-active-light-primary', {
                  'ms-auto': params?.orderType === ORDER_TYPE.VISA
                })}
                label='Cancel'
              />
              {showSaveOnly && (
                <OAButton
                  type='submit'
                  onClick={() => {
                    formikProps?.isValid && formikProps?.dirty
                      ? setsubmitOnLoad(true)
                      : setsubmitOnLoad(false);
                    formikProps?.validateForm(formikProps?.values);
                  }}
                  className='ms-5'
                  disabled={disableButton}
                  label={
                    submitOnLoad ? (
                      <React.Fragment>
                        Loading...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2' />
                      </React.Fragment>
                    ) : (
                      'Save Traveller'
                    )
                  }
                />
              )}
              {!isBusOrCabInsurance
                ? (case_1 || case_2) && (
                    <OAButton
                      type='submit'
                      onClick={() => {
                        formikProps.setFieldValue('submitAction', 'save-and-next');
                      }}
                      className='ms-auto btn-light-primary btn-active-light-primary'
                      label={'Save & add another'}
                    />
                  )
                : null}
              {!showSaveOnly && (
                <OAButton
                  type='submit'
                  onClick={() => {
                    formikProps?.isValid && formikProps?.dirty
                      ? setsubmitOnLoad(true)
                      : setsubmitOnLoad(false);
                    formikProps.setFieldValue('submitAction', 'save-and-submit');
                  }}
                  className='ms-5'
                  disabled={disableButton}
                  label={
                    submitOnLoad ? (
                      <React.Fragment>
                        Loading...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2' />
                      </React.Fragment>
                    ) : (
                      'Save Traveller'
                    )
                  }
                />
              )}
            </div>
          </Container>
        );
      }}
    </Formik>
  );
}

// helper function ...
const isFieldPresent = (formState: ICreateOrderFormState, fieldName: string) => {
  const requiredFields = formState?.travellerFormFields?.requiredFields || [];
  const optionalFields = formState?.travellerFormFields?.optionalFields || [];

  return requiredFields.includes(fieldName) || optionalFields.includes(fieldName);
};
