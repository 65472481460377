import { Form, Formik, FormikProps } from 'formik';
import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import OATextAreaField from '../../../../components/fields/OATextAreaField';
import OATextField from '../../../../components/fields/OATextField';

import OAModalComponent from '../../../../components/OAModalComponent';
import { KTSVG } from '../../../../_metronic/helpers';
import { useOrderContext } from '../../context/OrderContext';
import { IOrderType } from '../../types';

/**
 * types ...
 */
interface ISubmitToEmbassyModal {
  isOpen: boolean;
  onHide: () => void;
  data: any;
}

/**
 *
 * @param props
 * @returns
 */
export default function SubmitToEmbassyModal(props: ISubmitToEmbassyModal) {
  return (
    <OAModalComponent
      show={props?.isOpen}
      onHide={props?.onHide}
      className='submit-to-embassy__modal'
      header={{
        title: 'Submit your order',
        closeButton: true
      }}
      body={{
        content: <SubmitToEmbassyForm onHide={props?.onHide} />,
        className: 'submit-to-embassy__modal-body'
      }}
    />
  );
}

/**
 * Additional component : SubmitToEmbassyForm
 */
interface ISubmitToEmbassyForm {
  onHide: () => void;
}

export interface ISubmitToEmbassyFormValues {
  applicationId: string;
  remark?: string;
}

const init: ISubmitToEmbassyFormValues = {
  applicationId: '',
  remark: ''
};

const validate = (values: ISubmitToEmbassyFormValues) => {
  const errors: any = {};

  if (!values.applicationId) {
    errors.applicationId = 'Please update the Application ID received from the embassy';
  }

  return errors;
};

function SubmitToEmbassyForm(props: ISubmitToEmbassyForm) {
  const params = useParams<{ orderType: IOrderType; id?: string }>();

  const { orderData, onSubmitReview } = useOrderContext();

  const handleSubmit = (values: ISubmitToEmbassyFormValues) => {
    onSubmitReview(params?.orderType!, params?.id!, values, res => {
      if (res?.data?.success) {
        props?.onHide();
      }
    });
  };

  const embassyLink = orderData?.data?.product?.productInformation?.source?.find(
    (src: any) => src?.type === 'APPLICATION_SOURCE'
  )?.link;

  return (
    <Formik initialValues={init} onSubmit={handleSubmit} validate={validate}>
      {(formikProps: FormikProps<ISubmitToEmbassyFormValues>) => {
        return (
          <Form className='w-100'>
            <Container fluid className='p-0'>
              {/**
               * NOTE: row contains alert box only ...
               */}
              <Row>
                <Col>
                  <div className='alert alert-dismissible bg-light-warning border border-warning border-dashed d-flex flex-column flex-sm-row p-5 mb-4'>
                    <div className='d-flex flex-column text-muted pe-0 pe-sm-10'>
                      <span className='pb-2'>
                        Tap on the link below to submit your order to the embassy. Later, come back
                        here to add your Application ID
                      </span>
                      <a
                        href={embassyLink}
                        target='_blank'
                        rel='noreferrer'
                        className='d-flex align-items-end lh-1'>
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr036.svg'
                          className='svg-icon-primary svg-icon-2 me-2'
                        />
                        Click here
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
              {/**
               * NOTE: row contains form fields ...
               */}
              <Row>
                <Col lg={12} className='mb-6'>
                  <OATextField
                    name='applicationId'
                    label='Application ID (from embassy/VFS)'
                    placeholder='Type here'
                    required
                  />
                </Col>
                <Col lg={12} className='mb-6'>
                  <OATextAreaField name='remark' label='Remarks' placeholder='Type here' />
                </Col>
              </Row>
              {/**
               * NOTE: row contains form action buttons ...
               */}
              <Row>
                <Col>
                  <div className='d-flex justify-content-end'>
                    <Button
                      type='button'
                      size='sm'
                      onClick={props?.onHide}
                      disabled={formikProps?.isSubmitting}
                      className='btn-light-primary btn-active-light-primary me-4'>
                      Cancel
                    </Button>
                    <Button
                      type='submit'
                      size='sm'
                      disabled={
                        (!formikProps?.isValid && formikProps?.dirty) || formikProps?.isSubmitting
                      }
                      className='btn-primary btn-active-primary'>
                      Submit
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
}
