import { debounce } from 'lodash';
import { Dispatch, useContext, useEffect, useMemo, useState } from 'react';
import { KTCard, KTSVG, KTCardBody } from '../../../_metronic/helpers';
import { IFlightQueryParams } from '../type';
import { getFlightColumns, getItineraryColumns } from './flightsColumns';
import OATableComponent, { OATablePaginationProps } from '../../../components/OATableComponent';
import { useAppContext } from '../../../context/AppContext';
import { GridEventListener } from '@mui/x-data-grid-pro';
import FlightContext from '../context/FlightContext';
import { useNavigate } from 'react-router-dom';
import OrderFilterNavItem from '../../../components/OAFilterNav';
import { flightFilterNavs } from '../../order-management/constants';

interface IFlightsHistory {
  list: any;
  totalPage: number;
  queryParams: IFlightQueryParams;
  setQueryParams: Dispatch<React.SetStateAction<IFlightQueryParams>>;
  onChangeRangePicker: (range: any) => void;
  loading: boolean;
}

export const FlightOrderHistory = (props: IFlightsHistory) => {
  const navigate = useNavigate();
  const { list, queryParams, setQueryParams, loading } = props;
  const { pageInfo } = useContext(FlightContext);

  useEffect(() => {
    let ifFound = flightFilterNavs?.find((obj: any) => obj === queryParams?.orderStatus);
    if (!ifFound) {
      setQueryParams((prev: any) => ({
        ...prev,
        orderStatus: '',
        search: null
      }));
    }
  }, []);

  const filterNavs = [
    {
      label: 'All',
      value: '',
      count: list?.allTotalOrder,
      show: true
    },
    {
      label: 'Confirmed',
      value: 'CONFIRMED',
      count: list?.totalConfirmedOrder,
      show: true
    },
    {
      label: 'Cancelled',
      value: 'CANCELLED',
      count: list?.totalCancelledOrder,
      show: true
    },
    {
      label: 'Failed',
      value: 'FAILED',
      count: list?.totalFailedOrder,
      show: true
    },
    {
      label: 'Itinerary',
      value: 'ITINERARY',
      count: list?.totalItineraryOrder,
      show: true
    }
  ];

  const onChangeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams(prev => ({
      ...prev,
      search: evt?.target?.value?.trim(),
      pageIndex: 1
    }));
  };

  const tableColumns = useMemo(() => {
    return queryParams?.orderStatus === 'ITINERARY' ? getItineraryColumns() : getFlightColumns();
  }, [queryParams?.orderStatus]);

  const debouncedOnChange = debounce(onChangeHandler, 300);

  const handleRowClick: GridEventListener<'cellClick'> = params => {
    navigate(`/apps/flights/${params?.row?.id}`);
  };

  const paginationProps: OATablePaginationProps = {
    pageIndex: queryParams?.pageIndex,
    pageCount: pageInfo?.totalPages,
    total: pageInfo?.totalCount,
    pageSize: pageInfo?.size,
    onPageChange: (value: any) => {
      setQueryParams(prev => ({ ...prev, pageIndex: value }));
    }
  };
  return (
    <KTCard>
      <div className='card-header card-header-stretch border-bottom border-gray-200 min-h-60px'>
        <div className='card-title position-relative search-orders'>
          <KTSVG
            className='position-absolute ms-2 svg-icon-4'
            path='/media/icons/duotune/general/gen021.svg'
          />
          <input
            type='text'
            onChange={debouncedOnChange}
            className='form-control form-control-sm form-control-solid oa-text-search w-150px ps-9'
            placeholder='Search Orders'
          />
        </div>
        <div className='card-toolbar m-0 order-search'>
          <ul className='nav nav-stretch nav-line-tabs border-transparent' role='tablist'>
            {filterNavs?.length &&
              filterNavs?.map((obj, idx) =>
                obj?.show ? (
                  <OrderFilterNavItem
                    key={idx}
                    label={obj?.label}
                    orderStatus={queryParams?.orderStatus!}
                    value={obj?.value}
                    count={obj?.count}
                    onClick={() => {
                      setQueryParams(prev => ({
                        ...prev,
                        orderStatus: obj?.value,
                        pageIndex: 1
                      }));
                    }}
                    isLoading={props?.loading}
                  />
                ) : null
              )}
          </ul>
        </div>
      </div>
      <KTCardBody className='px-6 py-5'>
        <OATableComponent
          columns={tableColumns}
          data={list?.content ?? []}
          onCellClick={handleRowClick}
          pagination={paginationProps}
          loading={loading}
        />
      </KTCardBody>
    </KTCard>
  );
};
