import { IQueryParams } from '../Dashboard';
import LedgerProvider from './context/LedgerProvider';
import List from './List';

const Ledger = (props: IQueryParams) => {
  return (
    <LedgerProvider data={props}>
      <List data={props} />
    </LedgerProvider>
  );
};

export default Ledger;
