const BreadcrumbItem = () => {
  return [
    {
      title: `Dashboard`,
      path: `/dashboard`,
      isActive: false
    },
    {
      title: `Flights Management`,
      path: ``,
      isActive: true
    }
  ];
};

export default BreadcrumbItem;
