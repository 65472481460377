import { useContext, useEffect, useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Col, Row, Table } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';

import OrgContext from '../context/OrgContext';
import OACheckboxField from '../../../components/fields/OACheckboxField';

interface ISuppliersFieldArray {
  data?: any;
}

export default function SuppliersFieldArray(props: ISuppliersFieldArray) {
  const { organizations, suppliers, fetchSuppliers, selectedOrg } = useContext(OrgContext);
  const { values, setFieldValue } = useFormikContext<any>();

  const [supplierOrgs, setSupplierOrgs] = useState<Array<any> | undefined>([]);
  /**
   * DEVNOTE: if suppliers data not available then call fetchSuppliers method on
   * component mount.
   */
  useEffect(() => {
    if (!suppliers?.data) {
      fetchSuppliers?.();
    }
  }, []);
  /**
   * DEVNOTE: setting supplierOrgs based on ancillaryType / suppliers data changes.
   * - show only those suppliers providing selected ancillaries.
   * - for a child org, show suppliers from associatedOrganizations of parent org selected.
   * - for a parent org, show all suppliers.
   */
  useEffect(() => {
    let draft: Array<any> | undefined = [];
    if (values?.ancillaryType?.length) {
      if (values?.isChild === 'true' && values?.parentOrganizationId) {
        const selectedParentOrg: any = organizations?.data?.find(
          (org: any) => org?.id === values?.parentOrganizationId
        );
        const associatedOrgIds: Array<string> = selectedParentOrg?.associatedOrganization?.map(
          (org: any) => org?.organizationId
        );

        draft = suppliers?.data?.filter(
          (org: any) =>
            associatedOrgIds?.includes(org?.id) &&
            values?.ancillaryType?.some((org2: any) => org?.ancillaryType?.includes(org2))
        );
      } else {
        draft = suppliers?.data?.filter((org: any) =>
          values?.ancillaryType?.some((org2: any) => org?.ancillaryType?.includes(org2))
        );
      }
    }
    setSupplierOrgs(draft);
  }, [values?.ancillaryType, suppliers]);
  /**
   * DEVNOTE: reset associatedOrganization if selectedOrg is empty object else
   * set incoming associatedOrganization data to respective field.
   */
  useEffect(() => {
    if (isEmpty(selectedOrg)) {
      setFieldValue('associatedOrganization', '');
    } else {
      setFieldValue('associatedOrganization', props?.data);
    }
  }, [selectedOrg, props?.data]);

  return values?.ancillaryType?.length && supplierOrgs?.length ? (
    <Row className='mb-7'>
      <Col>
        <FieldArray
          name='associatedOrganization'
          render={_arrayHelpers => (
            <Table responsive='sm' className='table-row-bordered mb-0'>
              <thead>
                <tr>
                  <th className='fw-bold'>Suppliers</th>
                </tr>
              </thead>
              <tbody>
                {supplierOrgs?.map((sup, supIdx) => (
                  <tr key={`${sup?.id}_${supIdx}`}>
                    <td className='fw-bold text-muted px-4'>{sup?.name}</td>
                    <td>
                      {sup?.ancillaryType?.includes('EVISA') &&
                      values?.ancillaryType?.includes('EVISA') ? (
                        <OACheckboxField
                          name={`associatedOrganization.${sup?.id}.ancillaryType.EVISA`}
                          label='eVisa'
                          size='sm'
                          className='me-4'
                        />
                      ) : null}
                    </td>
                    <td>
                      {sup?.ancillaryType?.includes('INSURANCE') &&
                      values?.ancillaryType?.includes('INSURANCE') ? (
                        <OACheckboxField
                          name={`associatedOrganization.${sup?.id}.ancillaryType.INSURANCE`}
                          label='Insurance'
                          size='sm'
                          className='me-4'
                        />
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        />
      </Col>
    </Row>
  ) : null;
}
