import React, { useEffect, useState } from 'react';
import { DATE_FORMAT_DISPLAY_FE } from '../../constants';
import { useDashboardContext } from './context/DashboardContext';
import DashboardProvider from './context/DashboardProvider';
import OARangePicker from '../../components/OARangePicker';
import OAToolbar from '../../components/widgets/OAToolbar';
import { getDateFormat } from '../../helper';

import DraggableLayout, { ComponentItem } from '../../components/OADraggable/DraggableLayout';
import OrderRevenue from './Chart/OrderRevenue';
import {
  SummaryCardTile,
  TopCountriesTile,
  TopSellingProductsTile,
  TopSuppliersTile,
  UpcomingEventsTile,
  VisaToBeExpiredTile
} from './DashBoardTiles';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth';
import OAButton from '../../components/widgets/OAButton';

interface IDashBoardQueryParams {
  data: any;
}
const DashboardWrapper = (props: IDashBoardQueryParams) => {
  const { queryParams, setQueryParams } = props?.data;

  const {
    order,
    topProducts,
    topSuppliers,
    topCountries,
    report,
    getTopSuppliers,
    getTopSellingProducts,
    getOrderData,
    downloadReport,
    getTopCountries
  } = useDashboardContext();
  const navigate = useNavigate();
  const [displayDateRange, setDisplayDateRange] = useState<string>('');
  const [isDragging, setIsDragging] = useState(false);
  const { currentUser } = useAuth();

  const onDragStart = () => {
    setIsDragging(false);
  };

  const onDragStop = () => {
    setIsDragging(true);
  };

  useEffect(() => {
    if (queryParams?.dateRange[0] && queryParams?.dateRange[1]) {
      const startDate = getDateFormat(queryParams?.dateRange?.[0] as Date, DATE_FORMAT_DISPLAY_FE);
      const endDate = getDateFormat(queryParams?.dateRange?.[1] as Date, DATE_FORMAT_DISPLAY_FE);

      setDisplayDateRange(`${startDate} - ${endDate}`);
      getTopSuppliers();
      getTopSellingProducts();
      getOrderData();
      getTopCountries();
    }
  }, [queryParams]);

  const onChangeRangePicker = (range: any) => {
    setQueryParams((prev: any) => ({ ...prev, dateRange: range }));
  };

  const listOfComponents: ComponentItem[] = [
    {
      id: '0',
      name: (
        <div className='h-100' onClick={() => !isDragging && navigate('/apps/orders/visa')}>
          <SummaryCardTile data={order?.summaryData?.visa} isLoading={order?.isLoading} />
        </div>
      ),
      height: 1
    },
    {
      id: '1',
      name: (
        <div className='h-100' onClick={() => !isDragging && navigate('/apps/orders/insurance')}>
          <SummaryCardTile data={order?.summaryData?.insurance} isLoading={order?.isLoading} />
        </div>
      ),
      height: 1
    },
    {
      id: '2',
      name: <SummaryCardTile data={order?.summaryData?.order} isLoading={order?.isLoading} />,
      height: 1
    },
    { id: '3', name: <VisaToBeExpiredTile />, height: 2.5 },
    { id: '4', name: <UpcomingEventsTile />, height: 2.5 },
    {
      id: '5',
      name: (
        <OrderRevenue
          title='Revenue'
          dateRange={displayDateRange}
          isLoading={order?.isLoading}
          {...order?.revenueGraphData}
        />
      ),
      height: 2.5
    },
    {
      id: '6',
      name: <TopSuppliersTile {...topSuppliers} dateRange={displayDateRange} />,
      height: 2.5
    },
    {
      id: '7',
      name: <TopCountriesTile {...topCountries} dateRange={displayDateRange} />,
      height: 2.5
    },
    {
      id: '8',
      name: <TopSellingProductsTile {...topProducts} dateRange={displayDateRange} />,
      height: 2.5
    },
    {
      id: '9',
      name: (
        <OrderRevenue
          title='Orders'
          dateRange={displayDateRange}
          isLoading={order?.isLoading}
          {...order?.orderGraphData}
        />
      ),
      height: 2.5
    }
  ];

  return (
    <React.Fragment>
      <OAToolbar
        title='Dashboard'
        actionsElement={
          <div className='d-flex align-items-center gap-2 gap-lg-3'>
            <OARangePicker
              className='dashboard-data-range-filter'
              name='dashboard-data-range'
              onChangeRangePicker={onChangeRangePicker}
              dateRange={queryParams?.dateRange}
              maxDate={new Date()}
              shortcuts
            />
            <div>
              {currentUser?.data?.role !== 'ROLE_OPERATION' &&
                currentUser?.data?.role !== 'ROLE_OPERATION_EXTERNAL' && (
                  <OAButton
                    label='Report'
                    icon='/media/icons/duotune/files/fil021.svg'
                    iconClassName='svg-icon-3 me-2'
                    loadingText='generating...'
                    isLoading={report?.isLoading}
                    onClick={downloadReport}
                    className='btn btn-sm btn-flex btn-light btn-primary fw-bolder'
                    disabled={report?.isLoading}
                  />
                )}
            </div>
          </div>
        }
      />
      <DraggableLayout
        onDragStart={onDragStart}
        onDragStop={onDragStop}
        listOfComponents={listOfComponents}
        onLayoutChange={() => {}}
      />
    </React.Fragment>
  );
};
export interface IQueryParams {
  queryParams: any;
  setQueryParams: any;
}
const Dashboard = (props: IQueryParams) => {
  return (
    <DashboardProvider data={props}>
      <DashboardWrapper data={props} />
    </DashboardProvider>
  );
};

export default Dashboard;
