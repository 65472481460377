import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import orderListImg from '../../assets/images/dummy_screen/order_list.jpg';
import dashboardImg from '../../assets/images/dummy_screen/dashboard.jpg';
import requestImg from '../../assets/images/request_access.svg';
import requestVisaImg from '../../assets/images/request_access_visa.svg';
import requestInsuranceImg from '../../assets/images/request_access_insurance.svg';
import requestQuotationImg from '../../assets/images/request_access_quotation.svg';
import requestDashboardImg from '../../assets/images/request_access_dashboard.svg';
import { OATypography } from '../widgets';
import OAButton from '../widgets/OAButton';
import { useAppContext } from '../../context/AppContext';
import { useAuth } from '../../auth';

type AccessRequestInfo = {
  backgroundImg: string;
  requestImg: string;
  description: string;
  tag: 'VISA' | 'VISA INFORMATION' | 'INSURANCE' | 'DASHBOARD';
};

const getAccessRequestInfo = (path: string): AccessRequestInfo => {
  if (path.includes('dashboard')) {
    return {
      backgroundImg: dashboardImg,
      requestImg: requestDashboardImg,
      description:
        'The dashboard is your one single place to review your sales summary and activities',
      tag: 'DASHBOARD'
    };
  } else if (path.includes('orders/visa')) {
    return {
      backgroundImg: orderListImg,
      requestImg: requestVisaImg,
      description: 'Create and manage regular or group visa bookings for your organisation here',
      tag: 'VISA'
    };
  } else if (path.includes('orders/insurance')) {
    return {
      backgroundImg: orderListImg,
      requestImg: requestInsuranceImg,
      description: 'Create and manage regular or bulk insurance bookings here',
      tag: 'INSURANCE'
    };
  } else if (path.includes('quotation/visa')) {
    return {
      backgroundImg: orderListImg,
      requestImg: requestQuotationImg,
      description: 'You can generate and share visa quotations with your customers from here',
      tag: 'VISA'
    };
  } else if (path.includes('quotation/insurance')) {
    return {
      backgroundImg: orderListImg,
      requestImg: requestQuotationImg,
      description: 'You can generate and share insurance quotations with your customers from here',
      tag: 'INSURANCE'
    };
  }

  return {
    backgroundImg: orderListImg,
    requestImg: requestImg,
    description: 'The page you are trying to access is not available',
    tag: 'VISA'
  };
};

const RequestAccess: React.FC = () => {
  const location = useLocation();
  const { currentUser } = useAuth();
  const { requestAccess } = useAppContext();
  const { backgroundImg, requestImg, description, tag } = getAccessRequestInfo(location.pathname);

  const accessRequestedFor = currentUser?.data?.accessRequestedFor || [];

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requested, setRequested] = useState<boolean>(false);

  const onClickHandler = () => {
    setIsLoading(true);
    requestAccess(tag, res => {
      setIsLoading(false);
      setRequested(true);
    });
  };

  const isDisabled = isLoading || requested || accessRequestedFor.includes(tag);

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        marginTop: '54px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <img src={requestImg} alt='Request Access' />
      <Box textAlign='center' sx={{ mt: '-115px' }}>
        <OATypography variant='h4' sx={{ mb: '4px' }}>
          You don’t have access yet
        </OATypography>
        <OATypography
          variant='body2'
          sx={{
            maxWidth: '350px',
            mb: '16px',
            color: theme => theme?.palette?.grey?.[400]
          }}>
          {description}
        </OATypography>
        <OAButton
          label={accessRequestedFor.includes(tag) || requested ? 'Requested' : 'Request access'}
          onClick={onClickHandler}
          isLoading={isLoading}
          loadingText='Requesting access'
          disabled={isDisabled}
          color={accessRequestedFor.includes(tag) || requested ? 'secondary' : 'primary'}
        />
      </Box>
    </Box>
  );
};

export default RequestAccess;
