import OALoader from '../widgets/OALoader';

const LoadingState = (props: { columnLength: number }) => {
  return (
    <tr className='data-table__row'>
      <td colSpan={props?.columnLength}>
        <div className='my-3 text-center'>
          <OALoader innerComponent />
        </div>
      </td>
    </tr>
  );
};

export default LoadingState;
