import { useEffect, useState } from 'react';
import { ErrorMessage, useField } from 'formik';
import PhoneInput from 'react-phone-input-2';
import classNames from 'classnames';

import 'react-phone-input-2/lib/style.css';

interface IOAPhoneField {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  countryCode?: 'in' | 'ae';
}

export default function OAPhoneField(props: IOAPhoneField) {
  const { countryCode = 'in' } = props;
  const [field, _meta, helpers] = useField(props?.name);

  const [isValidNum, setIsValidNum] = useState(false);

  useEffect(() => {
    if (!_meta.error && !isValidNum) {
      helpers?.setError('Invalid phone number');
    }
  }, [isValidNum, _meta.error]);

  const labelClassName = classNames('form-label', {
    required: props?.required
  });
  const containerClassName = 'd-block';
  const inputClassName = 'form-control-sm form-control-solid';

  return (
    <div className='oa-phone-field'>
      <label className={labelClassName}>{props?.label}</label>
      <PhoneInput
        inputProps={{
          name: props?.name,
          required: true,
          autoComplete: 'off'
        }}
        country={countryCode}
        onlyCountries={[countryCode]} // NOTE: suppoting only India and UAE for now
        countryCodeEditable={false}
        autoFormat={false}
        specialLabel=''
        placeholder='Type here'
        containerClass={containerClassName}
        inputClass={inputClassName}
        prefix='+'
        value={field?.value}
        onBlur={(event, data) => {
          helpers?.setTouched(true);
          helpers?.setError('Invalid phone number');
        }}
        onChange={(_value, _data, _event, formattedValue) => {
          helpers.setValue(formattedValue);
        }}
        isValid={(value: any, country: any) => {
          let pattern =
            country?.iso2 === 'in'
              ? /^(\91)(?:6|7|8|9)\d{9}$/
              : /^(\971)(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/;
          if (pattern.test(value)) {
            setIsValidNum(true);
            return true;
          } else {
            setIsValidNum(false);
            return false;
          }
        }}
        disabled={props?.disabled}
      />
      <div className='text-danger fw-bold fs-9'>
        <ErrorMessage name={props?.name} />
      </div>
    </div>
  );
}
