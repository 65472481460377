import { IconType } from '.';

export default function SuccessSVG(props: IconType) {
  const { height = '15', width = '15', className = '' } = props;
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 16 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <circle cx='6.5' cy='6.5' r='6.5' fill='#2FB344' />
      <path
        d='M3.6875 6.78125L5.9375 9.03125L9.3125 3.96875'
        stroke='#F6F8FA'
        strokeWidth='0.72'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
