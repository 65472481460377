import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { Footer } from './components/Footer';
import { HeaderWrapper } from './components/header/HeaderWrapper';
import { Content } from './components/Content';
import { PageDataProvider } from './core';
import { MenuComponent } from '../assets/ts/components';
import HighLightTestMode from '../../components/HighLightTestMode';
import { useAppContext } from '../../context/AppContext';
import RequestAccess from '../../components/RequestAccess';
import { useAuth } from '../../auth';

const MasterLayout = () => {
  const location = useLocation();
  const { getDataExchangeByType } = useAppContext();
  const { currentUser } = useAuth();
  const isGuestUser = currentUser?.data?.role === 'GUEST_USER';
  let lsMode = localStorage.getItem('oaMode');

  const [curMode, setCurMode] = useState<string>(lsMode!);

  window.addEventListener('mode_switch', () => {
    let lsMode = localStorage.getItem('oaMode');
    setCurMode(lsMode!);
  });

  useEffect(() => {
    const typeStr = `OCCUPATION, TITLE, GENDER, VISA_STATUS, INSURANCE_STATUS, EVISA_UPDATE_STATUS, STICKER_VISA_UPDATE_STATUS, INSURANCE_UPDATE_STATUS, QUOTATION_STATUS, ODS_STATUS`;
    getDataExchangeByType(typeStr, null);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, []);

  // useEffect(() => {
  // 	setTimeout(() => {
  // 		MenuComponent.reinitialization()
  // 	}, 500);
  // }, [location.key]);

  return (
    <PageDataProvider>
      <div className='master-layout d-flex flex-row flex-column-fluid'>
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper mode={curMode} />

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <Content>
                {isGuestUser && !location.pathname.includes('visa-information') ? (
                  <RequestAccess />
                ) : (
                  <Outlet />
                )}
              </Content>
            </div>

            {!isGuestUser && <Footer />}
          </div>
        </div>
      </div>
      {lsMode === 'test' && <HighLightTestMode />}
    </PageDataProvider>
  );
};

export { MasterLayout };
