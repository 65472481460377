import { Link, useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, FormikValues, ErrorMessage, Field } from 'formik';
import { Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';

import APITokenLess from '../../helper/APITokenLess';
import { showToast } from '../../helper';
import { getUserByToken, login } from '../core/_requests';
import { useAuth } from '../core/Auth';
import { encryptStorage } from '../../helper/storage';

const initialValues = {
  code: '',
  password: '',
  passwordConfirmation: ''
};

const newInitialValues = {
  oldPassword: '',
  newPassword: '',
  passwordConfirmation: ''
};

const resetPasswordSchema = Yup.object().shape({
  code: Yup.number().typeError('you must specify a number').required('Code is required'),
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    ),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
});

const newPasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required('Old Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    ),
  newPassword: Yup.string()
    .required('New Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    ),
  passwordConfirmation: Yup.string()
    .required('Password confirmation is required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
});

export function ResetPassword() {
  let navigate = useNavigate();
  let { emailId, newPasswordRequired }: any = useParams();
  const { saveAuth, setCurrentUser } = useAuth();

  const submitHandler = async (values: any, actions: FormikValues) => {
    if (newPasswordRequired === 'true') {
      try {
        const { data: auth } = await login(emailId, values.oldPassword, values?.newPassword);
        saveAuth(auth);
        const { data: user } = await getUserByToken(auth.data.accessToken);
        setCurrentUser(user);
        const draftMode = user?.data?.role === 'ROLE_OPERATION' ? 'live' : 'test';
        localStorage.setItem('oaMode', draftMode);
        encryptStorage.setItem('oa_orgnization_id', user?.data?.organization?.id);
        window.dispatchEvent(new Event('mode_switch'));
      } catch (error: any) {
        saveAuth(undefined);
        showToast(error?.response?.data, 'error');
      }
    } else {
      const requestBody = {
        email: emailId?.trim(),
        code: values?.code.trim(),
        password: values?.password.trim()
      };
      try {
        APITokenLess.post(`/api/internal/password/reset`, requestBody)
          .then(response => {
            if (response.data.httpStatus === 200) {
              showToast('Password reset successfully', 'success');
              setTimeout(() => {
                navigate('/auth/login');
              }, 1000);
            }
          })
          .catch(err => {
            actions.resetForm();
            showToast(err?.response?.data, 'error');
          });
      } catch (error: any) {
        throw new Error(`API error: ${error?.message}`);
      }
    }
  };
  return (
    <>
      <Formik
        validationSchema={newPasswordRequired === 'true' ? newPasswordSchema : resetPasswordSchema}
        initialValues={newPasswordRequired === 'true' ? newInitialValues : initialValues}
        onSubmit={submitHandler}>
        {(formikProps: any) => (
          <Form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' noValidate>
            <div className='text-center mb-10'>
              <h1 className='text-dark mb-3'>Setup New Password</h1>
            </div>
            <Row className='mb-10'>
              <Col lg={12}>
                {newPasswordRequired === 'true' ? (
                  <>
                    <label className='form-label required'>Old Password</label>
                    <Field
                      type='password'
                      name='oldPassword'
                      placeholder='Type here'
                      className='form-control form-control-solid'
                    />
                    <div className='text-danger'>
                      <ErrorMessage name='oldPassword' />
                    </div>
                  </>
                ) : (
                  <>
                    <label className='form-label required'>Code</label>
                    <Field
                      type='text'
                      name='code'
                      placeholder='Type here'
                      className='form-control form-control-lg form-control-solid'
                    />
                    <div className='text-danger'>
                      <ErrorMessage name='code' />
                    </div>
                  </>
                )}
              </Col>
            </Row>
            <Row className='mb-10'>
              <Col lg={12}>
                {newPasswordRequired === 'true' ? (
                  <>
                    <label className='form-label required'>New Password</label>
                    <Field
                      type='password'
                      name='newPassword'
                      placeholder='Type here'
                      className='form-control form-control-solid'
                    />
                    <div className='text-danger'>
                      <ErrorMessage name='newPassword' />
                    </div>
                  </>
                ) : (
                  <>
                    <label className='form-label required'>Password</label>
                    <Field
                      type='password'
                      name='password'
                      placeholder='Type here'
                      className='form-control form-control-solid'
                    />
                    <div className='text-danger'>
                      <ErrorMessage name='password' />
                    </div>
                  </>
                )}
              </Col>
            </Row>
            <Row className='mb-10'>
              <Col lg={12}>
                <label className='form-label required'>Password Confirmation</label>
                <Field
                  type='password'
                  name='passwordConfirmation'
                  placeholder='Type here'
                  className='form-control form-control-solid'
                />
                <div className='text-danger'>
                  <ErrorMessage name='passwordConfirmation' />
                </div>
              </Col>
            </Row>
            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
              <Link to='/auth/login'>
                <button
                  type='button'
                  className='btn btn-lg btn-light-primary fw-bolder me-4'
                  disabled={formikProps.isSubmitting}>
                  Back to Sign in
                </button>
              </Link>{' '}
              <button
                type='submit'
                className='btn btn-lg btn-primary fw-bolder'
                disabled={formikProps.isSubmitting}>
                <span className='indicator-label'>
                  Submit{' '}
                  {formikProps.isSubmitting ? (
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  ) : (
                    ''
                  )}
                </span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
