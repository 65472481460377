import API from '../helper/API';
import { showToast } from '../helper';

type paymentInitiateRequest = {
  amount: string | number;
  phone: string;
  redirectUrl: string;
};
export const usePhonepe = () => {
  const paymentInitiate = async (request: paymentInitiateRequest) => {
    try {
      let response = await API.post(`/ledger/credit/pay`, request);
      showToast('Payment Initiated successfully.', 'success');
      return response?.data?.data?.url;
    } catch (error: any) {
      showToast(error?.response?.data?.errorMessage, 'error');
      return error?.response?.data?.errorMessage;
    }
  };

  const paymentInitiateForSignup = async (request: any) => {
    try {
      let response = await API.post(`/payment/initiate`, request);
      return response?.data?.data;
    } catch (error: any) {
      showToast(error?.response?.data?.errorMessage, 'error');
      return error?.response?.data?.errorMessage;
    }
  };

  const CheckPaymentStatus = async (transactionId: string) => {
    try {
      let response = await API.get(`payment/status/${transactionId}`);
      return response?.data?.data;
    } catch (error: any) {
      showToast(error?.response?.data?.errorMessage, 'error');
      return error?.response?.data?.errorMessage;
    }
  };
  return {
    paymentInitiate,
    paymentInitiateForSignup,
    CheckPaymentStatus
  };
};
