/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import {
  DateRange,
  DateRangePicker,
  LocalizationProvider,
  PickersShortcutsItem,
  PickersShortcutsProps,
  SingleInputDateRangeField
} from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { ListGroup } from 'react-bootstrap';
import moment, { Moment } from 'moment';
import classNames from 'classnames';

import { OA_DATE_FORMAT_FE_BASIC } from '../constants';
import { areDatesSame, generateRange } from '../helper';

interface IOARangePicker {
  name: string;
  dateRange: DateRange<Moment | Date | null>;
  onChangeRangePicker: (
    range: DateRange<Moment | Date | null>,
    minDate: Moment | Date | undefined
  ) => void;
  displayFormat?: string;
  openDirection?: 'up' | 'down';
  minDate?: Date | Moment;
  maxDate?: Date | Moment;
  className?: string;
  readOnly?: boolean;
  tripDuration?: any;
  block?: boolean;
  shortcuts?: boolean;
}

export default function OARangePicker(props: IOARangePicker) {
  const {
    displayFormat = OA_DATE_FORMAT_FE_BASIC,
    minDate = null,
    maxDate = null,
    className = '',
    dateRange
  } = props;

  const [start, setStart] = useState<Moment | Date | null>(null);
  const [end, setEnd] = useState<Moment | Date | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    dateRange?.[0] ? setStart(moment(dateRange[0])) : !minDate && maxDate ? setStart(null) : null;
    dateRange?.[1] ? setEnd(moment(dateRange[1])) : !minDate && maxDate ? setEnd(null) : null;
  }, [dateRange]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && open === true) {
      // Close the date picker popup
      setOpen(false);
    }

    if (event.key === 'Enter' && open === false) {
      // Open the date picker popup
      setOpen(true);
    }
  };

  const CustomRangeShortcuts = (props: PickersShortcutsProps<DateRange<Moment>>) => {
    const { items, onChange, isValid } = props;
    if (items == null || items.length === 0) {
      return null;
    }

    const resolvedItems = items?.map(item => {
      const newValue = item?.getValue({ isValid });
      const isStartDateSame = areDatesSame(moment(newValue?.[0]), start as Moment);
      const isEndDateSame = areDatesSame(moment(newValue?.[1]), end as Moment);

      const draftProps = {
        onClick: () => {
          onChange(newValue);
        },
        disabled: !isValid(newValue) || (isStartDateSame && isEndDateSame),
        action: !(isStartDateSame && isEndDateSame),
        active: isStartDateSame && isEndDateSame
      };
      return <ListGroup.Item {...draftProps}>{item?.label}</ListGroup.Item>;
    });

    return (
      <div className='MuiPickersLayout-shortcuts oa_range-picker--shortcuts'>
        <ListGroup>{resolvedItems}</ListGroup>
      </div>
    );
  };

  return (
    <div className={classNames('oa_range-picker', className)}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateRangePicker
          open={open}
          onClose={() => setOpen(false)}
          value={[start, end] as DateRange<moment.Moment>}
          onChange={(val: any) => {
            val?.[0]?.isValid() ? setStart(val?.[0]) : null;
            val?.[1]?.isValid() ? setEnd(val?.[1]) : null;
            val?.[0]?.isValid() && val?.[1]?.isValid()
              ? props?.onChangeRangePicker(val, props?.minDate)
              : null;
          }}
          slots={{
            field: SingleInputDateRangeField,
            shortcuts: props?.shortcuts ? CustomRangeShortcuts : undefined
          }}
          slotProps={{
            textField: {
              size: 'small',
              onClick: () => setOpen(true),
              onKeyDown: handleKeyPress
            },
            shortcuts: {
              items: props?.shortcuts ? shortcutsItems : undefined
            }
          }}
          format={displayFormat}
          minDate={props?.minDate && moment(props?.minDate!)}
          maxDate={props?.maxDate && moment(props?.maxDate!)}
        />
      </LocalizationProvider>
    </div>
  );
}

const shortcutsItems: PickersShortcutsItem<DateRange<Moment>>[] = [
  {
    label: 'Today',
    getValue: () => generateRange(0)
  },
  {
    label: 'Yesterday',
    getValue: () => generateRange(1, 'days')
  },
  {
    label: 'Last Week',
    getValue: () => generateRange(1, 'weeks')
  },
  {
    label: 'Last Month',
    getValue: () => generateRange(1, 'months')
  },
  {
    label: 'Current Month',
    getValue: () => [moment().startOf('month'), moment()]
  }
];
