import { useEffect, useState } from 'react';
import { toAbsoluteUrl } from '../../helper';

interface INoInternet {
  children: any;
}

const NoInternetConnection = (props: INoInternet) => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState<boolean>(true);

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  // event listeners to update the state
  window.addEventListener('online', () => {
    setOnline(true);
  });

  window.addEventListener('offline', () => {
    setOnline(false);
  });

  // if user is online, return the child component else return a custom component
  if (isOnline) {
    return props.children;
  } else {
    return (
      <div className='d-flex flex-column flex-root'>
        <div
          className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
          style={{
            backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/progress-hd.png')}')`
          }}>
          <div className='d-flex flex-column flex-column-fluid text-center p-10 py-lg-20'>
            <a href='/dashboard' className='mb-10 pt-lg-20'>
              <img
                alt='Logo'
                src={toAbsoluteUrl(
                  `/media/logos/${
                    window.location.host === 'world.evisas.io' ? 'logo-alsaffron.svg' : 'logo-1.svg'
                  }`
                )}
                className='h-50px mb-5'
              />
            </a>
            <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>Internet connection lost!</h1>

            <div className='fw-bold fs-3 text-gray-400 mb-15'>
              Please check your internet connection and try again. We appreciate your patience and
              understanding.
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default NoInternetConnection;
