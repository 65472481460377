import { ReactComponent as DoubleArrowIcon } from '../../assets/icons/Bi-directionArrows.svg';

interface IFlightTripCell {
  origin: string;
  destination?: string;
  twoWay?: boolean;
}

const FlightTripCell = (props: IFlightTripCell) => {
  const { origin, destination, twoWay } = props;
  return (
    <div
      className='fs-6 d-flex align-items-center gap-2'
      title={twoWay ? `${origin} <-> ${destination}` : `${origin} -> ${destination}`}>
      {origin}
      {twoWay ? <DoubleArrowIcon /> : '->'}
      {destination}
    </div>
  );
};

export default FlightTripCell;
