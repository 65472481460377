import moment, { now } from 'moment';
import { useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import classNames from 'classnames';

import { KTSVG } from '../../../../../_metronic/helpers';
import OABadge from '../../../../../components/widgets/OABadge';
import { useOrderContext } from '../../../context/OrderContext';
import { showDialog } from '../../../../../helper';
import { IOrderType } from '../../../types';
import OAButton from '../../../../../components/widgets/OAButton';
import { TravellersCell } from '../../../../../components/OAColumn';
import { IMember } from '../../../../../types';
import { ORDER_EVENT_TYPE_OPTIONS } from '../../../../../constants';

interface IEventCardProps {
  event: any;
  supplierViewAccess: boolean;
  toggleEventModal: () => void;
  archived?: boolean;
}

const EventCard = (props: IEventCardProps) => {
  const params = useParams<{ orderType: IOrderType; id?: any }>();
  const { orderData, archiveOrderEvent } = useOrderContext();

  const handleArchiveEvent = () => {
    showDialog({
      title: 'Archive Event?',
      text: 'Are you sure you want to archive this event?',
      icon: 'warning'
    }).then(result => {
      if (result?.isConfirmed) {
        archiveOrderEvent(props?.event?.id, params?.id);
      }
    });
  };

  const eventDateTime = moment.tz(
    props?.event?.scheduledOn?.datetime,
    props?.event?.scheduledOn?.timezone
  );
  const eventDate = eventDateTime.format('DD MMM, YYYY');
  const eventTime = eventDateTime.format('hh:mm A');
  const eventDocs = props?.event?.attachments?.map((doc: any) => {
    const docName = doc?.split('.amazonaws.com/')?.[1];
    const docType = docName?.split('.')?.[1];
    return { name: docName, type: docType, link: doc };
  });
  const draftEventScheduledOn = props?.event?.scheduledOn?.datetime;
  const badgeLabel = moment(draftEventScheduledOn).fromNow();

  const diff = moment(eventDate).diff(now(), 'days');
  const badgeColor = diff <= 2 ? 'light-danger' : 'secondary';
  const linkedMemberIds: Array<any> = props?.event?.linkedMemberIds;
  const members = orderData?.data?.members?.filter((obj: IMember) =>
    linkedMemberIds?.includes(obj?.id)
  );

  return (
    <div className={classNames('event-card', { archived: props?.event?.status === 'DELETED' })}>
      <div className='event-card--timing'>
        {!props?.archived ? (
          <OABadge type={badgeColor} label={badgeLabel} className='ms-2' />
        ) : null}
        <span className={classNames({ 'text-muted': props?.archived })}>
          {eventDate?.toUpperCase()}
        </span>
        <span className={classNames('fs-5 fw-bold', { 'text-muted': props?.archived })}>
          {eventTime}
        </span>
      </div>

      <div className='event-card--details'>
        <h6 className={classNames({ 'text-muted': props?.archived })}>
          {props?.event?.orderEventType === 'OTHERS'
            ? props?.event?.title
            : ORDER_EVENT_TYPE_OPTIONS?.find(
                (obj: any) => obj?.value === props?.event?.orderEventType
              )?.label}
        </h6>
        <span className={classNames('mb-3', { 'text-muted': props?.archived })}>
          {props?.event?.content}
        </span>
        <div className='d-flex flex-wrap'>
          {eventDocs?.map((doc: any) => (
            <a href={doc?.link} target='_blank' rel='noreferrer'>
              <div className='d-flex align-items-center me-4'>
                <KTSVG
                  path='/media/icons/duotune/files/default_icon.svg'
                  className='svg-icon svg-icon-2 svg-icon-gray-500'
                />
                <span className={classNames('ms-1', { 'text-muted': props?.archived })}>
                  {doc?.name}
                </span>
              </div>
            </a>
          ))}
        </div>
      </div>

      <div className='event-card--members'>
        <TravellersCell members={members} disabled={props?.archived} />
      </div>

      {props?.supplierViewAccess && props?.event?.status !== 'DELETED' && (
        <div className='event-card--actions'>
          <Dropdown>
            <Dropdown.Toggle variant='light' as={CustomToggleButton} drop='start' />
            <Dropdown.Menu>
              <Dropdown.Item onClick={props?.toggleEventModal}>Edit</Dropdown.Item>
              <Dropdown.Item onClick={handleArchiveEvent}>Archive</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </div>
  );
};

export default EventCard;

const CustomToggleButton = (props: any) => {
  return (
    <OAButton
      color='secondary'
      className='btn-icon btn-active-light-primary'
      onClick={props?.onClick}
      icon='/media/icons/duotune/general/gen053.svg'
      iconClassName='svg-icon-3'
      disabled={props?.disabled}
    />
  );
};
