import { AVATAR_COLORS } from '../../constants';

export default function UserAvatarsCell(props: { members: Array<any> }) {
  const draftMembers = props?.members?.map((obj: any) => ({
    shortName: obj?.firstName?.charAt(0).toUpperCase(),
    fullName: `${obj?.title} ${obj?.firstName} ${obj?.lastName}`
  }));

  const membersCount = draftMembers?.length;

  return (
    <div className='oa-symbol-group oa-symbol-hover flex-nowrap flex-grow-1 min-w-50px pe-2'>
      {draftMembers?.map(
        (obj: any, idx: number) =>
          idx <= 3 && (
            <div key={idx} className='oa-symbol oa-symbol-circle' title={obj?.fullName}>
              <span className='oa-symbol-label' style={{ background: AVATAR_COLORS[idx] }}>
                {obj?.shortName}
              </span>
            </div>
          )
      )}
      {draftMembers?.length > 4 ? (
        <div key={-1} className='oa-symbol oa-symbol-circle'>
          <span className='oa-symbol-label' style={{ background: AVATAR_COLORS[4] }}>
            +{membersCount - 4}
          </span>
        </div>
      ) : null}
    </div>
  );
}
