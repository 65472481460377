/* eslint-disable no-unused-vars */
import { Dispatch, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FileDownload from 'js-file-download';
import { isArray, isBoolean, isNull } from 'lodash';
import moment from 'moment';
import AWS from 'aws-sdk';

import API from '../../../helper/API';
import {
  DestinationOptionsMap,
  ORDER_CREATE_ACTIONS,
  ORDER_TYPE,
  orderFilterNavs
} from '../constants';
import OrderContext, { IOrderContext } from './OrderContext';
import { InvoiceType, IOrderFilterQueryParams, IOrderType } from '../types';
import { ICreateOrderFormState } from '../types';
import { downloadFile, getFormattedDate, setDateFormat, showToast } from '../../../helper';
import { ISubmitToEmbassyFormValues } from '../order-view/OrderActions/SubmitToEmbassyModal';
import { OA_DATE_FORMAT_BE } from '../../../constants';
import { IPageInfo } from '../../../components/OATableComponent';
import {
  getDraftOrderRequest,
  getItineraryRequest,
  getModifiedMembers,
  getModifiedOrderData
} from '../helper';
import { getDummyFlightRequest } from '../helper/getItineraryRequest';
import { toast } from 'react-toastify';
import { IInvoiceRequestBody } from '../helper/getInvoiceRequestBody';
import { getVisaInformationRequestBody } from '../../VisaInformation/helpers';
import { ICreateQuoteDraftValues, getDraftQuoteRequest } from '../helper/getDraftQuoteRequest';
import { handleDownload, handleS3Download } from '../../../helper/handleDownload';
import { IOrderModificationQuery } from '../helper/getModifiedOrderData';

const awsRegion = process.env.REACT_APP_AWS_REGION;
AWS.config.update({ region: awsRegion });
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: process.env.REACT_APP_AWS_IDENTITYPOOLID as string
});

interface ICreateOrderProvider {
  children: any;
  data: any;
  // TODO: define all types
}

const initFormState: ICreateOrderFormState = {
  tripDetails: {},
  tripDuration: {},
  travellerDetails: [],
  orderId: '',
  markup: {},
  quotationId: '',
  groupName: '',
  taxInfo: {},
  travellerFormFields: {
    requiredFields: [],
    optionalFields: []
  },
  category: null,
  cloneOrderId: null
};

function createOrderReducer(state: any, action: any) {
  switch (action.type) {
    case ORDER_CREATE_ACTIONS.ADD_TRIP_DETAILS:
      return {
        ...state,
        tripDetails: {
          ...state?.tripDetails,
          ...action.payload
        }
      };

    case ORDER_CREATE_ACTIONS.REMOVE_TRIP_DETAILS:
      const { trip } = state?.tripDetails;
      return {
        ...state,
        tripDetails: {
          trip
        }
      };

    case ORDER_CREATE_ACTIONS.ADD_TRAVELLER_FORM_FIELDS:
      return {
        ...state,
        travellerFormFields: action.payload?.travellerFormFields
      };

    case ORDER_CREATE_ACTIONS.ADD_TRIP_DURATION:
      return {
        ...state,
        tripDuration: action.payload?.tripDuration
      };

    case ORDER_CREATE_ACTIONS.ADD_OR_REMOVE_CONSULATE:
      const { consulate, ...stateWithoutConsulate } = state;
      return action.payload
        ? Object.assign({}, stateWithoutConsulate, { consulate: action.payload })
        : stateWithoutConsulate;

    case ORDER_CREATE_ACTIONS.ADD_MARKUP:
      return {
        ...state,
        markup: action.payload?.markup
      };

    case ORDER_CREATE_ACTIONS.ADD_GST:
      return {
        ...state,
        taxInfo: action.payload?.taxInfo
      };

    case ORDER_CREATE_ACTIONS.QUOTATION_ID:
      return {
        ...state,
        quotationId: action.payload
      };

    case ORDER_CREATE_ACTIONS.GROUP_NAME:
      return {
        ...state,
        groupName: action.payload
      };

    case ORDER_CREATE_ACTIONS.ADD_TRAVELLER_DETAILS:
      return {
        ...state,
        travellerDetails: [...state.travellerDetails, ...action.payload]
      };

    case ORDER_CREATE_ACTIONS.EDIT_TRAVELLER_DETAILS:
      const draftTravellers = state?.travellerDetails?.map((obj: any) => {
        if (obj?.id === action?.payload?.id) {
          return action?.payload;
        } else {
          return obj;
        }
      });
      return {
        ...state,
        travellerDetails: draftTravellers
      };

    case ORDER_CREATE_ACTIONS.MAKE_TRAVELLER_AS_PRIMARY:
      const draft_pri = state.travellerDetails.map((traveller: any) => {
        return Object.assign(
          {},
          {
            ...traveller,
            primary: traveller?.id === action.payload?.id
          }
        );
      });
      return {
        ...state,
        travellerDetails: draft_pri
      };

    case ORDER_CREATE_ACTIONS.ADD_ADDON_SERVICE:
      return {
        ...state,
        addOnServiceModifier: state?.addOnServiceModifier?.length
          ? [...state?.addOnServiceModifier, action.payload]
          : [action.payload]
      };

    case ORDER_CREATE_ACTIONS.ADD_ADDON_SERVICES:
      return {
        ...state,
        addOnServiceModifier: state?.addOnServiceModifier?.length
          ? [...state?.addOnServiceModifier, ...action.payload]
          : action.payload
      };

    case ORDER_CREATE_ACTIONS.REMOVE_ADDON_SERVICE:
      return {
        ...state,
        addOnServiceModifier: state?.addOnServiceModifier?.filter(
          (obj: any) => obj?.name !== action?.payload?.name
        )
      };

    case ORDER_CREATE_ACTIONS.MODIFY_TRAVELLERS:
      return {
        ...state,
        travellerDetails: action?.payload?.members,
        orderId: action?.payload?.orderId
      };

    case ORDER_CREATE_ACTIONS.DELETE_TRAVELLER:
      const draft_del = state?.travellerDetails?.filter(
        (traveller: any) => traveller?.id !== action.payload
      );
      return {
        ...state,
        travellerDetails: draft_del
      };

    case ORDER_CREATE_ACTIONS.SELECTED_ORDER:
      return action?.payload;

    case ORDER_CREATE_ACTIONS.CLONE_ORDER:
      return {
        ...state,
        cloneOrderId: action?.payload?.id,
        orderId: ''
      };

    case ORDER_CREATE_ACTIONS.RESET_FORM_STATE:
      return initFormState;

    case ORDER_CREATE_ACTIONS.ADD_TRIP_BOOKINGINFORMATION:
      return {
        ...state,
        tripDetails: {
          ...state?.tripDetails,
          trip: {
            ...state?.tripDetails?.trip,
            ...action.payload
          }
        }
      };

    case ORDER_CREATE_ACTIONS.ADD_INSURANCE_CATEGORY:
      return {
        ...state,
        category: action.payload
      };

    case ORDER_CREATE_ACTIONS.ADD_STAY_DETAILS:
      state.tripDetails.trip['bookingInformation'] = [action.payload];
      return state;

    case ORDER_CREATE_ACTIONS.UPDATE_STATE:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
}

export default function OrderProvider(props: ICreateOrderProvider) {
  const { queryParams, setQueryParams } = props?.data;
  const [formState, dispatch]: [ICreateOrderFormState, Dispatch<any>] = useReducer(
    createOrderReducer,
    initFormState
  );
  const navigate = useNavigate();
  // TODO: replace "any" with a proper type definition for products ...
  const [products, setProducts] = useState<Array<any> | null>(null);
  const [travellerId, setTravellerId] = useState<string | null>(null);
  const [draftOrder, setDraftOrder] = useState<any>(null);
  const [visaInfoProducts, setVisaInfoProducts] = useState<{
    data: Array<any> | null;
    isLoading: boolean;
  }>({
    data: null,
    isLoading: false
  });

  const [ordersData, setOrdersData] = useState<{ data: any | null; isLoading: boolean }>({
    data: null,
    isLoading: false
  });
  const [orderData, setOrderData] = useState<{ data: any | null; isLoading: boolean }>({
    data: null,
    isLoading: false
  });
  const [orderActions, setOrderActions] = useState<{ data: any | null; isLoading: boolean }>({
    data: null,
    isLoading: false
  });

  const [orderEvents, setOrderEvents] = useState<{ data: any | null; isLoading: boolean }>({
    data: null,
    isLoading: false
  });

  const [apiResponseFromClassifier, setApiResponseFromClassifier] = useState<any[]>([]);

  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    totalCount: 0,
    totalPages: 0,
    count: 0,
    size: 20,
    index: 1
  });
  const [isFetchingOcrData, setIsFetchingOcrData] = useState<boolean>(false);
  const [orderActivityLogs, setOrderActivityLogs] = useState<any[]>([]);

  const getInitQueryParams = () => setQueryParams;

  const updateFormState = (values: any) => {
    dispatch({
      type: ORDER_CREATE_ACTIONS.UPDATE_STATE,
      payload: values
    });
  };
  /**
   * NOTE: get api call `/${type}/order?${queryStr}`
   * @param type
   * @param cb
   */
  const fetchOrders = (type: IOrderType, cb?: (response: any) => void) => {
    try {
      API.get(`/${type}/order?${buildQueryParams(queryParams, false)}`)
        .then(response => {
          const result = response?.data?.data;

          setPageInfo(prev =>
            Object.assign({}, prev, {
              totalCount: result?.totalElements,
              totalPages: result?.totalPages,
              count: result?.numberOfElements
            })
          );
          cb && cb(response);
        })
        .catch(err => {
          cb && cb(err);
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      cb && cb(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };
  /**
   * NOTE: get api call `/${type}/status?id=${id}`
   * @param type
   * @param id
   * @param cb
   */
  const getOrderById = (
    type: IOrderType,
    id: string,
    query?: IOrderModificationQuery | null,
    cb?: () => void
  ) => {
    setOrderData(prev => ({ ...prev, isLoading: true }));
    try {
      API.get(`/${type}/status?id=${id}`)
        .then(response => {
          const resultData = response?.data?.data;
          const draft = getModifiedOrderData(resultData, type, query);
          /**
           * NOTE: 'onSelectDraftOrder' function will update form state ...
           */
          onSelectDraftOrder(draft);
          onAddTravellerFormFields({
            travellerFormFields: {
              requiredFields: resultData?.product?.requiredFields ?? [],
              optionalFields: resultData?.product?.optionalFields ?? []
            }
          });
          setOrderData(_prev => ({ data: resultData, isLoading: false }));
          cb?.();
        })
        .catch(err => {
          setOrderData(prev => ({ ...prev, isLoading: false }));
          showToast(err?.response?.data, 'error');
        });
    } catch (error: any) {
      setOrderData(prev => ({ ...prev, isLoading: false }));
      throw new Error(`API error: ${error?.message}`);
    }
  };
  /**
   * NOTE: this function will reset both orderData and orderActionData ...
   */
  const resetOrderData = () => {
    setOrderData({ data: null, isLoading: false });
    setOrderActions({ data: null, isLoading: false });
  };

  const resetProducts = () => setProducts(null);
  /**
   * NOTE: 'discoverMasterProduct' function will call a post api and will get
   * response back based on payload provided..
   */
  const discoverMasterProduct = async (
    type: IOrderType,
    values: any,
    cb?: (response?: any) => void
  ) => {
    const requestBody =
      type === 'visa'
        ? getDiscoverVisaTypeRequestBody(values)
        : getDiscoverInsuranceTypeRequestBody(values);
    const AncillaryType = type === 'visa' ? 'EVISA' : 'INSURANCE';

    try {
      API.post(`/discover/v2?type=${AncillaryType}`, requestBody)
        .then(response => {
          const result = response?.data?.data;
          const draftMasterProducts = result?.masterProduct?.filter(
            (obj: any) => !!obj?.displayName
          );
          setProducts(draftMasterProducts ?? []);
          let draftTrip = formState?.cloneOrderId
            ? Object.assign({}, result?.trip, {
                bookingInformation: formState?.tripDetails?.trip?.bookingInformation
              })
            : result?.trip;
          onAddTripDetails({ trip: draftTrip, id: null });
          if (type === 'visa' && values?.orderType === 'group' && values?.groupName !== '') {
            onAddGroupName(values?.groupName);
          } else {
            onAddGroupName('');
          }
          cb?.(result);
        })
        .catch(err => {
          setProducts(null);
          onAddTripDetails({});
          cb?.();
          showToast(err?.response?.data, 'error');
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };
  /**
   * NOTE: ...
   * @param values
   */
  const onAddTripDetails = (values: any) => {
    dispatch({
      type: ORDER_CREATE_ACTIONS.ADD_TRIP_DETAILS,
      payload: values
    });
  };

  const onAddTripBookingInformation = (values: any) => {
    dispatch({
      type: ORDER_CREATE_ACTIONS.ADD_TRIP_BOOKINGINFORMATION,
      payload: values
    });
  };

  const onRemoveTripDetails = () => {
    dispatch({
      type: ORDER_CREATE_ACTIONS.REMOVE_TRIP_DETAILS
    });
  };

  const onAddTravellerFormFields = (values: any) => {
    dispatch({
      type: ORDER_CREATE_ACTIONS.ADD_TRAVELLER_FORM_FIELDS,
      payload: values
    });
  };

  const onAddTripDuration = (values: any) => {
    dispatch({
      type: ORDER_CREATE_ACTIONS.ADD_TRIP_DURATION,
      payload: values
    });
  };

  const onAddConsulate = (values: any) => {
    dispatch({
      type: ORDER_CREATE_ACTIONS.ADD_OR_REMOVE_CONSULATE,
      payload: values
    });
  };

  const onRemoveConsulate = () => {
    dispatch({
      type: ORDER_CREATE_ACTIONS.ADD_OR_REMOVE_CONSULATE
    });
  };

  const onAddGroupName = (values: any) => {
    dispatch({
      type: ORDER_CREATE_ACTIONS.GROUP_NAME,
      payload: values
    });
  };

  const onAddCategory = (values: any) => {
    dispatch({
      type: ORDER_CREATE_ACTIONS.ADD_INSURANCE_CATEGORY,
      payload: values
    });
  };

  const onAddMarkup = (values: any) => {
    dispatch({
      type: ORDER_CREATE_ACTIONS.ADD_MARKUP,
      payload: values
    });
  };

  const onGstSubmit = (values: any) => {
    dispatch({
      type: ORDER_CREATE_ACTIONS.ADD_GST,
      payload: values
    });
  };

  const onQuotationId = (values: any) => {
    dispatch({
      type: ORDER_CREATE_ACTIONS.QUOTATION_ID,
      payload: values
    });
  };

  const onAddTravellerDetails = (values: any) => {
    const draftValues = formState?.travellerDetails?.length ? values : { ...values, primary: true };
    dispatch({
      type: ORDER_CREATE_ACTIONS.ADD_TRAVELLER_DETAILS,
      payload: [draftValues]
    });
  };

  const onEditTravellerDetails = (values: any) => {
    dispatch({
      type: ORDER_CREATE_ACTIONS.EDIT_TRAVELLER_DETAILS,
      payload: values
    });
  };

  const onSetTravellerAsPrimary = (values: { id: Date | number }) => {
    dispatch({
      type: ORDER_CREATE_ACTIONS.MAKE_TRAVELLER_AS_PRIMARY,
      payload: values
    });
  };

  const onDeleteTraveller = (id: string) => {
    dispatch({
      type: ORDER_CREATE_ACTIONS.DELETE_TRAVELLER,
      payload: id
    });
    showToast('Traveller deleted.', 'success');
  };

  const resetFormState = () => {
    dispatch({
      type: ORDER_CREATE_ACTIONS.RESET_FORM_STATE
    });
    setDraftOrder(null);
    setTravellerId(null);
    setProducts(null);
  };

  const onSelectDraftOrder = (values: any) => {
    dispatch({
      type: ORDER_CREATE_ACTIONS.SELECTED_ORDER,
      payload: values
    });
  };
  /**
   * NOTE: "onSelectEditTraveller" method capture the "id" of the selected
   * traveller to be edited and set it to a context state ...
   *
   * @param id
   */
  const onSelectEditTraveller = (id: string | null) => {
    setTravellerId(id);
  };
  /**
   * NOTE: post api call `/${type}/order` with "ORDER_QUOTE" status for order quotation.
   * - type: 'visa' | 'insurance'
   */
  const getDraftOrderQuotation = (
    type: IOrderType,
    values?: {
      markup?: any; // TODO: send 'markupAmount' amount as values, not separately...
      addOnService?: any;
      modifier?: any;
    } | null,
    markupAmount?: any,
    cb?: (res: any) => void
  ) => {
    /**
     * NOTE: remove 'convertToOrder' key from 'rewuestBody' when calling with
     * 'orderStatus' as 'ORDER_QUOTE'...
     */
    let { convertToOrder, ...requestBody } = getDraftOrderRequest(formState, type);
    requestBody = Object.assign({}, requestBody, {
      orderStatus: 'ORDER_QUOTE'
    });

    if (markupAmount && markupAmount !== 'downloadQuote') {
      requestBody = Object.assign({}, requestBody, {
        markup: markupAmount?.markup
      });
    }
    /**
     * NOTE: attach 'addOnServiceModifier', if available in incoming 'values' or
     * in 'formState'...
     */
    if (values?.addOnService) {
      const { action, ...addonServiceObj } = values?.addOnService;
      if (action === 'add') {
        const draftAddonServices = formState?.addOnServiceModifier?.length
          ? [...formState?.addOnServiceModifier, addonServiceObj]
          : [addonServiceObj];

        onAddAddonService(addonServiceObj);
        requestBody = Object.assign({}, requestBody, {
          addOnServiceModifier: draftAddonServices
        });
      }
      if (action === 'remove') {
        const draftAddonServices = formState?.addOnServiceModifier?.length
          ? formState?.addOnServiceModifier?.filter(
              (obj: any) => obj?.name !== addonServiceObj?.name
            )
          : null;

        onRemoveAddonService(addonServiceObj);
        requestBody = Object.assign({}, requestBody, {
          addOnServiceModifier: draftAddonServices
        });
      }
    } else if (formState?.addOnServiceModifier?.length) {
      requestBody = Object.assign({}, requestBody, {
        addOnServiceModifier: formState?.addOnServiceModifier
      });
    }
    if (values?.modifier) {
      if (values?.modifier?.key === 'PRODUCT_FEE') {
        formState.productAmountToBeCollected = values?.modifier?.amountCollected;
        requestBody.productAmountToBeCollected = values?.modifier?.amountCollected;
      } else if (values?.modifier?.key === 'VAC_SERVICE_CHARGE') {
        const draftAddOnServiceModifier = requestBody?.addOnServiceModifier?.map((obj: any) => {
          return obj?.type === 'VAC_SERVICE_CHARGE'
            ? Object.assign({}, obj, { amountCollected: values?.modifier?.amountCollected })
            : obj;
        });
        formState.addOnServiceModifier = draftAddOnServiceModifier;
        requestBody.addOnServiceModifier = draftAddOnServiceModifier;
      } else {
        const draftAddOnServiceModifier = requestBody?.addOnServiceModifier?.map((obj: any) => {
          return obj?.type === values?.modifier?.key && obj?.name === values?.modifier?.name
            ? Object.assign({}, obj, { amountCollected: values?.modifier?.amountCollected })
            : obj;
        });
        formState.addOnServiceModifier = draftAddOnServiceModifier;
        requestBody.addOnServiceModifier = draftAddOnServiceModifier;
      }
    }
    /**
     * NOTE: [api] call quote pdf download api for 'downloadQuote' key otherwise
     * call order api with orderStatus as 'ORDER_QUOTE' to get updated quotation
     * data...
     */
    if (markupAmount === 'downloadQuote') {
      try {
        API.post(`/${type}/quote/pdf`, requestBody)
          .then(response => {
            FileDownload(response?.data, `quote_${Date.now()}.pdf`);
            cb?.(response);
          })
          .catch(err => {
            cb?.(err?.response?.data);
            showToast(err?.response?.data, 'error');
          });
      } catch (error: any) {
        throw new Error(`API error: ${error?.message}`);
      }
    } else {
      if (values?.markup?.amount) {
        requestBody = Object.assign({}, requestBody, {
          markup: { amount: values?.markup?.amount }
        });
      } else if (isNull(values?.markup)) {
        const { markup, ...rest } = requestBody;
        requestBody = rest;
      }

      try {
        API.post(`/${type}/order`, requestBody)
          .then(response => {
            const result = response?.data?.data;
            setDraftOrder(result);
            cb?.(response);
          })
          .catch(err => {
            cb?.(err?.response?.data);
            showToast(err?.response?.data, 'error');
          });
      } catch (error: any) {
        throw new Error(`API error: ${error?.message}`);
      }
    }
  };
  /**
   * NOTE: post api call `/${type}/quote`
   * - type: 'visa' | 'insurance'
   * - markup: { amount: string }
   */
  const onDownloadQuote = (
    type: IOrderType,
    markup: { amount: string },
    cb?: (res: any) => void
  ) => {
    let requestBody = getDraftOrderRequest(formState, type);
    requestBody = Object.assign({}, requestBody, {
      orderStatus: 'ORDER_QUOTE'
    });
    if (markup?.amount) {
      requestBody.markup = markup;
    }

    try {
      API.post(`/${type}/quote/pdf`, requestBody)
        .then(response => {
          FileDownload(response.data, `quote_${Date.now()}.pdf`);
          cb?.(response);
        })
        .catch(err => {
          cb?.(err?.response?.data);
          showToast(err?.response?.data, 'error');
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };
  /**
   * NOTE: post api call `/${type}/order`
   * - type: 'visa' | 'insurance'
   */
  const onCreateDraftOrder = (type: IOrderType, cb?: (res: any) => void) => {
    const requestBody = getDraftOrderRequest(formState, type);

    try {
      API.post(`/${type}/order`, requestBody)
        .then(response => {
          const result = response?.data?.data;
          if (type === 'visa' && result?.groupId) {
            dispatch({
              type: ORDER_CREATE_ACTIONS.MODIFY_TRAVELLERS,
              payload: {
                members: [],
                orderId: ''
              }
            });
            getGroupById(type as IOrderType, result?.groupId);
          } else {
            const draftMembers = getModifiedMembers(result?.members);
            dispatch({
              type: ORDER_CREATE_ACTIONS.MODIFY_TRAVELLERS,
              payload: {
                members: draftMembers,
                orderId: result?.id
              }
            });
            setDraftOrder(result);
          }

          const draft_success_text =
            type === ORDER_TYPE.VISA
              ? 'Visa draft has been saved successfully.'
              : type === ORDER_TYPE.INSURANCE
              ? 'Insurance draft has been saved successfully.'
              : '';
          showToast(draft_success_text, 'success');
          cb?.(response);
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
          cb?.(err?.response);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };
  /**
   * NOTE: put api call `/${type}/order-info?id=${id}`
   * - type: 'visa'
   * - id: orderId
   */
  const onEditOrderInfo = (id: string, type: IOrderType, values?: any, cb?: (res: any) => void) => {
    const requestBody = getTripRequestBody(formState, type, values);
    try {
      API.put(`/${type}/order-info?id=${id}`, requestBody)
        .then(response => {
          const result = response?.data?.data;
          setDraftOrder(result);
          showToast('Travel Information updated successfully', 'success');
          cb?.(response);
        })
        .catch(err => {
          cb?.(err?.response);
          showToast(err?.response?.data, 'error');
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };
  /**
   * NOTE: put api call `/${type}/order?id=${id}`
   * - type: 'visa'
   * - id: orderId
   */
  const onEditDraftOrder = (
    id: string,
    type: IOrderType,
    values?: any,
    cb?: (res: any) => void
  ) => {
    if ((values && values?.markup) || (values && values?.markup === null)) {
      formState.markup = values?.markup ? values?.markup : null; // TODO: call reducer function to change values in formState.
    } else if (draftOrder && draftOrder?.markup) {
      formState.markup = draftOrder?.markup
        ? { amount: draftOrder?.markup?.tax?.totalAmountWithoutTax }
        : null;
    }

    if ((values && values?.taxInfo) || (values && values?.taxInfo === null)) {
      formState.taxInfo = values?.taxInfo;
    } else if (draftOrder && draftOrder?.data?.taxInfo) {
      formState.taxInfo = draftOrder?.data?.taxInfo
        ? { type: 'GST', taxRegistrationNumber: draftOrder?.data?.taxInfo?.taxRegistrationNumber }
        : null;
    }
    // assigning value to formState.additionalOrderInfo.clientName undefined value
    if (formState.additionalOrderInfo) {
      formState.additionalOrderInfo.clientName =
        orderData?.data?.additionalOrderInfo?.clientName ?? null;
    }
    let requestBody = getDraftOrderRequest(formState, type, id, values);
    /**
     * NOTE: check if values contains 'addOnService', modify requestBody by
     * checking addOnService's action and call reducer function as well to update
     * formState...
     */
    if (values?.addOnService) {
      const { action, ...addonServiceObj } = values?.addOnService;
      if (action === 'add') {
        const draftAddonServices = requestBody?.addOnServiceModifier?.length
          ? [...requestBody?.addOnServiceModifier, addonServiceObj]
          : [addonServiceObj];

        onAddAddonService(addonServiceObj);
        requestBody = Object.assign({}, requestBody, {
          addOnServiceModifier: draftAddonServices
        });
      }
      if (action === 'remove') {
        const draftAddonServices = requestBody?.addOnServiceModifier?.length
          ? requestBody?.addOnServiceModifier?.filter(
              (obj: any) => obj?.name !== addonServiceObj?.name
            )
          : null;

        onRemoveAddonService(addonServiceObj);
        requestBody = Object.assign({}, requestBody, {
          addOnServiceModifier: draftAddonServices
        });
      }
    }
    if (values?.modifier) {
      if (values?.modifier?.key === 'PRODUCT_FEE') {
        formState.productAmountToBeCollected = values?.modifier?.amountCollected;
        requestBody.productAmountToBeCollected = values?.modifier?.amountCollected;
      } else if (values?.modifier?.key === 'VAC_SERVICE_CHARGE') {
        const draftAddOnServiceModifier = requestBody?.addOnServiceModifier?.map((obj: any) => {
          return obj?.type === 'VAC_SERVICE_CHARGE'
            ? Object.assign({}, obj, { amountCollected: values?.modifier?.amountCollected })
            : obj;
        });
        formState.addOnServiceModifier = draftAddOnServiceModifier;
        requestBody.addOnServiceModifier = draftAddOnServiceModifier;
      } else {
        const draftAddOnServiceModifier = requestBody?.addOnServiceModifier?.map((obj: any) => {
          return obj?.type === values?.modifier?.key && obj?.name === values?.modifier?.name
            ? Object.assign({}, obj, { amountCollected: values?.modifier?.amountCollected })
            : obj;
        });
        formState.addOnServiceModifier = draftAddOnServiceModifier;
        requestBody.addOnServiceModifier = draftAddOnServiceModifier;
      }
    }

    try {
      API.put(`/${type}/order?id=${id}`, requestBody)
        .then(response => {
          const result = response?.data?.data;
          const draftMembers = getModifiedMembers(result?.members);

          dispatch({
            type: ORDER_CREATE_ACTIONS.MODIFY_TRAVELLERS,
            payload: {
              members: draftMembers,
              orderId: result?.id
            }
          });
          if (result?.consulate) onAddConsulate(result?.consulate);

          setDraftOrder(result);
          setOrderData({ data: result, isLoading: false });
          cb?.(response);
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
          cb?.(err?.response);
        });
    } catch (error: any) {
      cb?.(error?.response);
      throw new Error(`API error: ${error?.message}`);
    }
  };
  /**
   * NOTE: 'onAddAddonService' function will get called when someone add addon service.
   * on call this function, update formstate by adding this addon service.
   * @param values
   */
  const onAddAddonService = (values: any) => {
    dispatch({
      type: ORDER_CREATE_ACTIONS.ADD_ADDON_SERVICE,
      payload: values
    });
  };
  const onAddAddonServices = (values: Array<any>) => {
    dispatch({
      type: ORDER_CREATE_ACTIONS.ADD_ADDON_SERVICES,
      payload: values
    });
  };
  /**
   * NOTE: 'onRemoveAddonService' function will get called when someone remove addon service.
   * on call this function, update formstate by removing this addon service.
   * @param values
   */
  const onRemoveAddonService = (values: any) => {
    dispatch({
      type: ORDER_CREATE_ACTIONS.REMOVE_ADDON_SERVICE,
      payload: values
    });
  };
  /**
   * NOTE: post api call `/${type}/submit?id=${orderId}`
   * - type: 'visa' | 'insurance'
   * - id: orderId
   */
  const onSubmitOrder = (
    values: any,
    type: IOrderType,
    orderId: string,
    cb?: (res: any) => void
  ) => {
    try {
      let apiRequest;
      if (values) {
        const requestBody = getAdditionalDetailsRequestBody(values);
        apiRequest = API.post(`/${type}/submit?id=${orderId}`, requestBody);
      } else {
        apiRequest = API.post(`/${type}/submit?id=${orderId}`);
      }
      apiRequest
        .then(_response => {
          const draft_success_text =
            type === ORDER_TYPE.VISA
              ? 'Visa submitted successfully.'
              : type === ORDER_TYPE.INSURANCE
              ? 'Insurance submitted successfully.'
              : '';
          showToast(draft_success_text, 'success');
          getOrderById(type, orderId);
          cb?.(_response);
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
          cb?.(err?.response);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };
  /**
   * NOTE:
   */
  const onSubmitReview = (
    type: IOrderType,
    orderId: string,
    values: ISubmitToEmbassyFormValues | null,
    cb?: (res?: any) => void
  ) => {
    try {
      API.post(`/${type}/submit-review?id=${orderId}`, values ?? {})
        .then(_response => {
          const draft_success_text =
            type === ORDER_TYPE.VISA
              ? 'Visa reviewed and submitted successfully.'
              : type === ORDER_TYPE.INSURANCE
              ? 'Insurance reviewed and submitted successfully.'
              : '';
          showToast(draft_success_text, 'success');
          getOrderById(type, orderId);
          cb?.(_response);
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
          cb?.(err?.response);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };
  /**
   * NOTE: post api call `/${type}/cancel?id=${orderId}`
   * - type: 'visa' | 'insurance'
   * - id: orderId
   */
  const onCancelOrder = (
    type: IOrderType,
    orderId: string,
    approve: boolean | null,
    values?: any,
    cb?: (response: any) => void
  ) => {
    if (!isNull(approve) && isBoolean(approve)) {
      try {
        API.post(
          `/insurance/cancel/approve?id=${orderData?.data?.id!}&accept=${approve}`,
          values && values
        )
          .then(response => {
            const toastText = approve
              ? 'Insurance cancelled successfully.'
              : 'Insurance cancellation request rejected.';
            showToast(toastText, 'warning');
            getOrderById(ORDER_TYPE.INSURANCE, orderData?.data?.id!);
            fetchActivityLogs(orderData?.data?.id!, false);
            cb && cb(response);
          })
          .catch(err => {
            showToast(err?.response?.data, 'error');
          });
      } catch (error: any) {
        throw new Error(`API error: ${error?.message}`);
      }
    } else {
      try {
        API.post(`/${type}/cancel?id=${orderId}`)
          .then(response => {
            const draft_success_text =
              type === ORDER_TYPE.VISA
                ? 'Visa cancelled successfully.'
                : type === ORDER_TYPE.INSURANCE
                ? 'Insurance cancelled successfully.'
                : '';
            showToast(draft_success_text, 'success');
            getOrderById(type, orderId);
            cb && cb(response);
          })
          .catch(err => {
            showToast(err?.response?.data, 'error');
          });
      } catch (error: any) {
        throw new Error(`API error: ${error?.message}`);
      }
    }
  };
  /**
   *
   */
  const onRequestInsuranceCancellation = (
    orderId: string,
    values: any,
    cb?: (response: any) => void
  ) => {
    try {
      API.post(`/${ORDER_TYPE.INSURANCE}/cancel?id=${orderId!}`, values)
        .then(response => {
          showToast('Insurance cancellation requested successfully.', 'success');
          getOrderById(ORDER_TYPE.INSURANCE, orderId!);
          cb && cb(response);
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };
  /**
   * NOTE: get api call `/visa/download/${id}`
   * - id: orderId
   */
  const onDownloadVisa = (orderId: string, cb?: (response: any) => void) => {
    try {
      API.get(`/visa/download/${orderId}`)
        .then(response => {
          downloadFile(response?.data?.data);
          cb?.(response);
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
          cb?.(err?.response);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };
  /**
   * NOTE: get api call `/visa/invoice/${id}?type=${invoiceType}`
   * - id: orderId
   * - invoiceType
   */
  const onDownloadVisaInvoice = (
    orderId: string,
    invoiceType: InvoiceType,
    cb?: (response: any) => void
  ) => {
    try {
      API.get(`/visa/invoice/${orderId}?type=${invoiceType}`)
        .then(response => {
          downloadFile(response?.data?.data);
          cb?.(response);
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
          cb?.(err?.response);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };
  /**
   * NOTE: get api call `/${type}/quotation/${id}`
   * - type: 'visa' | 'insurance'
   * - id: string
   */
  const getQuotationById = (type: IOrderType, id: string) => {
    setOrderData(prev => ({ ...prev, isLoading: true }));
    try {
      API.get(`/${type}/quotation/${id}`)
        .then(response => {
          const resultData = response?.data?.data;

          const tripDetails = Object.assign(
            {},
            {
              trip: resultData?.trip,
              memberCount: resultData?.memberCount,
              supplierOrganization: resultData?.supplierOrganization,
              ...resultData?.product
            }
          );
          const tripDuration = {
            from: resultData?.trip?.tripStart?.datetime
              ? new Date(resultData?.trip?.tripStart?.datetime)
              : null,
            to: resultData?.trip?.tripEnd?.datetime
              ? new Date(resultData?.trip?.tripEnd?.datetime)
              : null
          };
          onAddTripDetails(tripDetails);
          onAddTravellerFormFields({
            travellerFormFields: {
              requiredFields: resultData?.product.requiredFields ?? [],
              optionalFields: resultData?.product.optionalFields ?? []
            }
          });
          onAddTripDuration({ tripDuration: tripDuration });
          onAddTravellerFormFields({
            travellerFormFields: {
              requiredFields: resultData?.product.requiredFields ?? [],
              optionalFields: resultData?.product.optionalFields ?? []
            }
          });
          onQuotationId(id);
          if (resultData?.consulate) onAddConsulate(resultData?.consulate);
          if (resultData?.markup?.amount) onAddMarkup(resultData);
        })
        .catch(err => {
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };
  /**
   * NOTE: OrderActions useful for supplier view only ...
   */
  const getOrderActions = (orderId: string, cb?: (response: any) => void) => {
    setOrderActions(prev => ({ ...prev, isLoading: true }));

    try {
      API.get(`/order/actions?orderId=${orderId}`)
        .then(response => {
          const resultData = response?.data?.data;
          setOrderActions({ data: resultData, isLoading: false });
          cb?.(resultData);
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
          setOrderActions(prev => ({ ...prev, isLoading: false }));
          cb?.([]);
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      setOrderActions(prev => ({ ...prev, isLoading: false }));
      throw new Error(`API error: ${error?.message}`);
    }
  };
  /**
   * NOTE: 'createOrderAction' method is getting used for creating order action by
   * supplier only ...
   */
  const createOrderAction = (values: object, cb?: (response: any) => void) => {
    try {
      API.post(`/order/actions`, values)
        .then(response => {
          const resultData = response?.data?.data;
          getOrderById('visa', resultData?.orderId);
          setOrderActions(prev => ({ ...prev, data: [...prev?.data, resultData] }));
          cb?.(resultData);
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };
  /**
   * NOTE: 'updateOrderAction' method is getting used for updating order action by
   * supplier only ...
   */
  const updateOrderAction = (actionId: string, values: object, cb?: (response: any) => void) => {
    try {
      API.put(`/order/actions/${actionId}`, values)
        .then(response => {
          const resultData = response?.data?.data;
          const nextOrderActions = orderActions?.data?.map((action: any) =>
            action?.id === resultData?.id ? resultData : action
          );
          setOrderActions(prev => ({ ...prev, data: nextOrderActions }));
          cb?.(resultData);
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };
  /**
   * NOTE: 'approveOrCompleteOrderAction' method is getting used for approval and complete
   * order action for supplier and consumer respectively...
   */
  const approveOrCompleteOrderAction = (
    actionId: string,
    status: 'APPROVED' | 'COMPLETED',
    cb?: (response: any) => void
  ) => {
    try {
      API.patch(`/order/actions/${actionId}?status=${status}`)
        .then(response => {
          const resultData = response?.data?.data;
          const nextOrderActions = orderActions?.data?.map((action: any) =>
            action?.id === resultData?.id ? resultData : action
          );
          setOrderActions(prev => ({ ...prev, data: nextOrderActions }));
          cb?.(resultData);
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };
  /**
   * NOTE: 'deleteOrderAction' method is getting used for deleting order action...
   */
  const deleteOrderAction = (actionId: string, cb?: (response: any) => void) => {
    try {
      API.delete(`/order/actions/${actionId}`)
        .then(response => {
          if (response?.data?.httpStatus === 200) {
            const nextOrderActions = orderActions?.data?.filter(
              (action: any) => action?.id !== actionId
            );
            setOrderActions(prev => ({ ...prev, data: nextOrderActions }));
            cb?.(nextOrderActions);
          }
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };
  /**
   * NOTE: comment
   */
  const createActionComment = (values: object, id: string, cb?: (response: any) => void) => {
    try {
      API.patch(`/order/actions/${id}`, values)
        .then(response => {
          const resultData = response?.data?.data;
          setOrderActions(prev => ({
            ...prev,
            data: prev?.data?.map((act: any) => (act?.id === resultData?.id ? resultData : act))
          }));

          cb?.(resultData);
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };
  /**
   * /products/visa/document?search=Passport
   */
  const searchAdditionalDocuments = (cb?: (response: any) => void) => {
    try {
      API.get(`/products/visa/document?search=`)
        .then(response => {
          const resultData = response?.data?.data;
          cb?.(resultData);
        })
        .catch(err => {
          showToast(err?.response?.data?.errors, 'error');
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const getCountriesList = async (cb?: (response?: any) => void) => {
    try {
      API.get(`/products/countries`)
        .then(response => {
          const resultData = response?.data?.data;
          cb?.(resultData);
        })
        .catch(err => {
          cb?.(err);
          throw new Error(`API error: ${err?.message}`);
        });
    } catch (error) {
      cb?.(error);
      throw new Error(`API error: ${error}`);
    }
  };
  /**
   * NOTE:
   * @param orderId
   * @param userId
   * @param cb
   */
  const assignReviewer = (
    orderId: string,
    userId: string,
    isEdit: boolean,
    cb?: (response: any) => void
  ) => {
    try {
      API.put(`/visa/${orderId}/assign-user/${userId}`)
        .then(response => {
          const resultData = response?.data?.data;

          if (isEdit) {
            showToast('Order reviewer updated successfully.', 'success');
            setOrderData(prev => ({ ...prev, data: resultData }));
          } else {
            showToast('Order reviewer added successfully.', 'success');
            setOrdersData(prev => {
              const draftContent =
                prev?.data?.content?.length &&
                prev?.data?.content?.map((obj: any) =>
                  obj?.linkedOrder?.id === resultData?.id
                    ? Object.assign({}, obj, { linkedOrder: resultData })
                    : obj
                );
              return {
                data: Object.assign({}, prev?.data, { content: draftContent }),
                isLoading: prev?.isLoading
              };
            });
          }
          cb?.(resultData);
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };
  /**
   *
   * @param type
   * @param orderId
   * @param values
   * @param cb
   */
  const updateOrderInfo = (
    type: IOrderType,
    orderId: string,
    values: any,
    cb?: (res?: any) => void
  ) => {
    const { message, ...restValues } = getDraftOrderInfo(values);

    try {
      API.put(`/${type}/order-info?id=${orderId}`, restValues)
        .then(response => {
          showToast(message, 'success');
          getOrderById(type, orderId);
          cb?.(response);
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
          cb?.(err?.response);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };
  /**
   *
   * @param type
   * @param orderId
   * @param memberId
   * @param values
   * @param cb
   */
  const updateMemberVisaStatus = (
    type: IOrderType,
    orderId: string,
    memberId: string,
    values: any,
    cb?: (res?: any) => void
  ) => {
    try {
      let statusUrl =
        type == 'visa' ? `/${type}/status/${orderId}` : `/${type}/${orderId}/members/${memberId}`;
      API.put(statusUrl, values)
        .then(response => {
          showToast('Member status updated successfully.', 'success');
          getOrderById(type, orderId);
          cb?.(response);
        })
        .catch(err => {
          showToast(err?.response?.data?.errors, 'error');
          cb?.(err?.response);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  /** Group Order
   * NOTE: get api call `/group-orders?${queryStr}`
   * - cb?: // callback function
   */
  const fetchOrderGroups = (type: IOrderType, cb?: (response: any) => void) => {
    try {
      API.get(`/group-orders?${buildQueryParams(queryParams, true)}`)
        .then(response => {
          cb && cb(response);

          const result = response?.data?.data;

          setPageInfo(prev =>
            Object.assign({}, prev, {
              totalCount: result?.totalElements,
              totalPages: result?.totalPages,
              count: result?.numberOfElements
            })
          );
        })
        .catch(err => {
          cb && cb(err);
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      cb && cb(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const onCreateGroup = (type: IOrderType) => {
    const requestBodyTemp = getDraftOrderRequest(formState, type);
    const requestBody = {
      groupName: formState?.groupName,
      trip: requestBodyTemp?.trip,
      productId: formState?.tripDetails?.id
    };

    try {
      API.post(`/group-orders`, requestBody)
        .then(response => {
          const result = response?.data?.data;
          showToast('Group has been created successfully.', 'success');
          navigate(`/apps/orders/visa/group/${result?.id}`);
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const getGroupById = (type: IOrderType, id: string | undefined) => {
    setOrderData(prev => ({ ...prev, isLoading: true }));
    /**
     * NOTE: removing 'orderStatus' from queryParams as we are showing all orders
     * in one table in group order details page for now.
     */
    const draftQueryParams = Object.assign({}, queryParams, {
      orderStatus: '',
      size: 50
    });
    try {
      API.get(`/group-orders/v2/${id}?${buildQueryParams(draftQueryParams, false)}`)
        .then(response => {
          const resultData = response?.data?.data;

          setPageInfo(prev =>
            Object.assign({}, prev, {
              totalCount: resultData?.totalElements,
              totalPages: resultData?.totalPages,
              count: resultData?.numberOfElements
            })
          );
          const tripDuration = {
            from: resultData?.groupOrder?.trip?.tripStart?.datetime
              ? new Date(resultData?.groupOrder?.trip?.tripStart?.datetime)
              : null,
            to: resultData?.groupOrder?.trip?.tripEnd?.datetime
              ? new Date(resultData?.groupOrder?.trip?.tripEnd?.datetime)
              : null
          };
          onAddTripDetails({
            ...resultData?.groupOrder?.trip,
            id: resultData?.groupOrder?.productId,
            trip: resultData?.groupOrder?.trip,
            productInformation: resultData?.groupOrder?.product?.productInformation,
            supplierOrganization: resultData?.groupOrder?.supplierOrganization
          });
          onAddTravellerFormFields({
            travellerFormFields: {
              requiredFields: resultData?.groupOrder?.product.requiredFields ?? [],
              optionalFields: resultData?.groupOrder?.product.optionalFields ?? []
            }
          });
          onAddGroupName(resultData?.groupOrder?.id);
          onAddTripDuration({ tripDuration: tripDuration });
          dispatch({
            type: ORDER_CREATE_ACTIONS.ADD_TRAVELLER_DETAILS,
            payload: []
          });
          setOrderData(_prev => ({ data: resultData, isLoading: false }));
        })
        .catch(err => {
          setOrderData(prev => ({ ...prev, isLoading: false }));
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      setOrderData(prev => ({ ...prev, isLoading: false }));
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const createBulkOrderGroup = (values: any, groupId: string, cb?: (res: any) => void) => {
    try {
      API.post(`/visa/orders?groupId=${groupId}`, values, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(response => {
          showToast(
            'File has been uploaded. We’ll send you an email once orders are ready.',
            'warning'
          );
          cb?.(response);
        })
        .catch(err => {
          cb?.(err?.response?.data);
          showToast(err?.response?.data, 'error');
        });
    } catch (error: any) {
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  // function called only for insurance. TODO : refactore with above funcation
  const createBulkOrder = (
    values: any,
    type: IOrderType,
    productCode?: string,
    cb?: (res: any) => void
  ) => {
    try {
      API.post(`/${type}/orders/v2?productCode=${productCode}`, values, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(response => {
          cb?.(response);
        })
        .catch(err => {
          cb?.(err);
          showToast(err?.response?.data?.errors, 'error');
        });
    } catch (error: any) {
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const onModifyTravellers = () => {
    dispatch({
      type: ORDER_CREATE_ACTIONS.MODIFY_TRAVELLERS,
      payload: {
        members: [],
        orderId: ''
      }
    });
  };

  const getOcrResponseByImgUrl = (imgUrl: any, cb: any) => {
    setIsFetchingOcrData(true);
    if (imgUrl?.front || imgUrl?.back) {
      let reqBody = {};
      if (imgUrl?.front) {
        reqBody = {
          ...reqBody,
          frontImage: imgUrl?.front
        };
      }

      if (imgUrl?.back) {
        reqBody = {
          ...reqBody,
          backImage: imgUrl?.back
        };
      }

      try {
        API.post(`/document/ocr?documentType=passport`, reqBody, {})
          .then((response: any) => {
            const result = response?.data?.data;
            cb?.(result);
            setIsFetchingOcrData(false);
          })
          .catch(error => {
            showToast(error?.response?.data?.errors, 'error');
          });
      } catch (error: any) {
        throw new Error(`API error: ${error?.message}`);
      }
    }
  };

  const createOrderEvent = (values: any, cb?: (res: any) => void) => {
    try {
      API.post(`/order/events`, values)
        .then(response => {
          showToast('Event created successfully.', 'success');
          cb?.(response);
        })
        .catch(err => {
          cb?.(err);
          showToast(err?.response?.data, 'error');
        });
    } catch (error: any) {
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const getOrderEventById = (id: string, cb?: (res: any) => void) => {
    setOrderEvents(prev => ({ ...prev, isLoading: true }));

    try {
      API.get(`/order/events?orderId=${id}`)
        .then(response => {
          const result = response?.data;
          setOrderEvents({ data: result, isLoading: false });
          cb?.(response);
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
          setOrderEvents(prev => ({ ...prev, isLoading: false }));
          cb?.([]);
        });
    } catch (error: any) {
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const editOrderEvent = (values: any, cb?: (res: any) => void) => {
    try {
      API.put(`/order/events/${values?.id}`, values)
        .then(response => {
          showToast('Event updated successfully.', 'success');
          cb?.(response);
        })
        .catch(err => {
          cb?.(err);
          showToast(err?.response?.data, 'error');
        });
    } catch (error: any) {
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const archiveOrderEvent = (eventId: string, orderId: string, cb?: (res: any) => void) => {
    try {
      API.delete(`/order/events/${eventId}`)
        .then(response => {
          showToast('Event deleted successfully.', 'success');
          getOrderEventById(orderId);
          cb?.(response);
        })
        .catch(err => {
          cb?.(err);
          showToast(err?.response?.data, 'error');
        });
    } catch (error: any) {
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const sendCommentNotification = (orderId: string, comment: any) => {
    try {
      const reqBody = {
        subject: `A new comment has been added for Order-${orderId}`,
        message: comment?.content
      };
      API.post(`orders/email?orderId=${orderId}`, reqBody, {})
        .then((response: any) => {
          const result = response?.data?.data;
        })
        .catch(error => {
          showToast(error?.response?.data?.errors, 'error');
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const onCreateItinerary = (values: any, cb?: (res: any) => void) => {
    const lambda = new AWS.Lambda();
    const requestBody = getItineraryRequest(values);

    try {
      lambda.invoke(requestBody, async function (err: any, data: any) {
        if (err) {
          showToast(err, 'error');
          cb?.(err);
        } else {
          cb?.(JSON.parse(data?.Payload));
        }
      });
    } catch (error: any) {
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const createDummyFlight = (values: any, cb?: (res: any) => void) => {
    const lambda = new AWS.Lambda();
    const requestBody = getDummyFlightRequest(values);

    try {
      lambda.invoke(requestBody, async function (err: any, data: any) {
        if (err) {
          showToast(err, 'error');
          cb?.(err);
        } else {
          cb?.(JSON.parse(data?.Payload));
          const draft_success_text =
            'Success! Your Dummy Flight Booking is sent to our Team. We will get back to you shortly.';
          showToast(draft_success_text, 'success');
        }
      });
    } catch (error: any) {
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const onDuplicateOrder = (id: string) => {
    dispatch({
      type: ORDER_CREATE_ACTIONS.CLONE_ORDER,
      payload: { id }
    });
    setDraftOrder(null);
  };

  const shareLinkWithTraveller = (
    orderId: string,
    emailNotification: boolean,
    cb?: (res?: any) => void
  ) => {
    try {
      API.post(`/visa/share-link/${orderId}?emailNotification=${emailNotification}`)
        .then(response => {
          if (emailNotification) showToast('email sent successfully.', 'success');
          cb?.(response);
        })
        .catch(err => {
          cb?.(err);
          showToast(err?.response?.data, 'error');
        });
    } catch (error: any) {
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const validateMemberByPassportNumber = (passportNumber: string, cb?: (res?: any) => void) => {
    try {
      API.get(`member/validate?passportNumber=${passportNumber}`)
        .then(response => {
          cb?.(response);
        })
        .catch(err => {
          cb?.(err);
          showToast(err?.response?.data, 'error');
        });
    } catch (error: any) {
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const updateProforma = (request: IInvoiceRequestBody, cb?: (res?: any) => void) => {
    try {
      API.put(`/visa/invoice/proforma`, request)
        .then(response => {
          getOrderById(ORDER_TYPE.VISA, request?.orderId, null, () => cb?.(response));
          showToast('Invoice updated successfully.', 'success');
        })
        .catch(err => {
          cb?.(err);
          showToast(err?.response?.data, 'error');
        });
    } catch (error: any) {
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const generateInvoice = (request: IInvoiceRequestBody, cb?: (res?: any) => void) => {
    try {
      API.post(`/visa/invoice`, request)
        .then(response => {
          cb?.(response);
          getOrderById(ORDER_TYPE.VISA, request?.orderId);
          showToast('Invoice generated successfully.', 'success');
        })
        .catch(err => {
          cb?.(err);
          showToast(err?.response?.data, 'error');
        });
    } catch (error: any) {
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const updateInvoice = (
    request: IInvoiceRequestBody,
    invoiceId: string,
    cb?: (res?: any) => void
  ) => {
    try {
      API.put(`/visa/invoice/${invoiceId}`, request)
        .then(response => {
          cb?.(response);
          getOrderById(ORDER_TYPE.VISA, request?.orderId);
          showToast('Invoice updated successfully.', 'success');
        })
        .catch(err => {
          cb?.(err);
          showToast(err?.response?.data, 'error');
        });
    } catch (error: any) {
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const fetchDocumentsFromClassifier = (
    zipFile: any,
    memberId: string,
    cb?: (res?: any) => void
  ) => {
    const payload = new FormData();

    payload.append('file', zipFile.blob, zipFile.fileName);
    payload.append('id', memberId);
    try {
      API.post(`v2/document/classifier`, payload, {
        headers: {
          'Content-Type': 'mutlipart/form-data'
        }
      })
        .then(response => {
          setApiResponseFromClassifier((prevResponses: any) => {
            const previous = Array.isArray(prevResponses) ? prevResponses : [];
            return [...previous, ...response.data.data];
          });
          cb?.(response);
        })

        .catch(err => {
          cb?.(err);
          showToast(err?.message, 'error');
        });
    } catch (error: any) {
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const updateDocumentClassifierAction = async (
    memberId: string,
    data: any[],
    selectedDocumentId: string,
    isSubmit: boolean,
    cb?: (res?: any) => void
  ) => {
    try {
      const submitValue = isSubmit ? 'true' : 'false';
      const toastMessage = isSubmit
        ? 'Documents submitted successfully'
        : 'Document deleted successfully';

      const response = await API.put(
        `document/classifier?id=${memberId}&submit=${submitValue}`,
        data
      );

      showToast(toastMessage, 'success');
      cb?.(response?.data?.data);
      return response?.data?.data;
    } catch (error: any) {
      cb?.(error);
      showToast(error?.response?.data, 'error');
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const deleteDocument = (visaId: string, memberId: string, documentId: string, cb?: any) => {
    try {
      API.delete(`visa/order/document?id=${visaId}&memberId=${memberId}&documentId=${documentId}`)
        .then(response => {
          showToast('Document deleted successfully', 'success');
          cb?.(response);
        })
        .catch(err => {
          cb?.(err);
          showToast(err?.response?.data, 'error');
        });
    } catch (error: any) {
      cb?.(error);
      showToast(error?.response?.data, 'error');
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const uploadDocument = async (
    orderId: string,
    memberId: string,
    doc: any,
    imageUrl: string,
    fileName: string,
    fileExtension: string,
    cb?: any
  ) => {
    const requestBody = {
      bookingId: orderId,
      members: [
        {
          id: memberId,
          documents: [
            {
              fieldName: doc?.fieldName,
              data: imageUrl,
              fileName: fileName,
              fileExtension: fileExtension
            }
          ]
        }
      ]
    };

    try {
      const response = await toast.promise(API.post(`/visa/order/document`, requestBody), {
        pending: 'Document uploading...',
        success: 'Uploaded',
        error: 'Error'
      });

      cb?.(null, response);
      return response;
    } catch (error: any) {
      cb?.(error);
      throw error;
    }
  };

  const visaInfoProduct = async (values: any, cb?: (response?: any) => void) => {
    setVisaInfoProducts(prev => ({ ...prev, isLoading: true }));
    const requestBody = getVisaInformationRequestBody(values);
    try {
      API.post(`/visa/products/info`, requestBody)
        .then(response => {
          const result = response?.data?.data;
          setVisaInfoProducts({ data: result?.visas ?? [], isLoading: false });
          cb?.(result);
        })
        .catch(error => {
          setVisaInfoProducts({ data: null, isLoading: false });
          cb?.();
          showToast(error?.response?.data?.errors, 'error');
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const fetchInsuranceProducts = (insuranceType: string, userId?: string, cb?: any) => {
    let config: any = {
      headers: {}
    };
    // Add headers only if userId is provided
    if (userId) {
      config.headers.userId = userId;
    }
    API.get(`/insurance/products/consumer?insuranceCategory=${insuranceType}`, config)
      .then(response => {
        const result = response?.data;
        cb?.(result);
      })
      .catch(error => {
        showToast(error?.response?.data?.errors, 'error');
      });
  };

  const onCreateDraftQuote = (
    type: IOrderType,
    values: ICreateQuoteDraftValues,
    cb?: (response?: any) => void
  ) => {
    const requestBody = getDraftQuoteRequest(type, formState, values, 'create', {
      onAddAddonService,
      onRemoveAddonService,
      onAddMarkup,
      updateFormState
    });
    /**
     * DEVNOTE: 'visa' and 'insurance' both are using same api for creating draft quote ...
     */
    try {
      API.post(`/${type}/order/quote`, requestBody)
        .then(response => {
          setDraftOrder(response?.data?.data);
          cb?.(response);
        })
        .catch(error => {
          showToast(error?.response?.data?.errors, 'error');
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const fetchActivityLogs = (orderId: string, isGroup: boolean, cb?: any) => {
    try {
      API.get(`/order/activity?${isGroup ? 'groupOrderId' : 'linkedOrderId'}=${orderId}`)
        .then(response => {
          const result = response?.data?.data;
          setOrderActivityLogs(result);
          cb?.(result);
        })
        .catch(error => {
          showToast(error?.response?.data?.errors, 'error');
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const getInsuranceCategory = (cb?: any) => {
    try {
      API.get(`/discover/insurance/categories`)
        .then(response => {
          const result = response?.data?.data;
          cb?.(result);
        })
        .catch(error => {
          showToast(error?.response?.data?.errors, 'error');
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const onUpdateDraftQuote = (
    id: string,
    type: IOrderType,
    values: ICreateQuoteDraftValues,
    cb?: (response?: any) => void
  ) => {
    const requestBody = getDraftQuoteRequest(type, formState, values, 'update', {
      onAddAddonService,
      onRemoveAddonService,
      onAddMarkup,
      updateFormState
    });

    /**
     * DEVNOTE: 'visa' and 'insurance' both are using same api for creating draft quote ...
     */
    try {
      API.put(`/${type}/order/quote?id=${id}`, requestBody)
        .then(response => {
          setDraftOrder(response?.data?.data);
          getOrderById(type, id);
          cb?.(response);
        })
        .catch(error => {
          showToast(error?.response?.data?.errors, 'error');
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const onShareQuotation = (
    values: any,
    type: IOrderType,
    quotationId: string | null,
    cb?: () => void
  ) => {
    let requestBody = quotationRequestBody(values, orderData?.data);

    try {
      API.put(`/${type}/share/quote/${quotationId}`, requestBody)
        .then((response: any) => {
          const resultData = response?.data?.data;
          cb?.();
          if (values?.downloadFile && resultData?.pdfUrl) {
            handleS3Download(resultData?.pdfUrl);
          }
        })
        .catch((err: any) => {
          showToast(err?.response?.data, 'error');
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const contextValue: IOrderContext = {
    ordersData,
    orderData,
    orderActions,
    products,
    formState,
    travellerId,
    draftOrder,
    orderEvents,
    pageInfo,
    isFetchingOcrData,
    apiResponseFromClassifier,
    orderActivityLogs,
    getInitQueryParams,
    updateFormState,
    fetchOrders,
    getOrderById,
    resetOrderData,
    discoverMasterProduct,
    visaInfoProduct,
    visaInfoProducts,
    onCreateDraftOrder, // works for both 'visa' and 'insurance'
    onEditDraftOrder, // works for both 'visa' and 'insurance'
    onEditOrderInfo, // works only for 'visa'
    onSubmitOrder, // works for both 'visa' and 'insurance'
    onCancelOrder, // works for both 'visa' and 'insurance'
    onRequestInsuranceCancellation,
    getDraftOrderQuotation,
    onAddTravellerFormFields,
    onAddTripDetails,
    onRemoveTripDetails,
    onAddConsulate,
    onRemoveConsulate,
    onAddMarkup,
    onGstSubmit,
    onAddTravellerDetails,
    onEditTravellerDetails,
    onDeleteTraveller,
    onSetTravellerAsPrimary,
    onAddAddonService,
    onRemoveAddonService,
    onSelectEditTraveller,
    onSelectDraftOrder,
    resetFormState,
    onDownloadVisa,
    onDownloadVisaInvoice,
    onDownloadQuote,
    setOrderData,
    setOrderActions,
    resetProducts,
    getOrderActions,
    createOrderAction,
    updateOrderAction,
    createActionComment,
    approveOrCompleteOrderAction,
    deleteOrderAction,
    onSubmitReview,
    searchAdditionalDocuments,
    getQuotationById,
    getCountriesList,
    assignReviewer,
    updateOrderInfo,
    updateMemberVisaStatus,
    fetchOrderGroups,
    getGroupById,
    onAddGroupName,
    onCreateGroup,
    createBulkOrderGroup,
    onModifyTravellers,
    createBulkOrder,
    getOcrResponseByImgUrl,
    createOrderEvent,
    setOrderEvents,
    getOrderEventById,
    editOrderEvent,
    archiveOrderEvent,
    sendCommentNotification,
    onAddTripBookingInformation,
    onAddCategory,
    onCreateItinerary,
    onDuplicateOrder,
    shareLinkWithTraveller,
    validateMemberByPassportNumber,
    createDummyFlight,
    updateProforma,
    generateInvoice,
    updateInvoice,
    fetchDocumentsFromClassifier,
    updateDocumentClassifierAction,
    setApiResponseFromClassifier,
    deleteDocument,
    uploadDocument,
    fetchInsuranceProducts,
    onCreateDraftQuote,
    onUpdateDraftQuote,
    onShareQuotation,
    fetchActivityLogs,
    setOrderActivityLogs,
    getInsuranceCategory
  };

  return <OrderContext.Provider value={contextValue}>{props?.children}</OrderContext.Provider>;
}

// helper functions ...
const buildQueryParams = (queryParams: IOrderFilterQueryParams, isGroup: boolean): string => {
  let queryStr = `page=${queryParams?.pageIndex - 1}&size=${queryParams?.size}`;
  if (queryParams?.search && queryParams?.search !== '') {
    queryStr = `${queryStr}&search=${queryParams?.search}`;
  }
  if (!isGroup && queryParams?.orderStatus && queryParams?.orderStatus !== '') {
    let ifFound = orderFilterNavs?.find((obj: any) => obj === queryParams?.orderStatus);
    queryStr = ifFound
      ? queryParams?.orderStatus === 'ORDER_ALL'
        ? queryStr
        : `${queryStr}&bookingStatus=${queryParams?.orderStatus}`
      : `${queryStr}&bookingStatus=ORDER_DRAFT`;
  }
  if (queryParams?.createdBy && queryParams?.createdBy !== '') {
    queryStr = `${queryStr}&createdBy=${queryParams?.createdBy}`;
  }
  if (queryParams?.dateRange.length > 0) {
    const startDate = queryParams?.dateRange[0]
      ? getFormattedDate(queryParams?.dateRange[0], OA_DATE_FORMAT_BE)
      : null;
    const endDate = queryParams?.dateRange[1]
      ? getFormattedDate(queryParams?.dateRange[1], OA_DATE_FORMAT_BE)
      : null;
    queryStr = `${queryStr}&start=${startDate}&end=${endDate}`;
  }

  if (queryParams?.sortBy && queryParams?.sortByField) {
    queryStr = `${queryStr}&sortByField=${queryParams?.sortByField}&sortBy=${queryParams?.sortBy}`;
  }
  return queryStr;
};

const getDiscoverVisaTypeRequestBody = (values: any) => {
  const draft: any = {
    trip: {
      visaType: values?.visaType,
      origin: {
        // "iata": "BLR",
        // "city": "Bangalore",
        state: values?.state,
        countryCode: values?.departure
      },
      destination: [
        {
          // "iata": "DXB",
          // "city": "Dubai",
          countryCode: values?.arrival
        }
      ]
    }
  };
  if (values?.tripDetails) {
    draft.trip.tripDetails = values?.tripDetails;
  }
  if (values?.occupation) {
    draft.occupation = values?.occupation;
  }
  return draft;
};

const getDiscoverInsuranceTypeRequestBody = (values: any) => {
  const localTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let draft: any = {
    insuranceCategory: values?.insuranceType || 'STANDARD_TRAVEL_INSURANCE',
    trip: {
      origin: {
        countryCode: values?.departure
      },
      destination: Array.isArray(values.arrival)
        ? values?.arrival?.map((countryCode: string) => ({ countryCode }))
        : values?.arrival?.split(',').map((country: string) => ({ countryCode: country })),
      tripStart: {
        datetime: setDateFormat.startOfDayForBE(new Date(values?.tripDuration?.from)),
        timezone: localTZ
      },
      domestic: isBoolean(values?.domestic)
        ? values?.domestic
        : values?.destination === DestinationOptionsMap.DOMESTIC,
      geographicalScope: values?.destination === 'ASIA' ? 'ASIA' : null
    }
  };
  if (values?.tripDuration?.to) {
    draft.trip.tripEnd = {
      datetime: setDateFormat.endOfDayForBE(new Date(values?.tripDuration?.to)),
      timezone: localTZ
    };
  }

  return draft;
};

const getTripRequestBody = (formState: ICreateOrderFormState, type: IOrderType, values?: any) => {
  const localTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let draft: any = {
    trip: {
      tripType: formState?.tripDetails?.trip?.tripType,
      visaType: formState?.tripDetails?.trip?.visaType,
      tripStart: {
        datetime: setDateFormat.startOfDayForBE(formState?.tripDuration?.from),
        timezone: localTZ
      },
      tripEnd: {
        datetime: setDateFormat.endOfDayForBE(formState?.tripDuration?.to),
        timezone: localTZ
      },
      origin: {
        // iata: "BLR",
        // city: "Bangalore",
        countryCode: formState?.tripDetails?.trip?.origin?.countryCode
      },
      destination: formState?.tripDetails?.trip?.destination
    }
  };
  /**
   * NOTE: if "values" contains "tripDuration" then check "start" and "end" of
   * "tripDuration" and attach with "draft" as part of "trip" ...
   */
  if (values?.tripDuration?.from) {
    draft.trip.tripStart.datetime = setDateFormat.startOfDayForBE(values?.tripDuration?.from);
  }
  if (values?.tripDuration?.to) {
    draft.trip.tripEnd.datetime = setDateFormat.endOfDayForBE(values?.tripDuration?.to);
  }

  return draft;
};

const getAdditionalDetailsRequestBody = (values: any) => {
  let draft: any = {};

  if (values?.clientName) {
    draft.clientName = values?.clientName;
  }
  if (values?.paymentReference) {
    draft.paymentReference = values?.paymentReference;
  }
  if (values?.tags) {
    draft.tags = values?.tags?.replaceAll(' ', '')?.split(',');
  }

  return draft;
};

const getDraftOrderInfo = (values: any) => {
  let draft: any = {};

  if (values?.applicationId) {
    draft.applicationId = values?.applicationId;
    draft.message = 'Application id updated successfully.';
  } else if (values?.orderStatus) {
    draft.orderStatus = values?.orderStatus;
    if (values?.remark) {
      draft.remark = values?.remark;
    }
    draft.message = 'Order status updated successfully.';
  } else if (values?.additionalValues) {
    draft.additionalOrderInfo = {};
    if (values?.additionalValues?.clientName) {
      draft.additionalOrderInfo.clientName = values?.additionalValues?.clientName;
    }
    if (values?.additionalValues?.paymentReference) {
      draft.additionalOrderInfo.paymentReference = values?.additionalValues?.paymentReference;
    }
    if (values?.additionalValues?.tags) {
      draft.additionalOrderInfo.tags = values?.additionalValues?.tags;
    }
    draft.message = 'Order Info updated successfully.';
  }

  return draft;
};

const quotationRequestBody = (values: any, data: any) => {
  const localTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const regex = /^[A-Z]{2}(,[A-Z]{2})*$/; // Regular expression to match the format
  let arrivalArray =
    !isArray(values?.arrival) && regex?.test(values?.arrival)
      ? values?.arrival?.split(',')
      : values?.arrival;
  values.arrival = Object.assign([], arrivalArray);

  let arrival: any;
  if (values?.arrival && isArray(values?.arrival)) {
    arrival = values?.arrival?.map((item: any) => ({ countryCode: item }));
  } else {
    arrival = [{ countryCode: values?.arrival }];
  }

  let origin: any;
  if (values?.state && values?.state !== '') {
    origin = {
      countryCode: values?.departure,
      state: values?.state
    };
  } else {
    origin = {
      countryCode: values?.departure
    };
  }

  let draft: any = {
    // requestedProducts: [data?.product?.id],
    // trip: {
    //   tripStart: data?.trip?.tripStart,
    //   tripEnd: data?.trip?.tripEnd,
    //   origin: data?.trip?.origin,
    //   destination: data?.trip?.destination
    // },
    // memberCount: data?.memberCount,
    proposerInfo: {
      name: values?.proposerName,
      email: values?.proposerEmail,
      companyName: data?.proposerInfo?.companyName
    }
    // save: false
  };
  if (values?.additionalEmail) {
    draft['additionalEmails'] = values?.additionalEmail.toString().split(',');
  }

  // if (!!values?.occupation) {
  //   draft['occupation'] = values?.occupation;
  // }
  if (values?.tripType) {
    draft['trip']['tripType'] = values?.tripType;
  }
  if (values?.visaType) {
    draft['trip']['visaType'] = values?.visaType;
  }
  if (values?.markup) {
    draft['markup'] = { amount: values?.markup };
  }

  const isDomestic = values?.destination === DestinationOptionsMap.DOMESTIC;
  if (isDomestic || (isDomestic && values?.tripType !== 'SINGLE')) {
    draft['trip']['tripEnd'] = {
      datetime: setDateFormat.endOfDayForBE(new Date(values?.tripDuration?.to)),
      timezone: localTZ
    };
    draft['trip']['domestic'] = true;
  }
  // if (!isNull(values?.consulate)) {
  //   draft['consulate'] = values?.consulate;
  // }
  return draft;
};
