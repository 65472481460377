import React from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { useOrderContext } from '../../../order-management/context/OrderContext';

const AdditionalNotes = () => {
  const {
    orderData: { data }
  } = useOrderContext();

  const {
    product: {
      productInformation: { additionalNotes }
    }
  } = data;

  return (
    <React.Fragment>
      {additionalNotes && additionalNotes?.lenght > 0 && (
        <div className='dev--quotation__additional_notes mb-8'>
          <h1 className='fw-bolder mb-3 text-dark fs-4'>Additional Notes</h1>
          {additionalNotes.map((obj: any, i: number) => (
            <div key={i} className='d-flex align-items-center mb-1'>
              <KTSVG path='/media/icons/duotune/arrows/arr085.svg' className='svg-icon-1 pe-2' />
              <span className='fw-bold fs-7 text-gray-700 '>{obj}</span>
            </div>
          ))}
        </div>
      )}
    </React.Fragment>
  );
};

export default AdditionalNotes;
