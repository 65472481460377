import { Box } from '@mui/material';
import { KTSVG } from '../../../../../_metronic/helpers';
import { OAStatusChip, OATypography } from '../../../../../components/widgets';
import { OA_SHORT_TIME_FORMAT_FE } from '../../../../../constants';
import { formatWordWithUnderscore, getFormattedDate } from '../../../../../helper';
import { svgPathMapping } from './helpers/svgPathMapping';
import { useParams } from 'react-router-dom';

interface IActivityLogItem {
  item: any;
  dataExchange?: any;
}

const ActivityLogItem: React.FC<IActivityLogItem> = ({ item, dataExchange }) => {
  const { orderType } = useParams();
  const status =
    orderType === 'insurance' ? dataExchange?.INSURANCE_STATUS : dataExchange?.VISA_STATUS;

  function createStatusObj(statusArray: any, item: any) {
    const foundStatus: any = statusArray?.find((status: any) => status?.value === item?.status);
    if (foundStatus) {
      return {
        status: item?.status,
        variant: foundStatus?.type ?? 'warning',
        label: foundStatus?.label
      };
    } else {
      return null;
    }
  }
  const statusObj = createStatusObj(status, item);
  const firstName = item?.performedBy?.userData?.firstName ?? 'Platform';
  const lastName = item?.performedBy?.userData?.lastName ?? '';
  const fullName = `${firstName} ${lastName}`;

  let content;
  switch (item?.type) {
    case 'TASK':
      content = item?.taskType ? (
        <>
          <OATypography variant='body2' sx={{ fontWeight: 500 }} className='me-2'>
            {fullName}
          </OATypography>{' '}
          <OATypography sx={{ color: theme => theme.palette.grey[400] }}>
            requested for
          </OATypography>{' '}
          <OATypography variant='body2' sx={{ fontWeight: 500 }} className='mx-2'>
            {formatWordWithUnderscore(item.taskType)}
          </OATypography>
        </>
      ) : null;
      break;
    case 'ORDER_STATUS':
      if (item.status === 'ORDER_DRAFT') {
        content = (
          <>
            <OATypography variant='body2' sx={{ fontWeight: 500 }} className='me-2'>
              {fullName}
            </OATypography>{' '}
            <OATypography sx={{ color: theme => theme.palette.grey[400] }}>
              created order in
            </OATypography>
          </>
        );
      } else if (item.status === 'CREATED') {
        content = (
          <>
            <OATypography variant='body2' sx={{ fontWeight: 500 }} className='me-2'>
              {fullName}
            </OATypography>{' '}
            <OATypography sx={{ color: theme => theme.palette.grey[400] }}>
              created a group order
            </OATypography>
          </>
        );
      } else {
        content = (
          <>
            <OATypography variant='body2' sx={{ fontWeight: 500 }} className='me-2'>
              {fullName}
            </OATypography>{' '}
            <OATypography sx={{ color: theme => theme.palette.grey[400] }}>
              changed order to
            </OATypography>
          </>
        );
      }
      break;
    case 'MESSAGE':
      content = (
        <>
          <OATypography variant='body2' sx={{ fontWeight: 500 }} className='me-2'>
            {fullName}
          </OATypography>{' '}
          <OATypography sx={{ color: theme => theme.palette.grey[400] }}>
            added a new comment
          </OATypography>
        </>
      );
      break;
    default:
      content = null;
  }

  return (
    <div className='d-flex w-100'>
      <div className='timeline-line w-30px'></div>
      <div className='timeline-icon ms-1'>
        <KTSVG path={svgPathMapping[item?.type]} className='svg-icon-2x me-4' />
      </div>
      <div className='w-100 mb-8'>
        <div className='d-flex justify-content-between flex-column w-100'>
          <div className='d-flex justify-content-between'>
            <div className='d-flex align-items-center'>
              {content}
              {item.type === 'ORDER_STATUS' && item.status !== 'CREATED' && (
                <div className='ms-3'>
                  <OAStatusChip
                    status={statusObj?.status}
                    label={statusObj?.label}
                    variant={statusObj?.variant}
                  />
                </div>
              )}
            </div>
            <div>
              <OATypography
                variant='body2'
                sx={{
                  color: theme => theme?.palette?.grey?.[600]
                }}>
                {getFormattedDate(item?.createdAt, OA_SHORT_TIME_FORMAT_FE)}
              </OATypography>
            </div>
          </div>
          <div></div>
        </div>
        {item?.message && (
          <Box
            sx={{
              backgroundColor: '#F6F8FA',
              borderRadius: '8px',
              marginTop: '12px',
              padding: '10px 16px'
            }}>
            <OATypography
              variant='body2'
              sx={{
                color: theme => theme?.palette?.grey?.[600]
              }}>
              {item.message}
            </OATypography>
          </Box>
        )}
      </div>
    </div>
  );
};

export default ActivityLogItem;
