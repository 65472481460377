import { useContext, useState } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { lowerCase, capitalize, compact } from 'lodash';

import OALabelValue from '../../../../../components/widgets/OALabelValue';
import OrderContext from '../../../context/OrderContext';
import BookingInfoFormModal from './BookingInfoFormModal';

interface IBookingInfo {
  isEditable: boolean;
}

export default function BookingInfo(props: IBookingInfo) {
  const { orderData } = useContext(OrderContext);

  const [show, setShow] = useState<boolean>(false);

  const bookingInformation = orderData?.data?.trip?.bookingInformation?.[0];
  const dataArr = [
    bookingInformation?.propertyType && {
      label: 'Stay Type',
      value: capitalize(lowerCase(bookingInformation?.propertyType)),
      col: 3
    },
    bookingInformation?.propertyName && {
      label: 'Stay Name',
      value: bookingInformation?.propertyName,
      col: 3
    },
    bookingInformation?.propertyAddress?.line1 && {
      label: 'Stay Address',
      value: getAddressStr(bookingInformation?.propertyAddress),
      col: 6
    },
    bookingInformation?.propertyPhone && {
      label: 'Stay Phone',
      value: bookingInformation?.propertyPhone,
      col: 3
    }
  ];

  return bookingInformation?.propertyAddress?.line1 ? (
    <div id='booking_info' className='booking-info card mb-6'>
      <div className='card-header align-items-center border-0'>
        <h3 className='card-title align-items-start flex-column'>
          <div className='d-flex align-items-center text-gray-800 fw-bolder'>
            <span className='fw-bolder text-dark'>Stay details</span>
          </div>
        </h3>
        <div className='d-flex align-items-center gap-2 gap-lg-3'>
          <Button
            size='sm'
            className='btn-light-primary btn-active-light-primary me-3'
            onClick={() => setShow(true)}>
            Edit stay details
          </Button>
        </div>
      </div>
      <div className='separator' />
      {orderData?.isLoading ? (
        <div className='card-body d-flex align-items-center justify-content-center text-muted fw-bold'>
          <Spinner animation='border' className='me-4' /> Loading...
        </div>
      ) : (
        <div className='card-body'>
          <Row>
            {compact(dataArr)?.map((obj, idx) => (
              <Col key={idx} md={obj?.col ?? 3}>
                <OALabelValue label={obj?.label} value={obj?.value} />
              </Col>
            ))}
          </Row>
        </div>
      )}
      <BookingInfoFormModal show={show} onHide={() => setShow(false)} />
    </div>
  ) : null;
}

// helper functions
const getAddressStr = (address: any) => {
  const { line1, city, district, subDistrict, postalCode } = address;
  let addressComponents = [
    line1,
    capitalize(lowerCase(city)),
    capitalize(lowerCase(district)),
    capitalize(lowerCase(subDistrict)),
    postalCode
  ];
  let addressStr = addressComponents.filter(component => component).join(', ');
  return addressStr;
};
