import React, { Dispatch } from 'react';
import { IFlightQueryParams } from '../type';
import { IPageInfo } from '../../../components/OATableComponent';

export interface IFlightContext {
  // queryParams: IFlightQueryParams;
  pageInfo: IPageInfo;
  // setQueryParams: Dispatch<React.SetStateAction<IFlightQueryParams>>;
  getFlightOrderList: (cb?: ((response?: any) => void) | undefined) => Promise<void>;
  getOrderById: (id: string, cb?: ((response?: any) => void) | undefined) => Promise<void>;
  getCancelReasons: (id: string, cb?: ((response?: any) => void) | undefined) => Promise<void>;
  cancelFlightsBooking: (
    id: string,
    requestBody: any,
    cb?: ((response?: any) => void) | undefined
  ) => Promise<void>;
  updatePNR: (
    draft: any,
    queryDraft: any,
    cb?: ((response?: any) => void) | undefined
  ) => Promise<void>;
  updateStatus: (draft: any, cb?: ((response?: any) => void) | undefined) => Promise<void>;
  downloadInvoice: (orderId: any, cb?: ((response?: any) => void) | undefined) => Promise<void>;
  downloadReport: () => void;
  report: { isLoading: boolean };
  statusChange: boolean;
}

const FlightContext = React.createContext<IFlightContext>({} as IFlightContext);

export default FlightContext;
