import classNames from 'classnames';
import { IconType } from '.';

export default function CommentSVG(props: IconType) {
  const {
    color = '#B5B5C2',
    width = '15',
    height = '15',
    // isActive = false,
    className = ''
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 15 14'
      fill='none'
      className={classNames('icon-svg comment-svg', className)}
      xmlns='http://www.w3.org/2000/svg'>
      <path
        opacity='0.3'
        d='M0 0.75V9.75C0 10.2 0.3 10.5 0.75 10.5H8.25L10.95 13.2C11.325 13.575 12 13.35 12 12.75V10.5H14.25C14.7 10.5 15 10.2 15 9.75V0.75C15 0.3 14.7 0 14.25 0H0.75C0.3 0 0 0.3 0 0.75Z'
        fill={color}
      />
      <path
        d='M12 4.5H3C2.55 4.5 2.25 4.2 2.25 3.75C2.25 3.3 2.55 3 3 3H12C12.45 3 12.75 3.3 12.75 3.75C12.75 4.2 12.45 4.5 12 4.5ZM10.5 6.75C10.5 6.3 10.2 6 9.75 6H3C2.55 6 2.25 6.3 2.25 6.75C2.25 7.2 2.55 7.5 3 7.5H9.75C10.2 7.5 10.5 7.2 10.5 6.75Z'
        fill={color}
      />
    </svg>
  );
}
