import React from 'react';
import { MenuItem } from './MenuItem';
import { MenuInnerWithSub } from './MenuInnerWithSub';
import { useAuth } from '../../../../auth';

const PICKYOURTRAILORGID = [process.env.REACT_APP_PICK_YOUR_TRAIL_ORG_ID];
export function MenuInner() {
  const { currentUser } = useAuth();
  const isPartOfSupplierOrg = currentUser?.data?.organization?.type === 'SUPPLIER';
  const isAdmin = currentUser?.data?.role === 'ROLE_ADMIN';
  const isSuperAdmin = currentUser?.data?.role === 'ROLE_SUPER_ADMIN';
  const isOrgAdmin = currentUser?.data?.role === 'ROLE_ORGANIZATION_ADMIN';
  const isGuest = currentUser?.data?.role === 'GUEST_USER';
  const claimUser = currentUser?.data?.role === 'ROLE_INSURANCE_CLAIM_USER';

  const ancillaryType = currentUser?.data?.organization?.ancillaryType;

  return (
    <React.Fragment>
      {!isGuest && <MenuItem title='Dashboard' to='/dashboard' />}
      {PICKYOURTRAILORGID?.includes(currentUser?.data?.organizationId) && (
        <MenuItem title='API' to='/api' />
      )}
      {!isGuest && (
        <MenuInnerWithSub
          title='Orders'
          to='/apps/orders'
          menuPlacement='bottom-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
          hasArrow>
          {ancillaryType?.includes('EVISA') && (
            <MenuItem
              title='Visa'
              to='/apps/orders/visa'
              icon='/media/icons/duotune/general/gen005.svg'
            />
          )}
          {ancillaryType?.includes('INSURANCE') && (
            <>
              {!claimUser && (
                <MenuItem
                  title='Insurance'
                  to='/apps/orders/insurance'
                  icon='/media/icons/duotune/general/gen049.svg'
                />
              )}
              {(claimUser || isSuperAdmin) && (
                <MenuItem
                  title='Claims'
                  to='/apps/claims'
                  icon='/media/icons/duotune/general/gen005.svg'
                />
              )}
            </>
          )}
          {ancillaryType?.includes('FLIGHT') && (
            <MenuItem
              title='Flights'
              to='/apps/flights'
              icon='/media/icons/duotune/general/gen005.svg'
            />
          )}
        </MenuInnerWithSub>
      )}
      {ancillaryType?.includes('EVISA') && (
        <MenuItem title='Visa Info' to='/apps/visa-information' />
      )}

      {(isAdmin || isSuperAdmin || isOrgAdmin) && <MenuItem title='Ledger' to='/ledger' />}
      {isAdmin || isSuperAdmin || isOrgAdmin ? (
        <MenuInnerWithSub
          title='Settings'
          to='/apps/settings'
          menuPlacement='bottom-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
          hasArrow>
          <MenuItem
            icon='/media/icons/duotune/communication/com014.svg'
            to='apps/settings/users'
            title='Users'
          />
          <MenuItem
            icon='/media/icons/duotune/general/gen051.svg'
            to='apps/settings/apiKey'
            title='API keys'
          />
          {!isPartOfSupplierOrg && (isSuperAdmin || isOrgAdmin) ? (
            <MenuItem
              icon='/media/icons/duotune/finance/fin001.svg'
              to='apps/settings/organizations'
              title='Organizations'
            />
          ) : null}
        </MenuInnerWithSub>
      ) : null}
    </React.Fragment>
  );
}
