import React, { Dispatch } from 'react';
import { ILedgerQueryParams } from '../type';
import { IPageInfo } from '../../../components/OATableComponent';

export interface ILedgerContext {
  ledgerList: any[];
  pageInfo: IPageInfo;
  getLedgerInfo: (cb?: ((response?: any) => void) | undefined) => Promise<void>;
  getLedgerList: (cb?: ((response?: any) => void) | undefined) => Promise<void>;
  ledgerBalance: any;
  getCurrentBalance: (cb?: ((response?: any) => void) | undefined) => Promise<void>;
  downloadReport: (cb?: ((response?: any) => void) | undefined) => Promise<void>;
  addBalance: (amount: number, cb?: ((response?: any) => void) | undefined) => Promise<void>;
  addTransaction: (values: any, cb?: ((response?: any) => void) | undefined) => Promise<void>;
  editTransaction: (values: any, cb?: ((response?: any) => void) | undefined) => Promise<void>;
}

const LedgerContext = React.createContext<ILedgerContext>({} as ILedgerContext);

export default LedgerContext;
