import { Col, Row } from 'react-bootstrap';
import { parseISO, format } from 'date-fns';
import Skeleton from 'react-loading-skeleton';

import { toAbsoluteUrl } from '../../../../helper';
import { useOrderContext } from '../../../order-management/context/OrderContext';

const iso3311a2 = require('iso-3166-1-alpha-2');

interface IInfoCardProps {
  previewType: 'summary' | 'elaboration';
}

const InfoCard = (props: IInfoCardProps) => {
  const { previewType } = props;

  const {
    orderData: { data, isLoading }
  } = useOrderContext();

  const trip: any = data?.trip;
  const product: any = data?.product;
  const productInfo: any = product?.productInformation;
  const formattedTripStart =
    trip?.tripStart?.datetime && format(parseISO(trip?.tripStart?.datetime), 'dd LLL, yyyy');
  const formattedTripEnd =
    trip?.tripEnd?.datetime && format(parseISO(trip?.tripEnd?.datetime), 'dd LLL, yyyy');

  return isLoading ? (
    <Skeleton height={145} className='mb-8 card-rounded' />
  ) : (
    <Row className='dev--quotation__info-card mb-8'>
      <Col>
        <div className={`quotation-info-card ${previewType === 'elaboration' ? 'p-8' : 'p-4'}`}>
          <Row className={previewType === 'elaboration' ? 'mb-10' : 'mb-4'}>
            <Col className='d-flex justify-content-between align-items-center'>
              <div className='w-150px'>
                <h2 className='text-center text-white fw-bolder fs-2 mb-0'>
                  {trip?.origin?.countryCode}
                </h2>
                <p className='text-center mb-0 text-muted fs-7 lh-sm'>
                  ({iso3311a2.getCountry(trip?.origin?.countryCode)})
                </p>
              </div>
              <div className='text-center px-5'>
                {previewType === 'elaboration' && (
                  <p className='mb-0 text-muted fs-7 lh-sm'>
                    {product?.name} {productInfo && `(${productInfo?.processingType})`}
                  </p>
                )}
                <img
                  src={toAbsoluteUrl('/media/customicon/divider.svg')}
                  alt='metronic'
                  className='quotation-info-card-img'
                />
                {previewType === 'elaboration' && (
                  <p className='mb-0 text-muted fs-7 lh-sm'>{`${formattedTripStart} - ${formattedTripEnd}`}</p>
                )}
              </div>
              <div className='w-150px'>
                <h2 className='text-center text-white fw-bolder fs-2 mb-0'>
                  {trip?.destination[0]?.countryCode}
                </h2>
                <p className='text-center mb-0 text-muted fs-7 lh-sm'>
                  ({iso3311a2.getCountry(trip?.destination[0]?.countryCode)})
                </p>
              </div>
            </Col>
          </Row>
          {previewType === 'summary' && (
            <Row>
              <Col>
                <div className='d-flex align-items-center mb-1'>
                  <span className='text-muted fs-8 flex-grow-1 lh-sm'>DURATION</span>
                  <span className='text-muted fs-8 lh-sm'>{`${formattedTripStart} - ${formattedTripEnd}`}</span>
                </div>
                <div className='separator separator-dashed my-3'></div>
                <div className='d-flex align-items-center mb-1'>
                  <span className='text-muted fs-8 flex-grow-1 lh-sm min-w-80px'>
                    {data?.ancillaryType === 'EVISA' ? 'VISA NAME' : 'PRODUCT NAME'}
                  </span>
                  <span className='text-muted fs-8 lh-sm'>
                    {product?.name} {productInfo && `(${productInfo?.processingType})`}
                  </span>
                </div>
              </Col>
            </Row>
          )}
          {previewType === 'elaboration' && (
            <Row>
              <Col>
                <div className='d-flex flex-stack flex-wrap flex-grow-1'>
                  {trip?.visaType ? (
                    <div>
                      <div className='text-muted fw-semibold text-white fs-8'>Visa Type</div>
                      <div className='text-white fs-7'>{trip?.visaType}</div>
                    </div>
                  ) : null}
                  {productInfo?.tripType ? (
                    <div>
                      <div className='text-muted fw-semibold text-white fs-8'>Trip type</div>
                      <div className='text-white fs-7'>{productInfo?.tripType}</div>
                    </div>
                  ) : null}
                  {productInfo?.validity ? (
                    <div>
                      <div className='text-muted fw-semibold text-white fs-8'>Visa Validity</div>
                      <div className='fw-bold text-white fs-7'>{productInfo?.validity}</div>
                    </div>
                  ) : null}
                  {productInfo?.processingTime ? (
                    <div>
                      <div className='text-muted fw-semibold text-white fs-8'>Processing Time</div>
                      <div className='fw-bold text-white fs-7'>{productInfo?.processingTime}</div>
                    </div>
                  ) : null}
                  {productInfo?.stayPeriod ? (
                    <div>
                      <div className='text-muted fw-semibold text-white fs-8'>Visa Stay</div>
                      <div className='fw-bold text-white fs-7'>{productInfo?.stayPeriod}</div>
                    </div>
                  ) : null}
                </div>
              </Col>
            </Row>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default InfoCard;
