import { FC, useState } from 'react';

import { KTSVG } from '../../../_metronic/helpers';

const OrgSearch: FC<any> = ({ onClickSearchHandler }) => {
  const [search, setSearch] = useState<any>('');

  const onClickClearHandler = () => {
    onClickSearchHandler('');
    setSearch('');
  };

  const onKeyDownSearchHandler = (e: any) => {
    if (e.key === 'Enter') onClickSearchHandler(search);
  };

  return (
    <div className='card-header card-header-stretch border-bottom border-gray-200 ps-0'>
      <div className='card-title position-relative'>
        <KTSVG
          className='position-absolute ms-4 svg-icon-1'
          path='/media/icons/duotune/general/gen021.svg'
        />
        <input
          type='text'
          onChange={e => setSearch(e?.target?.value)}
          onKeyDown={onKeyDownSearchHandler}
          className='form-control form-control-solid w-250px ps-15'
          placeholder='Search Organization'
          value={search}
        />
        {search?.length > 0 && (
          <span onClick={onClickClearHandler}>
            <KTSVG
              className='position-absolute order-search-clear svg-icon-2'
              path='/media/icons/duotune/abstract/abs012.svg'
            />
          </span>
        )}
      </div>
    </div>
  );
};

export default OrgSearch;
