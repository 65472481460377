import { ChangeEvent, useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { IOrderContext, useOrderContext } from '../../context/OrderContext';

const inputClasses = 'form-control form-control-sm form-control-solid';

const labelClassNames = 'form-label required';

interface ICfarDetails {
  errors: any;
  handleErrors?: (name: string, err: string | null) => void;
}

const CfarDetails = (props: ICfarDetails) => {
  const { formState, onAddTripBookingInformation }: IOrderContext = useOrderContext();
  const [inputValue, setInputValue] = useState<string>('');

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // Validate input to allow only integer numbers
    const sanitizedValue = value.replace(/\D/g, '');
    setInputValue(sanitizedValue);
  };

  const onBlurInputHandler = (event: any) => {
    const { name, value } = event.target;
    const bookingInformation = formState?.tripDetails?.trip?.bookingInformation;
    const newBookingInformation = {
      [name]: value,
      bookingAmountCurrency: 'INR'
    };

    onAddTripBookingInformation({
      bookingInformation:
        bookingInformation?.length > 0
          ? [{ ...bookingInformation[0], ...newBookingInformation }]
          : [newBookingInformation]
    });
    if (value && value !== '') props?.handleErrors?.(name, null);
  };

  return (
    <Card className='mb-6'>
      <Card.Header className='px-6'>
        <Card.Title className='fw-bolder text-dark fs-6 m-0'>CFAR details</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md='4'>
            <label className={labelClassNames}>Hotel booking reference ID</label>
            <input
              name='bookingReferenceId'
              type='text'
              placeholder='Type here'
              className={inputClasses}
              onBlur={onBlurInputHandler}
            />
            {props?.errors['bookingReferenceId'] && (
              <div className='text-danger fw-bold fs-9'>{props?.errors['bookingReferenceId']}</div>
            )}
          </Col>
          <Col md='4'>
            <label className={labelClassNames}>Amount per night(INR)</label>
            <input
              name='bookingAmountPerNight'
              type='text'
              placeholder='Type here'
              className={inputClasses}
              onBlur={onBlurInputHandler}
              value={inputValue}
              onChange={handleInputChange}
            />
            {props?.errors['bookingAmountPerNight'] && (
              <div className='text-danger fw-bold fs-9'>
                {props?.errors['bookingAmountPerNight']}
              </div>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CfarDetails;
