import { Box, Typography } from '@mui/material';
import { OATypography } from '../../../../components/widgets';
import React, { useState } from 'react';
import OAChip from '../../../../components/Base/OAChip';
import { ReactComponent as SourceDestIcon } from '../../../../assets/icons/source-DestArrow.svg';
import { ReactComponent as CopyIcon } from '../../../../assets/icons/copyIcon.svg';
import { ReactComponent as DottedLine } from '../../../../assets/icons/horizontal-dotted.svg';
import { ReactComponent as TimeIcon } from '../../../../assets/icons/time.svg';
import { ReactComponent as DotSeparatorIcon } from '../../../../assets/icons/dot_separator.svg';
import { ReactComponent as CopiedTag } from '../../../../assets/icons/Copied.svg';
import { theme } from '../../../../config/theme';
import { getFormattedDate } from '../../../../helper';
import { Data, Onward, OnwardDeparture } from './FlightInterface';

interface TripDetailProps {
  onward: Onward;
  onwardDeparture: OnwardDeparture;
  checkIfSameDay: (departure: string, arrival: string) => string;
  data: Data;
}

export const TripDetail: React.FC<TripDetailProps> = ({
  onward,
  onwardDeparture,
  checkIfSameDay,
  data
}) => {
  const [copy, setCopy] = useState<boolean>(false);
  const copyToClipboard = (Pnr: string) => {
    navigator.clipboard.writeText(Pnr);
    setCopy(true);

    setTimeout(() => {
      setCopy(false);
    }, 2000);
  };

  //We are using primary member PNR Details to show at order level
  let primaryTraveller = data?.travellers?.find(
    (obj: any) => obj?.userId === data?.contactDetails?.userId
  );
  const pnr =
    onwardDeparture?.bookingInfos?.find((obj: any) => obj?.travellerId === primaryTraveller?.id)
      ?.pnrNumber ?? onward?.pnrNumber;

  const seatsInfo = [];
  let mealCount: any = {};
  let extraBaggageInfo: any = {};
  for (let t = 0; t < data?.travellers?.length; t++) {
    let seat = onwardDeparture?.bookingInfos?.[t]?.selectedAncillaries?.find(
      (obj: any) => obj?.type === 'SEAT'
    );
    seat && seatsInfo.push(seat?.value);
    let meal = onwardDeparture?.bookingInfos?.[t]?.selectedAncillaries?.find(
      (obj: any) => obj?.type === 'MEAL'
    );
    if (meal && meal != undefined) {
      if (mealCount && mealCount[meal?.info ?? meal?.value]) {
        mealCount[meal?.info ?? meal?.value]++;
      } else {
        mealCount[meal?.info ?? meal?.value] = 1;
      }
    }

    let baggage = onwardDeparture?.bookingInfos?.[t]?.selectedAncillaries?.find(
      (obj: any) => obj?.type === 'BAGGAGE'
    );
    if (baggage && baggage != undefined) {
      if (extraBaggageInfo && extraBaggageInfo[baggage?.value]) {
        extraBaggageInfo[baggage?.value]++;
      } else {
        extraBaggageInfo[baggage?.value] = 1;
      }
    }
  }
  const seatSummary = seatsInfo.map(seat => `${seat}`).join(', ');
  const mealSummary = Object.entries(mealCount)
    .map(([meal, count]) => `${count} x ${meal}`)
    .join(', ');
  const baggageSummary = Object.entries(extraBaggageInfo)
    .map(([bag, count]) => `${count} x ${bag}`)
    .join(', ');

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' marginTop='24px' marginLeft='16px'>
        <Box>
          <Box display='flex' alignItems='center' gap='8px'>
            <img
              width={24}
              height={24}
              src={`https://oa-flight-assets.s3.ap-south-1.amazonaws.com/airline-logos/${onwardDeparture?.airline?.code}.svg`}
              alt='Indigo'
              loading='lazy'
              style={{ borderRadius: '50%' }}
            />
            <OATypography variant='h2'>{onwardDeparture?.departure?.airport?.city}</OATypography>
            <SourceDestIcon />
            <OATypography variant='h2'>{onwardDeparture?.arrival?.airport?.city}</OATypography>
            <OATypography variant='body2' sx={{ color: theme.palette.grey[400] }}>
              {getFormattedDate(onwardDeparture?.departure?.date, 'ddd, DD MMM, YYYY')}
            </OATypography>
          </Box>
          <Box display='flex' alignItems='center' marginLeft='28px'>
            <Box marginTop='32px'>
              <DottedLine />
            </Box>
            <Box marginLeft='20px'>
              <Box display='flex' gap='8px' marginTop='24px'>
                <OATypography variant='h4'>{onwardDeparture?.departure?.time}</OATypography>
                <OATypography variant='h4' sx={{ fontWeight: 400 }}>
                  {onwardDeparture?.departure?.airport?.iata}
                </OATypography>
                <OATypography variant='body2' sx={{ color: theme.palette.grey[400] }}>
                  {`${onwardDeparture?.departure?.airport?.name} ${
                    onwardDeparture?.departure?.terminal
                      ? 'Terminal' + onwardDeparture?.departure?.terminal ?? ''
                      : ''
                  }`}
                </OATypography>
              </Box>
              <Box marginTop='25px' display='flex' alignItems='center' gap='8px'>
                <img
                  width={16}
                  height={16}
                  src={`https://oa-flight-assets.s3.ap-south-1.amazonaws.com/airline-logos/${onwardDeparture?.airline?.code}.svg`}
                  alt='Indigo'
                  loading='lazy'
                  style={{ borderRadius: '50%' }}
                />
                <OATypography
                  variant='body2'
                  sx={{ fontSize: '12px', lineHeight: '18px', color: theme.palette.grey[400] }}>
                  {`${onwardDeparture?.airline?.code}-${onwardDeparture?.airline?.flightNumber}`}
                </OATypography>
                <DotSeparatorIcon />
                <TimeIcon style={{ height: '12px', width: '12px' }} />
                <OATypography
                  variant='body2'
                  sx={{ fontSize: '12px', lineHeight: '18px', color: theme.palette.grey[400] }}>
                  {`Duration: ${onwardDeparture?.flightDuration?.display}`}
                </OATypography>
              </Box>
              <Box display='flex' gap='8px' marginTop='22px'>
                <OATypography variant='h4'>{onwardDeparture?.arrival?.time}</OATypography>
                <OATypography variant='h4' sx={{ fontWeight: 400 }}>
                  {onwardDeparture?.arrival?.airport?.iata}
                </OATypography>
                <OATypography variant='body2' sx={{ color: theme.palette.grey[400] }}>
                  {`${onwardDeparture?.arrival?.airport?.name} ${
                    onwardDeparture?.arrival?.terminal
                      ? 'Terminal' + onwardDeparture?.arrival?.terminal
                      : ''
                  }`}
                </OATypography>
              </Box>
            </Box>
          </Box>
        </Box>
        {pnr && (
          <Box marginRight='16px' display='flex' flexDirection='column' alignItems='center'>
            <OATypography
              variant='h4'
              sx={{ lineHeight: '16px', fontSize: '12px', color: theme.palette.grey[400] }}>
              PNR Detail
            </OATypography>
            <Box display='flex' alignItems='center' position='relative'>
              <OATypography variant='h4' sx={{ marginRight: '4px' }}>
                {pnr}
              </OATypography>
              <Box sx={{ display: 'flex', cursor: 'pointer' }}>
                <CopyIcon onClick={() => copyToClipboard(pnr)} />
              </Box>
              {copy && (
                <CopiedTag
                  style={{
                    position: 'absolute',
                    left: '100%',
                    zIndex: 1,
                    padding: '2px'
                  }}
                />
              )}
            </Box>
            <OAChip
              label={checkIfSameDay(
                `${onwardDeparture?.departure?.date} ${onwardDeparture?.departure?.time}`,
                `${onwardDeparture?.arrival?.date} ${onwardDeparture?.arrival?.time}`
              )}
              color='warning'
              sx={{ mt: '8px', fontSize: '12px', fontWeight: 500, p: '4px 0' }}
            />
          </Box>
        )}
      </Box>

      <Box marginTop='16px' display='flex' alignItems='center' marginLeft='56px'>
        {onwardDeparture?.baggageInfo?.baggages?.[0]?.cabin && (
          <OAChip
            label={`Cabin: ${onwardDeparture?.baggageInfo?.baggages?.[0]?.cabin} / person`}
            color='secondary'
            sx={{ fontSize: '12px', fontWeight: 500, p: '4px 0' }}
          />
        )}
        {onwardDeparture?.baggageInfo?.baggages?.[0]?.checkIn && (
          <OAChip
            label={`Check-in: ${onwardDeparture?.baggageInfo?.baggages?.[0]?.checkIn} / person`}
            color='secondary'
            sx={{
              marginLeft: '8px',
              fontSize: '12px',
              fontWeight: 500,
              p: '4px 0'
            }}
          />
        )}
        <OAChip
          label={onward?.minirule?.refundable ? 'Refundable' : 'Non-refundable'}
          color='secondary'
          sx={{
            marginLeft: '8px',
            fontSize: '12px',
            fontWeight: 500,
            p: '4px 0'
          }}
        />
      </Box>
      <Box marginLeft='48px'>
        {seatSummary && (
          <OAChip
            label={`Seats: ${seatSummary}`}
            color='secondary'
            sx={{
              marginTop: '16px',
              marginLeft: '8px',
              fontSize: '12px',
              fontWeight: 500,
              p: '4px 0'
            }}
          />
        )}
        {baggageSummary && (
          <OAChip
            label={`Extra: ${baggageSummary} baggage`}
            color='secondary'
            sx={{
              marginTop: '16px',
              marginLeft: '8px',
              fontSize: '12px',
              fontWeight: 500,
              p: '4px 0'
            }}
          />
        )}
        {mealSummary && (
          <OAChip
            label={`Meals: ${mealSummary}`}
            color='secondary'
            sx={{
              marginTop: '16px',
              marginLeft: '8px',
              fontSize: '12px',
              fontWeight: 500,
              p: '4px 0'
            }}
          />
        )}
      </Box>
    </Box>
  );
};
