/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FormikHelpers } from 'formik';
import classNames from 'classnames';
import moment from 'moment';

import { ORDER_CREATE_ACTIONS, ORDER_TYPE } from '../../../constants';
import { useOrderContext } from '../../../context/OrderContext';
import AddTravellerFormModal from './AddTravellerFormModal';
import { dateDiff } from '../../../../../helper/dateFormat';
import TravellersList from './TravellersList';
import add_traveller_svg from '../../../../../assets/images/add_traveller.svg';
import { getFormattedDate, showToast } from '../../../../../helper';
import OAButton from '../../../../../components/widgets/OAButton';
import { useAppContext } from '../../../../../context/AppContext';
import { Paper } from '@mui/material';
import { ITravellerFormValues } from '../../../types';

interface IAddTravellers {
  onSubmit: (
    values: any,
    type: string,
    actions?: FormikHelpers<any>,
    cb?: ((response?: any) => void) | undefined
  ) => void;
  maxProcessingTime: number;
  isEdit?: boolean;
  className?: string;
  handleErrors?: (name: string, err: string | null) => void;
  disableAddButton?: boolean;
  maxProcessingTimeMatrix?: string;
}

function AddTravellers(props: IAddTravellers) {
  const { disableAddButton = false } = props;
  const params = useParams<any>();

  const { formState, onSelectEditTraveller, draftOrder } = useOrderContext();

  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (draftOrder?.members && formState?.travellerDetails.length !== draftOrder?.members.length) {
      formState.travellerDetails = draftOrder?.members; // set the formState traveller same as draft member
    }
  }, [params]);

  useEffect(() => {
    if (formState?.travellerDetails?.length) {
      const draftActive = !formState?.travellerDetails?.find(
        (traveller: ITravellerFormValues) => traveller?.primary === true
      );
      setIsDisabled(draftActive);
    }
  }, [props?.isEdit, formState]);

  const toggleModal = (open?: boolean) => {
    let startDate: any = getFormattedDate(formState?.tripDuration?.from, 'YYYY-MM-DD');
    let endDate: any = getFormattedDate(formState?.tripDuration?.to, 'YYYY-MM-DD');
    if (params?.orderType === ORDER_TYPE.VISA) {
      const from = formState?.tripDuration?.from;
      const to = formState?.tripDuration?.to;
      const maxUnit = props?.maxProcessingTimeMatrix
        ? (props?.maxProcessingTimeMatrix.toLowerCase() as moment.unitOfTime.DurationConstructor)
        : 'days';
      const maxProcessingDate = moment().add(props?.maxProcessingTime, maxUnit);
      const durationFrom = moment(from).startOf('day');
      const isdisabled = maxProcessingDate.isAfter(durationFrom);

      if (isdisabled) {
        setIsOpen(false);
        if (formState?.quotationId) {
          props?.handleErrors?.(
            'tripDuration',
            'Start Date should be higher than min processing Date'
          );
        } else {
          showToast('Start Date should be higher than min processing Date', 'error');
        }
      } else if (endDate < startDate) {
        setIsOpen(false);
        props?.handleErrors?.(
          'tripDuration',
          'End Date should be higher than or equal to start Date'
        );
      } else if (!formState?.consulate) {
        setIsOpen(false);
        props?.handleErrors?.('consulate', 'Consulate is a required field');
      } else {
        setIsOpen(true);
      }
    } else {
      setIsOpen(true);
    }
  };

  function handleSubmit() {
    if (params?.orderType === ORDER_TYPE.VISA) {
      props?.handleErrors?.('tripDuration', null);
      props?.handleErrors?.('maxProcessingTime', null);
      props?.handleErrors?.('wrongDates', null);
      let durationFrom = dateDiff(new Date(), formState?.tripDuration?.from);
      let durationTo = dateDiff(new Date(), formState?.tripDuration?.to);
      if (!formState?.tripDuration?.from && !formState?.tripDuration?.to) {
        props?.handleErrors?.('tripDuration', 'Travel Duration is a required field');
      } else if (durationTo < durationFrom) {
        props?.handleErrors?.('wrongDates', 'End Date must be greater than Start date');
      } else {
        setIsLoading(true);
        props?.onSubmit(null, ORDER_CREATE_ACTIONS.REVIEW_ORDER, undefined, _res => {
          setIsLoading(false);
        });
      }
    } else {
      const bookingInformation = formState?.tripDetails?.trip?.bookingInformation?.[0];
      if (formState?.category === 'CFAR_FOR_HOTEL' && !bookingInformation?.bookingReferenceId) {
        props?.handleErrors?.('bookingReferenceId', 'Hotel booking ID required field');
      } else if (
        formState?.category === 'CFAR_FOR_HOTEL' &&
        !bookingInformation?.bookingAmountPerNight
      ) {
        props?.handleErrors?.('bookingAmountPerNight', 'Amount per night required field');
      } else {
        setIsLoading(true);
        props?.onSubmit(null, ORDER_CREATE_ACTIONS.REVIEW_ORDER, undefined, _res => {
          setIsLoading(false);
        });
      }
    }
  }

  return (
    <Paper
      variant='outlined'
      className={classNames('add-travellers w-100', props?.className ?? '')}>
      {draftOrder?.members?.length ?? formState?.travellerDetails?.length ? (
        <WithDataJSX
          toggleModal={toggleModal}
          handleSubmit={handleSubmit}
          isDisabled={isDisabled}
          isLoading={isLoading}
          disableAddButton={disableAddButton}
        />
      ) : (
        <NoDataJSX toggleModal={toggleModal} disableAddButton={disableAddButton} />
      )}
      <AddTravellerFormModal
        isOpen={isOpen}
        onHide={() => {
          setIsOpen(false);
          onSelectEditTraveller(null);
        }}
      />
    </Paper>
  );
}

export default AddTravellers;

// Helper Components ...
const NoDataJSX = (props: { toggleModal: (val?: boolean) => void; disableAddButton: boolean }) => {
  return (
    <React.Fragment>
      <div className='d-flex justify-content-between align-items-center mb-6'>
        <h3 className='fw-semibold text-dark mb-0'>Travellers Details</h3>
        <OAButton
          size='sm'
          label='Add traveller'
          onClick={() => props?.toggleModal()}
          disabled={props?.disableAddButton}
        />
      </div>
      <div className='d-flex flex-column flex-column-fluid text-center'>
        <img src={add_traveller_svg} alt='' className='m-auto' style={{ maxHeight: '250px' }} />
        <div className='fs-3 fw-bolder text-dark'>No travellers have been added yet</div>
        <p className='text-gray-400 fw-bold fs-5 mt-1 mb-7'>
          Add all the relevant details needed for your travel booking
        </p>
      </div>
    </React.Fragment>
  );
};

const WithDataJSX = (props: {
  toggleModal: (val?: boolean) => void;
  handleSubmit: () => void;
  isDisabled: boolean;
  isLoading: boolean;
  disableAddButton: boolean;
}) => {
  const { formState, onSelectEditTraveller, draftOrder } = useOrderContext();

  const traveller_count_text = `${
    formState?.travellerDetails?.length <= 0
      ? draftOrder?.members?.length
      : formState?.travellerDetails?.length
  } ${
    (formState?.travellerDetails?.length <= 0
      ? draftOrder?.members?.length
      : formState?.travellerDetails?.length) > 1
      ? 'travellers'
      : 'traveller'
  } added`;

  const onClickAdd = () => {
    if (
      formState?.travellerDetails?.length >= 9 &&
      formState?.tripDetails?.supplierOrganization?.organizationCode === 'SUP005'
    ) {
      showToast('You can process only 9 travellers in an order', 'error');
    } else {
      props?.toggleModal();
      onSelectEditTraveller(null);
    }
  };

  return (
    <React.Fragment>
      <div className='d-flex justify-content-between mb-6'>
        <div>
          <h3 className='fw-semibold text-dark mb-0'>Travellers Details</h3>
          <p className='text-gray-400 fw-bold fs-6 mb-0'>{traveller_count_text}</p>
        </div>
        {!(formState?.category === 'BUS_INSURANCE' || formState?.category === 'CAB_INSURANCE') ? (
          <div>
            <Button
              size='sm'
              className='m-auto'
              variant='primary'
              onClick={onClickAdd}
              disabled={props?.disableAddButton}>
              Add traveller
            </Button>
          </div>
        ) : null}
      </div>
      <TravellersList
        travellers={
          formState?.travellerDetails?.length <= 0
            ? draftOrder?.members
            : formState?.travellerDetails
        }
        trip={formState?.tripDuration}
        toggleModal={props?.toggleModal}
      />
      <div className='d-flex flex-stack pt-10'>
        <OAButton
          label='Continue'
          onClick={props?.handleSubmit}
          disabled={props?.isDisabled || props?.isLoading}
          className='ms-auto'
        />
      </div>
    </React.Fragment>
  );
};
