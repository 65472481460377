import { FC } from 'react';
import { Box, Typography, Radio, FormControlLabel, Chip } from '@mui/material';
import { OATypography } from '../../../components/widgets';

interface IPaymentPlanCard {
  plan: 'monthly' | 'yearly';
  selected: boolean;
  price: string;
  saving?: string | undefined;
  onClick: (plan: 'monthly' | 'yearly') => void;
  actualPrice?: string;
}

const boxStyle = {
  border: '1px solid',
  borderRadius: '4px',
  p: '0 12px 12px 12px',
  cursor: 'pointer'
};
const PaymentPlanCard: FC<IPaymentPlanCard> = ({
  plan,
  selected,
  price,
  saving,
  onClick,
  actualPrice
}) => {
  return (
    <Box
      onClick={() => onClick(plan)}
      sx={{
        ...boxStyle,
        bgcolor: theme => (selected ? theme?.palette?.primary?.light : '#FFFFFF'),
        borderColor: selected ? '#3A86FF' : '#EFF2F5',
        minHeight: '130px'
      }}>
      <FormControlLabel
        control={<Radio checked={selected} color='primary' />}
        label={
          <Typography variant='subtitle1' sx={{ textTransform: 'capitalize', fontWeight: 500 }}>
            {plan === 'monthly' ? 'Quarterly' : plan}
          </Typography>
        }
      />
      <Box display='flex' alignItems='center'>
        <OATypography variant='h3' noWrap>
          {price}
        </OATypography>
        <OATypography variant='body2' component='span'>
          {plan === 'monthly' ? '/quarter' : '/year'}
        </OATypography>
      </Box>
      <OATypography
        variant='body2'
        sx={{
          textDecoration: 'line-through',
          color: theme => theme?.palette?.grey[400],
          visibility: actualPrice ? 'visible' : 'hidden'
        }}>
        {actualPrice}
      </OATypography>
      <Chip
        label={saving}
        variant='filled'
        color='error'
        sx={{ p: '4px 0', height: '20px', mt: '8px', visibility: saving ? 'visible' : 'hidden' }}
      />
    </Box>
  );
};

export default PaymentPlanCard;
