import React, { useState, useEffect, ReactNode } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import Skeleton from 'react-loading-skeleton';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export interface ComponentItem {
  id: string;
  name: ReactNode;
  height: number;
}

interface Props {
  onLayoutChange: (layout: any, layouts: any) => void;
  listOfComponents: any;
  className?: string;
  rowHeight?: number;
  cols?: {
    lg: number;
    md: number;
    sm: number;
    xs: number;
    xxs: number;
  };
  initialLayout?: any;
  onDragStart?: () => void;
  onDragStop?: () => void;
}

const DraggableLayout: React.FC<Props> = props => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState<any>('lg');
  const [compactType, setCompactType] = useState<any>('vertical');
  const [mounted, setMounted] = useState<boolean>(false);
  const savedLayoutsFromStorage = JSON.parse(localStorage.getItem('savedLayouts') || 'null');

  useEffect(() => {
    // Check if there's a saved layout in localStorage when the component mounts
    const savedLayouts = localStorage.getItem('savedLayouts');
    if (savedLayouts) {
      setLayouts(JSON.parse(savedLayouts));
    }
  }, []);

  const initialLayouts = {
    lg: generateLayout(),
    md: generateLayout(),
    sm: generateLayout(),
    xs: generateLayout(),
    xxs: generateLayout()
  };
  const [layouts, setLayouts] = useState(savedLayoutsFromStorage || initialLayouts);

  DraggableLayout.defaultProps = {
    className: 'layout',
    // rowHeight: 30,
    onLayoutChange: () => {},
    cols: { lg: 3, md: 3, sm: 3, xs: 4, xxs: 2 },
    initialLayout: generateLayout()
  };

  function generateLayout() {
    return props?.listOfComponents.map((componentObj: any, i: any) => {
      return {
        x: i % 3, // Ensure x is between 0 and 2 for a 3-column grid
        y: Math.floor(i / 3), // Adjust vertical stacking for 3 columns
        w: 1, // Set width to 1 column
        h: componentObj?.height, // Set height to span 5 rows (you can adjust this based on your content's height)
        i: componentObj?.id,
        static: false
      };
    });
  }

  useEffect(() => {
    setMounted(true);
  }, []);

  const generateDOM = () => {
    return props?.listOfComponents.map((componentObj: any) => (
      <div key={componentObj.id}>{componentObj.name}</div>
    ));
  };

  const onBreakpointChange = (breakpoint: string) => {
    setCurrentBreakpoint(breakpoint);
  };

  const onLayoutChange = (layout: any, layouts: any) => {
    localStorage.setItem('savedLayouts', JSON.stringify(layouts));
    setLayouts(layouts); // Update the state
    props.onLayoutChange(layout, layouts);
  };

  return (
    <div>
      {mounted ? (
        <ResponsiveReactGridLayout
          {...props}
          layouts={layouts}
          autoSize={true}
          onBreakpointChange={onBreakpointChange}
          onLayoutChange={onLayoutChange}
          measureBeforeMount={false}
          useCSSTransforms={mounted}
          compactType={compactType}
          preventCollision={!compactType}
          onDragStart={props?.onDragStart}
          onDragStop={props?.onDragStop}
          margin={[20, 20]}
          className='cursor-pointer'
          containerPadding={[0, 0]}>
          {generateDOM()}
        </ResponsiveReactGridLayout>
      ) : (
        <Skeleton height={800} />
      )}
    </div>
  );
};

export default DraggableLayout;
