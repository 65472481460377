import classNames from 'classnames';

interface IDisplayHeaderMessage {
  isFetchingDataFromApi: boolean;
  imgUrl: any;
}

export default function DisplayHeaderMessage(props: IDisplayHeaderMessage) {
  const { isFetchingDataFromApi, imgUrl } = props;
  const headerClassNames = classNames(
    'notice d-flex justify-content-center rounded py-4 px-4 mb-8',
    {
      'bg-light-warning': !(imgUrl?.front && imgUrl?.back && !isFetchingDataFromApi),
      'bg-light-success': imgUrl?.front && imgUrl?.back && !isFetchingDataFromApi
    }
  );
  return (
    <div className={headerClassNames}>
      {isFetchingDataFromApi ? (
        <span className='indicator-progress fw-semibold' style={{ display: 'block' }}>
          Fetching details...
          <span className='spinner-border spinner-border-sm align-middle ms-2' />
        </span>
      ) : (
        <div className='fw-semibold'>
          {imgUrl?.front && imgUrl?.back
            ? 'Auto-filled successfully! Kindly verify before proceeding further.'
            : 'Upload passport images first to autofill the form'}
        </div>
      )}
    </div>
  );
}
