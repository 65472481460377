import { Route, Routes, Navigate } from 'react-router-dom';

import { MasterLayout } from '../_metronic/layout/MasterLayout';

import Dashboard from '../pages/Dashboard';
import OrderManagement from '../pages/order-management';
import UserManagement from '../pages/user-management';
import ApiKeyManagement from '../pages/api-key-management';
import PasswordManagement from '../pages/change-password';
import OrgManagement from '../pages/org-management/index';
import AppProvider from '../context/AppProvider';
import Ledger from '../pages/Ledger';
import { ClaimsManagement } from '../pages/claims-Management';
import { FlightsManagement } from '../pages/flights-management';
import VisaInformation from '../pages/VisaInformation';
import Api from '../pages/Api';
import { useState } from 'react';
import moment from 'moment';

const PrivateRoutes = () => {
  const [queryParams, setQueryParams] = useState({
    dateRange: [moment().subtract(1, 'months').toDate(), new Date()],
    search: '',
    createdBy: '',
    pageIndex: 1,
    size: 20,
    orderStatus: 'ORDER_DRAFT',
    pageCount: 0,
    sortByField: null,
    sortBy: null
  });

  return (
    <AppProvider>
      <Routes>
        <Route element={<MasterLayout />}>
          <Route path='auth/*' element={<Navigate to='/dashboard' />} />
          <Route path='apps/settings' element={<Navigate to='/apps/settings/users' />} />

          <Route
            path='dashboard'
            element={<Dashboard queryParams={queryParams} setQueryParams={setQueryParams} />}
          />
          <Route
            path='apps/claims'
            element={<ClaimsManagement queryParams={queryParams} setQueryParams={setQueryParams} />}
          />
          <Route
            path='apps/flights/*'
            element={
              <FlightsManagement queryParams={queryParams} setQueryParams={setQueryParams} />
            }
          />
          <Route
            path='apps/orders/*'
            element={<OrderManagement queryParams={queryParams} setQueryParams={setQueryParams} />}
          />
          <Route path='apps/settings/users' element={<UserManagement />} />
          <Route path='apps/settings/apiKey' element={<ApiKeyManagement />} />
          <Route path='apps/settings/organizations' element={<OrgManagement />} />
          <Route
            path='apps/visa-information/*'
            element={<VisaInformation queryParams={queryParams} setQueryParams={setQueryParams} />}
          />
          <Route path='profile/changePassword' element={<PasswordManagement />} />
          <Route
            path='ledger'
            element={<Ledger queryParams={queryParams} setQueryParams={setQueryParams} />}
          />
          <Route path='api' element={<Api />} />
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    </AppProvider>
  );
};

export { PrivateRoutes };
