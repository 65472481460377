import { DateRange } from '@mui/x-date-pickers-pro';
import { Moment } from 'moment';

// Product Supplier
export type TProductSupplier = {
  data: Array<any>;
  isLoading: boolean;
};
export const initProductSupplier: TProductSupplier = {
  data: [],
  isLoading: false
};

// Summary Orders
export type TSummaryOrders = {
  icon: string;
  count: number;
  text: string;
  color: string;
};
const initSummaryOrders: TSummaryOrders = {
  icon: '',
  count: 0,
  text: '',
  color: ''
};

// Summary
export type TSummary = {
  title: string;
  orders: TSummaryOrders;
  customer: TSummaryOrders;
};
const initSummary: TSummary = {
  title: '',
  orders: initSummaryOrders,
  customer: initSummaryOrders
};

export type TCountries = {
  data: Array<any>;
  isLoading: boolean;
};

export const initCountries: TCountries = {
  data: [],
  isLoading: false
};

// Order
export type TOrder = {
  summaryData: {
    order: TSummary;
    visa: TSummary;
    insurance: TSummary;
  };
  orderGraphData: any;
  revenueGraphData: any;
  isLoading: boolean;
};
export const initOrder: TOrder = {
  summaryData: {
    order: initSummary,
    visa: initSummary,
    insurance: initSummary
  },
  orderGraphData: {},
  revenueGraphData: {},
  isLoading: true
};

export interface IDashboardProvider {
  children: any;
  data: any;
}

// Filter
export interface IDashboardFilterQueryParams {
  dateRange: DateRange<Moment | Date>;
}
