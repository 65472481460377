import { useContext, useEffect, useRef, useState } from 'react';
import { FormikHelpers } from 'formik';
import isNull from 'lodash/isNull';

import OrderContext, { IOrderContext } from '../../../context/OrderContext';
import { ORDER_CREATE_ACTIONS } from '../../../constants';
import MasterProduct from '../../../../../components/ProductCard/MasterProduct';

export default function SelectInsuranceForm(props: {
  onSubmit: (values: any, type: string, actions?: FormikHelpers<any>) => void;
}) {
  const { products, formState }: IOrderContext = useContext(OrderContext);

  const [insuranceProducts, setInsuranceProducts] = useState<Array<any> | null>(null);
  /**
   * NOTE: 'isSelected' state handle when to scroll page and when not to ...
   */
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const masterProductsRef = useRef<HTMLDivElement>(null);
  /**
   * NOTE: check if products available or not, if yes then filter products based
   * on selected tripType filter and set filtered data on a local state(insuranceProducts).
   */
  useEffect(() => {
    setInsuranceProducts(products);
  }, [products]);

  useEffect(() => {
    if (masterProductsRef.current && !isSelected) {
      masterProductsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [insuranceProducts]);

  if (isNull(products)) {
    return null;
  }
  if (!products?.length) {
    return (
      <div className='mb-0 mt-6'>
        <h3 className='m-0 mb-5'>No Results Found</h3>
      </div>
    );
  }

  const handleSubmit = (values: any, cb?: () => void) => {
    setIsSelected(true);
    setTimeout(() => {
      props?.onSubmit(values, ORDER_CREATE_ACTIONS.ADD_TRIP_DETAILS);
      setIsSelected(false);
      cb?.();
    }, 2000);
  };

  return (
    <div className='select-insurance-form' ref={masterProductsRef}>
      <div className='mb-0 mt-6'>
        <h3 className='fw-bolder m-0 mb-5'>{`Results (${products?.length})`}</h3>
        <div className='master-products-wrapper'>
          {insuranceProducts?.map((insuranceProduct: any) => (
            <MasterProduct
              data={insuranceProduct}
              type='insurance'
              onSelectProduct={handleSubmit}
              selectedProductId={formState?.tripDetails?.id}
              disabled={isSelected}
              enableQuoteButton={formState?.category === 'STANDARD_TRAVEL_INSURANCE'}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
