import { useEffect, useState } from 'react';
import { useExpanded, useTable, useSortBy } from 'react-table';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import NoRecords from './NoRecords';
import LoadingState from './LoadingState';
import Skeleton from 'react-loading-skeleton';
import OAPagination from './OAPagination';
import { KTSVG } from '../../_metronic/helpers';

// TODO: attach search by, filters and pagination in future ...

export default function OATable(props) {
  const {
    name = '',
    data,
    columns,
    isLoading = false,
    isRowClickable = false,
    basePath = '',
    nonClickableColumns = [],
    autoResetExpanded = false,
    pagination,
    noHeader = false,
    onSortChange,
    enableSkeleton = false
  } = props;

  const navigate = useNavigate();
  const [sortingData, setSortingData] = useState({ sortByField: null, sortByDirection: null });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state: { sortBy }
  } = useTable(
    {
      columns,
      data,
      autoResetExpanded,
      manualSortBy: true,
      autoResetSortBy: false,
      defaultColumn: { disableSortBy: true } // Disables sorting for all columns by default
    },
    useSortBy,
    useExpanded
  );

  useEffect(() => {
    const { sortByField, sortByDirection } = sortingData;
    if (sortBy.length > 0) {
      const newSortField = sortBy[0].id;
      const newSortDirection = sortBy[0].desc ? 'DESC' : 'ASC';

      if (newSortField !== sortByField || newSortDirection !== sortByDirection) {
        setSortingData({ sortByField: newSortField, sortByDirection: newSortDirection });
        onSortChange(newSortField, newSortDirection);
      }
    } else if (sortByField && sortByDirection) {
      setSortingData({ sortByField: null, sortByDirection: null });
      onSortChange(null, null);
    }
  }, [sortBy]);

  const renderHeaderGroups = tableHeaderGroups => {
    return tableHeaderGroups?.map(headerGroup => (
      <tr
        {...headerGroup?.getHeaderGroupProps()}
        className='text-start text-muted fw-bolder fs-8 text-uppercase gs-0'>
        {headerGroup?.headers?.map((column, index) => (
          <th
            key={index}
            {...column.getHeaderProps(column.getSortByToggleProps())}
            className='pb-4 pt-6'>
            {column?.render('Header')}
            {/* Add sort direction indicator */}
            <span>
              {!column.disableSortBy && (
                <SvgIconSorting isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} />
              )}
            </span>
          </th>
        ))}
      </tr>
    ));
  };

  const renderWithDataJSX = tableRows => {
    const renderSubComponent = rowData => {
      const SubComponent = props?.expand?.Component;
      return (
        <tr className='data-table__row--sub-component'>
          <td colSpan={visibleColumns?.length}>
            <SubComponent row={rowData} {...props?.expand?.componentProps} />
          </td>
        </tr>
      );
    };

    return tableRows?.map((row, index) => {
      prepareRow(row);

      return (
        <>
          <tr
            key={index}
            {...row?.getRowProps()}
            className='data-table__row'
            onClick={() => {
              if (
                isRowClickable &&
                !nonClickableColumns.some(c => row.cells.some(cell => cell.column.id === c))
              ) {
                if (props.onRowClick) {
                  props.onRowClick(row.original);
                } else {
                  let id = row?.original?.id;
                  if (name === 'order') id = `${row?.original?.linkedOrder?.id}`;
                  const bp = basePath === '' ? '' : `${basePath}/`;
                  const path = `${bp}${id}`;
                  navigate(path);
                }
              }
            }}>
            {row?.cells?.map(cell => {
              let tdProps = {};
              /**
               * DEVNOTE: if 'nonClickableColumns' includes 'cell?.column?.id' then
               * don't attach onClick on that row props...
               */
              const shouldClickable =
                isRowClickable && !nonClickableColumns?.includes(cell?.column?.id);
              /**
               * DEVNOTE: if 'shouldClickable' is true then attach className and
               * onClick props to it...
               */
              if (shouldClickable && !props.onRowClick) {
                let id = row?.original?.id;
                if (name === 'order') id = `${row?.original?.linkedOrder?.id}`;
                const bp = basePath === '' ? '' : `${basePath}/`;
                const path = `${bp}${id}`;
                tdProps.className = 'cursor-pointer';
                tdProps.onClick = () => navigate(path);
              }
              return (
                <td {...cell?.getCellProps()} {...tdProps}>
                  {cell?.render('Cell')}
                </td>
              );
            })}
          </tr>
          {row?.isExpanded ? renderSubComponent(row) : null}
        </>
      );
    });
  };

  const tableClassNames = classNames(
    'table align-middle table-row-dashed fs-7 gy-4 dataTable no-footer text-nowrap mb-0',
    {
      'table-hover': isRowClickable && data?.length
    }
  );

  return (
    <>
      <div className='table-responsive'>
        <table {...getTableProps()} className={tableClassNames}>
          {noHeader ? null : <thead>{renderHeaderGroups(headerGroups)}</thead>}
          <tbody className='text-dark' {...getTableBodyProps()}>
            {isLoading ? (
              !enableSkeleton ? (
                <LoadingState columnLength={columns.length} />
              ) : (
                <SkeletonComponent colData={columns[2]?.Header === 'Expiry Date' ? true : false} />
              )
            ) : data?.length > 0 ? (
              renderWithDataJSX(rows)
            ) : (
              <NoRecords columnLength={columns.length} />
            )}
          </tbody>
        </table>
      </div>
      {data?.length > 0 && pagination && !isLoading && pagination?.totalPages > 0 && (
        <OAPagination {...pagination} />
      )}
    </>
  );
}

OATable.propTypes = {
  name: PropTypes.string,
  data: PropTypes.array.isRequired,
  columns: PropTypes.any.isRequired,
  expand: PropTypes.shape({
    isEnabled: PropTypes.bool.isRequired,
    Component: PropTypes.elementType.isRequired,
    componentProps: PropTypes.object
  }),
  isLoading: PropTypes.bool,
  isRowClickable: PropTypes.bool,
  basePath: PropTypes.string,
  nonClickableColumns: PropTypes.array,
  autoResetExpanded: PropTypes.bool,
  pagination: PropTypes.shape({
    totalPages: PropTypes.number.isRequired,
    pageIndex: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired
  }),
  onRowClick: PropTypes.func,
  noHeader: PropTypes.bool,
  onSortChange: PropTypes.func,
  sorting: PropTypes.shape({
    sortByField: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    sortBy: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])])
  }),
  enableSkeleton: PropTypes.bool
};

const SvgIconSorting = props => {
  // {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
  // isSorted, isSortedDesc
  if (props.isSorted) {
    if (props.isSortedDesc) {
      return <KTSVG path={'/media/icons/duotune/arrows/desc.svg'} className='svg-icon-2' />;
    } else {
      return <KTSVG path={'/media/icons/duotune/arrows/asc.svg'} className='svg-icon-2' />;
    }
  } else {
    return <KTSVG path={'/media/icons/duotune/arrows/ascdesc.svg'} className='svg-icon-3' />;
  }
};

const SkeletonComponent = props => {
  const skeletonItems = !props?.colData
    ? [...Array(5)].map((_, index) => (
        <div key={index} className='mb-8'>
          <Skeleton width={60} />
          <Skeleton />
        </div>
      ))
    : [...Array(5)].map((_, index) => (
        <div key={index} className='mb-8'>
          <Skeleton width={60} />
          <Skeleton width={500} />
        </div>
      ));

  return skeletonItems;
};
