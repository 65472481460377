import { formatLogDate, sortDates } from './helpers/dateFormatting';
import DateHeader from './DateHeader';
import ActivityLogItem from './ActivityLogItem';
interface IActivityContainer {
  data: any;
  dataExchange?: any;
}

const ActivityContainer: React.FC<IActivityContainer> = ({ data, dataExchange }) => {
  const groupedLogs = data?.reduce((acc: any, item: any) => {
    const date = formatLogDate(item?.createdAt);
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date]?.push(item);
    return acc;
  }, {});

  // Sorting each group by time
  Object?.keys(groupedLogs)?.forEach(date => {
    groupedLogs[date]?.sort(
      (a: any, b: any) => new Date(b.createdAt)?.getTime() - new Date(a.createdAt)?.getTime()
    );
  });

  const sortedDates = sortDates(groupedLogs);

  return (
    <section>
      {sortedDates?.map(date => (
        <div key={date} className='timeline'>
          <DateHeader date={date} />
          {groupedLogs[date]?.map((item: any, index: number) => (
            <div className='timeline-item'>
              <ActivityLogItem key={index} item={item} dataExchange={dataExchange} />
            </div>
          ))}
        </div>
      ))}
    </section>
  );
};

export default ActivityContainer;
