import getFileExtensionFromMimeType from './getFileExtensionFromMimeType';

export const validateFile = (
  file: File,
  fileSizeLimit: number,
  allowedFileTypes: string[]
): string | undefined => {
  const fileSize = file?.size / 1024 / 1024;
  // TODO : we are getting name undefined in file object
  const fileExt = getFileExtensionFromMimeType(file?.type);

  if (fileSize > fileSizeLimit) {
    return `File size exceeds ${fileSizeLimit} MB`;
  }

  if (!allowedFileTypes.includes(fileExt!)) {
    return 'Invalid file format. Allowed formats are ' + allowedFileTypes.toString();
  }
};
