import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import OAToolbar from '../../../../../components/widgets/OAToolbar';
import BreadcrumbItem from './BreadcrumbItem';
import OASelectField from '../../../../../components/fields/OASelectField';
import { bulkUploadValidation, genericValidation } from './validation';
import { useOrderContext } from '../../../context/OrderContext';
import { useAuth } from '../../../../../auth';
import UserContext from '../../../../user-management/context/UserContext';
import OAButton from '../../../../../components/widgets/OAButton';
import { showToast } from '../../../../../helper';
import CsvManager from '../../../../../components/CsvManager';
import { unparseCSVFile } from '../../../../../components/CsvManager/helper';
import { OATypography } from '../../../../../components/widgets';
import { ReactComponent as IllustrationIcon } from '../../../../../assets/icons/Illustration.svg';

interface IBulkUpload {
  user: string;
  order: string;
  insuranceType: string;
}

const inits: IBulkUpload = {
  user: '',
  order: '',
  insuranceType: ''
};

const BulkUpload = () => {
  let navigate = useNavigate();
  const { currentUser } = useAuth();

  const { createBulkOrder, fetchInsuranceProducts } = useOrderContext();

  const {
    users,
    usersList,
    hasMoreUsers,
    isMoreUsersLoading,
    pageInfo,
    fetchUsers,
    setUsersList,
    setIsMoreUsersLoading,
    onPageChange
  } = useContext(UserContext);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [updatedCsv, setUpdatedCsv] = useState<any>();
  const [formValues, setFormValues] = useState<any>();
  const [shouldSubmit, setShouldSubmit] = useState<boolean>(false);
  const [products, setProducts] = useState<any>();
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [isRowCellEmpty, setIsRowCellEmpty] = useState<boolean>(false);

  const isSuperAdmin = currentUser?.data?.role === 'ROLE_SUPER_ADMIN';

  useEffect(() => {
    const fetchInitialUsers = async () => {
      if (!users) {
        await fetchUsers(undefined, false);
      }
    };

    const updateUsersList = () => {
      if (users) {
        //@ts-ignore
        setUsersList((prevOptions: UserOption[]) => [
          ...prevOptions,
          ...users.map((user: any) =>
            Object.assign({ label: `${user.firstName} ${user.lastName}`, value: user.id })
          )
        ]);
      }
    };

    const fetchMoreUsers = async () => {
      if (hasMoreUsers) {
        setIsMoreUsersLoading(true);
        await fetchUsers(undefined, true);
      }
    };

    if (pageInfo?.index === 1) {
      fetchInitialUsers();
      updateUsersList();
    } else {
      fetchMoreUsers();
    }
  }, [users, pageInfo?.index, hasMoreUsers]);

  const handleMenuScroll = (event: any) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    if (scrollTop + clientHeight >= scrollHeight && !isMoreUsersLoading && hasMoreUsers) {
      onPageChange((prevPage: number) => prevPage + 1);
      fetchUsers(undefined, true);
    }
  };

  const handleUpdate = (data: any) => {
    setUpdatedCsv(data);
  };

  const handleSubmitBulkOrders = (values: any, actions: FormikHelpers<any>) => {
    setIsSubmitting(true);
    unparseCSVFile(updatedCsv?.spaces, csvFile => {
      const payload = Object.assign(
        {
          file: csvFile,
          orderRequestStatus: values?.order,
          category: values?.category
        },
        isSuperAdmin
          ? {
              userId: values?.user
            }
          : {}
      );

      // TODO: Refactor this code once a proper solution is available

      createBulkOrder(payload, 'insurance', values?.productCode?.value, (_res: any) => {
        if (_res?.status === 200) {
          setTimeout(() => {
            actions.resetForm();
            setIsSubmitting(false);
            showToast(
              'File has been uploaded. We’ll send you an email once orders are ready.',
              'warning'
            );
            navigate('/apps/orders/insurance', { state: { from: 'insurance/bulk-upload' } });
          }, 2000);
        } else {
          actions.resetForm();
          setIsSubmitting(false);
        }
      });
    });
    return;
  };

  return (
    <>
      <OAToolbar title='Bulk upload' breadcrumb={BreadcrumbItem()} />
      <Formik
        initialValues={inits}
        validationSchema={isSuperAdmin ? bulkUploadValidation : genericValidation}
        onSubmit={handleSubmitBulkOrders}
        enableReinitialize>
        {(formikProps: FormikProps<any>) => {
          const { setFieldValue } = formikProps;
          const handleValueChange = (value: string) => {
            const selectedValue = value;
            const header = Object.assign(
              isSuperAdmin
                ? {
                    userId: value
                  }
                : {}
            );

            fetchInsuranceProducts(value, header, (_res: any) => {
              const insuranceProducts = _res?.data;
              const insuranceProductsOptions = insuranceProducts?.map((product: any) => {
                return {
                  label: `${product.name} - ${product.code}`,
                  value: product.code,
                  requiredBulkFields: product.requiredBulkFields
                };
              });
              setProducts(insuranceProductsOptions);
            });
            setFieldValue('category', selectedValue);
            setFieldValue('productCode', '');
          };

          setFormValues(formikProps?.values);
          useEffect(() => {
            if (shouldSubmit) {
              formikProps.submitForm();
              setShouldSubmit(false); // Reset the state
            }
          }, [shouldSubmit]);

          const handleProductChange = (product: string) => {
            setSelectedProduct(product);
          };

          return (
            <Form>
              <Card className='mb-6'>
                <Card.Body>
                  <Row>
                    {isSuperAdmin && (
                      <Col>
                        <Field
                          name='user'
                          label='Select a user'
                          as={OASelectField}
                          options={usersList}
                          isLoading={isMoreUsersLoading}
                          onMenuScrollToBottom={handleMenuScroll}
                          required
                          valueOnly
                        />
                      </Col>
                    )}
                    <Col>
                      <Field
                        name='order'
                        label='Order type'
                        as={OASelectField}
                        options={[
                          { label: 'Order draft', value: 'ORDER_DRAFT' },
                          { label: 'Order submit', value: 'ORDER_SUBMIT' }
                        ]}
                        valueOnly
                        required
                      />
                    </Col>
                    <Col>
                      <OASelectField
                        name='category'
                        label='Insurance type'
                        options={[
                          { label: 'CFAR', value: 'CFAR_FOR_HOTEL' },
                          { label: 'Standard Insurance', value: 'STANDARD_TRAVEL_INSURANCE' }
                        ]}
                        onChangeData={(value: string) => handleValueChange(value)}
                        valueOnly
                        required
                      />
                    </Col>
                    <Col>
                      <OASelectField
                        name='productCode'
                        label='Product name'
                        options={products}
                        onChangeData={(value: string) => handleProductChange(value)}
                        required
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              {formikProps?.values?.productCode && formikProps?.values?.productCode !== '' ? (
                <Card>
                  <Card.Body>
                    <Col>
                      <CsvManager
                        productSpecificFields={formValues?.productCode}
                        productName={selectedProduct?.label}
                        orderType='insurance'
                        category={formValues?.category}
                        onUpdate={handleUpdate}
                        selectedProduct={selectedProduct}
                        isRowCellEmpty={isRowCellEmpty}
                        setIsRowCellEmpty={setIsRowCellEmpty}
                      />
                      <div className='d-flex flex-stack justify-content-end mt-6'>
                        <div>
                          <OAButton
                            size='sm'
                            type='reset'
                            className='btn btn-secondary fw-bolder me-3'
                            label='Clear file'
                            disabled={isSubmitting}
                          />
                          <OAButton
                            size='sm'
                            type='submit'
                            className='btn btn-primary fw-bolder'
                            label='Submit order'
                            disabled={
                              !(formikProps.isValid && formikProps.dirty) ||
                              isSubmitting ||
                              (Array.isArray(updatedCsv?.hasErrors)
                                ? updatedCsv.hasErrors.length > 0
                                : updatedCsv?.hasErrors) ||
                              isRowCellEmpty
                            }
                            isLoading={isSubmitting}
                          />
                        </div>
                      </div>
                    </Col>
                  </Card.Body>
                </Card>
              ) : (
                <div className='d-flex flex-column align-items-center text-center mt-20'>
                  <IllustrationIcon />
                  <OATypography variant='h4' sx={{ color: theme => theme.palette.grey[900] }}>
                    Select insurance
                  </OATypography>
                  <OATypography
                    variant='body2'
                    sx={{ color: theme => theme.palette.grey[400], mt: '8px' }}>
                    Select insurance details to view editor
                  </OATypography>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default BulkUpload;
