import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Field, Formik, Form, FormikProps } from 'formik';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { capitalize } from 'lodash';

import OATextAreaField from '../../../../../components/fields/OATextAreaField';
import OAModalComponent from '../../../../../components/OAModalComponent';
import { useOrderContext } from '../../../context/OrderContext';
import { IOrderType } from '../../../types';
import OASelectField from '../../../../../components/fields/OASelectField';
import OAFileUploadField from '../../../../../components/fields/OAFileUploadField';
import { ORDER_TYPE } from '../../../constants';
import { useS3FileUploader } from '../../../../../hooks/useS3FileOperation';
import showToast from '../../../../../helper/showToast';
import { useAppContext } from '../../../../../context/AppContext';

/**
 * types ...
 */
interface IInsuranceStatusUpdateModal {
  isOpen: boolean;
  onHide: () => void;
  member: any;
}

export default function InsuranceStatusUpdateModal(props: IInsuranceStatusUpdateModal) {
  return (
    <OAModalComponent
      show={props?.isOpen}
      onHide={props?.onHide}
      className='visa-status-update__modal'
      header={{
        title: `Update status for ${capitalize(props?.member?.firstName)}`,
        closeButton: true
      }}
      body={{
        content: <InsuranceStatusUpdateForm onHide={props?.onHide} member={props?.member} />,
        className: 'visa-status-update__modal-body'
      }}
    />
  );
}

/**
 * Additional component : InsuranceStatusUpdateForm
 */
interface IInsuranceStatusUpdateForm {
  onHide: () => void;
  member: any;
}

export interface IInsuranceStatusUpdateFormValues {
  status: string;
  document: string;
  remark?: string;
}

function InsuranceStatusUpdateForm(props: IInsuranceStatusUpdateForm) {
  const params = useParams<{ orderType: IOrderType; id?: string }>();

  const { dataExchange } = useAppContext();
  const { updateMemberVisaStatus, orderData } = useOrderContext();
  const [uploadFile] = useS3FileUploader(5, ['jpg', 'png', 'jpeg', 'pdf']);

  const [file, setFile] = useState<any>({
    url: null,
    name: null
  });

  const init: IInsuranceStatusUpdateFormValues = {
    status: props?.member?.status ?? '',
    document: '',
    remark: ''
  };

  const validate = (values: IInsuranceStatusUpdateFormValues) => {
    const errors: any = {};
    if (!values.status) {
      errors.status = 'Visa Status is a required field';
    }

    if (!values.document) {
      errors.document = 'Document is a required field';
    }

    return errors;
  };

  let updateStatusOptionsLabel = 'Insurance Status';
  let updateStatusOptions = dataExchange?.INSURANCE_UPDATE_STATUS;

  let filteredStatusOptions = updateStatusOptions?.filter(
    (status: any) => status?.value !== 'PARTIAL_APPROVED'
  );
  updateStatusOptions = filteredStatusOptions;

  const fileUploadHandler = async (e: any) => {
    const orderId = params?.id;
    const memberId = props?.member?.id;
    const fieldName = params?.orderType === ORDER_TYPE.VISA ? 'visa_status_' : 'insurance_status_';
    const result = await uploadFile(
      e?.target?.files?.[0],
      `order/insurance/${orderId}/${memberId}/${fieldName}`
    );
    if (result?.uploadedUrl) {
      setFile({
        url: result?.uploadedUrl,
        name: result?.uploadedUrl?.split(`/${memberId}/`)[1]
      });
    } else {
      if (result?.error) showToast(result?.error, 'error');
    }
  };

  const handleSubmit = (values: IInsuranceStatusUpdateFormValues) => {
    const draft = { ...values, document: file?.url };
    updateMemberVisaStatus(params?.orderType!, params?.id!, props?.member?.id, draft, _res => {
      setFile({ url: null, name: null });
      props?.onHide();
    });
  };

  return (
    <Formik initialValues={init} onSubmit={handleSubmit} validate={validate}>
      {(formikProps: FormikProps<IInsuranceStatusUpdateFormValues>) => {
        return (
          <Form className='w-100'>
            <Container fluid className='p-0'>
              {/**
               * NOTE: row contains form fields ...
               */}
              <Row>
                <Col lg={12} className='mb-6'>
                  <Field
                    name='status'
                    label={updateStatusOptionsLabel}
                    as={OASelectField}
                    options={updateStatusOptions}
                    valueOnly
                    required
                  />
                </Col>
                <Col lg={12} className='mb-6'>
                  <OAFileUploadField
                    name='document'
                    label='Upload document'
                    doc={props?.member?.downloadLink}
                    onChange={e => fileUploadHandler(e)}
                    required
                  />
                </Col>
                <Col lg={12} className='mb-6'>
                  <OATextAreaField name='remark' label='Remarks' placeholder='Type here' />
                </Col>
              </Row>
              {/**
               * NOTE: row contains form action buttons ...
               */}
              <Row>
                <Col>
                  <div className='d-flex justify-content-end'>
                    <Button
                      type='button'
                      size='sm'
                      onClick={props?.onHide}
                      disabled={formikProps?.isSubmitting}
                      className='btn-light-primary btn-active-light-primary me-4'>
                      Cancel
                    </Button>
                    <Button
                      type='submit'
                      size='sm'
                      disabled={
                        (!formikProps?.isValid && formikProps?.dirty) || formikProps?.isSubmitting
                      }
                      className='btn-primary btn-active-primary'>
                      {formikProps?.isSubmitting ? 'Updating...' : 'Update'}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
}
