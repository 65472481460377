import { Field } from 'formik';
import { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import {
  capitalizeFirstLetterOnly,
  formatNumberForCurrency,
  getCurrencySymbol
} from '../../helper';
import InsuranceBenefitsModal from '../../pages/order-management/order-create/insurance/AddInsuranceCover/InsuranceBenefitsModal';
import OALabelValue from '../widgets/OALabelValue';
import { KTSVG } from '../../_metronic/helpers';
import { Country } from 'country-state-city';
import { OATypography } from '../widgets';

interface IOAProductCard {
  visaInfo?: any;
  prodInfo?: any;
  ageGroupText?: any;
  draftPrice?: any;
  quotation?: any;
  selectedId?: any;
  index?: any;
  inputEl?: any;
  ancillaryType?: any;
  isBasicCard?: any;
}

export default function OAProductCard(props: IOAProductCard) {
  // This logic is added here because, it is used only in this component.
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const countryInfo = Country.getAllCountries().find(
    country => country.currency === props?.prodInfo?.productInformation?.sumInsuredCurrency
  );
  return (
    <div>
      <div className='d-flex'>
        <div className='flex-grow-1 pe-4'>
          <div className='d-flex'>
            <div className='flex-grow-1'>
              <div className='d-flex'>
                <p className={`fs-6 fw-bold ${props?.isBasicCard ? 'mb-0' : ''}`}>
                  {props?.prodInfo?.displayName ?? props?.prodInfo?.name}
                </p>
                <div className='badge badge-light-primary ms-3 mb-2'>
                  {props?.prodInfo?.productInformation?.consulate ?? props?.prodInfo?.consulate}
                </div>
              </div>
              {props?.isBasicCard ? (
                props?.prodInfo?.productInformation?.processingTime ||
                props?.prodInfo?.processingTime ? (
                  <OATypography
                    variant='body2'
                    sx={{ color: theme => theme?.palette?.grey?.[400] }}>
                    {`Processing time: ${
                      props?.prodInfo?.productInformation?.processingTime ??
                      props?.prodInfo?.processingTime
                    }`}
                  </OATypography>
                ) : null
              ) : (
                <>
                  <InfoTag tag={props?.ageGroupText} />
                  <InfoTag
                    tag={
                      props?.prodInfo?.productInformation?.processingType ??
                      props?.prodInfo?.processingType
                    }
                  />
                  <InfoTag
                    tag={props?.prodInfo?.productInformation?.tripType ?? props?.prodInfo?.tripType}
                  />
                  <InfoTag
                    tag={
                      props?.prodInfo?.productInformation?.occupation?.split('_').join(' ') ??
                      props?.prodInfo?.occupation?.split('_').join(' ')
                    }
                  />
                </>
              )}
            </div>
            <div>
              <div className='d-flex justify-content-end'>
                {props?.prodInfo?.ancillaryType === 'INSURANCE' ? (
                  <h4>
                    {getCurrencySymbol(props?.prodInfo?.productInformation?.sumInsuredCurrency) ??
                      null}
                    {formatNumberForCurrency(props?.prodInfo?.productInformation?.sumInsured, {
                      countryCode: countryInfo?.isoCode,
                      toFixed: false
                    }) ?? 'N/A'}
                  </h4>
                ) : (
                  <h4>{!props?.visaInfo ? props?.draftPrice : null}</h4>
                )}
              </div>
              <div className='d-flex justify-content-end'>
                {!props?.visaInfo && (
                  <p>
                    {props?.prodInfo?.ancillaryType === 'INSURANCE'
                      ? 'Total Sum Insured'
                      : 'Per traveller'}
                  </p>
                )}
              </div>
            </div>
          </div>
          {props?.prodInfo?.ancillaryType === 'INSURANCE' ? (
            <Row>
              <Col>
                <OALabelValue
                  label={props?.prodInfo?.productInformation?.benefits[0]?.name ?? 'N/A'}
                  value={props?.prodInfo?.productInformation?.benefits[0]?.sumInsured ?? 'N/A'}
                />
                <OALabelValue
                  label={props?.prodInfo?.productInformation?.benefits[1]?.name ?? 'N/A'}
                  value={props?.prodInfo?.productInformation?.benefits[1]?.sumInsured ?? 'N/A'}
                />
              </Col>
              <Col>
                <OALabelValue
                  label={props?.prodInfo?.productInformation?.benefits[2]?.name ?? 'N/A'}
                  value={props?.prodInfo?.productInformation?.benefits[2]?.sumInsured ?? 'N/A'}
                />
                <div>
                  <span
                    className='fs-8 btn btn-link'
                    onClick={e => {
                      e.stopPropagation();
                      setIsOpen(true);
                    }}>
                    {'View more details >>'}
                  </span>
                </div>
              </Col>
            </Row>
          ) : (
            <>
              {!props?.isBasicCard && (
                <div className='d-flex justify-content-between'>
                  <div>
                    {props?.prodInfo?.processingTime ? (
                      <OALabelValue
                        label='Processing time'
                        value={
                          props?.prodInfo?.productInformation?.processingTime ??
                          props?.prodInfo?.processingTime
                        }
                        className='mt-2'
                      />
                    ) : null}
                    {props?.prodInfo?.stayPeriod ? (
                      <OALabelValue
                        label='Stay'
                        value={
                          props?.prodInfo?.productInformation?.stayPeriod ??
                          props?.prodInfo?.stayPeriod
                        }
                        className='mt-2'
                      />
                    ) : null}
                  </div>
                  <div>
                    {props?.prodInfo?.validity ? (
                      <OALabelValue
                        label='Validity'
                        value={
                          props?.prodInfo?.productInformation?.validity ?? props?.prodInfo?.validity
                        }
                        className='mt-2'
                      />
                    ) : null}

                    {props?.prodInfo?.visaType ? (
                      <OALabelValue
                        label='Visa type'
                        value={
                          props?.prodInfo?.productInformation?.visaType ?? props?.prodInfo?.visaType
                        }
                        className='mt-2'
                      />
                    ) : null}
                  </div>
                </div>
              )}
            </>
          )}
          {props?.prodInfo?.productInformation?.disclaimer && (
            <div className='d-flex mt-4'>
              <div>
                <KTSVG
                  path='/media/icons/duotune/general/gen045.svg'
                  className='svg-icon-3 svg-icon-gray-600 me-1'
                />
              </div>
              <div>{props?.prodInfo?.productInformation?.disclaimer}</div>
            </div>
          )}
        </div>
        <div className='d-flex align-items-center'>
          {props?.quotation || props?.visaInfo ? (
            <input
              className='form-check-input'
              type='radio'
              name='requestedProductId'
              checked={
                props?.selectedId === (props?.visaInfo ? props?.index : props?.prodInfo?.id)
                  ? true
                  : false
              }
            />
          ) : (
            <Field
              innerRef={props?.inputEl}
              className='form-check-input'
              type='radio'
              name='requestedProductId'
              value={props?.prodInfo?.id}
            />
          )}
        </div>
        <InsuranceBenefitsModal
          show={isOpen}
          onHide={() => setIsOpen(false)}
          data={{
            name: props?.prodInfo?.name,
            benefits: props?.prodInfo?.productInformation?.benefits
          }}
        />
      </div>
    </div>
  );
}

const InfoTag = ({ tag }: { tag: string }) => (
  <span className='badge py-1 px-2 fs-7 badge-light-info me-2'>
    {capitalizeFirstLetterOnly(tag?.toLowerCase())}
  </span>
);
