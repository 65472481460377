import { ILabelValuePair, ILabelValuePairWithType, IStepSchema } from './types';
import { IOrderType } from './types';
import { Country, State } from 'country-state-city';

var countries = require('i18n-iso-countries');
countries?.registerLocale(require('i18n-iso-countries/langs/en.json'));

const countriesData = countries?.getNames('en', { select: 'official' });
const stateData = State.getStatesOfCountry('IN');
const countryData = Country.getAllCountries();
let countryArr: any[] = [];
export const ANCILLARY_TYPE = {
  EVISA: 'EVISA',
  INSURANCE: 'INSURANCE'
};

export const ANCILLARY_TYPE_ROUTE_MAP = {
  EVISA: 'visa',
  INSURANCE: 'insurance'
};

export const ORDER_TYPE: { VISA: IOrderType; INSURANCE: IOrderType } = {
  VISA: 'visa',
  INSURANCE: 'insurance'
};

export const ORDER_CREATE_ACTIONS = {
  ADD_TRIP_DETAILS: 'add_trip_details',
  REMOVE_TRIP_DETAILS: 'remove_trip_details',
  ADD_TRIP_DURATION: 'add_trip_duration',
  ADD_OR_REMOVE_CONSULATE: 'add_or_remove_consulate',
  ADD_TRAVELLER_DETAILS: 'add_traveller_details',
  EDIT_TRAVELLER_DETAILS: 'edit_traveller_details',
  MAKE_TRAVELLER_AS_PRIMARY: 'make_traveller_as_primary',
  DELETE_TRAVELLER: 'delete_traveller',
  GET_ORDER_QUOTATION: 'get_order_quotation',
  REVIEW_ORDER: 'review_order',
  CREATE_DRAFT_ORDER: 'create_draft_order',
  EDIT_DRAFT_ORDER: 'edit_draft_order',
  SELECTED_ORDER: 'selected_order',
  RESET_FORM_STATE: 'reset_form_state',
  MODIFY_TRAVELLERS: 'modify_travellers',
  ADD_MARKUP: 'add_markup',
  DOWNLOAD_QUOTE: 'download_quote',
  QUOTATION_ID: 'quotation_id',
  ADD_ADDON_SERVICE: 'add_addon_service',
  ADD_ADDON_SERVICES: 'add_addon_services',
  REMOVE_ADDON_SERVICE: 'remove_addon_service',
  GROUP_NAME: 'group_name',
  ADD_GST: 'add_gst',
  ADD_TRAVELLER_FORM_FIELDS: 'add_traveller_form_fields',
  ADD_TRIP_BOOKINGINFORMATION: 'add_trip_bookingInformation',
  ADD_INSURANCE_CATEGORY: 'add_insurance_category',
  CLONE_ORDER: 'clone_order',
  ADD_STAY_DETAILS: 'add_stay_details',
  UPDATE_STATE: 'update_state',
  CREATE_DRAFT_QUOTE: 'create_draft_quote'
};

export const ORDER_STEPS_SCHEMA: {
  VISA: Array<IStepSchema>;
  INSURANCE: Array<IStepSchema>;
  QUOTATION: Array<IStepSchema>;
} = {
  VISA: [
    {
      index: 1,
      current: true,
      title: 'Visa type',
      desc: 'Select tourist visa type'
    },
    {
      index: 2,
      current: false,
      title: 'Trip & Traveller details',
      desc: 'Add trip dates & traveller details'
    },
    {
      index: 3,
      current: false,
      title: 'Add documents',
      desc: 'Provide all the listed documents'
    },
    {
      index: 4,
      current: false,
      title: 'Payment summary',
      desc: 'View order summary and payment'
    }
  ],
  INSURANCE: [
    {
      index: 1,
      current: true,
      title: 'Select your cover',
      desc: 'Add travel details and select cover'
    },
    {
      index: 2,
      current: false,
      title: 'Traveller details',
      desc: 'Add traveller details'
    },
    {
      index: 3,
      current: false,
      title: 'Payment summary',
      desc: 'View order summary and payment'
    }
  ],
  QUOTATION: [
    {
      index: 1,
      current: true,
      title: 'Select visa',
      desc: 'Search & select visa product'
    },
    {
      index: 2,
      current: false,
      title: 'Add travellers',
      desc: 'Provide traveller details'
    },
    {
      index: 3,
      current: false,
      title: 'Order summary',
      desc: 'Confire order and payment details'
    }
  ]
};

export const VisaTypeOptions: Array<ILabelValuePair> = [
  { value: 'TOURIST', label: 'Tourist' },
  { value: 'BUSINESS', label: 'Business' },
  { value: 'TRANSIT', label: 'Transit' }
];

export const VisaTypeOptionsMap = {
  TOURIST: 'Tourist',
  BUSINESS: 'Business',
  TRANSIT: 'Transit'
};

export const DestinationOptions: Array<ILabelValuePair> = [
  { value: 'Domestic (Within India)', label: 'Domestic (Within India)' },
  // { value: 'Worldwide (Excluding USA & Canada)', label: 'Worldwide (Excluding USA & Canada)' },
  { value: 'Worldwide (All countries)', label: 'Worldwide (All countries)' },
  { value: 'ASIA', label: 'Asian Countries' }
];

export const DestinationOptionsMap = {
  DOMESTIC: 'Domestic (Within India)',
  // WO_USA_CANADA: 'Worldwide (Excluding USA & Canada)',
  ALL: 'Worldwide (All countries)',
  ASIA: 'ASIA'
};

export const TripTypeOptions: Array<ILabelValuePair> = [
  { value: 'SINGLE', label: 'Single' },
  // { value: 'ANNUAL', label: 'Annual' },
  { value: 'ROUND', label: 'Round' },
  { value: 'MULTI', label: 'Multi' }
];

export const SupplierOptions: Array<ILabelValuePair> = [
  { value: 'SUP002', label: 'Visa2Fly' },
  { value: 'SUP004', label: 'Cozmo' },
  { value: 'SUP007', label: 'Cozmo Offline' }
  // { value: 'akbar', label: 'Akbar Travels' },
];

export const InsuranceSupplierOptions: Array<ILabelValuePair> = [
  { value: 'SUP005', label: 'Symbo/Reliance' },
  { value: 'SUP003', label: 'Godigit' },
  { value: 'SUP001', label: 'Travel cover' }
];

export const SUPPLIER_CODE = {
  VFS: 'SUP011',
  DNATA: 'SUP006',
  VISA2FLY: 'SUP002',
  COZMO: 'SUP004',
  COZMO_OFFLINE: 'SUP007',
  AKBAR: 'akbar',
  SYMBOL: 'SUP005',
  GODIGIT: 'SUP003',
  TRAVEL_COVER: 'SUP001'
};

export const DepartureCountries: Array<ILabelValuePair> = [{ value: 'IN', label: 'India' }];

// export const ArrivalCountries: Array<ILabelValuePair> = [
//   { label: 'Netherlands', value: 'NL' },
//   { label: 'Viet Nam', value: 'VN' },
//   { label: 'Uganda', value: 'UG' },
//   { label: 'Iran, Islamic Republic of', value: 'IR' },
//   { label: 'Tanzania, United Republic of', value: 'TZ' },
//   { label: 'Kazakhstan', value: 'KZ' },
//   { label: 'Belarus', value: 'BY' },
//   { label: 'Rwanda', value: 'RW' },
//   { label: 'Sri Lanka', value: 'LK' },
//   { label: 'Luxembourg', value: 'LU' },
//   { label: 'Poland', value: 'PL' },
//   { label: 'Austria', value: 'AT' },
//   { label: 'Denmark', value: 'DK' },
//   { label: 'Chile', value: 'CL' },
//   { label: 'Ghana', value: 'GH' },
//   { label: 'Qatar', value: 'QA' },
//   { label: 'Barbados', value: 'BB' },
//   { label: 'Myanmar', value: 'MM' },
//   { label: 'Mexico', value: 'MX' },
//   { label: 'Hungary', value: 'HU' },
//   { label: 'Sweden', value: 'SE' },
//   { label: 'Turkey', value: 'TR' },
//   { label: 'Tajikistan', value: 'TJ' },
//   { label: 'Saint Vincent and the Grenadines', value: 'VC' },
//   { label: 'Japan', value: 'JP' },
//   { label: 'Bahamas', value: 'BS' },
//   { label: 'United Kingdom', value: 'GB' },
//   { label: 'Portugal', value: 'PT' },
//   { label: 'Philippines', value: 'PH' },
//   { label: 'Armenia', value: 'AM' },
//   { label: 'Uzbekistan', value: 'UZ' },
//   { label: 'Papua New Guinea', value: 'PG' },
//   { label: 'Argentina', value: 'AR' },
//   { label: 'Angola', value: 'AO' },
//   { label: 'Bangladesh', value: 'BD' },
//   { label: 'Romania', value: 'RO' },
//   { label: 'Germany', value: 'DE' },
//   { label: 'Benin', value: 'BJ' },
//   { label: 'Morocco', value: 'MA' },
//   { label: 'Switzerland', value: 'CH' },
//   { label: 'Ireland', value: 'IE' },
//   { label: 'Thailand', value: 'TH' },
//   { label: 'Australia', value: 'AU' },
//   { label: 'Nigeria', value: 'NG' },
//   { label: 'Greece', value: 'GR' },
//   { label: 'South Korea', value: 'KR' },
//   { label: 'Colombia', value: 'CO' },
//   { label: 'Djibouti', value: 'DJ' },
//   { label: 'Kyrgyzstan Republic', value: 'KG' },
//   { label: 'Iceland', value: 'IS' },
//   { label: 'Oman', value: 'OM' },
//   { label: 'Algeria', value: 'DZ' },
//   { label: 'Costa Rica', value: 'CR' },
//   { label: 'South Africa', value: 'ZA' },
//   { label: 'Latvia', value: 'LV' },
//   { label: 'Moldova, Republic of', value: 'MD' },
//   { label: 'Italy', value: 'IT' },
//   { label: 'Hong Kong', value: 'HK' },
//   { label: 'Czech Republic', value: 'CZ' },
//   { label: 'Norway', value: 'NO' },
//   { label: 'Bolivia, Plurinational State of', value: 'BO' },
//   { label: 'Cuba', value: 'CU' },
//   { label: 'Singapore', value: 'SG' },
//   { label: 'United Arab Emirates', value: 'AE' },
//   { label: 'Malaysia', value: 'MY' },
//   { label: 'Venezuela, Bolivarian Republic of', value: 'VE' },
//   { label: 'Bermuda', value: 'BM' },
//   { label: 'Lebanon', value: 'LB' },
//   { label: 'Bahrain', value: 'BH' },
//   { label: 'Ethiopia', value: 'ET' },
//   { label: 'Croatia', value: 'HR' },
//   { label: 'Ivory Coast', value: 'CI' },
//   { label: 'Indonesia', value: 'ID' },
//   { label: 'Brazil', value: 'BR' },
//   { label: 'Peru', value: 'PE' },
//   { label: 'Guinea-Bissau', value: 'GW' },
//   { label: 'Spain', value: 'ES' },
//   { label: 'Canada', value: 'CA' },
//   { label: 'France', value: 'FR' },
//   { label: 'New Zealand', value: 'NZ' },
//   { label: 'Kenya', value: 'KE' },
//   { label: 'Belgium', value: 'BE' },
//   { label: 'China', value: 'CN' },
//   { label: 'Lithuania', value: 'LT' },
//   { label: 'Lesotho', value: 'LS' },
//   { label: 'Cambodia', value: 'KH' },
//   { label: 'Saint Lucia', value: 'LC' },
//   { label: 'Finland', value: 'FI' },
//   { label: 'Suriname', value: 'SR' },
//   { label: 'Azerbaijan', value: 'AZ' },
//   { label: 'Panama', value: 'PA' },
//   { label: 'Malta', value: 'MT' }
// ];

export const MemberTitleOptions: Array<ILabelValuePair> = [
  { value: 'Mr', label: 'Mr' },
  { value: 'Ms', label: 'Ms' },
  { value: 'Mrs', label: 'Mrs' },
  // { value: 'Miss', label: 'Miss' },
  { value: 'Master', label: 'Master' }
];

export const OccupationOptions: Array<ILabelValuePair> = [
  { value: 'SELF_EMPLOYED', label: 'Self Employed' },
  { value: 'SALARIED', label: 'Salaried' },
  { value: 'OTHER', label: 'Other' }
];

export const OccupationOptionsMap = {
  SELF_EMPLOYED: 'SELF_EMPLOYED',
  SALARIED: 'SALARIED',
  OTHER: 'OTHER'
};

export const GenderOptions: Array<ILabelValuePair> = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'Transgender', label: 'Transgender' }
];

export const NationalityOptions: Array<ILabelValuePair> = [
  { value: 'IN', label: 'Indian' },
  { value: 'LK', label: 'Srilankan' }
];

export const PassportIssuingCountriesOptions: Array<ILabelValuePair> = [
  { value: 'IN', label: 'India' }
  // { value: 'LK', label: 'Sri Lanka' }
];

export const UserRoleOptions: Array<ILabelValuePair> = [
  { value: 'ROLE_SUPER_ADMIN', label: 'Super Admin' },
  { value: 'ROLE_ORGANIZATION_ADMIN', label: 'Org Admin' },
  { value: 'ROLE_ADMIN', label: 'Admin' },
  { value: 'ROLE_OPERATION', label: 'Operation' },
  { value: 'ROLE_OPERATION_EXTERNAL', label: 'Operation External' },
  { value: 'ROLE_INSURANCE_CLAIM_USER', label: 'Insurance Claim User' }
];

export const CountryOptions: Array<ILabelValuePair> = Object.keys(countriesData)?.map(key => ({
  label: countriesData[key],
  value: key
}));

export const StateOptions = Object.keys(stateData).map(key => ({
  label: stateData[parseInt(key)].name,
  value: stateData[parseInt(key)].isoCode
}));

countryData?.map((country: any) => {
  if (country?.timezones[0]?.zoneName?.includes('Asia') && country?.name !== 'Afghanistan') {
    countryArr.push(country);
  }
});
export const countryOptions = Object.keys(countryArr)?.map(key => ({
  label: countryArr[parseInt(key)]?.name,
  value: countryArr[parseInt(key)]?.isoCode
}));

export const ProfessionOptions: Array<ILabelValuePair> = [
  { value: 'ACCOUNTANT', label: 'Accountant' },
  { value: 'NOT EMPLOYED', label: 'Not Employed' },
  { value: 'NONE', label: 'None' }
];

// export const ReligionOptions: Array<ILabelValuePair> = [
//   {
//     label: 'CHRISTIAN',
//     value: 'CHRISTIAN'
//   },
//   {
//     label: 'ISLAM',
//     value: 'ISLAM'
//   },
//   {
//     label: 'HINDUISM',
//     value: 'HINDUISM'
//   },
//   {
//     label: 'NON RELIGIOUS',
//     value: 'NON RELIGIOUS'
//   },
//   {
//     label: 'CHINESE FOLK',
//     value: 'CHINESE FOLK'
//   },
//   {
//     label: 'BUDDHISM',
//     value: 'BUDDHISM'
//   },
//   {
//     label: 'TRIBAL RELIGIONS',
//     value: 'TRIBAL RELIGIONS'
//   },
//   {
//     label: "BAHA'I",
//     value: "BAHA'I"
//   },
//   {
//     label: 'ZOROASTRIANISM',
//     value: 'ZOROASTRIANISM'
//   },
//   {
//     label: 'SABEAN',
//     value: 'SABEAN'
//   },
//   {
//     label: 'ALMHARICIH',
//     value: 'ALMHARICIH'
//   },
//   {
//     label: 'JAINISM',
//     value: 'JAINISM'
//   },
//   {
//     label: 'CONFUCIANISM',
//     value: 'CONFUCIANISM'
//   },
//   {
//     label: 'SPIRITISM',
//     value: 'SPIRITISM'
//   },
//   {
//     label: 'SHINTO',
//     value: 'SHINTO'
//   },
//   {
//     label: 'PAGANISM',
//     value: 'PAGANISM'
//   },
//   {
//     label: 'CAO DAI',
//     value: 'CAO DAI'
//   },
//   {
//     label: 'TENRIKYO',
//     value: 'TENRIKYO'
//   },
//   {
//     label: 'RASTAFARIANISM',
//     value: 'RASTAFARIANISM'
//   },
//   {
//     label: 'TAOISM',
//     value: 'TAOISM'
//   },
//   {
//     label: 'SIKHISM',
//     value: 'SIKHISM'
//   },
//   {
//     label: 'JUDAISM',
//     value: 'JUDAISM'
//   },
//   {
//     label: 'QADIANI',
//     value: 'QADIANI'
//   },
//   {
//     label: 'ATHEISTS',
//     value: 'ATHEISTS'
//   },
//   {
//     label: 'UNDEFIENED',
//     value: 'UNDEFIENED'
//   },
//   {
//     label: 'OTHERS',
//     value: 'OTHERS'
//   }
// ];

export const MaritalStatusOptions: Array<ILabelValuePair> = [
  {
    label: 'Single',
    value: 'SINGLE'
  },
  {
    label: 'Married',
    value: 'MARRIED'
  },
  {
    label: 'Divorced',
    value: 'DIVORCED'
  },
  {
    label: 'Widowed',
    value: 'WIDOWED'
  }
];

export const ORDER_STATUS = {
  ORDER_DRAFT: 'ORDER_DRAFT',
  ORDER_SUBMIT: 'ORDER_SUBMIT',
  ORDER_SUBMIT_INITIATED: 'ORDER_SUBMIT_INITIATED',
  ORDER_FAILED: 'ORDER_FAILED',
  ORDER_SUBMIT_FAILED: 'ORDER_SUBMIT_FAILED',
  ORDER_QUOTE: 'ORDER_QUOTE',
  ORDER_PROCESSING: 'ORDER_PROCESSING',
  IN_PROGRESS: 'IN_PROGRESS',
  ORDER_INITIATED: 'ORDER_INITIATED',
  DOCUMENT_PENDING: 'DOCUMENT_PENDING',
  BOOKING_CANCELLED: 'BOOKING_CANCELLED',
  DOCUMENT_COLLECTED: 'DOCUMENT_COLLECTED',
  DOCUMENT_COMPLETED: 'DOCUMENT_COMPLETED',
  PARTIAL_APPROVED: 'PARTIAL_APPROVED',
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  REJECTED: 'REJECTED',
  VISA_APPLICATION_APPROVED: 'VISA_APPLICATION_APPROVED',
  PARTIAL_APPLICATIONS_APPROVED: 'PARTIAL_APPLICATIONS_APPROVED',
  APPROVED: 'APPROVED',
  DISPATCHED: 'DISPATCHED',
  VISA_APPLIED_IN_EMBASSY: 'VISA_APPLIED_IN_EMBASSY',
  ORDER_CANCELLATION_REQUESTED: 'ORDER_CANCELLATION_REQUESTED',
  ORDER_CANCELLATION_FAILED: 'ORDER_CANCELLATION_FAILED',
  ISSUANCE_IN_PROGRESS: 'ISSUANCE_IN_PROGRESS'
};
/**
 * NOTE:
 */
export const ORDER_DRAFT_STATUS_ARR = ['ORDER_DRAFT', 'ORDER_SUBMIT_FAILED', 'ORDER_FAILED'];
export const ORDER_REVIEW_STATUS_ARR = ['ORDER_SUBMIT_IN_REVIEW', 'PENDING_ACTION', 'IN_REVIEW'];
export const ORDER_CONSUMER_ACTION_STATUS = [
  'ORDER_DRAFT',
  'ORDER_SUBMIT_IN_REVIEW',
  'PENDING_ACTION'
];
export const ORDER_SUBMITTED_STATUS_ARR = [
  'ORDER_SUBMIT_INITIATED',
  'ORDER_SUBMIT',
  'DOCUMENT_UPLOADED',
  'PAYMENT_COMPLETED',
  'VISA_APPLIED_IN_EMBASSY',
  'VISA_APPLICATION_APPROVED',
  'VISA_APPLICATION_REJECTED'
];
export const ORDER_APPROVED_STATUS_ARR = [
  'VISA_APPLICATION_APPROVED',
  'PARTIAL_APPLICATIONS_APPROVED',
  'APPROVED'
];
export const OFFLINE_ORDER_SUBMITTED_STATUS_ARR = [
  'ORDER_SUBMITTED_TO_EMBASSY',
  'ORDER_PROCESSING'
];

export const OrderActionStatusMap = {
  CREATED: 'CREATED',
  SUPPLIER_VIEWED: 'SUPPLIER_VIEWED',
  IN_REVIEW: 'IN_REVIEW',
  CONSUMER_VIEWED: 'CONSUMER_VIEWED',
  WAITING_FOR_APPROVAL: 'WAITING_FOR_APPROVAL',
  COMPLETED: 'COMPLETED',
  DELETED: 'DELETED'
};

export const OrderActionTypeMap = {
  TRIP_INFO: 'TRIP_INFO',
  // MEMBERS: 'MEMBERS',
  MEMBER_INFO: 'MEMBER_INFO',
  MEMBER_DOCUMENTS: 'MEMBER_DOCUMENTS',
  TRAVELER_DOCUMENTS: 'TRAVELER_DOCUMENTS',
  TRAVELER_INFO: 'TRAVELER_INFO'
  // OTHERS: 'OTHERS'
};

export const OrderActionTypeOptions = [
  { value: OrderActionTypeMap.TRIP_INFO, label: 'Travel information' },
  // { value: OrderActionTypeMap.MEMBERS, label: 'Members Information' },
  { value: OrderActionTypeMap.MEMBER_INFO, label: 'Member Information' },
  { value: OrderActionTypeMap.MEMBER_DOCUMENTS, label: 'Document Information' },
  { value: OrderActionTypeMap.TRAVELER_DOCUMENTS, label: 'Traveller Documents' },
  { value: OrderActionTypeMap.TRAVELER_INFO, label: 'Traveller Information' }
  // { value: OrderActionTypeMap.OTHERS, label: 'Others' }
];

// export const OrderActionReasonMap: any = {
//   TRIP_INFO: [
//     // { value: 'TRIP_INFO', label: 'Travel info' },
//     { value: 'TRIP_DURATION', label: 'Travel duration' },
//     { value: 'VISA_SELECTED', label: 'Visa selected' },
//     { value: 'OTHERS', label: 'Other' }
//   ],
//   MEMBER_INFO: [
//     { value: 'MEMBER_PERSONAL_INFO', label: 'Member personal info' },
//     { value: 'OTHERS', label: 'Others' }
//   ],
//   MEMBER_DOCUMENTS: [
//     { value: 'MEMBER_DOCUMENT', label: 'Member document' },
//     {
//       value: 'MEMBER_ADDITIONAL_DOCUMENT_REQUIRED',
//       label: 'Member additional document required'
//     },
//     // { value: 'MEMBER_DOCUMENT_OTHER', label: 'Member document other' },
//     { value: 'OTHERS', label: 'Other' }
//   ],
//   TRAVELER_INFO: [{ value: 'TRAVELER_PERSONAL_INFO', label: 'Traveller personal info' }],
//   TRAVELER_DOCUMENTS: [
//     { value: 'TRAVELER_DOCUMENT', label: 'Traveller document' },
//     {
//       value: 'TRAVELER_ADDITIONAL_DOCUMENT_REQUIRED',
//       label: 'Traveller additional document required'
//     }
//   ],
//   TRAVELER_RELATED_TASKS: [
//     { value: 'TRAVELER_PERSONAL_INFO', label: 'Traveller personal info' },
//     { value: 'TRAVELER_DOCUMENT', label: 'Traveller document' },
//     {
//       value: 'TRAVELER_ADDITIONAL_DOCUMENT_REQUIRED',
//       label: 'Traveller additional document required'
//     }
//   ],
//   SUPPLIER_RELATED_TASKS: [
//     { value: 'TRIP_DURATION', label: 'Travel duration' },
//     { value: 'VISA_SELECTED', label: 'Visa selected' },
//     { value: 'MEMBER_PERSONAL_INFO', label: 'Member personal info' },
//     { value: 'MEMBER_DOCUMENT', label: 'Member document' },
//     {
//       value: 'MEMBER_ADDITIONAL_DOCUMENT_REQUIRED',
//       label: 'Member additional document required'
//     },
//     { value: 'OTHERS', label: 'Other' }
//   ],
//   ALL: [
//     { value: 'TRIP_DURATION', label: 'Travel duration' },
//     { value: 'VISA_SELECTED', label: 'Visa selected' },
//     { value: 'MEMBER_PERSONAL_INFO', label: 'Member personal info' },
//     { value: 'MEMBER_DOCUMENT', label: 'Member document' },
//     {
//       value: 'MEMBER_ADDITIONAL_DOCUMENT_REQUIRED',
//       label: 'Member additional document required'
//     },
//     { value: 'OTHERS', label: 'Other' },
//     { value: 'TRAVELER_PERSONAL_INFO', label: 'Traveller personal info' },
//     { value: 'TRAVELER_DOCUMENT', label: 'Traveller document' },
//     {
//       value: 'TRAVELER_ADDITIONAL_DOCUMENT_REQUIRED',
//       label: 'Traveller additional document required'
//     }
//   ]
// };

export const ACTION_STATUS_MAP = {
  IN_REVIEW: 'Pending Review', //
  CHANGES_REQUESTED: 'Changes Requested',
  COMPLETED: 'Needs Review', // once consumer make the changes requested by supplier
  APPROVED: 'Approved', // once action approved by supplier
  DELETED: 'Deleted'
};

export const InsuranceTypeOptions: Array<ILabelValuePair> = [
  { value: 'CFAR_FOR_HOTEL', label: 'CFAR For Hotel' },
  { value: 'STANDARD_TRAVEL_INSURANCE', label: 'Standard Travel Insurance' },
  { value: 'BUS_INSURANCE', label: 'Bus Insurance' },
  { value: 'CAB_INSURANCE', label: 'Cab Insurance' }
];

export const TravellerFormFields: Array<ILabelValuePairWithType> = [
  { value: 'title', label: 'title', type: 'dropdown' },
  { value: 'firstName', label: 'firstName', type: 'string' },
  { value: 'lastName', label: 'lastName', type: 'string' },
  { value: 'gender', label: 'gender', type: 'dropdown' },
  { value: 'dateOfBirth', label: 'dateOfBirth', type: 'date' },
  { value: 'phone', label: 'phone', type: 'phone' },
  { value: 'email', label: 'email', type: 'email' },
  { value: 'line1', label: 'line1', type: 'string' },
  { value: 'state', label: 'state', type: 'dropdown' },
  { value: 'country', label: 'country', type: 'dropdown' },
  { value: 'postalCode', label: 'postalCode', type: 'string' },
  { value: 'nationality', label: 'nationality', type: 'dropdown' },
  { value: 'occupation', label: 'occupation', type: 'dropdown' },
  { value: 'passport.number', label: 'passportNumber', type: 'string' },
  { value: 'passport.issuingDate', label: 'passportIssuingDate', type: 'date' },
  { value: 'passport.expiration', label: 'passportExpiration', type: 'date' },
  { value: 'passport.issuingCountry', label: 'passportIssuingCountry', type: 'dropdown' },
  { value: 'passport.issuingPlace', label: 'passportIssuingPlace', type: 'string' },
  { value: 'passport.count', label: 'oldPassportCount', type: 'number' }
];

export const TransactionTypeOptions: Array<ILabelValuePair> = [
  { value: 'CREDIT', label: 'Credit' },
  { value: 'DEBIT', label: 'Debit' }
];

export const FLIGHT_NAMES = [
  {
    label: 'Indigo',
    value: '6E'
  },
  {
    label: 'AirIndiaExpress',
    value: 'IX'
  },
  {
    label: 'AkasaAir',
    value: 'QP'
  },
  {
    label: 'AirIndia',
    value: 'AI'
  },
  {
    label: 'SpiceJet',
    value: 'SG'
  },
  {
    label: 'Vistara',
    value: 'UK'
  },
  {
    label: 'AIX Connect',
    value: 'I5'
  },
  {
    label: 'Cathay Pacific Airways Ltd',
    value: 'CX'
  },
  {
    label: 'Etihad Airways',
    value: 'EY'
  }
];

export const orderFilterNavs = [
  'ORDER_QUOTE',
  'ORDER_DRAFT',
  'PENDING_ACTION',
  'ORDER_SUBMIT',
  'COMPLETED',
  'ORDER_CANCELLATION_REQUESTED',
  'BOOKING_CANCELLED',
  'ORDER_ALL'
];

export const flightFilterNavs = ['CONFIRMED', 'FAILED', 'CANCELLED', 'ORDER_ALL', 'ITINERARY'];
