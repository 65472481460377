import { Box } from '@mui/material';
import classNames from 'classnames';

interface IDefaultCell {
  data: any;
  isEllipsis?: boolean;
  textCapitalize?: boolean;
  className?: string;
}

const DefaultCellWithicon = (props: IDefaultCell) => {
  const { data, isEllipsis = false, textCapitalize = true, className = '' } = props;

  const draftClassNames = classNames(className, 'fs-6', {
    'text-capitalize': textCapitalize,
    'oa-table-cell-ellipsis': isEllipsis
  });

  return (
    <Box display='flex'>
      <img
        width={20}
        height={20}
        src={`https://oa-flight-assets.s3.ap-south-1.amazonaws.com/airline-logos/${data?.code}.svg`}
        alt='Indigo'
        loading='lazy'
        style={{ borderRadius: '50%', marginRight: '8px' }}
      />
      <div className={draftClassNames} title={data ? data.toString() : ''}>
        {data?.name}
      </div>
    </Box>
  );
};

export default DefaultCellWithicon;
