import { Box, Divider, Grid } from '@mui/material';
import classNames from 'classnames';
import { OATypography } from '../../../components/widgets';
import { theme } from '../../../config/theme';
import { getFormattedDate } from '../../../helper';
import { ReactComponent as SourceDestIcon } from '../../../assets/icons/source-DestArrow.svg';

export const FareRules = ({ tabs, setTabs, data }: any) => {
  const handleTabs = (navHeader: string) => {
    if (navHeader === 'customer') {
      setTabs({
        cancellation: true,
        dateChange: false
      });
    }

    if (navHeader === 'cleartrip') {
      setTabs({
        cancellation: false,
        dateChange: true
      });
    }
  };

  let cancellationActive = classNames('nav-link justify-content-center text-active-gray-800', {
    active: tabs?.cancellation
  });

  let dateChangeActive = classNames('nav-link justify-content-center text-active-gray-800', {
    active: tabs?.dateChange
  });

  let onward = data?.flights?.find((onward: any) => onward?.departureType === 'ONWARD');

  let returnData = data?.flights?.find((obj: any) => obj?.departureType === 'RETURN');
  let returnArrival = returnData?.segments?.find(
    (segment: any) => segment?.segmentId === onward?.segments?.length + 1
  );

  let adultsFare = tabs?.cancellation
    ? onward?.minirule?.cancellationFareRule?.filter(
        (obj: any) => (obj?.travellerType).toLowerCase() === 'adult'
      )
    : onward?.minirule?.amendmentFareRule?.filter(
        (obj: any) =>
          (obj?.travellerType).toLowerCase() === 'adult' && obj?.type === 'AMEND_SAME_FARE'
      );

  let childFare = tabs?.cancellation
    ? onward?.minirule?.cancellationFareRule?.filter(
        (obj: any) => (obj?.travellerType).toLowerCase() === 'child'
      )
    : onward?.minirule?.amendmentFareRule?.filter(
        (obj: any) =>
          (obj?.travellerType).toLowerCase() === 'child' && obj?.type === 'AMEND_SAME_FARE'
      );

  let infantFare = tabs?.cancellation
    ? onward?.minirule?.cancellationFareRule?.filter(
        (obj: any) => (obj?.travellerType).toLowerCase() === 'infant'
      )
    : onward?.minirule?.amendmentFareRule?.filter(
        (obj: any) =>
          (obj?.travellerType).toLowerCase() === 'infant' && obj?.type === 'AMEND_SAME_FARE'
      );

  let returnAdultsFare = tabs?.cancellation
    ? returnData?.minirule?.cancellationFareRule?.filter(
        (obj: any) => (obj?.travellerType).toLowerCase() === 'adult'
      )
    : returnData?.minirule?.amendmentFareRule?.filter(
        (obj: any) =>
          (obj?.travellerType).toLowerCase() === 'adult' && obj?.type === 'AMEND_SAME_FARE'
      );

  let returnChildFare = tabs?.cancellation
    ? returnData?.minirule?.cancellationFareRule?.filter(
        (obj: any) => (obj?.travellerType).toLowerCase() === 'child'
      )
    : returnData?.minirule?.amendmentFareRule?.filter(
        (obj: any) =>
          (obj?.travellerType).toLowerCase() === 'child' && obj?.type === 'AMEND_SAME_FARE'
      );

  let returnInfantFare = tabs?.cancellation
    ? returnData?.minirule?.cancellationFareRule?.filter(
        (obj: any) => (obj?.travellerType).toLowerCase() === 'infant'
      )
    : returnData?.minirule?.amendmentFareRule?.filter(
        (obj: any) =>
          (obj?.travellerType).toLowerCase() === 'infant' && obj?.type === 'AMEND_SAME_FARE'
      );

  return (
    <div className='order-view--activity card pb-6'>
      <Box display='flex' justifyContent='space-between' alignItems='center' padding='16px'>
        <h3 className='card-title fw-bolder text-dark ps-4'>Fare Rules</h3>
      </Box>
      <div className='card-toolbar m-0 order-search'>
        <ul className='nav nav-stretch nav-line-tabs border-transparent' role='tablist'>
          <Box display='flex' paddingLeft='24px'>
            <li className='nav-item' role='presentation'>
              <a className={cancellationActive} onClick={() => handleTabs('customer')}>
                <span className='me-1 fw-bold fs-7'>Cancellation</span>
              </a>
            </li>
            <li className='nav-item' role='presentation'>
              <a className={dateChangeActive} onClick={() => handleTabs('cleartrip')}>
                <span className='me-1 fw-bold fs-7'>Date Change</span>
              </a>
            </li>
          </Box>
        </ul>
      </div>
      <Divider />
      {tabs?.cancellation && (
        <Box padding='24px'>
          <OATypography variant='h4' sx={{ fontWeight: 500 }}>
            Cancellation policy
          </OATypography>
          {onward?.minirule?.cancellationFareRule?.length > 0 && (
            <Box display='flex' alignItems='center' gap='8px' marginTop='24px'>
              <OATypography variant='h6'>
                {onward?.segments?.[0]?.departure?.airport?.city}
              </OATypography>
              <SourceDestIcon />
              <OATypography variant='h6'>
                {onward?.segments?.[onward?.segments?.length - 1]?.arrival?.airport?.city}
              </OATypography>
              <OATypography variant='body2' color='textSecondary'>
                {getFormattedDate(onward?.segments?.[0]?.departure?.date, 'ddd, DD MMM, YYYY')}
              </OATypography>
            </Box>
          )}
          <Box sx={{ marginTop: '20px' }}>
            <Grid container spacing={2}>
              {adultsFare?.length > 0 && (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Box
                    sx={{
                      border: '1px #EFF2F5 solid',
                      boxSizing:
                        'border-box' /* Ensure that padding doesn't add to the total width */
                    }}>
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      padding='10px'>
                      <OATypography
                        variant='subtitle2'
                        sx={{
                          color: theme.palette.grey[400],
                          fontSize: '12px',
                          lineHeight: '16px'
                        }}>
                        CANCEL BETWEEN
                      </OATypography>
                      <OATypography
                        variant='subtitle2'
                        sx={{
                          color: theme.palette.grey[400],
                          fontSize: '12px',
                          lineHeight: '16px'
                        }}>
                        {`CHARGES (ADULT)`}
                      </OATypography>
                    </Box>
                    <Divider />
                    {adultsFare?.map((obj: any, index: number) => (
                      <>
                        <Box
                          display='flex'
                          justifyContent='space-between'
                          alignItems='center'
                          padding='10px'>
                          <OATypography variant='body2'>
                            {`${getFormattedDate(obj?.start, 'DD MMM, HH:mm')} - ${getFormattedDate(
                              obj?.end,
                              'DD MMM, HH:mm'
                            )}`}
                          </OATypography>
                          <OATypography variant='body2'>{`${
                            obj?.currency
                          } ${obj?.totalCharges.toFixed(2)}`}</OATypography>
                        </Box>
                        {index !== adultsFare?.length - 1 && <Divider />}
                      </>
                    ))}
                  </Box>
                </Grid>
              )}

              {childFare?.length > 0 && (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Box
                    sx={{
                      border: '1px #EFF2F5 solid',
                      boxSizing:
                        'border-box' /* Ensure that padding doesn't add to the total width */
                    }}>
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      padding='10px'>
                      <OATypography
                        variant='subtitle2'
                        sx={{
                          color: theme.palette.grey[400],
                          fontSize: '12px',
                          lineHeight: '16px'
                        }}>
                        CANCEL BETWEEN
                      </OATypography>
                      <OATypography
                        variant='subtitle2'
                        sx={{
                          color: theme.palette.grey[400],
                          fontSize: '12px',
                          lineHeight: '16px'
                        }}>
                        {`CHARGES (CHILD)`}
                      </OATypography>
                    </Box>
                    <Divider />
                    {childFare?.map((obj: any, index: number) => (
                      <>
                        <Box
                          display='flex'
                          justifyContent='space-between'
                          alignItems='center'
                          padding='10px'>
                          <OATypography variant='body2'>
                            {`${getFormattedDate(obj?.start, 'DD MMM, HH:mm')} - ${getFormattedDate(
                              obj?.end,
                              'DD MMM, HH:mm'
                            )}`}{' '}
                          </OATypography>
                          <OATypography variant='body2'>{`${
                            obj?.currency
                          } ${obj?.totalCharges.toFixed(2)}`}</OATypography>
                        </Box>
                        {index !== childFare?.length - 1 && <Divider />}
                      </>
                    ))}
                  </Box>
                </Grid>
              )}

              {infantFare?.length > 0 && (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Box
                    sx={{
                      border: '1px #EFF2F5 solid',
                      boxSizing:
                        'border-box' /* Ensure that padding doesn't add to the total width */
                    }}>
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      padding='10px'>
                      <OATypography
                        variant='subtitle2'
                        sx={{
                          color: theme.palette.grey[400],
                          fontSize: '12px',
                          lineHeight: '16px'
                        }}>
                        CANCEL BETWEEN
                      </OATypography>
                      <OATypography
                        variant='subtitle2'
                        sx={{
                          color: theme.palette.grey[400],
                          fontSize: '12px',
                          lineHeight: '16px'
                        }}>
                        {`CHARGES (INFANT)`}
                      </OATypography>
                    </Box>
                    <Divider />
                    {infantFare?.map((obj: any, index: number) => (
                      <>
                        <Box
                          display='flex'
                          justifyContent='space-between'
                          alignItems='center'
                          padding='10px'>
                          <OATypography variant='body2'>
                            {`${getFormattedDate(obj?.start, 'DD MMM, HH:mm')} - ${getFormattedDate(
                              obj?.end,
                              'DD MMM, HH:mm'
                            )}`}{' '}
                          </OATypography>
                          <OATypography variant='body2'>{`${
                            obj?.currency
                          } ${(obj?.totalCharges).toFixed(2)}`}</OATypography>
                        </Box>
                        {index !== infantFare?.length - 1 && <Divider />}
                      </>
                    ))}
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
          {returnData?.minirule?.cancellationFareRule?.length > 0 && (
            <Box display='flex' alignItems='center' gap='8px' marginTop='24px'>
              <OATypography variant='h6'>{returnArrival?.departure?.airport?.city}</OATypography>
              <SourceDestIcon />
              <OATypography variant='h6'>
                {returnData?.segments?.[returnData?.segments?.length - 1]?.arrival?.airport?.city}
              </OATypography>
              <OATypography variant='body2' color='textSecondary'>
                {getFormattedDate(returnArrival?.departure?.date, 'ddd, DD MMM, YYYY')}
              </OATypography>
            </Box>
          )}
          <Box sx={{ marginTop: '20px' }}>
            <Grid container spacing={2}>
              {returnAdultsFare?.length > 0 && (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Box
                    sx={{
                      border: '1px #EFF2F5 solid',
                      boxSizing:
                        'border-box' /* Ensure that padding doesn't add to the total width */
                    }}>
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      padding='10px'>
                      <OATypography
                        variant='subtitle2'
                        sx={{
                          color: theme.palette.grey[400],
                          fontSize: '12px',
                          lineHeight: '16px'
                        }}>
                        CANCEL BETWEEN
                      </OATypography>
                      <OATypography
                        variant='subtitle2'
                        sx={{
                          color: theme.palette.grey[400],
                          fontSize: '12px',
                          lineHeight: '16px'
                        }}>
                        {`CHARGES (ADULT)`}
                      </OATypography>
                    </Box>
                    <Divider />
                    {returnAdultsFare?.map((obj: any, index: number) => (
                      <>
                        <Box
                          display='flex'
                          justifyContent='space-between'
                          alignItems='center'
                          padding='10px'>
                          <OATypography variant='body2'>
                            {`${getFormattedDate(obj?.start, 'DD MMM, HH:mm')} - ${getFormattedDate(
                              obj?.end,
                              'DD MMM, HH:mm'
                            )}`}
                          </OATypography>
                          <OATypography variant='body2'>{`${
                            obj?.currency
                          } ${obj?.totalCharges.toFixed(2)}`}</OATypography>
                        </Box>
                        {index !== returnAdultsFare?.length - 1 && <Divider />}
                      </>
                    ))}
                  </Box>
                </Grid>
              )}

              {returnChildFare?.length > 0 && (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Box
                    sx={{
                      border: '1px #EFF2F5 solid',
                      boxSizing:
                        'border-box' /* Ensure that padding doesn't add to the total width */
                    }}>
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      padding='10px'>
                      <OATypography
                        variant='subtitle2'
                        sx={{
                          color: theme.palette.grey[400],
                          fontSize: '12px',
                          lineHeight: '16px'
                        }}>
                        CANCEL BETWEEN
                      </OATypography>
                      <OATypography
                        variant='subtitle2'
                        sx={{
                          color: theme.palette.grey[400],
                          fontSize: '12px',
                          lineHeight: '16px'
                        }}>
                        {`CHARGES (CHILD)`}
                      </OATypography>
                    </Box>
                    <Divider />
                    {returnChildFare?.map((obj: any, index: number) => (
                      <>
                        <Box
                          display='flex'
                          justifyContent='space-between'
                          alignItems='center'
                          padding='10px'>
                          <OATypography variant='body2'>
                            {`${getFormattedDate(obj?.start, 'DD MMM, HH:mm')} - ${getFormattedDate(
                              obj?.end,
                              'DD MMM, HH:mm'
                            )}`}{' '}
                          </OATypography>
                          <OATypography variant='body2'>{`${
                            obj?.currency
                          } ${obj?.totalCharges.toFixed(2)}`}</OATypography>
                        </Box>
                        {index !== returnChildFare?.length - 1 && <Divider />}
                      </>
                    ))}
                  </Box>
                </Grid>
              )}

              {returnInfantFare?.length > 0 && (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Box
                    sx={{
                      border: '1px #EFF2F5 solid',
                      boxSizing:
                        'border-box' /* Ensure that padding doesn't add to the total width */
                    }}>
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      padding='10px'>
                      <OATypography
                        variant='subtitle2'
                        sx={{
                          color: theme.palette.grey[400],
                          fontSize: '12px',
                          lineHeight: '16px'
                        }}>
                        CANCEL BETWEEN
                      </OATypography>
                      <OATypography
                        variant='subtitle2'
                        sx={{
                          color: theme.palette.grey[400],
                          fontSize: '12px',
                          lineHeight: '16px'
                        }}>
                        {`CHARGES (INFANT)`}
                      </OATypography>
                    </Box>
                    <Divider />
                    {returnInfantFare?.map((obj: any, index: number) => (
                      <>
                        <Box
                          display='flex'
                          justifyContent='space-between'
                          alignItems='center'
                          padding='10px'>
                          <OATypography variant='body2'>
                            {`${getFormattedDate(obj?.start, 'DD MMM, HH:mm')} - ${getFormattedDate(
                              obj?.end,
                              'DD MMM, HH:mm'
                            )}`}{' '}
                          </OATypography>
                          <OATypography variant='body2'>{`${
                            obj?.currency
                          } ${(obj?.totalCharges).toFixed(2)}`}</OATypography>
                        </Box>
                        {index !== returnInfantFare?.length - 1 && <Divider />}
                      </>
                    ))}
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
          <OATypography variant='h4' sx={{ fontWeight: 500, marginTop: '36px' }}>
            Terms & conditions
          </OATypography>
          <OATypography variant='body1' sx={{ marginTop: '16px', lineHeight: '17px' }}>
            All about flight cancellation and fees
          </OATypography>
          <ul
            style={{
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '22px',
              marginTop: '4px',
              color: '#616876'
            }}>
            <li>First, the total price displayed includes all applicable taxes.</li>
            <li>
              The taxes are only indicative in nature since the airline's policies are subject to
              change.
            </li>
          </ul>
          <OATypography variant='body1' sx={{ marginTop: '16px', lineHeight: '17px' }}>
            All about cancellation
          </OATypography>
          <ul
            style={{
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '22px',
              marginTop: '4px',
              color: '#616876'
            }}>
            {onward?.minirule?.cancelTermsAndConditions?.map((obj: any, index: number) => (
              <li> {obj} </li>
            ))}
          </ul>
        </Box>
      )}
      {tabs?.dateChange && (
        <Box padding='24px'>
          <OATypography variant='h4' sx={{ fontWeight: 500 }}>
            Date Change policy
          </OATypography>
          {onward?.minirule?.amendmentFareRule?.length > 0 && (
            <Box display='flex' alignItems='center' gap='8px' marginTop='24px'>
              <OATypography variant='h6'>
                {onward?.segments?.[0]?.departure?.airport?.city}
              </OATypography>
              <SourceDestIcon />
              <OATypography variant='h6'>
                {onward?.segments?.[onward?.segments?.length - 1]?.arrival?.airport?.city}
              </OATypography>
              <OATypography variant='body2' color='textSecondary'>
                {getFormattedDate(onward?.segments?.[0]?.departure?.date, 'ddd, DD MMM, YYYY')}
              </OATypography>
            </Box>
          )}
          <Box sx={{ marginTop: '20px' }}>
            <Grid container spacing={2}>
              {adultsFare?.length > 0 && (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Box
                    sx={{
                      border: '1px #EFF2F5 solid',
                      boxSizing:
                        'border-box' /* Ensure that padding doesn't add to the total width */
                    }}>
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      padding='10px'>
                      <OATypography
                        variant='subtitle2'
                        sx={{
                          color: theme.palette.grey[400],
                          fontSize: '12px',
                          lineHeight: '16px'
                        }}>
                        CANCEL BETWEEN
                      </OATypography>
                      <OATypography
                        variant='subtitle2'
                        sx={{
                          color: theme.palette.grey[400],
                          fontSize: '12px',
                          lineHeight: '16px'
                        }}>
                        {`CHARGES (ADULT)`}
                      </OATypography>
                    </Box>
                    <Divider />
                    {adultsFare?.map((obj: any, index: number) => (
                      <>
                        <Box
                          display='flex'
                          justifyContent='space-between'
                          alignItems='center'
                          padding='10px'>
                          <OATypography variant='body2'>
                            {`${getFormattedDate(obj?.start, 'DD MMM, HH:mm')} - ${getFormattedDate(
                              obj?.end,
                              'DD MMM, HH:mm'
                            )}`}
                          </OATypography>
                          <OATypography variant='body2'>{`${
                            obj?.currency
                          } ${obj?.totalCharges.toFixed(2)}`}</OATypography>
                        </Box>
                        {index !== adultsFare?.length - 1 && <Divider />}
                      </>
                    ))}
                  </Box>
                </Grid>
              )}

              {childFare?.length > 0 && (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Box
                    sx={{
                      border: '1px #EFF2F5 solid',
                      boxSizing:
                        'border-box' /* Ensure that padding doesn't add to the total width */
                    }}>
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      padding='10px'>
                      <OATypography
                        variant='subtitle2'
                        sx={{
                          color: theme.palette.grey[400],
                          fontSize: '12px',
                          lineHeight: '16px'
                        }}>
                        CANCEL BETWEEN
                      </OATypography>
                      <OATypography
                        variant='subtitle2'
                        sx={{
                          color: theme.palette.grey[400],
                          fontSize: '12px',
                          lineHeight: '16px'
                        }}>
                        {`CHARGES (CHILD)`}
                      </OATypography>
                    </Box>
                    <Divider />
                    {childFare?.map((obj: any, index: number) => (
                      <>
                        <Box
                          display='flex'
                          justifyContent='space-between'
                          alignItems='center'
                          padding='10px'>
                          <OATypography variant='body2'>
                            {`${getFormattedDate(obj?.start, 'DD MMM, HH:mm')} - ${getFormattedDate(
                              obj?.end,
                              'DD MMM, HH:mm'
                            )}`}{' '}
                          </OATypography>
                          <OATypography variant='body2'>{`${
                            obj?.currency
                          } ${obj?.totalCharges.toFixed(2)}`}</OATypography>
                        </Box>
                        {index !== childFare?.length - 1 && <Divider />}
                      </>
                    ))}
                  </Box>
                </Grid>
              )}

              {infantFare?.length > 0 && (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Box
                    sx={{
                      border: '1px #EFF2F5 solid',
                      boxSizing:
                        'border-box' /* Ensure that padding doesn't add to the total width */
                    }}>
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      padding='10px'>
                      <OATypography
                        variant='subtitle2'
                        sx={{
                          color: theme.palette.grey[400],
                          fontSize: '12px',
                          lineHeight: '16px'
                        }}>
                        CANCEL BETWEEN
                      </OATypography>
                      <OATypography
                        variant='subtitle2'
                        sx={{
                          color: theme.palette.grey[400],
                          fontSize: '12px',
                          lineHeight: '16px'
                        }}>
                        {`CHARGES (INFANT)`}
                      </OATypography>
                    </Box>
                    <Divider />
                    {infantFare?.map((obj: any, index: number) => (
                      <>
                        <Box
                          display='flex'
                          justifyContent='space-between'
                          alignItems='center'
                          padding='10px'>
                          <OATypography variant='body2'>
                            {`${getFormattedDate(obj?.start, 'DD MMM, HH:mm')} - ${getFormattedDate(
                              obj?.end,
                              'DD MMM, HH:mm'
                            )}`}{' '}
                          </OATypography>
                          <OATypography variant='body2'>{`${
                            obj?.currency
                          } ${(obj?.totalCharges).toFixed(2)}`}</OATypography>
                        </Box>
                        {index !== infantFare?.length - 1 && <Divider />}
                      </>
                    ))}
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
          {returnData?.minirule?.amendmentFareRule?.length > 0 && (
            <Box display='flex' alignItems='center' gap='8px' marginTop='24px'>
              <OATypography variant='h6'>{returnArrival?.departure?.airport?.city}</OATypography>
              <SourceDestIcon />
              <OATypography variant='h6'>
                {returnData?.segments?.[returnData?.segments?.length - 1]?.arrival?.airport?.city}
              </OATypography>
              <OATypography variant='body2' color='textSecondary'>
                {getFormattedDate(returnArrival?.departure?.date, 'ddd, DD MMM, YYYY')}
              </OATypography>
            </Box>
          )}
          <Box sx={{ marginTop: '20px' }}>
            <Grid container spacing={2}>
              {returnAdultsFare?.length > 0 && (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Box
                    sx={{
                      border: '1px #EFF2F5 solid',
                      boxSizing:
                        'border-box' /* Ensure that padding doesn't add to the total width */
                    }}>
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      padding='10px'>
                      <OATypography
                        variant='subtitle2'
                        sx={{
                          color: theme.palette.grey[400],
                          fontSize: '12px',
                          lineHeight: '16px'
                        }}>
                        CANCEL BETWEEN
                      </OATypography>
                      <OATypography
                        variant='subtitle2'
                        sx={{
                          color: theme.palette.grey[400],
                          fontSize: '12px',
                          lineHeight: '16px'
                        }}>
                        {`CHARGES (ADULT)`}
                      </OATypography>
                    </Box>
                    <Divider />
                    {returnAdultsFare?.map((obj: any, index: number) => (
                      <>
                        <Box
                          display='flex'
                          justifyContent='space-between'
                          alignItems='center'
                          padding='10px'>
                          <OATypography variant='body2'>
                            {`${getFormattedDate(obj?.start, 'DD MMM, HH:mm')} - ${getFormattedDate(
                              obj?.end,
                              'DD MMM, HH:mm'
                            )}`}
                          </OATypography>
                          <OATypography variant='body2'>{`${
                            obj?.currency
                          } ${obj?.totalCharges.toFixed(2)}`}</OATypography>
                        </Box>
                        {index !== returnAdultsFare?.length - 1 && <Divider />}
                      </>
                    ))}
                  </Box>
                </Grid>
              )}

              {returnChildFare?.length > 0 && (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Box
                    sx={{
                      border: '1px #EFF2F5 solid',
                      boxSizing:
                        'border-box' /* Ensure that padding doesn't add to the total width */
                    }}>
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      padding='10px'>
                      <OATypography
                        variant='subtitle2'
                        sx={{
                          color: theme.palette.grey[400],
                          fontSize: '12px',
                          lineHeight: '16px'
                        }}>
                        CANCEL BETWEEN
                      </OATypography>
                      <OATypography
                        variant='subtitle2'
                        sx={{
                          color: theme.palette.grey[400],
                          fontSize: '12px',
                          lineHeight: '16px'
                        }}>
                        {`CHARGES (CHILD)`}
                      </OATypography>
                    </Box>
                    <Divider />
                    {returnChildFare?.map((obj: any, index: number) => (
                      <>
                        <Box
                          display='flex'
                          justifyContent='space-between'
                          alignItems='center'
                          padding='10px'>
                          <OATypography variant='body2'>
                            {`${getFormattedDate(obj?.start, 'DD MMM, HH:mm')} - ${getFormattedDate(
                              obj?.end,
                              'DD MMM, HH:mm'
                            )}`}{' '}
                          </OATypography>
                          <OATypography variant='body2'>{`${
                            obj?.currency
                          } ${obj?.totalCharges.toFixed(2)}`}</OATypography>
                        </Box>
                        {index !== returnChildFare?.length - 1 && <Divider />}
                      </>
                    ))}
                  </Box>
                </Grid>
              )}

              {returnInfantFare?.length > 0 && (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Box
                    sx={{
                      border: '1px #EFF2F5 solid',
                      boxSizing:
                        'border-box' /* Ensure that padding doesn't add to the total width */
                    }}>
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      padding='10px'>
                      <OATypography
                        variant='subtitle2'
                        sx={{
                          color: theme.palette.grey[400],
                          fontSize: '12px',
                          lineHeight: '16px'
                        }}>
                        CANCEL BETWEEN
                      </OATypography>
                      <OATypography
                        variant='subtitle2'
                        sx={{
                          color: theme.palette.grey[400],
                          fontSize: '12px',
                          lineHeight: '16px'
                        }}>
                        {`CHARGES (INFANT)`}
                      </OATypography>
                    </Box>
                    <Divider />
                    {returnInfantFare?.map((obj: any, index: number) => (
                      <>
                        <Box
                          display='flex'
                          justifyContent='space-between'
                          alignItems='center'
                          padding='10px'>
                          <OATypography variant='body2'>
                            {`${getFormattedDate(obj?.start, 'DD MMM, HH:mm')} - ${getFormattedDate(
                              obj?.end,
                              'DD MMM, HH:mm'
                            )}`}{' '}
                          </OATypography>
                          <OATypography variant='body2'>{`${
                            obj?.currency
                          } ${(obj?.totalCharges).toFixed(2)}`}</OATypography>
                        </Box>
                        {index !== returnInfantFare?.length - 1 && <Divider />}
                      </>
                    ))}
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
          <OATypography variant='h4' sx={{ fontWeight: 500, marginTop: '36px' }}>
            Terms & conditions
          </OATypography>
          <OATypography variant='body1' sx={{ marginTop: '16px', lineHeight: '17px' }}>
            All about Date Change
          </OATypography>
          <ul
            style={{
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '22px',
              marginTop: '4px',
              color: '#616876'
            }}>
            {onward?.minirule?.amendTermsAndConditions?.map((obj: any, index: number) => (
              <li> {obj} </li>
            ))}
          </ul>
        </Box>
      )}
    </div>
  );
};
