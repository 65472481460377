import { FC } from 'react';
import { Box, IconButton } from '@mui/material';
import DeleteSVG from '../../assets/icons/outline/delete.svg';
import EditSVG from '../../assets/icons/outline/edit.svg';
import ApproveSVG from '../../assets/icons/outline/check-circle.svg';
import { useAuth } from '../../auth';
import { ITask } from '../../types';
import { showDialog } from '../../helper';
import { useOrderContext } from '../../pages/order-management/context/OrderContext';

interface IActionBtn {
  task: ITask;
  editTaskHandler: (e: React.MouseEvent, task: ITask) => void;
}

const ActionBtn: FC<IActionBtn> = ({ task, editTaskHandler }) => {
  const { currentUser } = useAuth();
  const { orderData, approveOrCompleteOrderAction, deleteOrderAction } = useOrderContext();
  const { id: currentUserId } = currentUser?.data;
  const { id: taskId, createdByUser, status } = task;

  /**
   * NOTE: if logged in user's org id matches order's supplierOrganization id then
   * logged in user can be considered as a supplier and can perform all the supplier's
   * actions...
   */
  const hasSupplierActionAccess =
    currentUser?.data?.organizationId === orderData?.data?.supplierOrganization?.id;

  const isUserCreatedTask = createdByUser?.id === currentUserId;

  const deleteTaskHandler = (e: React.MouseEvent, taskId: string) => {
    e.stopPropagation();
    showDialog({
      title: 'Delete order action?',
      text: 'Are you sure you want to delete this action?',
      icon: 'warning'
    }).then(result => {
      if (result?.isConfirmed) {
        deleteOrderAction(taskId);
      }
    });
  };

  const taskCompletedHandler = (e: React.MouseEvent, taskId: string) => {
    e.stopPropagation();
    showDialog({
      title: 'Complete order task?',
      text: 'Are you sure you want to complete this task?',
      icon: `warning`
    }).then(result => {
      if (result?.isConfirmed) {
        approveOrCompleteOrderAction(taskId, 'COMPLETED');
      }
    });
  };

  const isCompletedTask = status === 'COMPLETED';

  return (
    <Box sx={{ background: '#FFFFFF', borderRadius: '4px' }}>
      {isUserCreatedTask && !isCompletedTask && (
        <IconButton
          title='Delete task'
          onClick={(e: React.MouseEvent) => deleteTaskHandler(e, taskId)}
          aria-label='delete'
          sx={{ 'p': '6px 6px', '&:hover': { backgroundColor: 'transparent' } }}>
          <img src={DeleteSVG} style={{ width: '18px' }} />
        </IconButton>
      )}
      {isUserCreatedTask && !isCompletedTask && (
        <IconButton
          title='Edit task'
          onClick={(e: React.MouseEvent) => editTaskHandler(e, task)}
          aria-label='edit'
          sx={{ 'p': '6px 6px', '&:hover': { backgroundColor: 'transparent' } }}>
          <img src={EditSVG} style={{ width: '18px' }} />
        </IconButton>
      )}
      {!isUserCreatedTask && !hasSupplierActionAccess && !isCompletedTask && (
        <IconButton
          title='Mark as complete'
          onClick={(e: React.MouseEvent) => taskCompletedHandler(e, taskId)}
          aria-label='completed'
          sx={{ 'p': '6px 6px', '&:hover': { backgroundColor: 'transparent' } }}>
          <img src={ApproveSVG} style={{ width: '18px' }} />
        </IconButton>
      )}
    </Box>
  );
};

export default ActionBtn;
