import { FC } from 'react';
import OAModalComponent from '../../OAModalComponent';
import TaskForm from './TaskForm';
import { ITask } from '../../../types';

interface ITaskCreateUpdate {
  show: boolean;
  onHide: () => void;
  editTaskData: ITask | null;
}

const TaskCreateUpdate: FC<ITaskCreateUpdate> = ({ show, onHide, editTaskData }) => {
  return (
    <OAModalComponent
      show={show}
      onHide={onHide}
      header={{
        className: 'p-4',
        title: `${editTaskData ? 'Edit' : 'Create'} task`,
        closeButton: true
      }}
      body={{
        className: 'p-4',
        content: <TaskForm onHide={onHide} editTaskData={editTaskData} />
      }}
    />
  );
};

export default TaskCreateUpdate;
