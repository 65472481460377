import Big from 'big.js';
import { isBoolean, isEmpty } from 'lodash';

interface IInvoiceRequestValues {
  orderId: string;
  remark?: string;
  totalAmount: string | number;
  subtotalAmount: string | number;
  data?: IInvoiceItem[];
}

interface IInvoiceItem {
  type: string;
  name: string;
  quantity: string | number;
  currency: string;
  amount: string | number;
  gst?: string;
  gstAmount?: string | number;
}

export interface IInvoiceRequestBody {
  orderId: string;
  remark?: string;
  totalAmount: string | number;
  subtotalAmount: string | number;
  invoiceAmounts?: Array<{
    type: string;
    name: string;
    quantity: string | number;
    currency: string;
    amount: string | number;
    tax?: {
      totalTax: string | number;
      totalAmountWithoutTax: string | number;
    };
  }>;
}

export const getInvoiceRequestBody = (
  values: IInvoiceRequestValues,
  type: 'proforma' | 'invoice'
) => {
  let draft: IInvoiceRequestBody = {
    orderId: values?.orderId,
    totalAmount: Big(values?.totalAmount).toFixed(2),
    subtotalAmount: Big(values?.subtotalAmount).toFixed(2),

    invoiceAmounts: values?.data
      ?.filter((obj: any) => !['SUBTOTAL', 'TOTAL'].includes(obj?.type))
      ?.map((obj: any) => {
        const draftItem: any = {
          type: obj?.type,
          quantity: obj?.quantity,
          currency: obj?.currency,
          amount: Big(obj?.amount).toFixed(2)
        };
        /**
         * NOTE: check if 'name' present in 'obj' then only add it in 'draftItem'..
         */
        if (obj?.name) draftItem['name'] = obj?.name;
        /**
         * NOTE: check if 'tax' present in 'obj' then only add it in 'draftItem'..
         */
        if (!isEmpty(obj?.tax)) draftItem['tax'] = obj?.tax;
        /**
         * NOTE: check if 'amountCollected' present in 'obj' then only add it in 'draftItem'..
         */
        if (isBoolean(obj?.amountCollected)) draftItem['amountCollected'] = obj?.amountCollected;

        if (obj?.gst === 'true') {
          draftItem['tax'] = {
            totalTax: Big(obj?.gstAmount).toFixed(2),
            totalAmountWithoutTax: Big(obj?.amount).minus(obj?.gstAmount).toFixed(2)
          };
        }
        if (isBoolean(obj?.isCollected)) draftItem['amountCollected'] = obj?.isCollected;

        return draftItem;
      })
  };
  /**
   * NOTE: 'remark' can only be added in invoice type ...
   */
  if (type === 'invoice') draft['remark'] = values?.remark;

  return draft;
};
