import { forwardRef, useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { OATypography } from '../../../../../../components/widgets';
import { getCalTableData, getModTableData } from './helper';
import { convertPriceToWords } from '../../../../../../helper/convertPriceToWords';
import { getAgeInYearsOrMonths, getFormattedDate } from '../../../../../../helper';
import { useOrgContext } from '../../../../../org-management/context/OrgContext';

const InvoicePdf = forwardRef(function InvoicePdf(props: { data: any }, ref) {
  const { organizations, fetchOrgs } = useOrgContext();

  const [modData, setModData] = useState<Array<any>>([]);
  const [calData, setCalData] = useState<Array<any>>([]);
  const [notes, setNotes] = useState<string>('');
  /**
   *
   */
  useEffect(() => {
    if (!organizations?.data) {
      fetchOrgs?.();
    }
  }, []);
  /**
   * NOTE: if props.data changes, then update modData..
   */
  useEffect(() => {
    if (props?.data) {
      let draftModData = getModTableData(props?.data, 'invoice');
      const draftCalData = getCalTableData(props?.data);
      const draftNotes = props?.data?.invoiceAmount?.remark ?? '';
      setNotes(draftNotes);
      setModData(draftModData);
      setCalData(draftCalData);
    }
  }, [props?.data]);

  const draftAdditionalFees = calData?.filter(item =>
    ['SUPPLIER_FEE', 'MARKUP_FEE'].includes(item?.type)
  );
  const draftArr = [...modData, ...draftAdditionalFees];
  const totalAmountObj = calData?.find(item => item?.type === 'TOTAL');
  const invoiceNum = `INV-${props?.data?.id?.split('VIS')[1]}`;
  const invoiceDate = getFormattedDate(props?.data?.createdAt, 'DD/MM/YYYY');
  const primaryMember = props?.data?.members?.find((obj: any) => obj?.primary);
  const getTotalApplicants = (members: any) => {
    let draft = {
      adult: 0,
      child: 0,
      infant: 0
    };
    members?.forEach((obj: any) => {
      const age = getAgeInYearsOrMonths(obj?.dateOfBirth).replace('years', '').trim();
      if (!age.includes('months') && parseInt(age) >= 18) {
        draft = { ...draft, adult: draft?.adult + 1 };
      } else if (!age.includes('months') && parseInt(age) >= 1) {
        draft = { ...draft, child: draft?.child + 1 };
      } else {
        draft = { ...draft, infant: draft?.infant + 1 };
      }
    });
    const adultCountStr =
      draft?.adult > 0 ? `${draft?.adult} Adult${draft?.adult > 1 ? 's' : ''}` : ``;
    const childCountStr =
      draft?.child > 0 ? `${draft?.child} Child${draft?.child > 1 ? 'ren' : ''}` : ``;
    const infantCountStr =
      draft?.infant > 0 ? `${draft?.infant} Infant${draft?.infant > 1 ? 's' : ''}` : ``;

    return [adultCountStr, childCountStr, infantCountStr]?.filter(Boolean)?.join(', ');
  };
  const orderDetails = {
    orderId: props?.data?.id,
    productName: props?.data?.product?.displayName,
    primaryTravellerName: `${primaryMember?.firstName} ${primaryMember?.lastName}`,
    totalAplicants: getTotalApplicants(props?.data?.members)
  };
  const createdByUserOrg = organizations?.data?.find(
    (obj: any) => obj?.id === props?.data?.createdByUser?.organizationId
  );
  const consumerDetails = {
    name: createdByUserOrg?.name,
    GSTIN: createdByUserOrg?.GSTIN,
    address: {
      line1: createdByUserOrg?.address?.line1,
      line2: createdByUserOrg?.address?.line2,
      city: `${createdByUserOrg?.address?.city} - ${createdByUserOrg?.address?.postalCode}`,
      state: createdByUserOrg?.address?.state
    }
  };

  const renderTd = (str: string) => (
    <OATypography variant='h6' color={theme => theme.palette.grey[400]}>
      {str}
    </OATypography>
  );

  return (
    <Box id='invoice_pdf' ref={ref} padding='4rem 3rem'>
      <Box display='flex' justifyContent='space-between'>
        <Box display='flex' flexDirection='column' justifyContent='space-between'>
          <Box>
            <img src='/media/logos/onarrival-logo.png' alt='logo' />
          </Box>
          <Box>
            <OATypography variant='h4'>Tax Invoice</OATypography>
          </Box>
        </Box>
        <Box>
          <OATypography variant='h6'>OnArrival Travel Pvt Ltd</OATypography>
          <OATypography variant='body2'>Clayworks Mini Forest, 3rd floor, Site No. 74</OATypography>
          <OATypography variant='body2'>
            Mass Complex 15 Cross road, JP Nagar, 3rd Phase
          </OATypography>
          <OATypography variant='body2'>Bangalore, Karnataka-560078</OATypography>
          <OATypography variant='body2'>India</OATypography>
          <OATypography variant='body2'>GSTIN 29AADCO7212L1Z6</OATypography>
        </Box>
      </Box>
      <Box className='separator' marginY='1rem' />
      <Box display='grid' gridTemplateColumns='repeat(2, 1fr)' gap='0.5rem'>
        <Box display='grid' gridTemplateColumns='200px 1fr'>
          <OATypography variant='body2'>Invoice number</OATypography>
          <OATypography variant='body2'>: {invoiceNum}</OATypography>
        </Box>
        <Box display='grid' gridTemplateColumns='200px 1fr'>
          <OATypography variant='body2'>Place of supply</OATypography>
          <OATypography variant='body2'>: Maharashtra(27)</OATypography>
        </Box>
        <Box display='grid' gridTemplateColumns='200px 1fr'>
          <OATypography variant='body2'>Invoice date</OATypography>
          <OATypography variant='body2'>: {invoiceDate}</OATypography>
        </Box>
      </Box>
      <Box className='separator' marginY='1rem' />
      <Box display='grid' gridTemplateColumns='1fr 1fr'>
        <Box>
          <OATypography variant='body2'>Bill to</OATypography>
          <OATypography variant='h6'>{consumerDetails?.name}</OATypography>
          <OATypography variant='body2'>{consumerDetails?.address?.line1}</OATypography>
          <OATypography variant='body2'>{consumerDetails?.address?.line2}</OATypography>
          <OATypography variant='body2'>{consumerDetails?.address?.city}</OATypography>
          <OATypography variant='body2'>{consumerDetails?.address?.state}</OATypography>
          {consumerDetails?.GSTIN ? (
            <OATypography variant='body2'>GSTIN {consumerDetails?.GSTIN}</OATypography>
          ) : null}
        </Box>
        <Box>
          <Box display='flex' justifyContent='space-between'>
            <OATypography variant='body2'>OnArrival Order ID: </OATypography>
            <OATypography variant='body1'>{orderDetails?.orderId}</OATypography>
          </Box>
          <Box display='flex' justifyContent='space-between'>
            <OATypography variant='body2'>Product: </OATypography>
            <OATypography variant='body1'>{orderDetails?.productName}</OATypography>
          </Box>
          <Box display='flex' justifyContent='space-between'>
            <OATypography variant='body2'>Primary Applicant: </OATypography>
            <OATypography variant='body1'>{orderDetails?.primaryTravellerName}</OATypography>
          </Box>
          <Box display='flex' justifyContent='space-between'>
            <OATypography variant='body2'>Total Applicants: </OATypography>
            <OATypography variant='body1'>{orderDetails?.totalAplicants}</OATypography>
          </Box>
        </Box>
      </Box>
      <Box className='separator' marginY='1rem' />
      <Box className='table-responsive-xl'>
        <table className='invoice-pdf--table'>
          <thead>
            <tr>
              <th rowSpan={2} className='index'>
                {renderTd('#')}
              </th>
              <th rowSpan={2} className='name'>
                {renderTd('ITEM')}
              </th>
              <th rowSpan={2} className='hsn'>
                {renderTd('HSN/SAC')}
              </th>
              <th rowSpan={2} className='qty'>
                {renderTd('QTY')}
              </th>
              <th rowSpan={2} className='amount'>
                {renderTd('RATE')}
              </th>
              <th colSpan={2} className='igst'>
                {renderTd('IGST')}
              </th>
              <th rowSpan={2} className='amount'>
                {renderTd('AMOUNT')}
              </th>
            </tr>
            <tr>
              <th rowSpan={2}>{renderTd('%')}</th>
              <th rowSpan={2} className='amount'>
                {renderTd('AMT')}
              </th>
            </tr>
          </thead>
          <tbody>
            {draftArr?.map((item, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{item?.name}</td>
                <td>998659</td>
                <td>{item?.quantity}</td>
                <td className='amount'>{item?.price}</td>
                <td>{item?.gst === 'true' ? '18%' : ''}</td>
                <td className='amount'>{item?.gstAmount ?? ''}</td>
                <td className='amount'>{item?.amount}</td>
              </tr>
            ))}
            <tr>
              <td colSpan={5} rowSpan={3} className='bank-details'>
                <Box marginY='1rem'>
                  <OATypography variant='body2' color={theme => theme.palette.secondary.main}>
                    Total in words
                  </OATypography>
                  <OATypography variant='body1'>
                    {convertPriceToWords(totalAmountObj?.amount)}
                  </OATypography>
                </Box>
                <Box>
                  <OATypography variant='body2' color={theme => theme.palette.secondary.main}>
                    Bank Details
                  </OATypography>
                  <OATypography variant='body1'>
                    Bank Transfer: Onarrival Travel Technology Private Limited
                  </OATypography>
                  <OATypography variant='body1'>Account Type: Current</OATypography>
                  <OATypography variant='body1'>Account No: 040105006576</OATypography>
                  <OATypography variant='body1'>IFSC: ICIC0000401</OATypography>
                  <OATypography variant='body1'>
                    Branch: JP Nagar Branch, 75/A, 100 Feet Road 6th Phase,
                  </OATypography>
                  <OATypography variant='body1'>Bangalore - 560078</OATypography>
                </Box>
              </td>
              <td colSpan={2} className='total-amount'>
                <OATypography variant='h6'>Balance Due</OATypography>
              </td>
              <td className='total-amount'>
                <OATypography variant='h6'>{totalAmountObj?.amount}</OATypography>
              </td>
            </tr>
            <tr>
              <td colSpan={3} className='signature'>
                <OATypography variant='body2' color={theme => theme.palette.grey[400]}>
                  Authorised Signature
                </OATypography>
              </td>
            </tr>
            <tr>
              <td colSpan={3} className='border-0' />
            </tr>
          </tbody>
        </table>
      </Box>
      <Box
        margin='5rem 0 2.5rem'
        padding='1rem'
        borderRadius='6px'
        bgcolor={theme => theme?.palette?.secondary?.light}>
        <OATypography variant='caption' marginBottom='4px'>
          ADDITIONAL NOTES
        </OATypography>
        <OATypography variant='body2'>
          • Any additional service opted by applicant other than selected from us, to be paid
          directly at VAC/VFS
        </OATypography>
        {notes ? <OATypography variant='body2'>• {notes}</OATypography> : null}
      </Box>
      <Box display='flex' justifyContent='center'>
        <OATypography variant='body2'>
          This is a computer generated invoice and doesn’t need stamp & signature
        </OATypography>
      </Box>
    </Box>
  );
});

export default InvoicePdf;
