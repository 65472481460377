/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { Box } from '@mui/material';
import bgImg from '../../assets/images/visa_info_screen_signup_2.png';
import signupBgImg from '../../assets/images/signup_user_bg.svg';
import signupIconImg from '../../assets/images/signup_user_icon.svg';
import { OATypography } from '../../components/widgets';
import SignUpModal from './SignUpModal';
// import { Link } from 'react-router-dom';
import SignupVisa from '../../assets/images/signup_visa_info.svg';

const boxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400, // Adjust according to your design
  bgcolor: '#FDFDFE',
  p: '32px 16px 24px 16px',
  borderRadius: '8px',
  backgroundImage: `url(${signupBgImg})`,
  backgroundPosition: 'top center',
  backgroundRepeat: 'no-repeat'
};

const SignUp: FC = () => {
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        backgroundImage: `url(${bgImg})`,
        backgroundSize: '100% auto',
        backgroundPosition: 'top left',
        backgroundRepeat: 'no-repeat'
      }}>
      <Box sx={boxStyle}>
        <Box sx={{ textAlign: 'center' }}>
          <img src={signupIconImg} alt='user icon' />
          <OATypography variant='h3' sx={{ mt: '16px', mb: '4px' }}>
            Sign up for free
          </OATypography>
          <OATypography
            variant='subtitle1'
            sx={{ color: '#475467', mb: '24px', lineHeight: '20px' }}>
            Access in-depth information for over 150 countries, downloadable forms & other special
            visa information
          </OATypography>
          <img src={SignupVisa} alt='visa info' style={{ width: '100%', marginBottom: '24px' }} />
        </Box>
        <SignUpModal />
        {/* <OATypography
          variant='body2'
          sx={{
            color: theme => theme?.palette?.grey[400],
            mt: '20px',
            fontWeight: '400',
            textAlign: 'center'
          }}>
          By continuing, you agree to OnArrival’s <br />
          <Link to={'/privacy-policy'} target='_BLANK'>
            privacy policy |
          </Link>{' '}
          <Link to={'/terms-and-conditions'} target='_BLANK'>
            terms of use
          </Link>
        </OATypography> */}
      </Box>
    </Box>
  );
};

export default SignUp;
