import { FC } from 'react';
import { State } from 'country-state-city';
import { OATypography } from '../../widgets';

const HyperlinkSource = ({ text }: any) => {
  // Regular expression to match URLs
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // Find URLs in the text and replace them with <a> tags
  const textWithLinks = text?.replace(
    urlRegex,
    (url: any) => `<a href="${url}" target="_blank">${url}</a>`
  );

  return <div dangerouslySetInnerHTML={{ __html: textWithLinks }} />;
};

interface IEmbassyAndConsulate {
  consulate: any;
  visaInfo: any;
}

export const EmbassyAndConsulate: FC<IEmbassyAndConsulate> = ({ consulate, visaInfo }) => {
  return (
    <>
      <OATypography variant='body2' sx={{ mb: '4px', color: theme => theme?.palette?.grey?.[400] }}>
        {consulate?.consulateInfo?.name ?? visaInfo?.embassyInfo?.name}
      </OATypography>
      <OATypography variant='body2' sx={{ color: theme => theme?.palette?.grey?.[400] }}>
        <HyperlinkSource
          text={consulate?.consulateInfo?.description ?? visaInfo?.embassyInfo?.description}
        />
      </OATypography>

      {consulate?.allowedStates?.length > 0 && (
        <>
          <OATypography variant='body1' sx={{ mb: '4px', mt: '16px' }}>
            Jurisdiction Includes:
          </OATypography>
          <OATypography variant='body2' sx={{ color: theme => theme?.palette?.grey?.[400] }}>
            {consulate?.allowedStates?.map((item: any) => {
              let stateName = State?.getStateByCodeAndCountry(item, 'IN');
              return stateName?.name ? `${stateName?.name}, ` : null;
            })}
          </OATypography>
        </>
      )}
    </>
  );
};
