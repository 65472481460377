import { Skeleton, SkeletonProps, Typography, TypographyProps } from '@mui/material';

interface OATypographyProps extends TypographyProps {
  isLoading?: boolean;
  skeletonProps?: SkeletonProps;
  children: React.ReactNode;
  component?: React.ElementType;
}

export default function OATypography(props: OATypographyProps) {
  const {
    isLoading = false,
    skeletonProps = {},
    children,
    component = 'p',
    className = '',
    ...restProps
  } = props;
  const { variant = 'rounded', animation = 'wave', ...restSkeletonProps } = skeletonProps;

  return isLoading ? (
    <Skeleton
      variant={variant}
      animation={animation}
      className={className}
      {...restSkeletonProps}
    />
  ) : (
    <Typography component={component} className={className} {...restProps}>
      {children}
    </Typography>
  );
}
