import OAChip from '../../../../../components/Base/OAChip';

interface IDateHeader {
  date: string;
}

const DateHeader: React.FC<IDateHeader> = ({ date }) => {
  return (
    <div className='d-flex justify-content-center mb-8 font-bold'>
      <OAChip
        label={date}
        color='secondary'
        sx={{
          borderRadius: '60px',
          padding: '2px 8px',
          fontSize: '12px',
          lineHeight: '16px',
          fontWeight: 500
        }}
      />
    </div>
  );
};

export default DateHeader;
