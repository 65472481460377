import { FormikProps } from 'formik';
import { State } from 'country-state-city';

import { StateOptions } from '../../../constants';
import { renderFormFields } from './DynamicFormRenderer';
import {
  generateAdditionalPrimaryInfoFields,
  generateContactInfoFields,
  generatePassportFields,
  generatePrimaryInformationFields,
  generateTravelDetailsFields,
  generateTravelInfoFields,
  generateBookingInformationFields
} from './TravellerForm/formFields';

export const renderPrimaryInformation = (formState: any, options: any, isLoading: boolean) => {
  return renderFormFields(generatePrimaryInformationFields(options, isLoading), {
    required: formState?.travellerFormFields?.requiredFields,
    optional: formState?.travellerFormFields?.optionalFields
  });
};

export const renderAdditionalInformation = (formState: any, options: any, isLoading: boolean) => {
  return renderFormFields(generateAdditionalPrimaryInfoFields(options, isLoading), {
    required: formState?.travellerFormFields?.requiredFields,
    optional: formState?.travellerFormFields?.optionalFields
  });
};

export const renderTravelDetails = (formState: any, options: any, isLoading: boolean) => {
  return renderFormFields(generateTravelDetailsFields(options, isLoading), {
    required: formState?.travellerFormFields?.requiredFields,
    optional: formState?.travellerFormFields?.optionalFields
  });
};

export const renderContactInformation = (formState: any) => {
  let stateData: any;
  let states =
    formState?.tripDetails?.origin?.[0]?.state?.length > 0
      ? formState?.tripDetails?.origin?.[0]?.state?.map((s: string) => {
          stateData = State?.getStateByCodeAndCountry(
            s,
            formState?.tripDetails?.origin?.[0]?.countryCode
          );
          return { label: stateData?.name, value: s };
        })
      : StateOptions;
  return renderFormFields(generateContactInfoFields(states), {
    required: formState?.travellerFormFields?.requiredFields,
    optional: formState?.travellerFormFields?.optionalFields
  });
};

export const renderTravelInformation = (formState: any) => {
  let stateData: any;
  let states =
    formState?.tripDetails?.origin?.[0]?.state?.length > 0
      ? formState?.tripDetails?.origin?.[0]?.state?.map((s: string) => {
          stateData = State?.getStateByCodeAndCountry(
            s,
            formState?.tripDetails?.origin?.[0]?.countryCode
          );
          return { label: stateData?.name, value: s };
        })
      : StateOptions;
  return renderFormFields(generateTravelInfoFields(states), {
    required: formState?.travellerFormFields?.requiredFields,
    optional: formState?.travellerFormFields?.optionalFields
  });
};

export const renderPassportForm = (formState: any) => {
  return renderFormFields(generatePassportFields(formState), {
    required: formState?.travellerFormFields?.requiredFields,
    optional: formState?.travellerFormFields?.optionalFields
  });
};

export const renderBookingInformation = (
  formState: any,
  options: any,
  formProps: FormikProps<any>,
  isLoading: boolean
) => {
  return renderFormFields(generateBookingInformationFields(options, formProps, isLoading), {
    required: formState?.travellerFormFields?.requiredFields
      .filter((str: string) => str.includes('trip.bookingInformation'))
      .map((str: string) => str.split('trip.bookingInformation.')[1]),
    optional: formState?.travellerFormFields?.optionalFields
      .filter((str: string) => str.includes('trip.bookingInformation'))
      .map((str: string) => str.split('trip.bookingInformation.')[1])
  });
};
