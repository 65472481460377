/* eslint-disable react-hooks/exhaustive-deps */
import OrderFilterNavItem from '../../components/OAFilterNav';
import { useApiContext } from './context/ApiContext';

interface IOcrSearch {
  totalOrder: { totalSuccessCalls: number; totalFailedCalls: number; totalCalls: number };
  isLoading: boolean;
}

const OcrSearch = (props: IOcrSearch) => {
  const { totalOrder } = props;

  const { queryParams, setQueryParams } = useApiContext();

  const filterNavs = [
    {
      label: 'Success',
      value: 'SUCCESS',
      count: totalOrder?.totalSuccessCalls,
      show: true
    },
    {
      label: 'Failed',
      value: 'FAILED',
      count: totalOrder?.totalFailedCalls,
      show: true
    },
    {
      label: 'All',
      value: '',
      count: totalOrder?.totalCalls,
      show: true
    }
  ];

  return (
    <div className='card-header justify-content-end card-header-stretch border-bottom border-gray-200 min-h-70px'>
      <div className='card-toolbar m-0 order-search'>
        <ul className='nav nav-stretch nav-line-tabs border-transparent' role='tablist'>
          {filterNavs?.length &&
            filterNavs?.map((obj, idx) =>
              obj?.show ? (
                <OrderFilterNavItem
                  key={idx}
                  label={obj?.label}
                  orderStatus={queryParams?.orderStatus!}
                  value={obj?.value}
                  count={obj?.count}
                  onClick={() => {
                    setQueryParams(prev => ({
                      ...prev,
                      orderStatus: obj?.value,
                      pageIndex: 1,
                      isLoading: false
                    }));
                  }}
                  isLoading={props?.isLoading}
                />
              ) : null
            )}
        </ul>
      </div>
    </div>
  );
};

export default OcrSearch;
