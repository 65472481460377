import { useState } from 'react';
import { useField } from 'formik';
import classNames from 'classnames';

interface IOASwitchField {
  label?: string;
  name: string;
  required?: boolean;
  className?: string;
  size?: 'sm' | 'lg';
  toggle?: (val: boolean) => void;
}

export default function OASwitchField(props: IOASwitchField) {
  const { label, name, required = false, className = '' } = props;

  const [isToggle, setIsToggle] = useState<boolean>(false);
  const [{ value, checked, onChange, ...rest }, _meta, helpers] = useField(name);

  const labelClassNames = classNames('form-label mb-0 ms-n15', {
    required: required
  });

  const rootClasses = classNames('d-flex justify-content-between form-switch', className);

  return (
    <div className={rootClasses}>
      <label htmlFor={name} className={labelClassNames}>
        {label}
      </label>
      <div className='d-flex'>
        <span className='me-17'>{isToggle ? 'On' : 'Off'}</span>
        <input
          className='form-check-input'
          type='checkbox'
          role='switch'
          id={name}
          checked={value}
          onChange={e => {
            helpers?.setValue(e?.target?.checked);
            setIsToggle(e?.target?.checked);
            props?.toggle ? props?.toggle(false) : null;
          }}
        />
      </div>
    </div>
  );
}
