/* eslint-disable no-unused-vars */
import { Col, Form, Row } from 'react-bootstrap';

import { KTSVG } from '../../../_metronic/helpers';

interface IAddonServices {
  data: Array<any>;
  type: 'add' | 'edit';
  handleChange: (event: any) => void;
  values?: Array<any>;
}

export default function AddonServices(props: IAddonServices) {
  const { values = [] } = props;
  return (
    <Row className='order-premium-services mb-9'>
      {props?.data?.map((obj: any, index: number) => {
        const isChecked = !!values?.filter((valObj: any) => valObj?.name === obj?.name)?.length;

        return (
          <Col lg={6} sm={12} className='premium-service mb-4' key={index}>
            <div className='d-flex justify-content-between mb-4'>
              <div className='d-flex'>
                <KTSVG
                  path='/media/icons/duotune/communication/com009.svg'
                  className='svg-icon-4 me-2'
                />
                <span className='fw-bold'>{obj?.name}</span>
              </div>
              <div className='d-flex'>
                <span className='fw-bold'>{`${obj?.currency} ${obj?.amount}`}</span>
                <div className='form-check form-check-custom form-check-solid form-check-sm ms-3'>
                  <Form.Group controlId={`addon_service_${index}`}>
                    <Form.Check
                      value={obj?.name}
                      type='checkbox'
                      onChange={props?.handleChange}
                      checked={isChecked}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
            <div className='separator' />
          </Col>
        );
      })}
    </Row>
  );
}
