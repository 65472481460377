import { GridColDef } from '@mui/x-data-grid-pro';
import { DefaultCell } from '../../../../components/OAColumn';
import { GridColumnHeader } from '../../../../components/OATableComponent';
import { Box } from '@mui/material';
import { theme } from '../../../../config/theme';

type getPNRColumnsType = (value: any, setValue: any) => GridColDef[];

export const getPNRColumns: getPNRColumnsType = (value, setValue) => {
  let draft: Array<GridColDef> = [
    {
      field: 'TravellerName',
      renderHeader: params => <GridColumnHeader title='Traveller Name' params={params} />,
      renderCell: params => {
        return <DefaultCell data={params?.row?.travellerName ?? '--'} />;
      },
      flex: 1,
      minWidth: 200
    },
    {
      field: 'Ticket Number',
      renderHeader: params => <GridColumnHeader title='Ticket Number' params={params} />,
      renderCell: params => {
        const handleTicketNumberChange = (travellerId: any, newTicketNumber: any) => {
          setValue((prevState: any) => ({
            ...prevState,
            [travellerId]: newTicketNumber
          }));
        };
        return (
          <Box sx={{ backgroundColor: theme.palette.grey[50], borderRadius: '4px' }}>
            <input
              type='text'
              className='form-control form-control-sm form-control-solid oa-text-search w-150px ps-9'
              placeholder='Ticket Number'
              defaultValue={params?.row?.ticketNumber ?? null}
              onChange={e => handleTicketNumberChange(params?.row?.travellerId, e.target.value)}
            />
          </Box>
        );
      },
      width: 200
    }
  ];
  return draft;
};
