import { useState } from 'react';
import FileDownload from 'js-file-download';

import { getDateFormat, getFormattedDate, showToast } from '../../../helper';
import API from '../../../helper/API';
import { ILedgerProvider, ILedgerQueryParams } from '../type';
import LedgerContext, { ILedgerContext } from './LedgerContext';
import { DATE_FORMAT_BE, OA_DATE_FORMAT_BE } from '../../../constants';
import moment from 'moment';
import { IPageInfo } from '../../../components/OATableComponent';

const LedgerProvider = (props: ILedgerProvider) => {
  const { queryParams } = props?.data;
  const [ledgerList, setLedgerList] = useState<any[]>([]);
  const [ledgerBalance, setLedgerbalance] = useState<any>(null);
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    totalCount: 0,
    totalPages: 0,
    count: 0,
    size: 20,
    index: 1
  });
  const getLedgerInfo = async (cb?: (response?: any) => void) => {
    try {
      API.get(`/ledger`)
        .then((response: any) => {
          const resultData = response?.data?.data;
          cb?.(resultData);
        })
        .catch(err => {
          cb?.(err);
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const getLedgerList = async (cb?: (response?: any) => void) => {
    try {
      API.get(`/ledger/entries?${buildQueryParams(queryParams)}`)
        .then((response: any) => {
          const resultData = response?.data?.data;
          setLedgerList(resultData?.content);
          setPageInfo(prev =>
            Object.assign({}, prev, {
              totalCount: resultData?.totalElements,
              totalPages: resultData?.totalPages,
              count: resultData?.numberOfElements
            })
          );
          cb?.(resultData);
        })
        .catch(err => {
          cb?.(err);
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const getCurrentBalance = async (cb?: (response?: any) => void) => {
    try {
      API.get(`/ledger`)
        .then((response: any) => {
          const resultData = response?.data?.data;
          setLedgerbalance(resultData);
          cb?.(resultData);
        })
        .catch(err => {
          cb?.(err);
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const downloadReport = async (cb?: (response?: any) => void) => {
    try {
      const APITemp: any = API;
      APITemp.responseType = 'blob';
      APITemp.get(`/ledger/report?${buildQueryParamsReport(queryParams)}`)
        .then((response: any) => {
          showToast('Report generated!', 'success');
          FileDownload(response?.data, 'report.csv');
          cb?.(response);
        })
        .catch((err: any) => {
          cb?.(err);
          showToast(err?.response?.data, 'error');
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const addBalance = async (amount: number, cb?: (response?: any) => void) => {
    try {
      API.post(`/ledger/credit?amount=${amount}`)
        .then((response: any) => {
          const resultData = response?.data?.data;
          showToast(`INR ${amount} is added to your balance`, 'success');
          cb?.(resultData);
        })
        .catch(err => {
          cb?.(err);
          showToast(err, 'error');
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const addTransaction = async (values: any, cb?: (response?: any) => void) => {
    let requestBody = getTransactionalValues(values);

    try {
      API.post(`/ledger`, requestBody)
        .then((response: any) => {
          const resultData = response?.data?.data;
          showToast(`Transaction added successfully`, 'success');
          getLedgerList();
          getCurrentBalance();
          cb?.(resultData);
        })
        .catch(err => {
          showToast(err?.response?.data?.errors, 'error');
          cb?.(err);
        });
    } catch (error: any) {
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const editTransaction = async (values: any, cb?: (response?: any) => void) => {
    let requestBody = getTransactionalValues(values);

    try {
      API.put(`/ledger/${requestBody?.id}`, requestBody)
        .then((response: any) => {
          const resultData = response?.data?.data;
          showToast(`Transaction edited successfully`, 'success');
          getLedgerList();
          getCurrentBalance();
          cb?.(resultData);
        })
        .catch(err => {
          showToast(err?.response?.data?.errors, 'error');
          cb?.(err);
        });
    } catch (error: any) {
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };
  const contextValue: ILedgerContext = {
    getLedgerInfo,
    getLedgerList,
    getCurrentBalance,
    downloadReport,
    addBalance,
    addTransaction,
    editTransaction,
    ledgerList,
    ledgerBalance,
    pageInfo
  };

  return <LedgerContext.Provider value={contextValue}>{props?.children}</LedgerContext.Provider>;
};

export default LedgerProvider;

const buildQueryParams = (queryParams: ILedgerQueryParams): string => {
  const startDate = getDateFormat(queryParams?.dateRange?.[0] as Date, DATE_FORMAT_BE);
  const endDate = getDateFormat(queryParams?.dateRange?.[1] as Date, DATE_FORMAT_BE);
  let queryStr = `start=${startDate}&end=${endDate}&page=${queryParams?.pageIndex - 1}&size=10`;
  return queryStr;
};

const buildQueryParamsReport = (queryParams: ILedgerQueryParams): string => {
  const startDate = getDateFormat(queryParams?.dateRange?.[0] as Date, DATE_FORMAT_BE);
  const endDate = getDateFormat(queryParams?.dateRange?.[1] as Date, DATE_FORMAT_BE);
  let queryStr = `start=${startDate}&end=${endDate}`;
  return queryStr;
};

const getTransactionalValues = (values: any) => {
  let draft: any = {
    id: values?.id ?? null,
    ledgerId: values?.ledgerId ?? null,
    orderId: values?.linkedOrderId ?? null,
    linkedOrderId: values?.orderId ?? null,
    description: values?.description,
    credit: values?.credit,
    debit: values?.debit
  };
  let transactionDate = getFormattedDate(values?.dateOfTransaction, OA_DATE_FORMAT_BE);
  let time = moment(values?.transactionTime)?.toISOString();
  draft.createdAt = `${transactionDate}T${time.split('T')[1]}`;
  return draft;
};
