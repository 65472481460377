import moment from 'moment';
import { format } from 'date-fns';

import { OA_DATE_FORMAT_BE } from '../constants';
/**
 * DEVNOTE: to use these helper functions just import setDateFormat and use it's
 * predefined functions. e.g. -
 *
 * - import setDateFormat form "../../helper/dateFormat";
 * -
 * - const draftStartOfDay = setDateFormat.startOfDayForBE(date);
 * - const draftEndOfDay = setDateFormat.endOfDayForBE(date);
 *
 */

export const setDateFormat = {
  startOfDayForBE: (date: Date) => {
    const draft = moment(date).format(OA_DATE_FORMAT_BE);
    return `${draft}T00:00:00.000`;
  },
  endOfDayForBE: (date: Date) => {
    const draft = moment(date).format(OA_DATE_FORMAT_BE);
    return `${draft}T23:59:59.999`;
  },
  dateOnlyForBE: (date: Date) => {
    const draft = moment(date).format(OA_DATE_FORMAT_BE);
    return draft;
  }
};
/**
 * @deprecated
 */
export const getDateFormat = (date: number | Date, dateFormat: string = 'dd/MM/yyyy') => {
  const draft = format(new Date(date), dateFormat);

  return draft;
};

export const dateDiff = (d1: Date, d2: Date) => {
  let date1 = new Date(d1);
  let date2 = new Date(d2);

  // To calculate the time difference of two dates
  const Difference_In_Time = date2.getTime() - date1.getTime();

  // To calculate the no. of days between two dates
  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  return Math.trunc(Difference_In_Days) + 1;
};

export const dateDiffInsurance = (d1: Date, d2: Date) => {
  let date1 = new Date(d1);
  let date2 = new Date(d2);

  // To calculate the time difference of two dates
  const Difference_In_Time = date2.getTime() - date1.getTime();

  // To calculate the no. of days between two dates
  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  return Math.round(Difference_In_Days);
};

//To convert a string with a non-ISO-8601 Format to a Date object
export const nonISODate = (d: string) => {
  const [day, month, year] = d.split('/');
  const date = new Date(+year, +month - 1, +day);
  return date;
};
