import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { theme } from '../../../config/theme';
import { getFormattedDate } from '../../../helper';
import moment from 'moment';
import TripType from './FlightTabHelpers/TripType';
import { FareRules } from './FareRules';

interface ITravelDetails {
  data: any;
}
export default function RoundTrip(props: ITravelDetails) {
  const { data } = props;
  const [tabs, setTabs] = useState({
    cancellation: false,
    dateChange: false
  });

  const checkIfSameDay = (departureDateTime: any, arrivalDateTime: any) => {
    const departure = moment(departureDateTime, 'YYYY-MM-DD HH:mm');
    const arrival = moment(arrivalDateTime, 'YYYY-MM-DD HH:mm');
    let result = departure.isSame(arrival, 'day');
    return result ? 'Arrives same day' : 'Arrives next day';
  };

  useEffect(() => {
    setTabs({
      cancellation: true,
      dateChange: false
    });
  }, []);

  let onward = data?.flights?.find((onward: any) => onward?.departureType === 'ONWARD');
  let onwardDeparture = onward?.segments?.find((segment: any) => segment?.segmentId === 1);
  let onwardArrival = onward?.segments?.find(
    (segment: any) => segment?.segmentId === onward?.segments?.length
  );

  let returnData = data?.flights?.find((obj: any) => obj?.departureType === 'RETURN');
  let returnArrival = returnData?.segments?.find(
    (segment: any) => segment?.segmentId === onward?.segments?.length + 1
  );

  return (
    <Box display='flex' flexDirection='column' gap='20px'>
      <TripType
        onward={onward}
        onwardDeparture={onwardDeparture}
        onwardArrival={onwardArrival}
        checkIfSameDay={checkIfSameDay}
        data={data}
        header='Onward Details'
      />
      {returnData && (
        <TripType
          onward={returnData}
          onwardDeparture={returnArrival}
          onwardArrival={onwardArrival}
          checkIfSameDay={checkIfSameDay}
          data={data}
          header='Return Details'
        />
      )}
      {data?.bookingStatus !== 'ITINERARY' && (onward?.minirule || returnData?.minirule) && (
        <FareRules tabs={tabs} setTabs={setTabs} data={data} />
      )}
    </Box>
  );
}
