import React, { Fragment } from 'react';
import { Badge } from 'react-bootstrap';
export default function BellSVG(props: any) {
  return (
    <Fragment>
      <svg
        className={'notification-icon-button'}
        width='36'
        height='35'
        viewBox='0 0 36 35'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <rect width='36' height='35' rx='4' fill='#F6F8FA' />
        <path
          opacity='0.3'
          d='M18 28C19.6569 28 21 26.6569 21 25C21 23.3431 19.6569 22 18 22C16.3431 22 15 23.3431 15 25C15 26.6569 16.3431 28 18 28Z'
          fill='#68778C'
        />
        <path
          d='M25 21V24C25 24.6 24.6 25 24 25H12C11.4 25 11 24.6 11 24V21C12.1 21 13 20.1 13 19V16C13 13.6 14.7 11.6 17 11.1V9C17 8.4 17.4 8 18 8C18.6 8 19 8.4 19 9V11.1C21.3 11.6 23 13.6 23 16V19C23 20.1 23.9 21 25 21ZM17 16C17 15.4 17.4 15 18 15C18.6 15 19 14.6 19 14C19 13.4 18.6 13 18 13C16.3 13 15 14.3 15 16C15 16.6 15.4 17 16 17C16.6 17 17 16.6 17 16Z'
          fill='#68778C'
        />
      </svg>
      <Badge className='notification-count' pill bg='primary'>
        {props?.unseenCount < 100 ? props?.unseenCount : '99+'}
      </Badge>
    </Fragment>
  );
}
