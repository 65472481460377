import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';

import OAToolbar, { IBreadcrumbItem } from '../../components/widgets/OAToolbar';
import OAModalComponent from '../../components/OAModalComponent';
import { useOrgContext } from './context/OrgContext';
import OrgProvider from './context/OrgProvider';
import OrgForm from './OrgForm';
import OrgList from './OrgList';

export default function OrgManagement() {
  return (
    <OrgProvider>
      <Organizations />
    </OrgProvider>
  );
}

function Organizations() {
  const { selectedOrg, resetSelected } = useOrgContext();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggle = () => setIsOpen(prev => !prev);

  const handleToggle = () => {
    !isEmpty(selectedOrg) && resetSelected?.();
    toggle?.();
  };

  const breadcrumb: Array<IBreadcrumbItem> = [
    {
      title: `Dashboard`,
      path: `/dashboard`,
      isActive: false
    },
    {
      title: `Organizations Management`,
      path: ``,
      isActive: true
    }
  ];

  return (
    <React.Fragment>
      {/**
       * NOTE: Toolbar for organization management
       */}
      <OAToolbar
        title='Organizations'
        breadcrumb={breadcrumb}
        actionsElement={
          <Button size='sm' onClick={toggle}>
            Create Organization
          </Button>
        }
      />
      {/**
       * NOTE: Organization list table
       */}
      <OrgList toggle={toggle} />
      {/**
       * NOTE: Modal for create/edit organization
       */}
      <OAModalComponent
        size='lg' // NOTE: set modal size
        keyboard={false} // NOTE: disable keyboard event
        backdrop='static' // NOTE: disable backdrop click
        centered // NOTE: vertically center modal
        show={isOpen}
        onHide={handleToggle}
        header={{
          title: `${isEmpty(selectedOrg) ? `Add` : `Edit`} Organization`,
          closeButton: true // NOTE: show close button on header
        }}
        body={{
          content: <OrgForm data={selectedOrg} toggle={handleToggle} />
        }}
      />
    </React.Fragment>
  );
}
