import { isEmpty } from 'lodash';

import { setDateFormat } from '../../../helper';
import { ICreateOrderFormState, IOrderType } from '../types';
import { ORDER_TYPE } from '../constants';

export interface ICreateQuoteDraftValues {
  consulate?: string;
  occupation?: string;
  tripDuration?: { from: Date; to: Date };
  save?: boolean;
  proposerInfo?: {
    name: string;
    email: string;
    companyName: string;
  };
  addonModifiers?: any;
  markup?: {
    amount: number;
  };
  amountModifier?: {
    key: 'PRODUCT_FEE' | 'ADD_ON_SERVICE_FEE' | 'SUPPLIER_FEE' | 'VAC_SERVICE_CHARGE';
    amountCollected: boolean;
    name?: string;
  };
  memberCount?: Array<{ type: 'SENIOR' | 'ADULT' | 'CHILD' | 'INFANT'; count: number }>;
}

interface ICreateQuoteActions {
  onAddAddonService: (values: any) => void;
  onRemoveAddonService: (values: any) => void;
  onAddMarkup: (values: any) => void;
  updateFormState: (values: any) => void;
}

export const getDraftQuoteRequest = (
  type: IOrderType,
  formState: ICreateOrderFormState,
  values: ICreateQuoteDraftValues,
  opType: 'create' | 'update',
  actions: ICreateQuoteActions
) => {
  const { save = false } = values;

  const localTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // const isVisa = type === ORDER_TYPE.VISA;
  // const isInsurance = type === ORDER_TYPE.INSURANCE;
  /**
   * NOTE: create "draft" object with initial values ...
   */
  let draft: any = {
    trip: {
      tripStart: {
        datetime: setDateFormat.startOfDayForBE(formState?.tripDuration?.from),
        timezone: localTZ
      },
      tripEnd: {
        datetime: setDateFormat.endOfDayForBE(formState?.tripDuration?.to),
        timezone: localTZ
      },
      // tripType:
      //   formState?.tripDetails?.productInformation?.tripType ??
      //   formState?.tripDetails?.trip?.tripType,
      // visaType:
      //   formState?.tripDetails?.trip?.visaType ??
      //   formState?.tripDetails?.productInformation?.visaType,
      origin: formState?.tripDetails?.trip?.origin ?? formState?.tripDetails?.origin,
      destination: formState?.tripDetails?.trip?.destination ?? formState?.tripDetails?.destination
    }
  };

  if (opType === 'create') {
    draft['requestedProducts'] = [formState?.tripDetails?.id];
    draft['save'] = save;
  }

  if (type === ORDER_TYPE.VISA) {
    draft['consulate'] = values?.consulate ?? formState?.consulate;
    draft['occupation'] = values?.occupation ?? formState?.occupation;
    draft['productAmountToBeCollected'] = formState?.productAmountToBeCollected ?? true;
  } else {
    draft['trip'] = {
      ...draft['trip'],
      domestic: formState?.tripDetails?.trip?.domestic
      // tripTgooype: formState?.tripType
    };
  }

  if (!isEmpty(values?.tripDuration)) {
    draft['trip'] = {
      ...draft['trip'],
      tripStart: {
        datetime: setDateFormat.startOfDayForBE(values?.tripDuration?.from),
        timezone: localTZ
      },
      tripEnd: {
        datetime: setDateFormat.endOfDayForBE(values?.tripDuration?.to),
        timezone: localTZ
      }
    };
  }

  if (values?.memberCount) {
    draft['memberCount'] = values?.memberCount;
    actions?.updateFormState({ memberCount: values?.memberCount });
  } else {
    draft['memberCount'] = formState?.memberCount;
  }

  if (values?.proposerInfo) {
    draft['proposerInfo'] = values?.proposerInfo;
  }

  if (values?.addonModifiers) {
    const { action, ...addonServiceObj } = values?.addonModifiers;
    let addOnServiceModifier = [];
    if (action === 'add') {
      addOnServiceModifier = formState?.addOnServiceModifier?.length
        ? [...formState?.addOnServiceModifier, addonServiceObj]
        : [addonServiceObj];
      /**
       * NOTE: call "onAddAddonService" action to update "addOnServiceModifier" in "formState" ...
       */
      actions?.onAddAddonService(addonServiceObj);
    }
    if (action === 'remove') {
      addOnServiceModifier = formState?.addOnServiceModifier?.length
        ? formState?.addOnServiceModifier?.filter((obj: any) => obj?.name !== addonServiceObj?.name)
        : [];
      /**
       * NOTE: call "onRemoveAddonService" action to update "addOnServiceModifier" in "formState" ...
       */
      actions?.onRemoveAddonService(addonServiceObj);
    }
    draft['addOnServiceModifier'] = addOnServiceModifier;
  } else if (formState?.addOnServiceModifier?.length) {
    draft['addOnServiceModifier'] = formState?.addOnServiceModifier;
  }

  if (values?.markup) {
    draft['markup'] = values?.markup;
    /**
     * NOTE: call "onAddMarkup" action to update "markup" in "formState" ...
     */
    actions?.onAddMarkup(values);
  } else if (!isEmpty(formState?.markup)) {
    draft['markup'] = formState?.markup;
  }

  if (values?.amountModifier) {
    if (values?.amountModifier?.key === 'PRODUCT_FEE') {
      draft['productAmountToBeCollected'] = values?.amountModifier?.amountCollected;
      actions?.updateFormState({
        productAmountToBeCollected: values?.amountModifier?.amountCollected
      });
    } else if (values?.amountModifier?.key === 'VAC_SERVICE_CHARGE') {
      const draftAddOnServiceModifier = draft?.addOnServiceModifier?.map((obj: any) => {
        return obj?.type === 'VAC_SERVICE_CHARGE'
          ? Object.assign({}, obj, { amountCollected: values?.amountModifier?.amountCollected })
          : obj;
      });
      draft['addOnServiceModifier'] = draftAddOnServiceModifier;
      actions?.updateFormState({
        addOnServiceModifier: draftAddOnServiceModifier
      });
    } else {
      const draftAddOnServiceModifier = draft?.addOnServiceModifier?.map((obj: any) => {
        return obj?.type === values?.amountModifier?.key &&
          obj?.name === values?.amountModifier?.name
          ? Object.assign({}, obj, { amountCollected: values?.amountModifier?.amountCollected })
          : obj;
      });
      draft['addOnServiceModifier'] = draftAddOnServiceModifier;
      actions?.updateFormState({
        addOnServiceModifier: draftAddOnServiceModifier
      });
    }
  }

  return draft;
};
