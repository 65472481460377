import { KTSVG } from '../../../../_metronic/helpers';
import { useOrderContext } from '../../../order-management/context/OrderContext';

const NotesAndForms = () => {
  const {
    orderData: { data }
  } = useOrderContext();

  const {
    product: {
      productInformation: { notes, requiredVisaForms }
    }
  } = data;

  return (
    <>
      {notes && notes?.length > 0 && (
        <div className='dev--quotation__notes mb-8'>
          <h1 className='fw-bolder mb-3 text-dark fs-4'>Notes</h1>
          {notes.map((obj: any, i: number) => (
            <div key={i} className='d-flex align-items-center mb-1'>
              <KTSVG path='/media/icons/duotune/arrows/arr085.svg' className='svg-icon-1 pe-2' />
              <span className='fw-bold fs-7 text-gray-700 '>{obj}</span>
            </div>
          ))}
        </div>
      )}

      {requiredVisaForms && requiredVisaForms?.lenght > 0 && (
        <div className='dev--quotation__visa-forms mb-8'>
          <h1 className='fw-bolder mb-3 text-dark fs-4'>Visa Forms</h1>
          {requiredVisaForms.map((obj: any, i: number) => (
            <div key={i} className='d-flex align-items-center mb-1'>
              <KTSVG path='/media/icons/duotune/files/fil003.svg' className='svg-icon-1 pe-2' />
              <a
                href={obj?.formLink}
                target='_blank'
                rel='noreferrer'
                className='text-gray-800 text-hover-primary text-decoration-underline'>
                {obj?.formName}
              </a>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default NotesAndForms;
