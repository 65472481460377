import * as React from 'react';
import { toAbsoluteUrl } from '../../helper';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
  errorInfo?: React.ErrorInfo;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='d-flex flex-column flex-root'>
          <div
            className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
            style={{
              backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/progress-hd.png')}')`
            }}>
            <div className='d-flex flex-column flex-column-fluid text-center p-10 py-lg-20'>
              <a href='/dashboard' className='mb-10 pt-lg-20'>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl(
                    `/media/logos/${
                      window.location.host === 'world.evisas.io'
                        ? 'logo-alsaffron.svg'
                        : 'logo-1.svg'
                    }`
                  )}
                  className='h-50px mb-5'
                />
              </a>
              <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>Oops, something went wrong</h1>

              <div className='fw-bold fs-3 text-gray-400 mb-15'>The page you looked not found!</div>

              <div className='text-center'>
                <a href='/dashboard' className='btn btn-lg btn-primary fw-bolder'>
                  Go to homepage
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
