import { Card, Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { KTSVG } from '../../_metronic/helpers/components/KTSVG';
import OATypography from '../../components/widgets/OATypography';

interface IApiStatsCard {
  data: number;
  title: string;
  isLoading: boolean;
  icon: any;
}

const ApiCardTile = (props: IApiStatsCard) => {
  const { data, title, isLoading, icon } = props;

  return (
    <Card className='h-100 shadow-sm me-6'>
      <Card.Body className='d-flex'>
        <Row>
          <OATypography variant='body1'>{title}</OATypography>
          <OATypography variant='h1' sx={{ mt: 2 }}>
            {isLoading ? <Skeleton height={21} width={100} /> : data}
          </OATypography>
        </Row>
        <div className='d-flex align-items-center'>{icon}</div>
      </Card.Body>
    </Card>
  );
};

export default ApiCardTile;
