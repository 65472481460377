/* eslint-disable no-unused-vars */
import {
  GridColDef,
  GridRowParams,
  GridActionsCellItem,
  GridColumnHeaderParams,
  GridValidRowModel
} from '@mui/x-data-grid-pro';

import { UserRoleOptions } from '../../order-management/constants';
import {
  DefaultCell,
  CreatedOnCell,
  StatusCell,
  ActivatedAtCell
} from '../../../components/OAColumn';
import { OA_DATE_TIME_FORMAT_FE } from '../../../constants';
import { GridColumnHeader } from '../../../components/OATableComponent';
import { useAuth } from '../../../auth';
import OAButton from '../../../components/widgets/OAButton';

type getUserColumnsType = (actions: {
  handleEditUser: (userId: string) => void;
  handleDeleteUser: (userId: string) => void;
  handlePasswordReset: (email: string, userId: string) => void;
  resettingUserId: any;
  setResettingUserId: any;
}) => GridColDef[];

export const getUserColumns: getUserColumnsType = actions => {
  const { currentUser } = useAuth();
  const isSuperAdmin = currentUser?.data?.role === 'ROLE_SUPER_ADMIN';

  return [
    {
      field: 'userName',
      renderHeader: params => <GridColumnHeader title='User Name' params={params} />,
      renderCell: params => <DefaultCell data={params?.value} />,
      valueGetter: params => `${params.row.firstName || ''} ${params.row.lastName || ''}`,
      width: 200
    },
    ...(isSuperAdmin
      ? [
          {
            field: 'userOrganization',
            renderHeader: (params: any) => (
              <GridColumnHeader title='Organization' params={params} />
            ),
            renderCell: (params: any) => <DefaultCell data={params?.value} />,
            valueGetter: (params: any) => params?.row?.organization?.name ?? '',
            width: 150
          }
        ]
      : []),
    {
      field: 'userRole',
      renderHeader: params => <GridColumnHeader title='Role' params={params} />,
      renderCell: params => <DefaultCell data={params?.value} />,
      valueGetter: params => {
        const findRole = UserRoleOptions?.find(role => role?.value === params?.row?.role)?.label;
        return findRole ?? '';
      },
      width: 150
    },
    {
      field: 'status',
      renderHeader: params => <GridColumnHeader title='Status' params={params} />,
      renderCell: params => <StatusCell status={params?.value} type='user' />,
      valueGetter: params => params?.row?.status,
      width: 100
    },
    {
      field: 'userEmail',
      renderHeader: params => <GridColumnHeader title='Email' params={params} />,
      renderCell: params => <DefaultCell data={params?.value} textCapitalize={false} />,
      valueGetter: params => params?.row?.email ?? '',
      flex: 1,
      minWidth: 150
    },
    {
      field: 'createdOn',
      renderHeader: params => <GridColumnHeader title='Created On' params={params} />,
      renderCell: params => (
        <CreatedOnCell date={params?.value} dateFormat={OA_DATE_TIME_FORMAT_FE} />
      ),
      valueGetter: params => params?.row?.createdAt,
      width: 175,
      sortable: true
    },
    {
      field: 'activatedAt',
      renderHeader: params => <GridColumnHeader title='Activated At' params={params} />,
      renderCell: params => (
        <ActivatedAtCell date={params?.value} dateFormat={OA_DATE_TIME_FORMAT_FE} />
      ),
      valueGetter: params => params?.row?.activatedAt,
      width: 125,
      sortable: true
    },
    ...(isSuperAdmin
      ? [
          {
            field: 'changePassword',
            renderHeader: (params: any) => <GridColumnHeader title='' params={params} />,
            renderCell: (params: any) => {
              const isResetting = actions?.resettingUserId === params.id; // Check if the current row's button is being clicked
              return (
                <OAButton
                  label={isResetting ? 'Resetting...' : 'Reset'}
                  isLoading={isResetting}
                  onClick={() => actions?.handlePasswordReset(params?.row?.email, params?.id)}
                />
              );
            },
            width: 125
          }
        ]
      : []),
    {
      field: 'action',
      type: 'actions',
      getActions: (params: GridRowParams) => {
        return [
          <GridActionsCellItem
            label='Edit'
            onClick={() => actions?.handleEditUser(params?.row?.id)}
            showInMenu
          />,
          <GridActionsCellItem
            label='Delete'
            onClick={() => actions?.handleDeleteUser(params?.row?.id)}
            showInMenu
          />
        ];
      },
      width: 50
    }
  ];
};
