import { Box } from '@mui/material';
import OrderInvoice from '../OrderInvoice';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/error.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/success.svg';
import { theme } from '../../../config/theme';
import { OATypography } from '../../../components/widgets';
import OAButton from '../../../components/widgets/OAButton';
import { showDialog } from '../../../helper';
import { cancelTableData } from '../FlightTabs/PaymentSummaryTables/customerTables';

interface ICancelSummary {
  cancelReasons: any;
  refundInfo: any;
  onHide: (buttonText?: any) => void;
  flightData: any;
  handleSubmit: () => void;
  loading: boolean;
}

export const CancelSummary = (props: ICancelSummary) => {
  const orgCode = props?.flightData?.userOrganization?.organizationCode;
  const tableData = cancelTableData(props);
  return (
    <Box>
      {(!orgCode || orgCode?.toLowerCase()?.includes('niyo')) && (
        <Box
          display='flex'
          height='40px'
          borderRadius='10px'
          alignItems='center'
          justifyContent='start'
          sx={{ backgroundColor: props?.refundInfo?.coinsRefundable > 0 ? '#D9FCF3' : '#FFE5E2' }}>
          {props?.refundInfo?.coinsRefundable > 0 ? (
            <>
              <SuccessIcon style={{ marginLeft: '10px' }} />
              <OATypography
                variant='body2'
                sx={{
                  fontSize: '12px',
                  lineHeight: '16px',
                  marginLeft: '10px'
                }}>{`${props?.refundInfo?.coinsRefundable} Niyo Global Coins will be added back`}</OATypography>
            </>
          ) : (
            <>
              <ErrorIcon style={{ marginLeft: '10px' }} />
              {(!orgCode || orgCode?.toLowerCase()?.includes('niyo')) && (
                <OATypography
                  variant='body2'
                  sx={{ fontSize: '12px', lineHeight: '16px', marginLeft: '10px' }}>
                  No coins are being refunded for this trip
                </OATypography>
              )}
            </>
          )}
        </Box>
      )}
      <div className='card-body py-0'>
        <OrderInvoice tableData={tableData} />
        <Box display='flex' justifyContent='space-between' alignItems='center' marginTop='16px'>
          <OAButton
            label='Back'
            color='light-primary'
            className='btn btn-active-light'
            onClick={() => {
              props?.onHide('Back');
            }}
          />
          <Box display='flex' gap='4px'>
            <OAButton
              label='Cancel'
              color='secondary'
              onClick={() => {
                props?.onHide();
              }}
            />
            <OAButton
              label='Cancel Booking'
              isLoading={props?.loading}
              onClick={() => {
                props?.onHide();
                showDialog({
                  title: 'Cancel flight booking?',
                  text: `You are cancelling this flight from ${
                    props?.flightData?.flights[0]?.segments[0]?.departure?.airport?.city
                  } to ${
                    props?.flightData?.flights[props?.flightData?.flights?.length - 1]?.segments[
                      props?.flightData?.flights[props?.flightData?.flights?.length - 1]?.segments
                        ?.length - 1
                    ]?.arrival?.airport?.city
                  }`,
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, continue'
                }).then(result => {
                  if (result?.isDismissed) {
                    props?.onHide();
                  } else if (result?.isConfirmed) {
                    props?.onHide();
                    props?.handleSubmit();
                  }
                });
              }}
            />
          </Box>
        </Box>
      </div>
    </Box>
  );
};

export const capitalizeFirstLetter = (string: string) => {
  if (!string) return ''; // Return an empty string if the input is undefined or null
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
