import { differenceInMinutes } from 'date-fns';

export const getTimeDifference = (
  scheduledTime: any,
  dateScheduled: any,
  actualTime: any,
  dateActual: any
) => {
  // Parse the times
  const scheduledDate = new Date(`${dateScheduled}T${scheduledTime}:00Z`);
  const actualDate = new Date(`${dateActual}T${actualTime}:00Z`);

  // Calculate the difference
  const diff = differenceInMinutes(actualDate, scheduledDate);

  // Convert to hours and minutes
  const hours = Math.abs(Math.floor(diff / 60));
  const minutes = Math.abs(diff % 60);

  // Generate the result string
  let result = '';
  if (hours > 0) result += `${hours}hr `;
  if (minutes > 0) result += `${minutes}min `;
  result += diff < 0 ? 'early' : 'late';
  return result;
};
