import { useParams } from 'react-router-dom';
import { useAppContext } from '../context/AppContext';

export const getStatusConfig = (status: string, type?: string | undefined) => {
  const { dataExchange } = useAppContext();
  const { orderType } = useParams();

  let statusData;
  if (type) {
    if (type === 'quotation') statusData = dataExchange?.QUOTATION_STATUS;
    else statusData = dataExchange?.ODS_STATUS;
  } else {
    statusData =
      orderType === 'insurance' ? dataExchange?.INSURANCE_STATUS : dataExchange?.VISA_STATUS;
  }

  const findStatus = statusData?.find((obj: any) => obj.value === status);
  if (findStatus) return findStatus;
  else
    return {
      value: status,
      label: status,
      type: type || 'info'
    };
};
