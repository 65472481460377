interface IActionWithIcon {
  icon: any;
  data: any;
  handlePreview?: () => void;
}

const ActionWithIcon = (props: IActionWithIcon) => {
  return (
    <section
      className='d-flex align-items-center justify-content-between w-100'
      onClick={props?.handlePreview}>
      <span className='text-gray-700'>{props?.data}</span>
      {props?.icon}
    </section>
  );
};

export default ActionWithIcon;
