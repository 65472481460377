/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';

import { IOrderType } from '../../order-management/types';
import { IQuotationFormValues, IQuotationProposerValues } from '../types';

export interface IQuotationContext {
  quotationData: {
    data: any | null;
    isLoading: boolean;
  };
  travellerInformation: IQuotationFormValues;
  proposerInfo: IQuotationProposerValues;
  setTravellerInformation: React.Dispatch<React.SetStateAction<IQuotationFormValues>>;
  setProposerInformation: (type: IQuotationProposerValues) => void;
  getQuotationById: (type: IOrderType, id: string, callback?: (res: any) => void) => void;
  addOrEditQuotation: (
    values: any,
    type: IOrderType,
    quotationId: string | null,
    cb?: () => void
  ) => void;
  getCountriesList: (cb?: ((response?: any) => void) | undefined) => Promise<void>;
  setQuotationData: (data: any | null) => void;
}

const QuotationContext = React.createContext<IQuotationContext>({} as IQuotationContext);

export default QuotationContext;

export const useQuotationContext = () => useContext(QuotationContext);
