import { GridColDef } from '@mui/x-data-grid-pro';
import {
  DefaultCell,
  CreatedOnCell,
  AmountCell,
  TravellersCell
} from '../../../components/OAColumn';
import { GridColumnHeader } from '../../../components/OATableComponent';
import { OA_DATE_TIME_FORMAT_FE, OA_DATE_FORMAT_FE, FLIGHT_STATUS } from '../../../constants';
import { OAStatusChip } from '../../../components/widgets';
import DefaultCellWithIcon from '../../../components/OAColumn/DefaultCellWithIcon';
import FlightTripCell from '../../../components/OAColumn/FlightTripCell';
import { Box } from '@mui/material';

type getFlightColumnsType = () => GridColDef[];

export const getFlightColumns: getFlightColumnsType = () => {
  let draft: Array<GridColDef> = [
    {
      field: 'bookingId',
      renderHeader: params => <GridColumnHeader title='Booking Id' params={params} />,
      renderCell: params => <DefaultCell data={params?.row?.bookingId ?? '--'} />,
      width: 180
    },
    {
      field: 'pnrNumber',
      renderHeader: params => <GridColumnHeader title='PNR Number' params={params} />,
      renderCell: params => {
        return <DefaultCell data={params?.row?.flights[0]?.pnrNumber ?? '--'} />;
      },
      width: 120
    },
    {
      field: 'travellers',
      renderHeader: params => <GridColumnHeader title='Travellers' params={params} />,
      renderCell: params => {
        return <TravellersCell members={params?.row?.travellers} />;
      },
      valueGetter: params => params?.row?.travellers,
      width: 340
    },
    {
      field: 'trip',
      renderHeader: params => <GridColumnHeader title='Trip' params={params} />,
      renderCell: params => {
        let onward = params?.row?.flights?.find(
          (onward: any) => onward?.departureType === 'ONWARD'
        );
        let onwardDeparture = onward?.segments?.find((segment: any) => segment?.segmentId === 1);
        let onwardArrival = onward?.segments?.find(
          (segment: any) => segment?.segmentId === onward?.segments?.length
        );
        let returnData = params?.row?.flights?.find((obj: any) => obj?.departureType === 'RETURN');
        let returnArrival = returnData?.segments?.find(
          (segment: any) => segment?.segmentId === onward?.segments?.length + 1
        );
        const tripOrigin = onwardDeparture?.departure?.airport?.iata ?? '';
        const tripDestination =
          params?.row?.flights?.length > 1
            ? returnArrival?.departure?.airport?.iata ?? ''
            : onwardArrival?.arrival?.airport?.iata ?? '';
        return (
          <FlightTripCell
            origin={tripOrigin}
            destination={tripDestination}
            twoWay={params?.row?.flights?.length > 1 ? true : false}
          />
        );
      },
      width: 150
    },
    {
      field: 'airline',
      renderHeader: params => <GridColumnHeader title='Airline' params={params} />,
      renderCell: params => {
        return <DefaultCellWithIcon data={params?.row?.flights[0]?.segments[0]?.airline ?? '--'} />;
      },
      width: 200
    },
    {
      field: 'bookingStatus',
      renderHeader: params => <GridColumnHeader title='Status' params={params} />,
      renderCell: params => {
        const statusObj: any = FLIGHT_STATUS?.find(
          (obj: any) => obj?.value === params?.row?.bookingStatus
        );
        return (
          <OAStatusChip
            status={statusObj?.value}
            label={statusObj?.label}
            variant={statusObj?.type}
          />
        );
      },
      width: 180
    },
    {
      field: 'smbData',
      renderHeader: params => <GridColumnHeader title='SMB' params={params} />,
      renderCell: params => {
        const ancillaries = params?.row?.flights?.flatMap((flight: any) =>
          flight?.ticketNumberInfo?.flatMap((ticket: any) =>
            ticket?.selectedAncillaries?.map((item: any) => item.type)
          )
        );

        const counts = {
          SEAT: 0,
          MEAL: 0,
          BAGGAGE: 0
        };

        ancillaries?.forEach((type: string) => {
          if (type in counts) {
            counts[type as keyof typeof counts]++;
          }
        });

        const parts = [];
        if (counts.SEAT > 0) parts.push(`${counts.SEAT}S`);
        if (counts.MEAL > 0) parts.push(`${counts.MEAL}M`);
        if (counts.BAGGAGE > 0) parts.push(`${counts.BAGGAGE}B`);

        return <DefaultCell data={parts.join(', ') || '-'} />;
      },
      width: 150
    },
    {
      field: 'travelDates',
      renderHeader: params => <GridColumnHeader title='Travel Dates' params={params} />,
      renderCell: params => <CreatedOnCell date={params?.value} dateFormat={OA_DATE_FORMAT_FE} />,
      valueGetter: params => params?.row?.flights[0]?.segments[0]?.departure?.date,
      width: 140,
      sortable: true
    },
    {
      field: 'travelType',
      renderHeader: params => <GridColumnHeader title='Travel Type' params={params} />,
      renderCell: params => {
        return <DefaultCell data={params?.value ? 'International' : 'Domestic'} />;
      },
      valueGetter: params => params?.row?.international,
      width: 140,
      sortable: true
    },
    {
      field: 'bookedOn',
      renderHeader: params => <GridColumnHeader title='Booked On' params={params} />,
      renderCell: params => (
        <CreatedOnCell date={params?.value} dateFormat={OA_DATE_TIME_FORMAT_FE} />
      ),
      valueGetter: params => params?.row?.bookingDate ?? params?.row?.createdAt ?? 'N/A',
      width: 175,
      sortable: true
    },
    {
      field: 'cancelledOn',
      renderHeader: params => <GridColumnHeader title='Cancelled On' params={params} />,
      renderCell: params => {
        let cancelledDetails = params?.value?.find((obj: any) => obj?.status == 'CANCELLED');
        return (
          <>
            {cancelledDetails ? (
              <CreatedOnCell
                date={cancelledDetails?.createdAt}
                dateFormat={OA_DATE_TIME_FORMAT_FE}
              />
            ) : (
              <Box sx={{ marginLeft: '56px' }}>
                <DefaultCell data={'N/A'} />
              </Box>
            )}
          </>
        );
      },
      valueGetter: params => params?.row?.logs,
      width: 175,
      sortable: true
    },
    {
      field: 'amount',
      renderHeader: params => <GridColumnHeader title='Amount(INR)' params={params} />,
      renderCell: params => <AmountCell data={params?.value} />,
      valueGetter: params => params?.row?.orderAmount?.totalAmount,
      width: 120
    }
  ];
  return draft;
};

export const getItineraryColumns: getFlightColumnsType = () => {
  let draft: Array<GridColDef> = [
    {
      field: 'name',
      renderHeader: params => <GridColumnHeader title='Name' params={params} />,
      renderCell: params => {
        return params?.row?.contactDetails ? (
          <TravellersCell members={[params?.row?.contactDetails]} />
        ) : (
          <Box marginLeft='16px'>N/A</Box>
        );
      },
      width: 340
    },
    {
      field: 'email',
      renderHeader: params => <GridColumnHeader title='Email' params={params} />,
      renderCell: params => {
        return <DefaultCell data={params?.value ?? 'N/A'} />;
      },
      valueGetter: params => params?.row?.contactDetails?.email,
      width: 200
    },
    {
      field: 'phone',
      renderHeader: params => <GridColumnHeader title='Phone' params={params} />,
      renderCell: params => {
        return <DefaultCell data={params?.value ?? 'N/A'} />;
      },
      valueGetter: params => params?.row?.contactDetails?.phoneNumber,
      width: 140
    },
    {
      field: 'travellers',
      renderHeader: params => <GridColumnHeader title='Travellers' params={params} />,
      renderCell: params => {
        return (
          <Box display='flex' alignItems='center' justifyContent='center' marginLeft='32px'>
            <DefaultCell data={params?.value ?? 'N/A'} />
          </Box>
        );
      },
      valueGetter: params => params?.row?.travellers?.length,
      width: 100
    },
    {
      field: 'trip',
      renderHeader: params => <GridColumnHeader title='Trip' params={params} />,
      renderCell: params => {
        let onward = params?.row?.flights?.find(
          (onward: any) => onward?.departureType === 'ONWARD'
        );
        let onwardDeparture = onward?.segments?.find((segment: any) => segment?.segmentId === 1);
        let onwardArrival = onward?.segments?.find(
          (segment: any) => segment?.segmentId === onward?.segments?.length
        );
        let returnData = params?.row?.flights?.find((obj: any) => obj?.departureType === 'RETURN');
        let returnArrival = returnData?.segments?.find(
          (segment: any) => segment?.segmentId === onward?.segments?.length + 1
        );
        const tripOrigin = onwardDeparture?.departure?.airport?.iata ?? '';
        const tripDestination =
          params?.row?.flights?.length > 1
            ? returnArrival?.departure?.airport?.iata ?? ''
            : onwardArrival?.arrival?.airport?.iata ?? '';
        return (
          <FlightTripCell
            origin={tripOrigin}
            destination={tripDestination}
            twoWay={params?.row?.flights?.length > 1 ? true : false}
          />
        );
      },
      width: 150
    },
    {
      field: 'airline',
      renderHeader: params => <GridColumnHeader title='Airline' params={params} />,
      renderCell: params => {
        return (
          <DefaultCellWithIcon data={params?.row?.flights[0]?.segments[0]?.airline ?? 'N/A'} />
        );
      },
      width: 160
    },
    {
      field: 'bookingStatus',
      renderHeader: params => <GridColumnHeader title='Status' params={params} />,
      renderCell: params => {
        const statusObj: any = FLIGHT_STATUS?.find(
          (obj: any) => obj?.value === params?.row?.bookingStatus
        );
        return (
          <OAStatusChip
            status={statusObj?.value}
            label={statusObj?.label}
            variant={statusObj?.type}
          />
        );
      },
      width: 120
    },
    {
      field: 'travelDates',
      renderHeader: params => <GridColumnHeader title='Travel Dates' params={params} />,
      renderCell: params => {
        let onward = params?.row?.flights?.find(
          (onward: any) => onward?.departureType === 'ONWARD'
        );
        let onwardDeparture = onward?.segments?.find((segment: any) => segment?.segmentId === 1);
        let returnData = params?.row?.flights?.find((obj: any) => obj?.departureType === 'RETURN');
        let returnDeparture = returnData?.segments?.find(
          (segment: any) =>
            segment?.segmentId === returnData?.segments?.length + onward?.segments?.length
        );
        return (
          <Box display='flex' alignItems='center' gap='2px'>
            <CreatedOnCell date={onwardDeparture?.departure?.date} dateFormat={OA_DATE_FORMAT_FE} />
            {params?.row?.international && (
              <>
                <span> - </span>
                <CreatedOnCell
                  date={returnDeparture?.arrival?.date}
                  dateFormat={OA_DATE_FORMAT_FE}
                />
              </>
            )}
          </Box>
        );
      },
      width: 200,
      sortable: true
    },
    {
      field: 'travelType',
      renderHeader: params => <GridColumnHeader title='Travel Type' params={params} />,
      renderCell: params => {
        return <DefaultCell data={params?.value ? 'International' : 'Domestic'} />;
      },
      valueGetter: params => params?.row?.international,
      width: 140,
      sortable: true
    },
    {
      field: 'bookedOn',
      renderHeader: params => <GridColumnHeader title='Booked On' params={params} />,
      renderCell: params => (
        <CreatedOnCell date={params?.value} dateFormat={OA_DATE_TIME_FORMAT_FE} />
      ),
      valueGetter: params => params?.row?.bookingDate ?? params?.row?.createdAt ?? 'N/A',
      width: 175,
      sortable: true
    },
    {
      field: 'amount',
      renderHeader: params => <GridColumnHeader title='Amount(INR)' params={params} />,
      renderCell: params => <AmountCell data={params?.value} />,
      valueGetter: params => params?.row?.orderAmount?.totalAmount,
      width: 120
    }
  ];
  return draft;
};
