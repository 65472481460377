import React from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import checkIcon from '../../../assets/icons/icon_check.svg';
import { OATypography } from '../../../components/widgets';

function FeaturesList() {
  const features = [
    'Full access to 180+ countries',
    '10 users can create account',
    'Unlimited sharing with clients',
    'Create sub orgaganisations',
    'Get 24x7 support'
  ];

  return (
    <Box sx={{ mt: '20px' }}>
      <OATypography variant='tag' sx={{ color: theme => theme?.palette?.grey[400], mb: '8px' }}>
        PLAN INCLUDES
      </OATypography>
      <List>
        {features.map((feature, index) => (
          <ListItem key={index} sx={{ paddingLeft: 0, paddingTop: 0, paddingBottom: '4px' }}>
            <ListItemIcon sx={{ minWidth: '30px' }}>
              <img src={checkIcon} />
            </ListItemIcon>
            <ListItemText
              primary={
                <OATypography variant='body2' sx={{ color: theme => theme?.palette?.grey[600] }}>
                  {feature}
                </OATypography>
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default FeaturesList;
