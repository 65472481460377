import {
  formatLogDate,
  sortDates
} from '../../order-management/order-view/OrderTabs/ActivityLog/helpers/dateFormatting';
import ActivityLogItem from './ActivityLogItem';
import DateHeader from '../../order-management/order-view/OrderTabs/ActivityLog/DateHeader';

interface flightActivityLogs {
  data: any;
}

function OrderActivity(props: flightActivityLogs) {
  const groupedLogs = props?.data?.logs?.reduce((acc: any, item: any) => {
    const date = formatLogDate(item?.createdAt);
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date]?.push(item);
    return acc;
  }, {});

  // Sorting each group by time
  Object?.keys(groupedLogs)?.forEach(date => {
    groupedLogs[date]?.sort(
      (a: any, b: any) => new Date(b.createdAt)?.getTime() - new Date(a.createdAt)?.getTime()
    );
  });

  const sortedDates = sortDates(groupedLogs);
  return (
    <div className='order-view--activity card pb-6'>
      <div className='card-header'>
        <h3 className='card-title fw-bolder text-dark'>Activity Logs</h3>
      </div>
      <div className='card-body position-relative pb-0'>
        <section>
          {sortedDates?.map(date => (
            <div key={date} className='timeline'>
              <DateHeader date={date} />
              {groupedLogs[date]?.map((item: any, index: number) => (
                <div className='timeline-item '>
                  <ActivityLogItem item={item} />
                </div>
              ))}
            </div>
          ))}
        </section>
      </div>
    </div>
  );
}

export default OrderActivity;
