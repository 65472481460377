import { Col, Modal, Row } from 'react-bootstrap';
import OAButton from '../../../../../components/widgets/OAButton';
import { OASelectField } from '../../../../../components/fields';
import { Field, Form, Formik, FormikProps } from 'formik';
import DocumentReviewStatusList from './DocumentReviewStatusList';
import DocumentPreview from './DocumentPreview';
import { useEffect, useState } from 'react';
import { useOrderContext } from '../../../context/OrderContext';
import { useAppContext } from '../../../../../context/AppContext';
import LoadingState from './LoadingState';
import { useS3FileUploader } from '../../../../../hooks/useS3FileOperation';
import { IDocument, IMember, IOrder } from '../../../../../types';
interface IDocumentsReviewModal {
  orderData: any;
  title: string;
  isOpen: boolean;
  data: any;
  memberData: any;
  requiredDocuments: any;
  selectedClassifiedDocument?: any;
  setData: (data: any) => void;
  toggleModal: (open?: boolean) => void;
  setSelectedClassifiedDocument: (data: any) => void;
}

const DocumentsReviewModal = (props: IDocumentsReviewModal) => {
  const { orderData } = props;
  const { getDataExchangeByType } = useAppContext();

  const {
    apiResponseFromClassifier,
    getOrderById,
    updateDocumentClassifierAction,
    setApiResponseFromClassifier,
    deleteDocument
  } = useOrderContext();
  const {
    title,
    isOpen,
    data,
    memberData,
    selectedClassifiedDocument,
    toggleModal,
    setData,
    setSelectedClassifiedDocument
  } = props;
  const [selectedDocument, setSelectedDocument] = useState<any>(null);
  const [isReviewing, setIsReviewing] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [listOfDocumentTypes, setListOfDocumentTypes] = useState<any>([]);
  const [previouslyUploadedDocs, setPreviouslyUploadedDocs] = useState<any>([]);
  const [editedImgBlob, setEditedImgBlob] = useState<any>(null);
  const [documentJustReviewed, setDocumentJustReviewed] = useState(false);

  const [uploadFile] = useS3FileUploader(5, ['jpg', 'png', 'jpeg', 'pdf']);

  let memberId = memberData?.id;
  let linkedMembersId = null;
  let order: any = null;

  if (memberId) {
    order = orderData?.content?.find((order: IOrder) => {
      linkedMembersId = order?.linkedMembers?.find((linkedId: string) => linkedId === memberId);
      return linkedMembersId;
    });
  }

  const handleFileUpload = async (doc: any) => {
    const result = await uploadFile(doc);
    setEditedImgBlob(result?.uploadedUrl);
    handleReviewDocument(result?.uploadedUrl!);
    return result?.uploadedUrl;
  };

  const selectNextDocument = () => {
    const allDocuments = [...(apiResponseFromClassifier || []), ...(previouslyUploadedDocs || [])];

    const currentIndex = allDocuments?.findIndex(doc => doc.id === selectedDocument.id);
    return allDocuments[currentIndex + 1] || null;
  };

  const handleReviewDocument = async (url?: string) => {
    setIsReviewing(true);
    try {
      if (url) {
        selectedDocument.documentUrl = url;
      }
      await updateDocumentClassifierAction(
        memberId,
        selectedDocument,
        selectedDocument?.id,
        true,
        res => {
          const newInfo = apiResponseFromClassifier?.map((document: any) =>
            document?.id === res?.id ? res : document
          );
          setApiResponseFromClassifier(newInfo);
          const nextDocument = selectNextDocument();
          if (nextDocument) {
            setSelectedDocument(nextDocument);
          } else {
            setSelectedDocument(res);
          }
          setEditedImgBlob(null);
          setIsReviewing(false);
          setDocumentJustReviewed(true);

          const documentInfo = previouslyUploadedDocs?.map((document: any) =>
            document?.id === res?.id ? res : document
          );
          setPreviouslyUploadedDocs(documentInfo);
        }
      );
    } catch (error: any) {
      console.error(error.message);
    }
  };

  const handleDeleteDocument = async () => {
    setIsDeleting(true);
    const isRequiredDoc = props?.requiredDocuments?.some(
      (doc: IDocument) => doc?.id === selectedDocument?.id
    );

    if (isRequiredDoc) {
      deleteAndFilterDocuments();
      setIsDeleting(false);
    } else {
      try {
        await updateAndFilterDocuments();
        setIsDeleting(false);
      } catch (error: any) {
        console.error(error.message);
      }
    }
  };

  const deleteAndFilterDocuments = () => {
    deleteDocument(orderData?.id, memberData?.id, selectedDocument?.id, filterDocuments);
  };

  const updateAndFilterDocuments = async () => {
    await updateDocumentClassifierAction(
      memberId,
      selectedDocument,
      selectedDocument?.id,
      false,
      filterDocuments
    );
  };

  const filterDocuments = () => {
    const filteredDocs = filterById(previouslyUploadedDocs, selectedDocument?.id);
    const newInfo = filterById(apiResponseFromClassifier, selectedDocument?.id);
    const newUploadedFiles = data?.filter(
      (doc: IDocument) => doc?.fileName !== selectedDocument?.fileName
    );
    setSelectedDocument(filteredDocs?.[0]);
    setPreviouslyUploadedDocs(filteredDocs);
    setApiResponseFromClassifier(newInfo);
    setData(newUploadedFiles);

    if (!filteredDocs?.length && !newInfo?.length) {
      toggleModal(false);
    }
  };

  const filterById = (docs: any[], id: string | undefined) => docs?.filter(doc => doc.id !== id);

  //data exchange API
  useEffect(() => {
    const typeStr = `DOCUMENT_TYPE`;
    getDataExchangeByType(typeStr, null, true, (res: any) => {
      const docsList = res?.map((item: any) => item?.values);
      setListOfDocumentTypes(docsList);
    });
  }, []);

  // Pre-select the first item with a documentUrl
  useEffect(() => {
    if (!documentJustReviewed) {
      // Only run this logic if a document was not just reviewed
      const firstItemWithDocumentUrl = apiResponseFromClassifier?.find(
        (item: any) => item?.documentUrl
      );
      if (firstItemWithDocumentUrl) {
        setSelectedDocument(firstItemWithDocumentUrl);
      } else {
        setSelectedDocument(previouslyUploadedDocs?.[0]);
      }
    }

    setDocumentJustReviewed(false);
  }, [apiResponseFromClassifier]);

  useEffect(() => {
    // Extracting previously uploaded docs from orderData
    const linkedMemberIdToFind = memberData?.id;
    const memberSpecificDocs =
      orderData?.uploadedMemberDocuments?.filter(
        (member: IMember) => member.linkedMemberId === linkedMemberIdToFind
      ) || [];

    const previouslyUploadedDocsFromOrder = memberSpecificDocs.flatMap(
      (member: IMember) => member?.uploadedDocuments || []
    );

    const uploadedDocsFromMember =
      memberData?.documents?.filter((doc: any) => doc?.documentUrl) || [];

    const listOfDocuments = [...previouslyUploadedDocsFromOrder, ...uploadedDocsFromMember];

    setPreviouslyUploadedDocs(listOfDocuments);
  }, [orderData?.uploadedMemberDocuments, memberData?.documents]);

  const totalFiles = (previouslyUploadedDocs?.length ?? 0) + (data?.length ?? 0);

  useEffect(() => {
    setSelectedDocument(selectedClassifiedDocument);
  }, [isOpen]);

  const handleCheck = () => {
    const saveBtn: any = document.querySelector('.FIE_topbar-save-button');
    if (saveBtn) {
      saveBtn.click();
    } else {
      handleReviewDocument();
    }
  };

  return (
    <Modal
      show={isOpen}
      dialogClassName='modal-1240w'
      onHide={() => {
        toggleModal(false);
        setData(null);
        setApiResponseFromClassifier(null);
        setSelectedDocument(null);
        setSelectedClassifiedDocument(null);
        setEditedImgBlob(null);
        getOrderById('visa', orderData?.id);
      }}
      backdrop='static'
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {title} {`(${totalFiles} files)`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-0'>
        {data?.length > 0 || previouslyUploadedDocs?.length > 0 ? (
          <Row className='m-0'>
            {selectedDocument ? (
              <Col lg={9} className=' bg-gray-100 py-8 px-8'>
                <div className='d-flex w-100'>
                  <div className='d-flex justify-content-between col me-10 align-items-center'>
                    <div className='col'>
                      <Formik
                        initialValues={{ classification: '' }}
                        onSubmit={() => {}}
                        enableReinitialize>
                        {(formikProps: FormikProps<any>) => {
                          useEffect(() => {
                            if (selectedDocument) {
                              formikProps.setFieldValue(
                                'classification',
                                selectedDocument?.documentType
                              );
                            }
                          }, [selectedDocument]);
                          return (
                            <Form>
                              <Field
                                name='classification'
                                label='Classified as'
                                as={OASelectField}
                                options={listOfDocumentTypes[0]}
                                onChangeData={(value: string) => {
                                  const updatedDocument = {
                                    ...selectedDocument,
                                    documentType: value,
                                    fieldName: value?.trimEnd(),
                                    documentName: value
                                  };
                                  setSelectedDocument(updatedDocument);
                                }}
                                className='bg-white'
                                isDisabled={selectedDocument?.isReviewed}
                                valueOnly
                              />
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                  <div className='d-flex align-items-end'>
                    <OAButton
                      color='light-danger'
                      label={'Delete'}
                      disabled={!selectedDocument?.documentUrl || isReviewing}
                      onClick={handleDeleteDocument}
                      className='btn-icon btn-active-light-primary ms-4 bg-white w-100 p-3 bg-light-danger'
                      rightIcon='/media/icons/duotune/Bin.svg'
                      rightIconHover='/media/icons/duotune/BinSecondary.svg'
                      loadingText='Deleting...'
                      isLoading={isDeleting}
                    />
                    <OAButton
                      color={`${
                        selectedDocument?.isReviewed || selectedDocument?.uploaded
                          ? 'primary'
                          : 'light-success'
                      }`}
                      label={`${
                        selectedDocument?.isReviewed || selectedDocument?.uploaded
                          ? 'Approved'
                          : 'Approve'
                      }`}
                      disabled={!selectedDocument?.documentUrl || isDeleting}
                      isLoading={isReviewing}
                      onClick={
                        selectedDocument?.isReviewed || selectedDocument?.uploaded
                          ? undefined
                          : handleCheck
                      }
                      loadingText='Approving...'
                      className={`btn-icon btn-success ms-4 bg-white w-100 p-3 ${
                        selectedDocument?.isReviewed || selectedDocument?.uploaded
                          ? 'bg-success text-white'
                          : 'bg-light-success'
                      }`}
                      rightIcon={
                        selectedDocument?.isReviewed || selectedDocument?.uploaded
                          ? '/media/icons/duotune/arrows/arr012.svg'
                          : null
                      }
                      rightIconHover={
                        selectedDocument?.isReviewed || selectedDocument?.uploaded
                          ? '/media/icons/duotune/arrows/arr012.svg'
                          : null
                      }
                    />
                  </div>
                </div>
                <div className='mt-4' style={{ height: '500px' }}>
                  <DocumentPreview data={selectedDocument} handleFileUpload={handleFileUpload} />
                </div>
              </Col>
            ) : (
              <LoadingState />
            )}
            <Col lg={3} className='p-0 overflow-auto' style={{ height: '600px' }}>
              <DocumentReviewStatusList
                uploadedFiles={data}
                apiResponse={apiResponseFromClassifier}
                previouslyUploadedDocs={previouslyUploadedDocs}
                handleSelectedDocument={setSelectedDocument}
                selectedDocument={selectedDocument}
              />
            </Col>
          </Row>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default DocumentsReviewModal;
