import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { TripCell, StatusCell } from '../../../components/OAColumn';
import { OATypography } from '../../../components/widgets';
import { getFormattedDateRange } from '../../../helper';
import { ORDER_TYPE } from '../constants';
import { useOrderContext } from '../context/OrderContext';
import { IOrderType } from '../types';
import { ReactComponent as DotSeparatorIcon } from '../../../assets/icons/dot_separator.svg';
import { ReactComponent as CalenderIcon } from '../../../assets/icons/calender.svg';

const GroupOrderDetails = () => {
  const params = useParams<{ orderType: IOrderType; id: string }>();
  const {
    orderData: { data: orderData, isLoading }
  } = useOrderContext();

  const travelDuration = getFormattedDateRange(
    orderData?.groupOrder?.trip?.tripStart?.datetime,
    orderData?.groupOrder?.trip?.tripEnd?.datetime
  );

  return (
    <Box display='flex' flexDirection='column'>
      <Box display='flex' alignItems='center'>
        <OATypography variant='h2' isLoading={isLoading} skeletonProps={{ height: 24, width: 240 }}>
          {orderData?.groupOrder?.groupName}
        </OATypography>
        {params?.orderType === ORDER_TYPE.INSURANCE && (
          <StatusCell status={orderData?.bookingStatus} type={params?.orderType} />
        )}
      </Box>
      <Box display='flex' alignItems='center'>
        <OATypography
          variant='body2'
          color={theme => theme?.palette?.grey?.[400]}
          isLoading={isLoading}
          skeletonProps={{ height: 24, width: 90 }}>
          {orderData?.groupOrder?.id}
        </OATypography>
        <DotSeparatorIcon style={{ margin: '0 10px' }} />
        <Box display='flex' alignItems='center' gap='4px'>
          <CalenderIcon />
          <OATypography
            variant='body2'
            color={theme => theme?.palette?.grey?.[400]}
            isLoading={isLoading}
            skeletonProps={{ height: 24, width: 186 }}>
            {travelDuration}
          </OATypography>
        </Box>
        <DotSeparatorIcon style={{ margin: '0 10px' }} />
        <Box display='flex' alignItems='center' gap='4px'>
          <OATypography
            isLoading={isLoading}
            skeletonProps={{ height: 28, width: 20 }}
            component='div'
            display='flex'>
            <TripCell {...orderData?.groupOrder?.trip} width={'20px'} height={'28px'} />
          </OATypography>
          <Box display='flex' alignItems='center' gap='4px'>
            <OATypography
              variant='body2'
              color={theme => theme?.palette?.grey?.[400]}
              isLoading={isLoading}
              skeletonProps={{ height: 24, width: 120 }}>
              {orderData?.groupOrder?.product?.name}
            </OATypography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GroupOrderDetails;
