import { FC } from 'react';
import { formatNumberForCurrency } from '../../../helper';
import { OATypography } from '../../widgets';

interface IVisaFees {
  visaFee: any[];
}

export const VisaFees: FC<IVisaFees> = ({ visaFee }) => {
  return (
    <table className='classicTable' style={{ width: '200px' }}>
      <thead>
        <tr>
          <th>
            <OATypography variant='h5'>Type</OATypography>
          </th>
          <th>
            <OATypography variant='h5'>Amount(INR)</OATypography>
          </th>
        </tr>
      </thead>
      <tbody>
        {visaFee?.map((obj: any) => (
          <tr key={obj?.type}>
            <td>
              <OATypography variant='body2' sx={{ color: theme => theme?.palette?.grey?.[400] }}>
                {obj?.type}
              </OATypography>
            </td>
            <td>
              <OATypography
                variant='body2'
                sx={{ textAlign: 'right', color: theme => theme?.palette?.grey?.[400] }}>
                {formatNumberForCurrency(obj?.amount)}
              </OATypography>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
