import React from 'react';
import FileRobotEditor from '../../../../../components/OAImageEditor/FileRobotEditor';
import { getFileExtension } from '../../../../../helper';

interface IDocumentPreview {
  data?: any;
  handleFileUpload?: any;
  showCanvasOnly?: boolean;
}

const DocumentPreview: React.FC<IDocumentPreview> = ({
  data,
  handleFileUpload,
  showCanvasOnly
}) => {
  const replaceUrl = (url: any) => {
    if (url?.startsWith('https://storage.googleapis.com')) {
      return url;
    }

    const comIndex = url?.indexOf('.com');
    if (comIndex !== -1) {
      const trimmedUrl = url.substring(0, comIndex + 4);

      // Check for the presence of specific substrings and replace accordingly.
      if (url.includes('onarrival-develop.') || url.includes('onarrival-prod.')) {
        return url.replace(trimmedUrl, process.env.REACT_APP_AWS_CLOUDFRONT_URL_LIVE);
      } else if (url.includes('onarrival-develop-test.') || url.includes('onarrival-prod-test.')) {
        return url.replace(trimmedUrl, process.env.REACT_APP_AWS_CLOUDFRONT_URL_TEST);
      } else if (url.includes('https://onarrival-stage-test.')) {
        return url.replace(trimmedUrl, process.env.REACT_APP_AWS_CLOUDFRONT_URL_STAGE_TEST);
      } else if (url.includes('https://onarrival-stage.')) {
        return url.replace(trimmedUrl, process.env.REACT_APP_AWS_CLOUDFRONT_URL_STAGE_LIVE);
      }
    }
    return url;
  };

  const renderContent = (url: string, fileType: any) => {
    switch (fileType) {
      case 'pdf':
        return <embed className='w-100 h-100' src={`${url}#toolbar=0`} type='application/pdf' />;
      case 'doc':
      case 'docx':
        return (
          <iframe
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`}
            width='100%'
            height='100%'
          />
        );
      case 'png':
      case 'jpeg':
      case 'jpg':
        return (
          <FileRobotEditor
            targetImg={replaceUrl(url)}
            imgFile={replaceUrl(url)}
            fileUploadHandler={handleFileUpload}
            closeEditor={() => {}}
            setIsImgUploading={() => {}}
            showCanvasOnly={showCanvasOnly}
          />
        );
      default:
        return <img className='w-100 object-fit-contain' src={url} />;
    }
  };

  return (
    <section className='rounded-md bg-white h-100 doc-review-editor'>
      {data?.documentUrls
        ? data.documentUrls?.map((documentUrl: any, index: number) =>
            renderContent(documentUrl, getFileExtension(documentUrl))
          )
        : renderContent(data?.documentUrl, getFileExtension(data?.documentUrl))}
    </section>
  );
};

export default DocumentPreview;
