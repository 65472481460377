import React from 'react';
import { Button } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';

import OAToolbar, { IBreadcrumbItem } from '../../components/widgets/OAToolbar';
import ApiKeyCopyToClipboard from './api-key-create/ApiKeyCopyToClipboard';
import OAModalComponent from '../../components/OAModalComponent';
import { useApiKeyContext } from './context/ApiKeyContext';
import ApiKeyProvider from './context/ApiKeyProvider';
import ApiKeyForm from './api-key-create/ApiKeyForm';
import ApiKeyList from './api-key-list';

export default function ApiKeyManagement() {
  return (
    <ApiKeyProvider>
      <ApiKeys />
    </ApiKeyProvider>
  );
}

function ApiKeys() {
  const { onClickCreateApiKey, isOpen, selectedApiKey, toggleModal } = useApiKeyContext();

  const breadcrumb: Array<IBreadcrumbItem> = [
    {
      title: `Dashboard`,
      path: `/dashboard`,
      isActive: false
    },
    {
      title: `Api Keys Management`,
      path: ``,
      isActive: true
    }
  ];

  return (
    <React.Fragment>
      {/**
       * NOTE: Toolbar for api key management
       */}
      <OAToolbar
        title='Api Keys'
        breadcrumb={breadcrumb}
        actionsElement={
          <Button size='sm' onClick={onClickCreateApiKey}>
            Create new API key
          </Button>
        }
      />
      {/**
       * NOTE: Api key list table
       */}
      <ApiKeyList />
      {/**
       * NOTE: Modal for creating and editing api key
       */}
      <OAModalComponent
        size='lg' // NOTE: set modal size
        keyboard={false} // NOTE: disable keyboard event
        backdrop='static' // NOTE: disable backdrop click
        centered // NOTE: vertically center modal
        show={isOpen}
        onHide={toggleModal}
        header={{
          title: `${isEmpty(selectedApiKey) ? `Create` : `Copy`} API key`,
          closeButton: true // NOTE: show close button on header
        }}
        body={{
          content: isEmpty(selectedApiKey) ? (
            <ApiKeyForm data={selectedApiKey} />
          ) : (
            <React.Fragment>
              <ApiKeyCopyToClipboard id={selectedApiKey?.clientSecret} name={'Client Secret'} />
              <ApiKeyCopyToClipboard id={selectedApiKey?.clientId} name={'Client Id'} />
            </React.Fragment>
          )
        }}
      />
    </React.Fragment>
  );
}
