import React from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';

import { useOrderContext } from '../../../order-management/context/OrderContext';
import { ORDER_TYPE } from '../../../order-management/constants';
import PreviewCard from '../../../../components/Common/PreviewCard';
import AdditionalServices from './AdditionalServices';
import ElaborationHeader from './ElaborationHeader';
import AdditionalNotes from './AdditionalNotes';
import DocAndBenefits from './DocAndBenefits';
import NotesAndForms from './NotesAndForms';
import InfoCard from './InfoCard';
import ReviewOrderTable from '../../../order-management/order-create/components/ReviewOrder/ReviewOrderTable';

interface IPreview {
  previewType: 'summary' | 'elaboration';
}

const Preview = (props: IPreview) => {
  const { orderType } = useParams();

  const { orderData } = useOrderContext();

  return (
    <Card className='quotation-summary__card'>
      <Card.Body>
        {orderData?.data ? (
          <React.Fragment>
            {props?.previewType === 'elaboration' && <ElaborationHeader />}
            {orderType === ORDER_TYPE.VISA && <InfoCard previewType='elaboration' />}
            <ReviewOrderTable data={orderData?.data} />
            {props?.previewType === 'elaboration' && (
              <React.Fragment>
                <NotesAndForms />
                <AdditionalServices />
                <DocAndBenefits consulate={orderData?.data?.consulate} />
                <AdditionalNotes />
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <PreviewCard title='Quotation preview' bodyText='Your quote can be previewed here' />
        )}
      </Card.Body>
    </Card>
  );
};

export default Preview;
