import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';

import OAToolbar, { IBreadcrumbItem } from '../../components/widgets/OAToolbar';
import OAModalComponent from '../../components/OAModalComponent';
import UserProvider from './context/UserProvider';
import UserContext from './context/UserContext';
import UserForm from './UserForm';
import UserList from './UserList';

export default function UserManagement() {
  return (
    <UserProvider>
      <Users />
    </UserProvider>
  );
}

function Users() {
  const { isOpen, selectedUser, toggleModal, onClickCreateUser } = useContext(UserContext);

  const breadcrumb: Array<IBreadcrumbItem> = [
    {
      title: `Dashboard`,
      path: `/dashboard`,
      isActive: false
    },
    {
      title: `Users Management`,
      path: ``,
      isActive: true
    }
  ];

  return (
    <React.Fragment>
      {/**
       * NOTE: Toolbar for user management
       */}
      <OAToolbar
        title='Users'
        breadcrumb={breadcrumb}
        actionsElement={
          <Button size='sm' onClick={onClickCreateUser}>
            Create User
          </Button>
        }
      />
      {/**
       * NOTE: User list table
       */}
      <UserList />
      {/**
       * NOTE: Modal for creating and editing user
       */}
      <OAModalComponent
        size='lg' // NOTE: set modal size
        keyboard={false} // NOTE: disable keyboard event
        backdrop='static' // NOTE: disable backdrop click
        centered // NOTE: vertically center modal
        show={isOpen}
        onHide={toggleModal}
        header={{
          title: `${isEmpty(selectedUser) ? `Add` : `Edit`} User`,
          closeButton: true // NOTE: show close button on header
        }}
        body={{
          content: <UserForm data={selectedUser} />
        }}
      />
    </React.Fragment>
  );
}
