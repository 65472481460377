/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';
import { IconButton, MenuItem, Select, TextField } from '@mui/material';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import { isNull } from 'lodash';
import Big from 'big.js';

import { GridColumnHeader } from '../../../../../../components/OATableComponent';
import { DefaultCell } from '../../../../../../components/OAColumn';
import { ReactComponent as CloseIcon } from '../../../../../../assets/icons/close.svg';

export type getInvoiceColumnsType = (
  hasSupplierFee: boolean,
  hasMarkupFee: boolean,
  editable: boolean,
  type: 'proforma' | 'invoice',
  actions: {
    updateCell: (params: any, updatedRow?: any) => void;
    deleteCell: (params: any) => void;
    onAmountCollectedChange?: (e: any) => void;
  }
) => GridColDef[];

export const getInvoiceColumns: getInvoiceColumnsType = (
  hasSupplierFee,
  hasMarkupFee,
  editable,
  type,
  actions
) => {
  const commonProps = {
    sortable: false,
    resizable: false,
    pinnable: false,
    disableColumnMenu: true,
    filterable: false
  };

  let draftColumns: GridColDef[] = [
    {
      field: 'name',
      renderHeader: params => <GridColumnHeader title='Product' params={params} />,
      renderCell: params => {
        return editable && params?.row?.editEnabled?.includes(params?.field) ? (
          <CustomTextField
            value={params?.value}
            updateValue={val => {
              const draftRow = { ...params?.row, name: val };
              actions?.updateCell(params, draftRow);
            }}
            regex={/^[-_()@A-Za-z0-9\s]*$/i}
          />
        ) : (
          <DefaultCell data={params?.value} />
        );
      },
      valueGetter: params => params?.row?.name,
      flex: 1,
      ...commonProps
    },
    {
      field: 'quantity',
      headerAlign: 'center',
      renderHeader: params => <GridColumnHeader title='Qty' params={params} />,
      renderCell: params =>
        editable && params?.row?.editEnabled?.includes(params?.field) ? (
          <CustomTextField
            value={params?.value}
            type='number'
            regex={/^[0-9]+$|^$/i}
            updateValue={val => {
              const draftRow = {
                ...params?.row,
                quantity: val,
                gstAmount: getGstAmount(params, val, params?.row?.gst),
                amount: getTotalAmount(params, val, params?.row?.gst)
              };
              actions?.updateCell(params, draftRow);
            }}
            className='text-center'
            nonEmpty
          />
        ) : (
          <DefaultCell data={params?.value} textCapitalize={false} className='m-auto' />
        ),
      valueGetter: params => params?.row?.quantity,
      width: 75,
      ...commonProps
    },
    {
      field: 'price',
      headerAlign: 'right',
      renderHeader: params => <GridColumnHeader title='Price / Unit' params={params} />,
      renderCell: params => {
        const isSubTotal = params?.row?.id === 'SUBTOTAL';
        const isTotal = params?.row?.id === 'TOTAL';

        let draftText = params?.value;
        if (isTotal && hasSupplierFee && !hasMarkupFee) draftText = `${draftText} (A + B)`;
        if (isTotal && hasSupplierFee && hasMarkupFee) draftText = `${draftText} (A + B + C)`;

        const draftClassNames = classNames('ms-auto', { 'fw-bolder': isTotal || isSubTotal });

        return editable && params?.row?.editEnabled?.includes(params?.field) ? (
          <CustomTextField
            value={params?.value}
            updateValue={val => {
              const { quantity, gst } = params?.row;
              const draftRow = {
                ...params?.row,
                price: val,
                gstAmount: getGstAmount(params, quantity, gst, val),
                amount: getTotalAmount(params, quantity, gst, val)
              };
              actions?.updateCell(params, draftRow);
            }}
            regex={/^(?!0*(\.0)?$)\d+(\.\d{1,2})?$/i}
            className='text-end'
            type='number'
          />
        ) : (
          <DefaultCell data={draftText} className={draftClassNames} />
        );
      },
      valueGetter: params => params?.row?.price,
      width: 125,
      ...commonProps
    },
    {
      field: 'gst',
      headerAlign: 'right',
      renderHeader: params => <GridColumnHeader title='GST' params={params} />,
      renderCell: params => {
        const isSubTotal = params?.row?.id === 'SUBTOTAL';
        const isTotal = params?.row?.id === 'TOTAL';
        const draftClassNames = classNames('ms-auto', { 'fw-bolder': isTotal || isSubTotal });

        return editable &&
          params?.row?.editEnabled?.includes(params?.field) &&
          !isNull(params?.value) ? (
          <Select
            size='small'
            value={params?.value}
            onChange={(e: any) => {
              const { quantity, price } = params?.row;
              const draftRow = {
                ...params?.row,
                gst: e?.target?.value,
                gstAmount: getGstAmount(params, quantity, e?.target?.value, price),
                amount: getTotalAmount(params, quantity, e?.target?.value)
              };
              actions?.updateCell?.(params, draftRow);
            }}
            className='w-100'>
            <MenuItem value='false'>{'None'}</MenuItem>
            <MenuItem value='true'>{'18% GST'}</MenuItem>
          </Select>
        ) : (
          <DefaultCell
            data={params?.value === 'true' ? '18% GST' : isNull(params?.value) ? '' : '--'}
            className={draftClassNames}
          />
        );
      },
      valueGetter: params => params?.row?.gst,
      width: 135,
      ...commonProps
    },
    {
      field: 'gstAmount',
      headerAlign: 'right',
      renderHeader: params => <GridColumnHeader title='GST AMOUNT' params={params} />,
      renderCell: params => {
        const isSubTotal = params?.row?.id === 'SUBTOTAL';
        const isTotal = params?.row?.id === 'TOTAL';
        const draftClassNames = classNames('ms-auto', { 'fw-bolder': isTotal || isSubTotal });

        return isNull(params?.value) ? null : (
          <DefaultCell data={params?.value} className={draftClassNames} />
        );
      },
      valueGetter: params => params?.row?.gstAmount,
      width: 100,
      ...commonProps
    },
    {
      field: 'amount',
      headerAlign: 'right',
      renderHeader: params => <GridColumnHeader title='Total Amount' params={params} />,
      renderCell: params => {
        const isSubTotal = params?.row?.id === 'SUBTOTAL';
        const isTotal = params?.row?.id === 'TOTAL';
        const draftClassNames = classNames('ms-auto', { 'fw-bolder': isTotal || isSubTotal });

        return <DefaultCell data={params?.value} className={draftClassNames} />;
      },
      valueGetter: params => params?.row?.amount,
      width: 120,
      ...commonProps
    }
  ];
  /**
   * NOTE: Amount collected column is only available for proforma invoice...
   */
  if (type === 'proforma') {
    draftColumns.splice(1, 0, {
      field: 'isCollected',
      headerAlign: 'center',
      align: 'center',
      renderHeader: params => <GridColumnHeader title='Amt Collected' params={params} />,
      renderCell: params => {
        return isNull(params?.value) ? null : (
          <div className='form-check form-check-custom form-check-solid form-check-sm ms-3'>
            <Form.Group controlId={`addon_service_${params?.id}`}>
              <Form.Check
                value={params?.row?.id}
                type='checkbox'
                onChange={actions?.onAmountCollectedChange}
                checked={params?.value}
              />
            </Form.Group>
          </div>
        );
      },
      valueGetter: params => (isNull(params?.row?.isCollected) ? null : params?.row?.isCollected),
      width: 120,
      ...commonProps
    });
  }
  /**
   * NOTE: 'action' column is only visible if 'editable' is true...
   */
  if (editable) {
    draftColumns.push({
      field: 'action',
      renderHeader: params => <GridColumnHeader title='' params={params} />,
      renderCell: params => <b>{params?.value}</b>,
      valueGetter: params =>
        editable &&
        params?.row?.editEnabled?.includes(params?.field) && (
          <IconButton aria-label='remove' size='small' onClick={() => actions?.deleteCell(params)}>
            <CloseIcon fontSize='inherit' />
          </IconButton>
        ),
      width: 50,
      ...commonProps
    });
  }

  return draftColumns;
};

const CustomTextField = (props: {
  value: string;
  updateValue: (val: string) => void;
  regex: RegExp;
  className?: string;
  type?: 'number' | 'text';
  nonEmpty?: Boolean;
}) => {
  const { className = '', type = 'text', nonEmpty = false } = props;
  const [val, setVal] = useState<string>(props?.value);

  return (
    <TextField
      size='small'
      type={type}
      placeholder=''
      className='w-100'
      inputProps={{ className }}
      onKeyDown={e => e.stopPropagation()}
      onChange={e => {
        if (props?.regex?.test(e.target.value)) {
          if (nonEmpty) {
            e.target.value.split('').length > 1 && e.target.value.split('')[0] === '0'
              ? setVal(e.target.value.slice(1))
              : setVal(!!e.target.value ? e.target.value : '0');
          } else {
            setVal(e.target.value);
          }
          props?.updateValue(!!e.target.value ? e.target.value : '0');
        } else return;
      }}
      value={val}
    />
  );
};

const getGstAmount = (params: any, quantity: string, gst: string, price?: string) => {
  const draftQuantity = !quantity ? params?.row?.quantity : quantity;
  const draftPrice = !price ? params?.row?.price : price;

  return gst === 'true'
    ? Big(draftPrice).mul(0.18).mul(draftQuantity).toFixed(2)
    : Big(0).toFixed(2);
};

const getTotalAmount = (params: any, quantity: string, gst: string, price?: string) => {
  const draftQuantity = !quantity ? params?.row?.quantity : quantity;
  const draftPrice = !price ? params?.row?.price : price;

  const gstAmount = getGstAmount(params, draftQuantity, gst, price);
  return Big(draftPrice).mul(draftQuantity).plus(gstAmount).toFixed(2);
};
