export const getVisaInformationRequestBody = (values: any) => {
  const draft: any = {
    trip: {
      visaType: values?.visaType,
      origin: {
        state: values?.state,
        countryCode: values?.departure
      },
      destination: [
        {
          countryCode: values?.arrival
        }
      ]
    },
    visa: values?.visa,
    consulate: values?.consulate,
    selectedFields: values?.selectedFields
  };

  if (values?.emails) {
    draft.emails = values.emails;
  }

  return draft;
};
