import { ClaimsDashboard } from './ClaimsDashboard';
import ClaimProvider from './context/ClaimProvider';

export const ClaimsManagement = (props: any) => {
  return (
    <ClaimProvider data={props}>
      <ClaimsDashboard data={props} />
    </ClaimProvider>
  );
};
