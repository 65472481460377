import { useContext } from 'react';
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers';
import OrderContext from '../../../context/OrderContext';
import { DefaultCell } from '../../../../../components/OAColumn';
import OATable from '../../../../../components/OATable';
import { getCurrencySymbol } from '../../../../../helper';

const columns = [
  {
    Header: 'POLICY COVERAGE',
    accessor: 'policyCoverage',
    Cell: ({ ...props }) => {
      const draft = props?.data?.[props?.row?.index]?.name;
      return <DefaultCell data={draft} />;
    }
  },
  {
    Header: 'SUM INSURED (USD)',
    accessor: 'sumInsured',
    Cell: ({ ...props }) => {
      const draft = props?.data?.[props?.row?.index]?.sumInsured;
      return <DefaultCell data={draft ?? 'Nil'} isEllipsis={true} />;
    }
  },
  {
    Header: 'DEDUCTIBLE (USD)',
    accessor: 'deductible',
    Cell: ({ ...props }) => {
      const draft = props?.data?.[props?.row?.index]?.deductible;
      return <DefaultCell data={draft ?? 'Nil'} />;
    }
  }
];
const InsuranceSummary = () => {
  const { orderData } = useContext(OrderContext);
  const {
    data: {
      product: {
        productInformation: { additionalService, benefits, name, sumInsured, sumInsuredCurrency }
      }
    }
  } = orderData;

  const otherBenefits = benefits.filter((item: any) => !item.highlighted);
  const importantBenefits = benefits.filter((item: any) => item.highlighted);

  const renderBenefitCard = (benefit: any) => (
    <div className='col-4 mb-8' key={benefit.name}>
      <div className='content card-rounded border p-3 border-gray-200 h-100'>
        <p className='fs-7 fw-bold text-dark mb-2'>{benefit.name}</p>
        <h4 className='fs-4 fw-bolder text-dark mb-0'>{benefit.sumInsured}</h4>
        <p className='fs-7 fw-bold text-muted mb-0'>
          {benefit.deductible ? `Deductible - ${benefit.deductible}` : 'No deductible'}
        </p>
      </div>
    </div>
  );

  return (
    <>
      {benefits && (
        <KTCard>
          <KTCardBody>
            <div className='d-flex bg-light align-items-center py-6 px-6 card-rounded mb-8'>
              <div className='fw-bold flex-grow-1'>
                <h3 className='mb-0'>{name}</h3>
              </div>
              <div className='d-flex flex-column '>
                <p className='fs-7 text-muted mb-1'>Total sum insured</p>
                <h3 className='mb-0'>
                  {getCurrencySymbol(sumInsuredCurrency)} {sumInsured}
                </h3>
              </div>
            </div>
            {importantBenefits?.length > 0 && (
              <>
                <h4 className='text-dark fw-bolder mb-4'>Important benefits</h4>
                <div className='row row-flex'>{importantBenefits.map(renderBenefitCard)}</div>
              </>
            )}

            {otherBenefits?.length > 0 && (
              <>
                <h4 className='text-dark fw-bolder mb-4'>Other benefits</h4>
                <OATable data={otherBenefits} columns={columns} />
              </>
            )}
          </KTCardBody>
        </KTCard>
      )}

      {additionalService && (
        <KTCard className='mt-10'>
          <KTCardBody>
            <h1 className='fw-bolder mb-3 text-dark fs-4'>{additionalService?.name}</h1>
            {additionalService &&
              additionalService?.services?.map((obj: any, index: any) => (
                <tr key={index}>
                  <td>
                    <div className='fw-bolder fs-6 px-3'>{index + 1}</div>
                  </td>
                  <td>
                    <div className='fw-bolder fs-6 px-1 py-3'>{obj?.name}</div>
                    <div className='form-text mt-0'>{obj?.description}</div>
                    {obj?.addOnServices &&
                      obj?.addOnServices?.map((item: any, index: any) => (
                        <tr key={index}>
                          <td>
                            <li className='fw-bolder fs-6'></li>
                          </td>
                          <td>
                            <div className='fw-bolder fs-6 px-1 py-3'>{item?.name}</div>
                            <div className='form-text mt-0'>{item?.description}</div>
                          </td>
                        </tr>
                      ))}
                  </td>
                </tr>
              ))}
          </KTCardBody>
        </KTCard>
      )}
    </>
  );
};

export default InsuranceSummary;
