import { useEffect, useMemo } from 'react';
import { Card } from 'react-bootstrap';

import OATable from '../../../components/OATable';
import { DefaultCell } from '../../../components/OAColumn';
import { useDashboardContext } from '../context/DashboardContext';
import { getFormattedDate } from '../../../helper';
import { useNavigate } from 'react-router-dom';
import { KTSVG } from '../../../_metronic/helpers';

export default function VisaToBeExpiredTile() {
  const { visaAtRisk, fetchVisaAtRisk } = useDashboardContext();

  useEffect(() => {
    if (!visaAtRisk?.data?.length) {
      fetchVisaAtRisk();
    }
  }, []);

  const data = useMemo(() => getModifiedData(visaAtRisk?.data), [visaAtRisk?.data]);
  const columns = useMemo(() => getColumns(), []);

  return (
    <Card className='dashboard-tiles visa-to-be-expired overflow-hidden h-100'>
      <span className='menu-icon position-absolute top-0 end-0 p-2'>
        <KTSVG path='/media/icons/duotune/Move.svg' className='svg-icon-2' />
      </span>
      <div className='visa-to-be-expired__header-body'>
        <h5 className='text-danger'>Visa at Risk</h5>
        <h3 className='text-danger'>{visaAtRisk?.data?.length || ''}</h3>
      </div>
      <Card.Body className='dashboard-tiles__body py-0'>
        <div className='dashboard-tiles__body--floting shadow-xs mb-9 px-6'>
          <OATable
            data={data}
            columns={columns}
            isLoading={visaAtRisk?.isLoading}
            enableSkeleton={true}
          />
        </div>
      </Card.Body>
    </Card>
  );
}

const getColumns = () => [
  {
    Header: 'Booking Id',
    accessor: 'bookingId',
    Cell: ({ ...props }) => {
      const draft = props?.data?.[props?.row?.index];
      return <DefaultCell data={draft?.bookingId ?? ''} />;
    }
  },
  {
    Header: 'Trip',
    accessor: 'trip',
    Cell: ({ ...props }) => {
      const draft = props?.data?.[props?.row?.index];
      return <DefaultCell data={draft?.trip ?? ''} />;
    }
  },
  {
    Header: 'Expiry Date',
    accessor: 'expiryDate',
    Cell: ({ ...props }) => {
      const draft = props?.data?.[props?.row?.index];
      return <DefaultCell data={draft?.expiryDate ?? ''} />;
    }
  },
  {
    accessor: 'action',
    Cell: ({ ...props }) => {
      const draft = props?.data?.[props?.row?.index];
      const navigateTo = `/apps/orders/visa/${draft?.bookingId}/travel-details`;
      const navigate = useNavigate();

      return (
        <div className='text-end'>
          <div className='dashboard-tiles__btn--arrow ms-auto' onClick={() => navigate(navigateTo)}>
            <span>{'->'}</span>
          </div>
        </div>
      );
    }
  }
];

const getModifiedData = (data: Array<any>) => {
  let draft: Array<any> = [];
  if (data?.length) {
    draft = data?.map((obj: any) => {
      const { origin, destination, tripStart } = obj?.trip;
      return {
        bookingId: obj?.id,
        trip: `${origin?.countryCode} - ${destination?.[0]?.countryCode}`,
        expiryDate: getFormattedDate(tripStart?.datetime)
      };
    });
  }
  return draft;
};
