import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Button, Dropdown } from 'react-bootstrap';

import AddTravellerFormModal from '../../../order-create/components/AddTravellers/AddTravellerFormModal';
import { useOrderContext } from '../../../context/OrderContext';
import TravellerCard from './TravellerCard';
import { ApprovedSVG, ArrowDownSVG, WarningSVG } from '../../../../../components/Icons';
import { IOrderType } from '../../../types';
import { ORDER_TYPE } from '../../../constants';
import { OATypography } from '../../../../../components/widgets';
import { IMember } from '../../../../../types';
import showToast from '../../../../../helper/showToast';

interface ITravellerInfo {
  isEditable: boolean;
  isReviewrequired: boolean;
}

export default function TravellerInfo(props: ITravellerInfo) {
  const params = useParams<{ orderType: IOrderType; id?: string }>();
  const { pathname, hash, key } = useLocation();

  const { orderData, onSelectEditTraveller, getOrderActions, draftOrder } = useOrderContext();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>('all');
  const [membersArr, setMembersArr] = useState<Array<any>>([]);

  useEffect(() => {
    /**
     * NOTE: if not a hash link, scroll to top ...
     */
    if (hash === '') {
      window.scrollTo(0, 0);
    } else {
      /**
       * NOTE: else scroll to specific id ...
       */
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({
            // behavior: 'smooth'
          });
        }
      }, 0);
    }
  }, [pathname, hash, key]);
  /**
   * NOTE: call 'getOrderActions' method to get updated orderActions everytime
   * component did mount...
   */
  useEffect(() => {
    getOrderActions(params?.id!);
  }, []);

  useEffect(() => {
    if (draftOrder && draftOrder?.members?.length !== orderData?.data?.members?.length) {
      setMembersArr(draftOrder?.members);
      return;
    }

    if (orderData?.data?.members?.length) {
      if (selected === 'all') {
        setMembersArr(orderData?.data?.members);
      } else {
        const draftMember = orderData?.data?.members?.filter(
          (member: IMember) => member?.id === selected
        );
        setMembersArr(draftMember);
      }
    }
  }, [draftOrder, selected, orderData?.data?.members]);

  const onClickAddTraveller = () => {
    if (
      draftOrder?.members?.length >= 9 &&
      draftOrder?.supplierOrganization?.organizationCode === 'SUP005'
    ) {
      showToast('You can process only 9 travellers in an order', 'error');
    } else {
      setIsOpen(true);
    }
  };

  if (!orderData?.data?.members?.length) {
    return null;
  }

  return (
    <React.Fragment>
      {membersArr?.map((member: IMember, idx: number) => {
        const className = idx < membersArr?.length - 1 ? 'mb-6' : '';

        return (
          <TravellerCard
            key={idx}
            index={idx}
            data={member}
            className={className}
            toggleModal={() => setIsOpen(prev => !prev)}
            headerComponent={
              idx === 0 ? (
                <TravellerInfoHeader
                  members={orderData?.data?.members}
                  handleAddTraveller={onClickAddTraveller}
                  isEditable={props?.isEditable}
                  selected={selected}
                  handleSelected={val => setSelected(val)}
                />
              ) : null
            }
            isEditable={props?.isEditable}
          />
        );
      })}
      <AddTravellerFormModal
        isOpen={isOpen}
        onHide={() => {
          setIsOpen(false);
          onSelectEditTraveller(null); // NOTE: on close traveller form modal, always clear travellerId.
        }}
        type='single'
      />
    </React.Fragment>
  );
}

// Additional Components ...
interface ITravellerInfoHeader {
  members: Array<any>;
  handleAddTraveller: () => void;
  isEditable: boolean;
  selected: string;
  handleSelected: (val: any) => void;
}
interface IOption {
  memberId: string;
  name: string;
  type?: 'pending' | 'submitted' | null;
}
type IOptions = Array<IOption>;

const TravellerInfoHeader = (props: ITravellerInfoHeader) => {
  const params = useParams<{ orderType: IOrderType; id?: string }>();

  const { formState } = useOrderContext();

  const [options, setOptions] = useState<IOptions>([]);

  useEffect(() => {
    let draftOptions: IOptions = [
      {
        memberId: 'all',
        name: 'All travelers'
      }
    ];
    props?.members?.forEach((member: any) => {
      const isDocumentUploadPending = !member?.documents?.every(
        (doc: any) => doc?.uploaded === true
      );
      const isDocumentSubmitted = member?.documents?.every((doc: any) => doc?.submitted === true);

      draftOptions.push({
        memberId: member?.id,
        name: `${member?.title} ${member?.firstName} ${member?.lastName}`,
        type: isDocumentUploadPending ? 'pending' : isDocumentSubmitted ? 'submitted' : null
      });
    });
    setOptions(draftOptions);
  }, [props?.members]);

  return (
    <div className='card-header align-items-center border-bottom border-grey-200'>
      <OATypography variant='h4'>Traveller information</OATypography>
      <div className='d-flex align-items-center gap-2 gap-lg-3'>
        <Dropdown align='end'>
          <Dropdown.Toggle className='btn-sm btn-secondary bg-gray-700' id='dropdown-custom'>
            {options?.find(option => option?.memberId === props?.selected)?.name}
            <ArrowDownSVG down className='ms-3' width='18' height='18' fill='#616876' />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {options?.map(opt => {
              return (
                <Dropdown.Item
                  key={opt?.memberId}
                  className='menu-item pe-5'
                  onClick={() => {
                    props?.handleSelected(opt?.memberId);
                  }}>
                  <span className='menu-link px-5'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm me-3'>
                      <input
                        className='form-check-input'
                        type='radio'
                        id={opt?.memberId}
                        checked={opt?.memberId === props?.selected}
                      />
                    </div>
                    <span className='menu-text'>{opt?.name}</span>
                    {params?.orderType === ORDER_TYPE.VISA ? (
                      <React.Fragment>
                        {opt?.type === 'pending' && (
                          <WarningSVG width='16' height='16' className='ms-2' />
                        )}
                        {opt?.type === 'submitted' && (
                          <ApprovedSVG width='16' height='16' className='ms-2' />
                        )}
                      </React.Fragment>
                    ) : null}
                  </span>
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        {props?.isEditable &&
        !(formState?.category === 'BUS_INSURANCE' || formState?.category === 'CAB_INSURANCE') ? (
          <Button
            size='sm'
            onClick={props?.handleAddTraveller}
            className='btn-light-primary btn-active-light-primary me-3'>
            Add new traveller
          </Button>
        ) : null}
      </div>
    </div>
  );
};
