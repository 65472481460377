import { isNull } from 'lodash';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useAuth } from '../../../../../auth';

import { MemberCell } from '../../../../../components/OAColumn';
import { AVATAR_COLORS } from '../../../../../constants';
import { openInNewTab } from '../../../../../helper';
import { useOrderContext } from '../../../context/OrderContext';
import VisaStatusUpdateModal from './visaStatusModal';
import InsuranceStatusUpdateModal from './InsuranceStatusUpdateModal';
import StatusChip from './StatusChip';

interface IVisaStatus {
  isEditable: boolean;
  isReviewrequired: boolean;
  isSubmittedToEmbassy?: boolean;
  isOfflineOrder: boolean;
}

export default function VisaStatus(props: IVisaStatus) {
  const { currentUser } = useAuth();
  const { orderData } = useOrderContext();

  const [isVisaStatusUpdateModalOpen, setIsVisaStatusUpdateModalOpen] = useState<boolean>(false);
  const [selectedMember, setSelectedMember] = useState<any>(null);

  const onHideVisaStatusUpdateModal = () => {
    setIsVisaStatusUpdateModalOpen(false);
    setSelectedMember(null);
  };

  const toggleVisaStatusUpdateModal = (memberArr: any) => {
    setIsVisaStatusUpdateModalOpen(true);
    setSelectedMember(memberArr);
  };

  const hasSupplierViewAccess = currentUser?.data?.organization?.type === 'SUPPLIER';
  const orderTitle = orderData?.data?.product?.ancillaryType === 'INSURANCE' ? 'Insurance' : 'Visa';
  return (
    <React.Fragment>
      <div className='visa-status card'>
        <div className='p-3 align-items-center border-0'>
          <h3 className='card-title align-items-start flex-column'>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='text-gray-800 fw-bolder'>
                <span className='fw-bolder text-dark'>{orderTitle} Status</span>
              </div>
              <div>
                <td className='text-muted fw-bold fs-6 text-center'>
                  {props?.isSubmittedToEmbassy &&
                    hasSupplierViewAccess &&
                    orderTitle === 'Visa' && (
                      <Button
                        size='sm'
                        className='btn-light-primary btn-active-light-primary'
                        onClick={() => toggleVisaStatusUpdateModal(orderData?.data?.members)}>
                        Update
                      </Button>
                    )}
                </td>
              </div>
            </div>
          </h3>
        </div>
        <div className='separator' />
        {props?.isOfflineOrder ? (
          <div className='card-body pt-0'>
            <div className='table-responsive'>
              <table className='table table-row-gray-100 align-middle gs-0 gy-1'>
                <tbody>
                  {orderData?.data?.members?.length
                    ? orderData?.data?.members?.map((member: any, index: number) => {
                        const docUrl = member?.downloadLink ?? null;
                        return (
                          <tr key={index}>
                            <div className='d-flex justify-content-between'>
                              <td className='pb-6'>
                                <MemberCell data={member} colorScheme={AVATAR_COLORS[index]} />
                              </td>
                              {props?.isSubmittedToEmbassy ? (
                                <React.Fragment>
                                  <td className='text-muted fw-bold fs-6 pb-6'>
                                    <StatusChip status={member?.status} />
                                  </td>
                                  <td className='text-muted fw-bold fs-6 text-center pb-6'>
                                    {hasSupplierViewAccess && orderTitle === 'Insurance' && (
                                      <Button
                                        size='sm'
                                        className='btn-light-primary btn-active-light-primary'
                                        onClick={() => toggleVisaStatusUpdateModal(member)}>
                                        Update
                                      </Button>
                                    )}
                                    <Button
                                      variant='primary'
                                      size='sm'
                                      className={`${
                                        !isNull(docUrl) ? 'visible' : 'invisible'
                                      } ms-4`}
                                      onClick={() => openInNewTab(docUrl)}>
                                      Download
                                    </Button>
                                  </td>
                                </React.Fragment>
                              ) : (
                                <td className='text-end'>
                                  <StatusChip status={member?.status} />
                                </td>
                              )}
                            </div>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className='card-body pt-0'>
            <p className='d-flex justify-content-center h6'>
              {orderTitle} Status is not available for this Order
            </p>
          </div>
        )}
      </div>
      {orderTitle === 'Visa' ? (
        <VisaStatusUpdateModal
          isOpen={isVisaStatusUpdateModalOpen}
          onHide={onHideVisaStatusUpdateModal}
          member={selectedMember}
        />
      ) : (
        <InsuranceStatusUpdateModal
          isOpen={isVisaStatusUpdateModalOpen}
          onHide={onHideVisaStatusUpdateModal}
          member={selectedMember}
        />
      )}
    </React.Fragment>
  );
}
