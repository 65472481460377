import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, FormikValues, ErrorMessage, Field } from 'formik';
import { Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';

import APITokenLess from '../../helper/APITokenLess';
import { showToast } from '../../helper';

const initialValues = {
  email: ''
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required')
});

export const ForgotPassword: FC = () => {
  let navigate = useNavigate();

  const submitHandler = (values: any, actions: FormikValues) => {
    if (values?.email) {
      try {
        APITokenLess.post(`/api/internal/password/forgot`, values)
          .then(response => {
            if (response.data.httpStatus === 200) {
              navigate(`/auth/reset-password/${values?.email}/${false}`);
            }
          })
          .catch(err => {
            actions.resetForm();
            showToast(err?.response?.data?.errors, 'error');
          });
      } catch (error: any) {
        throw new Error(`API error: ${error?.message}`);
      }
    }
  };

  return (
    <Formik
      validationSchema={forgotPasswordSchema}
      initialValues={initialValues}
      onSubmit={submitHandler}>
      {(formikProps: any) => (
        <Form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' noValidate>
          <div className='text-center mb-10'>
            <h1 className='text-dark mb-3'>Forgot Password ?</h1>
            <div className='text-gray-400 fw-bold fs-4'>
              Enter your email to reset your password.
            </div>
          </div>
          <Row className='mb-10'>
            <Col lg={12}>
              <label className='form-label required'>Email</label>
              <Field
                type='text'
                name='email'
                placeholder='Type here'
                className='form-control form-control-lg form-control-solid'
              />
              <div className='text-danger'>
                <ErrorMessage name='email' />
              </div>
            </Col>
          </Row>
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <Link to='/auth/login'>
              <button
                type='button'
                className='btn btn-lg btn-light-primary fw-bolder me-4'
                disabled={formikProps.isSubmitting || !formikProps.isValid}>
                Cancel
              </button>
            </Link>{' '}
            <button
              type='submit'
              className='btn btn-lg btn-primary fw-bolder'
              disabled={formikProps.isSubmitting}>
              <span className='indicator-label'>
                Submit{' '}
                {formikProps.isSubmitting ? (
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                ) : (
                  ''
                )}
              </span>
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
