interface IDown {
  down: boolean;
  className?: string;
  width?: string;
  height?: string;
  fill?: string;
}

const ArrowDownSVG = (props: IDown) => {
  const { className, down, width = 12, height = 12, fill = '#3A86FF' } = props;
  return (
    <svg
      className={`${className} rotate-180`}
      width={width}
      height={height}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g id='outline/drop-small'>
        {down ? (
          <path
            id='Vector'
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M6.26541 8.13959C6.1951 8.20981 6.09979 8.24926 6.00041 8.24926C5.90104 8.24926 5.80572 8.20981 5.73541 8.13959L1.98541 4.38959C1.91917 4.3185 1.88311 4.22448 1.88482 4.12733C1.88654 4.03018 1.92589 3.93748 1.9946 3.86878C2.06331 3.80007 2.156 3.76072 2.25315 3.759C2.3503 3.75729 2.44432 3.79335 2.51541 3.85959L6.00041 7.34459L9.48541 3.85959C9.51974 3.82274 9.56114 3.79319 9.60714 3.7727C9.65314 3.7522 9.7028 3.74118 9.75315 3.74029C9.8035 3.7394 9.85352 3.74867 9.90021 3.76753C9.9469 3.78639 9.98932 3.81446 10.0249 3.85007C10.0605 3.88568 10.0886 3.9281 10.1075 3.97479C10.1263 4.02148 10.1356 4.0715 10.1347 4.12185C10.1338 4.1722 10.1228 4.22186 10.1023 4.26786C10.0818 4.31386 10.0523 4.35526 10.0154 4.38959L6.26541 8.13959Z'
            fill={fill}
          />
        ) : (
          <path
            id='Vector'
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M5.73459 3.86041C5.8049 3.79019 5.90021 3.75074 5.99959 3.75074C6.09896 3.75074 6.19428 3.79019 6.26459 3.86041L10.0146 7.61041C10.0808 7.6815 10.1169 7.77552 10.1152 7.87267C10.1135 7.96982 10.0741 8.06252 10.0054 8.13122C9.93669 8.19993 9.844 8.23928 9.74685 8.241C9.6497 8.24271 9.55568 8.20665 9.48459 8.14041L5.99959 4.65541L2.51459 8.14041C2.48026 8.17725 2.43886 8.20681 2.39286 8.2273C2.34686 8.2478 2.2972 8.25882 2.24685 8.25971C2.1965 8.26059 2.14648 8.25133 2.09979 8.23247C2.0531 8.21361 2.01068 8.18554 1.97507 8.14993C1.93946 8.11432 1.91139 8.0719 1.89253 8.02521C1.87367 7.97852 1.8644 7.9285 1.86529 7.87815C1.86618 7.8278 1.8772 7.77814 1.8977 7.73214C1.91819 7.68614 1.94774 7.64474 1.98459 7.61041L5.73459 3.86041V3.86041Z'
            fill={fill}
          />
        )}
      </g>
    </svg>
  );
};

export default ArrowDownSVG;
