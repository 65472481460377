import classNames from 'classnames';
import { IconType } from '.';

export default function ViewSVG(props: IconType) {
  const {
    color = '#B5B5C2',
    width = '14',
    height = '14',
    // isActive = false,
    className = ''
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 10 10'
      fill='none'
      className={classNames('icon-svg view-svg', className)}
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.99977 6.19095C5.65764 6.19095 6.19095 5.65764 6.19095 4.99977C6.19095 4.3419 5.65764 3.80859 4.99977 3.80859C4.3419 3.80859 3.80859 4.3419 3.80859 4.99977C3.80859 5.65764 4.3419 6.19095 4.99977 6.19095Z'
        fill={color}
      />
      <path
        opacity='0.3'
        d='M9.44903 4.89812C9.09878 3.99212 8.49073 3.20862 7.70003 2.64444C6.90933 2.08026 5.97064 1.76013 4.99999 1.72363C4.02933 1.76013 3.09065 2.08026 2.29995 2.64444C1.50925 3.20862 0.901198 3.99212 0.550944 4.89812C0.527289 4.96355 0.527289 5.03519 0.550944 5.10062C0.901198 6.00661 1.50925 6.79012 2.29995 7.3543C3.09065 7.91848 4.02933 8.2386 4.99999 8.2751C5.97064 8.2386 6.90933 7.91848 7.70003 7.3543C8.49073 6.79012 9.09878 6.00661 9.44903 5.10062C9.47269 5.03519 9.47269 4.96355 9.44903 4.89812ZM4.99999 6.93503C4.61715 6.93503 4.24291 6.82151 3.92459 6.60881C3.60627 6.39612 3.35818 6.09381 3.21167 5.74011C3.06516 5.38642 3.02683 4.99722 3.10152 4.62174C3.17621 4.24626 3.36056 3.90136 3.63127 3.63065C3.90198 3.35994 4.24688 3.17559 4.62236 3.1009C4.99784 3.02621 5.38704 3.06454 5.74073 3.21105C6.09443 3.35756 6.39674 3.60565 6.60943 3.92397C6.82212 4.24229 6.93565 4.61653 6.93565 4.99937C6.93486 5.5125 6.73067 6.00438 6.36784 6.36722C6.005 6.73005 5.51312 6.93424 4.99999 6.93503Z'
        fill={color}
      />
      {/* <g clip-path="url(#clip0_2649_2906)">
            </g> */}
      {/* <defs>
                <clipPath id="clip0_2649_2906">
                    <rect
                        width="9.52941"
                        height="9.52941"
                        fill="white"
                        transform="translate(0.236328 0.235352)" />
                </clipPath>
            </defs> */}
    </svg>
  );
}
