import { useParams } from 'react-router-dom';

import { useOrderContext } from '../../../context/OrderContext';
import { IOrderType } from '../../../types';
import OAModalComponent from '../../../../../components/OAModalComponent';
import StayDetailsForm from '../../../order-create/visa/AddStayDetails/StayDetailsForm';
import { SUPPLIER_CODE } from '../../../constants';

interface IBookingInfoFormModal {
  show: boolean;
  onHide: () => void;
}

export default function BookingInfoFormModal(props: IBookingInfoFormModal) {
  const params = useParams<{ orderType: IOrderType; id?: string }>();
  const { orderData, getOrderById, onEditDraftOrder } = useOrderContext();

  const showDetailsFor =
    orderData?.data?.supplierOrganizationCode === SUPPLIER_CODE.VFS &&
    orderData?.data?.trip?.destination?.[0]?.countryCode === 'TH'
      ? 'vfs-thai'
      : 'dnata-uae';

  const handleSubmit = (values: any) => {
    onEditDraftOrder(orderData?.data?.id, params?.orderType as IOrderType, values, _res => {
      getOrderById(params?.orderType as IOrderType, orderData?.data?.id, null, () => {
        props?.onHide();
      });
    });
  };

  return (
    <OAModalComponent
      size='lg'
      className='edit-booking-info--modal'
      show={props?.show}
      onHide={props?.onHide}
      header={{
        title: 'Edit Stay Details',
        closeButton: true
      }}
      body={{
        content: (
          <StayDetailsForm
            onHide={props?.onHide}
            onSubmit={handleSubmit}
            value={orderData?.data?.trip?.bookingInformation?.[0]}
            for={showDetailsFor}
          />
        )
      }}
    />
  );
}
