import React from 'react';
import { Formik, Form, FormikProps, ErrorMessage, Field, FormikValues } from 'formik';
import { Button, Col, Container, Row } from 'react-bootstrap';
import * as Yup from 'yup';

import { KTCard, KTCardBody } from '../../_metronic/helpers';
import { PageTitle } from '../../_metronic/layout/core';
import API from '../../helper/API';
import OAToolbar, { IBreadcrumbItem } from '../../components/widgets/OAToolbar';
import { showToast } from '../../helper';
import { useAuth } from '../../auth';

interface IPasswordValues {
  oldPassword: string;
  newPassword: string;
  passwordConfirmation: string;
}

const validationSchema = Yup.object({
  oldPassword: Yup.string().required().label('Old Password'),
  newPassword: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    ),
  passwordConfirmation: Yup.string()
    .required('Password confirmation is required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
});

const initValues: IPasswordValues = {
  oldPassword: '',
  newPassword: '',
  passwordConfirmation: ''
};

export default function ChangePassword() {
  const { logout } = useAuth();
  const onClickSubmit = async (values: any, actions: FormikValues) => {
    const result = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword
    };
    try {
      await API.put(`/users/password`, result);
      showToast('Password changed successfully', 'success');
      logout();
    } catch (err: any) {
      showToast(err?.response?.data, 'error');
    }
  };

  const breadcrumb: Array<IBreadcrumbItem> = [
    {
      title: `Dashboard`,
      path: `/dashboard`,
      isActive: false
    },
    {
      title: `Change Password`,
      path: ``,
      isActive: true
    }
  ];

  return (
    <React.Fragment>
      <OAToolbar title='Change Password' breadcrumb={breadcrumb} />
      <Formik
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={onClickSubmit}
        enableReinitialize>
        {(formikProps: FormikProps<IPasswordValues>) => {
          return (
            <React.Fragment>
              <PageTitle>Change Password</PageTitle>
              <div className='toolbar'>
                <Container fluid className='d-flex flex-stack'>
                  <div className='order-list-tab page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0'></div>
                </Container>
              </div>
              <KTCard>
                <KTCardBody>
                  <Container as={Form} className='user-form'>
                    <Row className='mb-7'>
                      <Col>
                        <label className='form-label required'>Old Password</label>
                        <Field
                          type='password'
                          name='oldPassword'
                          placeholder='Type here'
                          className='form-control form-control-solid'
                        />
                        <div className='text-danger'>
                          <ErrorMessage name='oldPassword' />
                        </div>
                      </Col>
                    </Row>
                    <Row className='mb-7'>
                      <Col>
                        <label className='form-label required'>New Password</label>
                        <Field
                          type='password'
                          name='newPassword'
                          placeholder='Type here'
                          className='form-control form-control-solid'
                        />
                        <div className='text-danger'>
                          <ErrorMessage name='newPassword' />
                        </div>
                      </Col>
                    </Row>
                    <Row className='mb-7'>
                      <Col>
                        <label className='form-label required'>Password Confirmation</label>
                        <Field
                          type='password'
                          name='passwordConfirmation'
                          placeholder='Type here'
                          className='form-control form-control-solid'
                        />
                        <div className='text-danger'>
                          <ErrorMessage name='passwordConfirmation' />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className='text-end'>
                        <Button type='submit' className='ms-2' disabled={!formikProps?.isValid}>
                          {formikProps?.isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </KTCardBody>
              </KTCard>
            </React.Fragment>
          );
        }}
      </Formik>
    </React.Fragment>
  );
}
