import moment from 'moment';

export const getModifiedMembers = (membersArr: Array<any>) => {
  let draft = membersArr?.map(member => {
    let draftMember: any = {
      id: member?.id,
      title: member?.title,
      firstName: member?.firstName,
      lastName: member?.lastName,
      dateOfBirth: moment(new Date(member?.dateOfBirth)),
      gender: member?.gender,
      occupation: member?.occupation,
      phone: member?.phone,
      email: member?.email,
      line1: member?.address?.line1,
      country: member?.address?.country,
      state: member?.address?.state,
      postalCode: member?.address?.postalCode,
      nationality: member?.nationality,
      oldPassportCount: member?.passport?.oldPassportCount,
      primary: member?.primary,
      birthCountry: member?.birthCountry,
      birthPlace: member?.birthPlace,
      fatherName: member?.fatherName,
      maritalStatus: member?.maritalStatus,
      religion: member?.religion,
      profession: member?.profession,
      motherName: member?.motherName
    };

    if (member?.travelDetails?.length) {
      const arrivalData = member?.travelDetails?.find((obj: any) => obj?.travelType === 'ARRIVAL');
      const departureData = member?.travelDetails?.find(
        (obj: any) => obj?.travelType === 'DEPARTURE'
      );
      draftMember = Object.assign({}, draftMember, {
        arrivalCarrierName: arrivalData?.carrierName,
        arrivalCarrierNumber: arrivalData?.carrierNumber,
        arrivalPnrNumber: arrivalData?.pnrNumber,
        arrivalIATA: arrivalData?.iata,
        departureCarrierName: departureData?.carrierName,
        departureCarrierNumber: departureData?.carrierNumber,
        departurePnrNumber: departureData?.pnrNumber,
        departureIATA: departureData?.iata
      });
    }

    if (member?.annualIncome) {
      draftMember.annualIncome = member?.annualIncome;
    }

    if (member?.previousNationality) {
      draftMember.previousNationality = member?.previousNationality;
    }

    if (member?.education) {
      draftMember.education = member?.education;
    }

    if (member?.ticketDetails?.[0]?.ticketNumber) {
      draftMember.ticketNumber = member?.ticketDetails?.[0]?.ticketNumber;
    }

    if (member?.ticketDetails?.[0]?.pnrNumber) {
      draftMember.pnrNumber = member?.ticketDetails?.[0]?.pnrNumber;
    }

    if (member?.passport?.issuingCountry) {
      draftMember.passportIssuingCountry = member?.passport?.issuingCountry;
    }

    if (member?.passport?.issuingPlace) {
      draftMember.passportIssuingPlace = member?.passport?.issuingPlace;
    }

    if (member?.passport?.issuingDate) {
      draftMember.passportIssuingDate = moment.parseZone(member?.passport?.issuingDate).utc();
    }

    if (member?.passport?.number) {
      draftMember.passportNumber = member?.passport?.number;
    }

    if (member?.passport?.expiration) {
      draftMember.passportExpiration = moment.parseZone(member?.passport?.expiration).utc();
    }

    if (member?.documents?.length) {
      // const filteredDocs = member?.documents
      //   ?.filter((doc: any) => !!doc?.documentUrl)
      //   ?.map(({ fieldName, documentUrl, fileExtension, fileName }: any) => ({
      //     fieldName,
      //     data: documentUrl,
      //     fileExtension,
      //     fileName
      //   }));

      draftMember['documents'] = member?.documents;
    }

    if (
      member?.documents?.find((doc: any) => doc?.fieldName === 'PassportFrontImage')?.documentUrl
    ) {
      draftMember.PassportFrontImage = member?.documents?.find(
        (doc: any) => doc?.fieldName === 'Passport Front Image'
      )?.documentUrl;
    }
    if (member?.documents?.find((doc: any) => doc?.fieldName === 'PassportBioPage')?.documentUrl) {
      draftMember.PassportBioPage = member?.documents?.find(
        (doc: any) => doc?.fieldName === 'PassportBioPage'
      )?.documentUrl;
    }
    if (
      member?.documents?.find((doc: any) => doc?.fieldName === 'PassportBackImage')?.documentUrl
    ) {
      draftMember.PassportBackImage = member?.documents?.find(
        (doc: any) => doc?.fieldName === 'Passport Back Image'
      )?.documentUrl;
    }
    if (member?.documents?.find((doc: any) => doc?.fieldName === 'PassportLastPage')?.documentUrl) {
      draftMember.PassportLastPage = member?.documents?.find(
        (doc: any) => doc?.fieldName === 'PassportLastPage'
      )?.documentUrl;
    }
    return draftMember;
  });
  return draft;
};
