import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { isNull } from 'lodash';

import { OrderSectionHeader } from '../../../shared/OrderSectionHeader';
import OAButton from '../../../../../components/widgets/OAButton';
import OAModalComponent from '../../../../../components/OAModalComponent';
import StayDetailsForm from './StayDetailsForm';
// import { useAppContext } from '../../../../../context/AppContext';
import { useOrderContext } from '../../../context/OrderContext';
import OALabelValue from '../../../../../components/widgets/OALabelValue';

interface IAddStayDetails {
  for: 'vfs-thai' | 'dnata-uae';
  // onSubmit: (values: any, type: string) => void;
}

export default function AddStayDetails(props: IAddStayDetails) {
  const { formState, onAddTripBookingInformation } = useOrderContext();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (formState?.tripDetails?.trip?.bookingInformation?.[0]) {
      const { propertyType, propertyName, propertyAddress, propertyPhone } =
        formState?.tripDetails?.trip?.bookingInformation?.[0];
      const dataArr = [
        { label: 'Stay Type', value: propertyType, xs: 3 },
        { label: 'Stay Name', value: propertyName, xs: 3 },
        { label: 'Postal Code', value: propertyAddress?.postalCode, xs: 3 },
        { label: 'City', value: propertyAddress?.city, xs: 3 },
        { label: 'District', value: propertyAddress?.district, xs: 3 },
        { label: 'Sub District', value: propertyAddress?.subDistrict, xs: 3 },
        { label: 'Address', value: propertyAddress?.line1, xs: 6 },
        { label: 'Phone No.', value: propertyPhone, xs: 6 }
      ];
      setData(dataArr);
    }
  }, [formState?.tripDetails?.trip?.bookingInformation]);

  const toggleModal = () => setIsOpen(prev => !prev);

  const renderJSX = isNull(data) ? (
    <div className='w-100 h-50px d-flex flex-center'>
      <span className='text-gray-400 fw-bold fs-7'>No Stay Details have been added yet</span>
    </div>
  ) : (
    <Grid container spacing={2}>
      {data?.map((item: any, index: number) =>
        item?.value ? (
          <Grid item xs={item?.xs} key={index}>
            <OALabelValue label={item?.label} value={item?.value} />
          </Grid>
        ) : null
      )}
    </Grid>
  );

  const bookingInformation = formState?.tripDetails?.trip?.bookingInformation?.[0];

  return (
    <div className='add-stay-details p-6 w-100 mb-6 bg-white rounded'>
      <OrderSectionHeader title='Stay Details' className='align-items-center'>
        <OAButton label={bookingInformation ? 'Edit Stay' : 'Add Stay'} onClick={toggleModal} />
      </OrderSectionHeader>
      <div className='separator mb-6' />
      {renderJSX}

      <OAModalComponent
        size='lg'
        className='add-or-edit-gst--modal'
        show={isOpen}
        onHide={toggleModal}
        header={{
          title: 'Stay Details',
          closeButton: true
        }}
        body={{
          content: (
            <StayDetailsForm
              onHide={toggleModal}
              onSubmit={onAddTripBookingInformation}
              value={bookingInformation}
              for={props?.for}
            />
          )
        }}
      />
    </div>
  );
}
