type Consulate = Array<{
  label: string;
  value: string;
  requiredDocuments: Array<any>;
}>;

const getConsulateOptionsForSelect = (consulateData: Consulate) => {
  if (consulateData?.length > 0) {
    return consulateData?.map((obj: any) => ({
      label: obj?.label,
      value: obj?.value,
      description: obj?.allowedStates
    }));
  } else {
    return [];
  }
};

export default getConsulateOptionsForSelect;
