import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Col, Container, Row } from 'react-bootstrap';
import { KTSVG } from '../../../_metronic/helpers';
import { useEffect, useState } from 'react';

interface IApiKeyCopyToClipboard {
  id: string;
  name: string;
}

const ApiKeyCopyToClipboard = (props: IApiKeyCopyToClipboard) => {
  const { id, name } = props;

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    let timer: any;
    if (copied) {
      timer = setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [copied]);

  return (
    <Container className='mb-5'>
      <Row>
        <Col>
          <div
            className={`border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 ${
              copied ? 'bg-light-success' : ''
            }`}>
            <div className='d-flex align-items-sm-center'>
              <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                <div className='flex-grow-1 me-2'>
                  <span className='text-gray-800 text-hover-primary fs-6 fw-bold'>{id}</span>
                  <span className='text-muted fw-semibold d-block fs-7'>{name}</span>
                </div>
                <span>
                  {copied && <span className='badge badge-success me-5'>Copied!</span>}
                  <CopyToClipboard text={id} onCopy={() => setCopied(true)}>
                    <span className='btn btn-sm btn-icon btn-active-light-primary'>
                      <KTSVG path='/media/icons/duotune/files/fil003.svg' className='svg-icon-2' />
                    </span>
                  </CopyToClipboard>
                </span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ApiKeyCopyToClipboard;
