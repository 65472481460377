import { FC, useRef } from 'react';
import { IOrder } from '../types';
import OAButton from './widgets/OAButton';
import { useCPayAPI } from '../hooks/useCPayAPI';
import { showDialog } from '../helper';
import { debounce } from 'lodash';
import { useParams } from 'react-router-dom';

interface ICPay {
  order: IOrder;
  disabled: boolean;
}

const MERCHANTID = process.env.REACT_APP_CPAY_MERCHANT_ID;
const MERCHANTTOKEN = process.env.REACT_APP_CPAY_MERCHANT_TOKEN;

const CPay: FC<ICPay> = ({ order, disabled }) => {
  const { id } = useParams();
  const { processPayment, isLoading, setIsLoading, sendPaymentReference } = useCPayAPI(order);
  const hasBeenCalled = useRef(false);

  const handleCPayResponse = (response: any) => {
    // Check if the function has already been called
    if (hasBeenCalled.current) {
      return;
    }

    if (response?.booking_status === 'BOOKING_SUCCESS' && order?.id === id) {
      hasBeenCalled.current = true;
      sendPaymentReference(id, response);
    } else {
      setIsLoading(false);
    }
  };

  // const debouncedHandleCPayResponse = debounce(handleCPayResponse, 500);

  const initiateCPay = (cpayOrderId: string) => {
    if (cpayOrderId) {
      var options = {
        merchantId: MERCHANTID,
        merchantToken: MERCHANTTOKEN,
        orderId: cpayOrderId,
        handler: handleCPayResponse
      };

      // @ts-ignore
      var cpay: any = new Cpay(options);
      cpay.open();
    }
  };

  const handleClick = async () => {
    showDialog({
      title: 'Submit Reviewed Order?',
      text: 'Are you sure you want to submit this reviewed order?',
      icon: 'warning'
    }).then(async result => {
      if (result?.isConfirmed) {
        const cpayOrderId = await processPayment();

        if (cpayOrderId) {
          initiateCPay(cpayOrderId);
        }
      }
    });
  };

  return (
    <OAButton
      label={'Submit order'}
      onClick={handleClick}
      isLoading={isLoading}
      loadingText='Please wait..'
      disabled={isLoading || disabled}
    />
  );
};

export default CPay;
