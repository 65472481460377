const sampleData = [
  {
    fieldName: 'orderNumber',
    value: 1
  },
  {
    fieldName: 'tripStartDate',
    value: '20/12/2023'
  },
  {
    fieldName: 'tripEndDate',
    value: '21/12/2023'
  },
  {
    fieldName: 'tripStartTime',
    value: '11:11'
  },
  {
    fieldName: 'tripEndTime',
    value: '11:11'
  },
  {
    fieldName: 'tripStartTimezone',
    value: 'Asia/Kolkata'
  },
  {
    fieldName: 'tripEndTimezone',
    value: 'Asia/Kolkata'
  },
  {
    fieldName: 'originCity',
    value: 'Bangalore'
  },
  {
    fieldName: 'originIataCode',
    value: 'BLR'
  },
  {
    fieldName: 'originCountryCode',
    value: 'IN'
  },
  {
    fieldName: 'destinationCity',
    value: 'Goa'
  },
  {
    fieldName: 'destinationIataCode',
    value: 'GOA'
  },
  {
    fieldName: 'destinationCountryCode',
    value: 'IN'
  },
  {
    fieldName: 'title',
    value: 'Mr'
  },
  {
    fieldName: 'firstName',
    value: 'John'
  },
  {
    fieldName: 'lastName',
    value: 'Doe'
  },
  {
    fieldName: 'dateOfBirth',
    value: '19/10/1999'
  },
  {
    fieldName: 'phone',
    value: '9988776655'
  },
  {
    fieldName: 'email',
    value: 'test@email.com'
  },
  {
    fieldName: 'gender',
    value: 'Male'
  },
  {
    fieldName: 'primaryTraveller',
    value: 'TRUE'
  },
  {
    fieldName: 'nationality',
    value: 'IN'
  },
  {
    fieldName: 'passportIssueDate',
    value: '10/10/2000'
  },
  {
    fieldName: 'passportIssuingDate',
    value: '10/10/2000'
  },
  {
    fieldName: 'passportNumber',
    value: 'T1234567'
  },
  {
    fieldName: 'passportExpiry',
    value: '10/10/2030'
  },
  {
    fieldName: 'passportExpiration',
    value: '10/10/2030'
  },
  {
    fieldName: 'passportIssuingCountry',
    value: 'IN'
  },
  {
    fieldName: 'pnrNumber',
    value: 'ABC12345'
  },
  {
    fieldName: 'ticketNumber',
    value: 'XYZ56789'
  },
  {
    fieldName: 'addressLine',
    value: '123 House'
  },
  {
    fieldName: 'addressState',
    value: 'Karnataka'
  },
  {
    fieldName: 'addressPostalCode',
    value: '560102'
  },
  {
    fieldName: 'bookingReferenceId',
    value: 'OM1234'
  },
  {
    fieldName: 'bookingAmountPerNight',
    value: '100'
  },
  {
    fieldName: 'bookingAmountCurrency',
    value: 'INR'
  },
  {
    fieldName: 'occupation',
    value: 'SELF_EMPLOYED'
  },
  {
    fieldName: 'passportIssuingPlace',
    value: 'Bangalore'
  }
];

const generateSampleCsvData = (fields: string[]) => {
  const row: { [key: string]: any } = {};
  fields.forEach(field => {
    const dataItem = sampleData.find(data => data.fieldName === field);
    row[field] = dataItem ? dataItem.value : '';
  });
  return row;
};

export { generateSampleCsvData };
