import { useOrderContext } from '../../../order-management/context/OrderContext';

interface IDocAndBenefitsProps {
  consulate: string;
}

const DocAndBenefits = (props: IDocAndBenefitsProps) => {
  const {
    orderData: { data }
  } = useOrderContext();

  const requiredDocuments = data?.product?.productInformation?.consulateData?.find(
    (obj: any) => obj?.value === props.consulate
  )?.requiredDocuments;

  const benefits = data?.product?.productInformation?.benefits;
  const sectionTitle = requiredDocuments ? 'Documents required' : 'Plan Benefits';
  const showDocsAndBenefits = requiredDocuments?.length || benefits?.length;

  return showDocsAndBenefits ? (
    <div className='dev--quotation__docs-and-benefits mt-4'>
      <h1 className='fw-bolder mb-3 text-dark fs-4'>{sectionTitle}</h1>
      {data?.product?.productInformation?.disclaimer && (
        <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed py-4 px-4'>
          <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-semibold'>
              <div className='fs-7 text-gray-700'>
                {data?.product?.productInformation?.disclaimer}
              </div>
            </div>
          </div>
        </div>
      )}
      <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4 quotation-doc-table'>
        <tbody>
          {requiredDocuments
            ? requiredDocuments?.map((obj: any, index: any) => (
                <tr key={index}>
                  <td>
                    <div className='fw-bolder fs-6'>{index + 1}</div>
                  </td>
                  <td>
                    <div className='fw-bolder fs-6'>{obj?.documentName}</div>
                    <div className='form-text mt-0'>{obj?.description}</div>
                  </td>
                </tr>
              ))
            : null}
          {benefits
            ? benefits.map((obj: any, index: any) => (
                <tr key={index}>
                  <td>
                    <div className='fw-bolder fs-6'>{index + 1}</div>
                  </td>
                  <td>
                    <div className='fw-bold fs-6'>{obj?.name}</div>
                    <div className='form-text mt-0'>
                      Cover: {obj?.sumInsured} | Deductible: {obj?.deductible}
                    </div>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  ) : null;
};

export default DocAndBenefits;
