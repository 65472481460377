import { Field, Formik, Form, FormikProps } from 'formik';
import { Col, Container, Row } from 'react-bootstrap';
import OAModalComponent from '../../../components/OAModalComponent';
import { OASelectField } from '../../../components/fields';
import { FLIGHT_STATUS } from '../../../constants';
import { useContext, useState } from 'react';
import FlightContext from '../context/FlightContext';
import OAButton from '../../../components/widgets/OAButton';

/**
 * types ...
 */
interface IOrderStatusUpdateModal {
  isOpen: boolean;
  onHide: () => void;
  data: any;
}

export default function FlightOrderStatus(props: IOrderStatusUpdateModal) {
  return (
    <OAModalComponent
      show={props?.isOpen}
      onHide={props?.onHide}
      className='order-status-update__modal'
      header={{
        title: `Update status for ${props?.data?.id}`,
        closeButton: true
      }}
      body={{
        content: <OrderStatusUpdateForm onHide={props?.onHide} data={props?.data} />,
        className: 'order-status-update__modal-body'
      }}
    />
  );
}

/**
 * Additional component : OrderStatusUpdateForm
 */
interface IOrderStatusUpdateForm {
  onHide: () => void;
  data: any;
}

export interface IOrderStatusUpdateFormValues {
  orderStatus: string;
  remark?: string;
}

const init: IOrderStatusUpdateFormValues = {
  orderStatus: '',
  remark: ''
};

const validate = (values: IOrderStatusUpdateFormValues) => {
  const errors: any = {};

  if (!values.orderStatus) {
    errors.orderStatus = 'Required';
  }

  return errors;
};

function OrderStatusUpdateForm(props: IOrderStatusUpdateForm) {
  const { updateStatus } = useContext(FlightContext);
  let updateStatusOptions = FLIGHT_STATUS;
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (values: IOrderStatusUpdateFormValues) => {
    setIsLoading(true);
    let draft = {
      id: props?.data?.id,
      status: values?.orderStatus
    };
    updateStatus(draft, res => {
      props?.onHide();
    });
  };

  return (
    <Formik initialValues={init} onSubmit={handleSubmit} validate={validate}>
      {(formikProps: FormikProps<IOrderStatusUpdateFormValues>) => {
        return (
          <Form className='w-100'>
            <Container fluid className='p-0'>
              {/**
               * NOTE: row contains form fields ...
               */}
              <Row>
                <Col lg={12} className='mb-6'>
                  <Field
                    name='orderStatus'
                    label={'Flight Status'}
                    as={OASelectField}
                    options={updateStatusOptions}
                    valueOnly
                    required
                  />
                </Col>
                {/* <Col lg={12} className='mb-6'>
                  <OATextAreaField name='remark' label='Remarks' placeholder='Type here' />
                </Col> */}
              </Row>
              {/**
               * NOTE: row contains form action buttons ...
               */}
              <Row>
                <Col>
                  <div className='d-flex justify-content-end'>
                    <OAButton
                      label='Cancel'
                      onClick={props?.onHide}
                      disabled={formikProps?.isSubmitting}
                      className='btn-light-primary btn-active-light-primary me-4'
                    />
                    <OAButton
                      label='Submit'
                      type='submit'
                      disabled={
                        (!formikProps?.isValid && formikProps?.dirty) || formikProps?.isSubmitting
                      }
                      className='btn-primary btn-active-primary'
                      isLoading={isLoading}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
}
