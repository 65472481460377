import { Box } from '@mui/material';
import { isNull } from 'lodash';
import { Card } from 'react-bootstrap';
import { OATypography } from '../../../components/widgets';
import { getCountryName } from '../../../helper';
import OAButton from '../../../components/widgets/OAButton';
import OAToggleTab from '../../../components/widgets/OATab';

interface IVisaInformationHeader {
  selectedVisaIndex: number;
  visaInformationValues: any;
  consulateData: Array<any>;
  selectedConsulate: any;
  selectedVisaProduct: any;
  setSelectedConsulate: (val: any) => void;
  toggleInfoEmailModal: () => void;
  newsLength: number;
}

const VisaInformationHeader: React.FC<IVisaInformationHeader> = ({
  selectedVisaIndex,
  visaInformationValues,
  consulateData,
  selectedVisaProduct,
  selectedConsulate,
  setSelectedConsulate,
  toggleInfoEmailModal,
  newsLength
}) => {
  return (
    <Card className={`visa-info__summary ${newsLength > 0 ? 'mt-6' : ''}`}>
      {!isNull(selectedVisaIndex) ? (
        <Card.Header style={{ display: 'block' }}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            sx={{ my: '16px' }}>
            <Box>
              <Box display='flex' alignItems='center'>
                <img
                  style={{ height: '16px' }}
                  alt='country-img'
                  src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${visaInformationValues?.departure}.svg`}
                />
                <OATypography variant='h4' sx={{ ml: '4px' }}>
                  {getCountryName(visaInformationValues?.departure)}
                </OATypography>
                <OATypography variant='h4' sx={{ mx: '4px' }}>
                  -
                </OATypography>
                <img
                  style={{ height: '16px' }}
                  alt='country-img'
                  src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${visaInformationValues?.arrival}.svg`}
                />
                <OATypography variant='h4' sx={{ ml: '4px' }}>
                  {getCountryName(visaInformationValues?.arrival)}
                </OATypography>
              </Box>
              <Box>
                <OATypography variant='body2' sx={{ color: theme => theme?.palette?.grey?.[400] }}>
                  {selectedVisaProduct?.name}
                </OATypography>
              </Box>
            </Box>
            <Box>
              <OAButton color='primary' label='Share info' onClick={() => toggleInfoEmailModal()} />
            </Box>
          </Box>
          {consulateData?.length > 1 && (
            <div className='d-flex align-items-center flex-wrap' style={{ marginTop: '20px' }}>
              <OAToggleTab
                options={consulateData}
                selected={selectedConsulate}
                onSelect={val => setSelectedConsulate(val)}
              />
            </div>
          )}
        </Card.Header>
      ) : null}
    </Card>
  );
};

export default VisaInformationHeader;
