import { TopbarRightMenu } from './TopbarRightMenu';
import { MenuInner } from './MenuInner';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../../auth';
import { isAlsaffronOrg } from '../../../../helper';

const MENU_CLASS_NAME =
  'menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch';

export function HeaderWrapper(props: { mode: string }) {
  const { currentUser } = useAuth();
  const isGuestUser = currentUser?.data?.role === 'GUEST_USER';
  const isAlsaffron = isAlsaffronOrg(currentUser);
  return (
    <div className='header container-fluid'>
      <div className='container-fluid d-flex align-items-center'>
        <div className={isGuestUser ? '' : 'me-8 mb-0'}>
          {isAlsaffron ? (
            <Link to='/'>
              <img
                alt='Logo'
                src='/media/logos/logo-alsaffron.svg'
                className='h-25px d-sm-none d-inline'
              />
              <img
                alt='Logo'
                src='/media/logos/logo-alsaffron.svg'
                className='h-25px d-none d-sm-inline'
              />
            </Link>
          ) : (
            !isGuestUser && (
              <Link to={'/dashboard'}>
                <img
                  alt='Logo'
                  src='/media/logos/logo-small.svg'
                  className='h-25px d-sm-none d-inline'
                />
                <img
                  alt='Logo'
                  src='/media/logos/logo-1.svg'
                  className='h-25px d-none d-sm-inline'
                />
              </Link>
            )
          )}
        </div>
        <div
          className='header-menu'
          data-kt-drawer='true'
          data-kt-drawer-name='header-menu'
          data-kt-drawer-activate='{default: true, lg: false}'
          data-kt-drawer-overlay='true'
          data-kt-drawer-width="{default:'200px', 'auto': 'auto'}"
          data-kt-drawer-direction='end'
          data-kt-drawer-toggle='#kt_header_menu_mobile_toggle'>
          <div className={MENU_CLASS_NAME} data-kt-menu='true'>
            <MenuInner />
          </div>
        </div>
        <div className='ms-auto'>
          <TopbarRightMenu mode={props?.mode} />
        </div>
      </div>
    </div>
  );
}
