import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import CsvErrorToolTip from './CsvErrorToolTip';
import moment, { Moment } from 'moment';
import {
  checkPrimaryTravelerUniqueness,
  convertToDate,
  isAtLeast6monthOld,
  isAtLeastOneYearOld,
  isAtMostSixtyYearOld,
  isPastDate,
  isTripEndDateBeforeStartDate
} from './helper';
import { getFormattedDate } from '../../helper';

export const SAMPLE_FILE = [
  {
    url: 'https://onarrival-develop-test.s3.ap-south-1.amazonaws.com/insurance-csv-files/Insurance-Order-Sample.csv',
    label: 'CSV for standard insurance',
    orderType: 'insurance'
  },
  {
    url: 'https://onarrival-develop-test.s3.ap-south-1.amazonaws.com/insurance-csv-files/insurance-cfar.csv',
    label: 'CSV for CFAR insurance',
    orderType: 'insurance'
  }
];

const fieldsMapping: any = {
  title: 'TITLE',
  gender: 'GENDER',
  occupation: 'OCCUPATION'
};

export const buildColumns = (
  spaces: any,
  dataExchange: any,
  tripStartDateMin?: Moment
): GridColDef[] => {
  return spaces?.columns?.map((obj: any) => {
    const isRequired = obj?.isRequired;
    const requiredIf = obj?.requiredIf;
    const isDateType = obj?.type === 'date';

    // Check if there's a regular expression validator for this column
    const regexValidator = obj?.validator?.find((v: any) => v.type === 'regex');

    // Check if the field is in the mapping and set the valueOptions from dataExchange accordingly
    const valueOptionsKey = fieldsMapping[obj.field];
    const valueOptions = valueOptionsKey ? dataExchange[valueOptionsKey] : obj?.valueOptions;

    const cellClassName = (params: GridCellParams) => {
      // Check if the row has at least one cell with data, excluding the 'id' field
      const hasDataInRow = Object?.keys(params.row)?.some(key => key !== 'id' && params.row[key]);

      if (hasDataInRow) {
        if (isRequired && !params.value) return 'data-grid-error';

        if (requiredIf && obj.requiredIf(params.row) && !params.value) {
          return 'data-grid-error';
        }

        if (params.value && regexValidator && !regexValidator.regex.test(params.value)) {
          return 'data-grid-error';
        }

        // Add this block for singleSelect type validation
        if (obj.type === 'singleSelect') {
          if (valueOptions && !valueOptions.some((vo: any) => vo?.value === params.value)) {
            return 'data-grid-error';
          }
        }

        // Specific Validation
        if (obj.field === 'primaryTraveller') {
          if (!checkPrimaryTravelerUniqueness(spaces?.rows, params.row.orderNumber)) {
            return 'data-grid-error';
          }
        }

        if (obj.field === 'dateOfBirth') {
          const dateValue = params.row[obj.field];
          if (
            ['OAINTATA3000', 'OAINTATA5000']?.includes(spaces?.requestedProductCode) &&
            (!isAtLeast6monthOld(dateValue) || !isAtMostSixtyYearOld(dateValue))
          ) {
            return 'data-grid-error';
          }

          if (
            !['OAINTATA3000', 'OAINTATA5000']?.includes(spaces?.requestedProductCode) &&
            !isAtLeastOneYearOld(dateValue)
          ) {
            return 'data-grid-error';
          }
        }

        if (
          obj.field === 'tripStartDate' ||
          obj.field === 'tripEndDate' ||
          obj.field === 'passportExpiration'
        ) {
          if (isPastDate(params.value)) {
            return 'data-grid-error';
          }
        }

        if (tripStartDateMin && obj.field === 'tripStartDate') {
          const durationFrom = moment(params.value ? String(params.value) : '').startOf('day');
          let isdisabled = tripStartDateMin.isAfter(durationFrom);
          if (isdisabled) {
            return 'data-grid-error';
          }
        }

        if (isDateType && isTripEndDateBeforeStartDate(params, obj.field)) {
          return 'data-grid-error';
        }

        if (obj.field === 'passportIssueDate') {
          if (!isPastDate(params.value)) {
            return 'data-grid-error';
          }
        }
      }

      return '';
    };

    const renderCell = (params: GridCellParams) => {
      let content = params.value ? String(params.value) : '';

      // Check if the row has at least one cell with data, excluding the 'id' field
      const hasDataInRow = Object.keys(params.row).some(key => key !== 'id' && params.row[key]);

      if (hasDataInRow) {
        if (isRequired && !content) {
          return <CsvErrorToolTip title={`${obj?.headerName} Can't be empty`} />;
        }

        if (requiredIf && obj.requiredIf(params.row) && !content) {
          return <CsvErrorToolTip title={obj.requiredIfMessage} />;
        }

        if (content && regexValidator && !regexValidator.regex.test(content)) {
          return <CsvErrorToolTip title={regexValidator.message} value={content} />;
        }

        // Add this block for singleSelect type validation
        if (
          obj.type === 'singleSelect' &&
          !valueOptions.some((vo: any) => vo?.value === params.value)
        ) {
          return (
            <CsvErrorToolTip
              title={`Select a valid option for ${obj?.headerName}`}
              value={content}
            />
          );
        }

        if (obj.field === 'dateOfBirth') {
          const dateObject = moment(content);
          content = dateObject.format('DD/MM/YYYY');
          if (
            ['OAINTATA3000', 'OAINTATA5000']?.includes(spaces?.requestedProductCode) &&
            (!isAtLeast6monthOld(params.value) || !isAtMostSixtyYearOld(params.value))
          ) {
            return (
              <CsvErrorToolTip
                title='Age must be at least 6 months old or at most 60 years old'
                value={content}
              />
            );
          } else if (
            !isAtLeastOneYearOld(params.value) &&
            !['OAINTATA3000', 'OAINTATA5000']?.includes(spaces?.requestedProductCode)
          ) {
            return <CsvErrorToolTip title='Age must be at least 1 year old' value={content} />;
          }
          return content;
        }

        if (
          (obj.field === 'tripStartDate' ||
            obj.field === 'tripEndDate' ||
            obj.field === 'passportExpiration') &&
          isPastDate(content)
        ) {
          const dateObject = moment(content);
          content = dateObject.format('DD/MM/YYYY');
          return <CsvErrorToolTip title="Date can't be in the past" value={content} />;
        }

        if (tripStartDateMin && obj.field === 'tripStartDate') {
          const durationFrom = moment(params.value ? String(params.value) : '').startOf('day');
          let isdisabled = tripStartDateMin.isAfter(durationFrom);
          if (isdisabled) {
            const dateObject = moment(content);
            content = dateObject.format('DD/MM/YYYY');
            return (
              <CsvErrorToolTip
                title={`Visa available if travel duration is after ${getFormattedDate(
                  tripStartDateMin
                )}`}
                value={content}
              />
            );
          }
        }

        if (isDateType) {
          const dateObject = moment(content);
          if (dateObject.isValid()) {
            content = dateObject.format('DD/MM/YYYY');
            if (isTripEndDateBeforeStartDate(params, obj.field))
              return (
                <CsvErrorToolTip
                  title="End date can't be earlier than Start date"
                  value={content}
                />
              );
          }
        }

        // Specific Validation
        if (
          obj.field === 'primaryTraveller' &&
          !checkPrimaryTravelerUniqueness(spaces?.rows, params.row.orderNumber)
        ) {
          return (
            <CsvErrorToolTip title='1 primary traveller mandatory per order' value={content} />
          );
        }

        if (obj.field === 'passportIssueDate') {
          if (!isPastDate(params.value)) {
            return <CsvErrorToolTip title="Date can't be in the future" value={content} />;
          }
        }
      }

      return <span>{content}</span>;
    };

    const valueGetter = isDateType
      ? (params: GridCellParams) => {
          const date = convertToDate(params.row[obj?.field]);
          return date;
        }
      : undefined;

    return {
      field: obj?.field,
      headerName: obj?.field,
      type: obj?.type,
      editable: obj?.editable,
      cellClassName,
      renderCell,
      valueOptions,
      valueGetter
    };
  });
};
