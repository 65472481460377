import { OA_DATE_FORMAT_BE } from '../../../constants';
import { getFormattedDate } from '../../../helper';

export const getItineraryRequest = (values: any) => {
  const param = {
    FunctionName: process.env.REACT_APP_LAMBDA_FUNCTION ?? '',
    InvocationType: 'RequestResponse',
    Payload: JSON.stringify({
      email: values?.email ?? null,
      destinationCountry: `${values?.destinationCountry.split('-')[1].trim()}`,
      originCountry: values?.originCountry ?? null,
      numberOfDays: values?.numberOfDays ?? null,
      intentOfTravel: values?.intentOfTravel ?? null,
      budget: values?.budget ?? null,
      numberOfTravellers: values?.numberOfTravellers ?? null,
      fullName: values?.fullName ?? null,
      occupation: values?.occupation ?? null,
      additionalInformation: values?.additionalInformation ?? null,
      occupationalInformation: values?.occupationInformation ?? null,
      coverLetter: values?.coverLetter ?? false,
      travelDuration: values?.travelDuration,
      orderData: values?.orderData,
      bucketName: values?.bucketName
    })
  };
  return param;
};

export const getDummyFlightRequest = (values: any) => {
  const param = {
    FunctionName: process.env.REACT_APP_DUMMY_FLIGHT_BOOKING ?? '',
    InvocationType: 'RequestResponse',
    Payload: JSON.stringify({
      onwardDepartureCity: values?.onwardDepartureCity,
      onwardArrivalCity: values?.onwardArrivalCity,
      returnDepartureCity: values?.returnDepartureCity,
      returnArrivalCity: values?.returnArrivalCity,
      appointmentDate: values?.appointmentDate
        ? getFormattedDate(values?.appointmentDate, OA_DATE_FORMAT_BE)
        : null,
      orderData: values?.orderData,
      bucketName: values?.bucketName
    })
  };
  return param;
};
