import { FC } from 'react';
import { formatNumberForCurrency } from '../../../helper';
import { OATypography } from '../../widgets';

interface IAddOns {
  addOns: any[];
}

export const AddOns: FC<IAddOns> = ({ addOns }) => {
  return (
    <table className='classicTable' style={{ width: '400px' }}>
      <thead>
        <tr>
          <th>
            <OATypography variant='h5'>Service Name</OATypography>
          </th>
          <th>
            <OATypography variant='h5'>Fees(INR)</OATypography>
          </th>
        </tr>
      </thead>
      <tbody>
        {addOns?.map((obj: any) => (
          <tr key={obj?.label}>
            <td>
              <OATypography variant='body2' sx={{ color: theme => theme?.palette?.grey?.[400] }}>
                {obj?.label}
              </OATypography>
            </td>
            <td>
              <OATypography
                variant='body2'
                sx={{ textAlign: 'right', color: theme => theme?.palette?.grey?.[400] }}>
                {formatNumberForCurrency(obj?.amount)}
              </OATypography>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
