import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Col, Row, Card } from 'react-bootstrap';
import * as Yup from 'yup';

import OASelectField from '../../../components/fields/OASelectField';
import { DepartureCountries } from '../../order-management/constants';
import { useVisaInformationContext } from '../context/VIsaInformationContext';
import { inits } from '../../../types/IVisaInformation';
import OAToolbar, { IBreadcrumbItem } from '../../../components/widgets/OAToolbar';
import { showToast } from '../../../helper';
import VisaInformationView from '../VisaInformationView';
import OALoader from '../../../components/widgets/OALoader';
import { useAuth } from '../../../auth';
import Payment from './Payment';
import emptyState from '../../../assets/images/empty_state_visa_info.svg';
import { useNavigate } from 'react-router-dom';

const validationSchema = Yup.object({
  departure: Yup.string().required().label('Departure'),
  arrival: Yup.string().required().label('Destination')
});

export default function VisaInformationForm() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const ancillaryType = currentUser?.data?.organization?.ancillaryType;
  if (!ancillaryType?.includes('EVISA')) {
    navigate('/error/404');
  }
  const isGuestUser = currentUser?.data?.role === 'GUEST_USER';
  const subscriptionStatus = currentUser?.data?.subscription?.find(
    (obj: any) => obj?.type === 'VISA_INFORMATION'
  )?.status;

  const {
    products,
    visaInformationValues,
    setVisaInformationValues,
    discoverVisaProducts,
    getCountriesList
  } = useVisaInformationContext();

  const [countries, setCountries] = useState<{ data: any; isLoading: boolean }>({
    data: [],
    isLoading: false
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setCountries(prev => ({ ...prev, isLoading: true }));
    getCountriesList(res => {
      const countries = res?.filter((country: any) => Object.keys(country).length !== 0);

      if (isGuestUser && subscriptionStatus !== 'ACTIVE') {
        const selectedValues = ['BR', 'AT', 'BS', 'VN', 'IR', 'CO'];
        const selectedCountries = countries.filter((country: any) =>
          selectedValues.includes(country.value)
        );
        const remainingCountries = countries
          .filter((country: any) => !selectedValues.includes(country.value))
          .map((country: any) => ({ ...country, isDisabled: true }));
        setCountries({
          data: [
            {
              label: 'For trial users',
              options: selectedCountries
            },
            {
              label: 'For paid users',
              options: remainingCountries
            }
          ],
          isLoading: false
        });
      } else {
        setCountries({ data: countries?.length ? countries : [], isLoading: false });
      }
    });
  }, [currentUser]);

  const handleFormSubmit = (value: string) => {
    const values = {
      departure: visaInformationValues.departure || '',
      arrival: value,
      nationality: visaInformationValues.nationality || ''
    };
    setIsLoading(true);
    setVisaInformationValues(values);
    discoverVisaProducts(values, res => {
      if (res?.visas?.length) {
        setIsLoading(false);
        return;
      } else {
        showToast('No data found.', 'error');
      }
      setIsLoading(false);
    });
  };

  const breadcrumb: Array<IBreadcrumbItem> = [
    {
      path: '/dashboard',
      title: 'Dashboard',
      isActive: false
    },
    {
      path: '',
      title: 'Visa information',
      isActive: true
    }
  ];

  return (
    <React.Fragment>
      {isGuestUser && subscriptionStatus !== 'ACTIVE' && <Payment />}
      <OAToolbar title='Visa information search' breadcrumb={breadcrumb} />
      <Card>
        <Card.Body>
          <Formik
            initialValues={visaInformationValues ?? inits}
            validationSchema={validationSchema}
            onSubmit={() => {}}
            enableReinitialize>
            {() => (
              <Form className='visa-info__search-form'>
                <Row className='mb-5'>
                  <Col lg={2}>
                    <Field
                      name='departure'
                      label='Origin'
                      as={OASelectField}
                      options={DepartureCountries}
                      valueOnly
                      required
                      isDisabled={isLoading}
                    />
                  </Col>
                  <Col lg={2}>
                    <Field
                      name='arrival'
                      label='Destination'
                      as={OASelectField}
                      options={countries?.data}
                      valueOnly
                      required
                      isLoading={countries?.isLoading}
                      isDisabled={countries?.isLoading || isLoading}
                      onChangeData={handleFormSubmit}
                    />
                  </Col>
                  <Col lg={2}>
                    <Field
                      name='nationality'
                      label='Nationality'
                      as={OASelectField}
                      options={DepartureCountries}
                      valueOnly
                      isDisabled={true}
                    />
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
      {isLoading ? (
        <div className='d-flex justify-content-center h-50'>
          <OALoader innerComponent />
        </div>
      ) : (
        <div className='mt-6'>
          {products?.data ? (
            <VisaInformationView />
          ) : (
            <div style={{ textAlign: 'center' }}>
              <img src={emptyState} style={{ marginTop: '64px' }} />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
}
