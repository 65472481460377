export interface IVisaInformationFormValues {
  departure: string;
  arrival: string;
  occupation?: string;
  visaType?: string;
  state?: string;
  nationality: string;
}

export interface ISendInformationEmail {
  emails: string;
  phone: string;
}

export interface IVisaInformationProvider {
  children: any;
}

export const inits: IVisaInformationFormValues = {
  departure: 'IN',
  arrival: '',
  nationality: 'IN'
};

export const sendInformationEmail: ISendInformationEmail = {
  emails: '',
  phone: ''
};
