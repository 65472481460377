import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import Big from 'big.js';

import OAButton from '../../../../../../components/widgets/OAButton';
import { getInvoiceColumns } from './columns';
import { OATypography } from '../../../../../../components/widgets';
import { useOrderContext } from '../../../../context/OrderContext';
import { getInvoiceRequestBody } from '../../../../helper/getInvoiceRequestBody';
import { IOrderType } from '../../../../types';
import OIModificationTable from './OIModificationTable';
import OICalculationTable from './OICalculationTable';
import { getCalTableData, getModTableData } from './helper';

interface OrderInvoiceProps {
  data: any;
  handleAmountCollected: (modifier: any) => void;
  onHide?: () => void;
  editable?: boolean;
  type: 'proforma' | 'invoice';
}

export default function OrderInvoice(props: OrderInvoiceProps) {
  const { editable = false } = props;
  const params = useParams<{ orderType: IOrderType; id?: string }>();

  const { generateInvoice, updateInvoice, updateProforma } = useOrderContext();

  const [modData, setModData] = useState<Array<any>>([]); // mod stands for modification
  const [calData, setCalData] = useState<Array<any>>([]); // cal stands for calculation
  const [notes, setNotes] = useState<string>('');
  /**
   * NOTE: if props.data changes, then update modData..
   */
  useEffect(() => {
    if (props?.data) {
      const draftMTD = getModTableData(props?.data, props?.type);
      const draftCTD = getCalTableData(props?.data);
      const draftNotes = props?.data?.invoiceAmount?.remark ?? '';
      setNotes(draftNotes);
      setModData(draftMTD);
      setCalData(draftCTD);
    }
  }, [props?.data]);

  const markupAmt = calData?.find((obj: any) => obj?.type === 'MARKUP_FEE')?.amount;
  const supplierAmt = calData?.find((obj: any) => obj?.type === 'SUPPLIER_FEE')?.amount;
  /**
   * NOTE: if modData or calData changes, then update calData..
   */
  useEffect(() => {
    if (modData?.length && calData?.length) {
      const draftSubTotal = modData
        ?.filter((obj: any) => !!obj?.isCollected)
        ?.reduce((acc: any, curr: any) => {
          const amount = new Big(curr?.amount);
          return Big(acc).plus(amount);
        }, 0)
        .toFixed(2);

      const editableCalData = calData?.filter(
        (obj: any) => obj?.id === 'MARKUP_FEE' || obj?.id === 'SUPPLIER_FEE'
      );

      const draftTotal = editableCalData?.reduce((acc: any, curr: any) => {
        const amount = new Big(curr?.amount);
        return Big(acc).plus(amount);
      }, 0);

      setCalData(prev =>
        prev?.map((obj: any) => {
          if (obj?.id === 'SUBTOTAL') {
            return { ...obj, amount: Big(draftSubTotal).toFixed(2) };
          }
          if (obj?.id === 'TOTAL') {
            return { ...obj, amount: Big(draftTotal).plus(draftSubTotal).toFixed(2) };
          }
          return obj;
        })
      );
    }
  }, [modData, markupAmt, supplierAmt]);

  const { orderAmount } = props?.data;
  const supplierFee = orderAmount?.additionalAmountModifiers?.find(
    (obj: any) => obj?.type === 'SUPPLIER_FEE'
  );
  const markupFee = orderAmount?.additionalAmountModifiers?.find(
    (obj: any) => obj?.type === 'MARKUP_FEE'
  );

  const onAmountCollectedChange = (e: any) => {
    const isChecked = e?.target?.checked;
    const modifierName = e?.target?.value;

    let draft: any = {};
    if (modifierName === 'PRODUCT_FEE' || modifierName === 'VAC_SERVICE_CHARGE') {
      draft = {
        key: modifierName,
        amountCollected: isChecked
      };
    } else {
      draft = {
        key: 'ADD_ON_SERVICE_FEE',
        name: modifierName?.split('-')[1],
        amountCollected: isChecked
      };
    }
    /**
     * NOTE: if it is a proforma invoice and editable(popup opened with editable fields),
     * then update local state (modData) only and calculate modification in frontend
     * only...
     * But, if it is a proforma invoice and non-editable, then call api to update
     * orderAmount of order object in backend and update local state (modData)...
     */
    if (props?.type === 'proforma' && editable) {
      setModData((prev: any) =>
        prev?.map((obj: any) => {
          if (
            obj?.type === draft?.key &&
            (obj?.type === 'PRODUCT_FEE' || obj?.type === 'VAC_SERVICE_CHARGE')
          ) {
            return { ...obj, isCollected: isChecked };
          } else if (obj?.type === draft?.key && obj?.name === draft?.name) {
            return { ...obj, isCollected: isChecked };
          } else {
            return obj;
          }
        })
      );
    } else {
      props?.handleAmountCollected(draft);
    }
  };

  const updateCell = (params: any, updatedRow?: any) => {
    if (['SUPPLIER_FEE', 'MARKUP_FEE']?.includes(updatedRow?.id)) {
      setCalData((prev: any) =>
        prev?.map((obj: any) => (obj?.id === params?.id ? updatedRow : obj))
      );
    } else {
      setModData((prev: any) =>
        prev?.map((obj: any) => (obj?.id === params?.id ? updatedRow : obj))
      );
    }
  };

  const deleteCell = (params: any) => {
    setModData((prev: any) => prev?.filter((obj: any) => obj?.id !== params?.id));
  };

  const columns_mod = useMemo(
    () =>
      getInvoiceColumns(!!supplierFee, !!markupFee, editable, props?.type, {
        updateCell,
        deleteCell,
        onAmountCollectedChange
      }),
    [onAmountCollectedChange]
  );

  const columns_cal = useMemo(
    () =>
      getInvoiceColumns(!!supplierFee, !!markupFee, editable, props?.type, {
        updateCell,
        deleteCell
      }),
    [onAmountCollectedChange]
  );

  const handleAddRow = () => {
    setModData(prev => [
      ...prev,
      {
        id: `OTHERS_${new Date().getTime()}`,
        type: 'OTHERS',
        currency: orderAmount?.currency,
        product: '',
        isCollected: true,
        quantity: 1,
        price: Big(0).toFixed(2),
        gst: false,
        gstAmount: Big(0).toFixed(2),
        amount: Big(0).toFixed(2),
        editEnabled: ['name', 'quantity', 'price', 'gst', 'action']
      }
    ]);
  };

  const handleSubmit = () => {
    /**
     * NOTE: if props?.type === 'proforma', then update proforma...
     */
    if (props?.type === 'proforma') {
      const requestBody = getInvoiceRequestBody(
        {
          data: [...modData, ...calData],
          orderId: params.id!,
          totalAmount: calData?.find((obj: any) => obj?.id === 'TOTAL')?.amount,
          subtotalAmount: calData?.find((obj: any) => obj?.id === 'SUBTOTAL')?.amount
        },
        'proforma'
      );
      updateProforma(requestBody, _res => {
        props?.onHide?.();
      });
    }
    /**
     * NOTE: if props?.type === 'invoice', then add/update invoice...
     */
    if (props?.type === 'invoice') {
      const requestBody = getInvoiceRequestBody(
        {
          data: [...modData, ...calData],
          remark: notes,
          orderId: params.id!,
          totalAmount: calData?.find((obj: any) => obj?.id === 'TOTAL')?.amount,
          subtotalAmount: calData?.find((obj: any) => obj?.id === 'SUBTOTAL')?.amount
        },
        'invoice'
      );
      if (props?.data?.invoiceAmount?.id) {
        updateInvoice(requestBody, props?.data?.invoiceAmount?.id, _res => {
          props?.onHide?.();
        });
      } else {
        generateInvoice(requestBody, _res => {
          props?.onHide?.();
        });
      }
    }
  };

  const submitBtnText = props?.type === 'invoice' ? 'Generate Invoice' : 'Update Proforma';

  return (
    <Box>
      <OIModificationTable
        data={modData}
        columns={columns_mod}
        handleAddRow={handleAddRow}
        editable={props?.editable}
      />
      <OICalculationTable data={calData} columns={columns_cal} />
      {props?.editable && props?.type === 'invoice' ? (
        <Box marginTop='16px'>
          <OATypography marginBottom='4px'>Notes</OATypography>
          <textarea
            rows={3}
            placeholder='Any additional remarks'
            className='form-control form-control-sm form-control-solid'
            style={{ fontSize: '14px' }}
            value={notes}
            onChange={e => setNotes(e?.target?.value)}
          />
        </Box>
      ) : null}
      {props?.editable ? (
        <Box display='flex' justifyContent='end' gap='1rem' paddingTop='1.5rem'>
          <OAButton label='Cancel' color='secondary' onClick={props?.onHide} />
          <OAButton label={submitBtnText} color='primary' onClick={handleSubmit} />
        </Box>
      ) : null}
      {!props?.editable && props?.type === 'invoice' ? (
        <Box
          margin='1.5rem 0'
          padding='1rem'
          borderRadius='6px'
          bgcolor={theme => theme?.palette?.secondary?.light}>
          <OATypography variant='caption' marginBottom='4px'>
            ADDITIONAL NOTES
          </OATypography>
          <OATypography variant='body2'>
            • Any additional service opted by applicant other than selected from us, to be paid
            directly at VAC/VFS
          </OATypography>
          {notes ? <OATypography variant='body2'>• {notes}</OATypography> : null}
        </Box>
      ) : null}
    </Box>
  );
}
