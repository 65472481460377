import moment from 'moment';
import Papa from 'papaparse';

export const CsvColumns = [
  {
    title: 'Standard Travel Insurance',
    category: 'STANDARD_TRAVEL_INSURANCE',
    columns: [
      {
        field: 'orderNumber',
        headerName: 'Order No.',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Order No. must consist of digits only.',
            regex: /^\d+$/
          }
        ]
      },
      {
        field: 'requestedProductCode',
        headerName: 'Product Code',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Product code must only be alphanumeric',
            regex: /^[A-Za-z0-9]+$/
          }
        ]
      },
      {
        field: 'tripStartDate',
        headerName: 'Trip Start Date',
        type: 'date',
        editable: true,
        isRequired: true
      },
      {
        field: 'tripStartTime',
        headerName: 'Trip Start Time',
        type: 'string',
        editable: true,
        requiredIf: (row: any) =>
          !['OAINTATA3000', 'OAINTATA5000']?.includes(row.requestedProductCode),
        requiredIfMessage: "Can't be empty",
        validator: [
          {
            type: 'regex',
            message: 'Enter time as HH:MM',
            regex: /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/
          }
        ]
      },
      {
        field: 'tripEndDate',
        headerName: 'Trip End Date',
        type: 'date',
        editable: true,
        isRequired: true
      },
      {
        field: 'tripEndTime',
        headerName: 'Trip End Time',
        type: 'string',
        editable: true,
        requiredIf: (row: any) =>
          !['OAINTATA3000', 'OAINTATA5000']?.includes(row.requestedProductCode),
        requiredIfMessage: "Can't be empty",
        validator: [
          {
            type: 'regex',
            message: 'Enter time as HH:MM',
            regex: /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/
          }
        ]
      },
      {
        field: 'originCity',
        headerName: 'Origin City',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter valid origin city',
            regex: /^[a-zA-Z\s\-']+$/
          }
        ]
      },
      {
        field: 'originIataCode',
        headerName: 'Origin IataCode',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter valid IATA Code, Ex. DXB, IXJ',
            regex: /^[A-Z]{3}$/
          }
        ]
      },
      {
        field: 'originCountryCode',
        headerName: 'Origin Country Code',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter valid country code, Ex. IN, AE',
            regex: /^[A-Z]{2}$/
          }
        ]
      },
      {
        field: 'destinationCity',
        headerName: 'Destination City',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter valid destination city',
            regex: /^[a-zA-Z\s\-']+$/
          }
        ]
      },
      {
        field: 'destinationIataCode',
        headerName: 'destinationIataCode',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter valid IATA Code, Ex. DXB, IXJ',
            regex: /^[A-Z]{3}$/
          }
        ]
      },
      {
        field: 'destinationCountryCode',
        headerName: 'destinationCountryCode',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter valid country code, Ex. IN, AE',
            regex: /^[A-Z]{2}$/
          }
        ]
      },
      {
        field: 'title',
        headerName: 'title',
        type: 'singleSelect',
        editable: true,
        isRequired: true
      },
      {
        field: 'firstName',
        headerName: 'firstName',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter valid name',
            regex: /^[a-zA-Z\s]*$/
          }
        ]
      },
      {
        field: 'lastName',
        headerName: 'lastName',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter valid last name',
            regex: /^[a-zA-Z\s]*$/
          }
        ]
      },
      {
        field: 'dateOfBirth',
        headerName: 'dateOfBirth',
        type: 'date',
        editable: true,
        isRequired: true
      },
      {
        field: 'phone',
        headerName: 'phone',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter valid 10 digit mobile',
            regex: /^\d{10}$/
          }
        ]
      },
      {
        field: 'email',
        headerName: 'email',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter valid email',
            regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
          }
        ]
      },
      {
        field: 'gender',
        headerName: 'gender',
        type: 'singleSelect',
        editable: true,
        isRequired: true
      },
      {
        field: 'primaryTraveller',
        headerName: 'primaryTraveller',
        type: 'singleSelect',
        editable: true,
        isRequired: true,
        valueOptions: [
          { value: 'TRUE', label: 'TRUE' },
          { value: 'FALSE', label: 'FALSE' }
        ]
      },
      {
        field: 'nationality',
        headerName: 'nationality',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Only IN is allowed',
            regex: /^IN$/
          }
        ]
      },
      {
        field: 'passportNumber',
        headerName: 'passportNumber',
        type: 'string',
        editable: true,
        requiredIf: (row: any) => row.destinationCountryCode !== row?.originCountryCode,
        requiredIfMessage: "Can't be empty since origin and destination are different",
        validator: [
          {
            type: 'regex',
            message: 'Enter valid passport number',
            regex: /^[A-Za-z]{1}-?\d{7}$/
          }
        ]
      },
      {
        field: 'passportExpiry',
        headerName: 'passportExpiry',
        type: 'date',
        editable: true,
        requiredIf: (row: any) => row.passportNumber !== '',
        requiredIfMessage: "Can't be empty"
      },
      {
        field: 'passportIssuingCountry',
        headerName: 'passportIssuingCountry',
        type: 'string',
        editable: true,
        requiredIf: (row: any) => row.passportNumber !== '',
        requiredIfMessage: "Can't be empty",
        validator: [
          {
            type: 'regex',
            message: 'Enter valid country code, Ex. IN, AE',
            regex: /^[A-Z]{2,3}$/
          }
        ]
      },
      {
        field: 'pnrNumber',
        headerName: 'PNR Number',
        type: 'string',
        editable: true
      },
      {
        field: 'ticketNumber',
        headerName: 'Ticket Number',
        type: 'string',
        editable: true
      }
    ]
  },
  {
    title: 'CFAR',
    category: 'CFAR_FOR_HOTEL',
    columns: [
      {
        field: 'orderNumber',
        headerName: 'Order No.',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Order No. must consist of digits only.',
            regex: /^\d+$/
          }
        ]
      },
      {
        field: 'requestedProductCode',
        headerName: 'Product Code',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Product code must only be alphanumeric',
            regex: /^[A-Za-z0-9]+$/
          }
        ]
      },
      {
        field: 'tripStartDate',
        headerName: 'Trip Start Date',
        type: 'date',
        editable: true,
        isRequired: true
      },
      {
        field: 'tripStartTime',
        headerName: 'Trip Start Time',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter time as HH:MM',
            regex: /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/
          }
        ]
      },
      {
        field: 'tripStartTimeZone',
        headerName: 'Trip Start TimeZone',
        type: 'string',
        editable: true,
        isRequired: true
      },
      {
        field: 'tripEndDate',
        headerName: 'Trip End Date',
        type: 'date',
        editable: true,
        isRequired: true
      },
      {
        field: 'tripEndTime',
        headerName: 'Trip End Time',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter time as HH:MM',
            regex: /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/
          }
        ]
      },
      {
        field: 'tripEndTimeZone',
        headerName: 'Trip End TimeZone',
        type: 'string',
        editable: true,
        isRequired: true
      },
      {
        field: 'originCity',
        headerName: 'Origin City',
        type: 'string',
        editable: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter valid origin city',
            regex: /^[a-zA-Z\s\-']+$/
          }
        ]
      },
      {
        field: 'originIataCode',
        headerName: 'Origin IataCode',
        type: 'string',
        editable: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter valid IATA Code, Ex. DXB, IXJ',
            regex: /^[A-Z]{3}$/
          }
        ]
      },
      {
        field: 'originCountryCode',
        headerName: 'Origin Country Code',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter valid country code, Ex. IN, AE',
            regex: /^[A-Z]{2}$/
          }
        ]
      },
      {
        field: 'destinationCity',
        headerName: 'Destination City',
        type: 'string',
        editable: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter valid destination city',
            regex: /^[a-zA-Z\s\-']+$/
          }
        ]
      },
      {
        field: 'destinationIataCode',
        headerName: 'destinationIataCode',
        type: 'string',
        editable: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter valid IATA Code, Ex. DXB, IXJ',
            regex: /^[A-Z]{3}$/
          }
        ]
      },
      {
        field: 'destinationCountryCode',
        headerName: 'destinationCountryCode',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter valid country code, Ex. IN, AE',
            regex: /^[A-Z]{2}$/
          }
        ]
      },
      {
        field: 'title',
        headerName: 'title',
        type: 'singleSelect',
        editable: true,
        isRequired: true
      },
      {
        field: 'firstName',
        headerName: 'firstName',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter valid first name',
            regex: /^[a-zA-Z\s]*$/
          }
        ]
      },
      {
        field: 'lastName',
        headerName: 'lastName',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter valid last name',
            regex: /^[a-zA-Z\s]*$/
          }
        ]
      },
      {
        field: 'dateOfBirth',
        headerName: 'dateOfBirth',
        type: 'date',
        editable: true,
        isRequired: true
      },
      {
        field: 'phone',
        headerName: 'phone',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter valid 10 digit mobile',
            regex: /^\d{10}$/
          }
        ]
      },
      {
        field: 'email',
        headerName: 'email',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter valid email',
            regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
          }
        ]
      },
      {
        field: 'gender',
        headerName: 'gender',
        type: 'singleSelect',
        editable: true,
        isRequired: true
      },
      {
        field: 'primaryTraveller',
        headerName: 'primaryTraveller',
        type: 'singleSelect',
        editable: true,
        isRequired: true,
        valueOptions: [
          { value: 'TRUE', label: 'TRUE' },
          { value: 'FALSE', label: 'FALSE' }
        ]
      },
      {
        field: 'nationality',
        headerName: 'nationality',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Only IN is allowed',
            regex: /^IN$/
          }
        ]
      },
      {
        field: 'passportNumber',
        headerName: 'Passport Number',
        type: 'string',
        editable: true,
        requiredIf: (row: any) => row.destinationCountryCode !== row.originCountryCode,
        requiredIfMessage: "Can't be empty since origin and destination are different",
        validator: [
          {
            type: 'regex',
            message: 'Enter valid passport number',
            regex: /^[A-Za-z]{1}-?\d{7}$/
          }
        ]
      },
      {
        field: 'passportExpiry',
        headerName: 'passportExpiry',
        type: 'date',
        editable: true,
        requiredIf: (row: any) => row.passportNumber !== '',
        requiredIfMessage: "Can't be empty"
      },
      {
        field: 'passportIssuingCountry',
        headerName: 'passportIssuingCountry',
        type: 'string',
        editable: true,
        requiredIf: (row: any) => row.passportNumber !== '',
        requiredIfMessage: "Can't be empty",
        validator: [
          {
            type: 'regex',
            message: 'Enter valid country code, Ex. IN, AE',
            regex: /^[A-Z]{2,3}$/
          }
        ]
      },
      {
        field: 'bookingReferenceId',
        headerName: 'Booking ReferenceId',
        type: 'string',
        editable: true,
        isRequired: true
      },
      {
        field: 'bookingAmountPerNight',
        headerName: 'Booking Amount PerNight',
        type: 'number',
        editable: true,
        isRequired: true
      },
      {
        field: 'bookingAmountCurrency',
        headerName: 'Booking Amount Currency',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter valid currency, Ex. INR, USD',
            regex: /^[A-Z]{2,3}$/
          }
        ]
      }
    ]
  },
  {
    title: 'Visa Orders',
    category: 'VISA',
    columns: [
      {
        field: 'orderNumber',
        headerName: 'Order No.',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Order No. must consist of digits only.',
            regex: /^\d+$/
          }
        ]
      },
      {
        field: 'tripStartDate',
        headerName: 'Trip Start Date',
        type: 'date',
        editable: true,
        isRequired: true
      },
      {
        field: 'tripStartTimezone',
        headerName: 'Trip Start TimeZone',
        type: 'string',
        editable: true,
        isRequired: true
      },
      {
        field: 'tripEndDate',
        headerName: 'Trip End Date',
        type: 'date',
        editable: true,
        isRequired: true
      },
      {
        field: 'tripEndTimezone',
        headerName: 'Trip End TimeZone',
        type: 'string',
        editable: true,
        isRequired: true
      },
      {
        field: 'title',
        headerName: 'title',
        type: 'singleSelect',
        editable: true,
        isRequired: true
      },
      {
        field: 'firstName',
        headerName: 'firstName',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter valid name',
            regex: /^[a-zA-Z\s]*$/
          }
        ]
      },
      {
        field: 'lastName',
        headerName: 'lastName',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter valid last name',
            regex: /^[a-zA-Z\s]*$/
          }
        ]
      },
      {
        field: 'dateOfBirth',
        headerName: 'dateOfBirth',
        type: 'date',
        editable: true,
        isRequired: true
      },
      {
        field: 'email',
        headerName: 'email',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter valid email',
            regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
          }
        ]
      },
      {
        field: 'gender',
        headerName: 'gender',
        type: 'singleSelect',
        editable: true,
        isRequired: true
      },
      {
        field: 'phone',
        headerName: 'phone',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter valid 10 digit mobile',
            regex: /^\d{10}$/
          }
        ]
      },
      {
        field: 'occupation',
        headerName: 'Occupation',
        type: 'singleSelect',
        editable: true,
        isRequired: true
      },
      {
        field: 'nationality',
        headerName: 'Nationality',
        type: 'string',
        editable: true,
        isRequired: true
      },
      {
        field: 'passportIssuingCountry',
        headerName: 'Passport Issuing Country',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter valid country code, Ex. IN, AE',
            regex: /^[A-Z]{2,3}$/
          }
        ]
      },
      {
        field: 'passportIssuingPlace',
        headerName: 'Passport Issuing Place',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter valid origin city',
            regex: /^[a-zA-Z\s\-']+$/
          }
        ]
      },
      {
        field: 'passportNumber',
        headerName: 'Passport Number',
        type: 'string',
        editable: true,
        isRequired: true,
        validator: [
          {
            type: 'regex',
            message: 'Enter valid passport number',
            regex: /^[A-Za-z]{1}-?\d{7}$/
          }
        ]
      },
      {
        field: 'passportIssueDate',
        headerName: 'Passport Issue Date',
        type: 'date',
        editable: true,
        isRequired: true
      },
      {
        field: 'passportExpiration',
        headerName: 'Passport Expiration',
        type: 'date',
        editable: true,
        isRequired: true
      },
      {
        field: 'addressLine',
        headerName: 'Address Line',
        type: 'string',
        editable: true,
        isRequired: true
      },
      {
        field: 'addressState',
        headerName: 'Address State',
        type: 'string',
        editable: true,
        isRequired: true
      },
      {
        field: 'addressPostalCode',
        headerName: 'Address Postal Code',
        type: 'string',
        editable: true,
        isRequired: true
      },
      {
        field: 'primaryTraveller',
        headerName: 'primaryTraveller',
        type: 'singleSelect',
        editable: true,
        isRequired: true,
        valueOptions: [
          { value: 'TRUE', label: 'TRUE' },
          { value: 'FALSE', label: 'FALSE' }
        ]
      }
    ]
  }
];

export const parseCSVFile = (
  file: File,
  orderType: 'insurance' | 'visa',
  callback: (dataWithIds: any[]) => void
) => {
  Papa.parse(file, {
    header: true,
    skipEmptyLines: true,
    complete: function (results) {
      if (results.data?.length > 0) {
        const dataWithIds = results.data.map((row: any, index) => {
          if (row?.tripStartDate) row.tripStartDate = convertToDate(row.tripStartDate);
          if (row?.tripEndDate) row.tripEndDate = convertToDate(row.tripEndDate);
          if (row?.dateOfBirth) row.dateOfBirth = convertToDate(row.dateOfBirth);
          if (row?.passportExpiry) row.passportExpiry = convertToDate(row.passportExpiry);
          if (row?.passportExpiration)
            row.passportExpiration = convertToDate(row.passportExpiration);
          if (row?.passportIssueDate) row.passportIssueDate = convertToDate(row.passportIssueDate);
          // if (row?.nationality && orderType === 'insurance') row.nationality = 'IN';
          return {
            id: index + 1,
            ...row
          };
        });
        callback(dataWithIds);
      } else {
        console.error('Fields and columns are not of the same length');
      }
    }
  });
};

export const unparseCSVFile = (spaces: any, callback: (csvFile: File) => void) => {
  // Transform the JSON object into an array of rows
  const rows = spaces.rows
    .filter((row: any) => {
      // Check if only the 'id' field is available
      const keysWithoutId = Object.keys(row).filter(key => key !== 'id');
      return keysWithoutId.some(key => row[key]);
    })
    .map((row: any) => {
      const filteredRow = { ...row };
      delete filteredRow.id; // Exclude the 'id' field
      // Convert date fields to DD/MM/YYYY format using moment.js
      for (const column of spaces.columns) {
        if (column.type === 'date' && filteredRow[column.field]) {
          filteredRow[column.field] = moment(filteredRow[column.field]).format('DD-MM-YYYY');
        }
      }

      return filteredRow;
    });

  // Convert to CSV
  const csv = Papa.unparse({
    fields: spaces.columns
      .map((column: any) => column.field)
      .filter((field: any) => field !== 'id'),
    data: rows
  });

  // Convert to Blob if you need to send as a file
  const csvBlob = new Blob([csv], { type: 'text/csv' });
  // Create a file from the CSV Blob
  const csvFile = new File([csvBlob], 'edited_data.csv');

  callback(csvFile);
};

export const createEmptyRows = (count: number) => {
  return Array(count)
    .fill({})
    .map((_, index) => ({ id: index + 1 }));
};

export const checkPrimaryTravelerUniqueness = (data: any[], orderNumber: any) => {
  const matchingRows = data.filter(row => row.orderNumber === orderNumber);
  const primaryTravellers = matchingRows.filter(row => {
    return row.primaryTraveller === 'TRUE';
  });
  return primaryTravellers.length === 1; // True if there's only one or zero primary traveler(s)
};

export const convertToDate = (inputDate: string) => {
  const formats = [
    'YYYY-MM-DD',
    'YYYY/MM/DD',
    'DD-MM-YYYY',
    'DD/MM/YYYY',
    'YYYY/M/D',
    'YYYY/MMM/DD',
    'DD/MMM/YYYY',
    'MMM/DD/YYYY',
    'MM/DD/YY',
    'DD/MM/YY',
    'YY/MM/DD',
    'DD-MM-YY',
    'D-M-YY',
    'D/M/YY'
  ];

  for (const format of formats) {
    const date = moment(inputDate, format, true);
    if (date.isValid()) {
      return date.toDate(); // Return a Date object
    }
  }

  return null; // Return the original value if no format matches
};

const mergeDateAndTime = (date: any, time: string) => {
  const momentDate = moment(date);
  if (time) {
    const [hours, minutes] = time.split(':').map(Number);
    momentDate.hours(hours);
    momentDate.minutes(minutes);
  }
  return momentDate;
};

export const isTripEndDateBeforeStartDate = (params: any, field: string) => {
  if (field === 'tripEndDate') {
    const tripStartDateString = params?.row?.tripStartDate;
    const tripEndDateString = params?.row?.tripEndDate;
    const tripStartTime = params?.row?.tripStartTime;
    const tripEndTime = params?.row?.tripEndTime;

    if (tripStartDateString && tripEndDateString) {
      const tripStartDate = mergeDateAndTime(tripStartDateString, tripStartTime);
      const tripEndDate = mergeDateAndTime(tripEndDateString, tripEndTime);

      return tripEndDate.isBefore(tripStartDate);
    }
  }
  return false;
};

export const getRowsWithErrors = (rows: any, columns: any) => {
  let isRowEmpty = true;

  if (rows) {
    for (const row of rows) {
      const keys = Object.keys(row);
      const hasNonEmptyAdditionalKey = keys.some(
        key => key !== 'id' && row[key] !== '' && row[key] != null
      );
      if (hasNonEmptyAdditionalKey) {
        isRowEmpty = false;
        break;
      }
    }
  }

  const errorRows = rows.reduce((errorRowsAccumulator: any, row: any) => {
    const hasError = columns.some((column: any) => {
      const cellValue = row[column.field];
      const params = { row, value: cellValue };
      return column.cellClassName && column.cellClassName(params) === 'data-grid-error';
    });

    if (hasError) {
      errorRowsAccumulator.push(row.id);
    }

    return errorRowsAccumulator;
  }, []);

  return { errorRows, isRowEmpty };
};

export const isPastDate = (date: any) => {
  const dateObject = moment(date);
  return dateObject.isBefore(moment(), 'day');
};

export const isAtLeastOneYearOld = (date: any) => {
  const dateObject = moment(date);
  return moment().diff(dateObject, 'years') >= 1;
};

export const isAtLeast6monthOld = (date: any) => {
  const dateObject = moment(date);
  return Math.abs(moment().diff(dateObject, 'months')) >= 6;
};

export const isAtMostSixtyYearOld = (date: any) => {
  const dateObject = moment(date);
  return Math.abs(moment().diff(dateObject, 'years')) <= 60;
};
