import { Form, Formik, FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import * as Yup from 'yup';

import OATextField from '../../../../../components/fields/OATextField';
import OAButton from '../../../../../components/widgets/OAButton';

interface IAddOrEditGstFormProps {
  onHide: () => void;
  onGstSubmit: (val?: any) => void;
  value: string | number;
}

interface IAddGstNumber {
  taxRegistrationNumber: string | number;
}

const init: IAddGstNumber = {
  taxRegistrationNumber: ''
};

const gstNumberValidator = Yup.object({
  taxRegistrationNumber: Yup.string().matches(
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
    'GST number is not valid'
  )
});

export default function AddOrEditGstForm(props: IAddOrEditGstFormProps) {
  const [initValues, setInitValues] = useState<IAddGstNumber>(init);

  useEffect(() => {
    setInitValues(props?.value ? { taxRegistrationNumber: props?.value } : init);
  }, [props?.value]);

  const handleSubmitGst = (values: IAddGstNumber) => {
    const draft = {
      taxInfo: {
        type: 'GST',
        taxRegistrationNumber: values?.taxRegistrationNumber
      }
    };
    props?.onGstSubmit(draft);
    setInitValues(init);
  };

  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleSubmitGst}
      validationSchema={gstNumberValidator}
      enableReinitialize>
      {(formikProps: FormikProps<IAddGstNumber>) => (
        <Container fluid as={Form} className='p-0'>
          <Row className='mb-8'>
            <Col>
              <OATextField name='taxRegistrationNumber' label='GST Number' />
            </Col>
          </Row>
          <Row>
            <Col className='text-end'>
              <OAButton
                label='Cancel'
                onClick={() => {
                  formikProps?.resetForm();
                  props?.onHide();
                }}
                color='light-primary'
              />
              <OAButton type='submit' label='Save' color='primary' className='ms-3' />
            </Col>
          </Row>
        </Container>
      )}
    </Formik>
  );
}
