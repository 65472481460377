import { FC } from 'react';
import { formatWordWithUnderscore } from '../../helper';
import { ITask } from '../../types';
import OAChip from '../Base/OAChip';
import { Box } from '@mui/material';
import { useOrderContext } from '../../pages/order-management/context/OrderContext';
import { useAuth } from '../../auth';

interface ITagsDisplay {
  task: ITask;
}

const addSpacesAndCapitalize = (str: string) => {
  return str
    ?.replace(/([A-Z])/g, ' $1')
    ?.trim()
    ?.replace(/^./, firstLetter => firstLetter.toUpperCase());
};

const TagsDisplay: FC<ITagsDisplay> = ({ task }) => {
  const { status, missingFields, memberDocument, createdByUser } = task || {};
  const { currentUser } = useAuth();
  const { orderData } = useOrderContext();
  const { id: currentUserId } = currentUser?.data;

  /**
   * NOTE: if logged in user's org id matches order's supplierOrganization id then
   * logged in user can be considered as a supplier and can perform all the supplier's
   * actions...
   */
  const hasSupplierActionAccess =
    currentUser?.data?.organizationId === orderData?.data?.supplierOrganization?.id;

  const isUserCreatedTask = createdByUser?.id === currentUserId;

  let statusLabel = formatWordWithUnderscore(status) ?? '';
  let statusColor: 'primary' | 'success' | 'error' = 'primary';
  if (status === 'COMPLETED') {
    statusColor = 'success';
  } else if (!hasSupplierActionAccess && !isUserCreatedTask && status === 'CHANGES_REQUESTED') {
    statusLabel = 'Pending task';
    statusColor = 'error';
  }

  return (
    <Box display='flex' alignItems='center' flexWrap='wrap'>
      <OAChip
        label={statusLabel}
        color={statusColor}
        sx={{ mr: '8px', mt: '8px', fontSize: '12px', fontWeight: 500, p: '4px 0' }}
      />
      {missingFields?.length > 0 &&
        missingFields?.map((field: any, index: number) => (
          <OAChip
            key={index}
            label={addSpacesAndCapitalize(field?.label)}
            color='info'
            sx={{ mr: '8px', mt: '8px', fontSize: '12px', fontWeight: 500, p: '4px 0' }}
          />
        ))}
      {memberDocument && (
        <OAChip
          label={addSpacesAndCapitalize(memberDocument?.documentName)}
          color='info'
          sx={{ mr: '8px', mt: '8px', fontSize: '12px', fontWeight: 500, p: '4px 0' }}
        />
      )}
    </Box>
  );
};

export default TagsDisplay;
