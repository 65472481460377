import { Theme } from '@mui/material';
import { ReactComponent as AppliedIcon } from '../assets/icons/status_applied.svg';
import { ReactComponent as ApprovedIcon } from '../assets/icons/status_approved.svg';
import { ReactComponent as DraftIcon } from '../assets/icons/status_draft.svg';
import { ReactComponent as ExpiredIcon } from '../assets/icons/status_expired.svg';
import { ReactComponent as PendingIcon } from '../assets/icons/status_pending.svg';
import { ReactComponent as ProcessingIcon } from '../assets/icons/status_processing.svg';
import { ReactComponent as RejectedIcon } from '../assets/icons/status_rejected.svg';
import { ReactComponent as ReviewIcon } from '../assets/icons/status_review.svg';
import { ReactComponent as ActiveIcon } from '../assets/icons/status_active.svg';
import { ReactComponent as AwaitingIcon } from '../assets/icons/status_awaiting.svg';
import { ReactComponent as QuoteIcon } from '../assets/icons/status_quote.svg';
import { ReactComponent as RupeeIcon } from '../assets/icons/status_payment_init.svg';
import { ReactComponent as BankIcon } from '../assets/icons/status_bank.svg';
import { ReactComponent as GlobeIcon } from '../assets/icons/globeIcon.svg';
import { ReactComponent as ExclaimationIcon } from '../assets/icons/exclaimation.svg';

export const getStatusIcon = (status: string) => {
  switch (status) {
    case 'ORDER_DRAFT':
      return <DraftIcon />;
    case 'ORDER_INITIATED':
      return <ProcessingIcon />;
    case 'ORDER_SUBMIT_INITIATED':
      return <ProcessingIcon />;
    case 'ORDER_SUBMIT':
      return <ProcessingIcon />;
    case 'ORDER_PROCESSING':
      return <ProcessingIcon />;
    case 'IN_REVIEW':
      return <ReviewIcon />;
    case 'PENDING_ACTION':
      return <ReviewIcon />;
    case 'ORDER_SUBMIT_IN_REVIEW':
      return <ReviewIcon />;
    case 'ORDER_SUBMIT_REVIEW_IN_PROGRESS':
      return <ReviewIcon />;
    case 'CANCELLED':
      return <RejectedIcon />;
    case 'BOOKING_CANCELLED':
      return <RejectedIcon />;
    case 'REJECTED':
      return <RejectedIcon />;
    case 'ORDER_EXPIRED':
      return <ExpiredIcon />;
    case 'ORDER_FAILED':
      return <ExpiredIcon />;
    case 'ORDER_SUBMIT_FAILED':
      return <ExpiredIcon />;
    case 'ORDER_UPLOAD_FAILED':
      return <ExpiredIcon />;
    case 'DISPATCHED':
      return <ApprovedIcon />;
    case 'PARTIAL_APPROVED':
      return <ApprovedIcon />;
    case 'APPROVED':
      return <ApprovedIcon />;
    case 'COMPLETED':
      return <ApprovedIcon />;
    case 'VISA_APPLICATION_APPROVED':
      return <ApprovedIcon />;
    case 'APPLIED_AT_PORTAL':
      return <AppliedIcon />;
    case 'APPLIED_AT_VAC':
      return <AppliedIcon />;
    case 'PENDING_TASK':
      return <PendingIcon />;
    case 'RESUBMIT_ORDER':
      return <PendingIcon />;
    case 'CANCEL_REQUEST':
      return <PendingIcon />;
    case 'ORDER_CANCELLATION_REQUESTED':
      return <PendingIcon />;
    case 'ISSUANCE_FAILED':
      return <PendingIcon />;
    case 'AWAITING_CUSTOMER':
      return <AwaitingIcon />;
    case 'AWAITING_VISA_UPDATE':
      return <AwaitingIcon />;
    case 'ORDER_QUOTE':
      return <QuoteIcon />;
    case 'CREATED':
      return <DraftIcon />;
    case 'PARTIALLY_ISSUED':
      return <ApprovedIcon />;
    case 'ISSUANCE_IN_PROGRESS':
      return <ProcessingIcon />;
    case 'ACTIVE':
      return <ActiveIcon />;
    case 'SETTLED':
      return <ApprovedIcon />;
    case 'SETTLEMENT_NOT_REQUIRED':
      return <ApprovedIcon />;
    case 'BANK_UPDATED':
      return <BankIcon />;
    case 'PAYMENT_INITIATED':
      return <RupeeIcon />;
    case 'PAYMENT_IN_PROGRESS':
      return <ProcessingIcon />;
    case 'PAYMENT_COMPLETED':
      return <RupeeIcon />;
    case 'PAYMENT_FAILED':
      return <ExpiredIcon />;
    case 'CLAIM_CANCELLED':
      return <ExpiredIcon />;
    case 'CANCELLED':
      return <ExpiredIcon />;
    case 'BOOKING_CONFIRMED':
      return <ApprovedIcon />;
    case 'IN_PROGRESS':
      return <ProcessingIcon />;
    case 'BOOKING_CONFIRMED':
      return <ExpiredIcon />;
    case 'PAYMENT_FAILED':
      return <ExpiredIcon />;
    case 'REFUND_IN_PROGRESS':
      return <RupeeIcon />;
    case 'CANCEL_FAILED':
      return <ExpiredIcon />;
    case 'BOOKING_IN_PROGRESS':
      return <ProcessingIcon />;
    case 'BOOKING_FAILED':
      return <ExclaimationIcon />;
    case 'PROCESSING_OFFLINE':
      return <GlobeIcon />;
    case 'CANCELLATION_REQUESTED':
      return <ExpiredIcon />;
    case 'ITINERARY':
      return <QuoteIcon />;
    case 'UNKNOWN':
      return '--';
    default:
      break;
  }
};

export const getStatusColor = (
  type: string, //'info' | 'primary' | 'warning' | 'success' | 'error' | 'danger',
  theme: Theme,
  next: boolean = false
) => {
  switch (type) {
    case 'info':
      return theme?.palette?.secondary?.main;
    case 'primary':
      return next ? theme?.palette?.success?.main : theme?.palette?.primary?.main;
    case 'warning':
      return next ? theme?.palette?.primary?.main : theme?.palette?.warning?.main;
    case 'success':
      return theme?.palette?.success?.main;
    case 'error':
      return theme?.palette?.error?.main;
    case 'danger':
      return theme?.palette?.error?.main;
    default:
      break;
  }
};
