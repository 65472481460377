import { useMemo, useEffect } from 'react';
import { Card } from 'react-bootstrap';

import { useApiKeyContext } from '../context/ApiKeyContext';
import { getApiKeyColumns } from './ApiKeyColumns';
import { showDialog } from '../../../helper';
import OATableComponent, { OATablePaginationProps } from '../../../components/OATableComponent';

export default function ApiKeyList() {
  const {
    isLoading,
    apiKeys,
    pageInfo,
    fetchApiKeys,
    onPageChange,
    onSelectApiKey,
    onDeleteApiKey
  } = useApiKeyContext();

  useEffect(() => {
    if (!apiKeys) {
      fetchApiKeys();
    }
  }, [apiKeys]);
  /**
   *
   * @param id
   * @returns
   */
  const handleView = (id: string) => onSelectApiKey(id);
  /**
   *
   * @param id
   */
  const handleDelete = (id: string) => {
    showDialog({
      title: 'Delete API key?',
      text: 'Are you sure you want to delete this API key?',
      icon: 'warning'
    }).then(result => {
      if (result?.isConfirmed) {
        onDeleteApiKey(id);
      }
    });
  };

  const columns = useMemo(
    () => getApiKeyColumns({ handleView, handleDelete }),
    [handleView, handleDelete]
  );

  const paginationProps: OATablePaginationProps = {
    pageIndex: pageInfo?.index,
    pageCount: pageInfo?.count,
    total: pageInfo?.totalCount,
    pageSize: pageInfo?.size,
    onPageChange
  };

  return (
    <Card>
      <Card.Body className='p-0 ps-2'>
        <OATableComponent
          columns={columns}
          data={apiKeys}
          pagination={paginationProps}
          loading={isLoading}
          disableColumnMenu={false}
        />
      </Card.Body>
    </Card>
  );
}
