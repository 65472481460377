import classNames from 'classnames';

/* eslint-disable no-unused-vars */
export interface IOrderSectionHeaderProps {
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  children?: JSX.Element;
  className?: string;
}

export function OrderSectionHeader(props: IOrderSectionHeaderProps) {
  const draftClassNames = classNames('d-flex justify-content-between mb-6', props?.className);

  return (
    <div className={draftClassNames}>
      <div>
        <h4 className='fw-semibold text-dark mb-0'>{props?.title}</h4>
        {props?.subtitle ? (
          <p className='text-gray-400 fw-bold fs-7 mb-0'>{props?.subtitle}</p>
        ) : null}
      </div>
      {props?.children}
    </div>
  );
}
