import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { bytesToSize } from '../helper';
import { KTSVG } from '../_metronic/helpers';

const baseStyle: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  // color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle: React.CSSProperties = {
  borderColor: '#2196f3'
};

const acceptStyle: React.CSSProperties = {
  borderColor: '#00e676'
};

const rejectStyle: React.CSSProperties = {
  borderColor: '#ff1744'
};

interface IOADropZone {
  placeHolder?: React.ReactNode;
  height?: number;
  maxFiles?: number;
  maxSize?: number;
  minSize?: number;
  fileTypes: Array<'csv' | 'png' | 'jpg' | 'jpeg' | 'pdf' | 'zip' | 'docx'>;
  onFileDragDrop: (value: Array<any>) => void;
  disableUpload?: boolean;
  prevFile?: any;
  isModalOpen?: boolean;
  showDescription?: boolean;
}

const OADropZone = (props: IOADropZone) => {
  const {
    placeHolder = "Drag 'n' drop some files here, or click to select files",
    height = 200,
    maxFiles = 1,
    maxSize = 5 * 1024 * 1024,
    minSize = 100,
    fileTypes,
    onFileDragDrop,
    disableUpload = false,
    prevFile,
    isModalOpen,
    showDescription = true
  } = props;

  const [myFiles, setMyFiles] = useState<Array<any>>([]);

  const onDrop = useCallback(
    (acceptedFiles: Array<File>, fileRejections: Array<any>) => {
      // Update the state only if the restrictions are met
      if (fileRejections?.length === 0 && acceptedFiles.length > 0) {
        setMyFiles(prev => [...prev, ...acceptedFiles]);
      }
    },
    [myFiles]
  );

  const dropzoneOptions: DropzoneOptions = {
    onDrop,
    accept: getAccept(fileTypes),
    maxFiles,
    maxSize,
    minSize,
    disabled: disableUpload || myFiles?.length >= maxFiles
  };

  const {
    getRootProps,
    getInputProps,
    fileRejections,
    // acceptedFiles,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone(dropzoneOptions);

  useEffect(() => {
    if (prevFile) {
      setMyFiles(prevFile);
    }
  }, []);

  useEffect(() => {
    onFileDragDrop(myFiles);
  }, [myFiles]);

  useEffect(() => {
    // When the modal closes, reset the myFiles state
    if (!isModalOpen) {
      setMyFiles([]);
    }
  }, [isModalOpen]);

  const removeFile = (file: any) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const acceptedFileItems = useMemo(() => acceptedFileItemsFunc(myFiles, removeFile), [myFiles]);

  const fileRejectionItems = useMemo(
    () => fileRejectionItemsFunc(fileRejections),
    [fileRejections]
  );

  const disabledStyle: React.CSSProperties = {
    backgroundColor: '#e0e0e0',
    cursor: 'not-allowed'
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...{ height: `${height}px` },
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(disableUpload ? disabledStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject, disableUpload]
  );

  return (
    <div>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p className='fs-6 fw-bold text-muted'>{placeHolder}</p>
        {showDescription && (
          <div className='d-flex flex-column fs-9 text-start'>
            {maxFiles > 0 && <em>- The maximum number of files allowed is {maxFiles}</em>}
            <em>- The minimum file size allowed is {bytesToSize(minSize)}.</em>
            <em>- The maximum file size allowed is {bytesToSize(maxSize)}.</em>
          </div>
        )}
      </div>
      <div className='d-flex flex-column'>{acceptedFileItems}</div>
      <div className='d-flex flex-column'>{fileRejectionItems}</div>
    </div>
  );
};

export default OADropZone;

const acceptedFileItemsFunc = (files: any, removeFile: (file: any) => () => void) => {
  return files.map((file: any) => (
    <li key={file?.path} className='d-flex align-items-center py-2'>
      <span className='bullet bg-success h-5px w-10px me-5' />
      <span className='text-success'>
        {file?.size ? `${file?.path} - ${bytesToSize(file?.size)}` : `${file?.path}`}
      </span>
      <span onClick={removeFile(file)}>
        <KTSVG
          path='/media/icons/duotune/arrows/arr015.svg'
          className='svg-icon svg-icon-2 svg-icon-gray-500 ms-2 cursor-pointer'
        />
      </span>
    </li>
  ));
};

const fileRejectionItemsFunc = (fileRejections: any) =>
  fileRejections.map(({ file, errors }: { file: any; errors: any }) => (
    <li key={file.path} className='d-flex align-items-center py-2'>
      <span className='bullet bg-danger h-5px w-10px me-5' />
      <span className='text-danger'>
        {file.path} -
        {errors.map((e: any) => (
          <span className='text-danger'> - {e.message}</span>
        ))}
      </span>
    </li>
  ));

const getAccept = (fileType: Array<string>) => {
  const acceptObj: Record<string, string[]> = {
    'text/csv': ['.csv'],
    'image/png': ['.png'],
    'image/jpg': ['.jpg'],
    'image/gif': ['.gif'],
    'image/jpeg': ['.jpeg'],
    'text/pdf': ['.pdf'],
    'text/docx': ['.docx'],
    'application/zip': ['.zip']
  };
  const filteredObj: Record<string, string[]> = {};
  for (const key in acceptObj) {
    if (fileType.some(ext => acceptObj[key].includes('.' + ext))) {
      filteredObj[key] = acceptObj[key];
    }
  }
  return filteredObj;
};
