import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';

import DocumentCard from '../../../order-view/OrderTabs/TravellerInfo/DocumentCard';
import { findAdditionalAmountModifiers } from '../../../../quotation/create/Preview/PriceTable';
import { getCurrencySymbol } from '../../../../../helper';
import { formatNumberForCurrency } from '../../../../../helper';
import { useQuotationContext } from '../../../../quotation/context/QuotationContext';
import { useOrderContext } from '../../../context/OrderContext';
import { useS3FileUploader } from '../../../../../hooks/useS3FileOperation';
import showToast from '../../../../../helper/showToast';
import add_traveller_svg from '../../../../../assets/images/add_traveller.svg';
import { OA_DATE_FORMAT_FE } from '../../../../../constants';

interface IDocumentUploadGroup {
  formState: any;
  orderData: any;
  documents: Array<any>;
  setDocuments: React.Dispatch<React.SetStateAction<any[]>>;
  setUploadedDoc: React.Dispatch<React.SetStateAction<any>>;
}
const DocumentUploadGroup = (props: IDocumentUploadGroup) => {
  const { formState, orderData, documents, setDocuments, setUploadedDoc } = props;

  const [uploadFile] = useS3FileUploader(5, ['jpg', 'png', 'jpeg', 'pdf', 'docx', 'xlsx']);

  useEffect(() => {
    const consulateData =
      orderData?.product?.productInformation?.consulateData ||
      orderData?.groupOrder?.product?.productInformation?.consulateData;

    if (formState?.consulate && formState?.consulate !== '' && consulateData) {
      const consulateDocs = consulateData.find(
        (obj: any) => obj?.value === formState?.consulate
      )?.requiredDocuments;
      /**
       * NOTE: uploadedDocs is an array of docs that are already uploaded before
       * changing the consulate. So, we need to update the documentUrl and uploaded
       * property of the document that is already uploaded..
       */
      const uploadedDocs = documents?.filter((obj: any) => obj?.uploaded && obj?.documentUrl);

      const excludedDocument = ['PassportFrontImage', 'PassportBackImage'];
      if (consulateDocs?.length > 0) {
        let filteredConsulateDocs = consulateDocs?.filter((obj: any) => {
          if (!obj?.applicableFor) {
            return obj;
          }
        });
        const draftDocs = filteredConsulateDocs?.map((obj: any) => {
          const uploadedDoc = uploadedDocs?.find((doc: any) => doc?.fieldName === obj?.fieldName);
          if (uploadedDoc) {
            return { ...obj, documentUrl: uploadedDoc?.documentUrl, uploaded: true };
          }
          return obj;
        });
        setDocuments(draftDocs?.filter((obj: any) => !excludedDocument.includes(obj?.fieldName)));
      }
    }
  }, [formState]);

  return (
    <>
      {documents.length > 0 ? (
        <Row className='mt-10'>
          <h3 className='text-gray-800 fs-3 fw-bolder mb-0'>STEP 2: Upload other documents</h3>
          <p className='fs-7 text-muted'>
            Maintain the PDF/JPG/PNG/JPEG/DOCX/XLSX document size under 5MB
          </p>
          {documents.length > 0 &&
            documents?.map((doc: any, idx: number) => {
              const fileUploadHandler = async (
                file: File,
                fileName: string,
                extension: string,
                cb?: () => void
              ) => {
                const result = await uploadFile(file, 'group/temp/');
                if (result?.uploadedUrl) {
                  const updatedArray = documents.map(obj => {
                    if (obj.fieldName === doc?.fieldName) {
                      return { ...obj, documentUrl: result.uploadedUrl, uploaded: true }; // add a new "email" property to object with id 2
                    }
                    return obj; // return the object as is if it doesn't need to be updated
                  });
                  setDocuments(updatedArray);
                  setUploadedDoc((prev: Array<{ fieldName: string; data: string }>) => {
                    return [...prev, { fieldName: doc?.fieldName, data: result.uploadedUrl }];
                  });
                  cb?.();
                } else {
                  if (result?.error) showToast(result?.error, 'error');
                }
              };

              return (
                <Col md='6' lg='2' key={idx}>
                  <DocumentCard
                    index={idx}
                    document={doc}
                    memberId={'123456'}
                    isEditable
                    fileUploadHandler={fileUploadHandler}
                  />
                </Col>
              );
            })}
        </Row>
      ) : (
        <Row className='mt-10'>
          <Col>
            <NoDataJSX />
          </Col>
        </Row>
      )}
      <Row className='mt-10'>
        <Col>
          <h3 className='text-gray-800 fs-3 fw-bolder mb-0'>Payment summary</h3>
          {orderData && <PriceTable orderData={orderData} />}
        </Col>
      </Row>
    </>
  );
};

export default DocumentUploadGroup;

const PriceTable = ({ orderData }: { orderData: any }) => {
  const { product, supplierOrganization, trip, groupOrder } = orderData;
  const { formState } = useOrderContext();
  const { quotationData, addOrEditQuotation } = useQuotationContext();

  useEffect(() => {
    addOrEditQuotation(getQuotationBody(formState), 'visa', null);
  }, []);
  const { name, productInformation } = product || groupOrder?.id;

  const orderAmount = quotationData?.data?.orderAmount;
  const draftCurrency = orderAmount && getCurrencySymbol(orderAmount?.currency);
  const serviceCharge =
    orderAmount &&
    findAdditionalAmountModifiers(orderAmount?.additionalAmountModifiers, 'SERVICE_CHARGES');
  const subTotal =
    orderAmount &&
    formatNumberForCurrency(orderAmount?.subtotalAmount, {
      countryCode: orderAmount?.currencyCountry
    });
  const grandTotal =
    orderAmount &&
    formatNumberForCurrency(orderAmount?.totalAmount, {
      countryCode: orderAmount?.currencyCountry
    });

  const tripStart = moment(
    trip?.tripStart?.datetime || groupOrder?.trip?.tripStart?.datetime
  ).format(OA_DATE_FORMAT_FE);
  const tripEnd = moment(trip?.tripEnd?.datetime || groupOrder?.trip?.tripEnd?.datetime).format(
    OA_DATE_FORMAT_FE
  );

  const getQuotationBody = (fs: any) => {
    const request = {
      departure: fs?.tripDetails?.origin?.countryCode,
      arrival: fs?.tripDetails?.destination[0]?.countryCode,
      senior: '',
      adult: 1,
      infant: '',
      tripType: '',
      visaType: productInformation?.visaType,
      requestedProductId: groupOrder?.productId,
      tripDuration: fs?.tripDuration,
      occupation: productInformation?.occupation,
      supplier: supplierOrganization?.organizationCode,
      destination: ''
    };
    return request;
  };
  return (
    <div className='flex-grow-1 mb-8'>
      <div className='w-100 border-bottom mb-4'>
        <table className='table mb-0'>
          <thead>
            <tr className='border-bottom fs-8 fw-bold text-muted'>
              <th className='min-w-70px pb-2'>SUPPLIER</th>
              <th className='min-w-90px text-end pb-2'>VISA NAME</th>
              <th className='min-w-90px text-end pb-2'>TRIP START</th>
              <th className='min-w-90px text-end pb-2'>TRIP END</th>
              <th className='min-w-90px text-end pb-2'>PRICE</th>
            </tr>
          </thead>
          <tbody>
            <tr className='fw-bold text-gray-700 fs-7 text-end'>
              <td className='text-start'>
                {groupOrder?.supplierOrganization?.name || supplierOrganization?.name}
              </td>
              <td>{groupOrder?.product?.name || name}</td>
              <td>{tripStart}</td>
              <td>{tripEnd}</td>
              <td>{groupOrder?.product?.subTotal || subTotal}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='d-flex justify-content-end'>
        <div className='min-w-300px'>
          {serviceCharge && (
            <div className='d-flex flex-stack mb-3'>
              <div className='fw-bold pe-10 text-gray-400 fs-7'>Service charge</div>
              <div className='text-end fw-bold fs-7 text-gray-400'>
                {draftCurrency}
                {serviceCharge?.amount}
              </div>
            </div>
          )}
          <div className='d-flex flex-stack mb-3'>
            <div className='fw-bold pe-10 text-gray-400 fs-7'>Subtotal</div>
            <div className='text-end fw-bold fs-7 text-gray-400'>
              {draftCurrency}
              {subTotal}
            </div>
          </div>
          {serviceCharge && (
            <div className='d-flex flex-stack mb-3'>
              <div className='fw-bold pe-10 text-gray-400 fs-7'>Service charge GST</div>
              <div className='text-end fw-bold fs-7 text-gray-400'>
                {draftCurrency}
                {serviceCharge?.tax}
              </div>
            </div>
          )}
          <div className='d-flex flex-stack'>
            <div className='fw-bolder pe-10 text-dark fs-7'>Grand total</div>
            <div className='text-end fw-bolder fs-7 text-dark'>
              {draftCurrency}
              {grandTotal}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const NoDataJSX = () => {
  return (
    <div className='d-flex flex-column flex-column-fluid text-center py-10'>
      <img src={add_traveller_svg} alt='' className='m-auto' style={{ maxHeight: '150px' }} />
      <div className='fs-3 fw-bolder text-dark'>Select a consulate</div>
      <p className='text-gray-400 fw-bold fs-5 mt-1 mb-7'>
        Please select a consulate from the options above in order to upload documents.
      </p>
    </div>
  );
};
