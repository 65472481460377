import { Box, Divider } from '@mui/material';
import { OATypography } from '../../../components/widgets';
import { FlightsJourney } from './FlightTabHelpers/FlightsJourney';

interface TravellerInfo {
  data: any;
  handleSelected: (val: any) => void;
  selected: string;
}

export default function TravellerInfo(props: TravellerInfo) {
  const { data } = props;

  let onward = data?.flights?.find((onward: any) => onward?.departureType === 'ONWARD');
  let returnData = data?.flights?.find((obj: any) => obj?.departureType === 'RETURN');

  return (
    <Box display='flex' flexDirection='column' gap='24px'>
      <div className='order-view--activity card pb-6'>
        <Box display='flex' justifyContent='space-between' alignItems='center' padding='16px'>
          <OATypography variant='h5'>Contact information</OATypography>
        </Box>
        <Divider />
        <Box display='flex' alignItems='center' gap='64px' padding='24px'>
          <Box display='flex'>
            <OATypography variant='body1' color='textSecondary'>
              Mobile number
            </OATypography>
            <OATypography variant='body1' sx={{ paddingLeft: '24px' }}>
              {data?.contactDetails?.phoneNumber}
            </OATypography>
          </Box>
          <Box display='flex'>
            <OATypography variant='body1' color='textSecondary'>
              Email
            </OATypography>
            <OATypography variant='body1' sx={{ paddingLeft: '24px' }}>
              {data?.contactDetails?.email}
            </OATypography>
          </Box>
        </Box>
      </div>
      <div className='order-view--activity card pb-6'>
        <Box display='flex' justifyContent='space-between' alignItems='center' padding='16px'>
          <OATypography variant='h5'>Traveller information</OATypography>
        </Box>
        <Divider />
        {data?.flights?.length > 1 ? (
          <Box>
            <Box>
              <FlightsJourney flightType={onward} data={data} />
            </Box>
            <Divider />
            <Box>
              <FlightsJourney flightType={returnData} data={data} />
            </Box>
          </Box>
        ) : (
          <FlightsJourney flightType={onward} data={data} />
        )}
      </div>
    </Box>
  );
}
