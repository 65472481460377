import { Fragment } from 'react';
import { ErrorMessage, useField } from 'formik';
import classNames from 'classnames';

import { ILabelValuePair } from '../../types';

interface IOARadioField {
  name: string;
  options: Array<ILabelValuePair>;
  label?: string;
  required?: boolean;
  alignment?: 'vertical' | 'horizontal';
}

export default function OARadioField(props: IOARadioField) {
  const { label, name, required = false, alignment = 'vertical' } = props;

  const [{ value, ...rest }, _meta, _helpers] = useField(name);

  const labelClassNames = classNames('form-label', {
    'required': required,
    'mb-0': alignment === 'horizontal'
  });

  const draftWrapperClass = classNames('', {
    'd-flex align-items-center gap-6': alignment === 'horizontal'
  });

  return (
    <Fragment>
      <div className={draftWrapperClass}>
        {label ? (
          <label htmlFor={name} className={labelClassNames}>
            {label}
          </label>
        ) : null}
        <div className='d-flex'>
          {props?.options?.length &&
            props?.options?.map?.((opt: any, idx: number) => {
              return (
                <div
                  key={`${name}_${idx}`}
                  className='form-check form-check-custom form-check-sm form-check-solid me-6'>
                  <input
                    type='radio'
                    className='form-check-input'
                    id={`${name}_${idx}`}
                    value={opt?.value}
                    checked={opt?.value === value}
                    {...rest}
                  />
                  <label className='form-check-label' htmlFor={`${name}_${idx}`}>
                    {opt?.label}
                  </label>
                </div>
              );
            })}
        </div>
      </div>
      <div className='text-danger'>
        <ErrorMessage name={name} />
      </div>
    </Fragment>
  );
}
