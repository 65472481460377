import classNames from 'classnames';
import { IconType } from '.';

export default function ImgPlusSvg(props: IconType) {
  const {
    width = '40',
    height = '40',
    // isActive = false,
    className = ''
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 39 39'
      fill='none'
      className={classNames('icon-svg edit-svg', className)}
      xmlns='http://www.w3.org/2000/svg'>
      <rect width='38.76' height='38.76' rx='4.56' fill='#F3FAFE' />
      <path
        opacity='0.3'
        d='M26.026 20.1176H12.7419C12.2991 20.1176 12.0039 19.8224 12.0039 19.3796C12.0039 18.9368 12.2991 18.6416 12.7419 18.6416H26.026C26.4688 18.6416 26.764 18.9368 26.764 19.3796C26.764 19.8224 26.4688 20.1176 26.026 20.1176Z'
        fill='#3A86FF'
      />
      <path
        d='M19.3806 26.7601C18.9378 26.7601 18.6426 26.4649 18.6426 26.0221V12.738C18.6426 12.2952 18.9378 12 19.3806 12C19.8234 12 20.1186 12.2952 20.1186 12.738V26.0221C20.1186 26.4649 19.8234 26.7601 19.3806 26.7601Z'
        fill='#3A86FF'
      />
    </svg>
  );
}
