import React from 'react';
import classNames from 'classnames';

import { IconType } from '.';

export default function CloseSVG(props: IconType) {
  const {
    color = '#68778C',
    width = '18',
    height = '18',
    // isActive = false,
    className = ''
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 18'
      fill='none'
      className={classNames('icon-svg close-svg', className)}
      xmlns='http://www.w3.org/2000/svg'>
      <path
        opacity='0.3'
        d='M4.5 14.775C4.275 14.775 4.125 14.7 3.975 14.55C3.675 14.25 3.675 13.8 3.975 13.5L13.5 3.975C13.8 3.675 14.25 3.675 14.55 3.975C14.85 4.275 14.85 4.725 14.55 5.025L5.025 14.55C4.875 14.7 4.725 14.775 4.5 14.775Z'
        fill={color}
      />
      <path
        d='M14.1031 14.775C13.8781 14.775 13.7281 14.7 13.5781 14.55L4.05313 5.025C3.75312 4.725 3.75312 4.275 4.05313 3.975C4.35313 3.675 4.80313 3.675 5.10313 3.975L14.6281 13.5C14.9281 13.8 14.9281 14.25 14.6281 14.55C14.4781 14.7 14.2531 14.775 14.1031 14.775Z'
        fill={color}
      />
    </svg>
  );
}
