import { useContext, useEffect } from 'react';
import { IDocument } from '../../../../../types';
import OrderContext from '../../../context/OrderContext';
import OAVisaSummary from '../../../../../components/OAVisaSummary';
import OAAccordionConfig from '../../../../../components/OAVisaSummary/helper';
import { Box } from '@mui/material';

const VisaSummary = () => {
  const { orderData, visaInfoProduct, visaInfoProducts } = useContext(OrderContext);

  useEffect(() => {
    let draft = {
      departure: orderData?.data?.trip?.origin?.countryCode,
      arrival: orderData?.data?.trip?.domestic
        ? orderData?.data?.trip?.origin?.countryCode
        : orderData?.data?.trip?.destination[0]?.countryCode
    };
    visaInfoProduct(draft);
  }, []);

  const selectedVisaInformation: any = visaInfoProducts?.data?.filter(
    (obj: any) => obj?.name === orderData?.data?.product?.displayName
  )[0];

  const selectedConsulate = selectedVisaInformation?.consulateData?.find(
    (obj: any) => obj?.value?.split('-')[1] === orderData?.data?.consulate?.split('-')[1]
  );

  const requiredVisaForms = selectedConsulate?.requiredVisaForms;

  const requiredDocuments = selectedConsulate?.requiredDocuments;
  let requiredDocs = [];
  let groupByApplicableFor: any = [];

  if (requiredDocuments?.length > 0) {
    requiredDocs = requiredDocuments.filter((doc: IDocument) => doc.required);

    groupByApplicableFor = requiredDocuments.reduce((acc: any, doc: any) => {
      if (doc.applicableFor) {
        doc.applicableFor.forEach((applicable: string) => {
          if (!acc[applicable]) {
            acc[applicable] = [];
          }
          acc[applicable].push(doc);
        });
      }
      return acc;
    }, {});
  }
  let accordianParams = {
    selectedConsulate,
    selectedVisaInformation,
    requiredVisaForms,
    requiredDocs,
    groupByApplicableFor
  };
  let accordionConfig = OAAccordionConfig(accordianParams);

  return (
    <Box sx={{ backgroundColor: 'white', borderRadius: '8px' }}>
      <OAVisaSummary
        accordionConfig={accordionConfig}
        selectedVisaInformation={selectedVisaInformation ?? {}}
      />
    </Box>
  );
};

export default VisaSummary;
