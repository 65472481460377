import moment from 'moment';

const formatLogDate = (date: Date) => {
  return moment(date)?.format('dddd, DD MMM, YYYY');
};

const sortDates = (groupedLogs: any) => {
  return Object?.keys(groupedLogs)?.sort((a, b) => new Date(b)?.getTime() - new Date(a)?.getTime());
};

export { formatLogDate, sortDates };
