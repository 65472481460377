/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import VisaInformationContext, { IVisaInformationContext } from './VIsaInformationContext';
import {
  inits,
  IVisaInformationFormValues,
  IVisaInformationProvider
} from '../../../types/IVisaInformation';
import { handleS3Download } from '../../../helper/handleDownload';
import { getVisaInformationRequestBody } from '../helpers';
import { showToast } from '../../../helper';
import API from '../../../helper/API';

const VisaInformationProvider = (props: IVisaInformationProvider) => {
  const navigate = useNavigate();

  const [infoValues, setInfoValues] = useState<any>(inits);
  const [products, setProducts] = useState<{ data: Array<any> | null; isLoading: boolean }>({
    data: null,
    isLoading: false
  });

  const discoverVisaProducts = async (values: any, cb?: (response?: any) => void) => {
    setProducts(prev => ({ ...prev, isLoading: true }));
    const requestBody = getVisaInformationRequestBody(values);

    try {
      API.post(`/visa/products/info`, requestBody)
        .then(response => {
          const result = response?.data?.data;
          setProducts({ data: result?.visas ?? [], isLoading: false });
          cb?.(result);
        })
        .catch(error => {
          setProducts({ data: null, isLoading: false });
          cb?.();
          showToast(error?.response?.data?.errors, 'error');
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const sendVisaInformation = async (values: any, cb?: (response?: any) => void) => {
    const requestBody = getVisaInformationRequestBody(values);

    try {
      const response = await API.post(`/visa/send-product-info`, requestBody);
      const resultData = response?.data?.data;

      let link;
      try {
        link = await urlShortner(resultData?.pdfUrl);
      } catch (error) {
        link = { data: resultData?.pdfUrl };
      }

      if (values?.downloadFile) {
        await handleS3Download(resultData?.pdfUrl);
        showToast(`Information downloaded successfully`, 'success');
        cb?.(resultData?.data?.data);
      } else {
        showToast(`Information sent successfully`, 'success');
        setInfoValues(inits);
        setProducts({ data: null, isLoading: false });
        cb?.(link?.data || resultData?.pdfUrl);
      }
    } catch (error: any) {
      cb?.();
      showToast(error?.response?.data, 'error');
    }
  };

  const getCountriesList = async (cb?: (response?: any) => void) => {
    try {
      API.get(`/products/countries`)
        .then(response => {
          const resultData = response?.data?.data;
          cb?.(resultData);
        })
        .catch(err => {
          cb?.(err);
          throw new Error(`API error: ${err?.message}`);
        });
    } catch (error) {
      cb?.(error);
      throw new Error(`API error: ${error}`);
    }
  };

  const urlShortner = async (url: string) => {
    try {
      const response = await API.get(`/short-url?url=${encodeURIComponent(url)}`);
      return response?.data;
    } catch (error) {
      throw new Error(`API error: ${error}`);
    }
  };

  const contextValue: IVisaInformationContext = {
    products,
    visaInformationValues: infoValues,
    setVisaInformationValues: setInfoValues,
    discoverVisaProducts,
    sendVisaInformation,
    getCountriesList
  };

  return (
    <VisaInformationContext.Provider value={contextValue}>
      {props?.children}
    </VisaInformationContext.Provider>
  );
};

export default VisaInformationProvider;
