import React, { Fragment, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { Dropdown } from 'react-bootstrap';

import { useOrderContext } from '../../context/OrderContext';
import { IOrderType } from '../../types';
import { useAuth } from '../../../../auth';
import {
  ORDER_APPROVED_STATUS_ARR,
  ORDER_DRAFT_STATUS_ARR,
  ORDER_REVIEW_STATUS_ARR,
  ORDER_STATUS,
  ORDER_TYPE
} from '../../constants';
import SubmitToEmbassyModal from './SubmitToEmbassyModal';
import OrderStatusUpdateModal from './OrderStatusUpdateModal';
import { handleDownload } from '../../../../helper/handleDownload';
import { isAlsaffronOrg, showDialog } from '../../../../helper';
import OAModalComponent from '../../../../components/OAModalComponent';
import CancellationReasonForm from './CancellationReasonForm';
import OAButton from '../../../../components/widgets/OAButton';
import InviteTravellerModal from './InviteTravellerModal';
import CPay from '../../../../components/CPay';
import UpdateQuoteModal from './UpdateQuoteModal';
/**
 * types ...
 */
interface IOrderActions {
  isEditable: boolean;
  shouldSubmit: boolean;
  isSubmittedToEmbassy: boolean;
}
/**
 *
 * @param props
 * @returns
 */
export default function OrderActions(props: IOrderActions) {
  const params = useParams<{ orderType: IOrderType; id?: string }>();
  const navigate = useNavigate();

  const { currentUser } = useAuth();

  const {
    orderData,
    // orderActions,
    onCancelOrder,
    onSubmitOrder,
    onSubmitReview,
    onDownloadVisa,
    onDuplicateOrder
  } = useOrderContext();

  const [isVisaDownloading, setIsVisaDownloading] = useState<boolean>(false);
  const [isInviteTravellerOpen, setIsInviteTravellerOpen] = useState<boolean>(false);
  const [updateQuote, setUpdateQuote] = useState<boolean>(false);
  const [isSubmitToEmbassyOpen, setIsSubmitToEmbassyOpen] = useState<boolean>(false);
  const [isStatusUpdateOpen, setIsStatusUpdateOpen] = useState<boolean>(false);
  const [isRequestCancellationOpen, setIsRequestCancellationOpen] = useState<boolean>(false);

  const hasSupplierViewAccess =
    currentUser?.data?.organizationId === orderData?.data?.supplierOrganization?.id;
  const isOrderCompleted = orderData?.data?.bookingStatus === ORDER_STATUS.COMPLETED;
  const issuanceInProgress = orderData?.data?.bookingStatus === ORDER_STATUS.ISSUANCE_IN_PROGRESS;
  const orderSupplierOrgId = orderData?.data?.supplierOrganization?.id;
  const loggedInUserOrgId = currentUser?.data?.organization?.id;
  const createdByUserOrgId = orderData?.data?.createdByUser?.organizationId;
  const isEditable = ORDER_DRAFT_STATUS_ARR.includes(orderData?.data?.bookingStatus);
  const isOrderApproved = ORDER_APPROVED_STATUS_ARR.includes(orderData?.data?.bookingStatus);
  const isOrderInReview = ORDER_REVIEW_STATUS_ARR.includes(orderData?.data?.bookingStatus);
  const isOfflineProduct = orderData?.data?.product?.submissionType === 'OFFLINE';
  const isVisa = params.orderType === ORDER_TYPE.VISA;
  const isInsurance = params.orderType === ORDER_TYPE.INSURANCE;
  const mode = localStorage.getItem('oaMode');
  const isStickerVisa = orderData?.data?.product?.productInformation?.productType === 'Sticker';

  /**
   * NOTE: 'handleCancel' method gets called while consumer initiate cancel order.
   */
  const handleCancel = () => {
    showDialog({
      title: 'Cancel Order?',
      text: 'Are you sure you want to cancel this order?',
      icon: 'warning'
    }).then(result => {
      if (result?.isConfirmed) {
        if (orderData?.data?.bookingStatus === ORDER_STATUS.ORDER_CANCELLATION_REQUESTED) {
          onCancelOrder(params?.orderType as IOrderType, orderData?.data?.id, true);
        } else {
          onCancelOrder(params?.orderType as IOrderType, orderData?.data?.id, null);
        }
      }
    });
  };
  /**
   * NOTE: 'handleSubmit' method gets called while consumer makes initial submit of an
   * order. Once succeeded order gets ready to be reviewed by supplier ...
   */
  const handleSubmit = () => {
    showDialog({
      title: 'Submit Order?',
      text: 'Are you sure you want to submit this order?',
      icon: 'warning'
    }).then(result => {
      if (result?.isConfirmed) {
        onSubmitOrder(null, params?.orderType as IOrderType, orderData?.data?.id);
      }
    });
  };
  /**
   * NOTE: 'handleSubmitReview' method gets called by supplier only if it's not an offline
   * product and only once order is reviewed and ready to ship...
   */
  const handleSubmitReview = () => {
    showDialog({
      title: 'Submit Reviewed Order?',
      text: 'Are you sure you want to submit this reviewed order?',
      icon: 'warning'
    }).then(result => {
      if (result?.isConfirmed) {
        onSubmitReview(params?.orderType as IOrderType, orderData?.data?.id, null);
      }
    });
  };

  const handleDownloadVisa = () => {
    setIsVisaDownloading(true);
    onDownloadVisa(orderData?.data?.id, () => {
      setIsVisaDownloading(false);
    });
  };
  /**
   * NOTE: 'handleDuplicateOrder' method gets called by user to create a clone of
   * current order...
   */
  const handleDuplicateOrder = () => {
    showDialog({
      title: 'Duplicate order?',
      text: 'You are creating a copy of this order',
      confirmButtonText: 'Yes, Continue',
      icon: 'warning'
    }).then(result => {
      if (result?.isConfirmed) {
        onDuplicateOrder(params?.id!);
        navigate(`/apps/orders/${params?.orderType}/create/1?cloneOrderId=${params?.id}`);
      }
    });
  };

  const onHideRequestCancellationModal = () => setIsRequestCancellationOpen(false);

  // const isOrderReviewed = orderActions?.data?.every((act: any) => act?.status === 'APPROVED');

  /**
   * NOTE: if bookingStatus is "ORDER_QUOTE" then make isQuotation to true...
   */
  const isQuotation = orderData?.data?.bookingStatus === ORDER_STATUS.ORDER_QUOTE;
  /**
   * NOTE: renderQuotationActions method will render quotation specific actions only...
   * @returns
   */
  const renderQuotationActions = () => {
    return isQuotation ? (
      <Fragment>
        {/* <OAButton
          color='light-danger'
          label='Delete'
          onClick={() => setIsInviteTravellerOpen(true)}
          className='me-3 btn-active-light-danger'
        /> */}
        <OAButton
          color='light-primary'
          label='Edit info'
          onClick={() => setUpdateQuote(true)}
          className='me-3 btn-active-light-primary'
        />
        <Link
          to={`/apps/orders/${params?.orderType}/create?convertToOrder=true&&quotationId=${params?.id}`}
          state={{ quotationId: params?.id }}
          className='btn btn-sm btn-primary'>
          Convert to order
        </Link>
        {/* <OAButton
          color='primary'
          label='Convert to order'
          onClick={() => setIsInviteTravellerOpen(true)}
          className='me-3'
        /> */}
        <UpdateQuoteModal
          isOpen={updateQuote}
          onHide={() => setUpdateQuote(false)}
          data={orderData?.data}
        />
      </Fragment>
    ) : null;
  };

  return (
    <Box display='flex' alignItems='center'>
      {renderQuotationActions()}
      {!isQuotation ? (
        <Fragment>
          <Dropdown align='end' className='me-4'>
            <Dropdown.Toggle className='btn-sm btn-secondary'>Manage order</Dropdown.Toggle>
            <Dropdown.Menu>
              {/**
               * NOTE: show 'Invite traveller' button...
               * - if order is a visa order and mode is live mode
               */}
              {/**
               * NOTE: show 'Duplicate Order' button...
               * - if order is a visa order
               */}
              {isVisa ? (
                <CustomDropdownItem
                  key='duplicate_order'
                  label='Duplicate order'
                  handler={handleDuplicateOrder}
                />
              ) : null}

              {/**
               * NOTE: show 'Request Cancellation' button for following conditions...
               * - if order is an insurance order
               * - if order status is 'COMPLETED'
               * - if loggedInUserOrgId and orderSupplierOrgId is not same
               */}
              {(() => {
                return isInsurance &&
                  (issuanceInProgress || isOrderCompleted) &&
                  loggedInUserOrgId !== orderSupplierOrgId ? (
                  <CustomDropdownItem
                    key='request_cancellation'
                    label='Request cancellation'
                    handler={() => setIsRequestCancellationOpen(true)}
                  />
                ) : null;
              })()}
              {/**
               * NOTE: show 'Cancel order' button IIFE(Immediately Invoked Function Expression)...
               */}
              {(() => {
                const BLOCKED_STATUSES = [
                  'CANCELLED',
                  'BOOKING_CANCELLED',
                  'ISSUANCE_FAILED',
                  'COMPLETED'
                ];
                /**
                 * DEVNOTE:
                 * - if 'loggedInUserOrgId' and 'orderCreatedByUserOrgId' is same then we
                 * consider the user as a 'Consumer' of the order. And a Consumer can not
                 * Cancel an order
                 * - if 'loggedInUserOrgId' and 'orderSupplierOrgId' is same then we
                 * consider the user as a 'Supplier' of the order. And a Supplier can Cancel
                 * an order anytime.
                 */
                const case_1 = loggedInUserOrgId !== createdByUserOrgId;
                const case_2 = !isOrderCompleted && loggedInUserOrgId === orderSupplierOrgId;
                const case_3 = ORDER_DRAFT_STATUS_ARR.includes(orderData?.data?.bookingStatus);
                const case_4 = BLOCKED_STATUSES.includes(orderData?.data?.bookingStatus);
                const isCancellationRequested =
                  orderData?.data?.bookingStatus === 'ORDER_CANCELLATION_REQUESTED';
                /**
                 * NOTE: for insurance order, check following conditions...
                 */
                const isOrderInEditableState = isEditable && case_3;
                const fulfilCheckForInsurance =
                  isInsurance &&
                  (isOrderInEditableState || (!case_4 && isCancellationRequested && case_1));

                return (isEditable && (case_1 || case_2 || case_3) && !case_4) ||
                  fulfilCheckForInsurance ? (
                  <CustomDropdownItem
                    key='cancel_order'
                    label='Cancel order'
                    handler={handleCancel}
                    error
                  />
                ) : null;
              })()}
            </Dropdown.Menu>
          </Dropdown>

          {isVisa && mode === 'live' ? (
            <OAButton
              color='light-primary'
              label='Invite Traveller'
              onClick={() => setIsInviteTravellerOpen(true)}
              className='me-3'
            />
          ) : null}
          {/**
           * NOTE: show 'Submit order' button IIFE(Immediately Invoked Function Expression)...
           * - API: '/visa/submit?id={{orderId}}'
           */}
          {(() => {
            /**
             * NOTE: for visa, check following conditions...
             * - if it's a visa order
             * - if order is editable based on booking status
             * - if order is 'in review' state
             */
            const fulfilCheckForVisa = isVisa && isEditable && !isOrderInReview;
            /**
             * NOTE: for insurance, check following conditions...
             * - if it's an insurance order
             * - if order is editable based on booking status
             */
            const fulfilCheckForInsurance = isInsurance && isEditable;
            /**
             * NOTE: check if booking status is either of below mentioned two...
             */
            const isOrderSubmitFailed = ['ORDER_FAILED', 'ORDER_SUBMIT_FAILED']?.includes(
              orderData?.data?.bookingStatus
            );
            /**
             * NOTE: if any of the above conditions are met then show 'Submit order' button
             */
            return fulfilCheckForVisa || fulfilCheckForInsurance ? (
              isAlsaffronOrg(currentUser) && orderData?.data?.bookingStatus === 'ORDER_DRAFT' ? (
                <CPay order={orderData?.data} disabled={!props?.shouldSubmit} />
              ) : (
                <OAButton
                  label={isOrderSubmitFailed ? 'Resubmit order' : 'Submit order'}
                  className='me-3'
                  onClick={handleSubmit}
                  disabled={!props?.shouldSubmit}
                />
              )
            ) : //
            null;
          })()}
          {(() => {
            const POST_DRAFT_STATES = [
              ...ORDER_DRAFT_STATUS_ARR,
              'ORDER_SUBMIT',
              'ORDER_SUBMIT_INITIATED',
              'ISSUANCE_FAILED',
              'CANCELLED'
            ];
            const fulfilCheckForVisa =
              isVisa && hasSupplierViewAccess && !isOrderInReview && props?.isSubmittedToEmbassy;
            const fulfilCheckForInsurance =
              isInsurance &&
              !isEditable &&
              !POST_DRAFT_STATES.includes(orderData?.data?.bookingStatus);

            return fulfilCheckForVisa || fulfilCheckForInsurance ? (
              <OAButton
                label='Update status'
                className='me-3'
                onClick={() => setIsStatusUpdateOpen(true)}
              />
            ) : null;
          })()}
          {/**
           * NOTE: show 'Download Visa' button if order is not editable and approved
           * and it's not an offline product...
           */}
          {!isEditable ? (
            <React.Fragment>
              {isOrderApproved && !isOfflineProduct && (
                <OAButton
                  color='light-primary'
                  label={isVisaDownloading ? 'Downloading Visa...' : 'Download Visa'}
                  onClick={handleDownloadVisa}
                  className='btn-active-light-primary me-3'
                  disabled={isVisaDownloading}
                />
              )}
            </React.Fragment>
          ) : null}
          {/**
           * NOTE: show 'Submit' / 'Submit review' button for following conditions...
           * this button will be visible only by Supplier when a visa order
           * is in review state.
           * - for offline product, button text will be 'Submit' and it will open
           * up 'SubmitToEmbassyModal'..
           * - for online product, button text will be 'Submit review' and it will
           * call submit-review api with orderType and orderId...
           */}
          {(() => {
            return isVisa && hasSupplierViewAccess && isOrderInReview ? (
              <OAButton
                label={isStickerVisa ? 'Apply to VAC' : 'Apply to Portal'}
                onClick={() =>
                  isOfflineProduct ? setIsSubmitToEmbassyOpen(true) : handleSubmitReview()
                }
              />
            ) : null;
          })()}
          {/**
           * NOTE: show 'Download' button for following conditions...
           * - if order is an insurance order
           * - if order status is 'COMPLETED'
           * - if order has download link
           */}
          {(() => {
            return isInsurance && isOrderCompleted && orderData?.data?.downloadLink ? (
              <OAButton
                color='primary'
                label='Download'
                onClick={() => handleDownload(orderData?.data?.downloadLink)}
                className='btn-active-primary me-3'
              />
            ) : null;
          })()}
        </Fragment>
      ) : null}
      {/**
       * NOTE: Invite traveller Modal --
       */}
      <InviteTravellerModal
        isOpen={isInviteTravellerOpen}
        onHide={() => setIsInviteTravellerOpen(false)}
        data={orderData?.data}
      />
      {/**
       * NOTE: Submit To Embassy Modal --
       */}
      <SubmitToEmbassyModal
        isOpen={isSubmitToEmbassyOpen}
        onHide={() => setIsSubmitToEmbassyOpen(false)}
        data={orderData?.data}
      />
      {/**
       * NOTE: Order Status Update Modal --
       */}
      <OrderStatusUpdateModal
        isOpen={isStatusUpdateOpen}
        onHide={() => setIsStatusUpdateOpen(false)}
        data={orderData?.data}
      />
      {/**
       * NOTE: Request Cancellation Modal --
       */}
      <OAModalComponent
        className='order-status-update__modal'
        show={isRequestCancellationOpen}
        onHide={onHideRequestCancellationModal}
        header={{
          title: `Request for order cancellation`,
          closeButton: true
        }}
        body={{
          content: (
            <CancellationReasonForm type='request' onHide={onHideRequestCancellationModal} />
          ),
          className: 'order-status-update__modal-body'
        }}
      />
    </Box>
  );
}

interface CustomDropdownItemProps {
  key: string;
  handler: () => void;
  label: string;
  error?: boolean;
}

export const CustomDropdownItem = (props: CustomDropdownItemProps) => {
  const draftColor = props?.error ? 'error.main' : 'secondary.main';
  return (
    <Dropdown.Item key={props?.key} className='menu-item' onClick={props?.handler}>
      <Typography variant='body2' className='menu-link' color={draftColor}>
        {props?.label}
      </Typography>
    </Dropdown.Item>
  );
};
