import { Box, Divider, Skeleton } from '@mui/material';
import { OATypography } from '../../../components/widgets';
import OAButton from '../../../components/widgets/OAButton';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/error.svg';
import { useState } from 'react';

interface ICancelOptions {
  data: any;
  onHide: (buttonText?: any) => void;
  loading: boolean;
  value: string | null;
  setValue: any;
  remark: string;
  setRemark: any;
}

export const CancelOptions = (props: ICancelOptions) => {
  const [error, setError] = useState<boolean>(false);
  return (
    <Box>
      {error && (
        <Box
          margin='0px 8px 8px 8px'
          display='flex'
          height='40px'
          borderRadius='10px'
          alignItems='center'
          justifyContent='start'
          sx={{ backgroundColor: '#FFE5E2' }}>
          <ErrorIcon style={{ marginLeft: '10px' }} />
          <OATypography
            variant='body2'
            sx={{ fontSize: '12px', lineHeight: '16px', marginLeft: '10px' }}>
            Please select any value before continuing
          </OATypography>
        </Box>
      )}
      {props?.loading ? (
        <>
          {Array.from({ length: 3 }).map((_, i) => (
            <>
              <Box key={i} display='flex' alignItems='center' justifyContent='space-between'>
                <Skeleton variant='text' width={300} height={20} />
                <Skeleton variant='circular' width={20} height={20} />
              </Box>
              <Divider variant='middle' sx={{ margin: '16px 0px 16px 0px' }} />
            </>
          ))}
        </>
      ) : (
        <>
          <Box>
            {props?.data?.map((reasons: any, index: number) => {
              return (
                <>
                  <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <OATypography variant='h5'>{reasons?.display}</OATypography>
                    <div className='form-check form-check-custom form-check-solid form-check-sm me-3'>
                      <input
                        className='form-check-input'
                        type='radio'
                        id={reasons?.id}
                        checked={reasons?.id === props?.value}
                        onClick={() => {
                          props?.setValue(reasons?.id);
                          setError(false);
                        }}
                      />
                    </div>
                  </Box>
                  <Divider variant='middle' sx={{ margin: '16px 0px 16px 0px' }} />
                </>
              );
            })}
          </Box>
          <Box my='16px'>
            <OATypography marginBottom='4px'>Remark</OATypography>
            <textarea
              rows={3}
              placeholder='Any additional remarks'
              className='form-control form-control-sm form-control-solid'
              style={{ fontSize: '14px' }}
              value={props?.remark}
              onChange={e => props?.setRemark(e?.target?.value)}
            />
          </Box>
        </>
      )}
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <OAButton
          label='Back'
          color='light-primary'
          className='btn btn-active-light'
          onClick={() => {
            props?.onHide();
          }}
        />
        <Box display='flex' gap='4px'>
          <OAButton
            label='Cancel'
            color='secondary'
            onClick={() => {
              props?.onHide();
            }}
          />
          <OAButton
            label='Continue'
            onClick={() => {
              !props?.value ? setError(true) : props?.onHide('Continue');
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
