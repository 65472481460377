import { Col } from 'react-bootstrap';

const LoadingState = () => {
  return (
    <Col className='bg-gray-100'>
      <div
        className='d-flex flex-column justify-content-center align-items-center'
        style={{ height: '500px' }}>
        <span
          className='spinner-border spinner-border-xl text-primary align-middle ms-2 mb-3'
          style={{ width: '3rem', height: '3rem' }}
        />
        Classifying documents...
        <span className='text-gray-600'>{`(Do not refresh or go back)`}</span>
      </div>
    </Col>
  );
};

export default LoadingState;
