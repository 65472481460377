import { ChangeEvent, FC, useRef } from 'react';
import { Stack } from '@mui/material';
import OAButton from '../widgets/OAButton';
import { formatWordWithUnderscore } from '../../helper';
import { OATypography } from '../widgets';

interface ICsvActionBar {
  title: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  category: string | undefined;
  productName: string;
  handleDownloadSampleCsv: () => void;
}

const CsvActionBar: FC<ICsvActionBar> = ({
  title,
  onChange,
  category,
  productName,
  handleDownloadSampleCsv
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    // Trigger the click event on the hidden input field
    inputRef.current?.click();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    // Call your original onChange handler
    onChange(event);

    // Reset the input value to ensure onChange triggers every time
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <Stack
      sx={{ border: '1px solid #EFF2F5', padding: '10px 16px' }}
      direction='row'
      justifyContent='space-between'
      alignItems='center'>
      <OATypography className='mb-0 fw-bold'>{`${formatWordWithUnderscore(
        title
      )} - ${productName}`}</OATypography>

      <div>
        <OAButton
          type='button'
          className='btn btn-light-primary fw-bolder me-4'
          label='Sample CSV'
          onClick={handleDownloadSampleCsv}
        />
        <OAButton
          type='button'
          className='btn fw-bolder'
          label='Upload CSV'
          onClick={handleButtonClick}
          disabled={!category}
        />
      </div>
      <input ref={inputRef} type='file' onChange={handleChange} accept='.csv' hidden />
    </Stack>
  );
};

export default CsvActionBar;
