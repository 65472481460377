import { useState } from 'react';
import { eachDayOfInterval } from 'date-fns';
import FileDownload from 'js-file-download';

import API from '../../../helper/API';
import {
  IDashboardFilterQueryParams,
  IDashboardProvider,
  initCountries,
  initOrder,
  initProductSupplier,
  TCountries,
  TOrder,
  TProductSupplier
} from '../type';
import DashboardContext, { IDashboardContext } from './DashboardContext';
import { getDateFormat, showToast } from '../../../helper';
import { DATE_FORMAT_BE } from '../../../constants';

export default function DashboardProvider(props: IDashboardProvider) {
  const { queryParams } = props?.data;
  const [topSuppliers, setTopSuppliers] = useState<TProductSupplier>(initProductSupplier);
  const [topProducts, setTopProducts] = useState<TProductSupplier>(initProductSupplier);
  const [order, setOrder] = useState<TOrder>(initOrder);
  const [report, setReport] = useState<{ isLoading: boolean }>({ isLoading: false });
  const [topCountries, setTopCountries] = useState<TCountries>(initCountries);
  const [visaAtRisk, setVisaAtRisk] = useState<{ data: any; isLoading: boolean }>({
    data: [],
    isLoading: false
  });

  const fetchVisaAtRisk = () => {
    try {
      setVisaAtRisk(prev => ({ ...prev, isLoading: true }));
      API.get(`dashboard/visa/risk`)
        .then((response: any) => {
          setVisaAtRisk({
            data: response?.data?.data?.length ? response?.data?.data : [],
            isLoading: false
          });
        })
        .catch(err => {
          setVisaAtRisk(prev => ({ ...prev, isLoading: false }));
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      setVisaAtRisk(prev => ({ ...prev, isLoading: false }));
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const fetchUpcomingEvents = (start: Date | null, end: Date | null, cb?: (res: any) => void) => {
    let queryStr = ``;
    if (start && end) queryStr = `?start=${start}&end=${end}`;

    try {
      API.get(`/dashboard/order/events${queryStr}`)
        .then((response: any) => {
          const result = response?.data?.data;
          cb?.(result);
        })
        .catch(err => {
          cb?.(null);
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      cb?.(null);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const getTopSuppliers = () => {
    try {
      setTopSuppliers(prev => ({ ...prev, isLoading: true }));
      API.get(`/dashboard/order/suppliers?${buildQueryParams(queryParams)}`)
        .then((response: any) => {
          const resultData = response?.data?.data;
          setTopSuppliers(_prev => ({ data: resultData, isLoading: false }));
        })
        .catch(err => {
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const getTopSellingProducts = () => {
    try {
      setTopProducts(prev => ({ ...prev, isLoading: true }));
      API.get(`/dashboard/order/products?${buildQueryParams(queryParams)}`)
        .then((response: any) => {
          const resultData = response?.data?.data;
          setTopProducts(_prev => ({ data: resultData, isLoading: false }));
        })
        .catch(err => {
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const getTopCountries = () => {
    try {
      setTopCountries(prev => ({ ...prev, isLoading: true }));
      API.get(`/dashboard/order/country?${buildQueryParams(queryParams)}`)
        .then((response: any) => {
          const resultData = response?.data?.data;
          setTopCountries(_prev => ({ data: resultData, isLoading: false }));
        })
        .catch(err => {
          showToast(`API error:${err?.message}`, 'error');
        });
    } catch (error: any) {
      throw showToast(`API error: ${error?.message}`, 'error');
    }
  };

  const getOrderData = () => {
    try {
      setOrder(prev => ({ ...prev, isLoading: true }));
      API.get(`/dashboard/order?${buildQueryParams(queryParams)}`)
        .then((response: any) => {
          const resultData = response?.data?.data;
          const dateRange = queryParams?.dateRange;
          const dateRangeArray = getDatesInRange(dateRange[0], dateRange[1]);
          const dateRangeCategories = getDatesInRangeCategories(dateRangeArray);
          setOrder(_prev => ({
            summaryData: getSummaryBody(resultData),
            orderGraphData: {
              series: [
                {
                  name: 'Pending orders',
                  data: getSeriesData(resultData?.graphPendingOrderData, dateRangeArray)
                },
                {
                  name: 'Processed orders',
                  data: getSeriesData(resultData?.graphProcessedOrderData, dateRangeArray)
                }
              ],
              categories: dateRangeCategories
            },
            revenueGraphData: {
              series: [
                {
                  name: 'Revenue',
                  data: getSeriesData(resultData?.graphRevenueData, dateRangeArray, 'revenue')
                }
              ],
              categories: dateRangeCategories
            },
            isLoading: false
          }));
        })
        .catch(err => {
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const downloadReport = () => {
    try {
      setReport(prev => ({ ...prev, isLoading: true }));
      const APITemp: any = API;
      APITemp.responseType = 'blob';
      APITemp.get(`/orders/report?${buildQueryParams(queryParams)}`)
        .then((response: any) => {
          showToast('Report generated!', 'success');
          setReport(prev => ({ ...prev, isLoading: false }));
          FileDownload(response?.data, 'report.csv');
        })
        .catch((err: any) => {
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const contextValue: IDashboardContext = {
    order,
    topSuppliers,
    topProducts,
    report,
    topCountries,
    visaAtRisk,
    getTopSuppliers,
    getTopSellingProducts,
    getOrderData,
    downloadReport,
    getTopCountries,
    fetchVisaAtRisk,
    fetchUpcomingEvents
  };

  return (
    <DashboardContext.Provider value={contextValue}>{props?.children}</DashboardContext.Provider>
  );
}

const buildQueryParams = (queryParams: IDashboardFilterQueryParams): string => {
  const startDate = getDateFormat(queryParams?.dateRange?.[0] as Date, DATE_FORMAT_BE);
  const endDate = getDateFormat(queryParams?.dateRange?.[1] as Date, DATE_FORMAT_BE);
  let queryStr = `start=${startDate}&end=${endDate}`;
  return queryStr;
};

const getDatesInRange = (startDate: any, endDate: any) => {
  return eachDayOfInterval({
    start: new Date(startDate),
    end: new Date(endDate)
  });
};

const getDatesInRangeCategories = (dateArray: Array<any>) =>
  dateArray.map(obj => getDateFormat(obj, 'MMM dd'));

const getSeriesData = (data: any, dateArray: Array<any>, type: any = '') => {
  const series = dateArray.map(obj => {
    const date = getDateFormat(obj, DATE_FORMAT_BE);
    const findDate = data.find((dt: any) => dt.date === date);
    return findDate ? (type === 'revenue' ? findDate?.revenue : findDate?.orderCount) : 0;
  });
  return series;
};

const getSummaryBody = (data: any) => {
  const insuranceOrder = data?.orderAncillaryBreakUp?.find(
    (obj: any) => obj.ancillaryType === 'INSURANCE'
  );
  const visaOrder = data?.orderAncillaryBreakUp?.find((obj: any) => obj.ancillaryType === 'EVISA');

  return {
    order: {
      title: 'Order Status',
      orders: {
        icon: '/media/icons/duotune/maps/map001.svg',
        count: data?.totalProcessedOrder,
        text: 'Processed',
        color: 'success'
      },
      customer: {
        icon: '/media/icons/duotune/general/gen013.svg',
        count: data?.totalPendingOrder,
        text: 'Pending',
        color: 'warning'
      }
    },
    visa: {
      title: 'Visa',
      orders: {
        icon: '/media/icons/duotune/ecommerce/ecm005.svg',
        count: visaOrder?.orderCount ?? 0,
        text: 'Orders',
        color: 'primary'
      },
      customer: {
        icon: '/media/icons/duotune/communication/com014.svg',
        count: visaOrder?.memberCount ?? 0,
        text: 'Customers',
        color: 'info'
      }
    },
    insurance: {
      title: 'Insurance',
      orders: {
        icon: '/media/icons/duotune/ecommerce/ecm005.svg',
        count: insuranceOrder?.orderCount ?? 0,
        text: 'Orders',
        color: 'primary'
      },
      customer: {
        icon: '/media/icons/duotune/communication/com014.svg',
        count: insuranceOrder?.memberCount ?? 0,
        text: 'Customers',
        color: 'info'
      }
    }
  };
};
