import { useMemo, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';

import OATableComponent, { OATablePaginationProps } from '../../../components/OATableComponent';
import { useUserContext } from '../context/UserContext';
import { getUserColumns } from './UserColumns';
import UserSearch from './UserSearch';
import { showDialog } from '../../../helper';
import { stubTrue } from 'lodash';

export default function UserList() {
  const {
    users,
    pageInfo,
    isLoading,
    fetchUsers,
    onPageChange,
    onSelectEditUser,
    onDeleteUser,
    onPasswordReset
  } = useUserContext();

  const [search, setSearch] = useState<any>('');
  const [resettingUserId, setResettingUserId] = useState<any>(null);

  useEffect(() => {
    if (search && search !== '') {
      fetchUsers(search);
    }
  }, [search]);

  useEffect(() => {
    if (!users) {
      fetchUsers();
    } else if (users?.length) {
    }
  }, [users]);

  const onClickSearchHandler = (value: any) => setSearch(value);

  const handleEditUser = (userId: string) => onSelectEditUser(userId);

  const handleDeleteUser = (userId: string) => {
    showDialog({
      title: 'Delete User?',
      text: 'Are you sure you want to delete this user?',
      icon: 'warning'
    }).then(result => {
      if (result?.isConfirmed) onDeleteUser(userId);
    });
  };

  const handlePasswordReset = (email: string, userId: string) => {
    setResettingUserId(userId); // Set the loading state on the button that's clicked

    let draft = {
      email
    };
    onPasswordReset(draft, (res: any) => {
      setResettingUserId(null); // Reset the loading state once the operation is complete
    });
  };

  const columns = useMemo(
    () =>
      getUserColumns({
        handleEditUser,
        handleDeleteUser,
        handlePasswordReset,
        resettingUserId,
        setResettingUserId
      }),
    [handleEditUser, handleDeleteUser, handlePasswordReset, resettingUserId]
  );

  const paginationProps: OATablePaginationProps = {
    pageIndex: pageInfo?.index,
    pageCount: pageInfo?.count,
    total: pageInfo?.totalCount,
    pageSize: pageInfo?.size,
    onPageChange
  };

  return (
    <Card>
      <Card.Body className='p-0 ps-2'>
        <UserSearch onClickSearchHandler={onClickSearchHandler} />
        <OATableComponent
          columns={columns}
          data={users}
          pagination={paginationProps}
          loading={isLoading}
          disableColumnMenu={false}
        />
      </Card.Body>
    </Card>
  );
}
