import { ErrorMessage, useField } from 'formik';
import {
  DateRangePicker,
  LocalizationProvider,
  SingleInputDateRangeField
} from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import moment from 'moment';
import classNames from 'classnames';

import { OA_DATE_FORMAT_FE_BASIC } from '../../constants';
import { useState } from 'react';

interface IOARangePickerField {
  name: string;
  label: string;
  required?: boolean;
  minDate?: Date;
  maxDate?: Date;
  direction?: any;
}

export default function OARangePickerField(props: IOARangePickerField) {
  const { name, label, required = false } = props;

  const [field, meta, helpers] = useField(name);
  const [open, setOpen] = useState<boolean>(false);

  const labelClassNames = classNames('form-label', {
    required
  });

  const draftStartDate =
    field?.value?.from && moment(field?.value?.from).isValid() ? field?.value?.from : null;
  const draftEndDate =
    field?.value?.to && moment(field?.value?.to).isValid() ? field?.value?.to : null;

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && open === true) {
      // Close the date picker popup
      setOpen(false);
    }

    if (event.key === 'Enter' && open === false) {
      // Open the date picker popup
      setOpen(true);
    }
  };

  return (
    <section className='oa-datepicker-field'>
      <label htmlFor={name} className={labelClassNames}>
        {label}
      </label>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateRangePicker
          open={open}
          onClose={() => setOpen(false)}
          value={[draftStartDate, draftEndDate]}
          onChange={(val: any) => {
            helpers?.setValue({ from: val?.[0], to: val?.[1] });
          }}
          slots={{
            field: SingleInputDateRangeField
          }}
          slotProps={{
            textField: {
              size: 'small',
              onClick: () => setOpen(true),
              onKeyDown: handleKeyPress
            }
          }}
          format={OA_DATE_FORMAT_FE_BASIC}
          minDate={props?.minDate && moment(props?.minDate!)}
          maxDate={props?.maxDate && moment(props?.maxDate!)}
        />
      </LocalizationProvider>
      <div className='text-danger fw-bold fs-9'>
        <ErrorMessage name={name} />
      </div>
    </section>
  );
}
