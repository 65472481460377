import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import { getAgeInYearsOrMonths, getCountryName, getFormattedDate } from '../../../../../helper';
import { OA_DATE_FORMAT_DOB_FE } from '../../../../../constants';
import { ORDER_TYPE } from '../../../constants';
import { IOrderType } from '../../../types';
import { IMember, IPassport } from '../../../../../types';
import { ArrowDownSVG } from '../../../../../components/Icons';

interface IDetailsContainer {
  data: any;
  flight?: boolean;
  onwardData?: any;
  index?: any;
  flightData?: any;
}

const DetailsContainer = (props: IDetailsContainer) => {
  const params = useParams<{ orderType: IOrderType; id?: string }>();
  const [isShowMore, setIsShowMore] = useState<boolean>(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  const isVisa: boolean = params?.orderType === ORDER_TYPE.VISA;

  const labelValuePairs = getLabelValuePairs(
    props?.data,
    isVisa,
    props?.flight,
    props?.onwardData,
    props?.flightData
  );

  return (
    <Row>
      {labelValuePairs?.map((obj: any, index: number) => {
        if (index <= 3) {
          return (
            <Col md='6' lg='6' className='mb-3' key={index}>
              <span className='text-gray-600 min-w-125px w-125px d-inline-block'>{obj?.label}</span>
              <span className='text-gray-900'>{obj?.value}</span>
            </Col>
          );
        }
      })}

      {isShowMore &&
        labelValuePairs?.map((obj: any, index: number) => {
          if (index > 3) {
            return (
              <Col md='6' lg='6' className='mb-3' key={index}>
                <span className='text-gray-600 min-w-125px w-125px d-inline-block'>
                  {obj?.label}
                </span>
                <span className='text-gray-900'>{obj?.value}</span>
              </Col>
            );
          }
        })}
      <div
        className='text-primary text-center mt-2 mb-2 cursor-pointer'
        onClick={toggleReadMoreLess}>
        {isShowMore ? 'View Less' : 'View More'}
        <span className='ms-2'>
          {isShowMore ? <ArrowDownSVG down={false} /> : <ArrowDownSVG down={true} />}
        </span>
      </div>
    </Row>
  );
};

export default DetailsContainer;

const getLabelValuePairs = (
  member: IMember,
  isVisa: boolean,
  flight?: boolean,
  onwardData?: any,
  flightData?: any
) => {
  const passport: IPassport = member?.passport;
  const dob = `${getFormattedDate(
    member?.dateOfBirth,
    OA_DATE_FORMAT_DOB_FE
  )}, ${getAgeInYearsOrMonths(member?.dateOfBirth)}`;

  let pnrNumber, ticketNumber;
  if (member?.ticketDetails?.length > 0) {
    pnrNumber = getTicketAndPnrNumbers(member?.ticketDetails, 'pnrNumber');
    ticketNumber = getTicketAndPnrNumbers(member?.ticketDetails, 'ticketNumber');
  }
  let draftTravelDetailsPairs: any[] = [];
  if (member?.travelDetails?.length) {
    const { travelDetails } = member;
    const arrivalData: any = travelDetails?.find((obj: any) => obj?.travelType === 'ARRIVAL');
    const departureData: any = travelDetails?.find((obj: any) => obj?.travelType === 'DEPARTURE');
    if (arrivalData) {
      draftTravelDetailsPairs = [
        ...draftTravelDetailsPairs,
        {
          label: 'Arrival Carrier Name',
          value: arrivalData?.carrierName
        },
        {
          label: 'Arrival Carrier No.',
          value: arrivalData?.carrierNumber
        },
        {
          label: 'Arrival IATA',
          value: arrivalData?.iata
        },
        {
          label: 'Arrival PNR No.',
          value: arrivalData?.pnrNumber
        }
      ];
    }
    if (departureData) {
      draftTravelDetailsPairs = [
        ...draftTravelDetailsPairs,
        {
          label: 'Departure Carrier Name',
          value: departureData?.carrierName
        },
        {
          label: 'Departure Carrier No.',
          value: departureData?.carrierNumber
        },
        {
          label: 'Departure IATA',
          value: departureData?.iata
        },
        {
          label: 'Departure PNR No.',
          value: departureData?.pnrNumber
        }
      ];
    }
  }

  let labelValuePairs = [
    flight && {
      label: 'PNR Detail',
      value: onwardData?.pnrNumber ?? 'N/A'
    },
    !flight && {
      label: 'Phone',
      value: member?.phone ?? 'N/A'
    },
    !flight && {
      label: 'Email',
      value: member?.email ?? 'N/A'
    },
    isVisa && {
      label: 'Occupation',
      value: member?.occupation
    },
    {
      label: 'Nationality',
      value: getCountryName(member?.nationality)
    },
    member?.dateOfBirth && {
      label: 'Date of birth',
      value: dob
    },
    passport?.number && {
      label: 'Passport number',
      value: passport?.number
    },
    {
      label: 'Gender',
      value: member?.gender ?? 'N/A'
    },
    passport?.expiration && {
      label: 'Passport expiry',
      value: getFormattedDate(passport?.expiration, OA_DATE_FORMAT_DOB_FE)
    },
    !flight && {
      label: 'Member ID',
      value: member?.id
    },
    passport?.issuingCountry &&
      !flight && {
        label: 'Issuing country',
        value: getCountryName(passport?.issuingCountry)
      },
    !flight && {
      label: 'Supplier ID',
      value: member?.supplierMemberId ?? 'N/A'
    },
    passport?.oldPassportCount && {
      label: 'Old passport count',
      value: passport?.oldPassportCount
    },
    member?.birthCountry && {
      label: 'Birth country',
      value: getCountryName(member?.birthCountry)
    },
    member?.birthPlace && {
      label: 'Birth place',
      value: member?.birthPlace
    },
    member?.fatherName && {
      label: "Father's name",
      value: member?.fatherName
    },
    member?.maritalStatus && {
      label: 'Marital status',
      value: member?.maritalStatus
    },
    member?.motherName && {
      label: "Mother's name",
      value: member?.motherName
    },
    member?.profession && {
      label: 'Profession',
      value: member?.profession
    },
    member?.religion && {
      label: 'Religion',
      value: member?.religion
    },
    member?.education && {
      label: 'Education',
      value: member?.education
    },
    member?.previousNationality && {
      label: 'Previous Nationality',
      value: getCountryName(member?.previousNationality)
    },
    member?.annualIncome && {
      label: 'Annual Income',
      value: member?.annualIncome
    },
    ...draftTravelDetailsPairs,
    pnrNumber && {
      label: 'PNR number',
      value: pnrNumber
    },
    (ticketNumber || flight) && {
      label: 'Ticket number',
      value: flight
        ? onwardData?.ticketNumber ??
          onwardData?.ticketNumberInfo?.find((info: any) => info?.travellerId === member?.id)
            ?.ticketNumber ??
          'N/A'
        : ticketNumber ?? 'N/A'
    },
    flightData?.flightSearchInfo?.fareGroup?.toLowerCase() === 'student' && {
      label: 'Student ID',
      value: member?.studentId ?? 'N/A'
    }
  ];

  return labelValuePairs.filter(pair => pair);
};

const getTicketAndPnrNumbers = (arr: any, field: string) => {
  let values: Array<any> = [];

  for (let i = 0; i < arr.length; i++) {
    let fieldValues = arr[i][field]?.split(',').map((item: any) => item.trim());
    values = values.concat(fieldValues);
  }

  return values.join(', ');
};
