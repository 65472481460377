import { Field, Formik, Form, FormikProps } from 'formik';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import OATextAreaField from '../../../../components/fields/OATextAreaField';
import OAModalComponent from '../../../../components/OAModalComponent';
import { useOrderContext } from '../../context/OrderContext';
import { IOrderType } from '../../types';
import OASelectField from '../../../../components/fields/OASelectField';
import { ORDER_TYPE } from '../../constants';
import { useAppContext } from '../../../../context/AppContext';

/**
 * types ...
 */
interface IOrderStatusUpdateModal {
  isOpen: boolean;
  onHide: () => void;
  data: any;
}

export default function OrderStatusUpdateModal(props: IOrderStatusUpdateModal) {
  return (
    <OAModalComponent
      show={props?.isOpen}
      onHide={props?.onHide}
      className='order-status-update__modal'
      header={{
        title: `Update status for ${props?.data?.id}`,
        closeButton: true
      }}
      body={{
        content: <OrderStatusUpdateForm onHide={props?.onHide} data={props?.data} />,
        className: 'order-status-update__modal-body'
      }}
    />
  );
}

/**
 * Additional component : OrderStatusUpdateForm
 */
interface IOrderStatusUpdateForm {
  onHide: () => void;
  data: any;
}

export interface IOrderStatusUpdateFormValues {
  orderStatus: string;
  remark?: string;
}

const init: IOrderStatusUpdateFormValues = {
  orderStatus: '',
  remark: ''
};

const validate = (values: IOrderStatusUpdateFormValues) => {
  const errors: any = {};

  if (!values.orderStatus) {
    errors.orderStatus = 'Required';
  }

  return errors;
};

function OrderStatusUpdateForm(props: IOrderStatusUpdateForm) {
  const params = useParams<{ orderType: IOrderType; id?: string }>();

  const { dataExchange } = useAppContext();
  const { updateOrderInfo } = useOrderContext();

  let updateStatusOptionsLabel = 'Insurance Status';
  let updateStatusOptions = dataExchange?.INSURANCE_UPDATE_STATUS;
  if (params?.orderType === ORDER_TYPE.VISA) {
    updateStatusOptionsLabel = 'Visa Status';
    const productType = props?.data.product?.productInformation?.productType;
    updateStatusOptions =
      productType === 'Sticker'
        ? dataExchange?.STICKER_VISA_UPDATE_STATUS
        : dataExchange?.EVISA_UPDATE_STATUS;
  }
  const handleSubmit = (values: IOrderStatusUpdateFormValues) => {
    updateOrderInfo(params?.orderType!, params?.id!, values, res => {
      if (res?.data?.success) {
        props?.onHide();
      }
    });
  };

  return (
    <Formik initialValues={init} onSubmit={handleSubmit} validate={validate}>
      {(formikProps: FormikProps<IOrderStatusUpdateFormValues>) => (
        <Form className='w-100'>
          <Container fluid className='p-0'>
            {/**
             * NOTE: row contains form fields ...
             */}
            <Row>
              <Col lg={12} className='mb-6'>
                <Field
                  name='orderStatus'
                  label={updateStatusOptionsLabel}
                  as={OASelectField}
                  options={updateStatusOptions}
                  valueOnly
                  required
                />
              </Col>
              <Col lg={12} className='mb-6'>
                <OATextAreaField name='remark' label='Remarks' placeholder='Type here' />
              </Col>
            </Row>
            {/**
             * NOTE: row contains form action buttons ...
             */}
            <Row>
              <Col>
                <div className='d-flex justify-content-end'>
                  <Button
                    type='button'
                    size='sm'
                    onClick={props?.onHide}
                    disabled={formikProps?.isSubmitting}
                    className='btn-light-primary btn-active-light-primary me-4'>
                    Cancel
                  </Button>
                  <Button
                    type='submit'
                    size='sm'
                    disabled={
                      (!formikProps?.isValid && formikProps?.dirty) || formikProps?.isSubmitting
                    }
                    className='btn-primary btn-active-primary'>
                    {formikProps?.isSubmitting ? 'Updating...' : 'Update'}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Form>
      )}
    </Formik>
  );
}
