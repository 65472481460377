import CommentBox from 'react-commenting-engine';
import { useOrderContext } from '../../pages/order-management/context/OrderContext';
import { Box } from '@mui/material';

interface IOAComments {
  orderId: string;
  userName: string;
  userId: string;
  readComment: boolean;
  mode?: any;
  isFlight?: boolean | undefined;
}

const OAComments: React.FC<IOAComments> = ({
  orderId,
  userName,
  userId,
  readComment,
  mode,
  isFlight
}) => {
  const { fetchActivityLogs } = useOrderContext();
  return (
    <Box>
      <CommentBox
        mode={mode}
        username={userName}
        userId={userId}
        orderId={orderId}
        readComment={readComment}
        onCommentAdded={() => {
          isFlight ? null : fetchActivityLogs(orderId, false);
        }}></CommentBox>
    </Box>
  );
};

export default OAComments;
