const BreadcrumbItem = (pageTitle?: string, orderType?: string) => {
  return [
    {
      title: `Insurance - Bulk Upload orders`,
      path: ``,
      isActive: false
    }
  ];
};

export default BreadcrumbItem;
