import { Fragment, useEffect, useRef } from 'react';
import { ErrorMessage, useField, useFormikContext } from 'formik';
import classNames from 'classnames';

interface IOATextField {
  type?: string;
  label?: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  inputClassName?: string;
  disabled?: boolean;
}

export default function OATextField(props: IOATextField) {
  const {
    type = 'text',
    label,
    name,
    required = false,
    inputClassName = '',
    placeholder = 'Type here',
    disabled = false
  } = props;

  const [field, _meta, _helpers] = useField(name);

  const labelClassNames = classNames('form-label', {
    required: required
  });

  const inputClasses = classNames(
    inputClassName,
    'form-control form-control-sm form-control-solid'
  );
  function onKeyDown(keyEvent: any) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const formikBag = useFormikContext();
  const fieldRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const firstError = Object.keys(formikBag.errors)[0];
    if (formikBag.isSubmitting && firstError === name) {
      fieldRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [_meta.error, formikBag.isSubmitting, name, formikBag.errors]);

  return (
    <Fragment>
      {label ? (
        <label htmlFor={name} className={labelClassNames}>
          {label}
        </label>
      ) : null}
      <div ref={fieldRef}>
        <input
          type={type}
          placeholder={placeholder}
          className={inputClasses}
          onKeyDown={onKeyDown}
          {...field}
          disabled={disabled}
        />
        <div className='text-danger'>
          <ErrorMessage name={name} />
        </div>
      </div>
    </Fragment>
  );
}
