import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import isNull from 'lodash/isNull';

import { useOrderContext } from '../../../context/OrderContext';
import { IOrderType, IVisaProduct } from '../../../types';
import MasterProduct from '../../../../../components/ProductCard/MasterProduct';
import TripTypeFilter from '../../../../../components/widgets/TripTypeFilter';
import { ORDER_CREATE_ACTIONS } from '../../../constants';
import OAButton from '../../../../../components/widgets/OAButton';
import moment from 'moment';
import { getFormattedDate } from '../../../../../helper';
import { Paper } from '@mui/material';

interface ISelectVisaForm {
  onSubmit: (
    values: any,
    type: string,
    actions?: FormikHelpers<any>,
    cb?: ((response?: any) => void) | undefined
  ) => void;
}

export default function SelectVisaForm(props: ISelectVisaForm) {
  const params = useParams<{ orderType: IOrderType; id?: string; step?: any }>();
  const location = useLocation();
  let [searchParams] = useSearchParams();
  /**
   * NOTE: get 'cloneOrderId' from 'searchParams' and store clone order data in formState...
   */
  const cloneOrderId = searchParams.get('cloneOrderId');

  let { products, formState } = useOrderContext();

  const { tripDuration } = formState;

  const [visaProducts, setVisaProducts] = useState<Array<any> | null>(null);
  const [tripTypeFilterVal, setTripTypeFilterVal] = useState<any>('ALL');
  /**
   * NOTE: 'isSelected' state handle when to scroll page and when not to ...
   */
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const masterProductsRef = useRef<HTMLDivElement>(null);

  const filteredProducts = products?.filter(
    (prodObj: any) => tripTypeFilterVal === 'ALL' || prodObj?.tripType === tripTypeFilterVal
  );

  useEffect(() => {
    if (masterProductsRef.current && !isSelected) {
      masterProductsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [filteredProducts]);
  /**
   * NOTE: check if products available or not, if yes then filter products based
   * on selected tripType filter and set filtered data on a local state(visaProducts).
   */
  useEffect(() => {
    setVisaProducts(
      products?.filter(
        (prodObj: any) => tripTypeFilterVal === 'ALL' || prodObj?.tripType === tripTypeFilterVal
      ) || null
    );
  }, [products, tripTypeFilterVal]);

  useEffect(() => {
    if (!!cloneOrderId) {
      formState.tripDetails.id =
        formState?.tripDetails?.productInformation?.productAmount?.productId;
    }
  }, [formState, location, params]);

  const onSelectProduct = (values: any, cb?: () => void) => {
    setIsSelected(true);
    setTimeout(() => {
      props?.onSubmit(values, ORDER_CREATE_ACTIONS.ADD_TRIP_DETAILS);
      setIsSelected(false);
      cb?.();
    }, 2000);
  };

  if (isNull(products)) {
    return null;
  }
  if (!visaProducts?.length) {
    return (
      <div className='mb-0 mt-6 d-flex justify-content-between'>
        <h3 className='m-0 mb-5'>No Results Found</h3>
        <TripTypeFilter setFilterValue={setTripTypeFilterVal} selectedValue={tripTypeFilterVal} />
      </div>
    );
  }

  let isdisabled: any;
  if (tripDuration?.from && tripDuration?.to) {
    const minProcessingDateArray = visaProducts.map(visa =>
      moment().add(visa?.maxProcessingTime, visa?.processingTimeUnit?.toLowerCase())
    );

    // Convert dates to timestamps
    const timestampArray = minProcessingDateArray.map((date: any) => new Date(date).getTime());

    // Find the minimum timestamp
    const minTimestamp = Math.min(...timestampArray);

    // Convert the minimum timestamp back to date
    const minDate = moment(minTimestamp);

    const durationFrom = moment(tripDuration?.from).startOf('day');

    // Compare if durationFrom is greater than minProcessingDate
    isdisabled = minDate.isAfter(durationFrom);
    if (isdisabled) {
      isdisabled = getFormattedDate(minDate);
    }
  }

  return (
    <div className='select-visa-form' ref={masterProductsRef}>
      <div className='mb-0 mt-6'>
        <div className='d-flex justify-content-between align-items-center mb-5'>
          <h3 className='fw-bolder m-0'>{`Results (${visaProducts?.length})`}</h3>
          <TripTypeFilter setFilterValue={setTripTypeFilterVal} selectedValue={tripTypeFilterVal} />
        </div>
        {isdisabled && (
          <div
            className='alert-component alert bg-light-warning false border border-warning border-dashed flex-sm-row p-5 mb-4'
            role='alert'>
            The Visa is available post {isdisabled} only
          </div>
        )}
        <Paper variant='outlined' className='master-products-wrapper'>
          {visaProducts?.map((visaProduct: IVisaProduct) => (
            <MasterProduct
              data={visaProduct}
              type='visa'
              onSelectProduct={onSelectProduct}
              duration={tripDuration}
              selectedProductId={formState?.tripDetails?.id}
              disabled={isSelected}
              showSelected={!!cloneOrderId}
              enableQuoteButton={!formState?.groupName}
            />
          ))}
        </Paper>
      </div>
      {formState?.cloneOrderId ? (
        <div className='d-flex bg-white p-4 mt-4 rounded sticky-bottom border border-secondary'>
          <OAButton
            label='Continue'
            disabled={isdisabled || !formState?.tripDetails?.id}
            onClick={() =>
              onSelectProduct({
                requestedProductId:
                  formState?.tripDetails?.productInformation?.productAmount?.productId
              })
            }
            className='ms-auto'
          />
        </div>
      ) : null}
    </div>
  );
}
