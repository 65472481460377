import { ICreateOrderFormState, IOrderType } from '../types';
import { getModifiedMembers } from './getModifiedMembers';

export interface IOrderModificationQuery {
  convertToOrder?: boolean;
}

export const getModifiedOrderData = (
  values: any,
  type: IOrderType,
  query?: IOrderModificationQuery | null
) => {
  const { tripStart, tripEnd, ...restTrip } = values?.trip;

  let draft: ICreateOrderFormState = {
    orderId: values?.id,
    tripDetails: {
      trip: restTrip,
      supplierOrganization: values?.supplierOrganization,
      ...values?.product
    },
    tripDuration: {
      from: tripStart?.datetime ? new Date(tripStart?.datetime) : null,
      to: tripEnd?.datetime ? new Date(tripEnd?.datetime) : null
    },
    travellerDetails: getModifiedMembers(values?.members),
    productAmountToBeCollected: values?.orderAmount?.productAmountToBeCollected
  };
  if (values?.proposerInfo) {
    draft['proposerInfo'] = values?.proposerInfo;
  }
  if (!query?.convertToOrder && values?.bookingStatus === 'ORDER_QUOTE') {
    draft['quotation'] = true;
  }
  if (values?.consulate) {
    draft['consulate'] = values?.consulate;
  }
  if (values?.markup) {
    draft['markup'] = {
      amount: values?.markup?.tax?.totalAmountWithoutTax
    };
  }
  if (values?.taxInfo) {
    draft['taxInfo'] = {
      type: 'GST',
      taxRegistrationNumber: values?.taxRegistrationNumber
    };
  }
  if (values?.additionalOrderInfo) {
    draft['additionalOrderInfo'] = {
      clientName: values?.additionalValues?.clientName,
      paymentReference: values?.additionalOrderInfo?.paymentReference,
      tags: values?.additionalOrderInfo?.tags
    };
  }
  if (values?.orderAmount?.additionalAmountModifiers?.length) {
    const addonServices = values?.orderAmount?.additionalAmountModifiers?.filter((obj: any) =>
      ['ADD_ON_SERVICE_FEE', 'VAC_SERVICE_CHARGE', 'SUPPLIER_FEE'].includes(obj?.type)
    );
    const draftModifiers =
      values?.product?.productInformation?.productAmount?.additionalAmountModifiers?.filter(
        (mod1: any) =>
          addonServices?.some((mod2: any) => mod2?.type === mod1?.type && mod2?.name === mod1?.name)
      );

    if (addonServices?.length) {
      draft['addOnServiceModifier'] = draftModifiers;
    }
  }
  if (values?.category) {
    draft['category'] = values?.category;
  }

  return draft;
};
