import { FC, useEffect, useState } from 'react';
import TaskCard from './TaskCard';
import { useOrderContext } from '../../pages/order-management/context/OrderContext';
import { ITask } from '../../types';
import OAButton from '../widgets/OAButton';
import TaskCreateUpdate from './TaskCreateUpdate';
import { Box, Skeleton } from '@mui/material';
import { OATypography } from '../widgets';
import emptyState from '../../assets/icons/outline/empty-no-tasks.svg';

interface IOATasks {
  orderId: string;
  getTaskCount: (count: number) => void;
}
const OATasks: FC<IOATasks> = ({ orderId, getTaskCount }) => {
  const { orderData, orderActions, getOrderActions } = useOrderContext();

  const isQuotation = orderData?.data?.bookingStatus === 'ORDER_QUOTE';

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editTaskData, setEditTaskData] = useState<ITask | null>(null);
  const [sortedActions, setSortedActions] = useState<any[]>([]);

  useEffect(() => {
    setIsLoading(true);
    getOrderActions(orderId, res => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (orderActions?.data?.length > 0) {
      const tempAction = orderActions?.data;
      tempAction.sort((a: any, b: any) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });
      setSortedActions(tempAction);
    } else {
      setSortedActions([]);
    }
  }, [orderActions?.data]);

  //Count for parent
  useEffect(() => {
    const requestChangeCount = sortedActions.filter(
      (action: ITask) => action?.status !== 'COMPLETED'
    )?.length;
    getTaskCount(requestChangeCount);
  }, [sortedActions]);

  const editTaskHandler = (e: React.MouseEvent, task: ITask) => {
    e.stopPropagation();
    setEditTaskData(task);
    setIsOpen(true);
  };

  const onHideHandler = () => {
    setIsOpen(false);
    setEditTaskData(null);
  };
  /**
   * NOTE: This function will render the add task button only if the order is not a quotation...
   * @returns
   */
  const renderAddTaskButton = () => {
    return !isQuotation ? (
      <OAButton label='Create new task' onClick={() => setIsOpen(true)} />
    ) : null;
  };

  return (
    <>
      <Box display='flex' alignItems='center' justifyContent='end' sx={{ mb: '16px', px: '8px' }}>
        {renderAddTaskButton()}
      </Box>
      <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
        {isLoading ? (
          <>
            <Skeleton variant='rounded' width='100%' height={134} sx={{ mb: '12px' }} />
            <Skeleton variant='rounded' width='100%' height={134} sx={{ mb: '12px' }} />
          </>
        ) : sortedActions?.length > 0 ? (
          sortedActions.map((task: ITask, index: number) => (
            <TaskCard key={index} index={index} task={task} editTaskHandler={editTaskHandler} />
          ))
        ) : (
          <Box sx={{ m: '24px', textAlign: 'center' }}>
            <img src={emptyState} style={{ width: '70px' }} />
            <OATypography
              variant='body1'
              sx={{ mt: '16px', color: theme => theme?.palette?.grey?.[300] }}>
              No tasks have been added yet
            </OATypography>
          </Box>
        )}
      </Box>
      <TaskCreateUpdate show={isOpen} onHide={onHideHandler} editTaskData={editTaskData} />
    </>
  );
};

export default OATasks;
