import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import API from '../../../helper/API';
import { handleS3Download } from '../../../helper/handleDownload';
import { DestinationOptionsMap } from '../../order-management/constants';
import { IOrderType } from '../../order-management/types';
import {
  inits,
  IQuotationFormValues,
  IQuotationProposerValues,
  proposerInits,
  IQuotationProvider
} from '../types';
import QuotationContext, { IQuotationContext } from './QuotationContext';
import { quotationRequestBody } from '../helpers';
import { showToast } from '../../../helper';

export default function QuotationProvider(props: IQuotationProvider) {
  const navigate = useNavigate();
  const [travellerInfo, setTravellerInfo] = useState<IQuotationFormValues>(inits);
  const [proposerInfo, setProposerInfo] = useState<IQuotationProposerValues>(proposerInits);
  const [quotationData, setQuotationData] = useState<{ data: any | null; isLoading: boolean }>({
    data: null,
    isLoading: false
  });

  /**
   * NOTE: This function is used to create or edit existing quotation.
   * @param values
   * @param type
   * @param quotationId
   * @param cb
   */
  const addOrEditQuotation = (
    values: any,
    type: IOrderType,
    quotationId: string | null,
    cb?: () => void
  ) => {
    setQuotationData(prev => ({ ...prev, isLoading: true }));

    let requestBody = quotationRequestBody(values);
    let apiMethod: any;
    if (quotationId) {
      apiMethod = API.put(`/${type}/quotation/${quotationId}`, requestBody);
    } else {
      apiMethod = API.post(`/${type}/quotation`, requestBody);
    }

    try {
      apiMethod
        .then((response: any) => {
          const resultData = response?.data?.data;
          setQuotationData({ data: resultData, isLoading: false });
          cb?.();
          if (resultData?.save) {
            if (values?.downloadFile && resultData?.pdfUrl) {
              handleS3Download(resultData?.pdfUrl);
            }
            showToast(`Quotation ${quotationId ? 'updated' : 'created'}`, 'success');
            navigate(`/apps/quotation/${type}`);
          }
        })
        .catch((err: any) => {
          setQuotationData(prev => ({ ...prev, isLoading: false }));
          showToast(err?.response?.data, 'error');
        });
    } catch (error: any) {
      setQuotationData(prev => ({ ...prev, isLoading: false }));
      throw new Error(`API error: ${error?.message}`);
    }
  };
  /**
   * NOTE: This function is used to fetch quotation by id.
   * @param type
   * @param id
   */
  const getQuotationById = (type: IOrderType, id: string, callback?: (res: any) => void) => {
    setQuotationData(prev => ({ ...prev, isLoading: true }));
    try {
      API.get(`/${type}/quotation/${id}`)
        .then(response => {
          const resultData = response?.data?.data;

          const adultCount = resultData?.memberCount?.find((item: any) => item.type === 'ADULT');
          const seniorCount = resultData?.memberCount?.find((item: any) => item.type === 'SENIOR');
          const childCount = resultData?.memberCount?.find((item: any) => item.type === 'CHILD');
          const infantCount = resultData?.memberCount?.find((item: any) => item.type === 'INFANT');

          setTravellerInfo({
            departure: resultData?.trip?.origin?.countryCode,
            arrival: resultData?.trip?.destination?.map((item: any) => item.countryCode).toString(),
            senior: seniorCount ? seniorCount.count : '',
            adult: adultCount ? adultCount.count : '',
            child: childCount ? childCount.count : '',
            infant: infantCount ? infantCount.count : '',
            tripType: resultData?.trip?.tripType,
            requestedProductId: resultData?.product?.id,
            tripDuration: {
              from: new Date(resultData?.trip?.tripStart?.datetime?.toString()),
              to: new Date(resultData?.trip?.tripEnd?.datetime?.toString())
            },
            destination:
              resultData?.product?.domestic === false
                ? DestinationOptionsMap.ALL
                : DestinationOptionsMap.DOMESTIC,
            occupation: resultData?.occupation,
            supplier: resultData?.linkedProductId
          });
          setProposerInfo({
            proposerEmail: resultData?.proposerInfo?.email,
            proposerName: resultData?.proposerInfo?.name,
            companyName: resultData?.proposerInfo?.companyName,
            additionalEmail: resultData?.additionalEmails?.map((item: any) => item)
          });
          callback?.(resultData);
          setQuotationData(_prev => ({ data: resultData, isLoading: false }));
        })
        .catch(err => {
          setQuotationData(prev => ({ ...prev, isLoading: false }));
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      setQuotationData(prev => ({ ...prev, isLoading: false }));
      throw new Error(`API error: ${error?.message}`);
    }
  };
  /**
   * NOTE: This function is used to fetch countries list.
   * @param cb
   */
  const getCountriesList = async (cb?: (response?: any) => void) => {
    try {
      API.get(`/products/countries`)
        .then(response => {
          const resultData = response?.data?.data;
          cb?.(resultData);
        })
        .catch(err => {
          cb?.(err);
          throw new Error(`API error: ${err?.message}`);
        });
    } catch (error) {
      cb?.(error);
      throw new Error(`API error: ${error}`);
    }
  };

  const contextValue: IQuotationContext = {
    quotationData,
    travellerInformation: travellerInfo,
    proposerInfo,
    getQuotationById,
    addOrEditQuotation,
    setTravellerInformation: setTravellerInfo,
    setProposerInformation: setProposerInfo,
    getCountriesList,
    setQuotationData
  };

  return (
    <QuotationContext.Provider value={contextValue}>{props?.children}</QuotationContext.Provider>
  );
}
