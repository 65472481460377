/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { GridEventListener } from '@mui/x-data-grid-pro';

import { getEVisaColumns, getInsuranceColumns, getGroupOrderColumns } from './OrderColumns';
import OAToolbar, { IBreadcrumbItem } from '../../../components/widgets/OAToolbar';
import OARangePicker from '../../../components/OARangePicker';
import { useOrderContext } from '../context/OrderContext';
import AssignUserModal from '../shared/AssignUserModal';
import { ORDER_TYPE } from '../constants';
import { useAuth } from '../../../auth';
import OrderSearch from './OrderSearch';
import { IOrderType } from '../types';
import OATableComponent, { OATablePaginationProps } from '../../../components/OATableComponent';
import { useAppContext } from '../../../context/AppContext';

interface IOrderListQueryParams {
  data: any;
}
const OrderList = (props: IOrderListQueryParams) => {
  const { queryParams, setQueryParams } = props?.data;
  const { orderType } = useParams();
  const navigate = useNavigate();

  const { currentUser } = useAuth();
  const ancillaryType = currentUser?.data?.organization?.ancillaryType;
  const { dataExchange } = useAppContext();
  const {
    ordersData,
    pageInfo,
    fetchOrders,
    getInitQueryParams,
    fetchOrderGroups,
    assignReviewer,
    resetFormState
  } = useOrderContext();

  if (
    (orderType === 'visa' && !ancillaryType?.includes('EVISA')) ||
    (orderType === 'insurance' && !ancillaryType?.includes('INSURANCE')) ||
    (!ancillaryType?.includes('EVISA') && !ancillaryType?.includes('INSURANCE'))
  ) {
    navigate('/error/404');
  }
  const { data, isLoading } = ordersData;

  const [ordersList, setOrdersList] = useState<Array<any>>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [totalOrder, setTotalOrder] = useState<any>();
  const [isAssignUserModalOpen, setIsAssignUserModalOpen] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any | null>(null);
  const [selectedTab, setSelectedTab] = useState('regular');
  const [orderTypeChange, setOrderTypeChange] = useState<string | null>(null);

  // TODO : Temp Implimetation
  const localStorageIsGroupTab = localStorage.getItem('isGroupTab');
  if (localStorageIsGroupTab === 'true' && orderType === ORDER_TYPE.VISA) {
    setSelectedTab('group');
    localStorage.removeItem('isGroupTab');
  }

  useEffect(() => {
    resetFormState();
  }, []);

  useEffect(() => {
    setIsLoadingData(true);
    setOrdersList([]);
    if (selectedTab === 'group' && orderType === ORDER_TYPE.VISA)
      fetchOrderGroups(orderType as IOrderType, res => {
        const resData = res?.data?.data;
        if (res?.status === 200 && resData) {
          getTotalOrder(resData);
          setOrdersList(resData?.content);
        }
        setIsLoadingData(false);
      });
    else
      fetchOrders(orderType as IOrderType, res => {
        const resData = res?.data?.data;
        if (res?.status === 200 && resData) {
          getTotalOrder(resData);
          setOrdersList(resData?.content);
        }
        setIsLoadingData(false);
      });
  }, [queryParams, orderType, selectedTab]);

  const getTotalOrder = (value: any) => {
    const draftParams = {
      totalOrderCount: value?.allTotalOrder,
      unassignedOrder: value?.totalUnAssignedOrder,
      cancelledOrderCount: value?.totalCancelledOrder,
      quotationCount: value?.totalQuoteOrder,
      draftOrderCount: value?.totalDraftOrder,
      submitOrderCount: value?.totalSubmitOrder,
      completedOrderCount: value?.totalCompletedOrder,
      pendingActionOrderCount: value?.totalPendingActionOrder ?? 0,
      cancelRequested: value?.totalCancellationRequestedOrder,
      totalPages: value?.totalPages
    };
    setTotalOrder(draftParams);
  };

  const toggleAssignUserModal = (rowData: any) => {
    setSelectedRow(rowData);
    setIsAssignUserModalOpen(true);
  };

  const handleCloseAssignUserModal = () => setIsAssignUserModalOpen(false);

  const handleAssignReviewers = (userId: string, cb?: (res: any) => void) => {
    assignReviewer(selectedRow?.linkedOrder?.id, userId, false, _res => {
      handleCloseAssignUserModal();
      setSelectedRow(null);
      cb?.(_res);
    });
  };

  const pageTitle =
    orderType === ORDER_TYPE.VISA ? 'Visa' : orderType === ORDER_TYPE.INSURANCE ? 'Insurance' : '';

  const breadcrumb: Array<IBreadcrumbItem> = [
    {
      title: `Dashboard`,
      path: `/dashboard`,
      isActive: false
    },
    {
      title: `${pageTitle} Order Management`,
      path: ``,
      isActive: true
    }
  ];
  /**
   * NOTE: orderType is coming from route parameters. If orderType doesn't match
   * ORDER_TYPE.VISA or ORDER_TYPE.INSURANCE then just redirect to error page.
   * And if orderType is matching either of ORDER_TYPE.VISA or ORDER_TYPE.INSURANCE
   * then proceed to call api with the orderType and show respective view for that.
   */
  if (![ORDER_TYPE.VISA, ORDER_TYPE.INSURANCE].includes(orderType as IOrderType)) {
    return <Navigate to='/error/404' />;
  }

  const onClickTabHandller = (tab: 'regular' | 'group') => {
    if (tab === 'group') {
      setSelectedTab('group');
    } else {
      setSelectedTab('regular');
    }
  };

  const onChangeRangePicker = (range: any) => {
    setQueryParams((prev: any) => ({ ...prev, dateRange: range }));
  };
  // TODO: add sorting option to table
  // const handleSortChange = (columnId: string, isDesc: 'DESC' | 'ASC') => {
  //   setQueryParams(prev => ({ ...prev, sortByField: columnId, sortBy: isDesc }));
  // };

  const columns = useMemo(() => {
    orderTypeChange && orderTypeChange !== orderType ? getInitQueryParams() : null;
    setOrderTypeChange(orderType ?? null);
    queryParams?.dateRange.length < 0 ? getInitQueryParams() : null;

    if (selectedTab === 'group' && orderType === ORDER_TYPE.VISA) {
      return getGroupOrderColumns();
    } else {
      return orderType === ORDER_TYPE.VISA
        ? getEVisaColumns(currentUser, dataExchange, toggleAssignUserModal, {
            status: queryParams?.orderStatus
          })
        : getInsuranceColumns(currentUser, dataExchange);
    }
  }, [selectedTab, orderType, dataExchange, queryParams]);

  const paginationProps: OATablePaginationProps = {
    pageIndex: queryParams?.pageIndex,
    pageCount: pageInfo?.totalPages,
    total: pageInfo?.totalCount,
    pageSize: pageInfo?.size,
    onPageChange: (value: any) => {
      setQueryParams((prev: any) => ({ ...prev, pageIndex: value }));
    }
  };

  /**
   *
   * @param params
   * @param event
   */
  const handleCellClick: GridEventListener<'cellClick'> = (params, event) => {
    if (params.field === 'assignedTo') {
      event.stopPropagation();
    } else {
      const selectedText = window.getSelection()?.toString();
      if (selectedText) {
        return;
      } else if (selectedTab === 'group' && orderType === ORDER_TYPE.VISA) {
        navigate(`group/${params?.id}?group=true`);
      } else {
        navigate(`${params?.row?.linkedOrder?.id}?group=false`);
      }
    }
  };

  return (
    <React.Fragment>
      <OAToolbar
        title={pageTitle}
        breadcrumb={breadcrumb}
        actionsElement={
          <div className='d-flex'>
            <div className='me-4'>
              <OARangePicker
                className='dashboard-data-range-filter'
                name='order-list-data-range'
                onChangeRangePicker={onChangeRangePicker}
                dateRange={queryParams?.dateRange}
                maxDate={new Date()}
                shortcuts
              />
            </div>
            {orderType === ORDER_TYPE.INSURANCE && (
              <Link to='bulk-upload' className='btn btn-sm btn-secondary me-4'>
                Bulk Upload
              </Link>
            )}
            <Link
              to='create/1'
              className='btn btn-sm btn-primary'
              state={{ selectedTab: selectedTab }}>
              Create Order
            </Link>
          </div>
        }
      />
      {orderType === ORDER_TYPE.VISA && (
        <div>
          <button
            onClick={() => onClickTabHandller('regular')}
            className={`oa-tab-btn fs-5 fw-bolder ms-2 pb-3 ${
              selectedTab === 'regular' && 'active'
            }`}
            disabled={isLoadingData}>
            Regular
          </button>
          <button
            onClick={() => onClickTabHandller('group')}
            className={`oa-tab-btn fs-5 fw-bolder ms-2 pb-3 ${selectedTab === 'group' && 'active'}`}
            disabled={isLoadingData}>
            Groups
          </button>
        </div>
      )}
      <Card>
        <OrderSearch
          totalOrder={totalOrder}
          isLoading={isLoading}
          isGroupOrder={selectedTab === 'group' && orderType === ORDER_TYPE.VISA}
          queryData={props}
        />
        <Card.Body className='p-0 px-2'>
          <React.Fragment>
            <OATableComponent
              columns={columns}
              data={ordersList ?? []}
              pagination={paginationProps}
              // loading={isLoading}
              loading={isLoadingData}
              disableColumnMenu={false}
              onCellClick={handleCellClick}
            />
            <AssignUserModal
              onHide={handleCloseAssignUserModal}
              show={isAssignUserModalOpen}
              handleAssignReviewers={handleAssignReviewers}
            />
          </React.Fragment>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default OrderList;
