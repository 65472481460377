/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { FormikHelpers, FormikValues, Form, Formik, FormikProps } from 'formik';
import { Paper } from '@mui/material';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

import { useOrderContext } from '../../../context/OrderContext';
import { ORDER_CREATE_ACTIONS, ORDER_TYPE } from '../../../constants';
import { IOrderType } from '../../../types';
import ReviewOrderTable from './ReviewOrderTable';
import OATextField from '../../../../../components/fields/OATextField';
import OAButton from '../../../../../components/widgets/OAButton';
import OAModalComponent from '../../../../../components/OAModalComponent';
import AddGstNumberForm from './AddOrEditGstForm';
import AddOrEditMarkupForm from './AddOrEditMarkupForm';
import { showToast } from '../../../../../helper';
import { AddonServicesSection } from './AddonServicesSection';
import { IOrderSectionHeaderProps, OrderSectionHeader } from '../../../shared/OrderSectionHeader';
import OATagsBasedInput from '../../../../../components/OATagsBasedInput';
import OrderInvoice from '../../../order-view/OrderTabs/PaymentSummary/OrderInvoice';
import PriceTable from '../../../../quotation/create/Preview/PriceTable';

interface IReviewOrderProps {
  type: IOrderType;
  onSubmit?: (
    values: any,
    type: string,
    actions?: FormikHelpers<any>,
    cb?: (response?: any) => void
  ) => void;
  handleStepChange?: (actions?: FormikValues) => void;
  className?: string;
}

const initValues = {
  clientName: '',
  proposerName: '',
  proposerEmail: '',
  paymentReference: '',
  tags: ''
};

export default function ReviewOrder(props: IReviewOrderProps) {
  const params = useParams<{ orderType: IOrderType; id?: string }>();
  const navigate = useNavigate();

  const {
    draftOrder,
    formState,
    onEditDraftOrder,
    onSubmitOrder,
    onCreateDraftQuote,
    getDraftOrderQuotation
  } = useOrderContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [isLoadingDraft, setIsLoadingDraft] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [addonServices, setAddonServices] = useState<Array<any>>([]);
  const [isGstModalOpen, setIsGstModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (
      formState?.tripDetails?.productInformation?.productAmount?.additionalAmountModifiers?.length
    ) {
      const draftAddonModifiers =
        formState?.tripDetails?.productInformation?.productAmount?.additionalAmountModifiers?.filter(
          (obj: any) => obj?.type === 'ADD_ON_SERVICE_FEE'
        );
      setAddonServices(draftAddonModifiers);
    }
  }, [formState?.tripDetails]);

  const toggleModal = (open?: boolean) => setIsOpen(prev => !prev);

  const toggleGstModal = () => setIsGstModalOpen(prev => !prev);

  const onMarkup = (value: any) => {
    setIsLoading(true);
    formState.travellerDetails = formState?.travellerDetails?.length
      ? formState?.travellerDetails
      : draftOrder?.members;

    if (formState?.quotation) {
      onCreateDraftQuote(
        params?.orderType as IOrderType,
        { markup: value?.markup },
        (_res: any) => {
          if (_res?.status === 200) {
            showToast('Markup modified successfully', 'success');
          }
          setIsLoadingSubmit(false);
          toggleModal();
        }
      );
    } else {
      getDraftOrderQuotation(params?.orderType as IOrderType, null, value, (_res: any) => {
        setIsLoading(false);
        toggleModal();
      });
    }
  };

  const onGstSubmit = (value: any) => {
    setIsLoading(true);
    formState.travellerDetails = formState?.travellerDetails?.length
      ? formState?.travellerDetails
      : draftOrder?.members;

    props?.onSubmit?.(value, ORDER_CREATE_ACTIONS.ADD_GST, undefined, _res => {
      setIsLoading(false);
      setIsGstModalOpen(false);
    });
  };

  // const onClickQuoteDownload = () => {
  //   setIsLoading(true);
  //   formState.travellerDetails = formState?.travellerDetails?.length
  //     ? formState?.travellerDetails
  //     : draftOrder?.members;

  //   props?.onSubmit?.(null, ORDER_CREATE_ACTIONS.DOWNLOAD_QUOTE, undefined, _res => {
  //     setIsLoading(false);
  //   });
  // };

  const handleAddOrRemoveAddonService = (event: any) => {
    const selectedAddonService = addonServices?.find(
      (obj: any) => obj?.name === event?.target?.value
    );
    const draftAddon = Object.assign({}, selectedAddonService, {
      action: event?.target?.checked ? 'add' : 'remove'
    });

    formState.travellerDetails = formState?.travellerDetails?.length
      ? formState?.travellerDetails
      : draftOrder?.members;
    /**
     * NOTE: If quotation, then we need to call create draft quote API else call
     * get draft order quotation API...
     */
    if (formState?.quotation) {
      onCreateDraftQuote(
        params?.orderType as IOrderType,
        { addonModifiers: draftAddon },
        (_res: any) => {
          if (_res?.status === 200) {
            showToast('Premium services modified successfully', 'success');
          }
          setIsLoadingSubmit(false);
        }
      );
    } else {
      const draftAddonService = Object.assign({}, selectedAddonService, {
        action: event?.target?.checked ? 'add' : 'remove'
      });

      getDraftOrderQuotation(
        params?.orderType as IOrderType,
        { addOnService: draftAddonService },
        null
      );
    }
  };

  const handleSubmitOrderSummary = (values: any, _actions: FormikHelpers<any>) => {
    setIsLoadingSubmit(true);
    const orderId = params?.id ?? formState?.orderId;
    const additionalValues = {
      additionalValues: values
    };
    formState.travellerDetails = formState?.travellerDetails?.length
      ? formState?.travellerDetails
      : draftOrder?.members;

    if (formState?.quotation) {
      const draftValues = {
        save: true,
        proposerInfo: {
          name: values?.proposerName,
          email: values?.proposerEmail,
          companyName: values?.clientName
        }
      };

      onCreateDraftQuote(params?.orderType as IOrderType, draftValues, (_res: any) => {
        if (_res?.status === 200) {
          const draft_success_text =
            params?.orderType === ORDER_TYPE.VISA
              ? 'Visa quotation created successfully.'
              : 'Insurance quotation created successfully.';
          showToast(draft_success_text, 'success');

          const id = _res?.data?.data?.id;
          if (id) navigate(`/apps/orders/${params?.orderType}/${id}`);
        }
        setIsLoadingSubmit(false);
      });
    } else {
      onEditDraftOrder(orderId, params?.orderType as IOrderType, additionalValues, (_res: any) => {
        if (_res?.status === 200) {
          const draft_success_text =
            params?.orderType === ORDER_TYPE.VISA
              ? 'Visa draft created successfully.'
              : params?.orderType === ORDER_TYPE.INSURANCE
              ? 'Insurance draft created successfully.'
              : '';
          showToast(draft_success_text, 'success');
          const id = _res?.data?.data?.id;
          if (id) navigate(`/apps/orders/${params?.orderType}/${id}`);
          else navigate(`/apps/orders/${params?.orderType}`);
        }
        setIsLoadingSubmit(false);
      });
    }
  };

  const onCreateSubmit = (values: any) => {
    setIsLoadingDraft(true);
    onSubmitOrder(values, params?.orderType as IOrderType, draftOrder?.id, _res => {
      if (_res?.status === 200) {
        const id = _res?.data?.data?.id;
        if (id) navigate(`/apps/orders/${params?.orderType}/${id}`);
        else navigate(`/apps/orders/${params?.orderType}`);
      }
      setIsLoadingDraft(false);
    });
  };

  const onAmountCollectedChange = (modifier: any) => {
    if (formState?.quotation) {
      onCreateDraftQuote(
        params?.orderType as IOrderType,
        { amountModifier: modifier },
        (_res: any) => {
          if (_res?.status === 200) {
            showToast('Order amount modified successfully', 'success');
          }
          setIsLoadingSubmit(false);
        }
      );
    } else {
      getDraftOrderQuotation(params?.orderType as IOrderType, { modifier }, null);
    }
  };

  const validationSchema = formState?.quotation
    ? Yup.object({
        proposerName: Yup.string().required().label('Proposer name'),
        proposerEmail: Yup.string().email().required().label('Proposer email'),
        clientName: Yup.string().required().label('Client name')
      })
    : Yup.object({});

  const isVisaOrder = params.orderType === ORDER_TYPE.VISA;
  const isInsuranceOrder = params.orderType === ORDER_TYPE.INSURANCE;
  /**
   * NOTE: If not quotation, then only add subtitle...
   */
  let orderSectionHeaderProps: IOrderSectionHeaderProps = {
    title: 'Review',
    subtitle: !formState?.quotation
      ? `${draftOrder?.members?.length} Traveller${draftOrder?.members?.length > 1 ? 's' : ''}`
      : ``
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={initValues}
        onSubmit={handleSubmitOrderSummary}
        validationSchema={validationSchema}
        enableReinitialize>
        {(_formikProps: FormikProps<any>) => {
          const handleOnChange = (_: any, newValue: string[]) => {
            _formikProps?.setFieldValue('tags', newValue);
          };

          return (
            <Form className='order-summary-form w-100'>
              <AddonServicesSection
                addonServices={addonServices}
                isVisa={isVisaOrder}
                values={formState?.addOnServiceModifier}
                onChangeAddonService={handleAddOrRemoveAddonService}
              />

              <Paper variant='outlined' className='w-100 p-6 mb-6'>
                <OrderSectionHeader {...orderSectionHeaderProps}>
                  <div>
                    {isInsuranceOrder && !formState?.quotation ? (
                      <OAButton
                        label={
                          formState?.taxInfo?.taxRegistrationNumber
                            ? 'Added GST number'
                            : 'Add GST number'
                        }
                        onClick={() => toggleGstModal()}
                        icon={getSvgUrl(formState?.taxInfo?.taxRegistrationNumber)}
                        color='light-primary'
                      />
                    ) : null}
                    {isVisaOrder ? (
                      <OAButton
                        label={formState?.markup?.amount ? 'Edit Markup' : 'Add Markup'}
                        onClick={() => toggleModal()}
                        // icon={getSvgUrl(formState?.markup?.amount)}
                        color='light-primary'
                      />
                    ) : null}
                    {/* <OAButton
                        label='Download Quote'
                        onClick={() => onClickQuoteDownload()}
                        color='light-primary'
                        className='ms-3'
                      /> */}
                  </div>
                </OrderSectionHeader>
                {params?.orderType === ORDER_TYPE.VISA ? (
                  draftOrder?.id ? (
                    <OrderInvoice
                      data={draftOrder}
                      handleAmountCollected={onAmountCollectedChange}
                      type='proforma'
                    />
                  ) : null
                ) : (
                  <Fragment>
                    {formState?.quotation ? (
                      <PriceTable data={draftOrder} />
                    ) : (
                      <ReviewOrderTable data={draftOrder} />
                    )}
                  </Fragment>
                )}
              </Paper>

              <Paper variant='outlined' className='w-100 p-6 mb-6'>
                {!formState?.quotation ? (
                  <Fragment>
                    <OrderSectionHeader
                      title='Other info'
                      subtitle='Add relevant details for this order'
                    />
                    <Row>
                      <Col className='mb-4'>
                        <OATextField name='clientName' label='Client name' />
                      </Col>
                      <Col className='mb-4'>
                        <OATextField name='paymentReference' label='Payment reference' />
                      </Col>
                      <Col className='mb-4'>
                        <label className='form-label'>Add tags for search</label>
                        <OATagsBasedInput
                          inputTagId='tags-filled'
                          textFieldName='tags'
                          textFieldPlaceholder='Press comma (",") to create a new tag'
                          inputValue={formState?.additionalOrderInfo?.tags}
                          onChange={handleOnChange}
                        />
                      </Col>
                    </Row>
                  </Fragment>
                ) : (
                  <Fragment>
                    <OrderSectionHeader
                      title='Customer information'
                      // subtitle='Add relevant details for this order'
                    />
                    <Row>
                      <Col className='mb-4'>
                        <OATextField name='proposerName' label='Proposer name' required />
                      </Col>
                      <Col className='mb-4'>
                        <OATextField name='proposerEmail' label='Proposer email' required />
                      </Col>
                      <Col className='mb-4'>
                        <OATextField name='clientName' label='Client name' required />
                      </Col>
                    </Row>
                  </Fragment>
                )}
              </Paper>

              <Paper variant='outlined' className='w-100 p-6 text-end'>
                <OAButton
                  type='submit'
                  label={
                    isVisaOrder || (isInsuranceOrder && formState?.quotation)
                      ? `Create ${formState?.quotation ? 'quotation' : 'order'}`
                      : 'Save as draft'
                  }
                  isLoading={isLoadingSubmit}
                  disabled={isLoadingSubmit || isLoadingDraft || !isEmpty(_formikProps?.errors)}
                  color={
                    isVisaOrder || (isInsuranceOrder && formState?.quotation)
                      ? 'primary'
                      : 'light-primary'
                  }
                />
                {params.orderType === ORDER_TYPE.INSURANCE && !formState?.quotation && (
                  <OAButton
                    label='Submit order'
                    isLoading={isLoadingDraft}
                    disabled={isLoadingSubmit || isLoadingDraft}
                    onClick={() => onCreateSubmit(_formikProps?.values)}
                    className='ms-3'
                  />
                )}
              </Paper>
            </Form>
          );
        }}
      </Formik>

      <OAModalComponent
        className='add-or-edit-markup--modal'
        show={isOpen}
        onHide={toggleModal}
        header={{
          title: `${formState?.markup?.amount ? 'Edit' : 'Add'} Price Markup`,
          closeButton: true
        }}
        body={{
          content: (
            <AddOrEditMarkupForm
              onHide={toggleModal}
              onMarkupSubmit={onMarkup}
              value={formState?.markup?.amount}
            />
          )
        }}
      />

      <OAModalComponent
        className='add-or-edit-gst--modal'
        show={isGstModalOpen}
        onHide={toggleGstModal}
        header={{
          title: `${formState?.taxInfo?.taxRegistrationNumber ? 'Edit' : 'Add'} GST Number`,
          closeButton: true
        }}
        body={{
          content: (
            <AddGstNumberForm
              onHide={toggleGstModal}
              onGstSubmit={onGstSubmit}
              value={formState?.taxInfo?.taxRegistrationNumber}
            />
          )
        }}
      />
    </React.Fragment>
  );
}

const getSvgUrl = (value: any) =>
  `/media/icons/duotune/${value ? 'arrows/arr085' : 'abstract/abs011'}.svg`;
