import axios from 'axios';
import { AuthModel, UserModel } from './_models';

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/api/internal/users/verify/me`;
export const LOGIN_URL = `${API_URL}/api/internal/auth/token`;
export const REGISTER_URL = `${API_URL}/register`;
export const SIGNUP_URL = `${API_URL}/users/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`;

// Server should return AuthModel
export function login(email: string, password: string, newPassword?: string, isSso?: boolean) {
  let requestBody;
  if (isSso) {
    requestBody = {
      loginType: email,
      externalToken: password,
      loginId: newPassword
    };
  } else {
    requestBody = {
      email,
      password,
      newPassword
    };
  }

  return axios.post<AuthModel>(LOGIN_URL, requestBody);
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation
  });
}

export function signup(fullName: string, email: string, companyName: string, password: string) {
  return axios.post(SIGNUP_URL, {
    fullName,
    email,
    companyName,
    password
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email
  });
}

export function getUserByToken(token: string) {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL);
}

export function getAccessTokenByRefreshToken(refreshToken: string) {
  return axios.post(`${API_URL}/api/internal/auth/refreshToken`, {
    refreshToken
  });
}
