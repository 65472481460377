import { FC, cloneElement, useState } from 'react';
import { Box, BoxProps, Paper, Popper, Zoom } from '@mui/material';
import { Placement } from '@popperjs/core';

import OATypography from './OATypography';

interface OAHoverPopoverProps {
  children: React.ReactNode | React.ReactNode[];
  popperText?: string;
  placement?: Placement;
  popperComponent?: React.ReactNode;
  wrapperProps?: BoxProps;
}

const OAHoverPopover: FC<OAHoverPopoverProps> = ({
  children,
  wrapperProps = {},
  placement = 'bottom',
  ...restProps
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);

  const handlePopoverClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);

  return (
    <Box {...wrapperProps}>
      {cloneElement(children as React.ReactElement, {
        'aria-owns': open ? 'mouse-over-popover' : undefined,
        'aria-haspopup': 'true',
        'onMouseEnter': handlePopoverOpen,
        'onMouseLeave': handlePopoverClose
      })}
      <Popper
        id='mouse-over-popover'
        sx={{
          pointerEvents: 'none'
        }}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition>
        {({ TransitionProps }) => (
          <Zoom {...TransitionProps} timeout={200}>
            <Paper elevation={3}>
              {restProps?.popperText ? (
                <OATypography variant='body2' sx={{ p: 1 }}>
                  {restProps?.popperText}
                </OATypography>
              ) : (
                restProps?.popperComponent ?? null
              )}
            </Paper>
          </Zoom>
        )}
      </Popper>
    </Box>
  );
};

export default OAHoverPopover;
