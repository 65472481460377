import { FC, useEffect, useState } from 'react';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { OAMultiSelectField, OASelectField, OATextAreaField } from '../../fields';
import { useOrderContext } from '../../../pages/order-management/context/OrderContext';
import { IDocument, IMember, ITask } from '../../../types';
import OAButton from '../../widgets/OAButton';
import { useAuth } from '../../../auth';
import { TravellerFormFields } from '../../../pages/order-management/constants';
import { Alert } from '@mui/material';

const TYPE = {
  TRIP_INFO: ['TRIP_DURATION', 'VISA_SELECTED'],
  MEMBER_INFO: ['MEMBER_PERSONAL_INFO'],
  MEMBER_DOCUMENTS: ['MEMBER_DOCUMENT', 'MEMBER_ADDITIONAL_DOCUMENT_REQUIRED'],
  OTHERS: ['OTHERS']
};

export const REASON_OPTIONS = [
  { value: 'MEMBER_PERSONAL_INFO', label: 'Update Traveller info' },
  { value: 'MEMBER_DOCUMENT', label: 'Re-upload traveller document' },
  {
    value: 'MEMBER_ADDITIONAL_DOCUMENT_REQUIRED',
    label: 'Request additional traveller document'
  },
  { value: 'TRIP_DURATION', label: 'Update travel duration' },
  { value: 'VISA_SELECTED', label: 'Update visa selected' },
  { value: 'OTHERS', label: 'Other' }
];

const MEMBER_LEVEL_TASK = [
  'MEMBER_PERSONAL_INFO',
  'MEMBER_DOCUMENT',
  'MEMBER_ADDITIONAL_DOCUMENT_REQUIRED'
];

const findTypeByReason = (reason: any) => {
  for (const [key, values] of Object.entries(TYPE)) {
    if (values.includes(reason)) {
      return key;
    }
  }
  return 'OTHERS';
};

interface ILabelValue {
  label: string;
  value: string;
}
interface ITaskForm {
  onHide: () => void;
  editTaskData: ITask | null;
}

const init = {
  reason: '',
  comment: ''
};

const validate = (values: any) => {
  const errors: any = {};
  const { reason, memberId, resource, comment } = values;

  if (!reason) errors.reason = 'Reason is a required field';
  if (!comment?.trim()) errors.comment = 'Comment is a required field';
  if (MEMBER_LEVEL_TASK.includes(reason)) {
    if (!memberId) errors.memberId = 'Traveller is a required field';

    if (!resource || resource?.length === 0) errors.resource = 'Document is a required field';
  }
  return errors;
};

const TaskForm: FC<ITaskForm> = ({ onHide, editTaskData }) => {
  const { currentUser } = useAuth();
  const {
    orderData,
    orderActions,
    createOrderAction,
    searchAdditionalDocuments,
    updateOrderAction
  } = useOrderContext();
  const {
    data: { members, product, supplierOrganization }
  } = orderData;

  const [initValues, setInitValues] = useState<any>(init);
  const [reasonOptions, setReasonOptions] = useState<ILabelValue[]>([]);
  const [memberOptions, setMemberOptions] = useState<ILabelValue[]>([]);
  const [fieldsOption, setFieldsOption] = useState<ILabelValue[]>([]);
  const [additionalDocumentsOptions, setAdditionalDocumentsOptions] = useState<ILabelValue[]>([]);
  const [additionalDocuments, setAdditionalDocuments] = useState<IDocument[]>([]);

  const getGroupedByReason = (orderAct: any) => {
    return orderAct?.reduce((acc: any, item: any) => {
      // Skip the item if its status is 'COMPLETED'
      if (item.status === 'COMPLETED') {
        return acc;
      }

      const key = item.reason;
      if (!acc[key]) {
        acc[key] = [];
      }

      // Special handling for 'MEMBER_PERSONAL_INFO'
      // item?.status !== 'IN_REVIEW' condition added because of old data crashing app
      if (key === 'MEMBER_PERSONAL_INFO' && item?.status !== 'IN_REVIEW') {
        if (!acc[key].missingFields) {
          acc[key].missingFields = [];
        }
        acc[key].missingFields = Array.from(
          new Set([
            ...Array.from(acc[key].missingFields || []),
            ...item.missingFields.map((field: any) => field.value)
          ])
        );
      }
      // Special handling for 'MEMBER_ADDITIONAL_DOCUMENT_REQUIRED' and 'MEMBER_DOCUMENT'
      else if (['MEMBER_ADDITIONAL_DOCUMENT_REQUIRED', 'MEMBER_DOCUMENT'].includes(key)) {
        if (!acc[key].memberDocuments) {
          acc[key].memberDocuments = [];
        }
        acc[key].memberDocuments.push(item.memberDocument);
      } else {
        acc[key].push(item);
      }

      return acc;
    }, {});
  };

  useEffect(() => {
    const groupedByReason: any = getGroupedByReason(orderActions?.data);

    /**
     * NOTE: if logged in user's org id matches order's supplierOrganization id then
     * logged in user can be considered as a supplier and can perform all the supplier's
     * actions...
     */
    const hasSupplierActionAccess = currentUser?.data?.organizationId === supplierOrganization?.id;
    if (hasSupplierActionAccess) setReasonOptions(REASON_OPTIONS);
    else {
      const filterReason = REASON_OPTIONS.filter((obj: any) =>
        MEMBER_LEVEL_TASK.includes(obj?.value)
      );
      setReasonOptions(filterReason);
    }

    if (members?.length > 0) {
      setMemberOptions(
        members?.map((member: IMember) => ({
          label: `${member?.firstName} ${member?.lastName}`,
          value: member?.id
        }))
      );
    }

    const fields = [...(product?.requiredFields || []), ...(product?.optionalFields || [])];
    let modifyFields = fields?.filter((obj: string) => !obj?.includes('.'));

    if (groupedByReason?.MEMBER_PERSONAL_INFO?.missingFields?.length > 0 && !editTaskData) {
      modifyFields = [];
      fields?.forEach((field: string) => {
        if (!groupedByReason?.MEMBER_PERSONAL_INFO?.missingFields.includes(field))
          modifyFields.push({
            label: field,
            value: field
          });
      });
      setFieldsOption(modifyFields);
    } else {
      setFieldsOption(
        modifyFields?.map((obj: any) => ({
          label: obj,
          value: obj
        }))
      );
    }

    searchAdditionalDocuments((res: any) => {
      if (res?.length > 0) {
        setAdditionalDocuments(res);
        setAdditionalDocumentsOptions(
          res?.map((doc: IDocument) => ({
            label: doc?.documentName,
            value: doc?.id
          }))
        );
      }
    });
  }, []);

  useEffect(() => {
    if (editTaskData) {
      let draftInit = {
        reason: editTaskData?.reason,
        comment: editTaskData?.comment
      };
      if (editTaskData?.memberId) {
        draftInit = { ...draftInit, ...{ memberId: editTaskData?.memberId } };
        if (editTaskData?.missingFields) {
          const preResource = editTaskData?.missingFields?.map((res: any) => res?.value);
          draftInit = {
            ...draftInit,
            ...{ resource: preResource }
          };
        }

        if (editTaskData?.memberDocument) {
          draftInit = {
            ...draftInit,
            ...{ resource: editTaskData?.memberDocument?.id }
          };
        }
      }

      setInitValues(draftInit);
    }
  }, [editTaskData]);

  const getMissingFields = (resources: string[]): { label: string; value: string; type: any }[] => {
    let missingFields: { label: string; value: string; type: any }[] = [];
    resources.forEach(resource => {
      let field = TravellerFormFields.find(
        item => item.value === resource || item.label === resource
      );
      if (field) {
        missingFields.push({ label: field.label, value: field.label, type: field.type });
      } else {
        missingFields.push({ label: resource, value: resource, type: 'string' });
      }
    });
    return missingFields;
  };

  const handleSubmit = (values: any, actions: FormikHelpers<any>) => {
    const { reason, memberId, resource, comment } = values;

    const type = findTypeByReason(reason);

    let draft: any = {
      orderId: orderData?.data?.id,
      reason,
      comment,
      type
    };

    if (memberId) {
      draft = { ...draft, memberId };

      if (resource?.length > 0) {
        if (reason === 'MEMBER_PERSONAL_INFO') {
          const missingFields = getMissingFields(resource);
          draft = { ...draft, missingFields };
        }
        if (['MEMBER_DOCUMENT', 'MEMBER_ADDITIONAL_DOCUMENT_REQUIRED'].includes(reason)) {
          const member = members.find((m: IMember) => m?.id === memberId);
          const documentList =
            reason === 'MEMBER_DOCUMENT' ? member?.documents : additionalDocuments;

          const draftDoc = documentList?.find((document: IDocument) => document?.id === resource);
          if (draftDoc) draft = { ...draft, memberDocument: draftDoc };
        }
      }
    }

    if (editTaskData) {
      updateOrderAction(editTaskData?.id, draft, _res => {
        actions.setSubmitting(false);
        onHide();
      });
    } else {
      createOrderAction(draft, _res => {
        actions.setSubmitting(false);
        onHide();
      });
    }
  };

  const handleReasonChange = (value: any, formikProps: FormikProps<any>) => {
    // First, update the 'reason' field with the new value
    formikProps.setFieldValue('reason', value);
    formikProps.setFieldValue('memberId', '');
    formikProps.setFieldValue('resource', '');
    formikProps.setFieldValue('comment', '');
  };

  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleSubmit}
      validate={validate}
      enableReinitialize>
      {(formikProps: FormikProps<any>) => {
        let documentOptions = [];
        if (formikProps?.values['memberId'] !== '') {
          const member = members.find(
            (member: IMember) => member?.id === formikProps?.values['memberId']
          );
          if (member) {
            documentOptions = member?.documents.map((document: IDocument) => ({
              label: document?.documentName,
              value: document?.id
            }));
          }
        }

        return (
          <Form>
            {MEMBER_LEVEL_TASK?.includes(formikProps?.values['reason']) && (
              <Row className='mb-4'>
                <Col>
                  <Alert
                    severity='warning'
                    sx={{
                      backgroundColor: theme => theme?.palette?.warning?.light,
                      color: theme => theme?.palette?.grey?.[400]
                    }}>
                    This task will also appear for the end traveller in the app
                  </Alert>
                </Col>
              </Row>
            )}

            <Row className='mb-4'>
              <Col>
                <OASelectField
                  name='reason'
                  label='Reason'
                  options={reasonOptions}
                  valueOnly
                  required
                  value={formikProps.values.reason}
                  onChangeData={(value: string) => handleReasonChange(value, formikProps)}
                  isDisabled={editTaskData ? true : false}
                />
              </Col>
            </Row>
            {memberOptions?.length > 0 &&
              MEMBER_LEVEL_TASK.includes(formikProps?.values['reason']) && (
                <Row className='mb-4'>
                  <Col>
                    <Field
                      name='memberId'
                      label='Select Traveller'
                      as={OASelectField}
                      options={memberOptions}
                      valueOnly
                      required
                      isDisabled={editTaskData ? true : false}
                    />
                  </Col>
                </Row>
              )}
            {formikProps?.values['reason'] === 'MEMBER_PERSONAL_INFO' && (
              <Row className='mb-4'>
                <Col>
                  <Field
                    name='resource'
                    label='Personal information'
                    as={OAMultiSelectField}
                    options={formikProps?.values['memberId'] !== '' ? fieldsOption : []}
                    valueOnly
                    required
                    placeholder='Search here...'
                  />
                </Col>
              </Row>
            )}
            {formikProps?.values['reason'] === 'MEMBER_DOCUMENT' && (
              <Row className='mb-4'>
                <Col>
                  <Field
                    name='resource'
                    label='Traveller Documents'
                    as={OASelectField}
                    options={documentOptions}
                    valueOnly
                    required
                    placeholder='Search here...'
                  />
                </Col>
              </Row>
            )}
            {formikProps?.values['reason'] === 'MEMBER_ADDITIONAL_DOCUMENT_REQUIRED' && (
              <Row className='mb-4'>
                <Col>
                  <Field
                    name='resource'
                    label='Traveller Additional Documents'
                    as={OASelectField}
                    options={additionalDocumentsOptions}
                    valueOnly
                    required
                    placeholder='Search here...'
                  />
                </Col>
              </Row>
            )}
            <Row className='mb-4'>
              <Col>
                <OATextAreaField name='comment' label='Comment' required />
              </Col>
            </Row>
            <div className='separator mb-5' />
            <Row>
              <Col className='text-end'>
                <OAButton
                  label={'Cancel'}
                  size='sm'
                  onClick={onHide}
                  disabled={formikProps?.isSubmitting}
                  className='btn-light-primary btn-active-light-primary me-4'
                />

                <OAButton
                  label={formikProps?.isSubmitting ? 'Saving...' : 'Save'}
                  type='submit'
                  size='sm'
                  disabled={
                    (!formikProps?.isValid && formikProps?.dirty) || formikProps?.isSubmitting
                  }
                  className='ms-auto btn-primary btn-active-primary'
                />
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TaskForm;
