/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { debounce, isNull } from 'lodash';
import { Badge } from 'react-bootstrap';
import classNames from 'classnames';
import Select from 'react-select';

import { KTSVG } from '../../../_metronic/helpers';
import { ORDER_TYPE, orderFilterNavs } from '../constants';
import { useOrderContext } from '../context/OrderContext';
import { useAuth } from '../../../auth';
import { useAppContext } from '../../../context/AppContext';

const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    'fontSize': '0.925rem',
    'fontWeight': '400',
    'color': 'red',
    'minHeight': '30px',
    'minWidth': '150px',
    'paddingRight': '5px',
    'borderRadius': '8px',
    'borderWidth': 0,
    ':focus': {
      borderWidth: 0
    },
    ':hover': {
      borderColor: 'transparent',
      boxShadow: 'none'
    }
  }),
  menu: (base: any) => ({
    ...base,
    fontSize: '0.925rem'
  })
};

interface IOrderSearch {
  totalOrder: any;
  isLoading: boolean;
  isGroupOrder: boolean;
  queryData: any;
}

const OrderSearch = (props: IOrderSearch) => {
  const { queryParams, setQueryParams } = props?.queryData?.data;
  const { totalOrder, isGroupOrder } = props;

  const { orderType } = useParams();
  const { users, fetchUsers } = useAppContext();
  const { currentUser } = useAuth();

  const [value, setValue] = useState<any>(null);

  useEffect(() => {
    if (currentUser && isNull(users)) {
      fetchUsers(currentUser?.data?.organization?.id);
    }
  }, [currentUser]);

  useEffect(() => {
    let ifFound = orderFilterNavs?.find((obj: any) => obj === queryParams?.orderStatus);
    if (!ifFound) {
      setQueryParams((prev: any) => ({
        ...prev,
        orderStatus: 'ORDER_DRAFT'
      }));
    }
  }, []);

  const hasSupplierViewAccess = currentUser?.data?.organization?.type === 'SUPPLIER';
  const placeholderText = `Search by ${hasSupplierViewAccess ? `'Assigned to'` : `'Created By'`}`;

  useEffect(() => {
    setValue('');
  }, [orderType]);

  const filterNavs = [
    {
      label: 'Quotation',
      value: 'ORDER_QUOTE',
      count: totalOrder?.quotationCount,
      show: true
    },
    {
      label: 'Draft',
      value: 'ORDER_DRAFT',
      count: totalOrder?.draftOrderCount,
      show: true
    },
    {
      label: 'Processing',
      value: 'PENDING_ACTION',
      count: totalOrder?.pendingActionOrderCount,
      show:
        orderType === ORDER_TYPE.VISA &&
        (currentUser?.data?.organization?.type === 'SUPPLIER' ||
          currentUser?.data?.organization?.type === 'ON_ARRIVAL')
    },
    {
      label: 'Submitted',
      value: 'ORDER_SUBMIT',
      count: totalOrder?.submitOrderCount,
      show: true
    },
    {
      label: 'Completed',
      value: 'COMPLETED',
      count: totalOrder?.completedOrderCount,
      show: orderType === ORDER_TYPE.VISA
    },
    {
      label: 'Cancel request',
      value: 'ORDER_CANCELLATION_REQUESTED',
      count: totalOrder?.cancelRequested,
      show: orderType === ORDER_TYPE.INSURANCE
    },
    {
      label: 'Cancelled',
      value: 'BOOKING_CANCELLED',
      count: totalOrder?.cancelledOrderCount,
      show: orderType === ORDER_TYPE.INSURANCE
    },
    {
      label: 'All',
      value: 'ORDER_ALL',
      count: totalOrder?.totalOrderCount,
      show: true
    }
  ];

  const onChangeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams((prev: any) => ({
      ...prev,
      search: evt?.target?.value?.trim(),
      pageIndex: 1
    }));
  };

  const selectChange = (userObj: any) => {
    setValue(userObj);
    setQueryParams((prev: any) => ({
      ...prev,
      createdBy: userObj ? userObj?.value : '',
      pageIndex: 1
    }));
  };
  const debouncedOnChange = debounce(onChangeHandler, 300);
  return (
    <div className='card-header card-header-stretch border-bottom border-gray-200 min-h-60px'>
      <div className='card-title position-relative search-orders'>
        <KTSVG
          className='position-absolute ms-2 svg-icon-4'
          path='/media/icons/duotune/general/gen021.svg'
        />
        <input
          type='text'
          onChange={debouncedOnChange}
          className='form-control form-control-sm form-control-solid oa-text-search w-150px ps-9'
          placeholder='Search Orders'
        />
        <Select
          options={
            users?.length
              ? users?.map((obj: any) => ({
                  label: `${obj?.firstName} ${obj?.lastName}`,
                  value: obj?.id
                }))
              : []
          }
          onChange={selectChange}
          value={value}
          placeholder={placeholderText}
          isClearable={true}
          className='oa-select-container oa-select-search ms-4'
          classNamePrefix='oa-select'
          styles={customStyles}
        />
      </div>
      {!isGroupOrder && (
        <div className='card-toolbar m-0 order-search'>
          <ul className='nav nav-stretch nav-line-tabs border-transparent' role='tablist'>
            {filterNavs?.length &&
              filterNavs?.map((obj, idx) =>
                obj?.show ? (
                  <OrderFilterNavItem
                    key={idx}
                    label={obj?.label}
                    orderStatus={queryParams?.orderStatus!}
                    value={obj?.value}
                    count={obj?.count}
                    onClick={() => {
                      setQueryParams((prev: any) => ({
                        ...prev,
                        orderStatus: obj?.value,
                        pageIndex: 1
                      }));
                    }}
                    isLoading={props?.isLoading}
                  />
                ) : null
              )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default OrderSearch;

// Helper Components
interface IOrderFilterNavItem {
  label: string;
  value: string;
  orderStatus: string;
  count: number;
  onClick: () => void;
  isLoading: boolean;
}

const OrderFilterNavItem = (props: IOrderFilterNavItem) => {
  const { label, value, orderStatus, count, onClick } = props;
  const anchorClassNames = classNames('nav-link justify-content-center text-active-gray-800', {
    active: orderStatus === value
  });

  const badgeClassNames = classNames('min-w-35px text-center d-inline fw-normal fs-8', {
    'badge-primary': orderStatus === value,
    'badge-secondary': orderStatus !== value
  });

  return (
    <li className='nav-item' role='presentation'>
      <a className={anchorClassNames} onClick={onClick}>
        <span className='me-1 fw-bold fs-7'>{label}</span>
        <Badge
          className={badgeClassNames}
          title={count ? count.toString() : ''}
          pill
          bg={orderStatus === value ? 'primary' : 'secondary'}>
          {count ? (count > 999 ? '999+' : count) : 0}
        </Badge>
      </a>
    </li>
  );
};
