import { FC, ReactNode } from 'react';
import { Accordion } from 'react-bootstrap';
import { OATypography } from '../../widgets';

export * from './Documents';
export * from './VisaFees';
export * from './AddOns';
export * from './EmbassyAndConsulate';
export * from './VisaFormsLink';
export * from './Faqs';
export * from './Steps';
export * from './AdditionalNotes';
export * from './Source';

interface IAccordionItemProps {
  eventKey: string;
  headerText: string;
  children: ReactNode;
}

export const AccordionItem: FC<IAccordionItemProps> = ({ eventKey, headerText, children }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        <OATypography variant='h4' sx={{ fontWeight: 500 }}>
          {headerText}
        </OATypography>
      </Accordion.Header>
      <Accordion.Body>{children}</Accordion.Body>
    </Accordion.Item>
  );
};
