import { getCountryName } from '../helper';
import { ITrip } from '../types';

// Helper function
const getFromAndTo = (trip: ITrip, isISO?: boolean): string | undefined => {
  if (!trip) return;

  const origin = trip.origin?.countryCode
    ? `${isISO ? trip.origin?.countryCode : getCountryName(trip.origin?.countryCode)}${
        trip.domestic ? ' (domestic)' : ''
      }`
    : '';
  const destination =
    !trip.domestic && trip.destination
      ? trip.destination
          .map((country: any) =>
            isISO ? country?.countryCode : getCountryName(country?.countryCode)
          )
          .join(', ')
      : '';

  return [origin, destination].filter(Boolean).join(' - ');
};

export default getFromAndTo;
