import React, { FC } from 'react';
import { Box, Theme } from '@mui/material';

import { useAppContext } from '../../context/AppContext';
import { OAStatusChip, OATypography } from '../widgets';
import { useAuth } from '../../auth';

interface ProgressCellProps {
  data: any;
}

type ProgressPercentageType = {
  // QUOTE: number;
  DRAFT: number;
  PROCESSING?: number;
  SUBMITTED: number;
  COMPLETED: number;
};

const PROGRESS_PERCENTAGE_DEFAULT: ProgressPercentageType = {
  // QUOTE: 5,
  DRAFT: 33,
  SUBMITTED: 67,
  COMPLETED: 100
};

const PROGRESS_PERCENTAGE_SUP: ProgressPercentageType = {
  // QUOTE: 5,
  DRAFT: 25,
  PROCESSING: 50,
  SUBMITTED: 75,
  COMPLETED: 100
};

const ProgressCell: FC<ProgressCellProps> = ({ data }) => {
  const { dataExchange } = useAppContext();
  const { currentUser } = useAuth();

  const isOrderSubmitFailed = ['ORDER_FAILED', 'ORDER_SUBMIT_FAILED']?.includes(
    data?.linkedOrder?.bookingStatus
  );

  const actionsLength: any = data?.linkedOrder?.orderActions?.filter(
    (obj: any) => obj?.status === 'CHANGES_REQUESTED'
  )?.length;

  const stage: any = dataExchange['VISA_STATUS']?.find(
    (obj: { label: string; stage: string; type: string; value: string }) =>
      obj?.value === data?.linkedOrder?.bookingStatus
  )?.stage;

  const hasProcessingStageAccess =
    currentUser?.data?.organization?.type === 'SUPPLIER' ||
    currentUser?.data?.organization?.type === 'ON_ARRIVAL';

  const PROGRESS_PERCENTAGE: any = hasProcessingStageAccess
    ? PROGRESS_PERCENTAGE_SUP
    : PROGRESS_PERCENTAGE_DEFAULT;

  const draftProps = {
    flex: '1',
    height: '6px',
    borderRadius: '4px'
  };
  const stages = [
    // 'QUOTE',
    'DRAFT',
    'PROCESSING',
    'SUBMITTED',
    'COMPLETED'
  ];
  // const showQuoteBar = stages?.includes(stage);
  const showDraftBar = stages?.includes(stage);
  const showProcessingBar = stages?.slice(1)?.includes(stage);
  const showSubmittedBar = stages?.slice(2)?.includes(stage);
  const showCompletedBar = stages?.slice(3)?.includes(stage);

  const getBgColor = (theme: Theme, active: boolean) =>
    active ? theme.palette.primary.main : theme.palette.grey[200];

  const getStatusChip = (status: string) => {
    const { type, label } = dataExchange['VISA_STATUS']?.find((obj: any) => obj?.value === status);
    return <OAStatusChip status={status} variant={type} label={label} />;
  };

  return (
    <Box width='100%' display='flex' alignItems='center' gap='4px'>
      {(() => {
        if (isOrderSubmitFailed) {
          return getStatusChip('RESUBMIT_ORDER');
        } else if (actionsLength > 0) {
          return getStatusChip('PENDING_TASK');
        } else {
          return (
            <React.Fragment>
              {/* <Box bgcolor={theme => getBgColor(theme, showQuoteBar)} {...draftProps} /> */}
              <Box bgcolor={theme => getBgColor(theme, showDraftBar)} {...draftProps} />
              {hasProcessingStageAccess ? (
                <Box bgcolor={theme => getBgColor(theme, showProcessingBar)} {...draftProps} />
              ) : null}
              <Box bgcolor={theme => getBgColor(theme, showSubmittedBar)} {...draftProps} />
              <Box bgcolor={theme => getBgColor(theme, showCompletedBar)} {...draftProps} />
              <Box flex='1'>
                <OATypography variant='body2'>{`${PROGRESS_PERCENTAGE[stage]}%`}</OATypography>
              </Box>
            </React.Fragment>
          );
        }
      })()}
    </Box>
  );
};

export default ProgressCell;
