/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';

export interface IPageLink {
  title: string;
  path: string;
  isActive: boolean;
  isSeparator?: boolean;
}

export interface IPageDataContext {
  pageTitle?: string;
  setPageTitle: (_title: string) => void;
  pageDescription?: string;
  setPageDescription: (_description: string) => void;
  pageBreadcrumbs?: Array<IPageLink>;
  setPageBreadcrumbs: (_breadcrumbs: Array<IPageLink>) => void;
}

const PageDataContext = createContext<IPageDataContext>({
  setPageTitle: (_title: string) => {},
  setPageBreadcrumbs: (_breadcrumbs: Array<IPageLink>) => {},
  setPageDescription: (_description: string) => {}
});

const PageDataProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [pageTitle, setPageTitle] = useState<string>('');
  const [pageDescription, setPageDescription] = useState<string>('');
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<IPageLink>>([]);

  const contextValue: IPageDataContext = {
    pageTitle,
    pageDescription,
    pageBreadcrumbs,
    setPageTitle,
    setPageDescription,
    setPageBreadcrumbs
  };

  return <PageDataContext.Provider value={contextValue}>{children}</PageDataContext.Provider>;
};

function usePageData() {
  return useContext(PageDataContext);
}

interface IPageTitle {
  description?: string;
  breadcrumbs?: Array<IPageLink>;
}

const PageTitle: React.FC<PropsWithChildren<IPageTitle>> = ({
  children,
  description,
  breadcrumbs
}) => {
  const { setPageTitle, setPageDescription, setPageBreadcrumbs } = usePageData();

  useEffect(() => {
    if (children) {
      setPageTitle(children.toString());
    }
    return () => setPageTitle('');
  }, [children]);

  useEffect(() => {
    if (description) {
      setPageDescription(description);
    }
    return () => setPageDescription('');
  }, [description]);

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs);
    }
    return () => setPageBreadcrumbs([]);
  }, [breadcrumbs]);

  return <React.Fragment />;
};

const PageDescription: React.FC<PropsWithChildren> = ({ children }) => {
  const { setPageDescription } = usePageData();

  useEffect(() => {
    if (children) {
      setPageDescription(children.toString());
    }
    return () => setPageDescription('');
  }, [children]);

  return <React.Fragment />;
};

export { PageDescription, PageTitle, PageDataProvider, usePageData };
