import classNames from 'classnames';
import { IconType } from '.';

export default function UploadSVG(props: IconType) {
  const {
    color = '#B5B5C2',
    width = '14',
    height = '14',
    // isActive = false,
    className = ''
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      className={classNames('icon-svg edit-svg', className)}
      xmlns='http://www.w3.org/2000/svg'>
      <path
        opacity='0.3'
        d='M4.425 20.525C2.525 18.625 2.525 15.525 4.425 13.525L14.825 3.125C16.325 1.625 18.825 1.625 20.425 3.125C20.825 3.525 20.825 4.12502 20.425 4.52502C20.025 4.92502 19.425 4.92502 19.025 4.52502C18.225 3.72502 17.025 3.72502 16.225 4.52502L5.82499 14.925C4.62499 16.125 4.62499 17.925 5.82499 19.125C7.02499 20.325 8.82501 20.325 10.025 19.125L18.425 10.725C18.825 10.325 19.425 10.325 19.825 10.725C20.225 11.125 20.225 11.725 19.825 12.125L11.425 20.525C9.525 22.425 6.425 22.425 4.425 20.525Z'
        fill={color}
      />
      <path
        d='M9.3248 15.6246C8.1248 14.4246 8.1248 12.6246 9.3248 11.4246L14.2248 6.52461C14.6248 6.12461 15.2248 6.12461 15.6248 6.52461C16.0248 6.92461 16.0248 7.52463 15.6248 7.92463L10.7248 12.8245C10.3248 13.2245 10.3248 13.8246 10.7248 14.2246C11.1248 14.6246 11.7248 14.6246 12.1248 14.2246L19.1248 7.22456C19.5248 6.82456 19.7248 6.42463 19.7248 5.92463C19.7248 5.32463 19.5248 4.82463 19.1248 4.42463C18.7248 4.02463 18.7248 3.42461 19.1248 3.02461C19.5248 2.62461 20.1248 2.62461 20.5248 3.02461C21.3248 3.82461 21.7248 4.82463 21.7248 5.92463C21.7248 6.92463 21.3248 7.82461 20.5248 8.52461L13.5248 15.5246C12.3248 16.7246 10.5248 16.7246 9.3248 15.6246Z'
        fill={color}
      />
    </svg>
  );
}
