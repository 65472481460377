import { useState } from 'react';
import { Accordion, Col, ListGroup, Row } from 'react-bootstrap';
import { Country } from 'country-state-city';
import classNames from 'classnames';
import { isNull } from 'lodash';
import moment from 'moment';

import {
  capitalizeFirstLetterOnly,
  formatNumberForCurrency,
  getCurrencySymbol,
  getFormattedDate,
  getSupplierOrganizationName
} from '../../helper';
import InsuranceBenefitsModal from '../../pages/order-management/order-create/insurance/AddInsuranceCover/InsuranceBenefitsModal';
import { useOrderContext } from '../../pages/order-management/context/OrderContext';
import { IOrderType } from '../../pages/order-management/types';
import OALabelValue from '../widgets/OALabelValue';
import { KTSVG } from '../../_metronic/helpers';
import OAButton from '../widgets/OAButton';
import { IProduct } from '../../types';
import { useAuth } from '../../auth';

export interface IMasterProduct {
  data: any;
  onSelectProduct: (
    values: { requestedProductId: string; isQuotation: boolean },
    cb?: () => void
  ) => void;
  type: IOrderType;
  selectedProductId?: string;
  duration?: { from: any; to: any };
  disabled?: boolean;
  disablePreSelected?: boolean;
  isQuotation?: boolean;
  showSelected?: boolean;
  /**
   * NOTE: enableQuoteButton prop is used to show/hide quote button in master product card...
   */
  enableQuoteButton?: boolean;
}

export default function MasterProduct(props: IMasterProduct) {
  const { data, duration, enableQuoteButton = false } = props;

  const { currentUser } = useAuth();
  const { onAddTripDetails, onAddTravellerFormFields } = useOrderContext();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<'quotation' | 'order' | null>(null);

  const handleSelect = (productObj: any, isQuotation: boolean = false) => {
    setIsLoading(isQuotation ? 'quotation' : 'order');
    onAddTravellerFormFields({
      travellerFormFields: {
        requiredFields: productObj?.requiredFields ?? [],
        optionalFields: productObj?.optionalFields ?? []
      }
    });
    onAddTripDetails(productObj);
    props?.onSelectProduct({ requestedProductId: productObj?.id, isQuotation }, () => {
      setIsLoading(null);
    });
  };

  let isdisabled: any;
  if (duration?.from && duration?.to) {
    const minProcessingDate = moment().add(
      data?.maxProcessingTime,
      data?.processingTimeUnit?.toLowerCase()
    );
    const durationFrom = moment(duration?.from).startOf('day');

    // Compare if durationFrom is greater than minProcessingDate
    isdisabled = minProcessingDate.isAfter(durationFrom);
    if (isdisabled) {
      isdisabled = getFormattedDate(minProcessingDate);
    }
  }

  const renderVisaProducts: JSX.Element = (
    <ListGroup variant='flush' className='master-products--list'>
      {data?.products?.map((obj: IProduct | any, index: number) => {
        const draftPrice = `${getCurrencySymbol(
          obj?.productInformation?.productAmount?.currency
        )} ${formatNumberForCurrency(obj?.productInformation?.productAmount?.totalAmount)}`;
        /**
         * NOTE: if 'disablePreSelected' prop is true then select product button
         * of selected product need to be disabled and text need to be changed to
         * 'Selected'...
         */
        const d_case_1 = props?.disablePreSelected ? props?.selectedProductId === obj?.id : false;

        const supOrgName = getSupplierOrganizationName(currentUser, obj?.supplierOrganization);

        return (
          <ListGroup.Item key={index} className='master-products--item border p-4'>
            <div className='d-flex justify-content-between'>
              <div className='d-flex flex-column justify-content-center flex-grow-1'>
                <h6 className='mb-0'>{supOrgName?.orgName}</h6>
                {obj?.supplierOrganization?.legalName && (
                  <p className='text-muted lh-1 m-0'>{supOrgName?.orgLegalName}</p>
                )}
              </div>
              <div className='d-flex flex-column justify-content-center px-4'>
                <h6 className='m-0'>{`${draftPrice} / traveller`}</h6>
              </div>
              {enableQuoteButton ? (
                <OAButton
                  label='Quote'
                  loadingText='Quote'
                  color='light-primary'
                  className='btn-active-light-primary me-4'
                  onClick={() => handleSelect(obj, true)}
                  disabled={
                    !!(props?.showSelected && props?.selectedProductId === obj?.id) ||
                    isdisabled ||
                    !isNull(isLoading) ||
                    props?.disabled ||
                    d_case_1
                  }
                  isLoading={
                    props?.selectedProductId === obj?.id &&
                    isLoading === 'quotation' &&
                    !props?.isQuotation
                  }
                />
              ) : null}
              <OAButton
                label='Create Order'
                loadingText='Create Order'
                onClick={() => handleSelect(obj)}
                disabled={
                  !!(props?.showSelected && props?.selectedProductId === obj?.id) ||
                  isdisabled ||
                  !isNull(isLoading) ||
                  props?.disabled ||
                  d_case_1
                }
                isLoading={
                  props?.selectedProductId === obj?.id &&
                  isLoading === 'order' &&
                  !props?.isQuotation
                }
              />
            </div>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );

  const renderInsuranceProducts: JSX.Element = (
    <ListGroup variant='flush' className='master-products--list'>
      {data?.products?.map((obj: IProduct | any, index: number) => {
        const draftPrice = `${getCurrencySymbol(
          obj?.productInformation?.productAmount?.currency
        )} ${formatNumberForCurrency(obj?.productInformation?.productAmount?.totalAmount)}`;

        const benefitsToShow = obj?.productInformation?.benefits?.length
          ? obj?.productInformation?.benefits?.slice(0, 3)
          : [];
        const numberOfBenefits = obj?.productInformation?.benefits?.length;
        /**
         * NOTE: if 'disablePreSelected' prop is true then select product button
         * of selected product need to be disabled and text need to be changed to
         * 'Selected'...
         */
        const d_case_1 = props?.disablePreSelected ? props?.selectedProductId === obj?.id : false;

        return (
          <ListGroup.Item key={index} className='master-products--item border p-4 pb-0'>
            <div className='d-flex justify-content-between mb-4'>
              <div className='d-flex flex-column justify-content-center flex-grow-1'>
                <h6 className='mb-0'>{obj?.supplierOrganization?.name}</h6>
                {obj?.supplierOrganization?.legalName && (
                  <p className='text-muted lh-1 m-0'>
                    {obj?.supplierOrganization?.legalName?.trim()}
                  </p>
                )}
              </div>
              {obj?.productInformation?.productAmount?.totalAmount ? (
                <div className='d-flex flex-column justify-content-center  px-4'>
                  <h6 className='m-0'>{`${draftPrice} / traveller`}</h6>
                </div>
              ) : null}
              {enableQuoteButton ? (
                <OAButton
                  color='light-primary'
                  className='btn-active-light-primary me-4'
                  label='Quote'
                  onClick={() => handleSelect(obj, true)}
                  disabled={isdisabled || !isNull(isLoading) || props?.disabled || d_case_1}
                  isLoading={
                    props?.selectedProductId === obj?.id &&
                    isLoading === 'quotation' &&
                    !props?.isQuotation
                  }
                />
              ) : null}
              <OAButton
                label='Create Order'
                onClick={() => handleSelect(obj)}
                disabled={isdisabled || !isNull(isLoading) || props?.disabled || d_case_1}
                isLoading={
                  props?.selectedProductId === obj?.id &&
                  isLoading === 'order' &&
                  !props?.isQuotation
                }
              />
            </div>
            <div className='separator mb-3' />
            <Row>
              {benefitsToShow?.map((obj: any) => (
                <OALabelValue
                  className='col-md-6 mb-3'
                  label={obj?.name ?? 'N/A'}
                  value={obj?.sumInsured ?? 'N/A'}
                />
              ))}
              {numberOfBenefits > benefitsToShow?.length ? (
                <Col md={6} className='mb-3'>
                  <span
                    className='fs-8 btn btn-link'
                    onClick={e => {
                      e.stopPropagation();
                      setIsOpen(true);
                    }}>
                    {'View more details >>'}
                  </span>
                </Col>
              ) : null}
            </Row>
            <InsuranceBenefitsModal
              show={isOpen}
              onHide={() => setIsOpen(false)}
              data={{ name: data?.displayName, benefits: data?.benefits }}
            />
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );

  return (
    <Accordion defaultActiveKey='0' className='master-product bg-white'>
      <Accordion.Item eventKey='0'>
        <Accordion.Header as='div'>
          <HeaderContent data={data} type={props?.type} />
        </Accordion.Header>
        <Accordion.Body className='p-0'>
          {props?.type === 'visa' ? renderVisaProducts : renderInsuranceProducts}
        </Accordion.Body>
        {isdisabled && (
          <div className='p-4 pt-0 text-danger fw-bold fs-8'>
            <KTSVG
              path='/media/icons/duotune/general/gen044.svg'
              className='svg-icon-danger svg-icon-3 me-1'
            />
            Visa available if travel duration is after {isdisabled}
          </div>
        )}
      </Accordion.Item>
    </Accordion>
  );
}

// Helper Components
const HeaderContent = (props: { data: any; type: IOrderType }) => {
  if (props?.type === 'visa') {
    const { processingTime, stayPeriod, validity, visaType, processingType, tripType, occupation } =
      props?.data;
    const tagsArr: Array<string> = [processingType, tripType, occupation];

    return (
      <div className='master-product__card-header--content'>
        <div className='d-flex flex-column'>
          <h3 className='flex-grow-1'>{props?.data?.displayName}</h3>
          <div className='d-flex'>
            {tagsArr?.map((tagStr: string, index: number) => (
              <span key={index} className='badge py-1 px-2 fs-7 badge-light-info me-2 border'>
                {capitalizeFirstLetterOnly(tagStr?.toLowerCase())}
              </span>
            ))}
          </div>
        </div>
        <div className='d-flex justify-content-between pt-4'>
          {processingTime ? <OALabelValue label='Processing Time' value={processingTime} /> : null}
          {stayPeriod ? <OALabelValue label='Stay Period' value={stayPeriod} /> : null}
          {validity ? <OALabelValue label='Validity' value={validity} /> : null}
          {visaType ? <OALabelValue label='Visa Type' value={visaType} /> : null}
        </div>
      </div>
    );
  } else if (props?.type === 'insurance') {
    let ageGroupText = '';
    if (props?.data?.ageGroup && props?.data?.ageGroup?.minAge) {
      const { unit, minAge, maxAge } = props?.data?.ageGroup;
      const draftUnit = unit?.toLowerCase();
      const draftMinAge =
        moment.duration(minAge, draftUnit).years() < 1
          ? `${minAge} ${draftUnit}`
          : `${moment.duration(minAge, draftUnit).years()} years`;
      ageGroupText = `${draftMinAge}`;
      if (maxAge) {
        const draftMaxAge =
          moment.duration(maxAge, draftUnit).years() < 1
            ? `${maxAge} ${draftUnit}`
            : `${moment.duration(maxAge, draftUnit).years()} years`;
        ageGroupText += ` - ${draftMaxAge}`;
      }
    }

    const countryInfo = Country.getAllCountries().find(
      country => country.currency === props?.data?.sumInsuredCurrency
    );

    return (
      <div className='master-product__card-header--content d-flex justify-content-between'>
        <div className='d-flex flex-column justify-content-center flex-grow-1'>
          <h3 className={classNames({ 'm-0': !!ageGroupText })}>{props?.data?.displayName}</h3>
          {ageGroupText ? (
            <div className='d-flex'>
              <span className='badge py-1 px-2 fs-7 badge-light-info me-2 border'>
                {capitalizeFirstLetterOnly(ageGroupText?.toLowerCase())}
              </span>
            </div>
          ) : null}
        </div>
        {props?.data?.sumInsured ? (
          <div className='me-12'>
            <p className='fs-4 fw-bold text-dark m-0'>
              {getCurrencySymbol(props?.data?.sumInsuredCurrency) ?? null}
              {formatNumberForCurrency(props?.data?.sumInsured, {
                countryCode: countryInfo?.isoCode,
                toFixed: false
              }) ?? 'N/A'}
            </p>
            <p className='text-muted m-0'>Total Sum Insured</p>
          </div>
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};
