import { useContext } from 'react';
import { Button, Form } from 'react-bootstrap';
import { format, parseISO } from 'date-fns';
import intervalToDuration from 'date-fns/intervalToDuration';

import { ITravellerFormValues } from '../../../types';
import OrderContext from '../../../context/OrderContext';
import { KTSVG } from '../../../../../_metronic/helpers';

import DeleteSVG from '../../../../../assets/icons/icon_delete.svg';
import EditSVG from '../../../../../assets/icons/icon_edit.svg';
import OABadge from '../../../../../components/widgets/OABadge';
import { showDialog } from '../../../../../helper';

var iso3311a2 = require('iso-3166-1-alpha-2');

export interface ITravellerCard {
  traveller: ITravellerFormValues;
  trip: any;
  toggleModal: (val?: boolean) => void;
}

export default function TravellerCard(props: ITravellerCard) {
  const { traveller, trip } = props;

  const { formState, onDeleteTraveller, onSetTravellerAsPrimary, onSelectEditTraveller } =
    useContext(OrderContext);

  const avatar = traveller?.firstName?.charAt(0).toUpperCase();
  const title = `${traveller?.title} ${traveller?.firstName} ${traveller?.lastName}`;
  const subTitle = `${iso3311a2.getCountry(
    formState?.tripDetails?.trip?.origin?.countryCode
  )} - ${iso3311a2.getCountry(formState?.tripDetails?.trip?.destination?.[0]?.countryCode)}, ${
    traveller?.nationality
  }, ${traveller?.gender}`;
  const travellerAge: Duration = intervalToDuration({
    start: traveller?.dateOfBirth ? new Date(traveller?.dateOfBirth) : 0,
    end: trip?.from ? new Date(trip?.from) : 0
  });
  /**
   * NOTE: 'handleDeleteTraveller' method calls on click of delete traveller button and
   * on proceed it will delete traveller data from context (local).
   */
  function handleDeleteTraveller(id: string) {
    if (traveller?.primary) {
      showDialog({
        title: `Oops...`,
        text: `You cannot delete a primary traveller. Please try editing instead`,
        icon: `error`,
        showCancelButton: false,
        confirmButtonText: `Ok`
      });
    } else {
      showDialog({
        title: 'Delete traveller?',
        text: 'Are you sure you want to delete this traveller?',
        icon: 'warning'
      }).then(result => {
        if (result?.isConfirmed) {
          onDeleteTraveller(id);
        }
      });
    }
  }

  return (
    <div className='card border border-gray-200'>
      <div className='card-header border-0 pt-6 px-6'>
        <div className='card-title m-0'>
          <div className='symbol symbol-50px w-50px symbol-circle'>
            <span className='h1 symbol-label bg-light-primary text-primary fw-bolder'>
              {avatar}
            </span>
          </div>
        </div>
        <div className='card-toolbar'>
          <Button
            variant='light'
            size='sm'
            className='w-35px h-35px p-0'
            onClick={() => {
              onSelectEditTraveller(traveller?.id as string);
              props?.toggleModal();
            }}
            title='Edit'>
            <KTSVG path={EditSVG} className='svg-icon-1 m-0' />
          </Button>
          <Button
            variant='light'
            size='sm'
            className='w-35px h-35px p-0 ms-3'
            onClick={() => handleDeleteTraveller(traveller?.id as string)}
            title='Delete'>
            <KTSVG path={DeleteSVG} className='svg-icon-1 m-0' />
          </Button>
        </div>
      </div>
      <div className='card-body p-6'>
        <div className='d-flex align-items-center fs-3 fw-bolder text-dark'>
          {title}
          {traveller?.primary ? <OABadge label='Primary' type='success' className='ms-5' /> : null}
        </div>
        <p className='text-gray-400 fw-bold fs-5 mt-1 mb-7'>{subTitle}</p>
        <div className='d-flex flex-row'>
          <div className='border border-gray-300 border-dashed rounded py-3 px-5'>
            <div className='fs-6 text-gray-800 fw-bolder'>
              {trip?.from ? format(parseISO(trip?.from?.toISOString()), 'MMM dd, yyyy') : null}
            </div>
            <div className='fw-bold text-gray-400'>Entry Date</div>
          </div>
          {trip?.to ? (
            <div className='border border-gray-300 border-dashed rounded py-3 px-5 ms-6'>
              <div className='fs-6 text-gray-800 fw-bolder'>
                {trip?.to ? format(parseISO(trip?.to?.toISOString()), 'MMM dd, yyyy') : null}
              </div>
              <div className='fw-bold text-gray-400'>Exit Date</div>
            </div>
          ) : null}
        </div>
        <div className='d-flex flex-row justify-content-between align-items-center mt-4'>
          <label htmlFor='flexSwitchDefault' className='fs-5 m-0'>
            <span className='fw-bold'>Make Primary traveller</span>
            {travellerAge?.years! < 18 && (
              <span className='text-gray-400'>
                {' '}
                (Age under 18 yrs can't be a primary traveller)
              </span>
            )}
          </label>
          <Form.Check
            type='radio'
            id={`traveller_${traveller?.id}`}
            name='isPrimary_traveller'
            checked={traveller?.primary}
            disabled={travellerAge?.years! < 18}
            onChange={() => onSetTravellerAsPrimary({ id: traveller?.id as any })}
          />
        </div>
      </div>
    </div>
  );
}
