import { Link, useNavigate } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { ReactComponent as ArrowBackIcon } from '../../assets/icons/arrow_back.svg';

export type IBreadcrumbItem = {
  path: string;
  title: string;
  isActive: boolean;
};

export interface IOAToolbar {
  title?: string;
  className?: string;
  breadcrumb?: Array<IBreadcrumbItem>;
  actionsElement?: JSX.Element | null;
  goBack?: {
    path: string;
    title: string;
  };
}

export default function OAToolbar(props: IOAToolbar) {
  const navigate = useNavigate();

  return (
    <div className={classNames('app-toolbar py-3 py-lg-5', props?.className ?? '')}>
      <div className='app-container d-flex flex-stack'>
        <div className='page-title d-flex flex-wrap me-3 flex-column justify-content-center'>
          {props?.title && (
            <h1 className='page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center'>
              {props?.title}
            </h1>
          )}
          {!isEmpty(props?.goBack) && (
            <Box display='grid' gridTemplateColumns='auto 1fr' gap='4px' alignItems='center'>
              <IconButton
                aria-label='go back'
                size='medium'
                onClick={() => navigate(props?.goBack?.path!)}>
                <ArrowBackIcon />
              </IconButton>
              <Typography variant='subtitle1'>Back to {props?.goBack?.title}</Typography>
            </Box>
          )}
          {props?.breadcrumb && props?.breadcrumb?.length > 0 && (
            <ul className='breadcrumb breadcrumb-line fw-bold fs-7 my-1'>
              {props?.breadcrumb?.map((item, index) => (
                <li
                  className={classNames(
                    'breadcrumb-item',
                    item.isActive ? 'text-dark' : 'text-muted'
                  )}
                  key={`${item.path}${index}`}>
                  {item?.path ? (
                    <Link className='text-muted text-hover-primary' to={item.path}>
                      {item.title}
                    </Link>
                  ) : (
                    item.title
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
        {props?.actionsElement ? (
          <div className='d-flex align-items-center gap-2 gap-lg-3'>
            <div className='m-0'>{props?.actionsElement}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
