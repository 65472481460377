import { useParams } from 'react-router-dom';
import { Grid, Paper } from '@mui/material';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';

import { IOrderType } from '../../types';
import { ORDER_CREATE_ACTIONS, ORDER_TYPE } from '../../constants';
import OAButton from '../../../../components/widgets/OAButton';
import { useOrderContext } from '../../context/OrderContext';
import { OATextField } from '../../../../components/fields';

interface AddTravellersCountProps {
  onSubmit: (
    values: any,
    type: string,
    actions?: FormikHelpers<any>,
    cb?: ((response?: any) => void) | undefined
  ) => void;
  handleErrors?: (name: string, err: string | null) => void;
  isEdit?: boolean;
  className?: string;
}

export interface ITravellersCountInit {
  senior: number | string;
  adult: number | string;
  child: number | string;
  infant: number | string;
}

const inits: ITravellersCountInit = {
  senior: '',
  adult: '',
  child: '',
  infant: ''
};

export default function AddTravellersCount(props: AddTravellersCountProps) {
  const { orderType } = useParams<{ orderType: IOrderType }>();

  const { formState } = useOrderContext();

  const handleFormSubmit = (values: any) => {
    if (orderType === ORDER_TYPE.VISA && !formState?.consulate) {
      props?.handleErrors?.('consulate', 'Consulate is a required field');
    }
    if (orderType === ORDER_TYPE.VISA && !formState?.occupation) {
      props?.handleErrors?.('occupation', 'Occupation is a required field');
    }

    const memberCount = [];

    for (const key in values) {
      const type = key.toUpperCase();
      const count = values[key];

      if (count) memberCount.push({ type, count });
    }

    if (
      (orderType === ORDER_TYPE.VISA && formState?.consulate && formState?.occupation) ||
      orderType === ORDER_TYPE.INSURANCE
    ) {
      props?.onSubmit({ memberCount }, ORDER_CREATE_ACTIONS.CREATE_DRAFT_QUOTE, undefined, _res => {
        // setIsLoading(false);
      });
    }
  };

  /**
   * Validation ...
   */
  const validateInformationForm = Yup.object({
    adult: Yup.number().min(1, 'invalid').max(99999999, 'Invalid').label('count'),
    senior: Yup.number().min(1, 'invalid').max(99999999, 'Invalid').label('count'),
    child: Yup.number().min(1, 'invalid').max(99999999, 'Invalid').label('count').nullable(true),
    infant: Yup.number().min(1, 'invalid').max(99999999, 'Invalid').label('count')
  });

  const validateTravellersForVisa = Yup.object().shape(
    {
      adult: Yup.number().when('child', {
        is: (child: any, adult: any) => !child,
        then: Yup.number().required().label('Adult')
      }),
      child: Yup.number().when('adult', {
        is: (adult: any, child: any) => !adult,
        then: Yup.number().required().label('Child')
      })
    },
    [['adult', 'child']]
  );

  const validateTravellersForInsurance = Yup.object({}).shape(
    {
      adult: Yup.number().when('senior', {
        is: (child: any) => !child,
        then: Yup.number().required().label('Adult')
      }),
      senior: Yup.number().when('adult', {
        is: (adult: any) => !adult,
        then: Yup.number().required().label('Senior')
      })
    },
    [['adult', 'senior']]
  );

  const genericValidationSchema =
    orderType === ORDER_TYPE.VISA
      ? validateInformationForm.concat(validateTravellersForVisa)
      : validateInformationForm.concat(validateTravellersForInsurance);

  return (
    <Paper variant='outlined' className='p-6'>
      <div className='d-flex justify-content-between align-items-center mb-6'>
        <h3 className='fw-semibold text-dark mb-0'>Travellers</h3>
      </div>
      <Formik
        initialValues={inits}
        validationSchema={genericValidationSchema}
        onSubmit={handleFormSubmit}
        enableReinitialize>
        {(formikProps: FormikProps<any>) => {
          return (
            <Form>
              <Grid container spacing={3}>
                {orderType === ORDER_TYPE.INSURANCE && (
                  <Grid item xs>
                    <OATextField type='number' name='senior' label='Senior' />
                    <p className='mt-1 mb-0 text-muted text-medium'>Travellers over 60 yrs</p>
                  </Grid>
                )}
                <Grid item xs>
                  <OATextField type='number' name='adult' label='Adult' />
                  <p className='mt-1 mb-0 text-muted text-medium'>Travellers over 12 yrs</p>
                </Grid>
                <Grid item xs>
                  <OATextField type='number' name='child' label='Child' />
                  <p className='mt-1 mb-0 text-muted text-medium'>Travellers between 2-11yrs</p>
                </Grid>
                {orderType === ORDER_TYPE.INSURANCE && (
                  <Grid item xs>
                    <OATextField type='number' name='infant' label='Infant' />
                    <p className='mt-1 mb-0 text-muted text-medium'>Travellers below 2 yrs</p>
                  </Grid>
                )}
              </Grid>
              <div className='d-flex flex-stack pt-10'>
                <OAButton
                  type='submit'
                  label='Continue'
                  className='ms-auto'
                  // isLoading={formikProps?.isSubmitting || isLoading}
                  // disabled={formikProps?.isSubmitting || isLoading}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Paper>
  );
}
