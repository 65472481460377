import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';

const ActivatedAtCell = ({ date, dateFormat }: { date: any; dateFormat: string }) => {
  if (date) {
    const parsedTime = moment(date).toDate();
    const result = moment(parsedTime).fromNow();
    const formattedTime = moment(parsedTime).format(dateFormat);

    return (
      <OverlayTrigger
        placement='top'
        delay={{ show: 250, hide: 300 }}
        overlay={
          <Tooltip id={date} className='last-login-tooltip'>
            <strong>{formattedTime}</strong>
          </Tooltip>
        }>
        <div className='badge badge-light fw-bolder'>{result}</div>
      </OverlayTrigger>
    );
  } else {
    return <>{'N/A'}</>;
  }
};

export default ActivatedAtCell;
