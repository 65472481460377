import { IconType } from '.';

export default function DummyDocSVG(props: IconType) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='145'
      height='198'
      viewBox='0 0 145 198'
      fill='none'>
      <rect width='145' height='198' rx='8' fill='#F6F8FA' />
      <rect x='13' y='23' width='36' height='4' rx='2' fill='#B5B5C2' />
      <rect x='96' y='23' width='36' height='4' rx='2' fill='#B5B5C2' />
      <rect x='13' y='31' width='36' height='4' rx='2' fill='#B5B5C2' />
      <rect x='13' y='60' width='119' height='4' rx='2' fill='#B5B5C2' />
      <rect x='13' y='76' width='36' height='4' rx='2' fill='#B5B5C2' />
      <rect x='13' y='84' width='119' height='4' rx='2' fill='#B5B5C2' />
      <rect x='13' y='92' width='119' height='4' rx='2' fill='#B5B5C2' />
      <rect x='13' y='100' width='119' height='4' rx='2' fill='#B5B5C2' />
      <rect x='13' y='108' width='119' height='4' rx='2' fill='#B5B5C2' />
      <rect x='13' y='116' width='119' height='4' rx='2' fill='#B5B5C2' />
      <rect x='13' y='124' width='119' height='4' rx='2' fill='#B5B5C2' />
      <rect x='13' y='132' width='119' height='4' rx='2' fill='#B5B5C2' />
      <rect x='13' y='140' width='119' height='4' rx='2' fill='#B5B5C2' />
      <rect x='13' y='148' width='119' height='4' rx='2' fill='#B5B5C2' />
      <rect x='13' y='156' width='119' height='4' rx='2' fill='#B5B5C2' />
      <rect x='13' y='172' width='36' height='4' rx='2' fill='#B5B5C2' />
      <rect x='96' y='31' width='36' height='4' rx='2' fill='#B5B5C2' />
      <rect x='96' y='39' width='36' height='4' rx='2' fill='#B5B5C2' />
    </svg>
  );
}
