/* eslint-disable no-unused-vars */
import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormikHelpers, FormikValues } from 'formik';

import AddInsuranceCover from './AddInsuranceCover';
import AddTravellers from '../components/AddTravellers';
import ReviewOrder from '../components/ReviewOrder';
import { ORDER_TYPE } from '../../constants';
import TravelDetail from '../components/TravelDetail';
import CfarDetails from './CfarDetails';
import { IOrderContext, useOrderContext } from '../../context/OrderContext';
import { isString } from 'lodash';
import AddTravellersCount from '../quotation/AddTravellersCount';

interface IInsuranceFormSteps {
  handleSubmit: (values: any, type: string, actions?: FormikHelpers<any>) => void;
  handleStepChange: (actions?: FormikValues) => void;
  onClickStep: (stepIndex: number) => void | undefined;
}

export default function InsuranceFormSteps(props: IInsuranceFormSteps) {
  const location = useLocation();
  const fieldRef = useRef<HTMLDivElement>(null);
  const quotationId = location?.state?.quotationId;
  const { formState }: IOrderContext = useOrderContext();
  const [errors, setErrors] = useState<any>({});

  const handleErrors = (name: string, err: string | null) =>
    setErrors((prev: any) => {
      if (isString(err)) {
        fieldRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        return { ...prev, [name]: err };
      } else {
        delete errors[name];
        return errors;
      }
    });

  return (
    <div className='w-100'>
      <div className='current' data-kt-stepper-element='content'>
        {quotationId ? (
          <TravelDetail quotationId={quotationId} onClickStep={props?.onClickStep} />
        ) : (
          <AddInsuranceCover onSubmit={props?.handleSubmit} />
        )}
      </div>
      <div data-kt-stepper-element='content'>
        <div className='w-100 d-flex flex-column'>
          {formState?.category === 'CFAR_FOR_HOTEL' && (
            <div ref={fieldRef}>
              <CfarDetails errors={errors} handleErrors={handleErrors} />
            </div>
          )}
          {formState?.quotation ? (
            <AddTravellersCount onSubmit={props?.handleSubmit} handleErrors={handleErrors} />
          ) : null}
          {!formState?.quotation ? (
            <AddTravellers
              onSubmit={props?.handleSubmit}
              maxProcessingTime={1}
              className='p-6 w-100 mb-6 bg-white rounded'
              handleErrors={handleErrors}
            />
          ) : null}
        </div>
      </div>
      <div data-kt-stepper-element='content'>
        <ReviewOrder
          onSubmit={props?.handleSubmit}
          handleStepChange={props?.handleStepChange}
          type={ORDER_TYPE.INSURANCE}
          className='py-9 px-9 bg-white rounded'
        />
      </div>
    </div>
  );
}
