import React from 'react';

import OtherInfo from './OtherInfo';
import TravelInfo from './TravelInfo';
import BookingInfo from './BookingInfo';

interface ITravelDetails {
  isEditable: boolean;
  isReviewrequired: boolean;
}

export default function TravelDetails(props: ITravelDetails) {
  return (
    <React.Fragment>
      <TravelInfo isEditable={props?.isEditable} />
      <BookingInfo isEditable={props?.isEditable} />
      <OtherInfo />
    </React.Fragment>
  );
}
