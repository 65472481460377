/* eslint-disable no-unused-vars */
import { useState } from 'react';
import {
  useLocation
  // useSearchParams
} from 'react-router-dom';
import { FormikHelpers, FormikValues } from 'formik';
import { isString } from 'lodash';

import AddVisaType from './AddVisaType';
import AddTravellers from '../components/AddTravellers';
import ReviewOrder from '../components/ReviewOrder';
import { ORDER_TYPE, SUPPLIER_CODE } from '../../constants';
import AddTripDetails from './AddTripDetails';
import TravelDetail from '../components/TravelDetail';
import { useOrderContext } from '../../context/OrderContext';
import ProductViewCard from '../../../../components/ProductCard/ProductViewCard';
import AddStayDetails from './AddStayDetails';
import AddDocuments from './AddDocuments';
import AddTravellersCount from '../quotation/AddTravellersCount';

interface IVisaFormStepsProps {
  handleSubmit: (
    values: any,
    type: string,
    actions?: FormikHelpers<any>,
    cb?: ((response?: any) => void) | undefined
  ) => void;
  handleStepChange: (actions?: FormikValues) => void;
  quotationId?: string | null;
  onClickStep: (stepIndex: number) => void | undefined;
}

export default function VisaFormSteps(props: IVisaFormStepsProps) {
  const location = useLocation();
  // let [searchParams] = useSearchParams();

  const { formState } = useOrderContext();

  const [errors, setErrors] = useState<any>({});

  // const quotation = searchParams.get('quotation');
  const quotationId = location?.state?.quotationId;
  const maxProcessingTime = formState?.tripDetails?.productInformation?.maxProcessingTime ?? 6;
  const maxProcessingTimeMatrix =
    formState?.tripDetails?.productInformation?.maxProcessingTimeMatrix ?? 'days';

  const handleErrors = (name: string, err: string | null) =>
    setErrors((prev: any) => {
      if (isString(err)) {
        return { ...prev, [name]: err };
      } else {
        delete errors[name];
        return errors;
      }
    });

  /**
   * NOTE: StayDetails needs to be shown for below mentioned scenarios:
   * 1. If supplier is VFS and the destination is Thailand
   * 2. If supplier is DNATA and the destination is UAE
   */
  const case_1 =
    formState?.tripDetails?.supplierOrganization?.organizationCode === SUPPLIER_CODE.VFS &&
    formState?.tripDetails?.destination?.[0]?.countryCode === 'TH' &&
    !formState?.groupName &&
    !formState?.quotation;
  const case_2 =
    formState?.tripDetails?.supplierOrganization?.organizationCode === SUPPLIER_CODE.DNATA &&
    formState?.tripDetails?.destination?.[0]?.countryCode === 'AE' &&
    !formState?.groupName &&
    !formState?.quotation;
  const showDetailsFor = case_1 ? 'vfs-thai' : 'dnata-uae';
  const bookingInformation = formState?.tripDetails?.trip?.bookingInformation?.[0];

  return (
    <div className='w-100'>
      <div className='current' data-kt-stepper-element='content'>
        {quotationId ? (
          <TravelDetail quotationId={quotationId} onClickStep={props?.onClickStep} />
        ) : (
          <AddVisaType onSubmit={props?.handleSubmit} />
        )}
      </div>
      <div data-kt-stepper-element='content'>
        <div className='w-100 d-flex flex-column'>
          <ProductViewCard data={formState?.tripDetails} />
          <AddTripDetails
            onSubmit={props?.handleSubmit}
            errors={errors}
            handleErrors={handleErrors}
            maxProcessingTime={maxProcessingTime}
          />
          {case_1 || case_2 ? <AddStayDetails for={showDetailsFor} /> : null}

          {formState?.quotation ? (
            <AddTravellersCount onSubmit={props?.handleSubmit} handleErrors={handleErrors} />
          ) : null}

          {!formState?.groupName && !formState?.quotation ? (
            <AddTravellers
              onSubmit={props?.handleSubmit}
              className='p-6 w-100 bg-white rounded'
              handleErrors={handleErrors}
              maxProcessingTime={maxProcessingTime}
              maxProcessingTimeMatrix={maxProcessingTimeMatrix}
              disableAddButton={!bookingInformation && (case_1 || case_2)}
            />
          ) : null}
        </div>
      </div>
      <div data-kt-stepper-element='content'>
        {formState?.quotation ? (
          <ReviewOrder
            type={ORDER_TYPE.VISA}
            onSubmit={props?.handleSubmit}
            handleStepChange={props?.handleStepChange}
            className='py-9 px-9 bg-white rounded'
          />
        ) : (
          <AddDocuments onSubmit={props?.handleSubmit} handleStepChange={props?.handleStepChange} />
        )}
      </div>

      <div data-kt-stepper-element='content'>
        <ReviewOrder
          onSubmit={props?.handleSubmit}
          handleStepChange={props?.handleStepChange}
          type={ORDER_TYPE.VISA}
          className='py-9 px-9 bg-white rounded'
        />
      </div>
    </div>
  );
}
