import { OATypography } from '../../widgets';

interface IFinancialInformation {
  financialInfo: string[];
}

const FinancialInformation: React.FC<IFinancialInformation> = ({ financialInfo }) => {
  if (!financialInfo || financialInfo?.length === 0) {
    return null;
  }
  return (
    <ul>
      {financialInfo?.length > 0
        ? financialInfo?.map((item: any, index: number) => (
            <li key={index} className='mb-3'>
              <OATypography variant='body2' sx={{ color: theme => theme?.palette?.grey?.[400] }}>
                {item}
              </OATypography>
            </li>
          ))
        : null}
    </ul>
  );
};

export default FinancialInformation;
