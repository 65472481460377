import { useContext, useEffect, useMemo, useState } from 'react';
import { Formik, Form, FormikProps, ErrorMessage, Field, FormikHelpers } from 'formik';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import isEmpty from 'lodash/isEmpty';
import * as Yup from 'yup';

import { UserRoleOptions } from '../../order-management/constants';
import OASelectField from '../../../components/fields/OASelectField';
import * as organisationsAPI from '../../../api/organisationsAPI';
import UserContext from '../context/UserContext';
import { IUserFormValues } from '../types';
import { useAuth } from '../../../auth';

const validationSchema = Yup.object({
  firstName: Yup.string().required().label('First Name'),
  lastName: Yup.string().required().label('Last Name'),
  email: Yup.string().email().required().label('Email address'),
  organizationId: Yup.string().required().label('Organization'),
  role: Yup.string().required().label('Role')
});

const inits: IUserFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  organizationId: '',
  role: ''
};

const UserForm = (props: { data: any }) => {
  const { data } = props;

  const { toggleModal, onEditUser, onCreateUser } = useContext(UserContext);
  const { currentUser } = useAuth();
  const currentUserOrg: any = currentUser?.data?.organization;
  const orgApiCall =
    currentUserOrg?.type === 'ON_ARRIVAL' && currentUserOrg?.id === 'on-arrival-travel';

  const [orgOptions, setOrgOptions] = useState<Array<any>>([]);
  const [initValues, setInitValues] = useState<IUserFormValues>(inits);
  const [userRole, setUserRole] = useState<Array<any>>([]);

  useEffect(() => {
    if (!orgApiCall) {
      setInitValues({ ...inits, ...{ organizationId: currentUserOrg?.id } });
      setUserRole(UserRoleOptions.filter(obj => obj.value !== 'ROLE_SUPER_ADMIN'));
    } else {
      setUserRole(UserRoleOptions);
    }
  }, []);

  /**
   * NOTE: set "initValues" based on the data coming as props...
   */
  useEffect(() => {
    if (!isEmpty(data)) {
      const draftUser: IUserFormValues = {
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        organizationId: data?.organizationId,
        role: data?.role
      };
      draftUser && setInitValues(draftUser);
    }
  }, [data]);

  const { data: orgsRes } = useQuery(['organizations-all'], organisationsAPI.getAllOrganization, {
    keepPreviousData: true,
    staleTime: Infinity
  });

  useMemo(() => {
    let draft = [{ value: currentUserOrg?.id, label: currentUserOrg?.name }];
    if (orgsRes?.data?.data?.length) {
      const org = orgsRes?.data?.data?.map((obj: any) => ({
        value: obj?.id,
        label: obj?.name
      }));
      draft = [...org];
    }
    setOrgOptions(draft);
  }, [orgsRes, currentUserOrg]);
  /**
   * NOTE: if 'data' available then call edit user method or call create
   * user method coming from UserContext ...
   *
   * @param values
   */
  const onClickSubmit = (values: IUserFormValues, actions: FormikHelpers<IUserFormValues>) => {
    if (data?.id) {
      onEditUser(data?.id, values, success => {
        success && toggleModal();
        actions?.setSubmitting(false);
      });
    } else {
      onCreateUser(values, success => {
        success && toggleModal();
        actions?.setSubmitting(false);
      });
    }
  };

  return (
    <Formik
      initialValues={initValues}
      validationSchema={validationSchema}
      onSubmit={onClickSubmit}
      enableReinitialize>
      {(formikProps: FormikProps<IUserFormValues>) => {
        const isSubmitDisabled = !(formikProps?.isValid && formikProps?.dirty);

        return (
          <Container as={Form} className='user--form p-0'>
            <Row className='mb-7'>
              <Col lg={6}>
                <label className='form-label required'>First Name</label>
                <Field
                  type='text'
                  name='firstName'
                  placeholder='Type here'
                  className='form-control form-control-solid'
                />
                <div className='text-danger'>
                  <ErrorMessage name='firstName' />
                </div>
              </Col>
              <Col lg={6}>
                <label className='form-label required'>Last Name</label>
                <Field
                  type='text'
                  name='lastName'
                  placeholder='Type here'
                  className='form-control form-control-solid'
                />
                <div className='text-danger'>
                  <ErrorMessage name='lastName' />
                </div>
              </Col>
            </Row>
            <Row className='mb-7'>
              <Col>
                <label className='form-label required'>Email address</label>
                <Field
                  type='text'
                  name='email'
                  placeholder='Type here'
                  className='form-control form-control-lg form-control-solid'
                  disabled={!isEmpty(data)}
                />
                <div className='text-danger'>
                  <ErrorMessage name='email' />
                </div>
              </Col>
            </Row>
            <Row className='mb-7'>
              <Col lg={8}>
                <Field
                  name='organizationId'
                  label='Organization'
                  as={OASelectField}
                  options={orgOptions}
                  valueOnly
                  required
                />
              </Col>
              <Col>
                <Field
                  name='role'
                  label='Role'
                  as={OASelectField}
                  options={userRole}
                  valueOnly
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col className='text-end'>
                <Button type='reset' size='sm' onClick={toggleModal} variant='light'>
                  Cancel
                </Button>
                <Button
                  type='submit'
                  size='sm'
                  className='ms-4'
                  disabled={isSubmitDisabled || formikProps?.isSubmitting}>
                  {formikProps?.isSubmitting ? 'Saving...' : 'Save'}
                </Button>
              </Col>
            </Row>
          </Container>
        );
      }}
    </Formik>
  );
};

export default UserForm;
