import { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import LogRocket from 'logrocket';
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/react';
import { LayoutProvider, LayoutSplashScreen } from './_metronic/layout/core';
import { MasterInit } from './_metronic/layout/MasterInit';
import { AuthInit, useAuth } from './auth';
import { CookieComponent } from './_metronic/assets/ts/components/_CookieComponent';

import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { isAlsaffronOrg, toAbsoluteUrl } from './helper';

const injectGTM = (id: string) => {
  // Check if the GTM script already exists
  if (!document.querySelector(`script[src^="https://www.googletagmanager.com/gtag/js?id=${id}"]`)) {
    const scriptTag = document.createElement('script');
    scriptTag.async = true;
    scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
    document.head.appendChild(scriptTag);

    const inlineScript = document.createElement('script');
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${id}');
    `;
    document.head.appendChild(inlineScript);
  }
};

const App = () => {
  const { currentUser } = useAuth();
  //setting cookie parameters
  useEffect(() => {
    // Condition to inject GTM
    if (window.location.hostname === 'hub.onarrival.travel') {
      injectGTM('G-M6Y3B9SBJL');
    }

    const cookie = () => {
      let allCookies = Cookies.get();
      for (const key in allCookies) {
        CookieComponent.set(`${key}`, `${allCookies[key]}`, {});
      }
    };
    cookie();
    const intervalId = setInterval(cookie, 2000);
    return () => clearInterval(intervalId);
  }, []);

  //logrocket tracking
  useEffect(() => {
    const identifyUser = () => {
      let userId: string;
      let userProperties: any;

      userId = currentUser?.data?.id;
      userProperties = {
        name: `${currentUser?.data?.firstName} ${currentUser?.data?.lastName}`,
        email: currentUser?.data?.email
      };
      LogRocket.identify(userId, userProperties);
    };

    identifyUser();

    Sentry.setUser({
      name: `${currentUser?.data?.firstName} ${currentUser?.data?.lastName}`,
      email: currentUser?.data?.email
    });
  }, [currentUser]);

  /* update favicon icon and title for alsaffron org */
  useEffect(() => {
    if (currentUser) {
      const isAlsaffron = isAlsaffronOrg(currentUser);
      const title = isAlsaffron ? 'Al Saffron Travel & Tourism LLC' : 'OnArrival Travel';
      const faviconIcon = isAlsaffron ? 'favicon-al.ico' : 'favicon.ico';
      const link: any = document.querySelector("link[rel*='icon']");
      link.href = toAbsoluteUrl(`/media/logos/${faviconIcon}`);
      document.title = title;
    }
  }, [currentUser]);

  // For Alsaffron org
  useEffect(() => {
    const scriptId = 'cpay-checkout-script'; // Unique ID for the script tag

    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.id = scriptId;
      script.async = true;

      if (window.location.href.includes('hub.onarrival.travel')) {
        script.src = 'https://front.cpay.ws/checkout/checkout.js';
      } else {
        script.src = 'https://front-dev.cpay.ws/checkout/checkout.js';
      }

      document.body.appendChild(script);

      return () => {
        // Remove the script when the component unmounts
        document.body.removeChild(script);
      };
    }
  }, []);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <LayoutProvider>
        <AuthInit>
          <Outlet />
          <MasterInit />
          <ToastContainer theme='colored' />
        </AuthInit>
      </LayoutProvider>
    </Suspense>
  );
};

export { App };
