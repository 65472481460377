/* eslint-disable no-unused-vars */
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid-pro';

import { DefaultCell, CreatedOnCell, StatusCell } from '../../../components/OAColumn';
import { OA_DATE_TIME_FORMAT_FE } from '../../../constants';
import { GridColumnHeader } from '../../../components/OATableComponent';

type getOrgColumnsType = (actions: {
  handleEdit: (orgId: string) => void;
  handleDelete: (orgId: string) => void;
}) => GridColDef[];

export const getOrgColumns: getOrgColumnsType = actions => [
  {
    field: 'orgName',
    renderHeader: params => <GridColumnHeader title='Organization Name' params={params} />,
    renderCell: params => <DefaultCell data={params?.value} />,
    valueGetter: params => params?.row?.name ?? '',
    flex: 1
  },
  {
    field: 'legalName',
    renderHeader: params => <GridColumnHeader title='Legal Name' params={params} />,
    renderCell: params => <DefaultCell data={params?.value} />,
    valueGetter: params => params?.row?.legalName ?? '-',
    flex: 1
  },
  {
    field: 'status',
    renderHeader: params => <GridColumnHeader title='Status' params={params} />,
    renderCell: params => <StatusCell status={params?.value} type='org' />,
    valueGetter: params => params?.row?.status,
    width: 85
  },
  {
    field: 'type',
    renderHeader: params => <GridColumnHeader title='Type' params={params} />,
    renderCell: params => <DefaultCell data={params?.value} />,
    valueGetter: params => params?.row?.type ?? '-',
    width: 110
  },
  {
    field: 'ancillaryType',
    renderHeader: params => <GridColumnHeader title='Ancillaries' params={params} />,
    renderCell: params => <DefaultCell data={params?.value} isEllipsis />,
    valueGetter: params =>
      params?.row?.ancillaryType?.length ? params?.row?.ancillaryType?.join(', ') : '-',
    width: 125
  },
  {
    field: 'userEmail',
    renderHeader: params => <GridColumnHeader title='Email Address' params={params} />,
    renderCell: params => <DefaultCell data={params?.value} textCapitalize={false} />,
    valueGetter: params => params?.row?.email ?? '',
    flex: 1,
    minWidth: 200
  },
  {
    field: 'createdOn',
    renderHeader: params => <GridColumnHeader title='Created On' params={params} />,
    renderCell: params => (
      <CreatedOnCell date={params?.value} dateFormat={OA_DATE_TIME_FORMAT_FE} />
    ),
    valueGetter: params => params?.row?.createdAt,
    width: 175
  },
  {
    field: 'action',
    type: 'actions',
    getActions: (params: GridRowParams) => {
      return [
        <GridActionsCellItem
          label='Edit'
          onClick={() => actions?.handleEdit(params?.row?.id)}
          showInMenu
          disabled={params?.row?.status === 'DELETED'}
        />,
        <GridActionsCellItem
          label='Delete'
          onClick={() => actions?.handleDelete(params?.row?.id)}
          showInMenu
          disabled={params?.row?.status === 'DELETED'}
        />
      ];
    },
    width: 50
  }
];
