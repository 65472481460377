import classNames from 'classnames';

import { IconType } from '.';

export default function DeleteSVG(props: IconType) {
  const {
    color = '#B5B5C2',
    width = '14',
    height = '14',
    // isActive = false,
    className = ''
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 18'
      fill='none'
      className={classNames('icon-svg delete-svg', className)}
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4 6H14V13C14 14.6569 12.6569 16 11 16H7C5.34315 16 4 14.6569 4 13V6Z'
        fill={color}
      />
      <path
        opacity='0.75'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11 3H7V4H4V5H14V4H11V3Z'
        fill={color}
      />
    </svg>
  );
}
