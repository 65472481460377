/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Row, Spinner } from 'react-bootstrap';
import classNames from 'classnames';

import OALabelValue, { IOALabelValue } from '../../../../../components/widgets/OALabelValue';
import { useOrderContext } from '../../../context/OrderContext';
import TravelDateFormModal from './TravelDateFormModal';
import { IOrderType, ILabelValuePair } from '../../../types';
import { InsuranceTypeOptions, ORDER_TYPE } from '../../../constants';
import {
  getFormattedAddress,
  getFormattedDate,
  getFormattedDateRange,
  getSupplierOrganizationName
} from '../../../../../helper';
import { useAuth } from '../../../../../auth';
import AssignUserModal from '../../../shared/AssignUserModal';
import UpdateApplicationIdModal from '../../OrderBasicDetails/UpdateApplicationIdModal';
import { KTSVG } from '../../../../../_metronic/helpers';
import OATruncatedText from '../../../../../components/OATruncatedText';

interface ITravelInfo {
  isEditable: boolean;
}

function TravelInfo(props: ITravelInfo) {
  const params = useParams<{ orderType: IOrderType; id?: string }>();

  const { currentUser } = useAuth();

  const hasSupplierViewAccess = currentUser?.data?.organization?.type === 'SUPPLIER';
  const hasConsumerViewAccess = currentUser?.data?.organization?.type === 'CONSUMER';

  const {
    orderData: { data: orderData, isLoading },
    orderActions,
    assignReviewer
  } = useOrderContext();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAssignUserModalOpen, setIsAssignUserModalOpen] = useState<boolean>(false);
  const [isUpdateAppIdModalOpen, setIsUpdateAppIdModalOpen] = useState<boolean>(false);

  const handleCloseAssignUserModal = () => setIsAssignUserModalOpen(false);

  const handleCloseUpdateAppIdModal = () => setIsUpdateAppIdModalOpen(false);

  const handleAssignReviewers = (userId: string, cb?: (res: any) => void) => {
    assignReviewer(orderData?.id, userId, true, _res => {
      handleCloseAssignUserModal();
      cb?.(_res);
    });
  };

  const primaryTraveller = useMemo(
    () => orderData?.members?.find((obj: any) => obj?.primary === true),
    [orderData]
  );
  const organization = currentUser?.data?.organization || {};
  const hasPermissionToAssignUser = organization?.id === orderData?.supplierOrganization?.id;
  const bookingReferenceId = orderData?.trip?.bookingInformation?.[0]?.bookingReferenceId;
  const bookingAmountPerNight = orderData?.trip?.bookingInformation?.[0]?.bookingAmountPerNight;
  const isBusOrCabInsurance =
    orderData?.category === 'CAB_INSURANCE' || orderData?.category === 'BUS_INSURANCE';
  const { minProcessingTime, maxProcessingTime, stayPeriod, validity } =
    orderData?.product?.productInformation || orderData?.groupOrder?.product?.productInformation;

  let draftDataArr: Array<IOALabelValue> = useMemo(
    () => [
      {
        label: 'Primary traveller',
        value: `${primaryTraveller?.title} ${primaryTraveller?.firstName} ${primaryTraveller?.lastName}`,
        show: true,
        className: 'col-md-3'
      },
      {
        label: orderData?.trip?.tripEnd ? 'Travel duration' : 'Travel Date',
        value:
          getFormattedDateRange(
            orderData?.trip?.tripStart?.datetime,
            orderData?.trip?.tripEnd?.datetime
          ) || '',
        show: true,
        className: 'col-md-3'
      },
      {
        label: 'Trip type',
        value: orderData?.trip?.tripType,
        show: !!orderData?.trip?.tripType,
        className: 'col-md-3'
      },
      {
        label: 'Purpose',
        value: orderData?.trip?.visaType,
        show: !!orderData?.trip?.visaType,
        className: 'col-md-3'
      },
      {
        label: 'Supplier ID',
        value: orderData?.supplierBookingId ?? 'N/A',
        show: orderData?.product?.submissionType !== 'OFFLINE',
        className: 'col-md-3'
      },
      {
        label: 'Created on',
        value: getFormattedDate(orderData?.createdAt) ?? 'N/A',
        show: true,
        className: 'col-md-3'
      },
      {
        label: 'Consulate',
        value: orderData?.consulate
          ? orderData?.product?.productInformation?.consulateData?.find(
              (obj: any) => obj?.value === orderData?.consulate
            )?.label
          : 'N/A',
        show: orderData?.product?.ancillaryType === 'EVISA',
        className: 'col-md-3'
      },
      {
        label: 'Application Id',
        value: orderData?.applicationId ?? 'N/A',
        show: orderData?.product?.submissionType === 'OFFLINE' && !props?.isEditable,
        postValueComponent: hasSupplierViewAccess ? (
          <span
            className='btn btn-link btn-color-gray-500 btn-active-color-primary p-0 d-flex align-items-center ms-2'
            onClick={() => setIsUpdateAppIdModalOpen(true)}>
            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-4 m-0' />
          </span>
        ) : null,
        className: 'col-md-3'
      },
      {
        label: 'Assigned to',
        value: orderData?.assignedUser
          ? `${orderData?.assignedUser?.firstName} ${orderData?.assignedUser?.lastName}`
          : 'N/A',
        show: hasSupplierViewAccess && !props?.isEditable,
        postValueComponent: hasPermissionToAssignUser ? (
          <span
            className='btn btn-link btn-color-gray-500 btn-active-color-primary p-0 d-flex align-items-center ms-2'
            onClick={() => setIsAssignUserModalOpen(true)}>
            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-4 m-0' />
          </span>
        ) : null,
        className: 'col-md-3'
      },
      {
        label: 'Supplier',
        value: getSupplierOrganizationName(currentUser, orderData?.supplierOrganization)?.orgName,
        show: true,
        className: 'col-md-3'
      },
      {
        label: 'Hotel Booking Reference Id',
        value: bookingReferenceId,
        show: !!bookingReferenceId,
        className: 'col-md-3'
      },
      {
        label: 'Booking Amount Per Night(INR)',
        value: bookingAmountPerNight,
        show: !!bookingAmountPerNight,
        className: 'col-md-3'
      },
      {
        label: 'Insurance Type',
        value: InsuranceTypeOptions?.find(
          (obj: ILabelValuePair) => obj?.value === orderData?.category
        )?.label,
        show: isBusOrCabInsurance,
        className: 'col-md-3'
      },
      {
        label: 'Origin Address',
        value: isBusOrCabInsurance && (
          <OATruncatedText text={getFormattedAddress(orderData?.trip?.origin)} maxLength={40} />
        ),
        show: isBusOrCabInsurance,
        className: 'col-md-6'
      },
      {
        label: 'Destination Address',
        value: isBusOrCabInsurance && (
          <OATruncatedText
            text={getFormattedAddress(orderData?.trip?.destination?.[0])}
            maxLength={40}
          />
        ),
        show: isBusOrCabInsurance,
        className: 'col-md-6'
      },
      {
        label: 'Processing time',
        value:
          minProcessingTime && maxProcessingTime
            ? `${minProcessingTime} - ${maxProcessingTime} days`
            : 'N/A',
        show: params?.orderType === ORDER_TYPE.VISA,
        className: 'col-md-3'
      },
      {
        label: 'Stay period',
        value: stayPeriod ?? 'N/A',
        show: params?.orderType === ORDER_TYPE.VISA,
        className: 'col-md-3'
      },
      {
        label: 'Validity',
        value: validity ?? 'N/A',
        show: params?.orderType === ORDER_TYPE.VISA,
        className: 'col-md-3'
      }
    ],
    [
      primaryTraveller,
      orderData,
      hasSupplierViewAccess,
      props?.isEditable,
      hasPermissionToAssignUser
    ]
  );

  const isDateChangeRequested = orderActions?.data?.some(
    (obj: any) => obj?.reason === 'TRIP_DURATION' && obj?.status === 'CHANGES_REQUESTED'
  );

  const renderActionButtons = () => {
    const isSupplierOrder = !!orderData?.supplierOrder;

    return (
      <div className='d-flex align-items-center gap-2 gap-lg-3'>
        {props?.isEditable ||
        (isDateChangeRequested &&
          (hasConsumerViewAccess || (hasSupplierViewAccess && isSupplierOrder))) ? (
          <Button
            size='sm'
            className='btn-light-primary btn-active-light-primary me-3'
            onClick={() => setIsOpen(true)}>
            Change travel date
          </Button>
        ) : null}
      </div>
    );
  };

  return (
    <div id='travel_info' className='travel-info card mb-6'>
      <div className='card-header align-items-center border-0'>
        <h3 className='card-title align-items-start flex-column'>
          <div className='d-flex align-items-center text-gray-800 fw-bolder'>
            <span className='fw-bolder text-dark'>Travel information</span>
          </div>
        </h3>
        {renderActionButtons()}
      </div>

      {orderData?.isLoading ? (
        <div className='card-body d-flex align-items-center justify-content-center text-muted fw-bold'>
          <Spinner animation='border' className='me-4' /> Loading...
        </div>
      ) : (
        <div className='card-body pt-5'>
          <Row>
            {draftDataArr?.map((obj: IOALabelValue, idx: number) => {
              const { className, ...rest } = obj;
              return obj?.show ? (
                <div key={idx} className={classNames(className, 'mb-6')}>
                  <OALabelValue {...rest} />
                </div>
              ) : null;
            })}
          </Row>
        </div>
      )}
      <TravelDateFormModal show={isOpen} onHide={() => setIsOpen(false)} />

      <AssignUserModal
        onHide={handleCloseAssignUserModal}
        show={isAssignUserModalOpen}
        handleAssignReviewers={handleAssignReviewers}
        data={orderData?.data?.assignedUser}
      />
      <UpdateApplicationIdModal
        onHide={handleCloseUpdateAppIdModal}
        isOpen={isUpdateAppIdModalOpen}
      />
    </div>
  );
}

export default TravelInfo;
