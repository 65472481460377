import React from 'react';
import { useParams } from 'react-router-dom';
import { OverlayTrigger, OverlayTriggerProps, Popover } from 'react-bootstrap';
import { Box } from '@mui/material';
import { capitalize } from 'lodash';

import { useOrderContext } from '../../context/OrderContext';
import { getFormattedDateRange } from '../../../../helper';
import { ORDER_TYPE, OccupationOptions } from '../../constants';
import { IOrderType } from '../../types';
import { OATypography } from '../../../../components/widgets';
import { StatusCell, TripCell } from '../../../../components/OAColumn';

import { ReactComponent as DotSeparatorIcon } from '../../../../assets/icons/dot_separator.svg';
import { ReactComponent as CalenderIcon } from '../../../../assets/icons/calender.svg';
import { ReactComponent as MembersIcon } from '../../../../assets/icons/members.svg';

const OrderBasicDetails = () => {
  const params = useParams<{ orderType: IOrderType; id: string }>();
  const {
    orderData: { data, isLoading }
  } = useOrderContext();

  const isQuotation = data?.bookingStatus === 'ORDER_QUOTE';
  const displayTitle =
    params?.orderType === ORDER_TYPE.VISA
      ? data?.product?.displayName
      : data?.product?.productInformation?.name;
  const travelDuration = getFormattedDateRange(
    data?.trip?.tripStart?.datetime,
    data?.trip?.tripEnd?.datetime
  );
  const membersCount = data?.linkedMembers?.length;
  let memberName = '';
  if (data?.members?.length > 0) {
    memberName = data?.members?.[0]?.firstName + ' ' + data?.members?.[0]?.lastName;
  } else if (data?.memberCount?.length > 0) {
    let result = '';
    for (let i = 0; i < data?.memberCount.length; i++) {
      if (i > 0) result += `, `;
      result += `${data?.memberCount[i].count} ${capitalize(data?.memberCount[i].type)}`;
    }
    memberName = result;
  }
  const allMemberNames = data?.members
    ?.filter((_: any, index: number) => index !== 0)
    .map(
      (member: { firstName: string; lastName: string }) => member.firstName + ' ' + member.lastName
    );

  const occupationText = OccupationOptions?.find(obj => obj?.value === data?.occupation)?.label;

  const overlayTriggerProps: Omit<OverlayTriggerProps, 'children'> = {
    trigger: ['hover', 'focus'],
    placement: 'bottom',
    overlay: (
      <Popover id='popover-trigger-hover-focus' className='p-3' title='Popover bottom'>
        {allMemberNames?.join(', ')}
      </Popover>
    )
  };

  const renderText = (text: string, width: number) => (
    <OATypography
      variant='body2'
      color={theme => theme?.palette?.grey?.[700]}
      isLoading={isLoading}
      skeletonProps={{ height: 24, width }}>
      {text}
    </OATypography>
  );

  return (
    <Box display='flex' flexDirection='column' gap='8px'>
      <Box display='flex' gap='8px' alignItems='center'>
        <OATypography
          isLoading={isLoading}
          skeletonProps={{ height: 24, width: 34 }}
          component='div'
          display='flex'>
          <TripCell {...data?.trip} />
        </OATypography>
        <OATypography variant='h2' isLoading={isLoading} skeletonProps={{ height: 24, width: 240 }}>
          {displayTitle}
        </OATypography>
        {params?.orderType === ORDER_TYPE.INSURANCE && <StatusCell status={data?.bookingStatus} />}
      </Box>

      <Box display='flex' alignItems='center' marginBottom='10px'>
        {data?.groupId ? (
          <React.Fragment>
            {renderText(data?.groupId, 90)}
            <DotSeparatorIcon style={{ margin: '0 6px' }} />
          </React.Fragment>
        ) : null}
        {renderText(data?.id, 90)}
        <DotSeparatorIcon style={{ margin: '0 6px' }} />
        <Box display='flex' alignItems='center' gap='4px'>
          <CalenderIcon />
          {renderText(travelDuration!, 186)}
        </Box>
        <DotSeparatorIcon style={{ margin: '0 6px' }} />
        <Box display='flex' alignItems='center' gap='4px'>
          <MembersIcon />
          <Box display='flex' alignItems='center' gap='4px'>
            {renderText(memberName, 120)}
            {allMemberNames?.length > 0 && (
              <OverlayTrigger {...overlayTriggerProps}>
                <Box component='u' sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                  <OATypography
                    variant='body2'
                    color='primary'
                    noWrap
                    isLoading={isLoading}
                    skeletonProps={{ height: 24, width: 60 }}>
                    +{membersCount - 1} more
                  </OATypography>
                </Box>
              </OverlayTrigger>
            )}
          </Box>
        </Box>
        {isQuotation ? (
          <React.Fragment>
            <DotSeparatorIcon style={{ margin: '0 6px' }} />
            <Box display='flex' alignItems='center' gap='4px'>
              {renderText(data?.proposerInfo?.companyName, 186)}
            </Box>
            {params?.orderType === ORDER_TYPE.VISA ? (
              <React.Fragment>
                <DotSeparatorIcon style={{ margin: '0 6px' }} />
                <Box display='flex' alignItems='center' gap='4px'>
                  {renderText(occupationText!, 186)}
                </Box>
                <DotSeparatorIcon style={{ margin: '0 6px' }} />
                <Box display='flex' alignItems='center' gap='4px'>
                  {renderText(data?.consulate, 186)}
                </Box>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ) : null}
      </Box>
    </Box>
  );
};

export default OrderBasicDetails;
