import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form, FormikProps, Field, FormikHelpers } from 'formik';
import { Button, Col, Container, Row } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import * as Yup from 'yup';

import { CountryOptions, StateOptions } from '../../order-management/constants';
import { useAuth } from '../../../auth';
import OrgContext from '../context/OrgContext';
import OASelectField from '../../../components/fields/OASelectField';
import OATextField from '../../../components/fields/OATextField';
import OrgDynamicFields from './OrgDynamicFields';
import OACheckboxField from '../../../components/fields/OACheckboxField';
import SuppliersFieldArray from './SuppliersFieldArray';
import OADropZone from '../../../components/OADropZone';
import { useS3FileUploader } from '../../../hooks/useS3FileOperation';

interface IOrgForm {
  data: any;
  toggle: () => void;
}

export interface IOrgFormValues {
  name: string;
  legalName: string;
  email: string;
  isChild: string | boolean;
  parentOrganizationId: string;
  type?: 'CONSUMER' | 'SUPPLIER';
  ancillaryType: Array<any>;
  addressLine1: string;
  country: string;
  state: string;
  city: string;
  postalCode: string;
  associatedOrganization: any;
  liveModeEnabled: boolean;
  logo: string;
}

const validationSchema = Yup.object({
  name: Yup.string().label('Organization Name').required(),
  legalName: Yup.string().label('Legal Name').required(),
  email: Yup.string().email().label('Email address').required(),
  type: Yup.string().label('Type').required(),
  addressLine1: Yup.string().label('Full Address').required(),
  country: Yup.string().label('Country').required(),
  state: Yup.string().label('State').required(),
  city: Yup.string().label('City').required(),
  postalCode: Yup.string().label('Postal Code').required()
});

const init: IOrgFormValues = {
  name: '',
  legalName: '',
  email: '',
  isChild: 'false',
  parentOrganizationId: '',
  ancillaryType: [],
  addressLine1: '',
  country: 'IN',
  state: '',
  city: '',
  postalCode: '',
  associatedOrganization: '',
  liveModeEnabled: false,
  logo: ''
};

export default function OrgForm(props: IOrgForm) {
  const { data } = props;

  const { currentUser } = useAuth();

  const { onEditOrg, onCreateOrg } = useContext(OrgContext);

  const currentUserOrg: any = currentUser?.data?.organization;
  const isOnArraival: boolean = currentUserOrg?.type === 'ON_ARRIVAL';

  const [initValues, setInitValues] = useState<IOrgFormValues>(init);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadFile] = useS3FileUploader(5, ['jpg', 'png', 'jpeg', 'pdf']);
  const [files, setFiles] = useState<any>();

  useEffect(() => {
    if (!isOnArraival) {
      const draft = Object.assign({}, init, {
        parentOrganizationId: currentUserOrg?.id,
        type: currentUserOrg?.type,
        liveModeEnabled: currentUserOrg?.liveModeEnabled
      });
      setInitValues(draft);
    } else {
      setInitValues(init);
    }
  }, []);

  /**
   * NOTE: set "initValues" based on the data coming as props...
   */
  useEffect(() => {
    if (!isEmpty(data)) {
      const draftOrg: IOrgFormValues | any = getModifiedValues(data);
      setInitValues(draftOrg);
    }
  }, [data]);

  const handleFileUpload = async (values: any) => {
    setIsLoading(true);
    const uploadedUrls = [];
    for (const file of values) {
      const result = await uploadFile(file);
      uploadedUrls.push(result?.uploadedUrl);
    }
    setIsLoading(false);
    setFiles(uploadedUrls);
  };

  const onSubmit = (values: IOrgFormValues, actions: FormikHelpers<IOrgFormValues>) => {
    setIsLoading(true);
    values.logo = files[0];
    if (data?.id) {
      onEditOrg(data?.id, values, res => {
        actions?.setSubmitting(false);
        setIsLoading(false);
        res && props?.toggle?.();
      });
    } else {
      onCreateOrg(values, res => {
        actions?.setSubmitting(false);
        setIsLoading(false);
        res && props?.toggle?.();
      });
    }
  };

  return (
    <Formik
      initialValues={initValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize>
      {(formikProps: FormikProps<IOrgFormValues>) => {
        const isSubmitDisabled = !(formikProps?.isValid && formikProps?.dirty);
        return (
          <Container as={Form} className='org--form p-0'>
            <Row className='mb-7'>
              <Col lg={6}>
                <OATextField name='name' label='Organization Name' required />
              </Col>
              <Col lg={6}>
                <OATextField name='legalName' label='Legal Name' required />
              </Col>
            </Row>
            <Row className='mb-7'>
              <Col>
                <OATextField name='email' label='Email address' required />
              </Col>
            </Row>
            <div className='separator mb-5' />
            <OrgDynamicFields currentUserOrg={currentUserOrg} isOnArraival={isOnArraival} />

            <SuppliersFieldArray data={initValues?.associatedOrganization} />
            <div className='separator mt-5' />
            <Row className='my-7'>
              <Col>
                <OATextField name='addressLine1' label='Full Address' required />
              </Col>
            </Row>
            <Row className='mb-7'>
              <Col lg={6}>
                <Field
                  name='country'
                  label='Country'
                  as={OASelectField}
                  options={CountryOptions}
                  valueOnly
                  required
                />
              </Col>
              <Col lg={6}>
                <Field
                  name='state'
                  label='State'
                  as={OASelectField}
                  options={StateOptions}
                  valueOnly
                  required
                />
              </Col>
            </Row>
            <Row className='mb-7'>
              <Col lg={6}>
                <OATextField name='city' label='City' required />
              </Col>
              <Col lg={6}>
                <OATextField name='postalCode' label='Postal Code' required />
              </Col>
            </Row>
            <Row>
              <Col sm={12} className='mb-5'>
                <label htmlFor='attachments' className='form-label'>
                  Upload Logo
                </label>
                <OADropZone
                  fileTypes={['png', 'jpeg', 'jpg', 'pdf']}
                  maxFiles={1}
                  height={86}
                  placeHolder='Drag and drop or Choose file to upload (max 5MB only)'
                  onFileDragDrop={handleFileUpload}
                  prevFile={data?.logo ? [{ path: data?.logo?.split('/')[3] }] : null}
                />
              </Col>
            </Row>
            <div className='separator mb-5' />
            <Row>
              <Col>
                <OACheckboxField
                  name='liveModeEnabled'
                  label='Enable live mode'
                  size='sm'
                  className='me-4'
                />
              </Col>
            </Row>

            <Row>
              <Col className='text-end'>
                <Button type='reset' size='sm' onClick={props?.toggle} variant='light'>
                  Cancel
                </Button>
                <Button
                  type='submit'
                  size='sm'
                  className='ms-4'
                  disabled={isSubmitDisabled || formikProps?.isSubmitting}>
                  {formikProps?.isSubmitting ? 'Saving...' : 'Save'}
                </Button>
              </Col>
            </Row>
          </Container>
        );
      }}
    </Formik>
  );
}

// helper functions ...
const getModifiedValues = (values: any) => {
  let draft: any = {
    name: values?.name,
    legalName: values?.legalName,
    email: values?.email,
    isChild: values?.parentOrganizationId ? 'true' : 'false',
    parentOrganizationId: values?.parentOrganizationId ?? null,
    type: values?.type,
    ancillaryType: values?.ancillaryType,
    addressLine1: values?.address?.line1,
    country: values?.address?.country,
    state: values?.address?.state,
    city: values?.address?.city,
    postalCode: values?.address?.postalCode,
    liveModeEnabled: values?.liveModeEnabled,
    logo: values?.logo
  };

  if (values?.associatedOrganization?.length) {
    let draftAO: any = {};

    values?.associatedOrganization?.forEach((org: any) => {
      let ancl: any = {};
      org?.ancillaries?.forEach((anc: any) => {
        ancl[anc.ancillaryType] = true;
      });

      draftAO[org.organizationId] = {
        ancillaryType: ancl
      };
    });

    draft.associatedOrganization = draftAO;
  }

  return draft;
};
