import { debounce } from 'lodash';
import { Dispatch, useContext, useMemo, useState } from 'react';
import { KTCard, KTSVG, KTCardBody } from '../../../_metronic/helpers';
import { IClaimQueryParams } from '../type';
import { getClaimColumns } from './claimsColumns';
import OATableComponent, { OATablePaginationProps } from '../../../components/OATableComponent';
import { GridEventListener } from '@mui/x-data-grid-pro';
import { ClaimDetails } from './claimDetails';
import ClaimContext from '../context/ClaimContext';

interface IClaimsHistory {
  list: Array<any>;
  totalPage: number;
  queryParams: IClaimQueryParams;
  setQueryParams: Dispatch<React.SetStateAction<IClaimQueryParams>>;
  onChangeRangePicker: (range: any) => void;
  loading: boolean;
}

export const ClaimsHistory = (props: IClaimsHistory) => {
  const { list, queryParams, setQueryParams, loading } = props;
  const { pageInfo } = useContext(ClaimContext);
  const [claimsData, setClaimData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onHideRequestSupportModal = () => {
    setIsModalOpen(false);
  };

  const onChangeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams(prev => ({
      ...prev,
      search: evt?.target?.value?.trim(),
      pageIndex: 1
    }));
  };

  const tableColumns = useMemo(() => {
    return getClaimColumns(onHideRequestSupportModal);
  }, [list]);

  const debouncedOnChange = debounce(onChangeHandler, 300);

  const handleRowClick: GridEventListener<'cellClick'> = params => {
    params?.field !== 'actionColumn' ? setIsModalOpen(true) : null;
    setClaimData(params?.row);
  };

  const paginationProps: OATablePaginationProps = {
    pageIndex: queryParams?.pageIndex,
    pageCount: pageInfo?.totalPages,
    total: pageInfo?.totalCount,
    pageSize: pageInfo?.size,
    onPageChange: (value: any) => {
      setQueryParams(prev => ({ ...prev, pageIndex: value }));
    }
  };

  return (
    <KTCard>
      <div className='card-header min-h-50px align-items-center px-6 py-4'>
        <div className='card-title position-relative search-orders'>
          <KTSVG
            className='position-absolute ms-2 svg-icon-4'
            path='/media/icons/duotune/general/gen021.svg'
          />
          <input
            type='text'
            onChange={debouncedOnChange}
            className='form-control form-control-sm form-control-solid oa-text-search w-150px ps-9'
            placeholder='Search Orders'
          />
        </div>
      </div>
      <KTCardBody className='px-6 py-5'>
        <OATableComponent
          columns={tableColumns}
          data={list ?? []}
          onCellClick={handleRowClick}
          pagination={paginationProps}
          loading={loading}
        />
      </KTCardBody>
      <ClaimDetails isOpen={isModalOpen} onHide={onHideRequestSupportModal} data={claimsData} />
    </KTCard>
  );
};
