import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { Col, Row } from 'react-bootstrap';

import { ILabelValuePair } from '../../../types';
import { ORG_TYPES } from '../../../constants';
import { useOrgContext } from '../context/OrgContext';
import OAMultiSelectField from '../../../components/fields/OAMultiSelectField';
import OASelectField from '../../../components/fields/OASelectField';
import OARadioField from '../../../components/fields/OARadioField';

interface IOrgDynamicFields {
  currentUserOrg: any;
  isOnArraival: boolean;
}

export default function OrgDynamicFields(props: IOrgDynamicFields) {
  const { organizations } = useOrgContext();

  const { values, setFieldValue } = useFormikContext<any>();
  /**
   * 'orgOptions' will be useful only if both conditions matches:
   *  - loggedin user org type is 'ON_ARRIVAL' / props?.isOnArraival === true
   *  - user choose to create a child org
   */
  const [orgOptions, setOrgOptions] = useState<Array<any>>([]);
  const [ancillaries, setAncillaries] = useState<Array<ILabelValuePair>>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (props?.isOnArraival && organizations?.data?.length) {
      const draft = organizations?.data
        ?.filter((obj: any) => !obj?.parentOrganizationId)
        ?.map((obj: any) => ({ value: obj?.id, label: obj?.name }));
      setOrgOptions(draft);
    } else {
      setOrgOptions([
        {
          value: props?.currentUserOrg?.id,
          label: props?.currentUserOrg?.name
        }
      ]);
    }
  }, [props?.isOnArraival, organizations]);

  useEffect(() => {
    if (values?.isChild === 'true' && values?.parentOrganizationId) {
      const selectedOrg: any = organizations?.data?.find(
        (org: any) => org?.id === values?.parentOrganizationId
      );

      let draftAncillaries = [];
      if (selectedOrg?.ancillaryType?.length) {
        draftAncillaries = selectedOrg?.ancillaryType
          ?.filter((anc: string) => ['EVISA', 'INSURANCE', 'FLIGHT']?.includes(anc))
          ?.map((anc: string) => ({ value: anc, label: anc }));
      }
      setAncillaries(draftAncillaries);

      if (ORG_TYPES?.map((obj: ILabelValuePair) => obj?.value)?.includes(selectedOrg?.type)) {
        setIsDisabled(true);
        setFieldValue('type', selectedOrg?.type);
      }
    } else {
      let draftAncillaries = [];
      if (props?.currentUserOrg?.ancillaryType?.length) {
        draftAncillaries = props?.currentUserOrg?.ancillaryType
          ?.filter((anc: string) => ['EVISA', 'INSURANCE', 'FLIGHT']?.includes(anc))
          ?.map((anc: string) => ({ value: anc, label: anc }));
      }
      setAncillaries(draftAncillaries);
    }
  }, [values?.isChild, values?.parentOrganizationId]);

  return (
    <React.Fragment>
      {props?.isOnArraival ? (
        <Row className='mb-7'>
          <Col>
            <OARadioField
              name='isChild'
              label='Do you want to create a child organization ?'
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' }
              ]}
              alignment='horizontal'
            />
          </Col>
        </Row>
      ) : null}
      <Row className='mb-7'>
        {(props?.isOnArraival && values?.isChild === 'true') || !props?.isOnArraival ? (
          <Col lg={6}>
            <Field
              name='parentOrganizationId'
              label='Parent Organization'
              as={OASelectField}
              options={orgOptions}
              valueOnly
              required
              isDisabled={!props?.isOnArraival}
            />
          </Col>
        ) : null}
        <Col lg={6}>
          <Field
            name='type'
            label='Type'
            as={OASelectField}
            options={ORG_TYPES}
            isDisabled={isDisabled || !props?.isOnArraival}
            valueOnly
            required
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <OAMultiSelectField
            name='ancillaryType'
            label='Ancillaries'
            options={ancillaries}
            valueOnly
            required
          />
        </Col>
      </Row>
    </React.Fragment>
  );
}
