import classNames from 'classnames';
import { IconType } from '.';

export default function ApproveSVG(props: IconType) {
  const {
    color = '#74CA8F',
    width = '15',
    height = '15',
    // isActive = false,
    className = ''
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      className={classNames('icon-svg approve-svg', className)}
      xmlns='http://www.w3.org/2000/svg'>
      <path
        opacity='0.3'
        d='M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z'
        fill={color}
      />
      <path
        d='M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z'
        fill={color}
      />
    </svg>
  );
}
