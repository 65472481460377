import { Card } from 'react-bootstrap';
import { OATypography } from '../../../components/widgets';
import { Box, Tab, Tabs } from '@mui/material';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';

interface ISideNavigation {
  accordionConfig: any;
  sectionRefs: any;
}

const SideNavigation: React.FC<ISideNavigation> = forwardRef(({ accordionConfig, sectionRefs }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isUserScroll, setIsUserScroll] = useState(false); // New state to track user-initiated scrolls
  const sidebarScrollRef = useRef(null);

  const handleScroll = () => {
    if (isUserScroll) return; // Ignore scroll events triggered by tab clicks
    let newActiveTab = null;
    Object.entries(sectionRefs?.current || {}).forEach(([key, ref]: any, index) => {
      if (
        ref &&
        window.scrollY >= ref.offsetTop - 50 &&
        window.scrollY < ref.offsetTop + ref.offsetHeight
      ) {
        newActiveTab = parseInt(key);
      }
    });

    // Check if the user has scrolled to the top of the page
    if (window.scrollY < 50) {
      newActiveTab = 0;
    }

    if (newActiveTab !== null && newActiveTab !== activeTab) {
      setActiveTab(newActiveTab);

      const sidebar: any = sidebarScrollRef.current;
      const activeTabElement = sidebar?.querySelector(`[data-tab-index="${newActiveTab}"]`);

      if (activeTabElement && sidebar) {
        const activeTabTop = activeTabElement.offsetTop;
        const sidebarHeight = sidebar.offsetHeight;

        // Calculate the desired scrollTop position
        const desiredScrollTop = activeTabTop - sidebarHeight / 2;

        // Scroll the sidebar
        sidebar.scrollTop = desiredScrollTop;
      }
    }
  };

  useEffect(() => {
    const debouncedHandleScroll = debounce(handleScroll, 100);
    window.addEventListener('scroll', debouncedHandleScroll);

    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [activeTab, sectionRefs, isUserScroll]);

  return (
    <Box
      ref={sidebarScrollRef}
      sx={{
        position: 'sticky',
        top: '86px',
        maxHeight: '500px',
        overflowY: 'auto',
        borderRadius: '8px',
        width: 'fit-content'
      }}>
      <Card>
        <OATypography
          variant='h5'
          sx={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            zIndex: 1,
            p: '10px 16px'
          }}>
          On this page
        </OATypography>
        <Tabs
          orientation='vertical'
          variant='standard'
          value={activeTab}
          sx={{
            borderLeft: 1,
            borderColor: 'divider',
            mt: '8px',
            ml: '6px',
            marginX: '16px'
          }}
          TabIndicatorProps={{ style: { left: 0 } }}>
          {accordionConfig?.map((item: any, index: number) => (
            <Tab
              key={index}
              data-tab-index={index}
              label={item?.header}
              sx={{
                marginLeft: '8px',
                minHeight: '10px',
                padding: '6px 0',
                alignItems: 'start',
                textAlign: 'start',
                textTransform: 'none',
                fontSize: '14px',
                lineHeight: '20px'
              }}
              onClick={() => {
                setIsUserScroll(true); // Set the flag to true
                setActiveTab(index);
                const sectionRef = sectionRefs?.current[index];
                if (sectionRef) {
                  sectionRef.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                  });
                }
                setTimeout(() => setIsUserScroll(false), 1000); // Reset the flag after the scroll
              }}
            />
          ))}
        </Tabs>
      </Card>
    </Box>
  );
});

SideNavigation.displayName = 'SideNavigation';

export default SideNavigation;
