import { isArray, isNull } from 'lodash';

import { setDateFormat } from '../../../helper';
import { DestinationOptionsMap } from '../../order-management/constants';
import { IQuotationFilterQueryParams } from '../types';

export const getMemberCount = (tf: any) => {
  let memberCount: { type: string; count: number }[] = [];
  if (tf?.senior && tf?.senior > 0) {
    memberCount.push({ type: 'SENIOR', count: tf?.senior });
  }
  if (tf?.adult && tf?.adult > 0) {
    memberCount.push({ type: 'ADULT', count: tf?.adult });
  }
  if (tf?.child && tf?.child > 0) {
    memberCount.push({ type: 'CHILD', count: tf?.child });
  }
  if (tf?.infant && tf?.infant > 0) {
    memberCount.push({ type: 'INFANT', count: tf?.infant });
  }
  return memberCount;
};

export const buildQueryParams = (queryParams: IQuotationFilterQueryParams): string => {
  const { pageIndex = 1, search = '', createdBy = '' } = queryParams;
  let queryStr = `page=${pageIndex - 1}&size=20`;

  if (search) queryStr += `&search=${search}`;
  if (createdBy) queryStr += `&createdBy=${createdBy}`;

  if (queryParams?.sortBy && queryParams?.sortByField) {
    queryStr = `${queryStr}&sortByField=${queryParams?.sortByField}&sortBy=${queryParams?.sortBy}`;
  }

  return queryStr;
};

export const quotationRequestBody = (values: any) => {
  const localTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const regex = /^[A-Z]{2}(,[A-Z]{2})*$/; // Regular expression to match the format
  let arrivalArray =
    !isArray(values?.arrival) && regex?.test(values?.arrival)
      ? values?.arrival?.split(',')
      : values?.arrival;
  values.arrival = Object.assign([], arrivalArray);

  let arrival: any;
  if (values?.arrival && isArray(values?.arrival)) {
    arrival = values?.arrival?.map((item: any) => ({ countryCode: item }));
  } else {
    arrival = [{ countryCode: values?.arrival }];
  }

  let origin: any;
  if (values?.state && values?.state !== '') {
    origin = {
      countryCode: values?.departure,
      state: values?.state
    };
  } else {
    origin = {
      countryCode: values?.departure
    };
  }

  let draft: any = {
    requestedProducts: [values?.requestedProductId],
    trip: {
      tripStart: {
        datetime: setDateFormat.startOfDayForBE(new Date(values?.tripDuration?.from)),
        timezone: localTZ
      },
      tripEnd: {
        datetime: setDateFormat.endOfDayForBE(new Date(values?.tripDuration?.to)),
        timezone: localTZ
      },
      origin: origin,
      destination: arrival
    },
    memberCount: getMemberCount(values),
    proposerInfo: {
      name: values?.proposerName,
      email: values?.proposerEmail,
      companyName: values?.companyName
    },
    save: values?.save ? true : false
  };
  if (!!values?.occupation) {
    draft['occupation'] = values?.occupation;
  }
  if (values?.tripType) {
    draft['trip']['tripType'] = values?.tripType;
  }
  if (values?.visaType) {
    draft['trip']['visaType'] = values?.visaType;
  }
  if (values?.markup) {
    draft['markup'] = { amount: values?.markup };
  }
  if (values?.additionalEmail) {
    draft['additionalEmails'] = values?.additionalEmail.toString().split(',');
  }

  const isDomestic = values?.destination === DestinationOptionsMap.DOMESTIC;
  if (isDomestic || (isDomestic && values?.tripType !== 'SINGLE')) {
    draft['trip']['tripEnd'] = {
      datetime: setDateFormat.endOfDayForBE(new Date(values?.tripDuration?.to)),
      timezone: localTZ
    };
    draft['trip']['domestic'] = true;
  }
  if (!isNull(values?.consulate)) {
    draft['consulate'] = values?.consulate;
  }
  return draft;
};
