import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { IOrderType } from '../../../types';
import { ORDER_TYPE } from '../../../constants';
import {
  extractNonZipFilesFromZip,
  getFileExtensionFromMimeType,
  showToast
} from '../../../../../helper';
import OADropZone from '../../../../../components/OADropZone';
import DocumentsReviewModal from '../../../order-create/components/AddTravellers/DocumentsReviewModal';
import OATableComponent, { GridColumnHeader } from '../../../../../components/OATableComponent';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import ImageEditorModal from '../../../../../components/OAImageEditor';
import { useOrderContext } from '../../../context/OrderContext';
import moment from 'moment';
import PreviewCell from '../../../../../components/OAColumn/PreviewCell';
import { DefaultCell, StatusCell } from '../../../../../components/OAColumn';
import ActionWithIcon from '../../../../../components/OAColumn/ActionWithIcon';
import { ArrowDownSVG } from '../../../../../components/Icons';
import { formatWordWithUnderscore } from '../../../../../helper';
import ActionsCell from '../../../../../components/OAColumn/ActionsCell';
import { IDocument, IMember } from '../../../../../types';
import { useS3FileUploader } from '../../../../../hooks/useS3FileOperation';
import { KTSVG } from '../../../../../_metronic/helpers';
import { handleDownload } from '../../../../../helper/handleDownload';
import { OATypography } from '../../../../../components/widgets';
import OAButton from '../../../../../components/widgets/OAButton';
import DocumentPreview from './DocumentPreview';
interface ITravellerDocuments {
  memberData: any;
  orderData: any;
}

const TravellerDocuments = (props: ITravellerDocuments) => {
  const { memberData, orderData } = props;
  const params = useParams<{ orderType: IOrderType; id?: string }>();

  const {
    getOrderById,
    fetchDocumentsFromClassifier,
    updateDocumentClassifierAction,
    deleteDocument,
    uploadDocument,
    getOrderActions
  } = useOrderContext();

  const [uploadedDocuments, setUploadedDocuments] = useState<any>(null);
  const [classifiedDocuments, setClassifiedDocuments] = useState<any>(null);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState<any>(false);
  const [isEditorOpen, setIsEditorOpen] = useState<boolean>(false);
  const [imgFile, setImgFile] = useState<File | null>(null);
  const [currentDoc, setCurrentDoc] = useState<any>(null);
  const [selectedClassifiedDocument, setSelectedClassifiedDocument] = useState<any>(null);
  const [isDocumentPreviewModalOpen, setIsDocumentPreviewModalOpen] = useState<boolean>(false);
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);
  const [uploadFile] = useS3FileUploader(10, ['jpg', 'png', 'jpeg', 'pdf', 'docx', 'xlsx', 'zip']);

  const fullName = `${memberData?.firstName} ${memberData?.lastName?.split()[0]}`;

  const fetchDocumentsByConsulate = () => {
    const orderConsulate = orderData?.consulate;
    if (!orderConsulate) return null;

    const requiredDocuments = memberData?.documents?.filter(
      (document: IDocument) => !document.additionalDocument
    );

    const requiredAndAdditionalDocuments = memberData?.documents?.filter(
      (doc: IDocument) => doc?.documentUrl
    );

    const uploadedDocsFromMember =
      memberData?.documents?.filter((doc: IDocument) => doc?.uploaded === true) || [];

    return { requiredDocuments, requiredAndAdditionalDocuments, uploadedDocsFromMember };
  };

  const { requiredDocuments, requiredAndAdditionalDocuments, uploadedDocsFromMember } =
    fetchDocumentsByConsulate() || {};

  const linkedMemberIdToFind = memberData?.id;

  const memberSpecificDocs =
    orderData?.uploadedMemberDocuments?.filter(
      (member: IMember) => member.linkedMemberId === linkedMemberIdToFind
    ) || [];

  const previouslyUploadedDocsFromOrder = memberSpecificDocs.flatMap(
    (member: IMember) => member?.uploadedDocuments || []
  );

  const allUploadedDocuments = [
    ...(requiredAndAdditionalDocuments || []),
    ...(previouslyUploadedDocsFromOrder || [])
  ];

  const tableColumns = (docs: any) => [
    {
      field: 'documentPreview',
      renderHeader: (params: any) => <GridColumnHeader title='Preview' params={params} />,
      renderCell: (params: any) => (
        <PreviewCell
          data={params?.row?.documentUrl}
          onClick={() => {
            setSelectedClassifiedDocument(params?.row);
            setIsDocumentPreviewModalOpen(true);
          }}
        />
      ),
      valueGetter: (params: any) => params?.row,
      flex: 1,
      minWidth: 150
    },
    {
      field: 'documentName',
      renderHeader: (params: any) => {
        return <GridColumnHeader title={'Document Name'} params={params} />;
      },
      renderCell: (params: any) => <DefaultCell data={params?.value} className='text-truncate' />,
      valueGetter: (params: any) => params?.row?.fileName || 'Document',
      flex: 5,
      minWidth: 150
    },
    {
      field: 'classifiedAs',
      renderHeader: (params: any) => <GridColumnHeader title='Document type' params={params} />,
      renderCell: (params: any) => (
        <ActionWithIcon
          icon={<ArrowDownSVG down className='ms-3' width='18' height='18' fill='#616876' />}
          data={
            (params?.row?.documentType &&
              params?.row?.uploaded === true &&
              formatWordWithUnderscore(params?.row?.documentType)) ||
            'Click to classify'
          }
          handlePreview={() => {
            setSelectedClassifiedDocument(params?.row);
            setIsReviewModalOpen(true);
          }}
        />
      ),
      flex: 1,
      minWidth: 220
    },
    {
      field: 'uploadStatus',
      renderHeader: (params: any) => <GridColumnHeader title='Status' params={params} />,
      renderCell: (params: any) => (
        <StatusCell
          status={params?.value}
          type={params?.row?.uploaded === true && params?.row?.documentType ? 'success' : 'danger'}
        />
      ),
      valueGetter: (params: any) =>
        params?.row?.uploaded === true && params?.row?.documentType
          ? 'Classified'
          : 'Not classified',
      flex: 1,
      minWidth: 150
    },
    {
      field: 'actions',
      renderHeader: (params: any) => <GridColumnHeader title='Actions' params={params} />,
      renderCell: (params: any) =>
        params?.row?.documentUrl ? (
          <ActionsCell
            isDeleteDisabled={params?.row?.additionalDocument === true}
            handlePreview={() => {
              setSelectedClassifiedDocument(params?.row);
              setIsDocumentPreviewModalOpen(true);
            }}
            handleDelete={() => handleDelete(params?.row)}
            handleDownload={() => handleDownload(params?.row?.documentUrl)}
          />
        ) : (
          <DefaultCell data={'No action yet'} className='text-gray-400' />
        ),
      valueGetter: (params: any) => params?.row,
      minWidth: 150,
      flex: 1
    }
  ];

  const tableColumnsList: any = tableColumns(allUploadedDocuments) || [];

  const handleFileSubmit = async (files: File[]) => {
    setClassifiedDocuments(null);

    if (files && files.length > 0) {
      setIsReviewModalOpen(true);

      // Helper function to process a single file
      const processFile = async (currentFile: File) => {
        const blobs: any = []; // Array to store generated blobs

        const extractedFiles = await extractNonZipFilesFromZip(currentFile);

        for (const { blob, name } of extractedFiles) {
          // Trigger the API call for the current blob
          fetchDocumentsFromClassifier({ fileName: name, blob }, memberData?.id);
          blobs.push({ fileName: name, blob });
        }

        return blobs;
      };

      // Process all files in parallel
      const allBlobs = await Promise.all(files.map(processFile));
      const flattenedBlobs = allBlobs.flat();

      setUploadedDocuments(flattenedBlobs);
    }
  };

  const handleFileUpload = (doc: IDocument) => (file: any) => {
    const inputFile = file?.target?.files[0];
    const extension = inputFile.name.slice(inputFile.name.lastIndexOf('.') + 1);
    if (extension.toLowerCase() === 'zip') {
      showToast('ZIP files are not allowed for manual uploads!', 'warning');
      return;
    } else if (extension.toLowerCase() === 'pdf' || extension.toLowerCase() === 'docx') {
      const draftDocFieldName =
        inputFile?.fieldName?.replaceAll(/\s/g, '') ?? inputFile?.name?.replaceAll(/\s/g, '');

      const fileName = `${draftDocFieldName}-${moment(new Date()).format('dd-mm-yyyy')}`;

      const documentToBeUploaded = memberData?.documents?.find(
        (document: any) => document?.id === doc?.id
      );
      fileUploadHandler(documentToBeUploaded, inputFile, fileName, memberData?.id, extension);
    } else {
      setImgFile(inputFile);
      setIsEditorOpen(true);
      setCurrentDoc(doc);
    }
  };

  const saveFileFromEditor = (editedFile: any) => {
    if (currentDoc) {
      const fileExtension = getFileExtensionFromMimeType(editedFile?.type);
      const draftDocFieldName = currentDoc?.fieldName?.replaceAll(/\s/g, '');
      const fileName = `${draftDocFieldName}-${moment(new Date()).format('dd-mm-yyyy')}`;
      const documentToBeUploaded = memberData?.documents?.find(
        (document: IDocument) => document?.id === currentDoc?.id
      );
      fileUploadHandler(documentToBeUploaded, editedFile, fileName, memberData?.id, fileExtension);
    }
  };

  const onHideEditor = () => {
    setIsEditorOpen(false);
    setImgFile(null);
  };

  const fileUploadHandler = async (
    doc: any,
    file: File,
    fileName: string,
    memberId: string,
    extension: string
  ) => {
    const orderId = orderData?.id;
    const result = await uploadFile(file, `order/visa/${orderId}/${memberId}/`, fileName);
    if (result?.uploadedUrl) {
      setIsEditorOpen(false);
      uploadDocument(
        orderData?.id,
        memberData?.id,
        doc,
        result?.uploadedUrl,
        fileName,
        extension,
        res => {
          setImgFile(null);
          setCurrentDoc(null);
          getOrderById('visa', orderData?.id);
          getOrderActions(orderData?.id);
        }
      );
    } else {
      if (result?.error) showToast(result?.error, 'error');
    }
  };

  const handleDelete = (document: any) => {
    const isRequiredDoc = requiredAndAdditionalDocuments?.some(
      (obj: IDocument) => obj?.id === document?.id
    );

    if (isRequiredDoc) {
      deleteDocument(orderData?.id, memberData?.id, document?.id, res => {
        getOrderById('visa', orderData?.id);
      });
    } else {
      updateDocumentClassifierAction(memberData?.id, document, document?.indexOf, false, res => {
        getOrderById('visa', orderData?.id);
      });
    }
  };

  return (
    <section>
      {params?.orderType === ORDER_TYPE.VISA ? (
        <React.Fragment>
          <div className='px-7 mt-3'>
            <OADropZone
              placeHolder={
                <div className='d-flex align-items-center'>
                  <div>
                    <KTSVG
                      path='/media/misc/documents.svg'
                      className='me-5'
                      svgClassName='h-75 w-75'
                    />
                  </div>
                  <div>
                    <OATypography variant='h4' className='mt-4'>
                      Document classifier
                    </OATypography>
                    <OATypography variant='body2'>
                      Classify and identify documents automatically (files under 10MB only)
                    </OATypography>
                    <OAButton
                      label='Upload documents'
                      className='mt-2 bg-white border'
                      color='light-secondary'
                    />
                  </div>
                </div>
              }
              fileTypes={['png', 'jpeg', 'jpg', 'pdf', 'zip', 'docx']}
              maxSize={15 * 1024 * 1024}
              onFileDragDrop={handleFileSubmit}
              maxFiles={100}
              isModalOpen={isReviewModalOpen}
              showDescription={false}
            />
          </div>
          <DocumentsReviewModal
            orderData={orderData}
            isOpen={isReviewModalOpen}
            toggleModal={setIsReviewModalOpen}
            memberData={memberData}
            data={classifiedDocuments || uploadedDocuments}
            setData={setUploadedDocuments}
            title={`Review documents for ${fullName}`}
            requiredDocuments={requiredAndAdditionalDocuments}
            selectedClassifiedDocument={selectedClassifiedDocument}
            setSelectedClassifiedDocument={setSelectedClassifiedDocument}
          />
          <div className='card-body'>
            <div>
              {requiredDocuments?.filter((doc: IDocument) => !doc.uploaded)?.length > 0 && (
                <OATypography variant='h4' className='mb-6'>
                  Required documents
                </OATypography>
              )}
              <Row className='mt-4'>
                {requiredDocuments
                  ?.filter((doc: IDocument) => !doc.uploaded)
                  .map((doc: IDocument, idx: number) => (
                    <Col lg={4} className='d-flex align-items-center mb-8' key={idx}>
                      <label
                        htmlFor={`input-${idx}-${memberData?.id}`}
                        className='document-card--upload-label'>
                        <span className='bg-gray-100 rounded p-4'>
                          <KTSVG
                            path='/media/icons/duotune/Plus.svg'
                            className='svg-icon svg-icon-2 svg-icon-gray-500'
                          />
                        </span>
                      </label>
                      <input
                        id={`input-${idx}-${memberData?.id}`}
                        className='document-card--upload-input'
                        type='file'
                        onChange={handleFileUpload(doc)}
                      />
                      <div className='d-flex align-items-center ms-3 overflow-hidden'>
                        <OverlayTrigger
                          placement='top'
                          delay={{ show: 250, hide: 400 }}
                          overlay={(overlayProps: any) => (
                            <Tooltip id='button-tootip' {...overlayProps}>
                              {doc?.documentName || doc?.fieldName}
                            </Tooltip>
                          )}>
                          <span className='text-truncate'>
                            {doc?.documentName || doc?.fieldName}
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement='top'
                          delay={{ show: 250, hide: 400 }}
                          overlay={(overlayProps: any) => (
                            <Tooltip id='button-tooltip' {...overlayProps}>
                              {doc?.description}
                            </Tooltip>
                          )}>
                          <span className='btn btn-link p-0 ms-2'>(?)</span>
                        </OverlayTrigger>
                      </div>
                    </Col>
                  ))}
                <ImageEditorModal
                  isOpen={isEditorOpen}
                  setIsOpen={setIsEditorOpen}
                  onHide={onHideEditor}
                  imgFile={imgFile}
                  fileUploadHandler={saveFileFromEditor}
                  setIsImgUploading={setIsImageUploading}
                />
                <DocumentPreview
                  isOpen={isDocumentPreviewModalOpen}
                  onHide={() => setIsDocumentPreviewModalOpen(false)}
                  document={selectedClassifiedDocument}
                  imgUrl={selectedClassifiedDocument?.documentUrl}
                />
              </Row>
            </div>
            {allUploadedDocuments?.filter((doc: IDocument) => doc.documentUrl)?.length > 0 && (
              <>
                <div className='d-flex justify-content-between align-items-start flex-wrap mt-3'>
                  <div className='d-flex flex-column'>
                    <OATypography variant='h4'>Uploaded documents</OATypography>
                  </div>
                </div>
                <OATableComponent
                  columns={tableColumnsList}
                  data={allUploadedDocuments?.filter((doc: IDocument) => doc?.documentUrl) ?? []}
                  getRowId={row => row?.id}
                  loading={false}
                  disableColumnMenu={false}
                  onCellClick={() => {}}
                />
              </>
            )}
          </div>
        </React.Fragment>
      ) : null}
    </section>
  );
};

export default TravellerDocuments;
