import classNames from 'classnames';

interface ILinkCell {
  link: string;
  isEllipsis?: boolean;
  textCapitalize?: boolean;
  className?: string;
  fileName?: string;
}

const LinkCell = (props: ILinkCell) => {
  const { link, isEllipsis = false, textCapitalize = false, className = '', fileName = '' } = props;

  const draftClassNames = classNames(className, 'fs-6', {
    'text-capitalize': textCapitalize,
    'oa-table-cell-ellipsis': isEllipsis
  });

  const urlParts = link?.split('/');
  const linkName = urlParts?.length ? urlParts[urlParts?.length - 1] : null;

  return (
    <a href={link} target='_blank' rel='noreferrer' className={draftClassNames}>
      <p className='d-flex align-items-center text-decoration-underline'>
        {fileName == '' ? linkName : fileName}
      </p>
    </a>
  );
};

export default LinkCell;
