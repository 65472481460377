import { useEffect, useState } from 'react';

import { IPageInfo } from '../../../components/OATableComponent';
import UserContext, { IUserContext } from './UserContext';
import { showToast } from '../../../helper';
import { IUserFormValues } from '../types';
import API from '../../../helper/API';

interface IUserProvider {
  children: any;
}

interface UserOption {
  label: string;
  value: number;
}

export default function UserProvider(props: IUserProvider) {
  const [users, setUsers] = useState<Array<any>>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>({});
  const [usersList, setUsersList] = useState<UserOption[]>([]);
  const [hasMoreUsers, setHasMoreUsers] = useState<boolean>(true);
  const [isMoreUsersLoading, setIsMoreUsersLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    totalCount: 0,
    totalPages: 0,
    count: 0,
    size: 10,
    index: 1
  });

  const fetchUsers = async (search?: any, loadMore?: boolean) => {
    setIsLoading(true);

    try {
      const page = loadMore ? pageInfo?.index : pageInfo?.index - 1;
      let queryString = `?page=${page}&size=${pageInfo?.size}`;

      if (search && search !== '') {
        queryString += `&search=${search}`;
      }

      await API.get(`/users${queryString}`)
        .then(response => {
          const result = response?.data?.data;

          setPageInfo(prev =>
            Object.assign({}, prev, {
              totalCount: result?.totalElements,
              totalPages: result?.totalPages,
              count: result?.numberOfElements
            })
          );

          if (loadMore) {
            if (result?.content?.length > 0) {
              const fetchMoreUsers = result?.content?.map((user: any) =>
                Object.assign({ label: `${user.firstName} ${user.lastName}`, value: user.id })
              );
              setUsersList((prevOptions: UserOption[]) => [...prevOptions, ...fetchMoreUsers]);
            } else {
              setHasMoreUsers(false);
            }
            setIsMoreUsersLoading(false);
          } else {
            setUsers(result?.content);
          }
          setIsLoading(false);
        })
        .catch(err => {
          console.error(err);
          if (loadMore) {
            setIsMoreUsersLoading(false);
            setIsLoading(false);
          }
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };
  /**
   * NOTE: fetch data whenever 'pageInfo?.index' changes happen ...
   */
  useEffect(() => {
    fetchUsers();
  }, [pageInfo?.index]);

  const onPageChange = (value: any) => {
    setPageInfo(prev =>
      Object.assign({}, prev, {
        index: value
      })
    );
  };

  const toggleModal = () => setIsOpen(prev => !prev);

  const onClickCreateUser = () => {
    setSelectedUser({});
    toggleModal();
  };

  const onCreateUser = async (values: IUserFormValues, callback?: (success: boolean) => void) => {
    try {
      await API.post(`/users`, values)
        .then(_response => {
          /**
           * NOTE: if page index is initial index then no need to set page index
           * again, instead call fetchUsers method else change page index to initial
           * index so that useEffect detects 'pageInfo?.index' change and refetch
           * user data...
           */
          pageInfo?.index === 1 ? fetchUsers() : onPageChange(1);
          showToast('User added successfully.', 'success');
          /**
           * NOTE: call callback method by adding a null check as it is not a
           * required parameter ...
           */
          callback?.(true);
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
          callback?.(false);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const onSelectEditUser = (userId: string) => {
    const draftUser = users?.find(user => user?.id === userId);
    setSelectedUser(draftUser);
    toggleModal();
  };

  const onEditUser = async (
    userId: string,
    values: IUserFormValues,
    callback?: (success: boolean) => void
  ) => {
    try {
      await API.put(`/users/${userId}`, values)
        .then(_response => {
          /**
           * NOTE: Edit user will happen on the same page so here no need
           * to change page index. Only refetching user data will work
           * for this case ...
           */
          fetchUsers();
          showToast('User updated successfully.', 'success');
          /**
           * NOTE: call callback method by adding a null check as it is
           * not a required parameter ...
           */
          callback?.(true);
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
          callback?.(false);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const onDeleteUser = async (userId: string, callback?: () => void) => {
    try {
      await API.delete(`/users/${userId}`)
        .then(_response => {
          /**
           * NOTE: Edit user will happen on the same page so here no need
           * to change page index. Only refetching user data will work
           * for this case ...
           */
          fetchUsers();
          showToast('User deleted successfully.', 'success');
          /**
           * NOTE: call callback method by adding a null check as it is
           * not a required parameter ...
           */
          callback?.();
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  // Only superadmin can access it to trigger the password email for the users
  const onPasswordReset = async (requestbody: any, callback?: (response: any) => void) => {
    try {
      await API.post(`password/superadmin/reset`, requestbody)
        .then(response => {
          const result = response?.data?.data;
          showToast('Please check you email for password', 'success');
          callback?.(result);
        })
        .catch(err => {
          showToast(err?.response?.data, 'error');
          callback?.(err);
        });
    } catch (error: any) {
      callback?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const contextValue: IUserContext = {
    users,
    isOpen,
    selectedUser,
    usersList,
    hasMoreUsers,
    isMoreUsersLoading,
    pageInfo,
    isLoading,
    fetchUsers,
    onPageChange,
    toggleModal,
    onClickCreateUser,
    onCreateUser,
    onSelectEditUser,
    onEditUser,
    onDeleteUser,
    setIsMoreUsersLoading,
    setHasMoreUsers,
    setUsersList,
    onPasswordReset
  };

  return <UserContext.Provider value={contextValue}>{props?.children}</UserContext.Provider>;
}
