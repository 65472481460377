import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { isBoolean, isUndefined } from 'lodash';
import Big from 'big.js';

import { getInvoiceRequestBody } from '../../../helper/getInvoiceRequestBody';
import { useOrderContext } from '../../../context/OrderContext';
import AddonServices from '../../../shared/AddonServices';
import { IOrderType } from '../../../types';

interface IAddonServicesModal {
  isOpen: boolean;
  onHide: () => void;
}

export default function AddonServicesModal(props: IAddonServicesModal) {
  const params = useParams<{ orderType: IOrderType; id: string }>();

  const { formState, orderData, updateProforma } = useOrderContext();

  const [addonServices, setAddonServices] = useState<Array<any>>([]);

  const { productAmount } = orderData?.data?.product?.productInformation;

  useEffect(() => {
    if (productAmount?.additionalAmountModifiers?.length) {
      const draftAddonModifiers = productAmount?.additionalAmountModifiers
        ?.filter((obj: any) => obj?.type === 'ADD_ON_SERVICE_FEE')
        .map((obj: any) => Object.assign({}, obj, { amount: Big(obj?.amount).toFixed(2) }));
      setAddonServices(draftAddonModifiers);
    }
  }, [orderData]);

  const handleAddOrRemoveAddonService = (event: any) => {
    const selectedAddonService = addonServices?.find(
      (obj: any) => obj?.name === event?.target.value
    );

    const { orderAmount } = orderData?.data;

    const draftAddonFromOrderAmount = orderAmount?.additionalAmountModifiers?.find(
      (obj: any) => obj?.type === 'ADD_ON_SERVICE_FEE' && obj?.name === selectedAddonService?.name
    );

    let draftAddonService = Object.assign({}, selectedAddonService, {
      quantity: orderAmount?.memberCount,
      amount: event?.target?.checked
        ? Big(selectedAddonService?.amount).mul(orderAmount?.memberCount).toFixed(2)
        : Big(draftAddonFromOrderAmount?.amount).toFixed(2),
      amountCollected: event?.target?.checked ? true : draftAddonFromOrderAmount?.amountCollected
    });
    let draftRB = orderAmount?.additionalAmountModifiers?.map((obj: any) =>
      isUndefined(obj?.quantity)
        ? Object.assign({}, obj, { quantity: orderAmount?.memberCount })
        : obj
    );
    let draftTotal = Big(orderAmount?.totalAmount);
    let draftSubtotal = Big(orderAmount?.subtotalAmount);
    /**
     * NOTE: for old orders, 'PRODUCT_FEE' modifier is not present in...
     */
    if (!draftRB?.find((obj: any) => obj?.type === 'PRODUCT_FEE')) {
      const { product } = orderData?.data;

      draftRB = [
        ...draftRB,
        {
          type: 'PRODUCT_FEE',
          name: product?.displayName,
          quantity: orderAmount?.memberCount,
          currency: orderAmount?.currency,
          amount: Big(product?.productInformation?.productAmount?.totalAmount)
            .mul(orderAmount?.memberCount)
            .toFixed(2),
          amountCollected: orderAmount?.productAmountToBeCollected
        }
      ];
    }

    if (isBoolean(event?.target?.checked) && selectedAddonService) {
      if (event?.target?.checked) {
        draftRB = [...draftRB, draftAddonService];
        /**
         * NOTE: update 'draftTotal' and 'draftSubtotal' both as adding new addOn
         * service going to change these totals...
         */
        draftTotal = draftTotal.add(Big(draftAddonService?.amount));
        draftSubtotal = draftSubtotal.add(Big(draftAddonService?.amount));
      } else {
        draftRB = draftRB?.filter((obj: any) => obj?.name !== draftAddonService?.name);
        /**
         * NOTE: If 'amountCollected' of 'draftAddonService' is true then only modify
         * 'draftTotal' and 'draftSubtotal'. If 'amountCollected' is false then that
         * amount is not considered in 'draftTotal' and 'draftSubtotal'...
         */
        if (draftAddonService?.amountCollected) {
          draftTotal = draftTotal.sub(Big(draftAddonService?.amount));
          draftSubtotal = draftSubtotal.sub(Big(draftAddonService?.amount));
        }
      }
    }

    const requestBody = getInvoiceRequestBody(
      {
        data: draftRB,
        orderId: params?.id!,
        totalAmount: draftTotal.toFixed(2),
        subtotalAmount: draftSubtotal.toFixed(2)
      },
      'proforma'
    );
    updateProforma(requestBody);
  };

  return (
    <Modal
      className='markup-form-modal'
      show={props?.isOpen}
      onHide={props?.onHide}
      centered
      size='lg'>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Modify Addon Services</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddonServices
          data={addonServices}
          values={formState?.addOnServiceModifier}
          handleChange={handleAddOrRemoveAddonService}
          type='edit'
        />
      </Modal.Body>
    </Modal>
  );
}
