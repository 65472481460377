import { Outlet, Route, Routes } from 'react-router-dom';
import { toAbsoluteUrl } from '../helper';
import { ForgotPassword } from './components/ForgotPassword';
import { Login } from './components/Login';
import { ResetPassword } from './components/ResetPassword';

const AuthLayout = () => {
  const rootClassNames =
    'd-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed bg-white';
  const rootStyles = {
    backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
    height: '100vh'
  };

  let logoUrl = '/media/logos/logo-1.svg';
  if (window.location.host === 'world.evisas.io') {
    logoUrl = '/media/logos/logo-alsaffron.svg';
  }
  return (
    <div className={rootClassNames} style={rootStyles}>
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <a href='#' className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl(logoUrl)} className='h-45px' />
        </a>
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset-password/:emailId/:newPasswordRequired' element={<ResetPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export { AuthPage };
