import { useEffect, useState } from 'react';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { isUndefined } from 'lodash';
import moment from 'moment';

import OADatePickerField from '../../../../../components/fields/OADatePickerField';
import OASelectField from '../../../../../components/fields/OASelectField';
import { useS3FileUploader } from '../../../../../hooks/useS3FileOperation';
import OATextField from '../../../../../components/fields/OATextField';
import OATimePicker from '../../../../../components/OATimePicker';
import OAButton from '../../../../../components/widgets/OAButton';
import { useOrderContext } from '../../../context/OrderContext';
import OADropZone from '../../../../../components/OADropZone';
import { KTSVG } from '../../../../../_metronic/helpers';
import { IOrderType } from '../../../types';
import OATextAreaField from '../../../../../components/fields/OATextAreaField';
import {
  OA_DATE_FORMAT_BE,
  OA_TIME_FORMAT,
  ORDER_EVENT_TYPE_OPTIONS
} from '../../../../../constants';
import { getFormattedDate, getMomentObject } from '../../../../../helper';
import OAMultiSelectField from '../../../../../components/fields/OAMultiSelectField';

interface IEventModalProps {
  id: number;
  show: boolean;
  onHide: () => void;
}

interface IEventValues {
  eventName: string;
  eventOptions: any;
  eventSchedule: string;
  eventTime: string;
  remarks: string;
  attachments: Array<string>;
  linkedMemberIds: Array<string>;
}

const initEventValues: IEventValues = {
  eventName: '',
  eventOptions: 'EMBASSY_APPOINTMENT',
  eventSchedule: '',
  eventTime: '',
  remarks: '',
  attachments: [],
  linkedMemberIds: []
};

const EventModal = (props: IEventModalProps) => {
  const params = useParams<{ orderType: IOrderType; id?: any }>();

  const { orderData, orderEvents, createOrderEvent, getOrderEventById, editOrderEvent } =
    useOrderContext();
  const [uploadFile] = useS3FileUploader(5, ['jpg', 'png', 'jpeg', 'pdf']);

  const [files, setFiles] = useState<any>();
  const [existingEventValues, setExistingEventValues] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const selectedEvent = orderEvents?.data?.data?.find((obj: any) => obj?.id === props?.id);

    if (props?.id && !isUndefined(selectedEvent)) {
      setExistingEventValues({
        id: selectedEvent?.id,
        eventName: selectedEvent?.title,
        eventOptions: selectedEvent?.orderEventType,
        linkedMemberIds: selectedEvent?.linkedMemberIds,
        eventSchedule: moment(new Date(selectedEvent?.scheduledOn?.datetime)),
        eventTime: moment.utc(selectedEvent?.scheduledOn?.datetime),
        remarks: selectedEvent?.content,
        attachments: selectedEvent?.attachments
      });
    } else {
      setExistingEventValues(null);
    }
  }, [props?.id]);

  const handleFileUpload = async (values: any) => {
    setIsLoading(true);
    const uploadedUrls = [];
    for (const file of values) {
      const result = await uploadFile(file);
      uploadedUrls.push(result?.uploadedUrl);
    }
    setIsLoading(false);
    setFiles(uploadedUrls);
  };

  const handleEventSubmit = (values: IEventValues, actions: FormikHelpers<any>) => {
    const dateMoment: any = getMomentObject(values?.eventSchedule, OA_DATE_FORMAT_BE);
    const timeMoment = getMomentObject(values?.eventTime, OA_TIME_FORMAT);
    dateMoment.set({
      hour: timeMoment.hour(),
      minute: timeMoment.minute(),
      second: timeMoment.second()
    });

    const payload = Object.assign({
      id: existingEventValues?.id,
      attachments:
        files?.length > 0 ? files : values?.attachments?.length > 0 ? values?.attachments : [],
      orderId: params?.id,
      orderEventType: values?.eventOptions,
      title: values?.eventName !== '' ? values?.eventName : null,
      content: values?.remarks,
      scheduledOn: {
        datetime: getFormattedDate(dateMoment, 'YYYY-MM-DDTHH:mm:ss.SSS'),
        timezone: 'Asia/Kolkata'
      },
      linkedMemberIds: values?.linkedMemberIds
    });

    if (existingEventValues) {
      editOrderEvent(payload, (_res: any) => {
        props?.onHide();
        actions.resetForm();
        if (_res?.status === 200) {
          getOrderEventById(params?.id);
        }
        setExistingEventValues(null);
      });
      setFiles(null);
    } else {
      createOrderEvent(payload, (_res: any) => {
        props?.onHide();
        actions.resetForm();
        if (_res?.status === 200) {
          getOrderEventById(params?.id);
        }
      });
      setFiles(null);
    }
  };

  const validate = (values: any) => {
    const errors: any = {};
    if (values?.eventSchedule === '') {
      errors.eventSchedule = 'Please select a date';
    }
    if (
      values?.eventOptions?.value === 'OTHERS' ||
      existingEventValues?.eventOptions === 'OTHERS'
    ) {
      if (!values?.eventName?.length) {
        errors.eventName = 'Please add an event name';
      } else if (values?.eventName?.length > 100) {
        errors.eventName = 'Event name is too long';
      }
    }
    if (!values?.linkedMemberIds?.length) {
      errors.linkedMemberIds = 'Please select atleast one traveller';
    }
    if (values?.eventTime === '') {
      errors.eventTime = 'Please select a time';
    }
    return errors;
  };

  const memberOptions = orderData?.data?.members?.map((obj: any) => ({
    label: `${obj?.title} ${obj?.firstName} ${obj?.lastName}`,
    value: obj?.id
  }));

  return (
    <Modal show={props?.show} onHide={props?.onHide} backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          {existingEventValues ? 'Update this event' : 'Create an event'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={existingEventValues ? existingEventValues : initEventValues}
          validate={validate}
          onSubmit={handleEventSubmit}
          enableReinitialize>
          {(formikProps: FormikProps<any>) => {
            const { values } = formikProps;

            const isSameDay = moment(
              moment(values?.eventSchedule).format(OA_DATE_FORMAT_BE)
            ).isSame(moment().format(OA_DATE_FORMAT_BE));

            return (
              <Container fluid as={Form} className='p-0'>
                <Row>
                  <Col sm={12} className='mb-5'>
                    <Field
                      as={OASelectField}
                      name='eventOptions'
                      label='Event title'
                      options={ORDER_EVENT_TYPE_OPTIONS}
                      valueOnly
                      required
                    />
                  </Col>
                  {values?.eventOptions === 'OTHERS' ||
                  existingEventValues?.eventOptions === 'OTHERS' ? (
                    <Col sm={12} className='mb-5'>
                      <OATextField name='eventName' label='Event name' required />
                    </Col>
                  ) : null}
                  <Col sm={12} className='mb-4'>
                    <OAMultiSelectField
                      name='linkedMemberIds'
                      label='Traveller'
                      options={memberOptions}
                      required
                    />
                  </Col>
                  <Col sm={6} className='mb-5'>
                    <OADatePickerField
                      name='eventSchedule'
                      label='Event date'
                      minDate={new Date()}
                      required
                    />
                  </Col>
                  <Col sm={6} className='mb-5'>
                    <OATimePicker
                      name='eventTime'
                      label='Event time'
                      minTime={isSameDay ? moment().add(30, 'minutes').toDate() : undefined}
                      required
                    />
                  </Col>
                  <Col sm={12} className='mb-5'>
                    <OATextAreaField name='remarks' label='Remarks' />
                  </Col>
                  <Col sm={12} className='mb-5'>
                    <label htmlFor='attachments' className='form-label'>
                      Attachments
                    </label>
                    <OADropZone
                      fileTypes={['png', 'jpeg', 'jpg', 'pdf']}
                      maxFiles={3}
                      height={86}
                      placeHolder='Drag and drop or Choose file to upload (max 5MB only)'
                      onFileDragDrop={handleFileUpload}
                    />
                  </Col>
                  <Col sm={12} className='mb-5'>
                    {(() => {
                      const { attachments } = values;
                      const draftDocs = attachments?.map((doc: any) => {
                        const docName = doc?.split('.amazonaws.com/')?.[1];
                        const docType = docName?.split('.')?.[1];
                        return { name: docName, type: docType, link: doc };
                      });
                      return draftDocs?.map((doc: any, index: number) => {
                        return (
                          <li key={index} className='d-flex align-items-center py-2'>
                            <span className='bullet bg-success h-5px w-10px me-5' />
                            <span className='text-success'>{doc?.name}</span>
                            <span
                              onClick={() =>
                                formikProps?.setFieldValue(
                                  'attachments',
                                  attachments?.filter((obj: any) => obj !== doc?.link)
                                )
                              }>
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr015.svg'
                                className='svg-icon svg-icon-2 svg-icon-gray-500 ms-2 cursor-pointer'
                              />
                            </span>
                          </li>
                        );
                      });
                    })()}
                  </Col>
                  <Col className='text-end'>
                    <OAButton
                      label='Cancel'
                      color='light-primary'
                      className='me-4'
                      onClick={() => props?.onHide()}
                    />
                    <OAButton type='submit' color='primary' label='Save' disabled={isLoading} />
                  </Col>
                </Row>
              </Container>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default EventModal;
