import classNames from 'classnames';

export interface IOALabelValue {
  value: string | JSX.Element;
  show?: boolean;
  label?: string | JSX.Element;
  className?: string;
  postValueComponent?: JSX.Element | null;
  labelClassName?: string;
  valueClassName?: string;
}

const OALabelValue = (props: IOALabelValue) => {
  const {
    value,
    label,
    postValueComponent,
    className,
    show = true,
    valueClassName = '',
    labelClassName = ''
  } = props;

  const labelClassNames = classNames('fw-bolder text-muted fs-9 text-uppercase', labelClassName);

  const valueClassNames = classNames(
    'text-dark fs-7 text-capitalize overflow-auto',
    valueClassName
  );

  return show ? (
    <div className={className}>
      {label ? <div className={labelClassNames}>{label}</div> : null}
      <div className='d-flex'>
        <span className={valueClassNames}>{value}</span>
        {postValueComponent ?? null}
      </div>
    </div>
  ) : null;
};

export default OALabelValue;
