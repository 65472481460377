import { useMemo, useEffect } from 'react';
import { Card } from 'react-bootstrap';

import { useOrgContext } from '../context/OrgContext';
import { getOrgColumns } from './OrgColumns';
import OrgSearch from './OrgSearch';
import OATableComponent from '../../../components/OATableComponent';
import { showDialog } from '../../../helper';

interface IOrgList {
  toggle: () => void;
}

export default function OrgList(props: IOrgList) {
  const { organizations, orgQuery, setOrgQuery, fetchOrgs, onSelectOrg, onDeleteOrg } =
    useOrgContext();
  /**
   * DEVNOTE: if organizations data not available then call fetchOrgs method on
   * component mount.
   */
  useEffect(() => {
    if (!organizations?.data) {
      fetchOrgs?.();
    }
  }, []);
  /**
   * NOTE: fetch org data if query changes ...
   */
  // TODO: move this to OrgContext
  useEffect(() => {
    fetchOrgs(orgQuery?.search);
  }, [orgQuery]);

  const onClickSearchHandler = (value: any) => setOrgQuery(prev => ({ ...prev, search: value }));
  /**
   *
   * @param orgId
   */
  const handleEdit = (orgId: string) => {
    onSelectOrg(orgId, () => {
      props?.toggle?.();
    });
  };
  /**
   *
   * @param orgId
   */
  const handleDelete = (orgId: string) => {
    showDialog({
      title: 'Delete Organization?',
      text: 'Are you sure you want to delete this organization?',
      icon: 'warning'
    }).then(result => {
      if (result?.isConfirmed) {
        onDeleteOrg(orgId);
      }
    });
  };

  const columns = useMemo(
    () => getOrgColumns({ handleEdit, handleDelete }),
    [handleEdit, handleDelete]
  );

  return (
    <Card>
      <Card.Body className='p-0 ps-2'>
        <OrgSearch onClickSearchHandler={onClickSearchHandler} />
        <OATableComponent
          columns={columns}
          data={organizations?.data ?? []}
          disableColumnMenu={false}
        />
      </Card.Body>
    </Card>
  );
}
