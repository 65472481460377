import { Box } from '@mui/material';
import { CancelOptions } from './CancelOptions';
import { CancelSummary } from './CancelSummary';
import OAModalComponent from '../../../components/OAModalComponent';
import { OATypography } from '../../../components/widgets';
import { useContext, useEffect, useState } from 'react';
import FlightContext from '../context/FlightContext';

interface ICancelFlightBooking {
  data: any;
  isCancelModalOpen: boolean;
  setIsCancelModalOpen: any;
}

export const CancelFlightBooking = (props: ICancelFlightBooking) => {
  const { getCancelReasons, cancelFlightsBooking } = useContext(FlightContext);
  const [isCancelSummary, setIsCancelSummary] = useState<boolean>(false);
  const [cancelReasons, setCancelReasons] = useState<any>();
  const [refundInfo, setRefundInfo] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const [value, setValue] = useState<string | null>(null);
  const [remark, setRemark] = useState<string>('');

  useEffect(() => {
    setIsLoading(true);
    if (
      props?.data &&
      props?.data?.id &&
      ['BOOKING_CONFIRMED', 'CANCELLATION_REQUESTED'].includes(props?.data?.bookingStatus)
    ) {
      getCancelReasons(props?.data?.id, res => {
        setIsLoading(false);
        const updatedData = res?.reasons?.map((item: any, index: number) => ({
          ...item,
          id: index + 1 // Adding an 'id' property that starts at 1 and increments for each item
        }));
        setCancelReasons(updatedData);
        setRefundInfo(res?.refund);
      });
    }
  }, [props?.data]);

  const toggleTransactionModal = (buttonText?: string) => {
    props?.setIsCancelModalOpen((prev: any) => !prev);
    if (buttonText === 'Continue') {
      setIsCancelSummary(true);
    }
  };

  const toggleSummaryModal = (buttonText?: string) => {
    setIsCancelSummary(prev => !prev);
    if (buttonText === 'Back') {
      props?.setIsCancelModalOpen(true);
    }
  };

  const handleSubmit = () => {
    setSubmitLoader(true);
    let selectedReason = cancelReasons?.find((obj: any) => obj?.id === value);
    let draft = {
      reason: selectedReason?.display,
      code: selectedReason?.value,
      ...(remark ? { remarks: remark } : {})
    };
    cancelFlightsBooking(props?.data?.id, draft, (res: any) => {
      setSubmitLoader(false);
      toggleSummaryModal();
      setValue(null);
      setRemark('');
    });
  };

  return (
    <Box>
      <OAModalComponent
        size='lg' // NOTE: set modal size
        keyboard={false} // NOTE: disable keyboard event
        backdrop='static' // NOTE: disable backdrop click
        centered // NOTE: vertically center modal
        show={props?.isCancelModalOpen}
        onHide={toggleTransactionModal}
        header={{
          title: (
            <>
              <Box display='flex' alignItems='center' marginLeft='2px'>
                <OATypography variant='h4'>Cancel booking</OATypography>
              </Box>
            </>
          ),
          closeButton: true // NOTE: show close button on header
        }}
        body={{
          content: (
            <CancelOptions
              onHide={toggleTransactionModal}
              loading={isLoading}
              data={cancelReasons}
              value={value}
              setValue={setValue}
              remark={remark}
              setRemark={setRemark}
            />
          )
        }}
      />
      <OAModalComponent
        size='lg' // NOTE: set modal size
        keyboard={false} // NOTE: disable keyboard event
        backdrop='static' // NOTE: disable backdrop click
        centered // NOTE: vertically center modal
        show={isCancelSummary}
        onHide={toggleSummaryModal}
        header={{
          title: (
            <>
              <Box display='flex' alignItems='center' marginLeft='2px'>
                <OATypography variant='h4'>Cancel booking</OATypography>
              </Box>
            </>
          ),
          closeButton: true // NOTE: show close button on header
        }}
        body={{
          content: (
            <CancelSummary
              onHide={toggleSummaryModal}
              cancelReasons={cancelReasons}
              refundInfo={refundInfo}
              flightData={props?.data}
              handleSubmit={handleSubmit}
              loading={submitLoader}
            />
          )
        }}
      />
    </Box>
  );
};
