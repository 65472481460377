import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { KTSVG } from '../../../../../../_metronic/helpers';

interface IBanner {
  text: string;
  type: 'warning' | 'error';
  textClassName?: string;
  action?: {
    text: string;
    handleAction: () => void;
  };
  children?: React.ReactNode;
}

export default function Banner(props: IBanner) {
  const { textClassName = 'mb-3', type = 'warning' } = props;

  const draftSvg = type === 'warning' ? 'caution.svg' : 'error.svg';
  const draftSvgPath = `/media/icons/duotune/general/${draftSvg}`;

  const draftClassNames = classNames('d-flex p-4 rounded', {
    'bg-light-warning': type === 'warning',
    'bg-light-danger': type === 'error'
  });

  return (
    <div className={draftClassNames}>
      <KTSVG className='pt-1 svg-icon-2' path={draftSvgPath} />
      <div className='ps-3'>
        <p className={textClassName}>{props?.text}</p>
        {props?.children}
        {isEmpty(props?.action) ? null : (
          <a
            className='text-primary fw-semibold cursor-pointer'
            onClick={props?.action?.handleAction}>
            {props?.action?.text}
          </a>
        )}
      </div>
    </div>
  );
}
