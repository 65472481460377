import moment from 'moment';
import { OA_DATE_FORMAT_BE } from '../constants';

/**
 * Get age in years or months from a date of birth.
 * @param dateOfBirth The date of birth as a string in YYYY-MM-DD format.
 * @returns The age in years or months as a string.
 */
export const getAgeInYearsOrMonths = (dateOfBirth: string): string => {
  // Parse the date of birth using Moment.js
  const dob = moment(dateOfBirth, OA_DATE_FORMAT_BE);
  const now = moment();

  // Calculate the difference between now and the date of birth in years
  const years = now.diff(dob, 'years');

  // If the age is less than 1 year, calculate the difference in months instead
  if (years < 1) {
    const months = now.diff(dob, 'months');
    return `${months} month${months !== 1 ? 's' : ''}`;
  } else {
    return `${years} year${years !== 1 ? 's' : ''}`;
  }
};
