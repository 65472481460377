import { Avatar, Box, Card, Divider } from '@mui/material';
import { OATypography } from '../../../components/widgets';
import { ReactComponent as UPIIcon } from '../../../assets/icons/UPI.svg';
import { ReactComponent as LineIcon } from '../../../assets/icons/Line-1.svg';
import { ReactComponent as LineIcon2 } from '../../../assets/icons/Line-2.svg';
import { ReactComponent as LineIcon3 } from '../../../assets/icons/Line-3.svg';
import { getFormattedDate } from '../../../helper';

const CardBoxStyle = {
  boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10)',
  borderRadius: '12px'
};

interface paymentStatus {
  data: any;
}

export const PaymentStatus = (props: paymentStatus) => {
  return (
    <Card sx={CardBoxStyle}>
      <Box sx={{ padding: '16px' }}>
        <OATypography variant='h4'>Payment Status</OATypography>
      </Box>
      <Divider />
      <Box sx={{ padding: '24px' }}>
        <Box display='flex' gap='8px'>
          {props?.data?.orderAmount?.paymentSummary?.paymentMode === 'NET_BANKING' ? (
            <Avatar sx={{ backgroundColor: '#f5f8fa' }}>
              <img src='/media/icons/duotune/finance/fin001.svg' height={24} width={24} />
            </Avatar>
          ) : (
            <UPIIcon />
          )}
          <Box marginBottom='33px'>
            <OATypography variant='h6'>
              Paid via
              {props?.data?.orderAmount?.paymentSummary?.paymentMode
                ? props?.data?.orderAmount?.paymentSummary?.paymentMode
                    ?.replace(/_/g, ' ')
                    ?.toLowerCase()
                : 'UPI'}
            </OATypography>
            <OATypography variant='body1' sx={{ color: '#616876', display: 'flex' }}>
              Payment ID:
              <OATypography variant='body2' sx={{ color: '#616876' }}>
                {props?.data?.orderAmount?.paymentSummary?.paymentId}
              </OATypography>
            </OATypography>
          </Box>
        </Box>
        {props?.data?.orderAmount?.paymentSummary?.logs?.map((logs: any, index: number) => {
          let condition1 = logs?.display?.toLowerCase()?.includes('failed');
          let condition2 = logs?.display?.toLowerCase()?.includes('pending');
          return (
            <Box display='flex' alignItems='center' gap='24px'>
              {index > 0 ? (
                props?.data?.orderAmount?.paymentSummary?.logs?.length - 1 !== index ? (
                  <LineIcon2
                    style={{ color: condition1 ? '#D63939' : condition2 ? '#F59F00' : '#2FB344' }}
                  />
                ) : (
                  <LineIcon3
                    style={{ color: condition1 ? '#D63939' : condition2 ? '#F59F00' : '#2FB344' }}
                  />
                )
              ) : (
                <LineIcon
                  style={{ color: condition1 ? '#D63939' : condition2 ? '#F59F00' : '#2FB344' }}
                />
              )}
              <Box>
                <OATypography variant='subtitle2'>{logs?.display}</OATypography>
                <OATypography variant='body2' sx={{ color: '#616876' }}>
                  {getFormattedDate(logs?.createdAt, 'DD MMM, YYYY, HH:mm')}
                </OATypography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Card>
  );
};
