import { useParams } from 'react-router-dom';
import { formatNumberForCurrency, getCurrencySymbol } from '../../../../helper';

interface PriceTableProps {
  data?: any;
}

const PriceTable = (props: PriceTableProps) => {
  const { data } = props;
  const params = useParams();

  const draftCurrency = getCurrencySymbol(data?.orderAmount?.currency);
  const serviceCharge = findAdditionalAmountModifiers(
    data?.orderAmount?.additionalAmountModifiers,
    'SERVICE_CHARGES'
  );
  const markupFee = findAdditionalAmountModifiers(
    data?.orderAmount?.additionalAmountModifiers,
    'MARKUP_FEE'
  );
  const subTotal = formatNumberForCurrency(data?.orderAmount?.subtotalAmount, {
    countryCode: data?.orderAmount?.currencyCountry
  });
  const grandTotal = formatNumberForCurrency(data?.orderAmount?.totalAmount, {
    countryCode: data?.orderAmount?.currencyCountry
  });

  let taxForInsurance: any = 0;
  if (
    data?.orderAmount?.tax?.totalTax &&
    (data?.ancillaryType === 'INSURANCE' || params?.orderType === 'insurance')
  ) {
    taxForInsurance = formatNumberForCurrency(data?.orderAmount?.tax?.totalTax, {
      countryCode: data?.orderAmount?.currencyCountry
    });
  }

  const insuranceLineItems = data?.orderAmount?.items?.map((obj_1: any) =>
    Object.assign({}, obj_1, {
      membersCount: data?.memberCount?.find((obj_2: any) => obj_2.type === obj_1.type)?.count,
      productName: props?.data?.product?.name
    })
  );

  return (
    <div className='dev--quotation__price-table flex-grow-1'>
      <div className='w-100 border-bottom mb-4'>
        <table className='table mb-0'>
          <thead>
            <tr className='border-bottom fs-7 fw-bold text-muted'>
              <th className='min-w-70px pb-2'>TRAVELLERS</th>
              <th className='min-w-140px'>PRODUCT</th>
              <th className='min-w-70px text-center pb-2'>UNIT</th>
              <th className='min-w-90px text-end pb-2'>AMOUNT</th>
            </tr>
          </thead>
          <tbody>
            {insuranceLineItems?.map((item: any) => (
              <tr key={item?.type} className='fw-bold text-gray-700 fs-7'>
                <td className=''>{item?.type}</td>
                <td className=''>{item?.productName}</td>
                <td className='text-center'>{item?.membersCount}</td>
                <td className='text-end'>
                  {draftCurrency}
                  {item?.tax?.totalAmountWithoutTax}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='d-flex justify-content-end'>
        <div className='mw-300px'>
          {serviceCharge && (
            <div className='d-flex flex-stack mb-3'>
              <div className='fw-bold pe-10 text-gray-400 fs-7'>Service charge</div>
              <div className='text-end fw-bold fs-7 text-gray-400'>
                {draftCurrency}
                {serviceCharge?.amount}
              </div>
            </div>
          )}
          {markupFee && (
            <div className='d-flex flex-stack mb-3'>
              <div className='fw-bold pe-10 text-gray-400 fs-7'>Total Markup</div>
              <div className='text-end fw-bold fs-7 text-gray-400'>
                {draftCurrency}
                {markupFee?.amount}
              </div>
            </div>
          )}
          <div className='d-flex flex-stack mb-3'>
            <div className='fw-bold pe-10 text-gray-400 fs-7'>Subtotal</div>
            <div className='text-end fw-bold fs-7 text-gray-400'>
              {draftCurrency}
              {subTotal}
            </div>
          </div>
          {serviceCharge && (
            <div className='d-flex flex-stack mb-3'>
              <div className='fw-bold pe-10 text-gray-400 fs-7'>Service charge GST</div>
              <div className='text-end fw-bold fs-7 text-gray-400'>
                {draftCurrency}
                {serviceCharge?.tax}
              </div>
            </div>
          )}
          {markupFee && (
            <div className='d-flex flex-stack mb-3'>
              <div className='fw-bold pe-10 text-gray-400 fs-7'>Markup GST</div>
              <div className='text-end fw-bold fs-7 text-gray-400'>
                {draftCurrency}
                {markupFee?.tax}
              </div>
            </div>
          )}
          {taxForInsurance !== 0 && (
            <div className='d-flex flex-stack mb-3'>
              <div className='fw-bold pe-10 text-gray-400 fs-7'>Tax</div>
              <div className='text-end fw-bold fs-7 text-gray-400'>
                {draftCurrency}
                {taxForInsurance}
              </div>
            </div>
          )}

          <div className='d-flex flex-stack'>
            <div className='fw-bolder pe-10 text-dark fs-7'>Grand total</div>
            <div className='text-end fw-bolder fs-7 text-dark'>
              {draftCurrency}
              {grandTotal}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceTable;

export const findAdditionalAmountModifiers = (additionalAmount: Array<object>, type: string) => {
  const findAmount: any = additionalAmount?.find((obj: any) => obj?.type === type);
  if (findAmount) {
    const amountToFixed = findAmount?.tax?.totalAmountWithoutTax?.toFixed(2);
    const taxToFixed = findAmount?.tax?.totalTax?.toFixed(2);
    return {
      amount: formatNumberForCurrency(amountToFixed),
      tax: formatNumberForCurrency(taxToFixed)
    };
  } else {
    return;
  }
};
