import { useState } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { getStatusConfig } from '../../helper';

interface IStatusCell {
  status: string;
  text?: string;
  varient?:
    | 'light'
    | 'primary'
    | 'secondary'
    | 'warning'
    | 'danger'
    | 'success'
    | 'info'
    | 'light-success';
  type?: string;
  path?: string;
}

const StatusCell = (props: IStatusCell) => {
  const navigate = useNavigate();

  const [active, setActive] = useState(false);

  const status = getStatusConfig(props?.status, props?.type);
  const statusType = status?.type || 'info';

  const handleClick = (e: any) => {
    e.stopPropagation();
    if (props?.path) {
      navigate(props?.path);
    }
  };

  const className = classNames('badge fw-bolder', {
    [`badge-light-${statusType}`]: !active,
    [`badge-${statusType}`]: active && props?.path
  });

  return (
    <div
      className={className}
      onClick={handleClick}
      onMouseOver={() => props?.path && setActive(true)}
      onMouseOut={() => props?.path && setActive(false)}>
      {status?.label}
    </div>
  );
};

export default StatusCell;
