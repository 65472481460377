/* eslint-disable no-unused-vars */
import { Button } from 'react-bootstrap';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';

import {
  DefaultCell,
  TripCell,
  CreatedOnCell,
  StatusCell,
  AmountCell,
  TravellersCell,
  AssignedUserCell,
  ProgressCell
} from '../../../components/OAColumn';
import { OA_DATE_TIME_FORMAT_FE } from '../../../constants';
import { GridColumnHeader } from '../../../components/OATableComponent';
import { OAStatusChip } from '../../../components/widgets';
import { IDataExchangeVisaStatus } from '../../../components/widgets/OAStatusChip';
import { getSupplierOrganizationName } from '../../../helper';
import { MemberDisplay } from '../../../components/OAColumn/TravellersCell';
import { ORDER_STATUS } from '../constants';

type getEVisaColumnsType = (
  currentUser: any,
  dataExchange: any,
  toggleAssignUserModal: any,
  columnModifiers?: {
    status?: string;
  }
) => GridColDef[];

export const getEVisaColumns: getEVisaColumnsType = (
  currentUser,
  dataExchange,
  toggleAssignUserModal,
  columnModifiers = {}
) => {
  let draft: Array<GridColDef> = [
    {
      field: 'visaId',
      renderHeader: params => <GridColumnHeader title='Booking Id' params={params} />,
      renderCell: params => {
        return (
          <Box>
            <DefaultCell data={params?.value?.orderId} />
            {params?.value?.groupId ? (
              <StatusCell
                status='GROUP'
                path={`/apps/orders/visa/group/${params?.value?.groupId}`}
              />
            ) : null}
          </Box>
        );
      },
      valueGetter: params => {
        return {
          orderId: params?.row?.linkedOrder?.id,
          groupId: params?.row?.groupId
        };
      },
      width: 110
      // pinnable: true,
      // disableColumnMenu: false,
      // filterable: true
    },
    {
      field: 'travellers',
      renderHeader: params => <GridColumnHeader title='Travellers' params={params} />,
      renderCell: params => {
        const isQuotation = params?.row?.linkedOrder?.bookingStatus === ORDER_STATUS.ORDER_QUOTE;

        return isQuotation ? (
          <MemberDisplay name={params?.value?.proposerInfo?.name} />
        ) : (
          <TravellersCell members={params?.value?.members} showPrimary />
        );
      },
      valueGetter: params => params?.row?.linkedOrder,
      flex: 3,
      minWidth: 275
    },
    {
      field: 'destination',
      renderHeader: params => <GridColumnHeader title='Destination' params={params} />,
      renderCell: params => <TripCell {...params?.value} />,
      valueGetter: params => params?.row?.linkedOrder?.trip,
      width: 95
    },
    {
      field: 'bookingStatus',
      renderHeader: params => <GridColumnHeader title='Status' params={params} />,
      renderCell: params => {
        const hasConsumerAccess =
          currentUser?.data?.organizationId === params?.value?.createdByUser?.organizationId;

        const statusObj: IDataExchangeVisaStatus = dataExchange['VISA_STATUS']
          ?.filter((obj: IDataExchangeVisaStatus) => {
            if (hasConsumerAccess) {
              return obj?.organizationTypes?.includes('CONSUMER');
            } else {
              return (
                obj?.organizationTypes?.includes('SUPPLIER') ||
                obj?.organizationTypes?.includes('ON_ARRIVAL')
              );
            }
          })
          ?.find((obj: IDataExchangeVisaStatus) => obj?.value === params?.value?.bookingStatus);

        return (
          <OAStatusChip
            status={statusObj?.value}
            label={statusObj?.label}
            variant={statusObj?.type}
          />
        );
      },
      valueGetter: params => params?.row?.linkedOrder,
      flex: 1,
      minWidth: 165
    },
    {
      field: 'progress',
      renderHeader: params => <GridColumnHeader title='Progress' params={params} />,
      renderCell: params => <ProgressCell data={params?.value} />,
      valueGetter: params => params?.row,
      flex: 1,
      minWidth: 185
    },
    {
      field: 'supplier',
      renderHeader: params => <GridColumnHeader title='Supplier' params={params} />,
      renderCell: params => (
        <DefaultCell
          data={
            getSupplierOrganizationName(currentUser, params?.row?.supplierOrganization)?.orgName
          }
          isEllipsis
        />
      ),
      valueGetter: params => params?.row?.supplierOrganization?.name,
      width: 130
    },
    {
      field: 'customer',
      renderHeader: params => <GridColumnHeader title='Customer' params={params} />,
      renderCell: params => <DefaultCell data={params?.value} isEllipsis />,
      valueGetter: params => params?.row?.customerOrganization?.name,
      width: 130
    },
    {
      field: 'visaType',
      renderHeader: params => <GridColumnHeader title='Visa Type' params={params} />,
      renderCell: params => <DefaultCell data={params?.value} />,
      valueGetter: params => params?.row?.linkedOrder?.product?.productInformation?.visaType,
      width: 80
    },
    {
      field: 'amount',
      renderHeader: params => <GridColumnHeader title='Amount(INR)' params={params} />,
      renderCell: params => <AmountCell data={params?.value} />,
      valueGetter: params => params?.row?.linkedOrder?.orderAmount?.totalAmount,
      width: 100
    },
    {
      field: 'createdAt',
      renderHeader: params => <GridColumnHeader title='Created On' params={params} />,
      renderCell: params => (
        <CreatedOnCell date={params?.value} dateFormat={OA_DATE_TIME_FORMAT_FE} />
      ),
      valueGetter: params => params?.row?.requestedAt,
      width: 175,
      sortable: true
    },
    {
      field: 'createdBy',
      renderHeader: params => <GridColumnHeader title='Created By' params={params} />,
      renderCell: params => <DefaultCell data={params?.value} isEllipsis />,
      valueGetter: params => {
        const draftData = params?.row?.linkedOrder?.createdByUser;
        const firstName = draftData?.firstName ?? '';
        const lastName = draftData?.lastName ?? '';
        const draftName = `${firstName} ${lastName}`;
        return draftName;
      },
      width: 130
    }
  ];
  if (
    currentUser?.data?.organization?.type === 'SUPPLIER' &&
    columnModifiers?.status !== 'ORDER_QUOTE'
  ) {
    const additionalColumns: Array<GridColDef> = [
      {
        field: 'assignedTo',
        renderHeader: params => <GridColumnHeader title='Assigned To' params={params} />,
        renderCell: params => {
          const currentUserOrgId = currentUser?.data?.organization?.id;
          const selectedRowOrgId = params?.value?.supplierOrganization?.id;

          return params?.value?.linkedOrder?.assignedUserId ? (
            <AssignedUserCell userId={params?.value?.linkedOrder?.assignedUserId} />
          ) : params?.value?.linkedOrder?.bookingStatus !== 'ORDER_QUOTE' ? (
            <Button
              size='sm'
              variant='secondary'
              onClick={evt => {
                evt.stopPropagation();
                toggleAssignUserModal(params?.value);
              }}
              disabled={currentUserOrgId !== selectedRowOrgId}>
              Assign
            </Button>
          ) : null;
        },
        valueGetter: params => params?.row,
        width: 130
      }
    ];
    draft = [...draft, ...additionalColumns];
  }
  return draft;
};

type getInsuranceColumnsType = (currentUser: any, dataExchange: any) => GridColDef[];

export const getInsuranceColumns: getInsuranceColumnsType = (
  currentUser: any,
  dataExchange: any
) => [
  {
    field: 'visaId',
    renderHeader: params => <GridColumnHeader title='Booking Id' params={params} />,
    renderCell: params => <DefaultCell data={params?.value} />,
    valueGetter: params => params?.row?.linkedOrder?.id,
    width: 110,
    pinnable: true,
    disableColumnMenu: false,
    filterable: true
  },
  {
    field: 'travellers',
    renderHeader: params => <GridColumnHeader title='Travellers' params={params} />,
    renderCell: params => <TravellersCell members={params?.value} showPrimary />,
    valueGetter: params => params?.row?.linkedOrder?.members,
    flex: 3,
    minWidth: 230
  },
  {
    field: 'destination',
    renderHeader: params => <GridColumnHeader title='Destination' params={params} />,
    renderCell: params => <TripCell truncate {...params?.value} />,
    valueGetter: params => params?.row?.linkedOrder?.trip,
    width: 110
  },
  {
    field: 'status',
    renderHeader: params => <GridColumnHeader title='Status' params={params} />,
    renderCell: params => {
      const statusObj: any = dataExchange['INSURANCE_STATUS']?.find(
        (obj: any) => obj?.value === params?.value
      );
      return (
        <OAStatusChip
          status={statusObj?.value}
          label={statusObj?.label}
          variant={statusObj?.type}
        />
      );
    },
    valueGetter: params => params?.row?.linkedOrder?.bookingStatus,
    flex: 1,
    minWidth: 150
  },
  {
    field: 'supplier',
    renderHeader: params => <GridColumnHeader title='Supplier' params={params} />,
    renderCell: params => <DefaultCell data={params?.value} isEllipsis />,
    valueGetter: params => params?.row?.supplierOrganization?.name,
    width: 130
  },
  {
    field: 'visaName',
    renderHeader: params => <GridColumnHeader title='Product Name' params={params} />,
    renderCell: params => <DefaultCell data={params?.value} isEllipsis />,
    valueGetter: params => params?.row?.linkedOrder?.product?.name,
    width: 130
  },
  {
    field: 'amount',
    renderHeader: params => <GridColumnHeader title='Amount(Rs)' params={params} />,
    renderCell: params => <AmountCell data={params?.value} />,
    valueGetter: params => params?.row?.linkedOrder?.orderAmount?.totalAmount,
    width: 100
  },
  {
    field: 'createdBy',
    renderHeader: params => <GridColumnHeader title='Created By' params={params} />,
    renderCell: params => <DefaultCell data={params?.value} isEllipsis />,
    valueGetter: params => {
      const draftData = params?.row?.linkedOrder?.createdByUser;
      const firstName = draftData?.firstName ?? '';
      const lastName = draftData?.lastName ?? '';
      const draftName = `${firstName} ${lastName}`;
      return draftName;
    },
    width: 130
  },
  {
    field: 'createdAt',
    renderHeader: params => <GridColumnHeader title='Created On' params={params} />,
    renderCell: params => (
      <CreatedOnCell date={params?.value} dateFormat={OA_DATE_TIME_FORMAT_FE} />
    ),
    valueGetter: params => params?.row?.createdAt,
    width: 175,
    sortable: true
  }
];

type getGroupOrderColumnsType = () => GridColDef[];

export const getGroupOrderColumns: getGroupOrderColumnsType = () => [
  {
    field: 'groupId',
    renderHeader: params => <GridColumnHeader title='Booking Id' params={params} />,
    renderCell: params => <DefaultCell data={params?.value} />,
    valueGetter: params => params?.row?.id,
    width: 110,
    pinnable: true,
    disableColumnMenu: false,
    filterable: true
  },
  {
    field: 'groupName',
    renderHeader: params => <GridColumnHeader title='Group Name' params={params} />,
    renderCell: params => <DefaultCell data={params?.value} />,
    valueGetter: params => params?.row?.groupName,
    flex: 1
  },
  {
    field: 'travellerCount',
    renderHeader: params => <GridColumnHeader title='Travellers' params={params} />,
    renderCell: params => <DefaultCell data={params?.value} />,
    valueGetter: params => params?.row?.associatedOrderIds?.length ?? 0,
    width: 110
  },
  {
    field: 'destination',
    renderHeader: params => <GridColumnHeader title='Destination' params={params} />,
    renderCell: params => <TripCell {...params?.value} />,
    valueGetter: params => params?.row?.trip,
    width: 95
  },
  {
    field: 'supplier',
    renderHeader: params => <GridColumnHeader title='Supplier' params={params} />,
    renderCell: params => <DefaultCell data={params?.value} isEllipsis />,
    valueGetter: params => params?.row?.supplierOrganization?.name ?? '',
    width: 130
  },
  {
    field: 'visaType',
    renderHeader: params => <GridColumnHeader title='Visa Type' params={params} />,
    renderCell: params => <DefaultCell data={params?.value} />,
    valueGetter: params => params?.row?.trip?.visaType,
    width: 80
  },
  {
    field: 'tripType',
    renderHeader: params => <GridColumnHeader title='Trip Type' params={params} />,
    renderCell: params => <DefaultCell data={params?.value} />,
    valueGetter: params => params?.row?.trip?.tripType,
    width: 80
  },
  {
    field: 'createdOn',
    renderHeader: params => <GridColumnHeader title='Created On' params={params} />,
    renderCell: params => (
      <CreatedOnCell date={params?.value} dateFormat={OA_DATE_TIME_FORMAT_FE} />
    ),
    valueGetter: params => params?.row?.createdAt,
    width: 175,
    sortable: true
  },
  {
    field: 'createdBy',
    renderHeader: params => <GridColumnHeader title='Created By' params={params} />,
    renderCell: params => <DefaultCell data={params?.value} isEllipsis />,
    valueGetter: params => {
      const draftData = params?.row?.createdByUser;
      const firstName = draftData?.firstName ?? '';
      const lastName = draftData?.lastName ?? '';
      const draftName = `${firstName} ${lastName}`;
      return draftName;
    },
    width: 130
  }
];
