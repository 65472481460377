import { getFormattedDate } from '../../helper';

const CreatedOnCell = ({ date, dateFormat }: { date: any; dateFormat: string }) => {
  if (date) {
    const formattedTime = getFormattedDate(date, dateFormat);
    return <div className='fs-6'>{formattedTime}</div>;
  } else {
    return <div className='fs-6'>{'N/A'}</div>;
  }
};

export default CreatedOnCell;
