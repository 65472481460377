import React, { useEffect, useRef, useState } from 'react';
import { WarningSVG } from '../../../../../components/Icons';
import { formatWordWithUnderscore } from '../../../../../helper';
import SuccessSVG from '../../../../../components/Icons/SuccessSvg';
import { isBoolean } from 'lodash';

interface DocumentItem {
  id?: string;
  documentUrl?: string;
  fileName?: string;
  documentType?: string;
  isReviewed?: boolean;
  uploaded?: boolean;
}

interface IDocsReviewStatusList {
  uploadedFiles: DocumentItem[];
  previouslyUploadedDocs: DocumentItem[];
  handleSelectedDocument: (document: DocumentItem) => void;
  apiResponse: any;
  selectedDocument?: any;
}

const getStatusAttributes = (item: DocumentItem) => {
  if (isBoolean(item?.uploaded) && !item?.uploaded) {
    if (item?.documentType && item?.documentUrl && !item?.isReviewed) {
      return { SVG: WarningSVG, text: 'Not reviewed', className: 'text-danger' };
    } else if (!item?.documentType) {
      return { SVG: WarningSVG, text: 'Unable to classify', className: 'text-danger' };
    }
  } else if ((item?.isReviewed || item?.uploaded) && item?.documentType) {
    return { SVG: SuccessSVG, color: '#2fb344', text: 'Reviewed', className: 'text-success' };
  }
  return null;
};

const DocumentItemComponent: React.FC<{
  document: any;
  handleSelectedDocument: (document: DocumentItem) => void;
  isSelected: boolean;
  apiResponse?: any[];
}> = ({ document, isSelected, apiResponse, handleSelectedDocument }) => {
  const statusAttributes = getStatusAttributes(document);

  return (
    <div
      className={`border-bottom cursor-pointer ${isSelected ? 'bg-gray-100' : ''}`}
      onClick={() => handleSelectedDocument(document)}>
      <div className='p-6'>
        <div className='d-flex justify-content-between'>
          <div className='text-break text-truncate'>
            {document?.fileName || document?.fieldName}
          </div>
        </div>
        {!statusAttributes ? (
          <span
            className='spinner-border spinner-border-xl text-primary align-middle ms-2 mb-3'
            style={{ width: '1rem', height: '1rem' }}
          />
        ) : (
          <div className='d-flex flex-column'>
            <div>
              <statusAttributes.SVG color={statusAttributes.color} height='14' width='14' />
              <span className={`fw-bold ${statusAttributes.className} ms-3`}>
                {statusAttributes.text}
              </span>
            </div>
            {document?.documentType && (
              <div className='mt-1'>
                <span className='bg-gray-200 text-gray-600 p-1 fs-8 rounded fw-bolder'>
                  {formatWordWithUnderscore(document?.documentType)}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const DocumentReviewStatusList: React.FC<IDocsReviewStatusList> = ({
  uploadedFiles,
  apiResponse,
  previouslyUploadedDocs,
  handleSelectedDocument,
  selectedDocument
}) => {
  const [displayedDocuments, setDisplayedDocuments] = useState(uploadedFiles);
  const docRefs = useRef<{ [id: string]: React.RefObject<HTMLDivElement> }>({});

  const [selectedDocId, setSelectedDocId] = useState<any | null>(
    uploadedFiles && uploadedFiles.length > 0
      ? 'uploaded-0'
      : previouslyUploadedDocs && previouslyUploadedDocs.length > 0
      ? 'previous-0'
      : null
  );

  useEffect(() => {
    if (selectedDocument?.id) {
      const ref = docRefs.current[selectedDocument.id];
      ref?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, [selectedDocument]);

  const fileNameOrderMap = new Map();
  uploadedFiles?.forEach((doc, index) => {
    fileNameOrderMap?.set(doc.fileName, index);
  });

  const sortedDocuments = apiResponse?.sort((a: any, b: any) => {
    const indexA = fileNameOrderMap?.get(a.fileName) || 0;
    const indexB = fileNameOrderMap?.get(b.fileName) || 0;
    return indexA - indexB;
  });

  useEffect(() => {
    let updatedDocuments = [...(uploadedFiles || [])];

    sortedDocuments?.forEach((sortedDoc: any) => {
      const index = updatedDocuments.findIndex(
        uploadedDoc => uploadedDoc.fileName === sortedDoc.fileName
      );
      if (index !== -1) {
        updatedDocuments[index] = sortedDoc;
      }
    });

    // Update the state to reflect the new list
    setDisplayedDocuments(updatedDocuments);
  }, [sortedDocuments, uploadedFiles]);

  const findMatchingApiResponse = (fileName: string): DocumentItem | null => {
    const strippedFileName = fileName.replace('.zip', '');
    return apiResponse?.find((item: any) => item?.fileName === strippedFileName) || null;
  };

  const handleDocumentSelection = (document: DocumentItem, index: number, prefix: string) => {
    setSelectedDocId(document?.id);
    handleSelectedDocument(document);
  };

  return (
    <section>
      {uploadedFiles?.length &&
        displayedDocuments?.map((uploadedFile: any, index: number) => {
          const matchingApiResponse = findMatchingApiResponse(uploadedFile?.fileName);
          const isSelected =
            selectedDocument?.id === matchingApiResponse?.id ||
            selectedDocId?.id === matchingApiResponse?.id;
          return (
            <DocumentItemComponent
              key={uploadedFile.fileName}
              document={matchingApiResponse || uploadedFile}
              handleSelectedDocument={doc => handleDocumentSelection(doc, index, 'uploaded')}
              isSelected={isSelected}
            />
          );
        })}
      {previouslyUploadedDocs?.length > 0 && (
        <div className='text-uppercase text-gray-500 px-6 my-2 fs-8 fw-bolder'>
          Previously uploaded
        </div>
      )}
      {previouslyUploadedDocs?.length > 0 &&
        previouslyUploadedDocs?.map((previouslyUploadedDoc: any, index: number) => {
          if (!docRefs.current[previouslyUploadedDoc.id]) {
            docRefs.current[previouslyUploadedDoc.id] = React.createRef();
          }
          return (
            <div ref={docRefs.current[previouslyUploadedDoc.id]} key={index}>
              <DocumentItemComponent
                document={previouslyUploadedDoc}
                handleSelectedDocument={doc => handleDocumentSelection(doc, index, 'previous')}
                isSelected={
                  selectedDocId === previouslyUploadedDoc.id ||
                  selectedDocument?.id === previouslyUploadedDoc.id
                }
              />
            </div>
          );
        })}
    </section>
  );
};

export default DocumentReviewStatusList;
