/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { useAuth } from '../../auth';
import { toAbsoluteUrl } from '../helpers';

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth();
  const isGuest = currentUser?.data?.role === 'GUEST_USER';
  const fullName = `${currentUser?.data?.firstName} ${currentUser?.data?.lastName}`;
  const rootClassNames =
    'menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px';

  return (
    <div className={classNames(rootClassNames, 'user__dropdown--menu')} data-kt-menu='true'>
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')} />
          </div>
          <div className='d-flex flex-column'>
            <div
              title={fullName}
              className='text-truncate fw-bold d-flex align-items-center fs-6 w-150px text-capitalize'>
              {fullName}
            </div>
            <div
              title={currentUser?.data?.email}
              className='text-truncate fw-bold text-muted fs-7 w-150px'>
              {currentUser?.data?.email}
            </div>
            <div
              title={currentUser?.data?.organization?.name}
              className='text-truncate fw-bold text-muted fs-7 w-150px text-capitalize'>
              {currentUser?.data?.organization?.name}
            </div>
          </div>
        </div>
      </div>
      <div className='separator my-2' />
      {!isGuest && (
        <div className='menu-item px-5 my-1'>
          <Link to='/profile/changePassword' className='menu-link px-5'>
            Change Password
          </Link>
        </div>
      )}
      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
