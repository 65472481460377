import { Route, Routes, Outlet, Navigate } from 'react-router-dom';

import OrderProvider from './context/OrderProvider';
import { ORDER_TYPE } from './constants';
import OrderList from './order-list';
import CreateOrder from './order-create';
import OrderView from './order-view';
import GroupView from './GroupView';
import QuotationProvider from '../quotation/context/QuotationProvider';
import BulkUpload from './order-create/insurance/BulkUpload';
import UserProvider from '../user-management/context/UserProvider';
import { IQueryParams } from '../Dashboard';

export default function OrderManagement(props: IQueryParams) {
  return (
    <UserProvider>
      {/**
       * NOTE: OrderProvider is used to provide order context to all it's child
       * components...
       */}
      <OrderProvider data={props}>
        {/**
         * NOTE: QuotationProvider is used to provide quotation context to all
         * it's child components...
         */}
        {/* TODO: move all methods and states in OrderContext and remove QuotationContext */}
        <QuotationProvider>
          <Routes>
            <Route element={<Outlet />}>
              {/**
               * NOTE: ':orderType/' will navigate to order listing page where
               * orderType is either of visa or insurance...
               */}
              <Route path=':orderType/' element={<OrderList data={props} />} />
              {/**
               * NOTE: ':orderType/create' will navigate to order create page...
               */}
              <Route path=':orderType/create' element={<CreateOrder />} />
              {/**
               * NOTE: ':orderType/create/:step' will also navigate to order
               * create page where step number will determine form creation step...
               */}
              <Route path=':orderType/create/:step' element={<CreateOrder />} />
              {/**
               * NOTE: ':orderType/:id' will navigate to order view page...
               */}
              <Route path=':orderType/:id/*' element={<OrderView />} />
              {/**
               * NOTE: ':orderType/group/:id' will navigate to group view page...
               */}
              <Route path=':orderType/group/:id' element={<GroupView data={props} />} />
              {/**
               * NOTE: ':orderType/bulk-upload' will navigate to bulk upload page...
               */}
              <Route path=':orderType/bulk-upload' element={<BulkUpload />} />
            </Route>
            {/**
             * NOTE: initial route for the app where '/' will navigate to visa
             * order listing page...
             */}
            <Route index element={<Navigate to={`/apps/orders/${ORDER_TYPE.VISA}`} />} />
          </Routes>
        </QuotationProvider>
      </OrderProvider>
    </UserProvider>
  );
}
