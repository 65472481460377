import { FormikHelpers } from 'formik';

import SearchCoverForm from './SearchCoverForm';
import SelectInsuranceForm from './SelectInsuranceForm';

export default function AddInsuranceCover(props: {
  onSubmit: (values: any, type: string, actions?: FormikHelpers<any>) => void;
}) {
  return (
    <div className='add-insurance-type w-100 p-6 bg-white rounded'>
      <SearchCoverForm {...props} />
      <SelectInsuranceForm {...props} />
    </div>
  );
}
