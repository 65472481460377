interface IPreview {
  data: any;
  onClick: () => void;
}

const PreviewCell = (props: IPreview) => {
  const isImage = /\.(jpe?g|png|gif|bmp|webp)$/i.test(props.data);
  const isPDF = /\.pdf$/i.test(props.data);
  const isDoc = /\.(doc|docx)$/i.test(props.data);

  return (
    <section style={{ height: '42px', width: '60px' }}>
      {isImage && (
        <img src={props.data} className='w-100 rounded pointer-cursor' onClick={props?.onClick} />
      )}
      {isPDF && (
        <div onClick={props.onClick} className='w-100 h-100 position-relative'>
          <embed
            className='w-100 h-100'
            src={`${props.data}#toolbar=0`}
            type='application/pdf'></embed>
          <div className='position-absolute top-0 start-0 end-0 bottom-0'></div>
        </div>
      )}
      {isDoc && (
        <div className='w-100 h-100 position-relative' onClick={props?.onClick}>
          <iframe
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
              props?.data
            )}`}
            width='100%'
            height='100%'></iframe>
          <div className='position-absolute top-0 start-0 end-0 bottom-0'></div>
        </div>
      )}
    </section>
  );
};

export default PreviewCell;
