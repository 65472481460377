import { KTSVG } from '../../_metronic/helpers';

const NoRecords = (props: { columnLength: number }) => {
  return (
    <tr className='data-table__row'>
      <td colSpan={props?.columnLength}>
        <div className='d-flex flex-column text-center w-100 align-content-center justify-content-center'>
          <KTSVG className='svg-icon-3x' path='/media/icons/duotune/general/gen021.svg' />
          <p className='mt-1 mb-0'>No matching records found</p>
        </div>
      </td>
    </tr>
  );
};

export default NoRecords;
