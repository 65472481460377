import { FC } from 'react';
import { OATypography } from '../../widgets';

interface IFaqs {
  faq: any[];
}

export const Faqs: FC<IFaqs> = ({ faq }) => {
  return (
    <>
      {faq.map((que: any, index: number) => (
        <div key={que?.number}>
          <OATypography variant='body1' sx={{ mb: '4px' }}>
            {que?.question}
          </OATypography>
          <OATypography
            variant='body2'
            sx={{
              mb: faq?.length - 1 === index ? '0' : '16px',
              color: theme => theme?.palette?.grey?.[400]
            }}>
            {que?.answer}
          </OATypography>
        </div>
      ))}
    </>
  );
};
