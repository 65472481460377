import { FC } from 'react';
import { KTSVG } from '../../../_metronic/helpers';
import { OATypography } from '../../widgets';

interface IVisaFormsLink {
  forms: any;
}

export const VisaFormsLink: FC<IVisaFormsLink> = ({ forms }) => {
  const getIcon = (url: string) => {
    // Extract the file extension from the URL using a regular expression
    const fileExtensionRegex = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gim;
    const matches = url.match(fileExtensionRegex);
    const iconMapping: {
      [key: string]: string;
    } = {
      pdf: 'pdf_icon.svg',
      web: 'web_icon.svg',
      default: 'default_icon.svg',
      xlsx: 'xlsx_icon.svg'
    };
    if (matches && matches.length > 0) {
      const fileType = matches[0].substring(1).toLowerCase();
      const iconFileName = iconMapping[fileType!] || iconMapping.default;
      return `/media/icons/duotune/files/${iconFileName}`;
    } else {
      return '/media/icons/duotune/files/default_icon.svg';
    }
  };

  return (
    <>
      {forms.map((obj: any, index: number) => (
        <a key={obj?.formName} href={obj?.formLink} target='_blank' rel='noreferrer'>
          <div className={`d-flex align-items-center ${forms?.length - 1 === index ? '' : 'mb-6'}`}>
            <KTSVG path={getIcon(obj?.formLink)} className='svg-icon-2x svg-icon-gray-600' />
            <OATypography variant='body2' sx={{ pl: 1, color: '#3A86FF' }}>
              {obj?.formName}
            </OATypography>
          </div>
        </a>
      ))}
    </>
  );
};
