import { Button, Col, Container, Row } from 'react-bootstrap';
import { Form, Formik, FormikProps } from 'formik';
import { useParams } from 'react-router-dom';

import OAModalComponent from '../../../../components/OAModalComponent';
import OATextField from '../../../../components/fields/OATextField';
import { useOrderContext } from '../../context/OrderContext';
import { IOrderType } from '../../types';
import { filter, includes } from 'lodash';

/**
 * types ...
 */
interface IUpdateApplicationIdModal {
  isOpen: boolean;
  onHide: () => void;
}

/**
 *
 * @param props
 * @returns
 */
export default function UpdateApplicationIdModal(props: IUpdateApplicationIdModal) {
  return (
    <OAModalComponent
      show={props?.isOpen}
      onHide={props?.onHide}
      className='update-application-id__modal'
      header={{
        title: 'Update application id',
        closeButton: true
      }}
      body={{
        content: <UpdateApplicationIdForm onHide={props?.onHide} />,
        className: 'update-application-id__modal-body'
      }}
    />
  );
}

/**
 * Additional component : UpdateApplicationIdForm
 */
interface IUpdateApplicationIdForm {
  onHide: () => void;
}

export interface IUpdateApplicationIdFormValues {
  applicationId: string;
}

const validate = (values: IUpdateApplicationIdFormValues) => {
  const errors: any = {};

  if (!values?.applicationId) {
    errors.applicationId = 'Please update the Application ID received from the embassy';
  }

  /* The below code should have been removed based on the ON-2357 ticket requirement.
      But commented the code because it can be used in the future.
  */

  // if (values?.applicationId && values?.applicationId !== '') {
  //   let applicationIds = values?.applicationId?.toString().split(',');
  //   var valid = true;
  //   var regex = /^[a-zA-Z0-9]+(?:,[a-zA-Z0-9]+)*$/;
  //   for (var i = 0; i < applicationIds?.length; i++) {
  //     if (applicationIds[i] === '' || !regex.test(applicationIds[i].trim())) valid = false;
  //   }
  //   applicationIds = applicationIds?.map((applicationId: any) => applicationId.trim());
  //   let duplicates = filter(applicationIds, (value, index: any, iteratee) =>
  //     includes(iteratee, value, parseInt(index) + 1)
  //   );
  //   if (!valid) errors.applicationId = 'Please write application id separated by comma';
  //   if (duplicates.length > 0)
  //     errors.applicationId = 'The application id list contains non-unique IDs';
  // }

  return errors;
};

function UpdateApplicationIdForm(props: IUpdateApplicationIdForm) {
  const params = useParams<{ orderType: IOrderType; id?: string }>();

  const { orderData, updateOrderInfo } = useOrderContext();

  const handleSubmit = (values: IUpdateApplicationIdFormValues) => {
    updateOrderInfo(params?.orderType!, params?.id!, values, () => {
      props?.onHide();
    });
  };

  return (
    <Formik
      initialValues={{ applicationId: orderData?.data?.applicationId }}
      onSubmit={handleSubmit}
      validate={validate}>
      {(formikProps: FormikProps<IUpdateApplicationIdFormValues>) => (
        <Form className='w-100'>
          <Container fluid className='p-0'>
            <Row>
              <Col lg={12} className='mb-6'>
                <OATextField
                  name='applicationId'
                  label='Application ID (from embassy/VFS)'
                  placeholder='Type here'
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='d-flex justify-content-end'>
                  <Button
                    type='button'
                    size='sm'
                    onClick={props?.onHide}
                    disabled={formikProps?.isSubmitting}
                    className='btn-light-primary btn-active-light-primary me-4'>
                    Cancel
                  </Button>
                  <Button
                    type='submit'
                    size='sm'
                    disabled={
                      (!formikProps?.isValid && formikProps?.dirty) || formikProps?.isSubmitting
                    }
                    className='btn-primary btn-active-primary'>
                    Update
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Form>
      )}
    </Formik>
  );
}
