import { useOrderContext } from '../../../order-management/context/OrderContext';

const AdditionalServices = () => {
  const {
    orderData: { data }
  } = useOrderContext();

  const {
    product: {
      productInformation: { additionalService }
    }
  } = data;

  return (
    <section className='dev--quotation__additional-services my-8'>
      {additionalService && (
        <h1 className='fw-bolder mb-3 text-dark fs-4'>{additionalService?.name}</h1>
      )}
      <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4 quotation-doc-table'>
        <tbody>
          {additionalService &&
            additionalService?.services?.map((obj: any, index: any) => (
              <tr key={index}>
                <td>
                  <div className='fw-bolder fs-6'>{index + 1}</div>
                </td>
                <td>
                  <div className='fw-bolder fs-6'>{obj?.name}</div>
                  <div className='form-text mt-0'>{obj?.description}</div>
                  {obj?.addOnServices &&
                    obj?.addOnServices?.map((item: any, index: any) => (
                      <tr key={index}>
                        <td>
                          <li className='fw-bolder fs-6'></li>
                        </td>
                        <td>
                          <div className='fw-bolder fs-6'>{item?.name}</div>
                          <div className='form-text mt-0'>{item?.description}</div>
                        </td>
                      </tr>
                    ))}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </section>
  );
};

export default AdditionalServices;
