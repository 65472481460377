import EditSVG from '../../assets/icons/icon_edit.svg';
import OAButton from '../widgets/OAButton';
import { useContext, useEffect, useState } from 'react';
import { useAuth } from '../../auth';
import LedgerContext, { ILedgerContext } from '../../pages/Ledger/context/LedgerContext';

interface IEditTransactionalModal {
  data: any;
  handleEditTransaction: (data: any) => void;
}
const LedgerAction = (props: IEditTransactionalModal) => {
  const [ledgerInfo, setLedgerInfo] = useState<any>(null);
  const { getLedgerInfo }: ILedgerContext = useContext(LedgerContext);
  const { currentUser } = useAuth();

  useEffect(() => {
    getLedgerInfo((res: any) => {
      setLedgerInfo(res);
    });
  }, []);

  return (
    <div>
      {currentUser?.data?.role === 'ROLE_SUPER_ADMIN' && ledgerInfo?.active && (
        <div>
          <OAButton
            color='light'
            size='sm'
            className='w-30px h-30px p-0'
            onClick={() => props?.handleEditTransaction(props?.data)}
            label='Edit'
            icon={EditSVG}
            iconClassName='svg-icon-1 ms-2'
          />
        </div>
      )}
    </div>
  );
};

export default LedgerAction;
