import React, { useEffect, useState } from 'react';
import { Box, Slider, SliderThumb } from '@mui/material';
import { isEmpty } from 'lodash';

import { useAuth } from '../../../auth';
import { useOrderContext } from '../context/OrderContext';
import { OAStatusChip, OATypography } from '../../../components/widgets';
import { getStatusColor } from '../../../helper';
import { useAppContext } from '../../../context/AppContext';
import { IDataExchangeVisaStatus } from '../../../components/widgets/OAStatusChip';

const getStageInfo = (stage: string, isSupplier: boolean) => {
  switch (stage) {
    case 'QUOTE':
      return {
        cur: 'QUOTE',
        next: 'DRAFT',
        value: 5
      };
    case 'DRAFT':
      return {
        cur: 'DRAFT',
        next: isSupplier ? 'PROCESSING' : 'SUBMITTED',
        value: isSupplier ? 25 : 33
      };
    case 'PROCESSING':
      return {
        cur: 'PROCESSING',
        next: 'SUBMITTED',
        value: 50
      };
    case 'SUBMITTED':
      return {
        cur: 'SUBMITTED',
        next: 'COMPLETED',
        value: isSupplier ? 75 : 67
      };
    case 'COMPLETED':
      return {
        cur: 'COMPLETED',
        next: '',
        value: 100
      };
    default:
      break;
  }
};

interface ThumbComponentProps extends React.HTMLAttributes<unknown> {
  addonBefore: JSX.Element;
  addonAfter: JSX.Element;
}

function ThumbComponent(props: ThumbComponentProps) {
  const { children, addonBefore, addonAfter, ...other } = props;

  return (
    <SliderThumb {...other}>
      {children}
      <Box display='flex'>
        {addonBefore}
        {addonAfter}
      </Box>
    </SliderThumb>
  );
}

interface OrderProgressProps {
  bookingStatus: string;
  isPendingTask: boolean;
}

export default function OrderProgress(props: OrderProgressProps) {
  const { dataExchange } = useAppContext();

  const { currentUser } = useAuth();
  const { orderData, orderActions } = useOrderContext();

  const [stageInfo, setStageInfo] = useState<any>({});

  const hasConsumerAccess =
    currentUser?.data?.organizationId === orderData?.data?.createdByUser?.organizationId;
  const hasSupplierAccess =
    currentUser?.data?.organizationId === orderData?.data?.supplierOrganization?.id;
  const hasAllAccess = hasConsumerAccess && hasSupplierAccess;
  /**
   * NOTE: differentiate visa statuses based on user's org, order created by user's org and
   * order supplier org ...
   */
  const visaStatuses = dataExchange['VISA_STATUS']?.filter((obj: IDataExchangeVisaStatus) => {
    if (hasConsumerAccess && !hasAllAccess) {
      return obj?.organizationTypes?.includes('CONSUMER');
    } else {
      return (
        obj?.organizationTypes?.includes('SUPPLIER') ||
        obj?.organizationTypes?.includes('ON_ARRIVAL')
      );
    }
  });

  const actions: any = orderActions?.data?.filter((obj: any) => obj?.status !== 'COMPLETED');

  const statusObj = visaStatuses?.find(
    (obj: IDataExchangeVisaStatus) => obj?.value === props?.bookingStatus
  );

  const isOrderSubmitFailed = ['ORDER_FAILED', 'ORDER_SUBMIT_FAILED']?.includes(
    props?.bookingStatus
  );

  useEffect(() => {
    const draftInfo: any = getStageInfo(
      statusObj?.stage,
      currentUser?.data?.organizationId === orderData?.data?.supplierOrganization?.id
    );
    setStageInfo(draftInfo);
  }, [orderData]);

  const getAddonStatusChip = () => {
    if (isOrderSubmitFailed) {
      const { type, label } = dataExchange['VISA_STATUS']?.find(
        (obj: any) => obj?.value === 'RESUBMIT_ORDER'
      );
      return <OAStatusChip status='RESUBMIT_ORDER' variant={type} label={label} animate />;
    } else if (actions?.length > 0) {
      const { type, label } = dataExchange['VISA_STATUS']?.find(
        (obj: any) => obj?.value === 'PENDING_TASK'
      );
      return <OAStatusChip status='PENDING_TASK' variant={type} label={label} animate />;
    } else {
      return null;
    }
  };

  const getStatusChip = () => {
    const { value, label, type }: IDataExchangeVisaStatus = dataExchange['VISA_STATUS']
      ?.filter((obj: IDataExchangeVisaStatus) => {
        if (hasConsumerAccess && !hasAllAccess) {
          return obj?.organizationTypes?.includes('CONSUMER');
        } else {
          return (
            obj?.organizationTypes?.includes('SUPPLIER') ||
            obj?.organizationTypes?.includes('ON_ARRIVAL')
          );
        }
      })
      ?.find((obj: IDataExchangeVisaStatus) => obj?.value === props?.bookingStatus);
    return <OAStatusChip status={value} variant={type} label={label} animate />;
  };

  return stageInfo?.value ? (
    <Box sx={{ width: '450px', display: 'flex', flexDirection: 'column', marginTop: '24px' }}>
      <Slider
        value={stageInfo?.value}
        aria-label='Default'
        valueLabelDisplay='off'
        slots={{
          thumb: thumbProps => (
            <ThumbComponent
              addonBefore={getStatusChip()}
              addonAfter={getAddonStatusChip()}
              {...thumbProps}
            />
          )
        }}
        sx={{
          'cursor': 'unset',
          'height': '6px',

          '& .MuiSlider-rail': {
            backgroundColor: theme => theme.palette.grey[400],
            pointerEvents: 'none'
          },

          '& .MuiSlider-track': {
            backgroundColor: theme => getStatusColor(statusObj?.type, theme),
            border: 'unset',
            pointerEvents: 'none'
          },

          '& .MuiSlider-thumb': {
            'width': '0',
            'height': '0',
            '&:before': {
              width: '0',
              height: '0'
            },
            '&:after': {
              width: '0',
              height: '0'
            },
            '&:hover': {
              boxShadow: 'unset'
            },
            '&.Mui-active': {
              boxShadow: 'unset'
            },
            '& .MuiChip-root': {
              backgroundColor: theme => theme.palette.background.default
            }
          }
        }}
      />
      <Box display='flex' justifyContent='space-between' marginTop='-6px'>
        <OATypography
          variant='button'
          sx={{ color: theme => getStatusColor(statusObj?.type, theme) }}>
          {stageInfo?.cur}
        </OATypography>
        {!isEmpty(stageInfo?.next) ? (
          <OATypography variant='button' sx={{ color: theme => theme?.palette?.grey?.[600] }}>
            {`Next Stage: ${stageInfo?.next}`}
          </OATypography>
        ) : null}
      </Box>
    </Box>
  ) : null;
}
