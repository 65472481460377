import { useContext, useEffect, useState } from 'react';
import { Box, IconButton, Skeleton } from '@mui/material';
import { OAStatusChip, OATypography } from '../../../components/widgets';
import { ReactComponent as ArrowBackIcon } from '../../../assets/icons/arrow_back.svg';
import { ReactComponent as SourceDestIcon } from '../../../assets/icons/source-DestArrow.svg';
import { Link, Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import {
  Card,
  Col,
  Dropdown,
  OverlayTrigger,
  OverlayTriggerProps,
  Popover,
  Row
} from 'react-bootstrap';
import { CustomDropdownItem } from '../../order-management/order-view/OrderActions';
import OAButton from '../../../components/widgets/OAButton';

import { ReactComponent as DotSeparatorIcon } from '../../../assets/icons/dot_separator.svg';
import { ReactComponent as CalenderIcon } from '../../../assets/icons/calender.svg';
import { ReactComponent as MembersIcon } from '../../../assets/icons/members.svg';
import { ReactComponent as DoubleArrowIcon } from '../../../assets/icons/DoubleArrows.svg';
import { ReactComponent as RegularFareIcon } from '../../../assets/icons/regularFare.svg';
import { ReactComponent as StudentFareIcon } from '../../../assets/icons/studentFare.svg';
import { getFormattedDateRange } from '../../../helper';
import classNames from 'classnames';
import ActivityLogs from '../FlightTabs/ActivityLogs';
import CommentsAndTasks from '../../order-management/order-view/CommentsAndTasks';
import TravellerInfo from '../FlightTabs/TravellerInfo';
import FlightContext from '../context/FlightContext';
import PaymentSummary from '../FlightTabs/PaymentSummary';
import { CancelFlightBooking } from '../CancelBooking';
import { FLIGHT_STATUS } from '../../../constants';
import FlightOrderStatus from './FlightOrderStatus';
import { useAuth } from '../../../auth';
import { handleDownload } from '../../../helper/handleDownload';
import { PaymentStatus } from '../PaymentStatus';
import RoundTrip from '../FlightTabs/RoundTrip';

export const FlightOrderView = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { getOrderById, statusChange, downloadInvoice } = useContext(FlightContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [docLoad, setDocLoad] = useState<boolean>(false);
  const [paymentStatus, setPaymentStatus] = useState<boolean>(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
  const [isStatusUpdateOpen, setIsStatusUpdateOpen] = useState<boolean>(false);
  const params = useParams<{ id: any }>();
  const [selected, setSelected] = useState<string>('all');
  const [flightData, setFlightData] = useState<any>();
  let onward = flightData?.flights?.find((onward: any) => onward?.departureType === 'ONWARD');
  let onwardDeparture = onward?.segments?.find((segment: any) => segment?.segmentId === 1);
  let onwardArrival = onward?.segments?.find(
    (segment: any) => segment?.segmentId === onward?.segments?.length
  );
  let returnData = flightData?.flights?.find((obj: any) => obj?.departureType === 'RETURN');
  let returnArrival = returnData?.segments?.find(
    (segment: any) => segment?.segmentId === onward?.segments?.length + 1
  );
  let returnDeparture = returnData?.segments?.find(
    (segment: any) => segment?.segmentId === returnData?.segments?.length + onward?.segments?.length
  );

  let flightList = () => {
    getOrderById(params ? params?.id : null, res => {
      setFlightData(res);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    flightList();
  }, [statusChange]);

  useEffect(() => {
    if (window.location.pathname?.includes('payment-summary')) {
      setPaymentStatus(true);
    }
  }, []);

  const membersCount = flightData?.travellers?.length;
  const travelDuration: any =
    flightData?.flights &&
    getFormattedDateRange(onwardDeparture?.departure?.date, returnDeparture?.arrival?.date);
  let memberName = '';
  if (membersCount > 0) {
    memberName =
      flightData?.travellers?.[0]?.firstName + ' ' + flightData?.travellers?.[0]?.lastName;
  }

  const allMemberNames = flightData?.travellers
    ?.filter((_: any, index: number) => index !== 0)
    .map(
      (member: { firstName: string; lastName: string }) => member.firstName + ' ' + member.lastName
    );
  const overlayTriggerProps: Omit<OverlayTriggerProps, 'children'> = {
    trigger: ['hover', 'focus'],
    placement: 'bottom',
    overlay: (
      <Popover id='popover-trigger-hover-focus' className='p-3' title='Popover bottom'>
        {allMemberNames?.join(', ')}
      </Popover>
    )
  };
  const renderText = (text: string, width: number) => (
    <OATypography
      variant='body2'
      color={theme => theme?.palette?.grey?.[700]}
      isLoading={isLoading}
      skeletonProps={{ height: 24, width }}>
      {text}
    </OATypography>
  );

  const handleBack = () => {
    navigate(`/apps/flights`);
  };

  const getClassNames = (pathValue: string) => {
    return classNames('nav-link text-active-primary me-4', {
      active: location?.pathname?.includes(pathValue)
    });
  };

  const handleClick = (link: string) => {
    if (link === 'payment-summary') {
      setPaymentStatus(true);
    } else {
      setPaymentStatus(false);
    }
  };
  const getListItem = (
    title: string,
    to: string,
    icon?: JSX.Element,
    iconPosition?: 'pre' | 'post'
  ) => (
    <li className='nav-item' onClick={() => handleClick(to)}>
      <Link to={`${to}${location?.search}`} className={getClassNames(to)}>
        {icon && iconPosition === 'pre' && icon}
        <span>{title}</span>
        {icon && iconPosition === 'post' && icon}
      </Link>
    </li>
  );

  const statusObj: any = FLIGHT_STATUS?.find(
    (obj: any) => obj?.value === flightData?.bookingStatus
  );
  const isBookingValid = ['BOOKING_FAILED'].includes(flightData?.bookingStatus) === false;
  const handleRecieptDownload = () => {
    setDocLoad(true);
    downloadInvoice(params ? params?.id : null, res => {
      if (res?.invoice) {
        handleDownload(res?.invoice?.url);
      }
      setDocLoad(false);
    });
  };
  return (
    <>
      <Routes>
        <Route
          element={
            <>
              <Row className='order-view'>
                <Col md='12' className='mb-4'>
                  <Card>
                    <Box display='flex' justifyContent='space-between' padding='16px 16px 0px 16px'>
                      <Box
                        display='flex'
                        alignItems='center'
                        sx={{ cursor: 'pointer' }}
                        onClick={handleBack}>
                        <IconButton aria-label='go back' size='medium'>
                          <ArrowBackIcon />
                        </IconButton>
                        <OATypography variant='subtitle1'>
                          Back to flight order managment
                        </OATypography>
                      </Box>
                      {flightData?.bookingStatus !== 'ITINERARY' && (
                        <Box display='flex' gap='1rem' alignItems='center'>
                          <Dropdown align='end'>
                            <Dropdown.Toggle className='btn-sm btn-secondary'>
                              Manage order
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {flightData?.bookingStatus !== 'CANCELLED' && (
                                <CustomDropdownItem
                                  key='cancel_flight'
                                  label='Cancel flight'
                                  error
                                  handler={() => setIsCancelModalOpen(true)}
                                />
                              )}
                              {/* <CustomDropdownItem
                              key='date_change'
                              label='Date Change'
                              handler={() => }
                            /> */}
                              {
                                <CustomDropdownItem
                                  key='update_status'
                                  label='Update Status'
                                  handler={() => setIsStatusUpdateOpen(true)}
                                />
                              }
                            </Dropdown.Menu>
                          </Dropdown>
                          {isBookingValid && (
                            <OAButton
                              label={`Download tax receipt`}
                              onClick={handleRecieptDownload}
                              isLoading={docLoad}
                              loadingText='downloading...'
                              className='btn btn-light-primary'
                            />
                          )}
                          {isBookingValid && (
                            <OAButton
                              label='Download eticket'
                              onClick={() =>
                                handleDownload(flightData?.downloadAttachment?.eticket?.url)
                              }
                            />
                          )}
                        </Box>
                      )}
                    </Box>
                    <Card.Body className='py-0'>
                      {flightData?.flights?.length > 1 ? (
                        <Box display='flex' alignItems='center' margin='24px 0px 8px 0px' gap='8px'>
                          {isLoading ? (
                            <Skeleton variant='text' width={40} height={20} />
                          ) : (
                            <OATypography variant='h2'>
                              {onwardDeparture?.departure?.airport?.city}
                            </OATypography>
                          )}
                          <DoubleArrowIcon />
                          {isLoading ? (
                            <Skeleton variant='text' width={50} height={20} />
                          ) : (
                            <OATypography variant='h2'>
                              {returnArrival?.departure?.airport?.city}
                            </OATypography>
                          )}
                          {isLoading ? (
                            <Skeleton variant='text' width={50} height={20} />
                          ) : (
                            <OAStatusChip
                              status={statusObj?.value}
                              label={statusObj?.label}
                              variant={statusObj?.type}
                            />
                          )}
                        </Box>
                      ) : (
                        <Box display='flex' margin='24px 0px 8px 0px' gap='8px'>
                          {isLoading ? (
                            <Skeleton variant='text' width={40} height={20} />
                          ) : (
                            <OATypography variant='h2'>
                              {onwardDeparture?.departure?.airport?.city}
                            </OATypography>
                          )}
                          <SourceDestIcon style={{ marginTop: '8px' }} />
                          {isLoading ? (
                            <Skeleton variant='text' width={50} height={20} />
                          ) : (
                            <OATypography variant='h2'>
                              {onwardArrival?.arrival?.airport?.city}
                            </OATypography>
                          )}
                          {isLoading ? (
                            <Skeleton variant='text' width={50} height={20} />
                          ) : (
                            <OAStatusChip
                              status={statusObj?.value}
                              label={statusObj?.label}
                              variant={statusObj?.type}
                            />
                          )}
                        </Box>
                      )}
                      <Box display='flex' flexDirection='column' gap='8px'>
                        <Box display='flex' alignItems='center' marginBottom='10px'>
                          {flightData?.bookingId ? renderText(flightData?.bookingId, 90) : null}
                          {flightData?.bookingId && travelDuration && (
                            <DotSeparatorIcon style={{ margin: '0 6px' }} />
                          )}{' '}
                          <Box display='flex' alignItems='center' gap='4px'>
                            <CalenderIcon />
                            {travelDuration ? renderText(travelDuration, 186) : null}
                          </Box>
                          <DotSeparatorIcon style={{ margin: '0 6px' }} />
                          <Box display='flex' alignItems='center' gap='4px'>
                            {flightData?.flightSearchInfo?.fareGroup?.toLowerCase() ===
                            'student' ? (
                              <StudentFareIcon />
                            ) : (
                              <RegularFareIcon />
                            )}
                            {flightData
                              ? renderText(
                                  flightData?.flightSearchInfo?.fareGroup?.toLowerCase() ===
                                    'student'
                                    ? 'Student fare'
                                    : 'Regular fare',
                                  90
                                )
                              : null}
                          </Box>
                          {allMemberNames?.length > 0 && (
                            <DotSeparatorIcon style={{ margin: '0 6px' }} />
                          )}
                          {allMemberNames?.length > 0 && (
                            <Box display='flex' alignItems='center' gap='4px'>
                              <MembersIcon />
                              <Box display='flex' alignItems='center' gap='4px'>
                                {flightData ? renderText(memberName, 120) : null}
                                <OverlayTrigger {...overlayTriggerProps}>
                                  <Box
                                    component='u'
                                    sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                                    <OATypography
                                      variant='body2'
                                      color='primary'
                                      noWrap
                                      isLoading={isLoading}
                                      skeletonProps={{ height: 24, width: 60 }}>
                                      +{membersCount - 1} more
                                    </OATypography>
                                  </Box>
                                </OverlayTrigger>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </Box>
                      <div
                        id='orderTabForScroll'
                        className={classNames('d-flex overflow-auto', 'order-view--tabs')}>
                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-bolder flex-nowrap'>
                          {getListItem('Activity log', 'activity-log')}
                          {getListItem('Travel details', 'travel-details')}
                          {flightData?.travellers?.length > 0 &&
                            getListItem('Traveller info', 'traveller-info')}
                          {flightData?.bookingStatus !== 'ITINERARY' &&
                            getListItem('Payment summary', 'payment-summary')}
                        </ul>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md='8'>
                  <Routes>
                    <Route path='/' element={<Navigate to='activity-log' replace />} />
                    <Route
                      path='activity-log'
                      element={
                        flightData ? (
                          <ActivityLogs data={flightData} />
                        ) : (
                          <>
                            <Skeleton
                              variant='rounded'
                              width='100%'
                              height={24}
                              sx={{ mb: '26px' }}
                            />
                            <Skeleton
                              variant='rounded'
                              width='100%'
                              height={24}
                              sx={{ mb: '26px' }}
                            />
                            <Skeleton
                              variant='rounded'
                              width='100%'
                              height={24}
                              sx={{ mb: '26px' }}
                            />
                            <Skeleton
                              variant='rounded'
                              width='100%'
                              height={24}
                              sx={{ mb: '26px' }}
                            />
                            <Skeleton
                              variant='rounded'
                              width='100%'
                              height={24}
                              sx={{ mb: '26px' }}
                            />
                          </>
                        )
                      }
                    />
                    <Route path='travel-details' element={<RoundTrip data={flightData} />} />
                    {flightData?.travellers?.length > 0 && (
                      <Route
                        path='traveller-info'
                        element={
                          <TravellerInfo
                            data={flightData}
                            handleSelected={val => setSelected(val)}
                            selected={selected}
                          />
                        }
                      />
                    )}
                    <Route
                      path='payment-summary'
                      element={<PaymentSummary data={flightData} modalOpen={isCancelModalOpen} />}
                    />
                  </Routes>
                </Col>
                <Col md='4'>
                  {!paymentStatus && (
                    <CommentsAndTasks
                      orderData={flightData}
                      currentUser={currentUser?.data}
                      isFlight={true}
                    />
                  )}
                  {paymentStatus && <PaymentStatus data={flightData} />}{' '}
                </Col>
              </Row>
              {/**
               * DEVNOTE: keep all modal component at end for better readability...
               */}
            </>
          }>
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
      <FlightOrderStatus
        isOpen={isStatusUpdateOpen}
        onHide={() => setIsStatusUpdateOpen(false)}
        data={flightData}
      />
      {flightData?.bookingStatus !== 'ITINERARY' && (
        <CancelFlightBooking
          setIsCancelModalOpen={setIsCancelModalOpen}
          isCancelModalOpen={isCancelModalOpen}
          data={flightData}
        />
      )}
    </>
  );
};
