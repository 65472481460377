import { useState } from 'react';

import { getDateFormat, getFormattedDate, showToast } from '../../../helper';
import API from '../../../helper/API';
import { DATE_FORMAT_BE, OA_DATE_FORMAT_BE } from '../../../constants';
import { IFlightProvider, IFlightQueryParams } from '../type';
import FlightContext, { IFlightContext } from './FlightContext';
import { IPageInfo } from '../../../components/OATableComponent';
import FileDownload from 'js-file-download';
import { flightFilterNavs } from '../../order-management/constants';

const FlightProvider = (props: IFlightProvider) => {
  const { queryParams } = props?.data;
  const [statusChange, setStatusChange] = useState<boolean>(false);
  const [report, setReport] = useState<{ isLoading: boolean }>({ isLoading: false });
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    totalCount: 0,
    totalPages: 0,
    count: 0,
    size: 10,
    index: 1
  });
  const getFlightOrderList = async (cb?: (response?: any) => void) => {
    try {
      API.get(`/flight/order/dashboard?${buildQueryParams(queryParams)}`)
        .then((response: any) => {
          const resultData = response?.data?.data;
          setPageInfo(prev =>
            Object.assign({}, prev, {
              totalCount: resultData?.totalElements,
              totalPages: resultData?.totalPages,
              count: resultData?.numberOfElements
            })
          );
          cb?.(resultData);
        })
        .catch(err => {
          cb?.(err);
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const getOrderById = async (id: string, cb?: (response?: any) => void) => {
    try {
      API.get(`/flight/order/${id}`)
        .then((response: any) => {
          const resultData = response?.data?.data;
          // showToast('Order fetched successfully', 'success');
          cb?.(resultData);
        })
        .catch(err => {
          showToast(err?.message, 'error');
          cb?.(err);
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      showToast(error?.message, 'error');
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const getCancelReasons = async (id: string, cb?: (response?: any) => void) => {
    try {
      API.get(`/flight/order/cancel/info/${id}`)
        .then((response: any) => {
          const resultData = response?.data?.data;
          // showToast('Order fetched successfully', 'success');
          cb?.(resultData);
        })
        .catch(err => {
          showToast(err?.message, 'error');
          cb?.(err);
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      showToast(error?.message, 'error');
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const cancelFlightsBooking = async (
    id: string,
    requestbody: any,
    cb?: (response?: any) => void
  ) => {
    setStatusChange(false);
    try {
      API.post(`/flight/order/cancel/${id}`, requestbody)
        .then((response: any) => {
          setStatusChange(true);
          const resultData = response?.data?.data;
          showToast('Flight cancelled successfully', 'success');
          cb?.(resultData);
        })
        .catch(err => {
          setStatusChange(false);
          showToast(err?.message, 'error');
          cb?.(err);
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      showToast(error?.message, 'error');
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const updatePNR = async (draft: any, queryDraft: any, cb?: (response?: any) => void) => {
    setStatusChange(false);
    try {
      API.put(`/flight/order/update/${queryDraft?.id}?flightTripId=${queryDraft?.tripId}`, draft)
        .then((response: any) => {
          setStatusChange(true);
          const resultData = response?.data?.data;
          showToast('PNR updated successfully', 'success');
          cb?.(resultData);
        })
        .catch(err => {
          setStatusChange(false);
          showToast(err?.message, 'error');
          cb?.(err);
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      showToast(error?.message, 'error');
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const updateStatus = async (draft: any, cb?: (response?: any) => void) => {
    setStatusChange(false);
    try {
      API.put(`/flight/order/update/${draft?.id}?status=${draft?.status}`)
        .then((response: any) => {
          setStatusChange(true);
          const resultData = response?.data?.data;
          showToast('Status updated successfully', 'success');
          cb?.(resultData);
        })
        .catch(err => {
          setStatusChange(false);
          showToast(err?.message, 'error');
          cb?.(err);
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      showToast(error?.message, 'error');
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const downloadReport = () => {
    try {
      setReport(prev => ({ ...prev, isLoading: true }));
      const APITemp: any = API;
      APITemp.responseType = 'blob';
      APITemp.get(`/flight/order/report?${buildQueryParamsReport(queryParams)}`)
        .then((response: any) => {
          showToast('Report generated!', 'success');
          setReport(prev => ({ ...prev, isLoading: false }));
          FileDownload(response?.data, 'report.csv');
        })
        .catch((err: any) => {
          setReport(prev => ({ ...prev, isLoading: false }));
          showToast(err?.message, 'error');
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const downloadInvoice = async (orderID: any, cb?: (response?: any) => void) => {
    try {
      API.get(`/flight/order/invoice/${orderID}`)
        .then((response: any) => {
          const resultData = response?.data?.data;
          showToast('Invoice downloaded successfully', 'success');
          cb?.(resultData);
        })
        .catch(err => {
          showToast(err?.message, 'error');
          cb?.(err);
          throw new Error(`API error:${err?.message}`);
        });
    } catch (error: any) {
      showToast(error?.message, 'error');
      cb?.(error);
      throw new Error(`API error: ${error?.message}`);
    }
  };

  const contextValue: IFlightContext = {
    getFlightOrderList,
    getOrderById,
    getCancelReasons,
    cancelFlightsBooking,
    updatePNR,
    updateStatus,
    downloadReport,
    downloadInvoice,
    report,
    pageInfo,
    statusChange
  };

  return <FlightContext.Provider value={contextValue}>{props?.children}</FlightContext.Provider>;
};

export default FlightProvider;

const buildQueryParams = (queryParams: IFlightQueryParams): string => {
  let queryStr = `page=${queryParams?.pageIndex - 1}&size=10`;
  if (queryParams?.search && queryParams?.search !== '') {
    queryStr = `${queryStr}&search=${queryParams?.search}`;
  }
  if (queryParams?.dateRange.length > 0) {
    const startDate = queryParams?.dateRange[0]
      ? getFormattedDate(queryParams?.dateRange[0], OA_DATE_FORMAT_BE)
      : null;
    const endDate = queryParams?.dateRange[1]
      ? getFormattedDate(queryParams?.dateRange[1], OA_DATE_FORMAT_BE)
      : null;
    queryStr = `${queryStr}&start=${startDate}&end=${endDate}`;
  }

  if (queryParams?.sortBy && queryParams?.sortByField) {
    queryStr = `${queryStr}&sortByField=${queryParams?.sortByField}&sortBy=${queryParams?.sortBy}`;
  }
  if (queryParams?.orderStatus && queryParams?.orderStatus !== '') {
    let ifFound = flightFilterNavs?.find((obj: any) => obj === queryParams?.orderStatus);
    queryStr = ifFound
      ? queryParams?.orderStatus === 'ORDER_DRAFT' || queryParams?.orderStatus === ''
        ? queryStr
        : `${queryStr}&status=${queryParams?.orderStatus}`
      : `${queryStr}`;
  }
  return queryStr;
};

const buildQueryParamsReport = (queryParams: IFlightQueryParams): string => {
  const startDate = getDateFormat(queryParams?.dateRange?.[0] as Date, DATE_FORMAT_BE);
  const endDate = getDateFormat(queryParams?.dateRange?.[1] as Date, DATE_FORMAT_BE);
  let queryStr = `start=${startDate}&end=${endDate}`;
  return queryStr;
};
