import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { DateRange } from '@mui/x-date-pickers-pro';
import moment, { Moment } from 'moment';
import Select from 'react-select';

import { ORDER_TYPE, OccupationOptions } from '../../../constants';
import { useOrderContext } from '../../../context/OrderContext';
import OARangePicker from '../../../../../components/OARangePicker';
import { stateAbbr } from '../../../../../helper';
import OAButton from '../../../../../components/widgets/OAButton';
import { IOrderType } from '../../../types';
import { Paper } from '@mui/material';
import { ILabelValuePair } from '../../../../../types';

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    'fontSize': '0.925rem',

    'borderColor': state.isFocused ? 'red' : provided.borderColor,

    '&:hover': {
      borderColor: '#f5f8fa'
    }
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    height: '30px',
    width: '30px',
    padding: '5px'
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontSize: '0.925rem',
    color: '#B5B5C2'
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '0.925rem',
    color: '#616876',
    backgroundColor: state.isSelected ? '#f0f0f0' : provided.backgroundColor
  })
};

interface StateAbbr {
  [key: string]: string;
}

interface IAddTripDetailsProps {
  onSubmit: (values: any, type: string) => void;
  errors: any;
  maxProcessingTime: number;
  handleErrors?: (name: string, err: string | null) => void;
}

const initRange = {
  from: null,
  to: null
};

type ParamsType = {
  orderType: IOrderType;
};

export default function AddTripDetails(props: IAddTripDetailsProps) {
  const { orderType } = useParams<ParamsType>();

  const { formState, onAddConsulate, onCreateGroup, updateFormState } = useOrderContext();

  const [values, setValues] = useState<{ from: Date | null; to?: Date | null }>(initRange);

  useEffect(() => {
    if (formState?.tripDuration?.from && formState?.tripDuration?.to) {
      const { from, to } = formState?.tripDuration;
      setValues({ from, to });
    } else {
      setValues(initRange);
    }
  }, [formState?.tripDuration]);

  const onChangeRangePicker = (range: DateRange<Moment | Date>) => {
    updateFormState({
      tripDuration: {
        from: range?.[0],
        to: range?.[1]
      }
    });

    props?.handleErrors?.('tripDuration', null);
    props?.handleErrors?.('maxProcessingTime', null);
    props?.handleErrors?.('wrongDates', null);
    setValues({ from: range?.[0] as Date, to: range?.[1] as Date });
  };

  const consulateDataArr = formState?.tripDetails?.productInformation?.consulateData?.length
    ? formState?.tripDetails?.productInformation?.consulateData?.map((obj: any) => ({
        label: obj?.label,
        value: obj?.value,
        description: obj?.allowedStates
      }))
    : [];
  /**
   * NOTE: if consulateDataArr contains only default option, then pre select
   * default to avoid unnecessary interaction...
   */
  useEffect(() => {
    if (consulateDataArr?.length === 1 && !formState?.consulate) {
      const draftConsulate = consulateDataArr?.[0]?.value;
      onAddConsulate(draftConsulate);
    }
  }, [consulateDataArr, formState?.consulate]);

  const handleConsulateChange = (selectedOption: any) => {
    onAddConsulate(selectedOption?.value);
    props?.handleErrors?.('consulate', null);
  };

  return (
    <Paper variant='outlined' className='add-trip-details w-100 p-6 mb-6'>
      <Row className='mb-0'>
        {formState?.quotationId && (
          <Col md={formState?.groupName ? 5 : 6}>
            <label htmlFor='tripDuration' className='form-label required'>
              Travel Duration
            </label>
            <OARangePicker
              name='add-trip-details-data-range'
              onChangeRangePicker={onChangeRangePicker}
              dateRange={[values?.from as Date, values?.to as Date]}
              minDate={moment().add(props?.maxProcessingTime + 1, 'days')} // DEVNOTE: excluding cur day
              block
            />
            {props?.errors['tripDuration'] ? (
              <div className='text-danger fw-bold fs-9'>{props?.errors['tripDuration']}</div>
            ) : null}
            {props?.errors['maxProcessingTime'] ? (
              <div className='text-danger fw-bold fs-9'>{props?.errors['maxProcessingTime']}</div>
            ) : null}
            {props?.errors['wrongDates'] ? (
              <div className='text-danger fw-bold fs-9'>{props?.errors['wrongDates']}</div>
            ) : null}
          </Col>
        )}

        {consulateDataArr?.length > 0 && !formState?.groupName && (
          <Col md={formState?.groupName ? 5 : 6}>
            <label htmlFor='consulate' className='form-label required'>
              Consulate
            </label>
            <Select
              options={consulateDataArr}
              onChange={handleConsulateChange}
              className='oa-select-container'
              classNamePrefix='oa-select'
              value={
                formState?.consulate
                  ? consulateDataArr?.find((opt: any) => opt?.value === formState?.consulate)
                  : ''
              }
              formatOptionLabel={formatOptionLabel}
              menuPlacement='bottom'
              styles={customStyles}
            />
            {props?.errors['consulate'] ? (
              <div className='text-danger fw-bold fs-9'>{props?.errors['consulate']}</div>
            ) : null}
          </Col>
        )}
        {orderType === ORDER_TYPE.VISA && formState?.quotation ? (
          <Col md={formState?.groupName ? 5 : 6}>
            <label htmlFor='occupation' className='form-label required'>
              Occupation
            </label>
            <Select
              options={OccupationOptions}
              onChange={(selectedOption: any) => {
                updateFormState({ occupation: selectedOption?.value });
                props?.handleErrors?.('occupation', null);
              }}
              className='oa-select-container'
              classNamePrefix='oa-select'
              value={
                (formState as any)?.occupation
                  ? OccupationOptions?.find(
                      (opt: ILabelValuePair) => opt?.value === (formState as any)?.occupation
                    )
                  : ''
              }
              menuPlacement='bottom'
              styles={customStyles}
            />
            {props?.errors['occupation'] ? (
              <div className='text-danger fw-bold fs-9'>{props?.errors['occupation']}</div>
            ) : null}
          </Col>
        ) : null}
        {formState?.groupName && (
          <Col md='2' className='d-flex align-items-end pe-0'>
            <OAButton
              label='Create Group'
              className='px-5 w-100'
              onClick={() => onCreateGroup('visa')}
              disabled={!formState?.tripDuration?.from && !formState?.tripDuration?.to}
            />
          </Col>
        )}
      </Row>
    </Paper>
  );
}

const getFullStateNames = (abbrArray: string[]): string[] => {
  const abbrToState: { [key: string]: string } = {};
  for (const key in stateAbbr) {
    abbrToState[stateAbbr[key]] = key;
  }
  return abbrArray.map(abbr => abbrToState[abbr] ?? '');
};

export const formatOptionLabel = (option: any, meta: any) => {
  stateAbbr;
  if (meta.context === 'menu') {
    return (
      <div>
        <p className='mb-0 fs-7 text-dark fw-bold'>{option.label}</p>
        <p className='mb-0 fs-7 text-muted'>
          Jurisdiction includes{' '}
          {option.description ? getFullStateNames(option.description).join(', ') : 'all state'}
        </p>
      </div>
    );
  } else {
    return option.label;
  }
};
