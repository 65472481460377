import React, { useEffect, useRef } from 'react';

import { ErrorMessage, useField, useFormikContext } from 'formik';
import Select, { ActionMeta, OptionProps } from 'react-select';
import classNames from 'classnames';
import { theme } from '../../config/theme';

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    'fontSize': '0.925rem',
    'borderColor': state.isFocused ? theme.palette.primary.light : provided.borderColor,
    '&:hover': {
      borderColor: '#f5f8fa'
    }
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    height: '30px',
    width: '30px',
    padding: '5px'
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontSize: '0.925rem',
    color: '#B5B5C2'
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '0.925rem',
    color: '#616876',
    backgroundColor: state.isSelected ? '#f0f0f0' : provided.backgroundColor
  })
};

export default function OASelectField(props: any) {
  const {
    isLoading = false,
    loadingMessage,
    label,
    options,
    required,
    valueOnly,
    className = 'oa-select-container',
    placeholder,
    isClearable = false,
    isDisabled = false,
    wrapperClass,
    onChangeData,
    formatOptionLabel,
    onMenuScrollToBottom,
    placement = 'bottom',
    ...rest
  } = props;

  const [field, meta, { setValue, setTouched }] = useField(rest);

  const onChange = (option: any, actionMeta: ActionMeta<OptionProps>) => {
    setValue(valueOnly ? option?.value : option);
    if (onChangeData) {
      onChangeData(valueOnly ? option?.value : option);
    }
  };

  const labelClassNames = classNames('form-label', {
    required: required
  });

  const formikBag = useFormikContext();
  const fieldRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const firstError = Object.keys(formikBag.errors)[0];
    if (formikBag.isSubmitting && firstError === rest.name) {
      fieldRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [meta.error, formikBag.isSubmitting, rest.name, formikBag.errors]);

  return (
    <React.Fragment>
      <label htmlFor={rest?.id || rest?.name} className={labelClassNames}>
        {label}
      </label>
      <div ref={fieldRef}>
        <Select
          defaultValue={options?.find((option: any) => option?.value === field?.value)}
          options={options}
          isLoading={isLoading}
          onChange={onChange}
          placeholder={placeholder}
          isClearable={isClearable}
          isDisabled={isDisabled}
          className={className}
          classNamePrefix='oa-select'
          value={field?.value ? options?.find((opt: any) => opt?.value === field?.value) : ''}
          formatOptionLabel={formatOptionLabel ? formatOptionLabel : null}
          onMenuScrollToBottom={onMenuScrollToBottom}
          menuPlacement={placement}
          styles={customStyles}
        />
        {/* TODO: check if this field has been touched or not. don't show error message
         *  unless form has been submitted. */}
        {/**
         * NOTE: Show error message in case of any.
         *
         */}
        <div className='text-danger fw-bold fs-9'>
          <ErrorMessage name={rest?.name} />
        </div>
      </div>
    </React.Fragment>
  );
}
