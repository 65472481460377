import DynamicField from './DynamicField';

export interface FieldData {
  fieldName: string;
  formState?: any;
  label: string;
  fieldType: 'select' | 'text' | 'date' | 'phone'; // TODO: add more types
  options?: Array<any>;
  colWidth?: number;
  params?: { [key: string]: any };
  inputClassName?: string;
  required?: boolean;
  [x: string]: any;
}

export interface IFields {
  required?: string[];
  optional?: string[];
}

export const renderFormFields = (
  fieldsData: FieldData[],
  fields: IFields = { required: [], optional: [] }
) => {
  let fieldsToBeShown = false;

  const draftFields = fieldsData?.map(field => {
    const isFieldRequired = fields?.required?.includes(field?.fieldName);
    const isFieldOptional = fields?.optional?.includes(field?.fieldName);

    if (isFieldRequired || isFieldOptional || field?.fieldName === 'consulate') {
      fieldsToBeShown = true;
    }

    return <DynamicField fields={fields} required={isFieldRequired} {...field} />;
  });

  return fieldsToBeShown ? draftFields : null;
};
