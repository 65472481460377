import { KTSVG } from '../../_metronic/helpers';
import { OATypography } from '../widgets';

interface IOANoData {
  icon: string;
  title?: string;
  svgClassName?: string;
}

const OANoData: React.FC<IOANoData> = ({ icon, title = 'No Data', svgClassName }) => {
  return (
    <div className='w-100 text-center pb-20 pt-5'>
      <KTSVG svgClassName={svgClassName} className='svg-icon svg-icon-2x mb-2' path={icon} />
      <OATypography variant='body2' sx={{ mt: 2 }}>
        {title}
      </OATypography>
    </div>
  );
};

export default OANoData;
