import { Form, Formik, FormikProps } from 'formik';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { filter, includes } from 'lodash';

import { useVisaInformationContext } from '../context/VIsaInformationContext';
import { ISendInformationEmail, sendInformationEmail } from '../../../types/IVisaInformation';
import OAButton from '../../../components/widgets/OAButton';
import OATagsBasedInput from '../../../components/OATagsBasedInput';
import { OATypography } from '../../../components/widgets';
import { IDocument } from '../../../types';
import OATableComponent, { GridColumnHeader } from '../../../components/OATableComponent';
import { Box, Checkbox, Tab, Tabs } from '@mui/material';
import { DefaultCell } from '../../../components/OAColumn';
import { useEffect, useState } from 'react';
import OAAccordionConfig from '../../../components/OAVisaSummary/helper';
import { OATextField } from '../../../components/fields';

interface ISendInfoModalProps {
  isOpen: boolean;
  toggle: (val?: boolean) => void;
  selectedVisaIndex: any;
  selectedConsulate: any;
  destination: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const validateEmailSchema = (values: any, activeTabIndex: number) => {
  const errors: any = {};
  if (activeTabIndex === 0) {
    if (values?.emails === '') {
      errors.emails = 'Please provide email address';
    }
    if (values?.emails !== '') {
      let emails = values?.emails?.toString().split(',');
      var valid = true;
      var regex =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      for (var i = 0; i < emails?.length; i++) {
        if (emails[i] === '' || !regex.test(emails[i].trim())) valid = false;
      }

      emails = emails.map((email: any) => email.trim());
      let duplicates = filter(emails, (value, index, iteratee) =>
        includes(iteratee, value, parseInt(index) + 1)
      );
      if (!valid) errors.emails = 'Please write emails separated by comma and add a valid email';
      if (duplicates.length > 0) errors.emails = 'The email list contains non-unique emails';
    }
  }

  if (activeTabIndex === 1) {
    if (!values?.phone) {
      errors.phone = 'Please provide a phone number';
    } else {
      var phoneRegex = /^\d{10}$/;
      if (!phoneRegex.test(values.phone.trim())) {
        errors.phone = 'Please enter a valid 10-digit phone number';
      }
    }
  }

  return errors;
};

const createWhatsAppLink = (phone: string, documentUrl: string, destination: string) => {
  const message = encodeURIComponent(
    `Hey, here is all the visa information you need for your trip to ${destination}. Please check the document here: ${documentUrl}`
  );
  return `https://api.whatsapp.com/send?phone=${phone}&text=${message}`;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ py: 2 }}>
          <OATypography>{children}</OATypography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    'id': `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

const SendInfoModal = (props: ISendInfoModalProps) => {
  const [value, setValue] = useState<number>(0);
  const [isDownloading, setIsVisaDownloading] = useState<boolean>(false);

  const { products, sendVisaInformation, visaInformationValues } = useVisaInformationContext();
  const selectedVisaInformation = products?.data?.[props?.selectedVisaIndex];

  const selectedConsulate = selectedVisaInformation?.consulateData?.find(
    (obj: any) => obj?.value === props?.selectedConsulate
  );

  const requiredVisaForms = selectedConsulate?.requiredVisaForms;

  const requiredDocuments = selectedConsulate?.requiredDocuments;
  let requiredDocs = [];
  let groupByApplicableFor: any = [];

  if (requiredDocuments?.length > 0) {
    requiredDocs = requiredDocuments?.filter((doc: IDocument) => doc?.required);

    groupByApplicableFor = requiredDocuments?.reduce((acc: any, doc: any) => {
      if (doc?.applicableFor) {
        doc.applicableFor?.forEach((applicable: string) => {
          if (!acc[applicable]) {
            acc[applicable] = [];
          }
          acc[applicable]?.push(doc);
        });
      }
      return acc;
    }, {});
  }

  let accordianParams = {
    selectedConsulate,
    selectedVisaInformation,
    requiredVisaForms,
    requiredDocs,
    groupByApplicableFor
  };
  let accordionConfig = OAAccordionConfig(accordianParams);
  let accData = accordionConfig?.filter(
    (item: any) => item?.check !== false && item?.check !== undefined
  );

  useEffect(() => {
    setSelectedFields(accData?.map((item: any) => item?.header));
  }, [selectedVisaInformation, props?.isOpen]);

  const handleCheckboxChange = (value: string) => {
    setSelectedFields(prev => {
      if (prev.includes(value)) {
        return prev.filter(field => field !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  const tableColumns = (data: any) => [
    {
      field: 'action',
      renderHeader: (params: any) => <GridColumnHeader title='Action' params={params?.value} />,
      renderCell: (params: any) => (
        <Checkbox
          checked={selectedFields?.includes(params?.row?.header)}
          onChange={() => handleCheckboxChange(params?.row?.header)}
        />
      ),
      valueGetter: (params: any) => params?.row?.id
    },
    {
      field: 'item',
      renderHeader: (params: any) => <GridColumnHeader title='Item' params={params?.value} />,
      renderCell: (params: any) => <DefaultCell data={params?.value} />,
      valueGetter: (params: any) => params?.row?.header,
      flex: 1,
      width: 150
    }
  ];

  const [selectedFields, setSelectedFields] = useState<string[]>([]);

  const columns: any = tableColumns([]);

  const handleSendVisaInfo = (values: any, shouldDownload: boolean) => {
    if (shouldDownload) {
      setIsVisaDownloading(true);
    }
    const draft = Object.assign({}, values, visaInformationValues, {
      visa: selectedVisaInformation,
      consulate: props?.selectedConsulate,
      save: true,
      selectedFields: selectedFields,
      downloadFile: shouldDownload
    });

    sendVisaInformation(draft, (res: any) => {
      if (res && values?.phone) {
        const whatsappUrl = createWhatsAppLink(values.phone, res, props?.destination);
        window.open(whatsappUrl, '_blank');
        props?.toggle();
        setIsVisaDownloading(false);
      } else {
        props?.toggle();
        setIsVisaDownloading(false);
      }
    });
  };

  return (
    <Modal show={props?.isOpen} backdrop='static' centered>
      <Modal.Header
        closeButton
        onClick={() => {
          props?.toggle();
          setSelectedFields([]);
        }}>
        <Modal.Title>Send information to share</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={sendInformationEmail}
          validate={values => validateEmailSchema(values, value)}
          onSubmit={() => {}}>
          {(formikProps: FormikProps<ISendInformationEmail>) => {
            const handleCancel = () => {
              formikProps?.resetForm();
              props?.toggle();
            };

            const handleOnChange = (e: any, newInputValue: string) => {
              formikProps?.setFieldValue('emails', newInputValue);
            };

            const handleChange = (event: React.SyntheticEvent, newValue: number) => {
              setValue(newValue);
              formikProps?.setFieldValue('phone', '');
              formikProps?.setFieldValue('emails', '');
            };

            return (
              <Container fluid as={Form} className='p-0'>
                <Row>
                  <OATypography variant='body2' className='mb-3'>
                    Select from a list of fields you want to send
                  </OATypography>
                  <Box sx={{ maxHeight: 400, width: '100%', overflow: 'auto' }}>
                    <OATableComponent columns={columns} data={accData} getRowId={row => row?.idx} />
                  </Box>
                </Row>
                <Row className='mt-4'>
                  <OATypography variant='h4'>Share via</OATypography>
                  <Tabs
                    variant='scrollable'
                    value={value}
                    onChange={handleChange}
                    sx={{
                      borderRight: 1,
                      borderColor: 'divider'
                    }}>
                    <Tab
                      sx={{
                        padding: '6px 0',
                        alignItems: 'start',
                        textAlign: 'start',
                        textTransform: 'none',
                        fontSize: '14px',
                        lineHeight: '20px'
                      }}
                      label='Email'
                      {...a11yProps(0)}
                    />
                    <Tab
                      sx={{
                        padding: '6px 0',
                        alignItems: 'start',
                        textAlign: 'start',
                        textTransform: 'none',
                        fontSize: '14px',
                        lineHeight: '20px'
                      }}
                      label='Whatsapp'
                      {...a11yProps(1)}
                    />
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    <OATagsBasedInput
                      inputTagId='tags-filled'
                      textFieldName='emails'
                      textFieldPlaceholder='Enter email and press comma (",") to add more'
                      onChange={handleOnChange}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <OATextField
                      name='phone'
                      label='Whatsapp number'
                      placeholder="Enter customer's whatsapp number"
                      required
                      disabled={formikProps?.isSubmitting}
                    />
                  </TabPanel>
                </Row>
                <Row className='mt-6'>
                  <Col className='text-end'>
                    <OAButton
                      label='Cancel'
                      color='secondary'
                      onClick={handleCancel}
                      className='me-5 btn-active-light-primary'
                      disabled={formikProps?.isSubmitting}
                    />
                    <OAButton
                      onClick={() => handleSendVisaInfo(formikProps?.values, true)}
                      label='Download PDF'
                      color='light-primary'
                      className='me-5 btn-active-light-primary'
                      disabled={formikProps?.isSubmitting}
                      isLoading={isDownloading}
                      loadingText='Downloading...'
                    />
                    <OAButton
                      type='submit'
                      label='Send quote'
                      color='primary'
                      onClick={() => handleSendVisaInfo(formikProps?.values, false)}
                      disabled={
                        !(formikProps?.isValid && formikProps?.dirty) ||
                        formikProps?.isSubmitting ||
                        selectedFields?.length === 0
                      }
                      className='ms-auto'
                      isLoading={formikProps?.isSubmitting}
                      loadingText='Sending...'
                    />
                  </Col>
                </Row>
              </Container>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default SendInfoModal;
