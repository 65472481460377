import { Card, Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

import { KTSVG } from '../../../_metronic/helpers';
import { TSummary } from '../type';

interface ISummaryCard {
  data: TSummary;
  isLoading: boolean;
}

const SummaryCardTile = (props: ISummaryCard) => {
  const { data, isLoading } = props;

  return (
    <Card className='h-100'>
      <span className='menu-icon position-absolute top-0 end-0 p-2'>
        <KTSVG path='/media/icons/duotune/Move.svg' className='svg-icon-2' />
      </span>
      <Card.Body>
        <h3 className='mb-5'>{isLoading ? <Skeleton height={21} width={100} /> : data?.title}</h3>
        <Row>
          <SummaryCardCol data={data?.orders} isLoading={isLoading} />
          <SummaryCardCol data={data?.customer} isLoading={isLoading} />
        </Row>
      </Card.Body>
    </Card>
  );
};

export default SummaryCardTile;

const SummaryCardCol = (props: any) => {
  const { data, isLoading } = props;

  return (
    <Col>
      <div className='d-flex align-items-center me-2'>
        <div className='symbol symbol-40px me-3'>
          <div className={`symbol-label bg-light-${data?.color}`}>
            <KTSVG path={data?.icon} className={`svg-icon-1 svg-icon-${data?.color}`} />
          </div>
        </div>
        <div>
          <div className='fs-3 text-dark fw-bold'>
            {isLoading ? <Skeleton height={26} width={26} borderRadius='2rem' /> : data?.count}
          </div>
          <div className='fs-7 text-muted fw-bold'>
            {isLoading ? <Skeleton height={14} width={80} /> : data?.text}
          </div>
        </div>
      </div>
    </Col>
  );
};
