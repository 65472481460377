import { FC } from 'react';
import ReactApexChart from 'react-apexcharts';
import { max } from 'lodash';
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils';

const OaLine: FC<any> = ({ series, categories }) => {
  const labelColor = getCSSVariableValue('--bs-gray-500');
  const borderColor = getCSSVariableValue('--bs-gray-200');
  const baseColor = getCSSVariableValue('--bs-primary');
  const baseLightColor = getCSSVariableValue('--bs-light-primary');
  const secondaryColor = getCSSVariableValue('--bs-info');

  let tickAmount: any = 10;
  if (categories.length > 12) {
    tickAmount = 9;
  } else if (categories.length > 24) {
    tickAmount = Math.round(categories.length / 10);
  }

  const maxArray: Array<any> = [];
  series.forEach((obj: any) => {
    maxArray.push(max(obj.data));
  });

  const options: any = {
    chart: {
      fontFamily: 'inherit',
      height: 300,
      type: 'line',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3
    },
    xaxis: {
      tickAmount: tickAmount,
      categories: categories,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px'
        }
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3
        }
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px'
        }
      }
    },
    yaxis: {
      labels: {
        formatter: function (val: any) {
          if (val) return val.toFixed(0);
          else return 0;
        }
      }
    },
    colors: ['#469CF0', '#6B3CE1'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      hover: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0
        }
      }
    }
  };
  return <ReactApexChart options={options} series={series} type='line' height={'300'} />;
};

export default OaLine;
