import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Field, Form, Formik, FormikProps } from 'formik';
import { Box, Grid } from '@mui/material';
import moment from 'moment';
import * as Yup from 'yup';

import { useOrderContext } from '../../context/OrderContext';
import { ORDER_TYPE, OccupationOptions } from '../../constants';
import { IOrderType } from '../../types';
import { OARangePickerField, OASelectField, OATextField } from '../../../../components/fields';
import OAModalComponent from '../../../../components/OAModalComponent';
import OAButton from '../../../../components/widgets/OAButton';
import { showToast } from '../../../../helper';

interface UpdateQuoteModalProps {
  isOpen: boolean;
  onHide: () => void;
  data: any;
}

export default function UpdateQuoteModal(props: UpdateQuoteModalProps) {
  return (
    <OAModalComponent
      show={props?.isOpen}
      onHide={props?.onHide}
      className=''
      header={{
        title: `Edit travel info`,
        closeButton: true
      }}
      body={{
        content: <QuotationUpdateForm onHide={props?.onHide} data={props?.data} />,
        className: ''
      }}
    />
  );
}

interface QuotationUpdateFormProps {
  onHide: () => void;
  data: any;
}

const QuotationUpdateForm = (props: QuotationUpdateFormProps) => {
  const { orderType, id } = useParams<{ orderType: IOrderType; id: string }>();
  const { formState, onUpdateDraftQuote } = useOrderContext();

  const [inits, setInits] = React.useState<any>({});

  useEffect(() => {
    const { data } = props;
    let draftInit = {
      tripDuration: {
        from: moment(data?.trip?.tripStart?.datetime),
        to: moment(data?.trip?.tripEnd?.datetime)
      },
      consulate: data?.consulate ?? '',
      occupation: data?.occupation ?? '',
      adult: data?.memberCount?.find((obj: any) => obj?.type === 'ADULT')?.count,
      child: data?.memberCount?.find((obj: any) => obj?.type === 'CHILD')?.count,
      senior: data?.memberCount?.find((obj: any) => obj?.type === 'SENIOR')?.count,
      infant: data?.memberCount?.find((obj: any) => obj?.type === 'INFANT')?.count
    };
    if (orderType === ORDER_TYPE.VISA) {
      draftInit['consulate'] = data?.consulate ?? '';
      draftInit['occupation'] = data?.occupation ?? '';
    } else if (orderType === ORDER_TYPE.INSURANCE) {
      draftInit['senior'] = data?.memberCount?.find((obj: any) => obj?.type === 'SENIOR')?.count;
      draftInit['infant'] = data?.memberCount?.find((obj: any) => obj?.type === 'INFANT')?.count;
    }

    setInits(draftInit);
  }, [props?.data]);

  const handleSubmit = (values: any) => {
    const draftValues: any = {
      tripDuration: {
        from: new Date(values?.tripDuration?.from),
        to: new Date(values?.tripDuration?.to)
      },
      consulate: values?.consulate,
      occupation: values?.occupation,
      save: true
    };
    let memberCount = [];
    if (values?.senior) memberCount.push({ type: 'SENIOR', count: values.senior });
    if (values?.adult) memberCount.push({ type: 'ADULT', count: values.adult });
    if (values?.child) memberCount.push({ type: 'CHILD', count: values.child });
    if (values?.infant) memberCount.push({ type: 'INFANT', count: values.infant });

    draftValues['memberCount'] = memberCount;

    onUpdateDraftQuote(id!, orderType as IOrderType, draftValues, (_res: any) => {
      if (_res?.status === 200) {
        showToast('Markup modified successfully', 'success');
      }
      props?.onHide();
    });
  };

  const consulateDataArr = formState?.tripDetails?.productInformation?.consulateData?.length
    ? formState?.tripDetails?.productInformation?.consulateData?.map((obj: any) => ({
        label: obj?.label,
        value: obj?.value,
        description: obj?.allowedStates
      }))
    : [];

  const validateInformationForm = Yup.object({
    adult: Yup.number().min(0, 'invalid').label('count'),
    senior: Yup.number().min(0, 'invalid').label('count'),
    child: Yup.number().min(0, 'invalid').label('count').nullable(true),
    infant: Yup.number().min(0, 'invalid').label('count')
  });

  const validateTravellersForVisa = Yup.object().shape(
    {
      adult: Yup.number().when('child', {
        is: (child: any, adult: any) => !child,
        then: Yup.number().required().label('Adult')
      }),
      child: Yup.number().when('adult', {
        is: (adult: any, child: any) => !adult,
        then: Yup.number().required().label('Child')
      })
    },
    [['adult', 'child']]
  );

  const validateTravellersForInsurance = Yup.object({}).shape(
    {
      adult: Yup.number().when('senior', {
        is: (child: any) => !child,
        then: Yup.number().required().label('Adult')
      }),
      senior: Yup.number().when('adult', {
        is: (adult: any) => !adult,
        then: Yup.number().required().label('Senior')
      })
    },
    [['adult', 'senior']]
  );

  const genericValidationSchema =
    orderType === ORDER_TYPE.VISA
      ? validateInformationForm.concat(validateTravellersForVisa)
      : validateInformationForm.concat(validateTravellersForInsurance);

  return (
    <Formik
      initialValues={inits}
      onSubmit={handleSubmit}
      validationSchema={genericValidationSchema}
      enableReinitialize>
      {(formikProps: FormikProps<any>) => (
        <Form className='w-100'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OARangePickerField
                label='Duration'
                name='tripDuration'
                direction='up'
                minDate={moment().toDate()}
                required
              />
            </Grid>
            {orderType === ORDER_TYPE.VISA ? (
              <React.Fragment>
                <Grid item xs={6}>
                  <Field
                    name='consulate'
                    label='Consulate'
                    placeholder='Select Consulate'
                    as={OASelectField}
                    options={consulateDataArr}
                    valueOnly
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name='occupation'
                    label='Occupation'
                    placeholder='Select Occupation'
                    as={OASelectField}
                    options={OccupationOptions}
                    valueOnly
                    required
                  />
                </Grid>
              </React.Fragment>
            ) : null}
            {orderType === ORDER_TYPE.INSURANCE && (
              <Grid item xs={6}>
                <OATextField type='number' name='senior' label='Senior' />
                <p className='mt-1 mb-0 text-muted text-medium'>Travellers over 60 yrs</p>
              </Grid>
            )}
            <Grid item xs={6}>
              <OATextField type='number' name='adult' label='Adult' />
              <p className='mt-1 mb-0 text-muted text-medium'>Travellers over 12 yrs</p>
            </Grid>
            <Grid item xs={6}>
              <OATextField type='number' name='child' label='Child' />
              <p className='mt-1 mb-0 text-muted text-medium'>Travellers between 2-11yrs</p>
            </Grid>
            {orderType === ORDER_TYPE.INSURANCE && (
              <Grid item xs={6}>
                <OATextField type='number' name='infant' label='Infant' />
                <p className='mt-1 mb-0 text-muted text-medium'>Travellers below 2 yrs</p>
              </Grid>
            )}
          </Grid>

          <Box display='flex' justifyContent='flex-end' marginTop='1.75rem'>
            <OAButton
              label='Cancel'
              color='light-primary'
              onClick={props?.onHide}
              disabled={formikProps?.isSubmitting}
              className='btn-active-light-primary me-4'
            />
            <OAButton
              type='submit'
              label='Update Quote'
              isLoading={formikProps?.isSubmitting}
              disabled={formikProps?.isSubmitting}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};
