import React, { FC } from 'react';
import { Box } from '@mui/material';

import { OAHoverPopover, OATypography } from '../widgets';

const iso3311a2 = require('iso-3166-1-alpha-2');

interface ITripCell {
  destination: Array<{ countryCode: string }>;
  origin: { countryCode: string };
  domestic: boolean;
  truncate?: boolean;
  width?: string;
  height?: string;
}

const TripCell: FC<ITripCell> = ({
  destination,
  origin,
  domestic,
  truncate = false,
  height,
  width
}) => renderFlags(domestic ? [origin] : destination, truncate, height, width);

export default TripCell;

export const renderFlags = (
  destination: Array<{ countryCode: string }>,
  truncate: boolean,
  height?: string,
  width?: string
) => {
  const destinationToShow = truncate ? destination?.slice(0, 1) : destination;
  const destinationToHide = truncate ? destination?.slice(1) : [];

  const popperComponent = (
    <Box display='flex' flexDirection='column' gap='4px' padding='8px'>
      {destinationToHide?.map(({ countryCode }, index: number) => (
        <Box key={index} display='flex' alignItems='center'>
          <img
            style={{
              width: width ?? '32px',
              height: height ?? '24px',
              marginRight: '4px',
              borderRadius: '4px'
            }}
            alt={`${countryCode}_FLAG`}
            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`}
          />
          <OATypography variant='body2' color='textPrimary'>
            {iso3311a2?.getCountry(countryCode)}
          </OATypography>
        </Box>
      ))}
    </Box>
  );

  return (
    <React.Fragment>
      {destinationToShow?.map(({ countryCode }, index) => (
        <Box key={index} display='flex' alignItems='center'>
          <OAHoverPopover key={index} popperText={iso3311a2?.getCountry(countryCode)}>
            <img
              style={{
                width: width ?? '32px',
                height: height ?? '24px',
                marginRight: '4px',
                borderRadius: '4px'
              }}
              alt={`${countryCode}_FLAG`}
              src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`}
            />
          </OAHoverPopover>
          {destinationToHide?.length > 0 && (
            <OAHoverPopover key={index} popperComponent={popperComponent}>
              <OATypography color='primary'>{`+${destinationToHide?.length} more`}</OATypography>
            </OAHoverPopover>
          )}
        </Box>
      ))}
    </React.Fragment>
  );
};
