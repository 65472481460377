const ArrowSVG = () => {
  return (
    <svg
      className='rotate-90'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <mask
        id='mask0_225_11'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='24'
        height='24'>
        <rect y='24' width='24' height='24' transform='rotate(-90 0 24)' fill='#C4C4C4' />
      </mask>
      <g mask='url(#mask0_225_11)'>
        <path d='M10 17L15 12L10 7.5' stroke='#68778C' strokeWidth='1.5' strokeLinecap='round' />
      </g>
    </svg>
  );
};

export default ArrowSVG;
