export type IOAStatus = {
  status: string;
  label: string;
  type: string;
  tag?: Array<string>;
};

// TODO: add tag specific to visa / insurance order
/**
 * @deprecated
 */
const OA_STATUS: Array<IOAStatus> = [
  {
    status: 'ORDER_SUBMIT',
    label: 'Submitted',
    tag: ['ORDER'],
    type: 'primary'
  },
  {
    status: 'ORDER_SUBMIT_INITIATED',
    label: 'Submit Initiated',
    tag: ['ORDER'],
    type: 'primary'
  },
  {
    status: 'ORDER_CANCELLATION_REQUESTED',
    label: 'Cancellation Requested',
    tag: ['ORDER'],
    type: 'info'
  },
  {
    status: 'ISSUANCE_FAILED',
    label: 'Issuance Failed',
    tag: ['ORDER'],
    type: 'danger'
  },
  {
    status: 'ORDER_SUBMIT_FAILED',
    label: 'Submission Failed',
    tag: ['ORDER'],
    type: 'danger'
  },
  {
    status: 'ORDER_QUOTE',
    label: 'Order Quote',
    type: 'primary'
  },
  {
    status: 'ORDER_PROCESSING',
    label: 'Order Processing',
    tag: ['UPDATE_ORDER', 'ORDER', 'UPDATE_MEMBER'],
    type: 'primary'
  },
  {
    status: 'IN_PROGRESS',
    label: 'In Progress',
    tag: ['ORDER'],
    type: 'primary'
  },
  {
    status: 'ORDER_DRAFT',
    label: 'Draft',
    tag: ['ORDER', 'UPDATE_MEMBER'],
    type: 'warning'
  },
  {
    status: 'ORDER_INITIATED',
    label: 'Order initiated',
    tag: ['ORDER'],
    type: 'primary'
  },
  {
    status: 'COMPLETED',
    label: 'Completed',
    tag: ['UPDATE_ORDER', 'ORDER', 'UPDATE_MEMBER'],
    type: 'success'
  },
  {
    status: 'INCOMPLETE',
    label: 'Incomplete',
    tag: ['UPDATE_ORDER', 'ORDER', 'UPDATE_MEMBER'],
    type: 'warning'
  },
  {
    status: 'CANCELLED',
    label: 'Cancelled',
    tag: ['UPDATE_ORDER', 'ORDER', 'UPDATE_MEMBER'],
    type: 'danger'
  },
  {
    status: 'DOCUMENT_PENDING',
    label: 'Document Pending',
    tag: ['UPDATE_ORDER', 'ORDER', 'UPDATE_MEMBER'],
    type: 'warning'
  },
  {
    status: 'DOCUMENT_COMPLETED',
    label: 'Document Completed',
    tag: ['UPDATE_ORDER', 'ORDER', 'UPDATE_MEMBER'],
    type: 'primary'
  },
  {
    status: 'PARTIAL_APPROVED',
    label: 'Partially Approved',
    tag: ['UPDATE_ORDER', 'ORDER'],
    type: 'success'
  },
  {
    status: 'APPROVED',
    label: 'Approved',
    tag: ['UPDATE_ORDER', 'ORDER', 'UPDATE_MEMBER'],
    type: 'success'
  },
  {
    status: 'DISPATCHED',
    label: 'Dispatched',
    tag: ['UPDATE_ORDER', 'ORDER', 'UPDATE_MEMBER'],
    type: 'primary'
  },
  {
    status: 'PENDING',
    label: 'Pending',
    type: 'warning'
  },
  {
    status: 'ISSUED',
    label: 'Issued',
    type: 'success'
  },
  {
    status: 'COUNTRY_SELECTED',
    label: 'Country Selected',
    type: 'primary'
  },
  {
    status: 'QUOTE_SELECTED',
    label: 'Quote Selected',
    type: 'primary'
  },
  {
    status: 'VISA_APPLICATION_APPROVED',
    label: 'Application Approved',
    tag: ['UPDATE_ORDER', 'ORDER'],
    type: 'success'
  },
  {
    status: 'PARTIAL_APPLICATIONS_APPROVED',
    label: 'Partially Approved',
    tag: ['UPDATE_ORDER', 'ORDER'],
    type: 'success'
  },
  {
    status: 'VISA_APPLIED_IN_EMBASSY',
    label: 'Applied In Embassy',
    tag: ['ORDER'],
    type: 'primary'
  },
  {
    status: 'VISA_APPLICATION_REJECTED',
    label: 'Visa Application Rejected',
    tag: ['UPDATE_ORDER', 'ORDER'],
    type: 'danger'
  },
  {
    status: 'PAYMENT_INITIATED',
    label: 'Payment Initiated',
    tag: ['UPDATE_ORDER', 'ORDER'],
    type: 'primary'
  },
  {
    status: 'PAYMENT_FAILED',
    label: 'Payment Failed',
    tag: ['UPDATE_ORDER', 'ORDER'],
    type: 'danger'
  },
  {
    status: 'PAYMENT_COMPLETED',
    label: 'Payment Completed',
    tag: ['UPDATE_ORDER', 'ORDER'],
    type: 'primary'
  },
  {
    status: 'DOCUMENT_COLLECTED',
    label: 'Document Collected',
    tag: ['UPDATE_ORDER', 'ORDER', 'UPDATE_MEMBER'],
    type: 'primary'
  },
  {
    status: 'BOOKING_CANCELLED',
    label: 'Booking Cancelled',
    tag: ['UPDATE_ORDER', 'ORDER', 'UPDATE_MEMBER'],
    type: 'danger'
  },
  {
    status: 'EXTRA_DOCUMENT_REQUIRED',
    label: 'Extra Document Required',
    tag: ['UPDATE_ORDER', 'ORDER', 'UPDATE_MEMBER'],
    type: 'warning'
  },
  {
    status: 'DOCUMENT_COLLECTION_RESCHEDULING',
    label: 'Document Collection Rescheduling',
    tag: ['UPDATE_ORDER', 'ORDER', 'UPDATE_MEMBER'],
    type: 'primary'
  },
  {
    status: 'DOCUMENT_RETURN_RESCHEDULING',
    label: 'Document Return Rescheduling',
    tag: ['UPDATE_ORDER', 'ORDER', 'UPDATE_MEMBER'],
    type: 'primary'
  },
  {
    status: 'USER_NOT_RESPONDING',
    label: 'User Not Responding',
    tag: ['UPDATE_ORDER'],
    type: 'warning'
  },
  {
    status: 'DOCUMENT_UPLOAD_PENDING',
    label: 'Document Upload Pending',
    tag: ['UPDATE_ORDER', 'ORDER', 'UPDATE_MEMBER'],
    type: 'warning'
  },
  {
    status: 'DOCUMENT_TO_BE_UPLOADED_LATER',
    label: 'Document To Be Uploaded Later',
    tag: ['UPDATE_ORDER', 'ORDER', 'UPDATE_MEMBER'],
    type: 'primary'
  },
  {
    status: 'DOCUMENT_UPLOADED',
    label: 'Document Uploaded',
    tag: ['UPDATE_ORDER', 'ORDER', 'UPDATE_MEMBER'],
    type: 'primary'
  },
  {
    status: 'REJECTED',
    label: 'Rejected',
    tag: ['UPDATE_ORDER', 'ORDER', 'UPDATE_MEMBER'],
    type: 'danger'
  },
  {
    status: 'ADDRESS_TO_BE_COLLECTED',
    label: 'Address To Be Collected',
    tag: ['ORDER'],
    type: 'primary'
  },
  {
    status: 'ADDRESS_COLLECTED',
    label: 'Address Collected',
    tag: ['ORDER'],
    type: 'primary'
  },
  {
    status: 'ORDER_EXPIRED',
    label: 'Expired',
    tag: ['ORDER'],
    type: 'danger'
  },
  {
    status: 'PENDING_ACTION',
    label: 'Pending Action',
    tag: ['ORDER', 'UPDATE_ORDER'],
    type: 'warning'
  },
  {
    status: 'ORDER_SUBMIT_IN_REVIEW',
    label: 'In Review',
    tag: ['ORDER', 'UPDATE_ORDER'],
    type: 'warning'
  },
  {
    status: 'ACTIVE',
    label: 'Active',
    type: 'success'
  },
  {
    status: 'DELETED',
    label: 'Deleted',
    type: 'danger'
  },
  {
    status: 'QUOTED',
    label: 'Quoted',
    type: 'primary'
  },
  {
    status: 'ORDER',
    label: 'Order',
    type: 'success'
  },
  {
    status: 'TRIP_INFO',
    label: 'Travel Info',
    tag: ['ORDER_ACTION'],
    type: 'primary'
  },
  {
    status: 'TRIP_DURATION',
    label: 'Travel Duration',
    tag: ['ORDER_ACTION'],
    type: 'primary'
  },
  {
    status: 'VISA_SELECTED',
    label: 'Visa Selected',
    tag: ['ORDER_ACTION'],
    type: 'primary'
  },
  {
    status: 'OTHERS',
    label: 'Others',
    tag: ['ORDER_ACTION'],
    type: 'primary'
  },
  {
    status: 'MEMBER_PERSONAL_INFO',
    label: 'Member Personal Info',
    tag: ['ORDER_ACTION'],
    type: 'primary'
  },
  {
    status: 'MEMBER_DOCUMENT',
    label: 'Member Document',
    tag: ['ORDER_ACTION'],
    type: 'primary'
  },
  {
    status: 'MEMBER_ADDITIONAL_DOCUMENT_REQUIRED',
    label: 'Member Additional Document',
    tag: ['ORDER_ACTION'],
    type: 'primary'
  },
  {
    status: 'ORDER_SUBMITTED_TO_EMBASSY',
    label: 'Submitted To Embassy',
    type: 'primary'
  },
  {
    status: 'ORDER_SUBMIT_REVIEW_IN_PROGRESS',
    label: 'Review in progress',
    type: 'info'
  },
  {
    status: 'ORDER_CANCELLATION_FAILED',
    label: 'Cancellation Failed',
    type: 'danger'
  },
  {
    status: 'TRAVELER_ADDITIONAL_DOCUMENT_REQUIRED',
    label: 'Traveller Additional Document Required',
    type: 'primary'
  },
  {
    status: 'TRAVELER_DOCUMENT',
    label: 'Traveller Document Required',
    type: 'primary'
  },
  {
    status: 'TRAVELER_PERSONAL_INFO',
    label: 'Traveller Personal Info',
    type: 'primary'
  }
];

export default OA_STATUS;
