import { Avatar, Chip } from '@mui/material';

import { getStatusColor, getStatusIcon } from '../../helper';

type VariantType = 'info' | 'primary' | 'success' | 'warning' | 'error' | 'danger';

export interface IDataExchangeVisaStatus {
  label: string;
  stage: string;
  type: VariantType;
  value: string;
  organizationTypes: Array<string>;
}

export interface IDataExchangeInsuranceStatus {
  label: string;
  stage: string;
  type: VariantType;
  value: string;
  organizationTypes: Array<string>;
}

interface OAStatusChipProps {
  status: string;
  variant: VariantType;
  label?: string;
  iconOnly?: boolean;
  animate?: boolean;
}

export default function OAStatusChip(props: OAStatusChipProps) {
  return (
    <Chip
      avatar={<Avatar>{getStatusIcon(props?.status)}</Avatar>}
      label={props?.label ?? ''}
      variant='outlined'
      size='small'
      sx={{
        'width': () => (props?.iconOnly || props?.animate ? '24px' : 'auto'),
        'borderColor': theme => getStatusColor(props?.variant, theme),
        '&:hover': props?.animate
          ? {
              'width': 'auto',
              '& .MuiChip-label': {
                padding: '0 8px',
                color: theme => getStatusColor(props?.variant, theme)
              }
            }
          : {},
        'overflow': 'hidden',
        '& .MuiAvatar-root': {
          bgcolor: theme => getStatusColor(props?.variant, theme)
        },
        '& .MuiChip-label': {
          padding: () => (props?.iconOnly || props?.animate ? '0 3px' : '0 8px'),
          color: theme => (props?.animate ? '#fff' : getStatusColor(props?.variant, theme))
        }
      }}
    />
  );
}
