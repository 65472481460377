import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { FormikProps } from 'formik';

import { useAppContext } from '../../../../../../context/AppContext';
import { useOrderContext } from '../../../../context/OrderContext';
import { renderBookingInformation } from '../travelFormRenderer';

interface BookingInformationSectionProps {
  shouldShow: boolean;
  formProps: FormikProps<any>;
}

export default function BookingInformationSection(props: BookingInformationSectionProps) {
  const { getDataExchangeByType } = useAppContext();
  const { formState } = useOrderContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<any>({
    city: [],
    district: [],
    placeOfStay: []
  });

  useEffect(() => {
    setIsLoading(true);
    const typeStr = 'CITY, DISTRICT, PLACE_OF_STAY';
    const supplierOrganizationCode = formState?.tripDetails?.supplierOrganization?.organizationCode;

    getDataExchangeByType(typeStr, supplierOrganizationCode, true, _res => {
      const draftPlaces = _res?.find((item: any) => item?.type === 'PLACE_OF_STAY')?.values;
      const draftCities = _res?.find((item: any) => item?.type === 'CITY')?.values;
      const draftDistricts = _res
        ?.find((item: any) => item?.type === 'DISTRICT')
        ?.values?.map((item: any) => (item?.label ? item : { ...item, label: item?.value }));
      setOptions({ city: draftCities, district: draftDistricts, placeOfStay: draftPlaces });
      setIsLoading(false);
    });
  }, []);

  const draftOptions = {
    placeOfStay: options.placeOfStay,
    city: options?.city,
    district: options?.district?.map(({ label, value }: any) => ({ label, value })),
    subDistrict: options?.district
      ?.find((dist: any) => dist?.value === props?.formProps?.values?.propertyAddress?.district)
      ?.associatedValue?.map((sub: string) => ({ value: sub, label: sub }))
  };

  return props?.shouldShow ? (
    <React.Fragment>
      <h6 className='text-dark mb-1 mt-6'>Stay details</h6>
      <div className='separator mb-4' />
      <Row>{renderBookingInformation(formState, draftOptions, props?.formProps, isLoading)}</Row>
    </React.Fragment>
  ) : null;
}
