import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import OAModalComponent from '../../../../components/OAModalComponent';
import { IMember } from '../../../../types';
import OAButton from '../../../../components/widgets/OAButton';
import { useOrderContext } from '../../context/OrderContext';
import { useParams } from 'react-router-dom';

/**
 * types ...
 */
interface IInviteTravellerModal {
  isOpen: boolean;
  onHide: () => void;
  data: any;
}

/**
 *
 * @param props
 * @returns
 */
export default function InviteTravellerModal(props: IInviteTravellerModal) {
  const params = useParams<{ id?: string }>();
  const { members } = props?.data;
  const { shareLinkWithTraveller } = useOrderContext();
  const primaryMember = members?.find((member: IMember) => member?.primary);
  const [linkUrl, setLinkUrl] = useState<string>('Fetching link......');
  const [copied, setCopied] = useState<string>('Copy link');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const shareLink = (emailNotification: boolean) => {
    if (emailNotification) setIsLoading(true);
    shareLinkWithTraveller(params?.id!, emailNotification, res => {
      if (res?.data?.data?.link) {
        setLinkUrl(res?.data?.data?.link);
        if (emailNotification) {
          setIsLoading(false);
          props?.onHide();
        }
      }
    });
  };

  useEffect(() => {
    if (props?.isOpen) {
      setCopied('Copy link');
      shareLink(false);
    }
  }, [props?.isOpen]);

  return (
    <OAModalComponent
      show={props?.isOpen}
      onHide={props?.onHide}
      className='invite-traveller__modal'
      header={{
        title: 'Invite traveller to view application',
        closeButton: true
      }}
      body={{
        content: (
          <>
            <span>
              You are inviting{' '}
              <b>
                {primaryMember?.firstName} {primaryMember?.lastName}
              </b>{' '}
              via <b>{primaryMember?.email}</b> to view their visa application
            </span>
            <div className='d-flex flex-stack pt-4'>
              <input
                type='text'
                value={linkUrl}
                className={`form-control form-control-sm me-3 ${
                  copied === 'Copied!' ? 'bg-light-success' : 'bg-transparent'
                }`}
                disabled
              />
              <CopyToClipboard text={linkUrl} onCopy={() => setCopied('Copied!')}>
                <OAButton
                  disabled={linkUrl?.includes('Fetching')}
                  label={copied}
                  className='btn-light-primary btn-active-light-primary me-3 text-nowrap'
                />
              </CopyToClipboard>
            </div>
            <div className='d-flex justify-content-end mt-8'>
              <OAButton label='Cancel' onClick={props?.onHide} color='light-primary' />
              <OAButton
                type='button'
                label='Send via mail'
                color='primary'
                className='ms-3'
                onClick={() => shareLink(true)}
                isLoading={isLoading}
                disabled={isLoading}
              />
            </div>
          </>
        )
      }}
    />
  );
}
