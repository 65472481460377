import { toAbsoluteUrl } from '../../helper';

const PreviewCard = ({ title = '', bodyText = '' }) => {
  return (
    <div className='text-center my-10 py-10'>
      <img
        src={toAbsoluteUrl('/media/illustrations/sketchy-1/16.png')}
        alt=''
        className='mh-200px mb-4'
      />
      <h2 className='fs-5 fw-bolder mb-1'>{title}</h2>
      <div className='fs-7 fw-bold text-gray-400'>{bodyText}</div>
    </div>
  );
};

export default PreviewCard;
