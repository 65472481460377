import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useVisaInformationContext } from '../context/VIsaInformationContext';
import SendInfoModal from './SendInfoModal';
import ProductCard from './ProductCard';
import SideNavigation from './SideNavigation';
import { IDocument } from '../../../types';
import OAAccordionConfig from '../../../components/OAVisaSummary/helper';
import { Box } from '@mui/material';
import OAVisaSummary from '../../../components/OAVisaSummary';
import VisaInformationHeader from './VisaInformationHeader';
import { LatestNews } from '../../../components/OAVisaSummary/AccordionItem/LatestNews';

export default function VisaInformationView() {
  const { products, visaInformationValues } = useVisaInformationContext();

  const [showInfoEmailModal, setShowInfoEmailModal] = useState<boolean>(false);
  const [selectedVisaIndex, setSelectedVisaIndex] = useState<any>(null);
  const [selectedConsulate, setSelectedConsulate] = useState<any>(null);

  const consulateData: Array<any> = products?.data?.[selectedVisaIndex]?.consulateData;
  /**
   * NOTE: on 'consulateData' change, set first one from the array as selected...
   */
  useEffect(() => {
    if (consulateData?.length) {
      setSelectedConsulate(consulateData[0]?.value);
    }
  }, [consulateData]);
  /**
   * NOTE: check if 'selectedVisaIndex' is not null then make it null on mount only...
   */
  useEffect(() => {
    if (products?.data && products?.data?.length > 0) {
      setSelectedVisaIndex(0);
    }
  }, [products]);

  const toggleInfoEmailModal = () => setShowInfoEmailModal(prev => !prev);

  const selectedVisaInformation = products?.data?.[selectedVisaIndex];

  const selectedConsulateVal = selectedVisaInformation?.consulateData?.find(
    (obj: any) => obj?.value === selectedConsulate
  );
  const selectedVisaProduct = products?.data?.[selectedVisaIndex];
  const requiredVisaForms = selectedConsulateVal?.requiredVisaForms;

  const requiredDocuments = selectedConsulateVal?.requiredDocuments;
  let requiredDocs = [];
  let groupByApplicableFor: any = [];

  if (requiredDocuments?.length > 0) {
    requiredDocs = requiredDocuments.filter((doc: IDocument) => doc.required);

    groupByApplicableFor = requiredDocuments.reduce((acc: any, doc: any) => {
      if (doc.applicableFor) {
        doc.applicableFor.forEach((applicable: string) => {
          if (!acc[applicable]) {
            acc[applicable] = [];
          }
          acc[applicable].push(doc);
        });
      }
      return acc;
    }, {});
  }

  let accordianParams = {
    selectedConsulate: selectedConsulateVal,
    selectedVisaInformation,
    requiredVisaForms,
    requiredDocs,
    groupByApplicableFor
  };
  let accordionConfig = selectedConsulate && OAAccordionConfig(accordianParams);

  let accData = accordionConfig?.filter(
    (item: any) => item?.check !== false && item?.check !== undefined
  );
  const sectionRefs = useRef<any>({});

  return (
    <React.Fragment>
      <Row>
        <Col md={3}>
          {products?.data?.length ? (
            <Box sx={{ position: 'sticky', top: '60px' }}>
              <Card className='visa-info__products-list'>
                <Card.Body>
                  <h2 className='fs-4'>Available products</h2>
                  <div className='mt-8'>
                    <ProductCard
                      onClickCard={(id: any) => setSelectedVisaIndex(id)}
                      selectedId={selectedVisaIndex}
                      product={products?.data}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Box>
          ) : null}
        </Col>
        <Col md={7}>
          {products?.data?.length !== 0 && products?.data !== null && (
            <Box>
              <LatestNews news={selectedVisaInformation?.latestNewsAlerts} />
              <VisaInformationHeader
                selectedVisaIndex={selectedVisaIndex}
                visaInformationValues={visaInformationValues}
                consulateData={consulateData}
                selectedConsulate={selectedConsulate}
                selectedVisaProduct={selectedVisaProduct}
                setSelectedConsulate={setSelectedConsulate}
                toggleInfoEmailModal={toggleInfoEmailModal}
                newsLength={selectedVisaInformation?.latestNewsAlerts?.length}
              />
            </Box>
          )}
          {products?.data?.length !== 0 && products?.data !== null ? (
            <Box sx={{ backgroundColor: 'white', borderRadius: '8px' }}>
              <OAVisaSummary
                accordionConfig={accordionConfig}
                selectedVisaInformation={selectedVisaInformation}
                sectionRefs={sectionRefs}
              />
            </Box>
          ) : null}
        </Col>
        <Col md={2}>
          {products?.data?.length !== 0 && products?.data !== null ? (
            <SideNavigation accordionConfig={accData} sectionRefs={sectionRefs} />
          ) : null}
        </Col>
      </Row>
      <SendInfoModal
        isOpen={showInfoEmailModal}
        toggle={toggleInfoEmailModal}
        selectedVisaIndex={selectedVisaIndex}
        selectedConsulate={selectedConsulate}
        destination={selectedVisaInformation?.destination[0]?.country}
      />
    </React.Fragment>
  );
}
