import { FC } from 'react';
import { KTSVG } from '../../../_metronic/helpers/components/KTSVG';

interface IEmbassyHolidayList {
  consulate: any;
}

export const EmbassyHolidayList: FC<IEmbassyHolidayList> = ({ consulate }) => {
  return (
    <a href={consulate?.consulateInfo?.holidayListLink} target='_blank' rel='noreferrer'>
      <div className='d-flex align-items-center'>
        <KTSVG path='/media/icons/duotune/arrows/arr036.svg' className='svg-icon-4 me-1' />
        Embassy Holiday List
      </div>
    </a>
  );
};
