import { EncryptStorage } from 'encrypt-storage';

const secretKey: string = process.env.REACT_APP_BROWSER_STORAGE_SECRET_KEY ?? '';
export const encryptStorage = new EncryptStorage(secretKey, {
  storageType: 'sessionStorage'
});

export const encryptLocalStorage = new EncryptStorage(secretKey, {
  storageType: 'localStorage'
});
