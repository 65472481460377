import { isArray } from 'lodash';
import React, { useState } from 'react';

type ToggleTabOption = {
  label: string;
  value: string;
};

interface IToggleTab {
  options: Array<ToggleTabOption> | ToggleTabOption;
  selected: string | null;
  onSelect: (val: any) => void;
}

const OAToggleTab = (props: IToggleTab) => {
  const [checked, setChecked] = useState<boolean>(false);

  return (
    <React.Fragment>
      {!isArray(props?.options) ? (
        <button
          id='toggle-check'
          className={`toggle-option ${checked ? 'active' : ''}`}
          onClick={() => setChecked(!checked)}>
          Checked
        </button>
      ) : (
        props?.options?.map((option, idx) => (
          <button
            key={idx}
            id={`${option?.label}-${idx}`}
            className={`toggle-option ${props?.selected === option?.value ? 'active' : ''}`}
            onClick={() => props?.onSelect(option?.value)}>
            {option?.label}
          </button>
        ))
      )}
    </React.Fragment>
  );
};

export default OAToggleTab;
