/* eslint-disable no-unused-vars */
import isBoolean from 'lodash/isBoolean';

import { setDateFormat } from '../../../helper';
import { ORDER_TYPE } from '../constants';
import { ICreateOrderFormState, IOrderType } from '../types';
import { getTravellerRequest } from './getTravellerRequest';
import { isEmpty } from 'lodash';

export const getDraftOrderRequest = (
  formState: ICreateOrderFormState,
  type: IOrderType,
  orderId?: string,
  values?: any
) => {
  const localTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const isVisa = type === ORDER_TYPE.VISA;
  const isInsurance = type === ORDER_TYPE.INSURANCE;
  /**
   * NOTE: create "draft" object with initial values ...
   */
  let draft: any = {
    orderStatus: 'ORDER_DRAFT',
    requestedProducts: [formState?.tripDetails?.id],
    trip: {
      tripType:
        formState?.tripDetails?.productInformation?.tripType ??
        formState?.tripDetails?.trip?.tripType,
      visaType:
        formState?.tripDetails?.trip?.visaType ??
        formState?.tripDetails?.productInformation?.visaType,
      tripStart: {
        datetime: setDateFormat.startOfDayForBE(formState?.tripDuration?.from),
        timezone: localTZ
      },
      origin: formState?.tripDetails?.trip?.origin ?? formState?.tripDetails?.origin,
      destination: formState?.tripDetails?.trip?.destination ?? formState?.tripDetails?.destination
    },
    members: formState?.travellerDetails?.map(traveller => {
      const tripDetails = formState?.tripDetails;
      // const isPrimary = !!(traveller?.isNew && !traveller?.primary);

      const draftTraveller = getTravellerRequest(type, traveller, tripDetails);
      /**
       * NOTE: if "orderId" is available and "formState.travellerDetails" contains
       * only one traveller but that traveller is not primary then make it primary ...
       *
       * N.B. - order should always have atleast one primary traveller ...
       */
      if (orderId && formState?.travellerDetails?.length === 1 && !traveller?.primary) {
        draftTraveller['primary'] = true;
      }
      return draftTraveller;
    })
  };
  /**
   * NOTE: set "convertToOrder" in "draft" if it's available in "formState" ...
   */
  if (formState?.convertToOrder) {
    draft['convertToOrder'] = true;
  }

  if (formState?.category === 'CFAR_FOR_HOTEL' && formState?.orderId === '') {
    draft['insuranceCategory'] = formState?.category;
  }
  /**
   * NOTE: set "bookingInformation" in "draft" if it's available in "formState" ...
   */
  const bookingInformation = !isEmpty(values?.bookingInformation)
    ? values?.bookingInformation
    : formState?.tripDetails?.trip?.bookingInformation;

  if (!isEmpty(bookingInformation)) {
    if (isInsurance && formState?.tripDetails?.category === 'CFAR_FOR_HOTEL') {
      draft.trip.bookingInformation = [
        {
          bookingReferenceId: bookingInformation[0]?.bookingReferenceId,
          bookingAmountPerNight: Number(bookingInformation[0]?.bookingAmountPerNight),
          bookingAmountCurrency: 'INR'
        }
      ];
    } else {
      draft.trip.bookingInformation = bookingInformation;
    }
  }
  /**
   * NOTE: set "consulate" in "draft" if it's available in "formState" ...
   */
  if (formState?.consulate) {
    draft['consulate'] = formState?.consulate;
  }
  /**
   * NOTE: set "groupId" in "draft" if it's available in "formState" ...
   */
  if (formState?.groupName && formState?.groupName !== '' && !values?.member?.consulate) {
    draft['groupId'] = formState?.groupName;
  }
  /**
   * NOTE: set "quotationId" in "draft" if it's available in "formState" ...
   */
  if (formState?.quotationId && formState?.quotationId !== '' && !orderId) {
    draft['quotationId'] = formState?.quotationId;
  }
  /**
   * NOTE: check if trip includes "domestic" then attach it with "draft.trip" ...
   */
  if (isInsurance && Object.keys(formState?.tripDetails?.trip)?.includes('domestic')) {
    draft.trip.domestic = formState?.tripDetails?.trip?.domestic;
  }
  /**
   * DEVNOTE: for below mentioned cases we need to set trip.tripEnd ...
   */
  const case_1 = isVisa;
  const case_2 =
    isInsurance &&
    formState?.category === 'STANDARD_TRAVEL_INSURANCE' &&
    !formState?.tripDetails?.trip?.domestic;
  const case_3 = isInsurance && formState?.tripDetails?.trip?.domestic;
  const case_4 = isInsurance && formState?.category === 'CFAR_FOR_HOTEL';

  if ((case_1 || case_2 || case_3 || case_4) && !!formState?.tripDuration?.to) {
    draft.trip.tripEnd = {
      datetime: setDateFormat.endOfDayForBE(formState?.tripDuration?.to),
      timezone: localTZ
    };
  }
  /**
   * NOTE: if "values" or "formState" contains "markup" then check markup
   * amount availability and attach it with "draft" as "markup" ...
   */
  if (values?.markup || formState?.markup?.amount) {
    draft.markup = values?.markup ?? formState?.markup;
  }

  if (values?.taxInfo || formState?.taxInfo?.taxRegistrationNumber) {
    draft.taxInfo = {
      type: 'GST',
      taxRegistrationNumber:
        values?.taxInfo?.taxRegistrationNumbe ?? formState?.taxInfo?.taxRegistrationNumber
    };
  }
  /**
   * NOTE: if "values" or "formState" contains "additionalValues" then check
   * each field of "additionalValues" and attach all in "draft.additionalOrderInfo" ...
   */
  let draftAdditionalOrderInfo: any = {};
  if (values?.additionalValues?.clientName || formState?.additionalOrderInfo?.clientName) {
    draftAdditionalOrderInfo.clientName =
      values?.additionalValues?.clientName ?? formState?.additionalOrderInfo?.clientName;
  }
  if (
    values?.additionalValues?.paymentReference ||
    formState?.additionalOrderInfo?.paymentReference
  ) {
    draftAdditionalOrderInfo.paymentReference =
      values?.additionalValues?.paymentReference ??
      formState?.additionalOrderInfo?.paymentReference;
  }
  if (values?.additionalValues?.tags || formState?.additionalOrderInfo?.tags) {
    draftAdditionalOrderInfo.tags = values?.additionalValues?.tags
      ? values?.additionalValues?.tags
      : formState?.additionalOrderInfo?.tags;
  }
  if (draftAdditionalOrderInfo) {
    draft.additionalOrderInfo = draftAdditionalOrderInfo;
  }
  /**
   * NOTE: if "values" contains "tripDuration" then check "start" and "end" of
   * "tripDuration" and attach with "draft" as part of "trip" ...
   */
  if (values?.tripDuration?.from) {
    draft.trip['tripStart'] = {
      datetime: setDateFormat.startOfDayForBE(values?.tripDuration?.from),
      timezone: localTZ
    };
  }

  if (values?.tripDuration?.to) {
    draft.trip['tripEnd'] = {
      datetime: setDateFormat.endOfDayForBE(values?.tripDuration?.to),
      timezone: localTZ
    };
  }
  /**
   * NOTE: if "values" contains "member" then check it's "id" and replace it from
   * "draft.members" list ...
   */
  if (values?.member) {
    const draftTraveller = getTravellerRequest(type, values?.member, formState?.tripDetails);
    const filteredMembers = !formState?.groupName
      ? draft?.members?.filter((obj: any) => obj?.id !== draftTraveller?.id)
      : [];
    const draftMembers = [...filteredMembers, draftTraveller];

    draft.members = draftMembers;
  }
  /**
   * NOTE: delete traveller workflow
   * - if "values" contains "memberId" then filter out that traveller from
   * "draft.members" list ...
   */
  if (values?.memberId) {
    const filteredMembers = draft?.members?.filter((obj: any) => obj?.id !== values?.memberId);
    draft.members = filteredMembers;
  }
  /**
   * NOTE: if "values" contains "primaryMemberId" then find that member and
   * make it primary ...
   */
  if (values?.primaryMemberId) {
    let draftArr = draft?.members?.map((obj: any) =>
      Object.assign({}, obj, {
        primary: obj?.id === values?.primaryMemberId
      })
    );
    draft.members = draftArr;
  }

  if (isVisa && isBoolean(formState?.productAmountToBeCollected)) {
    draft.productAmountToBeCollected = formState?.productAmountToBeCollected;
  }
  /**
   * -- addOnServiceModifier
   */
  if (formState?.addOnServiceModifier?.length) {
    draft.addOnServiceModifier = formState?.addOnServiceModifier;
  }
  if (!orderId && !formState?.addOnServiceModifier?.length) {
    const vac =
      formState?.tripDetails?.productInformation?.productAmount?.additionalAmountModifiers?.find(
        (obj: any) => obj?.type === 'VAC_SERVICE_CHARGE'
      );
    if (vac) draft.addOnServiceModifier = [vac];
  }
  /**
   * NOTE: if 'cloneOrderId' present in formState then attach in 'draft'...
   */
  if (formState?.cloneOrderId && !orderId) {
    draft.cloneOrderId = formState?.cloneOrderId;
  }
  /**
   * NOTE: no need to attach "orderStatus" and "requestedProducts" in case of update ...
   */
  const { orderStatus, requestedProducts, ...rest } = draft;

  return orderId ? rest : draft;
};
