import { ISupplierOrganization } from '../types/ISupplierOrganization';

export const getSupplierOrganizationName = (
  currentUser: any,
  supplierOrganization: ISupplierOrganization
) => {
  const currentUserOrgId = currentUser?.data?.organization?.id;
  let orgName = supplierOrganization?.name;
  let orgLegalName = supplierOrganization?.legalName?.trim();
  if (supplierOrganization?.aliasedNames && supplierOrganization?.aliasedNames?.length > 0) {
    const findAlias = supplierOrganization?.aliasedNames.find(
      (org: any) => org?.organizationId === currentUserOrgId
    );
    if (findAlias) {
      orgName = findAlias?.name;
      orgLegalName = findAlias?.name;
    }
  }
  return {
    orgName: orgName,
    orgLegalName: orgLegalName
  };
};
