import { useState, useEffect } from 'react';
import { ErrorMessage, Form, Formik, FormikHelpers, FormikProps, useField } from 'formik';
import { Col, Row, Container, CloseButton } from 'react-bootstrap';
import classNames from 'classnames';
import * as Yup from 'yup';

import OAButton from '../../../../../components/widgets/OAButton';

interface IAddOrEditMarkupFormProps {
  onHide: () => void;
  onMarkupSubmit: (value: any) => void;
  value: string | number;
}

interface IMarkupValues {
  amount: number | string;
}

const init: IMarkupValues = {
  amount: 0
};

const markupValidationSchema = Yup.object({
  amount: Yup.number()
    .integer()
    .moreThan(-1, 'Amount cannot be less than 0')
    .lessThan(10000, 'Amount cannot be more than 9999')
    .required()
});

export default function AddOrEditMarkupForm(props: IAddOrEditMarkupFormProps) {
  const [initValues, setInitValues] = useState<IMarkupValues>(init);

  useEffect(() => {
    if (props?.value) {
      setInitValues({ amount: props?.value });
    } else {
      setInitValues(init);
    }
  }, [props?.value]);

  const handleSubmit = (values: IMarkupValues, actions: FormikHelpers<IMarkupValues>) => {
    const draft = {
      markup: values?.amount !== 0 ? values : null
    };
    props?.onMarkupSubmit(draft);
  };
  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleSubmit}
      validationSchema={markupValidationSchema}
      enableReinitialize>
      {(formikProps: FormikProps<IMarkupValues>) => (
        <Container fluid as={Form} className='p-0'>
          <Row className='mb-8'>
            <Col>
              <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
                <div className='d-flex flex-stack flex-grow-1'>
                  <div className='fw-semibold fs-6 text-gray-700'>
                    The amount added here is not inclusive of taxes.
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col>
              <OAMarkupTextField
                name='amount'
                type='number'
                label='Markup amount (per traveller)'
                required
              />
            </Col>
          </Row>
          <Row>
            <Col className='text-end'>
              <OAButton
                label='Cancel'
                color='light-primary'
                onClick={() => {
                  formikProps?.resetForm();
                  props?.onHide();
                }}
                className='me-5 btn-active-light-primary'
              />
              <OAButton
                label='Save'
                isLoading={formikProps?.isSubmitting}
                loadingText='Saving...'
                type='submit'
                color='primary'
                className='ms-auto'
                disabled={formikProps?.isSubmitting}
              />
            </Col>
          </Row>
        </Container>
      )}
    </Formik>
  );
}

interface IOAMarkupTextField {
  type?: string;
  label?: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  inputClassName?: string;
}

export function OAMarkupTextField(props: IOAMarkupTextField) {
  const {
    type = 'text',
    label,
    name,
    required = false,
    inputClassName = 'markup',
    placeholder = 'Type here'
  } = props;
  const [field, _meta, helpers] = useField(name);
  const labelClassNames = classNames('form-label', {
    required: required
  });
  const inputClasses = classNames(
    inputClassName,
    'form-control form-control-sm form-control-solid'
  );
  const closeHandler = () => {
    helpers.setValue(0);
  };
  return (
    <div className='oa-markup-text-field'>
      {label ? (
        <label htmlFor={name} className={labelClassNames}>
          {label}
        </label>
      ) : null}
      <div className='d-flex align-items-center'>
        <div className='flex-grow-1'>
          <input type={type} placeholder={placeholder} className={inputClasses} {...field} />
          <CloseButton className='close' onClick={closeHandler} />
        </div>
        <span className='ms-4'>per traveller</span>
      </div>
      <p className='mt-3'>When markup amount is 0, no markup will be added</p>
      <div className='text-danger'>
        <ErrorMessage name={name} />
      </div>
    </div>
  );
}
