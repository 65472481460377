import { Route, Routes } from 'react-router-dom';

import OrderProvider from '../order-management/context/OrderProvider';
import VisaInformationProvider from './context/VisaInformationProvider';
import VisaInformationForm from './VisaInformationForm';
import { IQueryParams } from '../Dashboard';

const VisaInformation = (props: IQueryParams) => {
  return (
    <OrderProvider data={props}>
      <VisaInformationProvider>
        <Routes>
          <Route path='/' element={<VisaInformationForm />} />
        </Routes>
      </VisaInformationProvider>
    </OrderProvider>
  );
};

export default VisaInformation;
