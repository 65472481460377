import moment, { Moment } from 'moment';
import { OA_DATE_FORMAT_FE } from '../constants';

/**
 * getFormatedDate function
 *
 * This function accepts a date (in various formats) and a date format string
 * and returns the date formatted as per the provided format string.
 *
 * @param {Date | Moment | string} input - The date to be formatted. This can be a JavaScript Date object, a Moment object, or a string.
 * @param {string} [format=OA_DATE_FORMAT_FE] - Optional. The format in which to return the date. Default is 'DD MMM, YYYY'.
 *
 * @throws {Error} Will throw an error if the input date is not a Date object, a Moment object, or a string.
 * @throws {Error} Will throw an error if the input date is not valid.
 *
 * @returns {string} The input date formatted as per the provided format string, or in the default format if no format is provided.
 */
const getFormattedDate = (input: Date | Moment | string, format?: string): string | undefined => {
  let date: Moment;

  if (!input) return;

  if (moment.isMoment(input)) {
    date = input;
  } else if (typeof input === 'string') {
    date = moment(input);
  } else if (input instanceof Date) {
    date = moment(input);
  } else {
    throw new Error('Invalid input type');
  }

  if (!date.isValid()) {
    // If the date is not valid
    throw new Error('Invalid date');
  }

  return date.format(format || OA_DATE_FORMAT_FE);
};

export default getFormattedDate;
