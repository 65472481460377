import { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import parse from 'date-fns/parse';
import { isString } from 'lodash';
import moment from 'moment';

import TravellerForm from './TravellerForm';
import OrderContext, { IOrderContext } from '../../../context/OrderContext';
import { DATE_FORMAT_BE } from '../../../../../constants';
import { showDialog } from '../../../../../helper';

interface IAddTravellerFormModal {
  isOpen: boolean;
  onHide: () => void;
  type?: 'single' | 'multi';
}

export default function AddTravellerFormModal(props: IAddTravellerFormModal) {
  const { formState, travellerId }: IOrderContext = useContext(OrderContext);

  const [formVal, setFormVal] = useState<any>({});
  const [isFormDirty, setIsFormDirty] = useState(false);

  useEffect(() => {
    if (props?.isOpen) {
      if (travellerId) {
        const {
          dateOfBirth,
          passportExpiration,
          passportIssuingPlace,
          passportIssuingDate,
          documents,
          ...rest
        } = formState?.travellerDetails?.find(obj => obj?.id === travellerId)!;

        let draftRest: any = {
          ...rest
        };
        if (formState?.category === 'BUS_INSURANCE' || formState?.category === 'CAB_INSURANCE') {
          const { origin, destination } = formState?.tripDetails?.trip;

          draftRest['originLine1'] = origin?.line1;
          draftRest['originCity'] = origin?.city;
          draftRest['originState'] = origin?.state;
          draftRest['originPostalCode'] = origin?.postalCode;
          draftRest['destinationLine1'] = destination?.[0]?.line1;
          draftRest['destinationCity'] = destination?.[0]?.city;
          draftRest['destinationState'] = destination?.[0]?.state;
          draftRest['destinationPostalCode'] = destination?.[0]?.postalCode;
        }

        setFormVal({
          dateOfBirth: isString(dateOfBirth)
            ? parse(dateOfBirth as string, DATE_FORMAT_BE, new Date())
            : moment(dateOfBirth),
          passportExpiration: passportExpiration
            ? isString(passportExpiration)
              ? parse(passportExpiration as string, DATE_FORMAT_BE, new Date())
              : moment(passportExpiration)
            : '',
          passportIssuingDate: passportIssuingDate
            ? isString(passportIssuingDate)
              ? parse(passportIssuingDate as string, DATE_FORMAT_BE, new Date())
              : moment(passportIssuingDate)
            : '',
          passportIssuingPlace: passportIssuingPlace ?? '',
          PassportFrontImage: documents?.find(
            (doc: any) => doc?.fieldName === 'Passport Front Image'
          )?.data,
          PassportBackImage: documents?.find((doc: any) => doc?.fieldName === 'Passport Back Image')
            ?.data,
          ...draftRest
        });
      } else {
        setFormVal({});
      }
    }
  }, [travellerId, props?.isOpen]);

  const handleCloseModal = () => {
    if (isFormDirty) {
      showDialog({
        title: 'Are you sure?',
        text: 'You will lose all the data you have entered',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, close it!',
        cancelButtonText: 'No, keep it'
      }).then(result => {
        if (result.isConfirmed) {
          props?.onHide();
        }
      });
    } else {
      props?.onHide();
    }
  };

  return (
    <Modal
      show={props?.isOpen}
      onHide={handleCloseModal}
      dialogClassName='modal-1240w'
      aria-labelledby='contained-modal-title-vcenter'
      backdrop='static'
      centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Add Traveller</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='flex-row-fluid px-lg-5'>
          <TravellerForm
            data={formVal ?? null}
            onHide={() => props?.onHide()}
            type={props?.type}
            resetFormVal={() => setFormVal({})}
            button={1}
            isFormDirty={isFormDirty}
            setIsFormDirty={setIsFormDirty}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
