import { useParams } from 'react-router-dom';
import { OAStatusChip } from '../../../../../components/widgets';
import { useAppContext } from '../../../../../context/AppContext';
import { ORDER_TYPE } from '../../../constants';
import { IOrderType } from '../../../types';
import { useOrderContext } from '../../../context/OrderContext';
import { IDataExchangeVisaStatus } from '../../../../../components/widgets/OAStatusChip';

interface IStatusChip {
  status: string;
}

const StatusChip = (props: IStatusChip) => {
  const params = useParams<{ orderType: IOrderType; id?: string }>();
  const { dataExchange } = useAppContext();
  const { orderData } = useOrderContext();

  let updateStatusOptionsLabel = 'Insurance Status';
  let updateStatusOptions = dataExchange?.INSURANCE_UPDATE_STATUS;
  if (params?.orderType === ORDER_TYPE.VISA) {
    updateStatusOptionsLabel = 'Visa Status';
    const productType = orderData?.data.product?.productInformation?.productType;
    updateStatusOptions =
      productType === 'Sticker'
        ? dataExchange?.STICKER_VISA_UPDATE_STATUS
        : dataExchange?.EVISA_UPDATE_STATUS;
  }
  let filteredStatusOptions = updateStatusOptions?.filter(
    (status: any) => status?.value !== 'PARTIAL_APPROVED'
  );
  updateStatusOptions = filteredStatusOptions;
  const statusObj: IDataExchangeVisaStatus =
    params?.orderType === ORDER_TYPE.VISA
      ? dataExchange['VISA_STATUS']?.find(
          (obj: IDataExchangeVisaStatus) => obj?.value === props?.status
        )
      : dataExchange['INSURANCE_STATUS']?.find((obj: any) => obj?.value === props?.status);

  return (
    <OAStatusChip status={statusObj?.value} label={statusObj?.label} variant={statusObj?.type} />
  );
};

export default StatusChip;
