/* eslint-disable react-hooks/exhaustive-deps */
import { Box, IconButton } from '@mui/material';
import { Card, Col, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { OATypography } from '../../../components/widgets';
import { IOrderType } from '../types';
import { ReactComponent as ArrowBackIcon } from '../../../assets/icons/arrow_back.svg';
import classNames from 'classnames';
import GroupOrderDetails from './GroupOrderDetails';

export default function GroupOrderView() {
  const params = useParams<{ orderType: IOrderType; id?: string }>();
  const location = useLocation();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(`/apps/orders/${params?.orderType}`);
  };

  const getClassNames = (pathValue: string) => {
    return classNames('nav-link text-active-primary ms-8', {
      active: location?.pathname?.includes(pathValue)
    });
  };

  return (
    <div>
      <Row className='order-view'>
        <Col md='12'>
          <Card>
            <Card.Header className='border-0 align-items-center'>
              <Box
                display='flex'
                alignItems='center'
                marginTop='32px'
                sx={{ cursor: 'pointer' }}
                onClick={handleBack}>
                <IconButton aria-label='go back' size='medium'>
                  <ArrowBackIcon />
                </IconButton>
                <OATypography variant='subtitle1'>{`Back to ${params?.orderType} orders`}</OATypography>
              </Box>
            </Card.Header>
            <Card.Body className='ms-2'>
              <GroupOrderDetails />
            </Card.Body>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-7 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link to={``} className={getClassNames('')}>
                  Overview
                </Link>
              </li>
            </ul>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
