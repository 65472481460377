import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { Form, Formik, FormikProps } from 'formik';

import OATextField from '../../../../../components/fields/OATextField';
import { useOrderContext } from '../../../context/OrderContext';
import { IOrderType } from '../../../types';
import OATagsBasedInput from '../../../../../components/OATagsBasedInput';

const init = {
  clientName: '',
  paymentReference: '',
  tags: ''
};

interface IOtherInfoFormModal {
  show: boolean;
  onHide: () => void;
}

export default function OtherInfoFormModal(props: IOtherInfoFormModal) {
  const params = useParams<{ orderType: IOrderType; id?: string }>();
  const { orderData, updateOrderInfo } = useOrderContext();

  const [initValues, setInitValues] = useState<any>(init);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (params?.id && orderData?.data?.additionalOrderInfo) {
      let draft: any = {};
      if (orderData?.data?.additionalOrderInfo?.clientName !== '')
        draft.clientName = orderData?.data?.additionalOrderInfo?.clientName;
      if (orderData?.data?.additionalOrderInfo?.clientName !== '')
        draft.paymentReference = orderData?.data?.additionalOrderInfo?.paymentReference;
      if (orderData?.data?.additionalOrderInfo?.tags?.length)
        draft.tags = orderData?.data?.additionalOrderInfo?.tags;

      setInitValues(draft);
    } else {
      setInitValues(init);
    }
  }, [params?.id, orderData]);

  const handleSubmit = (values: any) => {
    setIsLoading(true);
    const draftValues = {
      additionalValues: {
        clientName: values?.clientName,
        paymentReference: values?.paymentReference,
        tags: values?.tags
      }
    };

    updateOrderInfo(params?.orderType as IOrderType, orderData?.data?.id, draftValues, _res => {
      setIsLoading(false);
      props?.onHide();
    });
  };

  return (
    <Modal centered {...props}>
      <Modal.Header closeButton>
        <Modal.Title>Edit other information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik initialValues={initValues} onSubmit={handleSubmit} enableReinitialize>
          {(formikProps: FormikProps<any>) => {
            const handleChange = (_: any, newValue: string[]) => {
              formikProps?.setFieldValue('tags', newValue);
            };

            return (
              <Form className='order-summary-form'>
                <Row className='mb-10'>
                  <Col>
                    <OATextField name='clientName' label='Client name' />
                  </Col>
                </Row>
                <Row className='mb-10'>
                  <Col>
                    <OATextField name='paymentReference' label='Payment reference' />
                  </Col>
                </Row>
                <Row className='mb-10'>
                  <Col>
                    <label className='form-label'>Search tags</label>
                    <OATagsBasedInput
                      textFieldName='tags'
                      inputTagId='tags-filled'
                      textFieldPlaceholder='Press comma (",") to create a new tag'
                      inputValue={orderData?.data?.additionalOrderInfo?.tags}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <div className='d-flex justify-content-end'>
                  <Button
                    size='sm'
                    onClick={props?.onHide}
                    disabled={isLoading}
                    className='btn-light-primary btn-active-light-primary me-3'>
                    Cancel
                  </Button>
                  <Button size='sm' type='submit' disabled={isLoading}>
                    {isLoading ? (
                      <React.Fragment>
                        Saving...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2' />
                      </React.Fragment>
                    ) : (
                      'Save'
                    )}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
