import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import moment from 'moment';

import { OA_DATE_TIME_FORMAT_FE } from '../../../constants';
import { CreatedOnCell, DefaultCell, StatusCell } from '../../../components/OAColumn';
import TravellerForm from '../order-create/components/AddTravellers/TravellerForm';
import { useOrderContext } from '../context/OrderContext';
import { setDateFormat, showDialog } from '../../../helper';
import OATableComponent, {
  GridColumnHeader,
  OATablePaginationProps
} from '../../../components/OATableComponent';
import { IOrderType } from '../types';
import OAModalComponent from '../../../components/OAModalComponent';
import { useAuth } from '../../../auth';
import { useAppContext } from '../../../context/AppContext';
import { OAStatusChip } from '../../../components/widgets';
import { IDataExchangeVisaStatus } from '../../../components/widgets/OAStatusChip';

const columns = (currentUser: any, dataExchange: any) => [
  {
    renderHeader: (params: any) => <GridColumnHeader title='Order ID' params={params} />,
    field: 'orderId',
    renderCell: (params: any) => <DefaultCell data={params?.value} />,
    valueGetter: (params: any) => params?.row?.id,
    minWidth: 150
  },
  {
    renderHeader: (params: any) => <GridColumnHeader title='Traveller Name' params={params} />,
    field: 'travelerName',
    renderCell: (params: any) => <DefaultCell data={params?.value} />,
    valueGetter: (params: any) => {
      const linkedMembers = params?.row?.members;
      return <DefaultCell data={`${linkedMembers[0]?.firstName} ${linkedMembers[0]?.lastName}`} />;
    },
    flex: 1,
    minWidth: 200
  },
  {
    renderHeader: (params: any) => <GridColumnHeader title='Document Status' params={params} />,
    field: 'documentStatus',
    renderCell: (params: any) => <StatusCell status={params?.value} />,
    valueGetter: (params: any) => {
      const linkedMembers = params?.row?.members;
      const hasDocumentUrl =
        linkedMembers[0]?.documents?.length > 0 &&
        linkedMembers[0]?.documents?.every((obj: any) => obj.hasOwnProperty('documentUrl'));

      return hasDocumentUrl ? 'COMPLETED' : 'INCOMPLETE';
    },
    width: 150
  },
  {
    renderHeader: (params: any) => <GridColumnHeader title='Order Status' params={params} />,
    field: 'OrderStatus',
    renderCell: (params: any) => {
      const hasConsumerAccess =
        currentUser?.data?.organizationId === params?.value?.createdByUser?.organizationId;

      const statusObj: IDataExchangeVisaStatus = dataExchange['VISA_STATUS']
        ?.filter((obj: IDataExchangeVisaStatus) => {
          if (hasConsumerAccess) {
            return obj?.organizationTypes?.includes('CONSUMER');
          } else {
            return (
              obj?.organizationTypes?.includes('SUPPLIER') ||
              obj?.organizationTypes?.includes('ON_ARRIVAL')
            );
          }
        })
        ?.find((obj: IDataExchangeVisaStatus) => obj?.value === params?.row?.bookingStatus);

      return (
        <OAStatusChip
          status={statusObj?.value}
          label={statusObj?.label}
          variant={statusObj?.type}
        />
      );
    },
    valueGetter: (params: any) => params?.row?.bookingStatus,
    width: 120
  },
  {
    renderHeader: (params: any) => <GridColumnHeader title='Created At' params={params} />,
    field: 'createdAt',
    renderCell: (params: any) => (
      <CreatedOnCell date={params?.value} dateFormat={OA_DATE_TIME_FORMAT_FE} />
    ),
    valueGetter: (params: any) => params?.row?.createdAt,
    width: 170
  },
  {
    renderHeader: (params: any) => <GridColumnHeader title='Updated At' params={params} />,
    field: 'updatedAt',
    renderCell: (params: any) => (
      <CreatedOnCell date={params?.value} dateFormat={OA_DATE_TIME_FORMAT_FE} />
    ),
    valueGetter: (params: any) => params?.row?.updatedAt,
    width: 170
  }
];

interface IGroupOrderTabel {
  data: any;
  isLoading: boolean;
  queryData: any;
}

const GroupOrderTabel = (props: IGroupOrderTabel) => {
  const { queryParams, setQueryParams } = props?.queryData?.queryData?.data;
  const { data, isLoading } = props;
  const { currentUser } = useAuth();
  const { dataExchange } = useAppContext();
  const { pageInfo, getGroupById, onSelectEditTraveller, onRemoveConsulate } = useOrderContext();
  const navigate = useNavigate();
  const params = useParams<{ orderType: IOrderType; id?: string }>();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [activeMember, setActiveMember] = useState<any | null>({ orderId: '', member: {} });
  const [isFormDirty, setIsFormDirty] = useState(false);

  useEffect(() => {
    getGroupById(params?.orderType as IOrderType, params?.id);
  }, [queryParams]);

  const handleRowClick = (group: any) => {
    const memberData: any = group?.row?.members[0];
    const selectedConsulate = group?.row?.consulate;
    onSelectEditTraveller(memberData?.id as string);
    const { passport, dateOfBirth, address } = memberData;

    setActiveMember({
      orderId: group?.id,
      member: {
        ...memberData,
        consulate: selectedConsulate,
        dateOfBirth: moment(new Date(dateOfBirth)),
        passportNumber: passport?.number,
        passportExpiration: passport?.expiration
          ? setDateFormat.startOfDayForBE(passport?.expiration)
          : '',
        passportIssuingCountry: passport?.issuingCountry ?? 'IN',
        passportIssuingPlace: passport?.issuingPlace,
        passportIssuingDate: passport?.issuingDate
          ? setDateFormat.startOfDayForBE(passport?.issuingDate)
          : '',
        line1: address?.line1,
        country: address?.country ?? 'IN',
        state: address?.state,
        postalCode: address?.postalCode,
        birthCountry: memberData?.birthCountry ?? '',
        birthPlace: memberData?.birthPlace ?? '',
        fatherName: memberData?.fatherName ?? '',
        maritalStatus: memberData?.maritalStatus ?? '',
        religion: memberData?.religion ?? '',
        profession: memberData?.profession ?? '',
        motherName: memberData?.motherName ?? ''
      }
    });
    if (group?.row?.consulate) {
      navigate(`/apps/orders/visa/${group?.id}/travel-details${location?.search}`);
    } else {
      setIsOpen(true);
    }
  };

  const handleCloseModal = (cb?: () => void) => {
    if (isFormDirty) {
      showDialog({
        title: 'Are you sure?',
        text: 'You will lose all the data you have entered',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, close it!',
        cancelButtonText: 'No, keep it'
      }).then(result => {
        if (result.isConfirmed) {
          setIsOpen(prev => !prev);
          onRemoveConsulate();
          cb?.();
        }
      });
    } else {
      setIsOpen(prev => !prev);
    }
  };

  const tableColumns: any = useMemo(() => columns(currentUser, dataExchange), [data]);

  const paginationProps: OATablePaginationProps = {
    pageIndex: queryParams?.pageIndex,
    pageCount: pageInfo?.totalPages,
    total: pageInfo?.totalCount,
    pageSize: 50,
    onPageChange: (value: any) => {
      setQueryParams((prev: any) => ({ ...prev, pageIndex: value }));
    }
  };

  return (
    <React.Fragment>
      <Card>
        <Card.Body className='p-0 px-2'>
          <OATableComponent
            columns={tableColumns}
            data={data ?? []}
            loading={isLoading}
            disableColumnMenu={false}
            onCellClick={handleRowClick}
            pagination={paginationProps}
          />
        </Card.Body>
      </Card>

      <OAModalComponent
        show={isOpen}
        onHide={handleCloseModal}
        dialogClassName='modal-1240w'
        backdrop='static'
        header={{
          title: 'Add New Traveller',
          closeButton: true
        }}
        body={{
          content: (
            <TravellerForm
              data={activeMember?.member ?? null}
              onHide={() => setIsOpen(false)}
              onCancel={handleCloseModal}
              type={'single'}
              resetFormVal={() => ({})}
              button={1}
              travellerId={activeMember?.orderId ?? null}
              isFormDirty={isFormDirty}
              setIsFormDirty={setIsFormDirty}
            />
          )
        }}
      />
    </React.Fragment>
  );
};

export default GroupOrderTabel;
