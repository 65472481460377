import { Fragment } from 'react';
import { ErrorMessage, useField } from 'formik';
import classNames from 'classnames';

interface IOATextAreaField {
  // type?: string;
  label?: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  inputClassName?: string;
}

export default function OATextAreaField(props: IOATextAreaField) {
  const {
    // type = 'textarea',
    label,
    name,
    required = false,
    inputClassName = '',
    placeholder = 'Type here'
  } = props;

  const [field, _meta, _helpers] = useField(name);

  const labelClassNames = classNames('form-label', {
    required: required
  });

  const inputClasses = classNames(
    inputClassName,
    'form-control form-control-sm form-control-solid'
  );

  return (
    <Fragment>
      {label ? (
        <label htmlFor={name} className={labelClassNames}>
          {label}
        </label>
      ) : null}
      <div>
        <textarea placeholder={placeholder} className={inputClasses} {...field} />
      </div>
      <div className='text-danger'>
        <ErrorMessage name={name} />
      </div>
    </Fragment>
  );
}
