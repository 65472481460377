/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import intervalToDuration from 'date-fns/intervalToDuration';
import classNames from 'classnames';
import { KTSVG } from '../../../../../_metronic/helpers';
import { useOrderContext } from '../../../context/OrderContext';
import { IOrderType } from '../../../types';
import OABadge from '../../../../../components/widgets/OABadge';
import DeleteSVG from '../../../../../assets/icons/icon_delete.svg';
import { FrameSVG, StarSVG, WarningSVG } from '../../../../../components/Icons';
import { getAgeInYearsOrMonths, removeChar, showDialog } from '../../../../../helper';
import { ORDER_STATUS, ORDER_TYPE } from '../../../constants';
import { useAuth } from '../../../../../auth';
import DetailsContainer from './DetailsContainer';
import TravellerDocuments from './TravellerDocuments';

interface ITravellerCard {
  index: number;
  data: any;
  toggleModal: () => void;
  headerComponent: JSX.Element | null;
  isEditable: boolean;
  className?: string;
}

export default function TravellerCard(props: ITravellerCard) {
  const { data } = props;
  const params = useParams<{ orderType: IOrderType; id?: string }>();
  const { currentUser } = useAuth();

  const { orderData, orderActions, getOrderById, onSelectEditTraveller, onEditDraftOrder } =
    useOrderContext();

  const [docsByType, setDocsByType] = useState<{ upload: Array<any>; info: Array<any> }>({
    upload: [],
    info: []
  });

  const hasSupplierViewAccess = currentUser?.data?.organization?.type === 'SUPPLIER';
  const hasConsumerViewAccess = currentUser?.data?.organization?.type === 'CONSUMER';

  useEffect(() => {
    if (data?.documents?.length) {
      setDocsByType({
        upload: data?.documents?.filter((obj: any) => obj?.fieldType === 'upload'),
        info: data?.documents?.filter((obj: any) => obj?.fieldType === 'info')
      });
    }
  }, [data]);

  const fullName = `${data?.firstName} ${data?.lastName?.split()[0]}`;
  const shortName = data?.firstName?.charAt?.(0)?.toUpperCase?.();
  const isOrderCompleted = orderData?.data?.bookingStatus === ORDER_STATUS.COMPLETED;
  const addressTemp = data?.address;
  const line1 = addressTemp?.line1 ?? 'N/A';
  const state = addressTemp?.state ?? 'N/A';
  const postalCode = addressTemp?.postalCode ?? 'N/A';
  const country = addressTemp?.country ?? 'N/A';
  const address = `${line1 ? `${line1}, ` : ''} ${state ? `${state}, ` : ''} ${
    postalCode ? `${postalCode}, ` : ''
  } ${country ? `${country} ` : ''}`;
  const pendingDocCount = docsByType?.upload?.filter((doc: any) => !doc?.uploaded)?.length;
  const warningBadgeText = `${pendingDocCount} document(s) pending`;
  const age = getAgeInYearsOrMonths(data?.dateOfBirth).replace('years', '').trim();
  const policy = orderData?.data?.issuedPolicies?.find(
    (obj: any) => obj?.linkedMember === data?.id
  );

  const handleEditTraveller = () => {
    onSelectEditTraveller(data?.id as string);
    props?.toggleModal();
  };

  const handleDeleteTraveller = () => {
    showDialog({
      title: 'Delete traveller?',
      text: 'Are you sure you want to delete this traveller?',
      icon: 'warning'
    }).then(result => {
      if (result?.isConfirmed) {
        const draftValues = {
          memberId: data?.id
        };
        onEditDraftOrder(
          orderData?.data?.id,
          params?.orderType as IOrderType,
          draftValues,
          _res => {
            getOrderById(params?.orderType as IOrderType, orderData?.data?.id);
          }
        );
      }
    });
  };

  const handlePrimaryTraveller = () => {
    if (data?.primary) {
      showDialog({
        title: `Oops...`,
        text: `Already a primary traveller!`,
        icon: `error`,
        showCancelButton: false,
        confirmButtonText: `Ok`
      });
    } else {
      const travellerAge: Duration = intervalToDuration({
        start: data?.dateOfBirth ? new Date(data?.dateOfBirth) : 0,
        end: orderData?.data?.dateOfTravel ? new Date(orderData?.data?.dateOfTravel) : 0
      });
      if (travellerAge?.years! < 18) {
        showDialog({
          title: `Oops...`,
          text: `Traveller age below 18 can't be a Primary traveller.`,
          icon: `error`,
          showCancelButton: false,
          confirmButtonText: `Ok`
        });
      } else {
        showDialog({
          title: 'Change primary traveller?',
          text: 'Are you sure you want to make this traveller as primary?',
          icon: 'warning'
        }).then(result => {
          if (result?.isConfirmed) {
            const draftValues = {
              primaryMemberId: data?.id
            };
            onEditDraftOrder(
              orderData?.data?.id,
              params?.orderType as IOrderType,
              draftValues,
              _res => {
                getOrderById(params?.orderType as IOrderType, orderData?.data?.id);
              }
            );
          }
        });
      }
    }
  };

  /**
   * NOTE: filter out member info specific actions only ...
   */
  const draftMemberData = orderActions?.data?.filter(
    (action: any) =>
      (action?.type === 'MEMBER_INFO' || action?.type === 'MEMBERS') &&
      action?.memberId === data?.id
  );

  let memberTableData = [];
  if (hasSupplierViewAccess && draftMemberData?.length) {
    const possibleStatusArr = ['CHANGES_REQUESTED', 'COMPLETED', 'IN_REVIEW'];
    memberTableData = draftMemberData?.filter((action: any) =>
      possibleStatusArr?.includes(action?.status)
    );
  } else if (hasConsumerViewAccess && draftMemberData?.length) {
    memberTableData = draftMemberData?.filter(
      (action: any) => action?.status === 'CHANGES_REQUESTED'
    );
  }

  const isSupplierOrder = !!orderData?.data?.supplierOrder;
  const isMemberInfoChangeRequested = memberTableData?.some(
    (obj: any) => obj?.type === 'MEMBER_INFO'
  );

  return (
    <div className={classNames('order-view--traveller-card card', props?.className ?? '')}>
      {props?.headerComponent}

      <div id={`traveller_${removeChar(data?.id)}_info`} className='card-body pb-6'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <Button
                    variant='light'
                    size='sm'
                    className='w-20px h-20px p-0 me-3'
                    onClick={handlePrimaryTraveller}
                    disabled={!props?.isEditable}>
                    <StarSVG height='18' width='18' isActive={data?.primary} />
                  </Button>
                  <div className='symbol symbol-circle symbol-20px overflow-hidden me-2'>
                    <div className='symbol-label fs-7 bg-info text-light'>{shortName}</div>
                  </div>
                  <span className='d-flex flex-column text-capitalize fw-bolder fs-5'>
                    {fullName}
                  </span>
                  <span className='badge ms-2 badge-light fs-8 fw-bold'>
                    {!age.includes('months') && parseInt(age) >= 18
                      ? 'Adult'
                      : !age.includes('months') && parseInt(age) >= 1
                      ? 'Child'
                      : 'Infant'}
                  </span>
                  {pendingDocCount > 0 ? (
                    <OABadge
                      type='light-danger'
                      label={
                        <React.Fragment>
                          <WarningSVG height='16' width='16' className='me-1' />
                          {warningBadgeText}
                        </React.Fragment>
                      }
                      className='ms-5'
                    />
                  ) : null}
                </div>
                <div className='d-flex flex-wrap fw-400 fs-6 pe-2'>
                  <span className='d-flex ps-2 fs-6'>
                    <FrameSVG />
                    {address}
                  </span>
                </div>
              </div>
              <div className='d-flex my-4'>
                {props?.isEditable ||
                (isMemberInfoChangeRequested &&
                  (hasConsumerViewAccess || (hasSupplierViewAccess && isSupplierOrder))) ? (
                  <Button
                    variant='light'
                    size='sm'
                    className='btn-icon btn-active-light-primary'
                    onClick={handleEditTraveller}
                    title='Edit'>
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                  </Button>
                ) : null}
                {props?.isEditable && !data?.primary && (
                  <Button
                    variant='light'
                    size='sm'
                    className='w-35px h-35px p-0 ms-3'
                    onClick={handleDeleteTraveller}
                    title='Delete'>
                    <KTSVG path={DeleteSVG} className='svg-icon-1 m-0' />
                  </Button>
                )}
                {params?.orderType === ORDER_TYPE.INSURANCE && policy && isOrderCompleted ? (
                  <a
                    href={policy?.policyUrl}
                    target='_BLANK'
                    rel='noreferrer'
                    className='btn btn-sm btn-light-primary btn-active-light-primary ms-3'
                    download>
                    Download
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card-body pt-0 pb-2 border-grey-200'>
        <DetailsContainer data={data} />
      </div>
      <TravellerDocuments memberData={data} orderData={orderData?.data} />
    </div>
  );
}
