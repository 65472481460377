import { monthsToYears } from 'date-fns';
import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import InsuranceBenefitsModal from '../../insurance/AddInsuranceCover/InsuranceBenefitsModal';

interface IInsuranceProductCard {
  product: any;
}

const InsuranceProductCard = (props: IInsuranceProductCard) => {
  const { product } = props;
  const prodInfo = product?.productInformation;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [ageGroupText, setAgeGroupText] = useState<string>('');

  useEffect(() => {
    if (prodInfo?.ageGroup) {
      const getUnit = (num: any, type: 'Month' | 'Year') => {
        if (num === 1) return `${type}`;
        else return `${type}s`;
      };
      // TODO: calculate month and year properly
      /**
       * if maxAge = 842
       * year = Math.floor(842/12) // 70
       * month = 842%12 // 2
       * draftTo = "70 Years 2 Months"
       */
      const draftFrom =
        prodInfo?.ageGroup?.minAge > 12
          ? `${monthsToYears(prodInfo?.ageGroup?.minAge)} ${getUnit(
              monthsToYears(prodInfo?.ageGroup?.minAge),
              'Year'
            )}`
          : `${prodInfo?.ageGroup?.minAge} ${getUnit(prodInfo?.ageGroup?.minAge, 'Month')}`;
      const draftTo =
        prodInfo?.ageGroup?.maxAge > 12
          ? `${monthsToYears(prodInfo?.ageGroup?.maxAge)} ${getUnit(
              monthsToYears(prodInfo?.ageGroup?.maxAge),
              'Year'
            )}`
          : `${prodInfo?.ageGroup?.maxAge} ${getUnit(prodInfo?.ageGroup?.maxAge, 'Month')}`;

      setAgeGroupText(`${draftFrom} - ${draftTo}`);
    }
  }, [prodInfo]);

  return (
    <div className='notice rounded border border-dashed p-6 mb-6 bg-light-secondary border-secondary'>
      <div className='d-flex flex-stack flex-grow-1'>
        <div className='fw-bold'>
          <h6 className='text-gray-800 fw-bolder'>{prodInfo?.name}</h6>
          {ageGroupText ? <div className='fs-6 text-gray-600'>[{ageGroupText}]</div> : null}
        </div>
      </div>
      <Row>
        {prodInfo?.benefits?.map((benefit: any, index: number) => {
          if (index <= 3) {
            return (
              <Col lg={6} key={index}>
                {index !== 3 ? (
                  <div className='d-flex flex-column justify-content-center'>
                    <p className='text-gray-800 fs-7 fw-bold m-0'>{benefit?.name ?? ''}</p>
                    <p className='text-gray-600 fs-6 mb-2'>{benefit?.sumInsured ?? ''}</p>
                  </div>
                ) : (
                  <div className='d-flex justify-content-sm-between me-16'>
                    <div className='d-flex flex-column justify-content-center'>
                      <p className='text-gray-800 fs-7 fw-bold m-0'>Sum Insured</p>
                      <p className='text-gray-600 fs-6 mb-2'>{prodInfo?.sumInsured ?? 'N/A'}</p>
                    </div>
                    <div className='fw-bold d-flex align-items-center h-100'>
                      <span className='fs-8 btn btn-link' onClick={() => setIsOpen(true)}>
                        {'View more details >>'}
                      </span>
                    </div>
                  </div>
                )}
              </Col>
            );
          }
        })}
      </Row>
      <InsuranceBenefitsModal
        show={isOpen}
        onHide={() => setIsOpen(false)}
        data={{
          name: prodInfo?.name,
          benefits: prodInfo?.productInformation?.benefits
        }}
      />
    </div>
  );
};

export default InsuranceProductCard;
