import { useContext, useEffect, useState } from 'react';
import OAToolbar from '../../../components/widgets/OAToolbar';
import LedgerContext, { ILedgerContext } from '../context/LedgerContext';
import AccountBalance from './AccountBalance';
import BreadcrumbItem from './BreadcrumbItem';
import TransactionHistory from './TransactionHistory';

interface IListQueryParams {
  data: any;
}
const List = (props: IListQueryParams) => {
  const { queryParams, setQueryParams } = props?.data;

  const { getLedgerList, getCurrentBalance }: ILedgerContext = useContext(LedgerContext);

  const [list, setList] = useState<Array<any>>([]);
  const [isRefresh, setIsRefresh] = useState<boolean>(false);
  const [data, setData] = useState<any>({});
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggle = () => {
    setIsOpen(prev => !prev);
  };

  const getBalance = () => {
    setIsRefresh(true);
    getCurrentBalance(res => {
      setData(res);
      setIsRefresh(false);
    });
  };

  useEffect(() => {
    getBalance();
  }, []);

  useEffect(() => {
    if (queryParams?.dateRange[0] && queryParams?.dateRange[1]) {
      getLedgerList(res => {
        setList(res?.content);
      });
    }
  }, [queryParams]);

  const onChangeRangePicker = (range: any) => {
    setQueryParams((prev: any) => ({ ...prev, dateRange: range }));
  };

  return (
    <>
      <OAToolbar title={'Ledger'} breadcrumb={BreadcrumbItem()} />
      <AccountBalance
        toggle={toggle}
        isOpen={isOpen}
        isRefresh={isRefresh}
        getBalance={getBalance}
      />
      <TransactionHistory
        list={list}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        onChangeRangePicker={onChangeRangePicker}
      />
    </>
  );
};

export default List;
