import { useState } from 'react';
import { Box, Button, Modal, Stack } from '@mui/material';
import { OATypography } from '../../../components/widgets';
import signupBgImg from '../../../assets/images/signup_user_bg_old.svg';
import paymentBgImg from '../../../assets/images/upgrad_payment_bg.svg';
import PaymentPlanCard from './PaymentPlanCard';
import FeaturesList from './FeaturesList';
import OAButton from '../../../components/widgets/OAButton';
import { usePhonepe } from '../../../hooks/usePhonepe';
import { showToast } from '../../../helper';
import { getUserByToken } from '../../../auth/core/_requests';
import { useAuth } from '../../../auth';
import { encryptStorage } from '../../../helper/storage';

// At the top of your component file
declare global {
  interface Window {
    Razorpay: any;
  }
}

const boxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 370, // Adjust according to your design
  bgcolor: '#FDFDFE',
  p: '32px 40px 24px 40px',
  borderRadius: '8px',
  backgroundPosition: 'top left',
  backgroundRepeat: 'no-repeat'
};

const Payment = () => {
  const { paymentInitiateForSignup } = usePhonepe();
  const { currentUser, setCurrentUser } = useAuth();
  const cu = currentUser?.data;

  const [openPlan, setOpenPlan] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState('yearly');

  const handleClosePlan = () => setOpenPlan(false);

  const handleSelectPlan = (plan: any) => {
    setSelectedPlan(plan);
  };

  const currentUserHandler = async () => {
    const token = JSON.parse(localStorage?.getItem('kt-auth-react-v') as any)?.data?.accessToken;
    const { data: user } = await getUserByToken(token);
    setCurrentUser(user);
    //setting up live mode by default
    if (user?.data?.organization?.liveModeEnabled === true) {
      localStorage.setItem('oaMode', 'live');
    } else {
      localStorage.setItem('oaMode', 'test');
    }

    encryptStorage.setItem('oa_orgnization_id', user?.data?.organization?.id);
    window.dispatchEvent(new Event('mode_switch'));
  };

  const handleClickPayment = async () => {
    try {
      setIsLoading(true);
      let draft = {
        type: 'GUEST_VISA_INFORMATION',
        redirectUrl: `${window.location.protocol}//${window.location.host}/apps/visa-information`,
        plan: {
          name: 'VISA_INFO',
          unit: selectedPlan === 'yearly' ? 'YEAR' : 'MONTH',
          duration: 1
        }
      };
      let paymentResult = await paymentInitiateForSignup(draft);

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
        amount: paymentResult?.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: 'INR',
        name: 'OnArrival Travel Technology Private Limited.', //your business name
        description: 'Signup - Visa information Transaction',
        order_id: paymentResult?.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        prefill: {
          //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
          name: `${cu?.firstName} ${cu?.lastName}`, //your customer's name
          email: cu?.email
        },
        theme: {
          color: '#3A86FF'
        },
        handler: function (response: any) {
          currentUserHandler();
          handleClosePlan();
        }
      };
      setIsLoading(false);
      var rzp1 = new window.Razorpay(options);
      // rzp1.on('payment.failed', function (response: any) {

      //   console.info('response', response);

      // });
      rzp1.open();
    } catch (error: any) {
      setIsLoading(false);
      showToast(error?.response?.data?.errors);
    }
  };

  return (
    <>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        sx={{ bgcolor: '#0E1F34', p: '4px', position: 'absolute', left: 0, width: '100%' }}>
        <OATypography variant='subtitle2' sx={{ color: '#ffffff' }}>
          Upgrade your plan now! Get access to over 180+ countries!
        </OATypography>
        {/* Not using OAButton, this is custom button */}
        <Button
          onClick={() => setOpenPlan(true)}
          sx={{
            'bgcolor': '#FFFFFF',
            'borderRadius': '4px',
            'p': '2px 8px',
            'ml': '10px',
            '&: hover': {
              bgcolor: '#FFFFFF'
            }
          }}>
          <OATypography variant='subtitle2' sx={{ color: '#0E1F34', textTransform: 'capitalize' }}>
            Explore plans
          </OATypography>
        </Button>
      </Box>
      <Box sx={{ height: '32px', mb: '20px' }}></Box>
      {/* Plan Modal */}
      <Modal open={openPlan} onClose={handleClosePlan} sx={{ borderRadius: '16px' }}>
        <Box sx={{ ...boxStyle, backgroundImage: `url(${signupBgImg})` }}>
          <img src={paymentBgImg} alt='user icon' />
          <OATypography variant='h2' sx={{ mt: '16px' }}>
            Upgrade your plan
          </OATypography>
          <OATypography
            variant='subtitle1'
            sx={{ color: theme => theme?.palette?.grey[600], mb: '24px' }}>
            Get access to our complete visa info list
          </OATypography>
          <Stack direction='row' justifyContent='center' alignItems='center' spacing={4}>
            <PaymentPlanCard
              onClick={handleSelectPlan}
              plan='monthly'
              selected={selectedPlan === 'monthly'}
              price='₹ 4,999'
            />
            <PaymentPlanCard
              onClick={handleSelectPlan}
              plan='yearly'
              selected={selectedPlan === 'yearly'}
              price='₹ 9,999'
              saving='SAVE 50%'
              actualPrice='₹ 19996/year'
            />
          </Stack>
          <FeaturesList />
          <OAButton
            label={'Make payment'}
            onClick={handleClickPayment}
            isLoading={isLoading}
            loadingText='Please wait..'
            disabled={isLoading}
            className='w-100 mt-6'
          />
        </Box>
      </Modal>
    </>
  );
};

export default Payment;
