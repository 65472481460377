import Skeleton from 'react-loading-skeleton';
import sum from 'lodash/sum';
import OaLine from '../../../components/Chart/OaLine';
import OANoData from '../../../components/Common/OANoData';
import { KTCard, KTCardBody, KTSVG } from '../../../_metronic/helpers';
import { formatNumberForCurrency } from '../../../helper';
interface IOrderRevenue {
  title: string;
  dateRange: any;
  isLoading: boolean;
  series: Array<{ name: string; data: Array<any> }>;
  categories: Array<string>;
}

const OrderRevenue = (props: IOrderRevenue) => {
  const { title, dateRange, isLoading = false, series, categories } = props;
  return (
    <KTCard className='h-100 overflow-hidden'>
      <span className='menu-icon position-absolute top-0 end-0 p-2'>
        <KTSVG path='/media/icons/duotune/Move.svg' className='svg-icon-2' />
      </span>
      <KTCardBody className='pb-0'>
        <div className='d-flex flex-stack flex-wrap'>
          <div className='fw-bold flex-grow-1'>
            <h3 className='mb-1'>{title}</h3>
            <p className='fs-7 text-muted mb-0'>{dateRange}</p>
          </div>
          {title === 'Revenue' ? (
            <Revenue series={series} />
          ) : (
            <OrderStatus series={series} isLoading={isLoading} />
          )}
        </div>
        <div>
          {isLoading ? (
            <div className='mt-10 pt-10'>
              <Skeleton height={200} />
            </div>
          ) : series && series.length > 0 ? (
            <OaLine series={series} categories={categories} />
          ) : (
            <OANoData icon='/media/icons/duotune/graphs/gra003.svg' />
          )}
        </div>
      </KTCardBody>
    </KTCard>
  );
};

export default OrderRevenue;

const OrderStatus = ({
  series,
  isLoading
}: {
  series: Array<{ name: string; data: Array<any> }>;
  isLoading: boolean;
}) => {
  const totalPendingOrders = series?.find((obj: any) => obj.name === 'Pending orders');
  const totalProcessedOrders = series?.find((obj: any) => obj.name === 'Processed orders');
  return (
    <div className='d-flex align-items-center'>
      <div>
        <div className='d-flex align-items-center'>
          <span className='bullet bullet-dot bg-primary me-3 h-10px w-10px'></span>
          <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>
            {isLoading ? (
              <Skeleton height={32} width={32} borderRadius='2rem' />
            ) : (
              totalPendingOrders && sum(totalPendingOrders.data)
            )}
          </span>
        </div>
        <span className='fs-7 text-muted fw-bold'>Pending</span>
      </div>
      <div className='ms-10'>
        <div className='d-flex align-items-center'>
          <span className='bullet bullet-dot bg-info me-3 h-10px w-10px'></span>
          <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>
            {isLoading ? (
              <Skeleton height={32} width={32} borderRadius='2rem' />
            ) : (
              totalProcessedOrders && sum(totalProcessedOrders.data)
            )}
          </span>
        </div>
        <span className='fs-7 text-muted fw-bold'>Processed</span>
      </div>
    </div>
  );
};

const Revenue = ({ series }: { series: Array<{ name: string; data: Array<any> }> }) => {
  return (
    <div className='d-flex align-items-center'>
      <span className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'>₹</span>
      <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>
        {series?.length > 0
          ? formatNumberForCurrency(Math.round(sum(series[0].data)), { toFixed: false })
          : 0}
      </span>
    </div>
  );
};
