import { useState } from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Container, Row, Col } from 'react-bootstrap';
import OATextField from '../../../../../components/fields/OATextField';
import OAButton from '../../../../../components/widgets/OAButton';
import OADatePickerField from '../../../../../components/fields/OADatePickerField';
import * as Yup from 'yup';
import { useOrderContext } from '../../../context/OrderContext';
import { awsBucketName } from '../../../../../hooks/useS3FileOperation';

interface IDummyFormModal {
  onHide: () => void;
  orderData: any;
  dummyLoader: (props: boolean) => void;
}
const init = {
  onwardDepartureCity: '',
  onwardArrivalCity: '',
  returnDepartureCity: '',
  returnArrivalCity: ''
};

const ItinerarySchema = Yup.object().shape({
  onwardDepartureCity: Yup.string().required('Departure City - (onward flight) is required'),
  onwardArrivalCity: Yup.string().required('Arrival City - (onward flight) is required'),
  returnDepartureCity: Yup.string().required('Departure City - (return flight) is required'),
  returnArrivalCity: Yup.string().required('Arrival City - (return flight) is required')
});

export default function DummyForm(props: IDummyFormModal) {
  const { createDummyFlight } = useOrderContext();

  const [initValues, setInitValues] = useState<any>(init);

  const handleSubmit = (values: any, actions: FormikHelpers<any>) => {
    values['orderData'] = props?.orderData;
    values['bucketName'] = awsBucketName;
    props?.onHide();
    props?.dummyLoader(true);
    createDummyFlight(values, async (res: any) => {
      props?.dummyLoader(false);
    });
  };

  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleSubmit}
      validationSchema={ItinerarySchema}
      enableReinitialize>
      {(formikProps: FormikProps<any>) => {
        return (
          <Container fluid as={Form} className='p-0'>
            <h5>Onward Flights</h5>
            <Row className='mb-4 mt-4'>
              <Col lg={6}>
                <OATextField name='onwardDepartureCity' label='Departure City' required />
              </Col>
              <Col lg={6}>
                <OATextField name='onwardArrivalCity' label='Arrival City' required />
              </Col>
            </Row>

            <h5>Return Flights</h5>
            <Row className='mb-4 mt-4'>
              <Col lg={6}>
                <OATextField name='returnDepartureCity' label='Departure City' required />
              </Col>
              <Col lg={6}>
                <OATextField name='returnArrivalCity' label='Arrival City' required />
              </Col>
            </Row>
            <Row className='mb-4'>
              <Col>
                <OADatePickerField
                  name='appointmentDate'
                  label='Visa appointment/submission date'
                  minDate={new Date()}
                />
              </Col>
            </Row>
            <div className='d-flex justify-content-end mt-5'>
              <OAButton
                className='btn-light-primary btn-active-light-primary me-3'
                label='Cancel'
                onClick={() => {
                  formikProps?.resetForm();
                  props?.onHide();
                }}
              />
              <OAButton
                type='submit'
                disabled={
                  (!formikProps?.dirty && formikProps?.isValid) ||
                  (formikProps?.dirty && !formikProps?.isValid)
                }
                label='Request Dummy Flight'
              />
            </div>
          </Container>
        );
      }}
    </Formik>
  );
}
