/* eslint-disable no-unused-vars */
import React, { Dispatch, useContext } from 'react';
import { ISubmitToEmbassyFormValues } from '../order-view/OrderActions/SubmitToEmbassyModal';

import { ICreateOrderFormState, InvoiceType, IOrderFilterQueryParams, IOrderType } from '../types';
import { IPageInfo } from '../../../components/OATableComponent';
import { IInvoiceRequestBody } from '../helper/getInvoiceRequestBody';
import { ICreateQuoteDraftValues } from '../helper/getDraftQuoteRequest';

export interface IOrderContext {
  ordersData: {
    data: any | null;
    isLoading: boolean;
  };
  orderData: {
    data: any | null;
    isLoading: boolean;
  };
  orderActions: {
    data: any | null;
    isLoading: boolean;
  };
  visaInfoProducts: { data: Array<any> | null; isLoading: boolean };
  products: Array<any> | null;
  formState: ICreateOrderFormState;
  travellerId: string | null;
  draftOrder: any | null;
  orderEvents: {
    data: any | null;
    isLoading: boolean;
  };
  pageInfo: IPageInfo;
  isFetchingOcrData: boolean;
  apiResponseFromClassifier: any;
  orderActivityLogs: any;
  getInitQueryParams: () => void;
  updateFormState: (values: any) => void;
  fetchOrders: (type: IOrderType, cb?: ((response: any) => void) | undefined) => void;
  getOrderById: (type: IOrderType, id: string, query?: object | null, cb?: () => void) => void;
  resetOrderData: () => void;
  discoverMasterProduct: (
    type: IOrderType,
    values: any,
    cb?: ((response?: any) => void) | undefined
  ) => Promise<void>;
  onAddTripDetails: (values: any) => void;
  onRemoveTripDetails: () => void;
  onAddTravellerFormFields: (values: any) => void;
  onAddConsulate: (values: any) => void;
  onRemoveConsulate: () => void;
  onAddMarkup: (values: any) => void;
  onGstSubmit: (values: any) => void;
  onAddTravellerDetails: (values: any) => void;
  onEditTravellerDetails: (values: any) => void;
  onDeleteTraveller: (value: string) => void;
  onSetTravellerAsPrimary: (values: { id: Date | number }) => void;
  onAddAddonService: (values: any) => void;
  onRemoveAddonService: (values: any) => void;
  onSelectEditTraveller: (id: string | null) => void;
  onSelectDraftOrder: (values: any) => void;
  resetFormState: () => void;
  visaInfoProduct: (values: any, cb?: ((response?: any) => void) | undefined) => Promise<void>;
  getDraftOrderQuotation: (
    type: IOrderType,
    values?: {
      markup?: any;
      addOnService?: any;
      modifier?: any;
    } | null,
    markupAmount?: any,
    cb?: ((res: any) => void) | undefined
  ) => void;
  onCreateDraftOrder: (type: IOrderType, cb?: ((res: any) => void) | undefined) => void;
  onEditDraftOrder: (
    id: string,
    type: IOrderType,
    values?: any,
    cb?: ((res: any) => void) | undefined
  ) => void;
  onEditOrderInfo: (
    id: string,
    type: IOrderType,
    values?: any,
    cb?: ((res: any) => void) | undefined
  ) => void;
  onSubmitOrder: (
    values: any,
    type: IOrderType,
    orderId: string,
    cb?: ((res?: any) => void) | undefined
  ) => void;
  onCancelOrder: (
    type: IOrderType,
    orderId: string,
    approve: boolean | null,
    values?: any,
    cb?: ((response: any) => void) | undefined
  ) => void;
  onRequestInsuranceCancellation: (
    orderId: string,
    values: any,
    cb?: ((response: any) => void) | undefined
  ) => void;
  onDownloadVisa: (orderId: string, cb?: ((response: any) => void) | undefined) => void;
  onDownloadVisaInvoice: (
    orderId: string,
    invoiceType: InvoiceType,
    cb?: ((response: any) => void) | undefined
  ) => void;
  onDownloadQuote: (
    type: IOrderType,
    markup: { amount: string },
    cb?: ((res: any) => void) | undefined
  ) => void;
  setOrderData: Dispatch<React.SetStateAction<{ data: any | null; isLoading: boolean }>>;
  getOrderActions: (orderId: string, cb?: ((response: any) => void) | undefined) => void;
  setOrderActions: Dispatch<React.SetStateAction<{ data: any | null; isLoading: boolean }>>;
  createOrderAction: (values: object, cb?: ((response: any) => void) | undefined) => void;
  updateOrderAction: (
    actionId: string,
    values: object,
    cb?: ((response: any) => void) | undefined
  ) => void;
  createActionComment: (
    values: object,
    id: string,
    cb?: ((response: any) => void) | undefined
  ) => void;
  approveOrCompleteOrderAction: (
    actionId: string,
    status: 'APPROVED' | 'COMPLETED',
    cb?: ((response: any) => void) | undefined
  ) => void;
  deleteOrderAction: (actionId: string) => void;
  onSubmitReview: (
    type: IOrderType,
    orderId: string,
    values: ISubmitToEmbassyFormValues | null,
    cb?: ((res?: any) => void) | undefined
  ) => void;
  resetProducts: () => void;
  searchAdditionalDocuments: (cb?: ((response: any) => void) | undefined) => void;
  getQuotationById: (type: IOrderType, id: string) => void;
  getCountriesList: (cb?: ((response?: any) => void) | undefined) => Promise<void>;
  assignReviewer: (
    orderId: string,
    userId: string,
    isEdit: boolean,
    cb?: ((response: any) => void) | undefined
  ) => void;
  updateOrderInfo: (
    type: IOrderType,
    orderId: string,
    values: any,
    cb?: ((res?: any) => void) | undefined
  ) => void;
  updateMemberVisaStatus: (
    type: IOrderType,
    orderId: string,
    memberId: string,
    values: any,
    cb?: ((res?: any) => void) | undefined
  ) => void;
  fetchOrderGroups: (type: IOrderType, cb?: ((response: any) => void) | undefined) => void;
  getGroupById: (type: IOrderType, id: string | undefined) => void;
  onAddGroupName: (values: any) => void;
  onCreateGroup: (type: IOrderType) => void;
  createBulkOrderGroup: (
    values: any,
    groupId: string,
    cb?: ((res: any) => void) | undefined
  ) => void;
  onModifyTravellers: () => void;
  createBulkOrder: (
    values: any,
    type: IOrderType,
    productCode?: string,
    cb?: ((res: any) => void) | undefined
  ) => void;
  getOcrResponseByImgUrl: (imgUrl: any, cb?: ((res: any) => void) | undefined) => void;
  createOrderEvent: (values: any, cb?: ((res?: any) => void) | undefined) => void;
  setOrderEvents: Dispatch<React.SetStateAction<{ data: any | null; isLoading: boolean }>>;
  getOrderEventById: (id: string, cb?: ((res?: any) => void) | undefined) => void;
  editOrderEvent: (id: string, values: any, cb?: ((res?: any) => void) | undefined) => void;
  archiveOrderEvent: (
    eventId: string,
    orderId: string,
    cb?: ((res: any) => void) | undefined
  ) => void;
  sendCommentNotification: (orderId: any, comment: any) => void;
  onAddTripBookingInformation: (values: any) => void;
  onAddCategory: (values: any) => void;
  onCreateItinerary: (values: any, cb?: ((res: any) => void) | undefined) => void;
  createDummyFlight: (values: any, cb?: ((res: any) => void) | undefined) => void;
  onDuplicateOrder: (id: string) => void;
  shareLinkWithTraveller: (
    orderId: string,
    emailNotification: boolean,
    cb?: ((res: any) => void) | undefined
  ) => void;
  validateMemberByPassportNumber: (
    passportNumber: string,
    cb?: ((res?: any) => void) | undefined
  ) => void;
  updateProforma: (request: IInvoiceRequestBody, cb?: ((res?: any) => void) | undefined) => void;
  generateInvoice: (values: IInvoiceRequestBody, cb?: (res?: any) => void) => void;
  updateInvoice: (values: IInvoiceRequestBody, invoiceId: string, cb?: (res?: any) => void) => void;
  fetchDocumentsFromClassifier: (
    zipFile: any,
    id: string,
    cb?: ((res?: any) => void) | undefined
  ) => void;
  updateDocumentClassifierAction: (
    memberData: string,
    data: any[],
    selectedDocumentId: string,
    isSubmit: boolean,
    cb?: ((res?: any) => void) | undefined
  ) => void;
  setApiResponseFromClassifier: Dispatch<React.SetStateAction<any>>;
  deleteDocument: (
    visaId: string,
    memberId: string,
    documentId: string,
    cb?: ((res?: any) => void) | undefined
  ) => void;
  uploadDocument(
    visaId: string,
    memberId: string,
    documentId: string,
    file: any,
    fileName: string,
    fileExtension: string,
    cb?: ((res?: any) => void) | undefined
  ): void;
  fetchInsuranceProducts(
    insuranceType: string,
    userId?: string,
    cb?: ((res?: any) => void) | undefined
  ): void;
  onCreateDraftQuote: (
    type: IOrderType,
    values: ICreateQuoteDraftValues,
    cb?: ((response?: any) => void) | undefined
  ) => void;
  onUpdateDraftQuote: (
    id: string,
    type: IOrderType,
    values: ICreateQuoteDraftValues,
    cb?: ((response?: any) => void) | undefined
  ) => void;
  onShareQuotation: (
    values: any,
    type: IOrderType,
    quotationId: string | null,
    cb?: () => void
  ) => void;
  fetchActivityLogs(
    orderId: string,
    isGroup: boolean,
    cb?: ((res?: any) => void) | undefined
  ): void;
  getInsuranceCategory(cb?: ((res?: any) => void) | undefined): void;
  setOrderActivityLogs: Dispatch<React.SetStateAction<any>>;
}

const OrderContext = React.createContext<IOrderContext>({} as IOrderContext);

export default OrderContext;

export const useOrderContext = () => useContext(OrderContext);
