import Skeleton from 'react-loading-skeleton';
import { Card } from 'react-bootstrap';

import { KTSVG } from '../../../_metronic/helpers';
import OANoData from '../../../components/Common/OANoData';

const TopSuppliersTile = ({
  data,
  isLoading,
  dateRange
}: {
  data: any;
  isLoading: boolean;
  dateRange: string;
}) => {
  return (
    <Card className='h-100 overflow-hidden'>
      <Card.Body>
        <div className='fw-bold'>
          <h3 className='mb-1'>Top suppliers</h3>
          <p className='fs-7 text-muted mb-0'>{dateRange}</p>
        </div>
        <div className='table-responsive mt-4'>
          {isLoading ? <SkeletonComponent /> : <SuppliersTable data={data} />}
        </div>
      </Card.Body>
    </Card>
  );
};

export default TopSuppliersTile;

const SuppliersTable = ({ data }: { data: any }) => {
  return (
    <>
      <span className='menu-icon position-absolute top-0 end-0 p-2'>
        <KTSVG path='/media/icons/duotune/Move.svg' className='svg-icon-2' />
      </span>
      {data && data.length > 0 ? (
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          <tbody>
            {data?.map((obj: any) => (
              <tr key={obj?.organizationId}>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      <span className='text-dark fw-bolder fs-6'>{obj?.organizationName}</span>
                      <span className='text-muted fw-bold text-muted d-block fs-7'>Supplier</span>
                    </div>
                  </div>
                </td>
                <td className='text-end'>
                  <span className='text-dark fw-bolder d-block fs-6'>{obj?.totalOrder}</span>
                  <span className='text-muted fw-bold text-muted d-block fs-7'>Orders</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <OANoData icon='/media/icons/duotune/general/gen005.svg' />
      )}
    </>
  );
};

export const SkeletonComponent: React.FC = () => {
  const skeletonItems = [...Array(4)].map((_, index) => (
    <div key={index} className='mb-8'>
      <Skeleton width={60} />
      <Skeleton />
    </div>
  ));

  return <>{skeletonItems}</>;
};
